import { Button, Grid, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import './FunctionCard.scss';
import BlockingOverlay from '../SubscriptionComponents/BlockingOverlay/BlockingOverlay';

const DISABLED_COLOR = '#868686';

const TextTypoStyle = {
  fontSize: '150%'
};

const FunctionCard = props => {
  const { color, imgSrc, imgAlt, text, title, subtitle, onClick, disabled, extras, blocking, styles, ...rest } = props;

  const content = (
    <div>
      {extras && extras}
      <Button color="primary" fullWidth={true} onClick={!disabled ? onClick : undefined} disableRipple={disabled}>
        <Grid container alignItems="center" wrap="nowrap" spacing={2}>
          <Grid item>
            <div className="side-bar" style={{ backgroundColor: disabled ? DISABLED_COLOR : color }} />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <Grid container direction="column" alignItems="flex-start" spacing={1}>
              <Grid item style={{ flexGrow: 1, width: '100%' }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item className="img-container" style={{ width: '100%', height: '100%', ...styles?.imgContainer }}>
                    <img style={styles?.img} src={imgSrc} alt={imgAlt} />
                  </Grid>
                  {text && (
                    <Grid item>
                      {typeof text === 'string' ? (
                        <Typography className="title" align="left">
                          {text}
                        </Typography>
                      ) : (
                        text
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {title && (
                <Grid item>
                  <Typography className="title" align="left">
                    {title}
                  </Typography>
                </Grid>
              )}

              {subtitle && (
                <Grid item>
                  <Typography className="sub-title" align="left">
                    {subtitle}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <div className="forward-icon" style={disabled ? { color: DISABLED_COLOR } : undefined}>
              <ArrowForwardIosIcon />
            </div>
          </Grid>
        </Grid>
      </Button>
    </div>
  );

  return (
    <div className={`function-card${disabled ? ' disabled' : ''}`} {...rest}>
      {blocking ? <BlockingOverlay>{content}</BlockingOverlay> : content}
    </div>
  );
};

export default FunctionCard;
