import _ from 'lodash';
import { getYouTubeVideoIdFromUrl } from './url';

const PFPLUS_STATIC_URL = 'https://static.portfoplus.com/hot-topic/img/';
const PFPLUS_URL = process.env.PFPLUS_URL;
const PFPLUS_BACKEND_URL = process.env.PFPLUS_BACKEND_URL;

export const getHotTopicSocialPicZipUrl = socialPicZip => {
  if (socialPicZip) {
    return `${PFPLUS_STATIC_URL}${socialPicZip}`;
  }
  return '';
};

export const getHotTopicContentsAllItems = contents => _.flatMap(contents, category => category.items);

export const getHotTopicItemSuggestions = (item, contentsAllItems) => {
  return (item.suggestIdea || [])
    .map(suggestion => {
      return contentsAllItems.find(i => i.id === suggestion);
    })
    .filter(Boolean);
};

export const formatHotTopicContents = contents => {
  const allItems = getHotTopicContentsAllItems(contents);
  const newContents = contents.map(category => {
    return {
      ...category,
      items: (category.items || []).map(item => {
        return {
          ...item,
          suggestions: getHotTopicItemSuggestions(item, allItems)
        };
      })
    };
  });
  return newContents;
};

export const getHotTopicItemImageUrl = (item, isFull) => {
  const { thumbnail, videoId } = item || {};
  if (thumbnail) {
    return `${PFPLUS_STATIC_URL}${isFull ? 'full-' : ''}${thumbnail}`;
  } else {
    // use youtube thumbnail if videoId is specified and the video is a youtube video
    if (videoId) {
      const youtubeId = getYouTubeVideoIdFromUrl(videoId);
      if (youtubeId) {
        return `https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`; //using Med quality thumbnail
      }
    }
  }

  return 'img/genericHelpCenter.png';
};

export const getHotTopicItemShareUrl = (item, locale, extraSearch) => {
  const { id, piktoId } = item || {};

  // compatible with piktoId = 'xxx' format (can remove later)
  const finalPiktoId = piktoId ? piktoId[locale] || piktoId : '';

  if (!finalPiktoId) {
    return '';
  }

  const searchObj = {
    id: id,
    uid: finalPiktoId,
    ...extraSearch
  };

  if (locale) {
    searchObj.l = locale;
  }

  return `${PFPLUS_BACKEND_URL}/lp?${new URLSearchParams(searchObj).toString()}`;
};

export const getHotTopicItemPreviewUrl = (item, locale, extraSearch) => {
  const { id, piktoId } = item || {};

  // compatible with piktoId = 'xxx' format (can remove later)
  const finalPiktoId = piktoId ? piktoId[locale] || piktoId : '';

  if (!finalPiktoId) {
    return '';
  }

  const searchObj = {
    id: id,
    uid: finalPiktoId,
    ...extraSearch
  };

  if (locale) {
    searchObj.l = locale;
  }

  return `${PFPLUS_URL}/lp?${new URLSearchParams(searchObj).toString()}`;
};
