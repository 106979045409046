import { vertexGenerateWithServersidePrompt } from './vertex';
import { validateGPTQuota, decreaseGPTQuota } from '../../utils/openai'
import { getObjectContent, getCombineRemark } from '../../utils/formatGPT/formatGPT';
import _ from 'lodash';
import { tracking } from '../../utils/tracking';


const remarkValidator = (remark) => {
    if (remark && remark.length > 4) {
        return true;
    } else {
        return false;
    }
}




const validator = (val) => {
    if (typeof val === 'number' && val != undefined) {
        return true;
    } else if (typeof val === 'string' && val != undefined && val != "") {
        return true;
    } else {
        return false
    }
}



export const requiredFields = ['factsheetId.remark'];



const availableTypes = ['product_comparison', 'product_suggestion'];
export const getGPTRecommendation = async (
    /** @type {object} */ client,
    /** @type {string} */ type,
    /** @type {string} */ product_list,
    /** @type {string} */ language
) => {

    const combinedRemark = getCombineRemark(client.factsheetId.remark);
    const checkerResults = requiredFields.reduce((fieldA, fieldB) => {
        const next = { ...fieldA }
        if (fieldB === 'factsheetId.remark') {
            next[fieldB] = remarkValidator(combinedRemark);
        }
        else { next[fieldB] = validator(_.get(client, fieldB)); }
        return next
    }, {})

    const checkerCount = Object.values(checkerResults).filter(val => val === false).length;
    if (checkerCount > 0) {
        tracking('AI: GPT', {
            feature: type,
            error: 'gpt-insufficent-data-error',
            client: client,
            file_name: product_list,
        });
        throw new Error('gpt-insufficent-data-error');
    }
    else {
        if (!product_list && !availableTypes.includes(type)) {
            throw new Error('invalid-type-request-type');
        }

        validateGPTQuota();
        const result = await vertexGenerateWithServersidePrompt(
            type, false, false,
            {
                client: client,
                file_name: product_list,
                language: language ?? 'zh-Hant-HK',
                script_language: language === 'zh-Hant-HK' ? 'cantonese' : language === 'zh_CN' ? 'mandarin' : language
            });
        if (result.success) {
            const recommendation = getObjectContent(result, 'gemini');
            if (recommendation.error === "gpt-insufficent-data-error") {
                tracking('AI: GPT', {
                    feature: type,
                    error: 'gpt-insufficent-data-error',
                    client: client,
                    file_name: product_list
                });
                throw new Error('gpt-insufficent-data-error');
            } else if (recommendation.length === 0) {
                tracking('AI: GPT', {
                    feature: type,
                    error: 'gpt-unknown-error',
                    client: client,
                    file_name: product_list
                });
                throw new Error('gpt-unknown-error');
            } else {
                tracking('AI: GPT', {
                    feature: type,
                    result: recommendation,
                    client: client,
                    file_name: product_list

                });

                decreaseGPTQuota();
                return recommendation;

            }
        } else {
            throw new Error('gpt-unknown-error');
        }
    }
}

export const getGPTRecommendationSample = {
    "plan": "安心守護危疾保障計劃",
    "suggest_insure_amount": "6,000,000 HKD",
    "reason_of_recommendation": [
        "作為醫生家庭，醫療保障非常重要，而呢個計劃提供全面嘅危疾保障，涵蓋超過134種疾病。",
        "Carol 有兩個年幼子女，呢個計劃提供額外保障比受保人嘅子女，可以為佢哋提供多一重保障。",
        "計劃提供額外保障比癌症、心臟病同中風，而呢啲疾病係香港人常見嘅危疾，可以提供更周全嘅保障。"
    ],
    "selling_script": "Carol 你好，我哋上次傾開話想了解下危疾保險，我哋公司嘅「安心守護危疾保障計劃」就非常適合你。你作為醫生，平時工作繁忙，呢個計劃可以為你提供全面嘅保障，萬一不幸患上危疾，都可以減輕你嘅經濟負擔，專心休養。加上你哋家庭收入穩定，每月8萬蚊嘅可動用資金，絕對可以應付呢個計劃嘅保費。",
    "reasons_for_others": [
        {
            "plan": "Gold富稅延期年金計劃",
            "reasons": [
                "Carol 家庭月入20萬，呢個計劃可以幫佢哋有效咁累積退休儲蓄，為未來做好準備。",
                "計劃提供稅務優惠，可以幫助佢哋減輕稅務負擔。"
            ]
        }
    ],
    "product_detail_page": "https://www.chubb.com/hk-zh/personal/embrace-care-critical-illness-protector.html",
    "product_brochure": "https://www.chubb.com/content/dam/chubb-sites/chubb/hk-en/pdf/cid_pb_agy_bkg_tc_jul24.pdf"
}


export const getCombineRemarkFromGPT = async (remark) => {
    const result = await vertexGenerateWithServersidePrompt(
        'verify_client_data', false, false,
        {
            remark: remark,
        });
    if (result.success) {
        const data = getObjectContent(result, 'gemini');
        console.log('getCombineRemarkFromGPT result:', result);

        return data;
    }

}