import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ArrowForwardIos, NotificationsActive } from "@material-ui/icons";

function NoNFCAlert({
  show = false /** @type {boolean} */,
  intl,
  onCardLinkAlertClick
}) {
  if (show) {
    const alertStyle = {
      backgroundColor: "white",
      boxShadow: "0px 2px 11.5px rgba(85, 85, 90, 0.16)",
      borderRadius: "8px",
      fontSize: "16px",
      padding: "12px 12px 12px  22px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "19px",
      background: "#FFFFFF",
      borderLeft: "5px solid #F21A1A",
      width: "429px",
      maxWidth: "calc(100vw - 32px)",
      cursor: "pointer"
    };
    return (
      <Box style={alertStyle} onClick={onCardLinkAlertClick}>
        <Box>
          <Box
            style={{
              backgroundColor: "#FFE9E7",
              borderRadius: "50%",
              width: "32px",
              height: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <NotificationsActive
              style={{ fontSize: "14px", color: "#F21A1A" }}
            />
          </Box>
        </Box>
        <Box>
          <Typography
            variant="body2"
            component="h2"
            style={{ color: "black", fontWeight: "600", fontSize: "16px" }}
          >
            {intl.formatMessage({ id: "linker-no-nfc-alert-message" })}
          </Typography>
        </Box>
        <Box
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-end",
            color: "#9F9F9F"
          }}
        >
          <ArrowForwardIos style={{ fontSize: "12px" }} />
        </Box>
      </Box>
    );
  } else {
    return null;
  }
}
export const NFCPersistentAlert = NoNFCAlert;
export default NoNFCAlert;
