import { Capacitor } from '@capacitor/core';
import { history } from '../../views/App/history';
import { Browser } from '@capacitor/browser';

export const processInternalRoute = url => {
  const withoutScheme = (url || '').split('//').pop();
  const splits = withoutScheme.split('/');

  if (splits.length > 1) {
    const filtered = splits.slice(1, splits.length);
    if (filtered) {
      const target = `/${filtered.join('/')}`;

      if (Capacitor.getPlatform() === 'ios' && (target.indexOf('signup') > -1 || target.indexOf('subscription') > -1)) {
        window.open(url, '_blank');
      } else if (target.indexOf('/web/') === 0) {
        Browser.open({ url: url });
      } else {
        history.push(target);
      }
    }
  }
};
