import React, { PureComponent } from "react";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import MySwipeable from "../../../components/MySwipeable";
import { Grid, Button } from "@material-ui/core";
import NumberFormat from "react-number-format";
import "./myDetail.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AlertPopUp from "../../../components/AlertPopup";
import _ from "lodash";
import MyEditor from "../../../components/MyEditor/MyEditor";
import {
  formatEditorStateValue,
  getSaveContentState,
  getEditorDefaultMessage
} from "../../../utils/editor";
import MyToolbar from "../../../components/MyEditor/MyToolbar";
import { getAgeFromDob } from "../../../utils/date";

class MyDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      smoker: "",
      occupation: "",
      marriage: "",
      child: "",
      monthlyIncome: "",
      monthlyExpense: "",
      dob: "",
      age: "",
      liquidAsset: "",
      debt: "",
      remark: "",
      err: {},
      userType: "",
      company: "",
      adviserSince: "",
      servicingYear: "",
      myPassion: "",
      adviserMessage: formatEditorStateValue(
        getEditorDefaultMessage(props.intl.locale, "adviser-message")
      ),
      openAlertModal: false,
      samePath: false,
      editMessage: false,
      linkedinLink: "",
      facebookLink: ""
    };
    this.submitForm = this.submitForm.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleSwipe = this.handleSwipe.bind(this);

    this.myEditorRef = React.createRef();
    this.myToolbarRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    var user = this.props.userDetails;
    var localStorageData = JSON.parse(localStorage.getItem("myDetails"));
    let id = user._id;
    this.setState({ id: id });
    this.props.getAdvisorMessage(id);
    let data = localStorageData ? localStorageData : user;
    let userType = user.userType;
    this.setState({ userType: userType });
    if (data) {
      //saving the users data in there respective fields
      if (userType === "User") {
        this.setState({
          smoker: data.smoker ? data.smoker : "",
          occupation: data.occupation ? data.occupation : "",
          marriage: data.marriageStatus ? data.marriageStatus : "",
          child:
            data.child !== null && data.child !== undefined ? data.child : "",
          monthlyIncome: data.monthlyIncome ? data.monthlyIncome : "",
          monthlyExpense: data.monthlyExpense ? data.monthlyExpense : "",
          liquidAsset: data.liquidAsset ? data.liquidAsset : "",
          debt: data.debt ? data.debt : "",
          remark: data.remark ? data.remark : "",
          dob: data.dob ? data.dob : "",
          age: getAgeFromDob(data.dob, data.age) || ""
        });
      } else {
        let adviserMessage;
        if (data.adviserMessage) {
          adviserMessage = formatEditorStateValue(data.adviserMessage);
        } else {
          adviserMessage = this.state.adviserMessage;
        }
        this.setState({
          company: data.company ? data.company : "",
          adviserSince: data.adviserSince
            ? moment.parseZone(data.adviserSince)
            : "",
          servicingYear: data.servicingYear ? parseInt(data.servicingYear) : "",
          myPassion: data.myPassion ? data.myPassion : "",
          adviserMessage: adviserMessage,
          linkedinLink: _.get(data, "linkedinLink", ""),
          facebookLink: _.get(data, "facebookLink", "")
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { samePath } = this.state;
    if (
      nextProps.updatePhase === true &&
      this.props.updatePhase !== true &&
      !samePath
    ) {
      var user = nextProps.userDetails;
      this.props.getAdvisorMessage(user._id);
      // let routePath = user.userType === 'User' ? '/analysis' : '/referral';
      // this.props.history.push({
      //   pathname: this.props.location
      // });
      // history.goBack();
    }
    if (
      nextProps.updatePhase === true &&
      this.props.updatePhase !== true &&
      samePath
    ) {
      this.props.getUser();
      this.setState({
        openAlertModal: false,
        editMessage: true,
        adviserMessage: _.get(
          nextProps,
          "userDetails.adviserMessage",
          formatEditorStateValue(
            getEditorDefaultMessage(nextProps.intl.locale, "adviser-message")
          )
        )
      });
      // this.setState({ adviserMessage: undefined });
    }
  }

  //Handle the date od servicing year
  handleDateChange(date) {
    this.setState({ adviserSince: date });
    let adviserSince = moment(date).format("DD-MM-YYYY");
    let years = date
      ? moment().diff(moment(adviserSince, "DD-MM-YYYY"), "years")
      : "";
    this.setState({ servicingYear: years });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleNumberKeyDown(e) {
    if (
      !(
        (e.keyCode > 95 && e.keyCode < 106) ||
        (e.keyCode > 47 && e.keyCode < 58) ||
        e.keyCode === 8
      )
    ) {
      e.preventDefault();
    }
  }

  handleSwipe(direction) {
    if (direction === "left") {
      this.handlePrevious();
    }
  }
  setBrithDate = date => {
    this.setState({ dob: date });
    let dob = moment(date).format("DD-MM-YYYY");
    let years = date ? moment().diff(moment(dob, "DD-MM-YYYY"), "years") : "";
    this.setState({ age: years });
  };

  //function submit the final data to main component
  submitForm() {
    var formData = {};
    if (this.state.userType === "User") {
      formData.smoker = this.state.smoker;
      formData.occupation = this.state.occupation;
      formData.marriageStatus = this.state.marriage;
      formData.child = this.state.child;
      // formData.monthlyIncome = parseInt(this.state.monthlyIncome);
      // formData.monthlyExpense = parseInt(this.state.monthlyExpense);
      if (
        Number.isInteger(this.state.monthlyIncome) === true ||
        this.state.monthlyIncome.toString().indexOf(".") === -1
      ) {
        formData.monthlyIncome = parseInt(this.state.monthlyIncome);
      } else {
        formData.monthlyIncome = parseFloat(this.state.monthlyIncome).toFixed(
          2
        );
      }
      if (
        Number.isInteger(this.state.monthlyExpense) === true ||
        this.state.monthlyExpense.toString().indexOf(".") === -1
      ) {
        formData.monthlyExpense = parseInt(this.state.monthlyExpense);
      } else {
        formData.monthlyExpense = parseFloat(this.state.monthlyExpense).toFixed(
          2
        );
      }
      formData.liquidAsset = this.state.liquidAsset;
      formData.debt = this.state.debt;
      formData.remark = this.state.remark;
      formData.dob = this.state.dob
        ? moment(this.state.dob).toISOString()
        : this.state.dob;
      formData.age = this.state.age;
    } else {
      formData.company = this.state.company;
      var adviserSince = this.state.adviserSince
        ? moment(this.state.adviserSince).format("YYYY-MM-DD")
        : "";
      let adviserSince1 = adviserSince
        ? moment.utc(adviserSince).toISOString()
        : "";
      formData.adviserSince = adviserSince1;
      formData.servicingYear = parseInt(this.state.servicingYear).toString();
      formData.myPassion = this.state.myPassion;
      formData.adviserMessage = getSaveContentState(this.state.adviserMessage);
      formData.linkedinLink = this.state.linkedinLink;
      formData.facebookLink = this.state.facebookLink;
    }
    localStorage.setItem("myDetails", JSON.stringify(formData));
    this.setState({ samePath: false });
    this.props.handleSubmit();
  }

  //Handle the previous data
  handlePrevious() {
    var formData = {};
    if (this.state.userType === "User") {
      formData.smoker = this.state.smoker;
      formData.occupation = this.state.occupation;
      formData.marriageStatus = this.state.marriage;
      formData.child = this.state.child;
      formData.monthlyIncome = this.state.monthlyIncome;
      formData.monthlyExpense = this.state.monthlyExpense;
      formData.liquidAsset = this.state.liquidAsset;
      formData.debt = this.state.debt;
      formData.remark = this.state.remark;
      formData.dob = this.state.dob
        ? moment(this.state.dob).toISOString()
        : this.state.dob;
      formData.age = this.state.age;
    } else {
      formData.company = this.state.company;
      formData.adviserSince = this.state.adviserSince
        ? moment(this.state.adviserSince).toISOString()
        : this.state.adviserSince;
      formData.servicingYear = parseInt(this.state.servicingYear).toString();
      formData.myPassion = this.state.myPassion;
      formData.adviserMessage = getSaveContentState(this.state.adviserMessage);
      formData.linkedinLink = this.state.linkedinLink;
      formData.facebookLink = this.state.facebookLink;
    }
    localStorage.setItem("myDetails", JSON.stringify(formData));

    this.props.handlePreviousPage(2);
  }

  //Handle the status of marriage status
  handleMarriageStatus(key) {
    const { marriage } = this.state;
    let formattedKey = key;

    if (marriage === key) formattedKey = "";

    this.setState({
      marriage: formattedKey
    });
  }

  //Handle the status of smoker
  handleSmokerStatus(key) {
    const { smoker } = this.state;
    let formattedKey = key;

    if (smoker === key) formattedKey = "";

    this.setState({
      smoker: formattedKey
    });
  }

  handleUpdateUserProfile() {
    this.setState({ samePath: true });
    this.props.updateUserDetails({ unsetKey: "adviserMessage" });
  }

  render() {
    const { intl } = this.props;
    const { adviserSince, openAlertModal, adviserMessage } = this.state;
    let adviserSinceVal = adviserSince ? moment.parseZone(adviserSince) : null;
    return (
      <div>
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <MySwipeable handleSwipe={this.handleSwipe}>
                  <div className="col-lg-12">
                    <form id="msform" className="formPager remMarg">
                      <ul id="progressbar">
                        <li className="active" />
                        <li className="active" />
                      </ul>
                      <fieldset>
                        <h2 className="title-text text-center">
                          {intl.formatMessage({ id: "My Details" })}
                        </h2>
                        <p className="title-text text-center">
                          {intl.formatMessage({
                            id: "Fill up data for accurate analysis"
                          })}
                        </p>
                        {this.state.userType === "User" ? (
                          <div>
                            <div className="select-buttons-inline-form-control">
                              <div className="control-orphay select-buttons-inline-label status-label-res">
                                {intl.formatMessage({ id: "Smoker" })}
                              </div>
                              <div className="select-buttons-inline-div select-buttons-inline-res">
                                <Button
                                  variant={
                                    this.state.smoker === "Yes"
                                      ? "contained"
                                      : "outlined"
                                  }
                                  className={
                                    this.state.smoker === "Yes"
                                      ? "plan-type-select-button selected"
                                      : "plan-type-select-button"
                                  }
                                  key={0}
                                  onClick={() => this.handleSmokerStatus("Yes")}
                                >
                                  {intl.formatMessage({ id: "Yes" })}
                                </Button>

                                <Button
                                  variant={
                                    this.state.smoker === "No"
                                      ? "contained"
                                      : "outlined"
                                  }
                                  className={
                                    this.state.smoker === "No"
                                      ? "plan-type-select-button selected"
                                      : "plan-type-select-button"
                                  }
                                  key={1}
                                  onClick={() => this.handleSmokerStatus("No")}
                                >
                                  {intl.formatMessage({ id: "No" })}
                                </Button>
                              </div>
                            </div>

                            <div className="select-buttons-inline-form-control">
                              <div className="control-orphay select-buttons-inline-label status-label-res">
                                {intl.formatMessage({ id: "Marriage Status" })}
                              </div>
                              <div className="select-buttons-inline-div select-buttons-inline-res">
                                <Button
                                  variant={
                                    this.state.marriage === "Single"
                                      ? "contained"
                                      : "outlined"
                                  }
                                  className={
                                    this.state.marriage === "Single"
                                      ? "plan-type-select-button selected"
                                      : "plan-type-select-button"
                                  }
                                  key={0}
                                  onClick={() =>
                                    this.handleMarriageStatus("Single")
                                  }
                                >
                                  {intl.formatMessage({ id: "Single" })}
                                </Button>

                                <Button
                                  variant={
                                    this.state.marriage === "Married"
                                      ? "contained"
                                      : "outlined"
                                  }
                                  className={
                                    this.state.marriage === "Married"
                                      ? "plan-type-select-button selected"
                                      : "plan-type-select-button"
                                  }
                                  key={1}
                                  onClick={() =>
                                    this.handleMarriageStatus("Married")
                                  }
                                >
                                  {intl.formatMessage({ id: "Married" })}
                                </Button>
                              </div>
                            </div>

                            {/* <div className="form-group">
                              <input
                                type="number"
                                name="child"
                                value={this.state.child}
                                onChange={this.handleChange.bind(this)}
                                min="0"
                                pattern="[0-9]*"
                                onKeyDown={this.handleNumberKeyDown.bind(this)}
                              />
                              <label className="control-label" htmlFor="input">
                                No. of Child
                              </label>
                              <i className="bar" />
                            </div>
                          */}
                            <div className="select-buttons-inline-form-control">
                              <div className="control-orphay select-buttons-inline-label">
                                {intl.formatMessage({ id: "No. of Child" })}
                              </div>
                              <div className="select-buttons-inline-div">
                                <Button
                                  variant={
                                    this.state.child !== null
                                      ? "contained"
                                      : "outlined"
                                  }
                                  className={
                                    this.state.child === null ||
                                    this.state.child === ""
                                      ? "plan-type-select-button  child-sel-btn"
                                      : "plan-type-select-button selected  child-sel-btn"
                                  }
                                  key={0}
                                  onClick={() =>
                                    this.setState({
                                      child:
                                        this.state.child === "0" ||
                                        this.state.child === 0 ||
                                        this.state.child === null ||
                                        this.state.child === ""
                                          ? null
                                          : (
                                              parseInt(
                                                this.state.child === "" ||
                                                  this.state.child === "0" ||
                                                  this.state.child === 0
                                                  ? "1"
                                                  : this.state.child
                                              ) - 1
                                            ).toString()
                                    })
                                  }
                                >
                                  -
                                </Button>
                                <span style={{ padding: "5px 12px 2px 12px" }}>
                                  {this.state.child}
                                </span>
                                <Button
                                  variant={
                                    this.state.child !== "9"
                                      ? "contained"
                                      : "outlined"
                                  }
                                  className={
                                    this.state.child !== "9"
                                      ? "plan-type-select-button selected child-sel-btn"
                                      : "plan-type-select-button child-sel-btn"
                                  }
                                  key={1}
                                  onClick={() =>
                                    this.setState({
                                      child:
                                        this.state.child === "9"
                                          ? "9"
                                          : (
                                              parseInt(
                                                this.state.child === "" ||
                                                  this.state.child === null
                                                  ? "0"
                                                  : this.state.child
                                              ) +
                                              (this.state.child === null ||
                                              this.state.child === ""
                                                ? 0
                                                : 1)
                                            ).toString()
                                    })
                                  }
                                >
                                  +
                                </Button>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 col-xs-6">
                                <div className="form-group datepicker-group">
                                  <DatePicker
                                    name="dob"
                                    clearable={true}
                                    format="DD-MMM-YYYY"
                                    maxDate={new Date()}
                                    value={
                                      this.state.dob === "" ||
                                      this.state.dob === undefined ||
                                      this.state.dob === null
                                        ? null
                                        : moment.parseZone(
                                            this.state.dob
                                          ) /*moment.parseZone is equivalent to parsing the string and using moment utcoffset and to handle the date for -utc as well as it works for +utc*/
                                    }
                                    openTo="year"
                                    onChange={date => this.setBrithDate(date)}
                                    autoOk={true}
                                    initialFocusedDate={moment().subtract(
                                      30,
                                      "years"
                                    )}
                                    label={intl.formatMessage({
                                      id: "Date of Birth"
                                    })}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                  <input
                                    type="number"
                                    name="age"
                                    value={this.state.age}
                                    onChange={this.handleChange.bind(this)}
                                    required="required"
                                    min="0"
                                    pattern="[0-9]*"
                                    onKeyDown={this.handleNumberKeyDown.bind(
                                      this
                                    )}
                                    disabled={!!this.state.dob}
                                  />
                                  <label
                                    className="control-label"
                                    htmlFor="input"
                                  >
                                    {intl.formatMessage({ id: "Age" })}
                                  </label>
                                  <i className="bar" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                  <NumberFormat
                                    //type="number"
                                    //name="monthlyIncome"
                                    thousandSeparator={true}
                                    value={this.state.monthlyIncome}
                                    onValueChange={values => {
                                      const { value } = values;
                                      this.setState({ monthlyIncome: value });
                                    }}
                                    min="0"
                                    //pattern="[0-9]*"
                                    onKeyDown={this.handleNumberKeyDown.bind(
                                      this
                                    )}
                                  />
                                  <label
                                    className="control-label"
                                    htmlFor="input"
                                  >
                                    {intl.formatMessage({
                                      id: "Monthly Income"
                                    })}
                                  </label>
                                  <i className="bar" />
                                </div>
                              </div>
                              <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                  <NumberFormat
                                    //type="number"
                                    //name="monthlyExpense"
                                    thousandSeparator={true}
                                    value={this.state.monthlyExpense}
                                    onValueChange={values => {
                                      const { value } = values;
                                      this.setState({ monthlyExpense: value });
                                    }}
                                    min="0"
                                    //pattern="[0-9]*"
                                    onKeyDown={this.handleNumberKeyDown.bind(
                                      this
                                    )}
                                  />
                                  <label
                                    className="control-label"
                                    htmlFor="input"
                                  >
                                    {intl.formatMessage({
                                      id: "Monthly Expense"
                                    })}
                                  </label>
                                  <i className="bar" />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <input
                                type="text"
                                name="liquidAsset"
                                value={this.state.liquidAsset}
                                onChange={this.handleChange.bind(this)}
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: "Liquid Asset" })}
                              </label>
                              <i className="bar" />
                            </div>

                            <div className="form-group">
                              <NumberFormat
                                //type="number"
                                //name="debt"
                                //pattern="[0-9]*"
                                thousandSeparator={true}
                                value={this.state.debt}
                                onValueChange={values => {
                                  const { value } = values;
                                  this.setState({ debt: value });
                                }}
                                min="0"
                                onKeyDown={this.handleNumberKeyDown.bind(this)}
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: "Debt" })}
                              </label>
                              <i className="bar" />
                            </div>

                            <div className="form-group">
                              <textarea
                                type="text"
                                name="remark"
                                value={this.state.remark}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: "Remark" })}
                              </label>
                              <i className="bar" />
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="company"
                                value={this.state.company}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: "Workplace" })}
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="row">
                              <div className="col-md-6 col-xs-6">
                                <div className="form-group datepicker-group">
                                  <DatePicker
                                    name="adviserSince"
                                    format="DD-MMM-YYYY"
                                    clearable={true}
                                    maxDate={new Date()}
                                    value={
                                      this.state.adviserSince === ""
                                        ? null
                                        : adviserSinceVal
                                    }
                                    openTo="year"
                                    onChange={date =>
                                      this.handleDateChange(date)
                                    }
                                    autoOk={true}
                                    label={intl.formatMessage({
                                      id: "Adviser since"
                                    })}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                  <input
                                    type="number"
                                    name="servicingYear"
                                    value={this.state.servicingYear}
                                    onChange={this.handleChange.bind(this)}
                                    required="required"
                                    min="0"
                                    pattern="[0-9]*"
                                    onKeyDown={this.handleNumberKeyDown.bind(
                                      this
                                    )}
                                  />
                                  <label
                                    className="control-label"
                                    htmlFor="input"
                                  >
                                    {intl.formatMessage({
                                      id: "Servicing year"
                                    })}
                                  </label>
                                  <i className="bar" />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="facebookLink"
                                value={this.state.facebookLink}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                                placeholder="https://"
                              />
                              <label
                                className="control-label login__page--label"
                                htmlFor="input"
                              >
                                {intl.formatMessage({ id: "Facebook Link" })}
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="linkedinLink"
                                value={this.state.linkedinLink}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                                placeholder="https://"
                              />
                              <label
                                className="control-label login__page--label"
                                htmlFor="input"
                              >
                                {intl.formatMessage({ id: "Adviser-Link" })}
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group adviser-message">
                              <div>
                                <MyToolbar
                                  editorState={adviserMessage}
                                  onChange={editorState => {
                                    this.setState({
                                      adviserMessage: editorState
                                    });
                                  }}
                                  ref={this.myToolbarRef}
                                  editorRef={this.myEditorRef}
                                  undo={true}
                                />
                                <MyEditor
                                  editorState={adviserMessage}
                                  onChange={editorState => {
                                    this.setState({
                                      adviserMessage: editorState
                                    });
                                  }}
                                  textAlign={true}
                                  ref={this.myEditorRef}
                                  toolbarRef={this.myToolbarRef}
                                />
                                <div>
                                  <label
                                    className="control-label"
                                    htmlFor="input"
                                  >
                                    {intl.formatMessage({
                                      id: "Message in my page"
                                    })}
                                  </label>
                                </div>
                              </div>
                              <Button
                                variant="contained"
                                className="plan-type-select-button selected default__profile"
                                key={0}
                                onClick={() =>
                                  this.setState({ openAlertModal: true })
                                }
                              >
                                {intl.formatMessage({ id: "Default" })}
                              </Button>
                            </div>
                          </div>
                        )}
                        <Grid container justify={"space-between"}>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handlePrevious}
                              className="prevNextButton"
                              startIcon={<ArrowBackIosIcon />}
                            >
                              {intl.formatMessage({ id: "Back" })}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.submitForm}
                              className="submitButton"
                            >
                              {intl.formatMessage({ id: "Save" })}
                            </Button>
                          </Grid>
                        </Grid>
                        <AlertPopUp
                          intl={intl}
                          open={openAlertModal}
                          handleClose={() =>
                            this.setState({ openAlertModal: false })
                          }
                          handleUpdateUserProfile={this.handleUpdateUserProfile.bind(
                            this
                          )}
                          title={intl.formatMessage({ id: "Confirm" })}
                          description={intl.formatMessage({
                            id: "adviser_message_delete"
                          })}
                          showUpdateButton={true}
                        />
                      </fieldset>
                    </form>
                  </div>
                </MySwipeable>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MyDetail;
