import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getAdviserProfilePlus } from '../../store/adviserProfilePlus/duck';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { INIT, SUCCESS } from '../../constants/phase';
import AdviserInfoPage from './AdviserInfoPage';
import { usePrevious } from '../../utils/hooks';
import { getSearchObject } from '../../utils/router';
import { getWhatsappUrl } from '../../utils/whatsapp';
import { getVCardStringWithPhoto } from '../../utils/vcard';
import { download } from '../../utils/download';
import ECardWelcomeDialog from './ECardWelcomeDialog/ECardWelcomeDialog';

const AdviserInfoPagePublic = props => {
  const {
    aid,
    location,
    match,
    showPadding,
    userDetails,
    adviserInfo,
    getAdviserProfilePlusPhase,
    getAdviserProfilePlus
  } = props;

  const [eCardWelcomeDialogOpen, setECardWelcomeDialogOpen] = useState(false);
  const [triggerFormOpen, setTriggerFormOpen] = useState(false);
  const [defaultMessage, setDefaultMessage] = useState(undefined);
  const prevGetAdviserProfilePlusPhase = usePrevious(getAdviserProfilePlusPhase);
  const autoDownloaded = useRef(false);
  const userId = aid ?? _.get(match, 'params.userId');

  useEffect(() => {
    if (getAdviserProfilePlusPhase === INIT || userId !== adviserInfo?._id) {
      getAdviserProfilePlus(userId, location);
    }
  }, [getAdviserProfilePlus, getAdviserProfilePlusPhase, userId, location]);

  useEffect(() => {
    if (getAdviserProfilePlusPhase !== prevGetAdviserProfilePlusPhase) {
      if (getAdviserProfilePlusPhase === SUCCESS) {
        const searchObj = getSearchObject({ location });
        const { defaultMessage, fromQRCode, vcf } = searchObj || {};
        const { name, whatsapp } = adviserInfo || {};

        if (defaultMessage) {
          const text = (defaultMessage || '').replace('*adviserName*', name);
          setDefaultMessage(text);
          if (whatsapp) {
            setECardWelcomeDialogOpen({ phone: whatsapp, text });
          } else {
            setTriggerFormOpen(true);
          }
        } else {
          if (fromQRCode) {
            setTriggerFormOpen(true);
          }
        }

        if (vcf) {
          if (!autoDownloaded.current) {
            autoDownloaded.current = true;
            getVCardStringWithPhoto(adviserInfo, string => {
              download(string, `${name}.vcf`, 'text/x-vcard');
            });
          }
        }
      }
    }
  }, [getAdviserProfilePlusPhase, prevGetAdviserProfilePlusPhase, location, adviserInfo]);

  const isLoggedIn = userDetails && userDetails._id;

  const onECardWelcomeDialogSayNo = () => {
    setTriggerFormOpen(true);
    setECardWelcomeDialogOpen(false);
  };

  const onECardWelcomeDialogSayYes = () => {
    const { phone, text } = eCardWelcomeDialogOpen || {};
    const whatsappUrl = getWhatsappUrl({ phone, text });
    window.open(whatsappUrl, '_blank');
    setECardWelcomeDialogOpen(false);
  };

  return (
    <div key={location.pathname} style={{ height: '100%', paddingTop: 0, paddingBottom: 0 }}>
      <ECardWelcomeDialog
        open={!!eCardWelcomeDialogOpen}
        onSayNo={onECardWelcomeDialogSayNo}
        onSayYes={onECardWelcomeDialogSayYes}
      />
      <AdviserInfoPage withNav={isLoggedIn} triggerFormOpen={triggerFormOpen} defaultMessage={defaultMessage} />
    </div>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    adviserInfo: _.get(state.adviserProfilePlus, 'adviserProfilePlus.adviserInfo'),
    getAdviserProfilePlusPhase: state.adviserProfilePlus.getAdviserProfilePlusPhase,
    showPadding: state.app.get('showPadding')
  }),
  {
    getAdviserProfilePlus
  }
)(AdviserInfoPagePublic);

export default injectIntl(container);
