import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Button, Box, Typography } from '@material-ui/core';
import { Capacitor } from "@capacitor/core";
import { isMobile } from 'react-device-detect';
import BottomSheetOrDialog from '../../components/BottomSheetOrDialog/BottomSheetOrDialog';

const LinkerNfcCardInstruction = ({ intl, linkNfcCardDialogOpen, setLinkNfcCardDialogOpen }) => {
    const [step, setStep] = useState(1);

    const buttonStyle = {
        boxShadow: "none",
        width: "100%",
        minHeight: "44px",
        borderRadius: "10px",
        fontSize: 16
    };

    const whatsappGetNfcCard = () => {
        const defaultText = "%E6%88%91%E6%83%B3%E9%A0%90%E7%B4%84%E6%8B%8E%20Linker%20NFC%20Card";
        const platform = Capacitor.getPlatform();
        window.open(`https://api.whatsapp.com/send?phone=85266370320&text=(${platform})%20${defaultText}`, "_blank");
    }

    useEffect(() => {
        setStep(1);
    }, [linkNfcCardDialogOpen]);

    return (
        <BottomSheetOrDialog
            open={linkNfcCardDialogOpen}
            onClose={() => setLinkNfcCardDialogOpen(false)}
            header={step === 2 ? intl.formatMessage({ id: 'linker-bind-nfc-card' }) : ""}
            content={
                <>
                    {step === 1 &&
                        <>
                            <Typography style={{ color: "black", fontWeight: 800, fontSize: 18, marginBottom: 10 }}> {intl.formatMessage({ id: 'linker-nfc-card-required-to-bind' })} </Typography>
                            <Typography style={{ marginBottom: 20 }}> {intl.formatMessage({ id: 'linker-request-nfc-card-through-whatsapp' })} </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Button variant='text' size='large' style={{ ...buttonStyle }} onClick={() => setStep(2)}>{intl.formatMessage({ id: 'linker-bind' })}</Button>
                                </Grid>
                                <Grid item xs={8}>
                                    <Button variant='contained' color="primary" size='large' style={{ ...buttonStyle }} onClick={whatsappGetNfcCard}>{intl.formatMessage({ id: 'linker-reserve-and-collect' })} →</Button>
                                </Grid>
                            </Grid>
                        </>
                    }
                    {step === 2 &&
                        <Box textAlign="center">
                            <img src="img/tap.svg" alt="tap-card-on-mobile-phone" width={180} height={180} style={{ margin: 30 }} />
                            {!isMobile && <Typography style={{ marginBottom: 10 }}>* {intl.formatMessage({ id: 'linker-use-mobile-phone-to-perform-action' })}</Typography>}
                            <Typography style={{ color: "black", fontWeight: 800, fontSize: 16, marginBottom: 30 }}>{intl.formatMessage({ id: 'linker-tap-nfc-card-with-mobile-phone' })}</Typography>
                        </Box>
                    }
                </>
            }
            actions={[]}
            BottomSheetProps={{
                expandOnContentDrag: false,
                disableAutoDismiss: true
            }}
            DialogProps={{
                maxWidth: 'xs',
                fullWidth: true
            }}
            DialogActionsProps={{
                style: { padding: 4 }
            }}
            DialogParams={{ dialogTitleCloseButton: true }}
        />
    );
};

export default injectIntl(LinkerNfcCardInstruction);
