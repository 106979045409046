import { toast } from 'react-toastify';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { getVCardStringWithPhoto, getVCardString } from '../../../../utils/vcard';
import { download } from '../../../../utils/download';
import Backend from '../../../../backend';
import { handleWalletPassFetch } from '../../../../utils/wallet-pass';

function isSelfHosted(url) {
  const nextHost = new URL(url).host;
  const currentHost = new URL(process.env.PUBLIC_URL).host;
  return nextHost === currentHost;
}

export function messageListener(history, onMessage, cases, userDetails) {
  const back = event => {
    if (event.origin === process.env.LINKER_URL) {
      const data = event.data.data;

      if (onMessage) {
        onMessage(event.data.success, event.data);
      } else if (event.data.success) {
        if (cases && cases[event.data.code]) {
          cases[event.data.code](data);
        } else
          switch (event.data.code) {
            case 'PUSH':
              // console.log(" --- messageListener: --- Redirect to PATH: ", data);
              if (data) {
                history.push({ pathname: data });
              }
              break;
            case 'REDIRECT':
              // console.log(" --- messageListener: --- Redirect to PATH: ", data);
              if (data?.startsWith('http') || data?.startsWith('https')) {
                if (isSelfHosted(data)) {
                  const url = new URL(data);
                  history.push({ pathname: url.pathname, search: url.search });
                } else {
                  if (Capacitor.isNativePlatform()) {
                    Browser.open({ url: data });
                  } else {
                    window.open(data);
                  }
                }
              } else if (data) {
                history.push({ pathname: data });
              }
              break;
            case 'TOAST':
              toast.success(data);
              break;
            case 'DOWNLOAD_VCF_FILE':
              if (userDetails) {
                if (userDetails.avatar) {
                  getVCardStringWithPhoto(userDetails, string =>
                    download(string, `${userDetails.name}.vcf`, 'text/x-vcard')
                  );
                } else {
                  const vCardString = getVCardString(userDetails);
                  download(vCardString, `${userDetails.name}.vcf`, 'text/x-vcard');
                }
              }
              break;
            case 'OPEN_IN_NEW_WINDOW':
              window.open(event.data.data, '_blank');
              break;
            case 'DOWNLOAD_APPLE_WALLET_ECARD':
              handleWalletPassFetch(Backend.methodUrl(`wallet-pass/apple?language=${event.data.data}`), 'apple');
              break;
          }
      }
    }
  };
  window.addEventListener('message', back, false);
  return () => window.removeEventListener('message', back);
}
