import React from 'react';
import QRCodeComponent from '../../../../../components/QRCodeComponent';
import { injectIntl } from 'react-intl';
import './ReferralCard.scss';
import ProCornerTriangle from '../../../../../components/CornerTriangle/ProCornerTriangle';
import { THEME_COLOR_PALETTES,getValidThemeColorOption } from '../../../../../constants/my-details';
import MyDetailsProfilePicture from '../../../MyDetails/MyDetailsProfile/MyDetailsProfilePicture/MyDetailsProfilePicture';
import { getUserInitial } from '../../../../../utils/user';

const ReferralCard = props => {
  const { intl, link, customLogo, customCTAMessage, hideHeader, details } = props;

  const { avatar } = details || {};
  const themeColor = getValidThemeColorOption(details);
  const initial = getUserInitial(details);
  const shouldShowIcon = avatar || initial;

  return (
    <div className="referral-card-wrapper">
      {!hideHeader && (
        <div className="logo-wrapper">
          <div style={{ paddingTop: 15 }}>
            <ProCornerTriangle />
            {customLogo ? (
              <img alt="" src={customLogo + `?v=${new Date().getTime()}`} crossOrigin="anonymous" />
            ) : (
              <img alt="" src="img/logo_kyc.png" />
            )}
          </div>
        </div>
      )}
      <div className="card-wrapper" style={shouldShowIcon ? { marginTop: 44 } : undefined}>
        {shouldShowIcon && (
          <div className="icon-wrapper">
            <MyDetailsProfilePicture
              width={68}
              initial={initial}
              src={avatar}
              borderColor={THEME_COLOR_PALETTES[themeColor || 'blue'][1]}
            />
          </div>
        )}

        <div className="referral-card">
          <div className="card-content" style={shouldShowIcon ? { paddingTop: 45 } : undefined}>
            <div className="qr-code-container">
              <QRCodeComponent content={link} isStyle={true} />
            </div>
            {customCTAMessage && (
              <div className="button-wrapper">
                <div>{customCTAMessage}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ReferralCard);
