import { IconButton } from '@material-ui/core';
import { toggleControl } from '../../../../store/control/duck';
import store from '../../../../store';
import React from 'react';
import RewriteMessageDrawer from './drawer';
import { tracking } from '../../../../utils/tracking';
import MyToolbarButton from '../../../../utils/myToolbarButton';
import { shouldEnableChatGPT } from '../../../../utils/user';


const ChatGPTRewriteMessageButton = (props) => {
    return <IconButton
        style={props.style}
        aria-label="GPTRewriteMessageDrawerButton"
        onClick={() => {
            // console.log('ChatGPTRewriteMessageButton onClick called');
            tracking('Click on RewriteMessage - ChatGPT');
            store.dispatch(
                toggleControl('chatGPTRewriteMessageDrawer',
                    {
                        open: true,
                        message: props.message,
                        client: props.client,
                        type: props.type,
                        onValue: props.onValue
                    }));
        }}>
        <img
            style={{
                width: "20px",
                height: "20px",
                ...props.iconStyle
            }}
            src="img/aiGPT.svg" />
    </IconButton>
}

export const ChatGPTRewriteMessageToolbarButton = (props) => {
    return <MyToolbarButton i="img/aiGPT.svg" {...props}
        aria-label="ChatGPTRewriteMessageToolbarButton"
        onClick={() => {
            // console.log('ChatGPTRewriteMessageButton onClick called');
            tracking('Click on RewriteMessage - ChatGPT');
            store.dispatch(
                toggleControl('chatGPTRewriteMessageDrawer',
                    {
                        open: true,
                        message: props.message,
                        client: props.client,
                        type: props.type,
                        onValue: props.onValue
                    }));
        }} />
}

export const ChatGPTRewriteMessageDrawer = RewriteMessageDrawer;
export const ChatGPTRewriteMessage = (props) => {
    const gptEnabled = shouldEnableChatGPT({
        user: store.getState().user.userDetails,
        teamCampaignCodes: store.getState().functions.campaignCodes,
        userSubscription: store.getState().userSubscription.userSubscription
    });

    if (!gptEnabled) return <></>;

    const { buttonType, containerStyle } = props
    return (
        <div style={containerStyle}>
            <RewriteMessageDrawer />
            {buttonType === 'toolbar' ?
                <ChatGPTRewriteMessageToolbarButton {...props} /> :
                <ChatGPTRewriteMessageButton {...props} />}
        </div>
    )
}