import React from 'react';
import ReactDOM from 'react-dom';
import { Backdrop, CircularProgress } from '@material-ui/core';

const LoadingBackdrop = (props) => {
  const { className, open, style } = props;

  return ReactDOM.createPortal(
    <Backdrop
      className={className}
      open={open}
      style={{ zIndex: 1400, ...style }}
    >
      <CircularProgress color="primary" />
    </Backdrop>,
    document.body
  );
};

export default LoadingBackdrop;
