import React from 'react';
import AspectRatioSkeleton from '../AspectRatioSkeleton/AspectRatioSkeleton';
import { sanitize } from 'dompurify';

export default class AnimatedSvg extends React.PureComponent {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.state = {
      svgHtml: undefined,
      loading: true,
      error: null
    };
  }

  async componentDidMount() {
    const { url } = this.props;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to load SVG: ${response.statusText}`);
      }
      const svg = await response.text();

      // Parse SVG to preserve animations
      const parser = new DOMParser();
      const doc = parser.parseFromString(svg, 'image/svg+xml');
      const svgElement = doc.querySelector('svg');

      if (svgElement) {
        // Preserve animation classes
        if (this.props.animated !== false) {
          svgElement.classList.add('animated');
        }

        // Set viewBox if not present
        if (!svgElement.getAttribute('viewBox')) {
          svgElement.setAttribute('viewBox', '0 0 500 500');
        }

        this.setState({
          svgHtml: svgElement.outerHTML,
          loading: false
        });
      } else {
        throw new Error('Invalid SVG content');
      }
    } catch (error) {
      console.error('Error loading SVG:', error);
      this.setState({
        error: error.message,
        loading: false
      });
    }
  }

  render() {
    const { className, animated, ...rest } = this.props;
    const { svgHtml, loading, error } = this.state;

    if (loading) {
      return <AspectRatioSkeleton aspectPercentage={66.7} />;
    }

    if (error) {
      return <div className="animated-svg-error">Failed to load SVG: {error}</div>;
    }

    return animated !== false && svgHtml ? (
      <div
        className={`animated-svg ${className || ''}`}
        ref={this.container}
        dangerouslySetInnerHTML={{
          __html: sanitize(svgHtml, {
            USE_PROFILES: { svg: true },
            ADD_TAGS: ['animate', 'animateTransform'],
            ADD_ATTR: ['viewbox', 'class']
          })
        }}
        {...rest}
      />
    ) : (
      <AspectRatioSkeleton aspectPercentage={66.7} />
    );
  }
}
