import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import AdviserInfoContactEntry from '../AdviserInfoContactEntry/AdviserInfoContactEntry';
import { formatPhoneNumber } from '../../../utils/user';
import { formatOpenUrl } from '../../../utils/url';
import AdviserInfoCardAbstract from '../AdviserInfoCardAbstract/AdviserInfoCardAbstract';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';

const FullWidthStyle = {
  width: '100%'
};

const PoweredByTypoStyle = {
  fontSize: '80%'
};

const AdviserInfoPageContact = props => {
  const { intl, adviserInfo, isIntoView } = props;

  const {
    phoneNumber,
    phoneNumber2,
    whatsapp,
    workEmail,
    wechatId,
    lineId,
    facebookLink,
    instagram,
    realLinkedinLink,
    linkedinLink
  } = adviserInfo || {};

  const goPhoneNumber = phoneNumber
    ? () => {
        window.open(`tel:${phoneNumber}`, '_blank');
      }
    : undefined;
  const goPhoneNumber2 = phoneNumber2
    ? () => {
        window.open(`tel:${phoneNumber2}`, '_blank');
      }
    : undefined;
  const goWhatsapp = whatsapp
    ? () => {
        window.open(`https://api.whatsapp.com/send?phone=${whatsapp.replace('+', '')}`, '_blank');
      }
    : undefined;
  const goWorkEmail = workEmail
    ? () => {
        window.open(`mailto:${workEmail}`, '_blank');
      }
    : undefined;
  const goFacebook = () => {
    window.open(formatOpenUrl(facebookLink), '_blank');
  };

  const goInstagram = () => {
    window.open(`https://www.instagram.com/${instagram}`, '_blank');
  };

  const goLinkedin = () => {
    window.open(formatOpenUrl(realLinkedinLink), '_blank');
  };

  const goMyWebpage = () => {
    window.open(formatOpenUrl(linkedinLink), '_blank');
  };

  const onClickWechat = () => {
    navigator.clipboard.writeText(wechatId);
    toast.info(IconToast('info', intl.formatMessage({ id: 'wechat-msg-copied' })), { className: 'new-toast' });
  };

  const onClickLine = () => {
    navigator.clipboard.writeText(lineId);
    toast.info(IconToast('info', intl.formatMessage({ id: 'line-msg-copied' })), { className: 'new-toast' });
  };

  return (
    <AdviserInfoCardAbstract
      className="adviser-info-page-contact"
      title={intl.formatMessage({ id: 'adv-info-contact-title' })}
      adviserInfo={adviserInfo}
      contentHeight="calc(100% - 110px)"
      isIntoView={isIntoView}
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid item style={{ ...FullWidthStyle, flexGrow: 1, paddingBottom: 34 }} className="content-container">
          <Grid container direction="column" alignItems="flex-start" spacing={3}>
            <Grid item style={{ ...FullWidthStyle, flexGrow: 1 }}>
              <Grid container direction="column" alignItems="flex-start" spacing={2}>
                <Grid item style={FullWidthStyle}>
                  <AdviserInfoContactEntry
                    imgSrc="img/phone.svg"
                    alt="phone"
                    text={formatPhoneNumber(phoneNumber) || '-'}
                    textStyle={phoneNumber ? { textDecoration: 'underline' } : undefined}
                    onClick={goPhoneNumber}
                  />
                </Grid>

                {phoneNumber2 && (
                  <Grid item style={FullWidthStyle}>
                    <AdviserInfoContactEntry
                      imgSrc="img/phone.svg"
                      alt="phone"
                      text={formatPhoneNumber(phoneNumber2) || '-'}
                      textStyle={phoneNumber2 ? { textDecoration: 'underline' } : undefined}
                      onClick={goPhoneNumber2}
                    />
                  </Grid>
                )}

                <Grid item style={FullWidthStyle}>
                  <AdviserInfoContactEntry
                    imgSrc="img/whatsapp.svg"
                    alt="whatsapp"
                    text={formatPhoneNumber(whatsapp) || '-'}
                    textStyle={whatsapp ? { textDecoration: 'underline' } : undefined}
                    onClick={goWhatsapp}
                  />
                </Grid>

                <Grid item style={FullWidthStyle}>
                  <AdviserInfoContactEntry
                    imgSrc="img/email.svg"
                    alt="email"
                    text={workEmail || '-'}
                    textStyle={workEmail ? { textDecoration: 'underline' } : undefined}
                    onClick={goWorkEmail}
                  />
                </Grid>

                <Grid item style={FullWidthStyle}>
                  <AdviserInfoContactEntry
                    imgSrc="img/wechat.svg"
                    alt="wechat"
                    text={wechatId || '-'}
                    textStyle={wechatId ? { textDecoration: 'underline' } : undefined}
                    type="wechat"
                    adviserInfo={adviserInfo}
                    onClick={onClickWechat}
                  />
                </Grid>

                <Grid item style={FullWidthStyle}>
                  <AdviserInfoContactEntry
                    imgSrc="img/line.svg"
                    alt="line"
                    text={lineId || '-'}
                    textStyle={lineId ? { textDecoration: 'underline' } : undefined}
                    onClick={onClickLine}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={FullWidthStyle}>
              <Grid container direction="column" alignItems="flex-start" spacing={1}>
                <Grid item>
                  <Typography display="inline" className="emoji">
                    📱
                  </Typography>
                  <Typography display="inline" className="title">
                    {intl.formatMessage({ id: 'social-platform' })}
                  </Typography>
                </Grid>
                {facebookLink || instagram || realLinkedinLink || linkedinLink ? (
                  <Fragment>
                    {facebookLink && (
                      <Grid item style={FullWidthStyle}>
                        <AdviserInfoContactEntry
                          imgSrc="img/facebook.svg"
                          alt="facebook"
                          text="Facebook"
                          textStyle={{ textDecoration: 'underline' }}
                          onClick={goFacebook}
                        />
                      </Grid>
                    )}
                    {instagram && (
                      <Grid item style={FullWidthStyle}>
                        <AdviserInfoContactEntry
                          imgSrc="img/instagram.svg"
                          alt="instagram"
                          text={`IG: ${instagram}`}
                          textStyle={{ textDecoration: 'underline' }}
                          onClick={goInstagram}
                        />
                      </Grid>
                    )}
                    {realLinkedinLink && (
                      <Grid item style={FullWidthStyle}>
                        <AdviserInfoContactEntry
                          imgSrc="img/linkedin.svg"
                          alt="linkedin"
                          text="Linkedin"
                          textStyle={{ textDecoration: 'underline' }}
                          onClick={goLinkedin}
                        />
                      </Grid>
                    )}
                    {linkedinLink && (
                      <Grid item style={FullWidthStyle}>
                        <AdviserInfoContactEntry
                          imgSrc="img/web.svg"
                          alt="my-webpage"
                          text={linkedinLink}
                          textStyle={{ textDecoration: 'underline' }}
                          onClick={goMyWebpage}
                        />
                      </Grid>
                    )}
                  </Fragment>
                ) : (
                  <Typography>-</Typography>
                )}
              </Grid>
            </Grid>

            <Grid item style={FullWidthStyle}>
              <Grid container alignItems="center" justify="flex-end" spacing={1}>
                <Grid item>
                  <Typography style={PoweredByTypoStyle}>Powered By</Typography>
                </Grid>
                <Grid item>
                  <img src="img/logo.png" alt="portfoplus" height={25} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdviserInfoCardAbstract>
  );
};

export default injectIntl(AdviserInfoPageContact);
