import React, { Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Grid, Button, IconButton, makeStyles } from '@material-ui/core';
import { toggleDialog, toggleControl } from '../../../../store/control/duck';
import StyledTextField from '../../../StyledTextField/StyledTextField';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment';
import { scrollAndFocusTextareaEnd } from '../../../../utils/dom';
import QuickNotesDialog from '../../QuickNotesDialog/QuickNotesDialog';
import RecordMeetingRemarkButton from '../../../RecordMeetingRemarkButton/RecordMeetingRemarkButton';
import { getRemarkDateLine } from '../../../../utils/client';
import { GPTProductRecommendationButton } from '../../ChatGPTPresetDrawer/GPTProductRecommendation/GPTProductRecommendation';
import CampaignCodeControl from '../../../CampaignCodeControl/container';

const useStyles = makeStyles(theme => ({
  labelRoot: {
    color: '#333'
  }
}));

const RemarkControl = props => {
  const {
    intl,
    remark,
    onChange,
    toggleDialog,
    toggleControl,
    fullHeight,
    InputProp,
    InputLabelProps,
    noLabelColorClass,
    recordMeetingRemarkButtonRef,
    enableSpeechToText,
    meetingDialog,
    setLoading,
    ...rest
  } = props;

  const inputRef = useRef(null);
  const gptRecommendationRef = useRef(null);
  const classes = useStyles(props);
  const { style: InputPropsStyle, ...restInputProps } = InputProp || {};
  const { style: InputLabelPropsStyle, ...restInputLabelProps } = InputLabelProps || {};

  const appendRemark = text => {
    onChange({
      target: {
        value: `${remark}${remark ? `\n${!(remark || '').endsWith('\n') ? '\n' : ''}` : ''}${text}`
      }
    });
  };

  const appendVoiceRemark = text => {
    toggleDialog('meeting', {
      ...meetingDialog,
      remark: `${remark}${remark ? `\n${!(remark || '').endsWith('\n') ? '\n' : ''}` : ''}${text}`
    });
  };

  const expand = () => {
    toggleDialog('quickNotes', {
      client: meetingDialog?.client,
      remark: remark,
      onSubmit: value => onChange({ target: { value: value } })
    });
  };

  const addDate = event => {
    appendRemark(`📅 ${moment().format('DD-MMM-YYYY')}\n`);
    setTimeout(() => {
      if (inputRef.current) {
        const textareaElem = inputRef.current.querySelector('textarea');
        scrollAndFocusTextareaEnd(textareaElem);
      }
    }, 150);
  };

  return (
    <Fragment>
      <QuickNotesDialog />

      <StyledTextField
        label={
          <Grid container alignItems="center" spacing={1} onClick={event => event.preventDefault()}>
            <Grid item>{intl.formatMessage({ id: 'Quick Notes' })}</Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={addDate}
                    style={{
                      padding: "0px 4px",
                      fontSize: 12,
                      marginLeft: 4,
                      marginRight: 4,
                      minWidth: 'unset'
                    }}
                  >
                    {intl.formatMessage({ id: 'add-date' })}
                  </Button>
                  {enableSpeechToText &&
                    <RecordMeetingRemarkButton
                      ButtonProps={{
                        color: 'primary',
                        size: 'small',
                        style: { pointerEvents: 'auto' }
                      }}
                      IconProps={{
                        style: { fontSize: 20 }
                      }}
                      onResult={result => appendVoiceRemark(`${getRemarkDateLine()}${result}`)}
                      ref={recordMeetingRemarkButtonRef}
                    />}

                  <CampaignCodeControl targetCampaignCode={["FEATURE_PRODUCT_RECOMMENDATION_CHUBB","FEATURE_GPT"]}>
                  {
                    meetingDialog?.client &&
                    <GPTProductRecommendationButton
                      ref={gptRecommendationRef}
                      useLoadingBackdrop={false}
                      loadingControl={setLoading}
                      toggleControl={toggleControl}
                      client={meetingDialog.client}
                      language={intl.locale}
                    >
                      <Button
                        onClick={() => gptRecommendationRef.current.handleCheckAndSend?.()}
                        color="primary"
                        variant="outlined"
                        style={{
                          padding: "0px 4px",
                          fontSize: 12,
                          marginLeft: 4,
                          marginRight: 4,
                          minWidth: 'unset'
                        }}
                      ><img
                          style={{
                            width: "14px",
                            height: "14px",
                            paddingRight: "2px"

                          }}
                          src="/img/aiGPT.svg" />
                        {intl.formatMessage({ id: 'gpt-product-recommendation-client-entry-title' })}
                      </Button>
                    </GPTProductRecommendationButton>
                  }
                  </CampaignCodeControl>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={expand} style={{ pointerEvents: 'auto', color: 'grey' }}>
                    <OpenInNewIcon style={{ fontSize: 20 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        InputProps={{
          style: {
            paddingTop: 12,
            ...InputPropsStyle
          },
          ...restInputProps
        }}
        InputLabelProps={{
          classes: {
            root: !noLabelColorClass ? classes.labelRoot : undefined
          },
          style: {
            zIndex: 1,
            width: '100%',
            ...InputLabelPropsStyle
          },
          ...restInputLabelProps
        }}
        inputProps={{
          style: {
            height: fullHeight ? '100%' : undefined,
            overflow: fullHeight ? 'auto' : undefined
          }
        }}
        value={remark}
        onChange={onChange}
        multiline={true}
        rows={!fullHeight ? 3 : undefined}
        {...rest}
        ref={inputRef}
      />
    </Fragment>

    // <div className="form-group">
    //   <textarea
    //     type="text"
    //     name="remark"
    //     value={remark}
    //     onChange={onChange}
    //     required="required"
    //     rows="3"
    //     style={{ marginTop: '8px' }}
    //   />
    //   <label className="control-label" htmlFor="input" style={{ top: }}>
    //       <Grid container alignItems="center" spacing={1}>
    //           <Grid item>
    //               {intl.formatMessage({ id: 'Quick Notes' })}
    //           </Grid>
    //           <Grid item>
    //               <IconButton size="small" onClick={expand} style={{ pointerEvents: 'auto' }}>
    //                   <AspectRatioIcon color="primary" />
    //               </IconButton>
    //           </Grid>
    //       </Grid>
    //   </label>
    //   <i className="bar" />
    // </div>
  );
};

const container = connect(state => ({}),
  {
    toggleDialog,
    toggleControl
  })
  (RemarkControl);

export default injectIntl(container);
