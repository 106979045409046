import React, { Fragment } from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import MyDetailsProfilePicture from "../../../views/Advisor/MyDetails/MyDetailsProfile/MyDetailsProfilePicture/MyDetailsProfilePicture.js";
import { injectIntl } from "react-intl";
import { getUserInitial } from "../../../utils/user.js";
import {
  THEME_COLOR_PALETTES,
  getValidThemeColorOption
} from "../../../constants/my-details.js";
import {
  NFCPersistentAlert,
  LinkerProIcon,
  ActionButtonBar,
  UpgradeButton
} from "../Components/Components.js";

function XS({
  userDetails /** @type {UserDetails} */,
  intl,
  onEditProfile,
  onViewProfile,
  onUpgrade,
  showNoNFCAlert,
  showLinkerProIcon,
  onCardLinkAlertClick
}) {
  const cardStyle = {
    boxShadow: "0px 2px 11.5px rgba(85, 85, 90, 0.16)",
    borderRadius: "16px",
    backgroundColor: "#FFFFFF",
    gap: "10px",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "16px"
  };
  const themeColor = getValidThemeColorOption(userDetails);
  const initial = getUserInitial(userDetails);

  return (
    <Fragment>
      <Box style={{ height: "16px", width: "16px" }} />
      <Grid container spacing={2} display="flex" alignItems="center">
        <Grid item>
          {
            <NFCPersistentAlert
              show={showNoNFCAlert}
              intl={intl}
              onCardLinkAlertClick={onCardLinkAlertClick}
            />
          }
        </Grid>
      </Grid>
      <Box>
        <Box style={{ marginBottom: "-36px", marginTop: "24px" }}>
          <MyDetailsProfilePicture
            width={110}
            initial={initial}
            src={userDetails.avatar}
            borderColor={THEME_COLOR_PALETTES[themeColor || "blue"][1]}
          />
        </Box>

        <Box style={cardStyle}>
          <Box
            style={{ width: "100%", display: "flex", justifyContent: "right" }}
          >
            <UpgradeButton
              msg={intl.formatMessage({
                id: "linker-upgrade-cta"
              })}
              onUpgrade={onUpgrade}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            style={{
              gap: "5px"
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: "600",
                color: "black",
                wordBreak: "break-all",
                overflow: "wrap"
              }}
            >
              {userDetails.name}
            </Typography>
            <LinkerProIcon showLinkerProIcon={showLinkerProIcon} />
          </Box>
          <Typography
            variant="body1"
            style={{
              color: "#9F9F9F",
              lineHeight: "1.5rem",
              width: "100%",
              wordBreak: "break-all",
              overflow: "wrap"
            }}
          >
            {userDetails.email}
          </Typography>
          <Box style={{ height: "6px", height: "6px" }}></Box>
          <ActionButtonBar
            intl={intl}
            onEditProfile={onEditProfile}
            onViewProfile={onViewProfile}
          />
        </Box>
      </Box>
    </Fragment>
  );
}

export const LinkerAdviserHeaderXS = injectIntl(XS);
