import React, { useState, useEffect, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import FunctionCardsView from '../../../components/FunctionCard/FunctionCardsView';
import { HotLabel } from '../../AdviserHomePage/HotLabel/HotLabel';
import Badge from '@material-ui/core/Badge';
import { getNewReferralCount } from '../../../store/referral/duck';
import Grid from '@material-ui/core/Grid';
import './ClientAcquisition.scss';
import { Typography } from '@material-ui/core';
import { tracking } from '../../../utils/tracking';
import { toggleDialog } from '../../../store/control/duck';
import GridButtonLayout from '../../../components/GridButtonLayout/GridButtonLayout';
import CornerTriangle from '../../../components/CornerTriangle/CornerTriangle';
import NFCAutoGenerateDialog from '../Referral/partials/NFCAutoGenerateButton/NFCAutoGenerateDialog/NFCAutoGenerateDialog';
import { shouldEnableNFCAutoGen } from '../../../utils/user';
import { UserSubscription } from '../../../utils/user-subscription';
import SubscriptionDialog from '../../Subscription/SubscriptionDialog/SubscriptionDialog';

const isDevelopment = process.env.NODE_ENV !== 'production';
const ClientAcquisition = props => {
  const {
    intl,
    history,
    userDetails,
    stripePromise,
    teamCampaignCodes,
    newReferralCount,
    getNewReferralCount,
    toggleDialog
  } = props;
  const [nfcDialogOpen, setNfcDialogOpen] = useState(false);
  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);

  const userSubscriptionO = new UserSubscription(props.userSubscription);
  const currentCoreSubscription = userSubscriptionO.getCurrentCoreSubscription();
  const shouldDisableFunctions = !userSubscriptionO.containVaildProSubscription();

  function openSubscriptionDialog() {
    setSubscriptionDialogOpen(true);
  }

  const shouldShowNFC = shouldEnableNFCAutoGen({ user: userDetails, teamCampaignCodes: teamCampaignCodes });

  const onCloseNfcDialog = () => setNfcDialogOpen(false);

  // console.log('shouldDisableFunctions', shouldDisableFunctions);
  const getItems = ({ intl, history }) => [
    {
      color: '#5fc3d2',
      imgSrc: 'img/icons/qr_code_active_color.svg',
      imgAlt: 'qr-code',
      text: intl.formatMessage({ id: 'my-qr' }),
      subtitle: intl.formatMessage({ id: 'my-qr-desc' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('open refer QRCode');
          toggleDialog('myQR', {});
        }
      }
    },
    {
      color: '#3999f8',
      imgSrc: 'img/icons/e_card_active_color.svg',
      imgAlt: 'profile+',
      text: <img src="img/profile-plus.png" alt="profile+" height="20" />,
      subtitle: intl.formatMessage({ id: 'ca-aip-desc' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on Profile+ - Lead Generation');
          history.push('/profile-plus');
        }
      },
      extras: (
        <HotLabel
          text={intl.formatMessage({ id: 'hot' })}
          style={{ zIndex: 1, left: 'unset', right: 0, borderRadius: '4px 0 0 4px' }}
        />
      )
    },
    {
      color: '#2260a5',
      imgSrc: 'img/icons/wifi_active_color.svg',
      imgAlt: 'nfc-card',
      text: intl.formatMessage({ id: 'nfc-card' }),
      subtitle: intl.formatMessage({ id: 'nfc-card-desc' }),
      onClick: nfcCard,
      extras: (
        <Fragment>
          <CornerTriangle text="COT" backgroundColor="#ffc000" containerStyle={{ left: 6, borderRadius: 0 }} />
          <HotLabel
            text={intl.formatMessage({ id: 'hot' })}
            style={{ zIndex: 1, left: 'unset', right: 0, borderRadius: '4px 0 0 4px' }}
          />
        </Fragment>
      )
    },
    {
      color: '#453bd1',
      imgSrc: 'img/icons/money_hand_active_color.svg',
      imgAlt: 'new-clients',
      text: (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography className="title" align="left">
              {intl.formatMessage({ id: 'New-Clients' })}
            </Typography>
          </Grid>
          {!!newReferralCount && (
            <Grid item>
              <Badge className="referral-count" badgeContent={newReferralCount} color="error" />
            </Grid>
          )}
        </Grid>
      ),
      subtitle: intl.formatMessage({ id: 'ca-new-client-desc' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on referral listing - Lead Generation');
          history.push({ pathname: '/referral_records' });
        }
      },
      blocking: !shouldDisableFunctions
    },
    isDevelopment && {
      //hide from production
      color: '#3966f8',
      imgSrc: 'img/icons/team_active_color.svg',
      imgAlt: 'client-matching',
      text: intl.formatMessage({ id: 'client-matching' }),
      subtitle: intl.formatMessage({ id: 'ca-client-matching-desc' }),
      disabled: true,
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on client matching- Lead Generation ');
          history.push('/client-matching');
        }
      }
    }
  ];

  const nfcCard = () => {
    if (shouldDisableFunctions) {
      openSubscriptionDialog();
    } else {
      tracking('Click on NFC Card - Lead Generation');
      history.push('/nfc-card');
    }
  };

  const whatsappWizard = () => {
    if (shouldDisableFunctions) {
      openSubscriptionDialog();
    } else {
      tracking('Click on whatsapp wizard - Lead Generation');
      history.push('/whatsapp-wizard');
    }
  };

  useEffect(() => {
    getNewReferralCount();
  }, [getNewReferralCount]);

  return (
    <Fragment>
      <SubscriptionDialog
        intl={intl}
        userDetails={userDetails}
        stripePromise={stripePromise}
        open={subscriptionDialogOpen}
        onClose={() => setSubscriptionDialogOpen(false)}
        type="upgrade"
        currentSubscription={currentCoreSubscription}
        trialing={false}
        disableCoupon={true}
        defaultCoupon=""
      />

      <NFCAutoGenerateDialog open={nfcDialogOpen} onClose={onCloseNfcDialog} />
      <FunctionCardsView
        className="client-acquisition"
        getItems={getItems}
        append={
          <GridButtonLayout
            items={[
              shouldShowNFC && {
                image: <img src="img/NFCicon.png" alt="nfc-card-settings" />,
                text: intl.formatMessage({ id: 'nfc-card-settings' }),
                onClick: () => {
                  setNfcDialogOpen(true);
                }
              },
              {
                image: <img src="img/showqrcode.png" alt="whatsapp-wizard" />,
                text: intl.formatMessage({ id: 'whatsapp-wizard' }),
                onClick: whatsappWizard
              }
            ]}
          />
        }
      />
    </Fragment>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    newReferralCount: state.referral.newReferralCount,
    teamCampaignCodes: state.functions.campaignCodes,
    userSubscription: state.userSubscription.userSubscription
  }),
  {
    getNewReferralCount,
    toggleDialog
  }
)(ClientAcquisition);

export default injectIntl(container);
