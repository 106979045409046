import Rx from 'rxjs/Rx';
import { Record } from 'immutable';
import { combineEpics } from 'redux-observable';
import { assign } from 'lodash';
import { INIT, LOADING, ERROR } from '../../constants/phase';
import Config from '../../config';
import * as api from './api';
import { getTeams, getUserTeamLogoNews, UPDATE_TEAM_BASIC } from '../team/duck';
import { UPDATE_ANALYSIS_INPUTS, updateAnalysisInputs } from '../analysis/duck';
import { GET_CLIENT, getClient } from '../client/duck';
import {
  checkUserSubscriptionExpiry,
  getAdviserSubscriptionNickname,
  UPDATE_SUBSCRIPTIONS
} from '../userSubscription/duck';
import { LOCALE_SELECTED } from '../locale/action';
import { checkAssistantDataExist } from '../../utils/assistant';
import { getNewReferralCount } from '../referral/duck';
import { adviserBusinessAnalysis, fetchAdviserBusiness, fetchFigureAdviser } from '../adviserDashboard/duck';
import { getReminderBadgeCount } from '../reminder/duck';
import _ from 'lodash';
import { getShareFromData, getShareMeDocuments } from '../share/duck';
import { fetchMePolicy, fetchPolicy } from '../policy/duck';
import { getFigure } from '../figure/duck';
import { isAdviserType, shouldShowUserFirstGuideline } from '../../utils/user';
import { getCurrencyExchangeRate } from '../../utils/currencyExchange';
import { jsonParse } from '../../utils/json';
import { defaultFilters } from '../../constants/adviserCustomFilters';
import { getNotificationCenterList } from '../notificationCenter/duck';
import { getAgeFromDob } from '../../utils/date';
import { AUTHENTICATE_USER_SUCCESS, RESET_AUTH } from '../auth/duck';
import { getRegion } from '../../utils/region';
import { getProFlagFromLocalStorage, saveProFlagToLocalStorage } from '../../utils/user-subscription';
import { toast } from 'react-toastify';
import { history } from '../../views/App/history';
import { clearCookies } from '../../utils/native/http';
import { checkAndRequestPermissions } from '../../utils/native/push-notification';
import { TOGGLE_DIALOG } from '../control/duck';
import NativeOrWeb from '../../utils/native';
import { AUTO_TRIED_BIOMETRIC_LOGIN_SESSION_KEY } from '../../constants/session';
import { getFunctions } from '../functions/duck';
import { getUserData } from '../userData/duck';
import { resetGPTDisclaimer, resetGPTQuota } from '../../utils/openai';
import moment from 'moment';
import { Preferences } from '@capacitor/preferences';

/***********************************
 * Action Types
 ***********/
export const GET_FIGURE_USER = 'portfoplus/user/GET_FIGURE_USER';
export const GET_FIGURE_USER_SUCCESS = 'portfoplus/user/GET_FIGURE_USER_SUCCESS';
export const GET_FIGURE_USER_ERROR = 'portfoplus/user/GET_FIGURE_USER_ERROR';

export const LOGIN_USER = 'portfoplus/user/LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'portfoplus/user/LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'portfoplus/user/LOGIN_USER_ERROR';

export const LOGOUT_USER = 'portfoplus/user/LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'portfoplus/user/LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_SUCCESS_KEEP = 'portfoplus/user/LOGOUT_USER_SUCCESS_KEEP';
export const LOGOUT_USER_ERROR = 'portfoplus/user/LOGOUT_USER_ERROR';

export const CREATE_USER = 'portfoplus/user/CREATE_USER';
export const CREATE_USER_SUCCESS = 'portfoplus/user/CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'portfoplus/user/CREATE_USER_ERROR';

export const APPROVE_USER = 'portfoplus/user/APPROVE_USER';
export const APPROVE_USER_SUCCESS = 'portfoplus/user/APPROVE_USER_SUCCESS';
export const APPROVE_USER_ERROR = 'portfoplus/user/APPROVE_USER_ERROR';

export const VERIFY_EMAIL = 'portfoplus/user/VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'portfoplus/user/VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_ERROR = 'portfoplus/user/VERIFY_EMAIL_ERROR';

export const UPDATE_USER = 'portfoplus/user/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'portfoplus/user/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'portfoplus/user/UPDATE_USER_ERROR';

export const CHANGE_PASSWORD = 'portfoplus/user/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'portfoplus/user/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'portfoplus/user/CHANGE_PASSWORD_ERROR';

export const FORGOT_PASSWORD = 'portfoplus/user/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'portfoplus/user/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'portfoplus/user/FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD = 'portfoplus/user/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'portfoplus/user/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'portfoplus/user/RESET_PASSWORD_ERROR';

export const GET_USER = 'portfoplus/user/GET_USER';
export const GET_USER_SUCCESS = 'portfoplus/user/GET_USER_SUCCESS';
export const GET_USER_ERROR = 'portfoplus/user/GET_USER_ERROR';

export const GET_ADVISER = 'portfoplus/user/GET_ADVISER';
export const GET_ADVISER_SUCCESS = 'portfoplus/user/GET_ADVISER_SUCCESS';
export const GET_ADVISER_ERROR = 'portfoplus/user/GET_ADVISER_ERROR';

export const CURRENCY_EXCHANGE_RATE = 'portfoplus/user/CURRENCY_EXCHANGE_RATE';
export const CURRENCY_EXCHANGE_RATE_SUCCESS = 'portfoplus/user/CURRENCY_EXCHANGE_RATE_SUCCESS';
export const CURRENCY_EXCHANGE_RATE_ERROR = 'portfoplus/user/CURRENCY_EXCHANGE_RATE_ERROR';

export const DEFAULT_MESSAGES = 'portfoplus/user/DEFAULT_MESSAGES';
export const DEFAULT_MESSAGES_SUCCESS = 'portfoplus/user/DEFAULT_MESSAGES_SUCCESS';
export const DEFAULT_MESSAGES_ERROR = 'portfoplus/user/DEFAULT_MESSAGES_SUCCESS';

export const INIT_PHASE = 'portfoplus/user/INIT_PHASE';

export const INIT_PHASE_EMAIL = 'portfoplus/user/INIT_PHASE_EMAIL';

export const GET_NOTIFICATIONS = 'portfoplus/client/GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'portfoplus/client/GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERROR = 'portfoplus/client/GET_NOTIFICATIONS_ERROR';

export const REQUIRE_EXPLICIT_LOGIN = 'portfoplus/user/REQUIRE_EXPLICIT_LOGIN';

export const UPDATE_TODO = 'portfoplus/adviser/UPDATE_TODO';

export const RESET_STATE = 'portfoplus/user/RESET_STATE';

export const UPDATE_LOGIN_FETCHED = 'UPDATE_LOGIN_FETCHED';

export const UPDATE_TEMPLATE = 'portfoplus/adviser/UPDATE_TEMPLATE';

export const UPDATE_DASH_HELP_STATE = 'portfoplus/user/UPDATE_DASH_HELP_STATE';

const UPDATE_FIRST_GUIDELINE = 'portfoplus/user/newClientGuideline/UPDATE_FIRST_GUIDELINE';

export const UPDATE_ADVISER_CUSTOM_FILTERS = 'portfoplus/user/adviser/UPDATE_ADVISER_CUSTOM_FILTERS';
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  //                 but it will be reset below to what is in localStorage, unless a value
  //                 is passed in when the object is instanciated
  figureUserPhase: INIT,
  figureUser: null,
  resetPasswordPhase: INIT,
  forgotPasswordPhase: INIT,
  loginPhase: INIT,
  createPhase: INIT,
  approvePhase: INIT,
  verifyEmailPhase: INIT,
  updatePhase: INIT,
  changePasswordPhase: INIT,
  getUserPhase: INIT,
  getAdviserPhase: INIT,
  userDetails: {},
  connectedAdvisers: [],
  exchangeRate: {},
  defaultMessage: {},
  users: null,
  createMessage: null,
  createMessageExtras: undefined,
  loginMessage: null,
  loginMessageExtras: undefined,
  forgotPasswordMessageExtras: undefined,
  updateMessageExtras: undefined,
  message: null,
  error: null,
  isSubmitting: false,
  requireExplicitLogin: false,
  notifications: [],
  adviserTodo: {},
  loginFetched: false,
  userFirstGuideline: false,
  adviserTemplate: [],
  shownDashHelp: true,
  firstGuidelineRan: true,
  adviserCustomFilters: defaultFilters
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    super(desiredValues);
  }
}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function (state = new InitialState(), action = {}) {
  const getLogoutState = () => {
    const { payload } = action;
    const restoreAutoTriedBiometricLogin = sessionStorage.getItem(AUTO_TRIED_BIOMETRIC_LOGIN_SESSION_KEY);
    sessionStorage.clear();
    if (restoreAutoTriedBiometricLogin) {
      sessionStorage.setItem(AUTO_TRIED_BIOMETRIC_LOGIN_SESSION_KEY, restoreAutoTriedBiometricLogin);
    }
    return new InitialState().set('error', payload.error);
  };

  switch (action.type) {
    case GET_FIGURE_USER: {
      return state
        .set('figureUser', null)
        .set('figureUserPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_FIGURE_USER_SUCCESS: {
      const { payload } = action;
      return state
        .set('figureUser', payload.data)
        .set('figureUserPhase', payload.success)
        .set('error', null);
    }

    case GET_FIGURE_USER_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('figureUserPhase', error);
    }

    case LOGIN_USER: {
      return state
        .set('loginPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case LOGIN_USER_SUCCESS: {
      const { payload } = action;
      NativeOrWeb.saveAccessTokenCookie()
        .then()
        .catch();
      return state
        .set('userDetails', payload.data)
        .set('loginPhase', payload.success)
        .set('loginMessage', payload.message)
        .set('loginMessageExtras', payload.extras)
        .set('error', null)
        .set('isSubmitting', false)
        .set('requireExplicitLogin', false)
        .set('userFirstGuideline', shouldShowUserFirstGuideline(payload.data));
    }

    case REQUIRE_EXPLICIT_LOGIN: {
      return state.set('requireExplicitLogin', true);
    }

    case LOGIN_USER_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('loginPhase', ERROR)
        .set('loginMessageExtras', undefined);
    }

    case LOGOUT_USER: {
      return state;
    }

    case LOGOUT_USER_SUCCESS: {
      return getLogoutState();
    }

    case LOGOUT_USER_SUCCESS_KEEP: {
      return getLogoutState();
    }

    case LOGOUT_USER_ERROR: {
      return state;
    }

    case CREATE_USER: {
      return state
        .set('createPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case CREATE_USER_SUCCESS: {
      const { payload } = action;
      return state
        .set('createPhase', payload.success)
        .set('userDetails', payload.data)
        .set('createMessage', payload.message)
        .set('createMessageExtras', payload.extras)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case CREATE_USER_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('createPhase', ERROR);
    }

    case APPROVE_USER: {
      return state
        .set('approvePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case APPROVE_USER_SUCCESS: {
      const { payload } = action;
      return state
        .set('approvePhase', payload.success)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case APPROVE_USER_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('approvePhase', ERROR);
    }
    case VERIFY_EMAIL: {
      return state
        .set('verifyEmailPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case VERIFY_EMAIL_SUCCESS: {
      const { payload } = action;
      return state
        .set('verifyEmailPhase', payload.success)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case VERIFY_EMAIL_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('verifyEmailPhase', ERROR);
    }

    case GET_USER: {
      return state
        .set('updatePhase', INIT)
        .set('getUserPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_USER_SUCCESS: {
      const { payload } = action;
      // localStorage.setItem('user', JSON.stringify(payload.data));
      return state
        .set('userDetails', payload.data)
        .set('getUserPhase', payload.success)
        .set('adviserTodo', {
          activeAdviserTodo: payload.data.activeAdviserTodo ? payload.data.activeAdviserTodo : [],
          completedAdviserTodo: payload.data.completedAdviserTodo ? payload.data.completedAdviserTodo : [],
          importantAdviserTodo: payload.data.importantAdviserTodo ? payload.data.importantAdviserTodo : []
        })
        .set('adviserTemplate', payload.data.adviserTemplate ? payload.data.adviserTemplate : [])
        .set(
          'adviserCustomFilters',
          payload.data.adviserCustomFilters && payload.data.adviserCustomFilters.length
            ? payload.data.adviserCustomFilters
            : state.adviserCustomFilters
        )
        .set('error', null)
        .set('userFirstGuideline', shouldShowUserFirstGuideline(payload.data));
    }

    case GET_USER_ERROR: {
      const { error } = action.payload;
      //localStorage.clear();
      return state.set('error', error).set('getUserPhase', error);
    }

    case GET_ADVISER: {
      return state
        .set('getAdviserPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_ADVISER_SUCCESS: {
      const { payload } = action;
      return state
        .set('connectedAdvisers', payload.data)
        .set('getAdviserPhase', payload.success)
        .set('error', null);
    }

    case GET_ADVISER_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('getAdviserPhase', error);
    }

    case UPDATE_USER: {
      return state
        .set('updatePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case UPDATE_USER_SUCCESS: {
      const { payload } = action;
      let updatedState = state
        .set('updatePhase', payload.success)
        .set('message', payload.message)
        .set('updateMessageExtras', payload.extras)
        .set('error', null)
        .set('isSubmitting', false);

      if (payload.success) {
        updatedState = updatedState.set('userDetails', payload.data);
      }

      return updatedState;
    }

    case UPDATE_USER_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('updatePhase', ERROR)
        .set('updateMessageExtras', undefined);
    }

    case CHANGE_PASSWORD: {
      return state
        .set('changePasswordPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case CHANGE_PASSWORD_SUCCESS: {
      const { payload } = action;
      return state
        .set('changePasswordPhase', payload.success)
        .set('message', payload.message)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case CHANGE_PASSWORD_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('changePasswordPhase', ERROR);
    }

    case FORGOT_PASSWORD: {
      return state
        .set('forgotPasswordPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case FORGOT_PASSWORD_SUCCESS: {
      const { payload } = action;
      return state
        .set('forgotPasswordPhase', payload.success)
        .set('message', payload.message)
        .set('forgotPasswordMessageExtras', payload.extras)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case FORGOT_PASSWORD_ERROR: {
      const { payload } = action;
      return state
        .set('error', payload.error)
        .set('forgotPasswordPhase', ERROR)
        .set('forgotPasswordMessageExtras', undefined);
    }

    case RESET_PASSWORD: {
      return state
        .set('resetPasswordPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case RESET_PASSWORD_SUCCESS: {
      const { payload } = action;
      return state
        .set('resetPasswordPhase', payload.success)
        .set('message', payload.message)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case RESET_PASSWORD_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('resetPasswordPhase', ERROR);
    }

    case CURRENCY_EXCHANGE_RATE: {
      return state.set('error', null).set('isSubmitting', true);
    }

    case CURRENCY_EXCHANGE_RATE_SUCCESS: {
      const { payload } = action;
      getCurrencyExchangeRate(payload.data);
      return state.set('exchangeRate', payload.data).set('error', null);
    }

    case CURRENCY_EXCHANGE_RATE_ERROR: {
      const { error } = action.payload;
      return state.set('error', error);
    }

    case DEFAULT_MESSAGES: {
      return state.set('error', null).set('isSubmitting', true);
    }

    case DEFAULT_MESSAGES_SUCCESS: {
      const { payload } = action;
      return state.set('defaultMessage', payload.data).set('error', null);
    }

    case DEFAULT_MESSAGES_ERROR: {
      const { error } = action.payload;
      return state.set('error', error);
    }

    case INIT_PHASE: {
      return state.set('updatePhase', INIT);
    }

    case INIT_PHASE_EMAIL: {
      return state.set('approvePhase', INIT);
    }

    case GET_NOTIFICATIONS: {
      return state.set('error', null).set('isSubmitting', true);
    }

    case GET_NOTIFICATIONS_SUCCESS: {
      const { payload } = action;
      return state
        .set('notifications', payload.data)
        .set('message', payload.success)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case GET_NOTIFICATIONS_ERROR: {
      const { payload } = action;
      return state
        .set('message', payload.success)
        .set('error', true)
        .set('isSubmitting', false);
    }

    case UPDATE_TODO: {
      const { payload } = action;
      return state.set('adviserTodo', payload.data);
    }

    case RESET_STATE: {
      return new InitialState();
    }

    case UPDATE_LOGIN_FETCHED: {
      const { payload } = action;
      return state.set('loginFetched', payload);
    }

    case UPDATE_TEMPLATE: {
      const { payload } = action;
      return state.set('adviserTemplate', payload.data);
    }

    case UPDATE_DASH_HELP_STATE: {
      return state.set('shownDashHelp', action.payload);
    }

    case UPDATE_FIRST_GUIDELINE: {
      return state.set('firstGuidelineRan', action.payload);
    }

    case UPDATE_ADVISER_CUSTOM_FILTERS: {
      const { payload } = action;
      return state.set('adviserCustomFilters', payload.data);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Selectors
 ***********/

export const isAdmin = ({ user }) =>
  user.roles.some(role => {
    return role === 'admin';
  });

export const isSuperAdmin = ({ user }) =>
  user.roles.some(role => {
    return role === '1';
  });

/***********************************
 * Action Creators
 ***********/

export const resetUserStore = () => ({ type: null });

export const getFigureUser = () => ({
  type: GET_FIGURE_USER
});

export const loginUser = credentials => {
  return {
    type: LOGIN_USER,
    payload: credentials
  };
};

export const createUser = data => ({
  type: CREATE_USER,
  payload: { data }
});

export const approveUser = data => ({
  type: APPROVE_USER,
  payload: { data }
});

export const verifyEmail = data => ({
  type: VERIFY_EMAIL,
  payload: { data }
});

export const updateUserDetails = data => ({
  type: UPDATE_USER,
  payload: { data }
});

export const changePassword = data => ({
  type: CHANGE_PASSWORD,
  payload: { data }
});

export const forgotPassword = data => ({
  type: FORGOT_PASSWORD,
  payload: { data }
});

export const resetPassword = data => ({
  type: RESET_PASSWORD,
  payload: { data }
});

export const getUser = () => ({
  type: GET_USER
});

export const getConnectedAdviser = () => ({
  type: GET_ADVISER
});

export const currencyExchangeRate = () => ({
  type: CURRENCY_EXCHANGE_RATE
});

export const defaultMessages = () => ({
  type: DEFAULT_MESSAGES
});

export const initPhase = () => ({
  type: INIT_PHASE
});

export const initPhaseEmail = () => ({
  type: INIT_PHASE_EMAIL
});

export const handleSignOut = (nextUrl, doNotRedirect, isKeep) => async (dispatch, getState) => {
  dispatch({ type: LOGOUT_USER });

  try {
    const logoutPromise = await api.logoutUser();

    await Promise.all([clearCookies(), NativeOrWeb.removeUserEmail(), NativeOrWeb.removeUserName()]);

    localStorage.removeItem('clientId');

    if (!doNotRedirect) {
      const store = getState();

      const isAdviser = isAdviserType(_.get(store, 'user.userDetails'));

      const searchObj = {
        region: getRegion()
      };

      if (isAdviser) {
        searchObj.pro = getProFlagFromLocalStorage();
      }

      const loginSearch = `?${new URLSearchParams(searchObj).toString()}`;

      toast.dismiss();

      history.push({
        pathname: '/login',
        search: loginSearch,
        nextUrl: nextUrl
      });
    }

    dispatch({ type: isKeep ? LOGOUT_USER_SUCCESS_KEEP : LOGOUT_USER_SUCCESS, payload: await logoutPromise });
  } catch (error) {
    dispatch({ type: LOGOUT_USER_ERROR, payload: { error } });
  }
};

export const getNotifications = () => ({
  type: GET_NOTIFICATIONS
});

export const resetState = () => ({
  type: RESET_STATE
});

export const setShownDashHelp = payload => ({
  type: UPDATE_DASH_HELP_STATE,
  payload
});

export const updateFirstGuidelineState = val => {
  return {
    type: UPDATE_FIRST_GUIDELINE,
    payload: val
  };
};

export const updateLoginFetched = value => ({ type: UPDATE_LOGIN_FETCHED, payload: value });

export const userDashboardFetch = (location, currentPortfolioId) => (dispatch, getState) => {
  if (_.get(location, 'state.showAdviserBlast')) {
    dispatch(getConnectedAdviser());
  }

  setTimeout(() => {
    const states = getState();
    const user = states.user.userDetails;
    const shareFromData = states.share.shareFromData;
    const getShareFromPhase = states.share.getShareFromPhase;

    if (_.get(user, 'userType') === 'User') {
      localStorage.removeItem('myDetails');
      localStorage.removeItem('clientId');
      if (shareFromData && shareFromData.length === 0 && getShareFromPhase !== 'error') {
        dispatch(getShareFromData());
      }
      if (currentPortfolioId === undefined || user._id === currentPortfolioId || currentPortfolioId === 'Me') {
        dispatch(fetchMePolicy());
      } else {
        dispatch(getShareMeDocuments({ id: currentPortfolioId }));
      }
    } else {
      const clientId = jsonParse(window.localStorage.getItem('clientId'));
      if (clientId) {
        dispatch(fetchPolicy({ clientId: clientId }));
      }
    }
  }, 300);
};

export const loginFetch = (userId, userType, location) => async (dispatch, getState) => {
  localStorage.removeItem('clientState');

  dispatch(getNotificationCenterList());

  if (userType === 'Adviser') {
    // get new referral count
    dispatch(getNewReferralCount());

    // get user subscription & check expiration
    dispatch(checkUserSubscriptionExpiry());

    // load business/progress data
    dispatch(fetchAdviserBusiness());
    dispatch(adviserBusinessAnalysis());
    dispatch(fetchFigureAdviser());
    dispatch(getClient());

    // get team details
    dispatch(getTeams());

    // load business/assistant data
    checkAssistantDataExist();

    await Promise.all([
      dispatch(getAdviserSubscriptionNickname(userId)),
      dispatch(getUserTeamLogoNews()),
      dispatch(getFigure()),
      dispatch(getFunctions()),
      dispatch(getUserData())
    ]);
  } else {
    NativeOrWeb.removeStorage('teamShortName')
      .then()
      .catch();

    saveProFlagToLocalStorage([]);

    // get reminder badge count
    dispatch(getReminderBadgeCount());

    // get connected adviser subscription nickname
    dispatch(getAdviserSubscriptionNickname());

    dispatch(userDashboardFetch(location));
  }

  dispatch(updateLoginFetched(true));
};

/***********************************
 * Epics
 ***********/
const getFigureUserEpic = action$ =>
  action$.ofType(GET_FIGURE_USER).mergeMap(() => {
    return Rx.Observable.fromPromise(api.getFigureUser())
      .flatMap(payload => [
        {
          type: GET_FIGURE_USER_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: GET_FIGURE_USER_ERROR,
          payload: { error }
        })
      );
  });

const setFirstLoginDate = async () => {
  if (NativeOrWeb.isNativePlatform()) {
    let { value: firstLoginDate } = await Preferences.get({ key: 'firstLoginDate' });
    if (!firstLoginDate) {
      await Preferences.set({ key: 'firstLoginDate', value: moment().format() });
    }
  }
}

const loginUserEpic = action$ =>
  action$.ofType(LOGIN_USER).mergeMap(action => {
    return Rx.Observable.fromPromise(api.loginUser(action.payload))
      .flatMap(payload => {
        let actions = [
          {
            type: LOGIN_USER_SUCCESS,
            payload
          },
          {
            type: UPDATE_SUBSCRIPTIONS,
            payload: payload && payload.data && payload.data.subscriptions ? payload.data.subscriptions : []
          }
        ];

        if (payload && payload.success === true) {
          if (payload.token) {
            NativeOrWeb.saveAccessTokenCookie(payload && payload.token)
              .then()
              .catch();
          }

          if (payload.data) {
            NativeOrWeb.saveUserEmail(payload.data.email);
            NativeOrWeb.saveUserName(payload.data.name);
          }

          actions.push({
            type: AUTHENTICATE_USER_SUCCESS,
            payload: payload && payload.data
          });
          actions.push({
            type: GET_USER
          });

          setFirstLoginDate();
          checkAndRequestPermissions(true);
          resetGPTQuota();
          resetGPTDisclaimer();
        }

        // if the user is Advisor and teamDetails is defined, dispatch action to update team logo and news urls
        if (payload && payload.data && payload.data.userType === 'Adviser') {
          if (NativeOrWeb.shouldShowEnterpriseDialog(payload.data)) {
            actions.push({ type: TOGGLE_DIALOG, dialog: 'enterpriseOnly', content: {} });
          }

          if (payload.data.teamDetails) {
            actions.push({
              type: UPDATE_TEAM_BASIC,
              payload: payload.data.teamDetails
            });
          }
        }

        // if the user is Advisor, trigger to get client list
        if (payload && payload.data && payload.data.userType === 'Adviser') {
          actions.push({ type: GET_CLIENT });
        }

        return actions;
      })
      .catch(error =>
        Rx.Observable.of({
          type: LOGIN_USER_ERROR,
          payload: { error }
        })
      );
  });

const createUserEpic = action$ =>
  action$.ofType(CREATE_USER).mergeMap(action => {
    return Rx.Observable.fromPromise(
      action.payload.data.userType === 'User'
        ? api.createClient(action.payload.data)
        : api.createUser(action.payload.data)
    )
      .flatMap(payload => [
        {
          type: AUTHENTICATE_USER_SUCCESS,
          payload: payload && payload.data
        },
        {
          type: UPDATE_SUBSCRIPTIONS,
          payload: payload && payload.data && payload.data.subscriptions ? payload.data.subscriptions : []
        },
        {
          type: CREATE_USER_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: CREATE_USER_ERROR,
          payload: { error }
        })
      );
  });

const approveUserEpic = action$ =>
  action$.ofType(APPROVE_USER).mergeMap(action => {
    return Rx.Observable.fromPromise(api.approveUser(action.payload.data))
      .flatMap(payload => [
        {
          type: APPROVE_USER_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: APPROVE_USER_ERROR,
          payload: { error }
        })
      );
  });
const verifyEmailEpic = action$ =>
  action$.ofType(VERIFY_EMAIL).mergeMap(action => {
    return Rx.Observable.fromPromise(api.verifyEmail(action.payload.data))
      .flatMap(payload => [
        {
          type: VERIFY_EMAIL_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: VERIFY_EMAIL_ERROR,
          payload: { error }
        })
      );
  });

const getUserEpic = action$ =>
  action$.ofType(GET_USER).mergeMap(() => {
    return Rx.Observable.fromPromise(api.getUser())
      .flatMap(payload => {
        let actions = [
          {
            type: GET_USER_SUCCESS,
            payload
          }
        ];

        // update analysis default inputs when the user is User
        if (payload.data.userType === 'User') {
          let updates = {};
          ['monthlyIncome', 'monthlyExpense', 'debt', 'age'].forEach(path => {
            if (
              payload.data[path] !== undefined &&
              payload.data[path] !== null &&
              payload.data.toString().trim() !== ''
            ) {
              if (path !== 'age') {
                updates[path] = parseFloat(payload.data[path].toString());
              } else if (path === 'age') {
                const age = getAgeFromDob(payload.data.dob, payload.data.age);
                if (age) {
                  updates[path] = parseFloat(age.toString());
                }
              }
            }
          });
          actions.push({
            // Update analysis inputs
            type: UPDATE_ANALYSIS_INPUTS,
            payload: updates
          });
        }

        return actions;
      })
      .catch(error =>
        Rx.Observable.of({
          type: GET_USER_ERROR,
          payload: { error }
        })
      );
  });

const getConnectedAdviserEpic = action$ =>
  action$.ofType(GET_ADVISER).mergeMap(() => {
    return Rx.Observable.fromPromise(api.getConnectedAdviser())
      .map(payload => ({
        type: GET_ADVISER_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_ADVISER_ERROR,
          payload: { error }
        })
      );
  });

const updateUserDetailsEpic = action$ =>
  action$.ofType(UPDATE_USER).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateUserDetails(action.payload.data))
      .flatMap(payload => {
        const actions = [
          {
            type: UPDATE_USER_SUCCESS,
            payload
          }
        ];

        if (payload.data && payload.data.userType === 'User') {
          actions.push(
            updateAnalysisInputs({
              monthlyExpense: payload.data.monthlyExpense,
              debt: payload.data.debt ? parseInt(payload.data.debt) : payload.data.debt,
              age: payload.data.age,
              monthlyIncome: payload.data.monthlyIncome
            })
          );
        }

        return actions;
      })
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_USER_ERROR,
          payload: { error }
        })
      );
  });

const changePasswordEpic = action$ =>
  action$.ofType(CHANGE_PASSWORD).mergeMap(action => {
    return Rx.Observable.fromPromise(api.changePassword(action.payload.data))
      .flatMap(payload => [
        {
          type: CHANGE_PASSWORD_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: CHANGE_PASSWORD_ERROR,
          payload: { error }
        })
      );
  });

const forgotPasswordEpic = action$ =>
  action$.ofType(FORGOT_PASSWORD).mergeMap(action => {
    return Rx.Observable.fromPromise(api.forgotPassword(action.payload.data))
      .flatMap(payload => [
        {
          type: FORGOT_PASSWORD_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: FORGOT_PASSWORD_ERROR,
          payload: { error }
        })
      );
  });

const resetPasswordEpic = action$ =>
  action$.ofType(RESET_PASSWORD).mergeMap(action => {
    return Rx.Observable.fromPromise(api.resetPassword(action.payload.data))
      .flatMap(payload => [
        {
          type: RESET_PASSWORD_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: RESET_PASSWORD_ERROR,
          payload: { error }
        })
      );
  });

const currencyExchangeRateEpic = action$ =>
  action$.ofType(CURRENCY_EXCHANGE_RATE).mergeMap(() => {
    return Rx.Observable.fromPromise(api.currencyExchangeRate())
      .map(payload => ({
        type: CURRENCY_EXCHANGE_RATE_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: CURRENCY_EXCHANGE_RATE_ERROR,
          payload: { error }
        })
      );
  });

const defaultMessagesEpic = action$ =>
  action$.ofType(DEFAULT_MESSAGES).mergeMap(() => {
    return Rx.Observable.fromPromise(api.defaultMessages())
      .map(payload => ({
        type: DEFAULT_MESSAGES_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: DEFAULT_MESSAGES_ERROR,
          payload: { error }
        })
      );
  });

const getNotificationsEpic = action$ =>
  action$.ofType(GET_NOTIFICATIONS).mergeMap(() => {
    return Rx.Observable.fromPromise(api.getNotifications())
      .map(payload => ({
        type: GET_NOTIFICATIONS_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_NOTIFICATIONS_ERROR,
          payload: { error }
        })
      );
  });

export const userEpic = combineEpics(
  getFigureUserEpic,
  createUserEpic,
  approveUserEpic,
  loginUserEpic,
  changePasswordEpic,
  forgotPasswordEpic,
  resetPasswordEpic,
  updateUserDetailsEpic,
  getUserEpic,
  getConnectedAdviserEpic,
  currencyExchangeRateEpic,
  defaultMessagesEpic,
  verifyEmailEpic,
  getNotificationsEpic
);
