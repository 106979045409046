import store from '../store';
import { getAdviserDashboard, getDuePolicy, getPaidUpPolicies } from '../store/adviserDashboard/duck';
import { getAssistant, refreshAssistant, updateDataSourceStatus } from '../store/assistant/duck';
import { getClient } from '../store/client/duck';
import { LOADING } from '../constants/phase';

export const checkAssistantDataExist = () => {
  let state = store.getState();
  if (!state.adviserDashboard.GetUsersPolicyDueData && state.adviserDashboard.policyDuePhase !== LOADING) {
    store.dispatch(getDuePolicy());
  }

  if (
    !state.adviserDashboard.GetConnectedUsersBirthday &&
    state.adviserDashboard.getAdviserDashboardPhase !== LOADING
  ) {
    store.dispatch(getAdviserDashboard());
  }

  if (!state.adviserDashboard.paidUpPolicyClients && state.adviserDashboard.paidUpPolicyPhase !== LOADING) {
    store.dispatch(getPaidUpPolicies());
  }

  state = store.getState();
  if (!state.assistant.formattedAssistant && !state.assistant.updating) {
    store.dispatch(getAssistant());
    return false;
  } else {
    return !state.assistant.updating;
  }
};

export const updateAssistantData = async shouldUpdate => {
  const state = store.getState();

  const assistantNeedUpdate = shouldUpdate || state.assistant.needUpdate;

  if (assistantNeedUpdate) {
    const { assistant, policyDue, adviserDashboard, paidUpPolicy } = assistantNeedUpdate;
    if (policyDue) {
      store.dispatch(updateDataSourceStatus({ policyDue: false }));
      store.dispatch(getDuePolicy());
    }
    if (adviserDashboard) {
      store.dispatch(updateDataSourceStatus({ adviserDashboard: false }));
      store.dispatch(getAdviserDashboard());
    }
    if (paidUpPolicy) {
      store.dispatch(updateDataSourceStatus({ paidUpPolicy: false }));
      store.dispatch(getPaidUpPolicies());
    }
    if (assistant) {
      store.dispatch(updateDataSourceStatus({ assistant: false }));
      store.dispatch(refreshAssistant());
    }
  }
};

export const retryAssistant = async () => {
  store.dispatch(getClient());
  store.dispatch(getAdviserDashboard());
  store.dispatch(getDuePolicy());
  store.dispatch(getPaidUpPolicies());

  return store.dispatch(getAssistant());
};
