import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { tracking } from '../../../utils/tracking';
import FunctionCardsView from '../../../components/FunctionCard/FunctionCardsView';
import { HotLabel } from '../../AdviserHomePage/HotLabel/HotLabel';
import GuidanceGroup from '../../../components/GuidanceGroup/GuidanceGroup';
import GridButtonLayout from '../../../components/GridButtonLayout/GridButtonLayout';
import { Grid } from '@material-ui/core';
import { UserSubscription } from '../../../utils/user-subscription';
import SubscriptionDialog from '../../Subscription/SubscriptionDialog/SubscriptionDialog';

const DoorOpener = props => {
  const { intl, history, userDetails, stripePromise } = props;

  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);

  const userSubscriptionO = new UserSubscription(props.userSubscription);
  const currentCoreSubscription = userSubscriptionO.getCurrentCoreSubscription();
  const shouldDisableFunctions = !userSubscriptionO.containVaildProSubscription();

  function openSubscriptionDialog() {
    setSubscriptionDialogOpen(true);
  }

  const getItems = ({ intl, history }) => [
    {
      color: '#5fc3d2',
      imgSrc: 'img/icons/lazypack_active_color.svg',
      imgAlt: 'hot-topic',
      text: intl.formatMessage({ id: 'lazy-pack' }),
      subtitle: intl.formatMessage({ id: 'do-hot-topic-desc' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on Hot Topic - Homepage');
          history.push('/hot_topic');
        }
      },
      styles: {
        position: 'relative'
      },
      extras: (
        <HotLabel
          text={intl.formatMessage({ id: 'hot' })}
          style={{ zIndex: 1, left: 'unset', right: 0, borderRadius: '4px 0 0 4px' }}
        />
      )
    },
    {
      color: '#3999f8',
      imgSrc: 'img/icons/sos_active_color.svg',
      imgAlt: 'Create Demo Client',
      text: intl.formatMessage({ id: 'create-demo-client' }),
      subtitle: intl.formatMessage({ id: 'do-med-review-pdf-desc' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on Add demo client - Homepage');
          history.push('/add_demo_client');
        }
      },
      extras: (
        <HotLabel
          text={intl.formatMessage({ id: 'hot' })}
          style={{ zIndex: 1, left: 'unset', right: 0, borderRadius: '4px 0 0 4px' }}
        />
      )
    },
    {
      color: '#2260a5',
      imgSrc: 'img/icons/hot_active_color.svg',
      imgAlt: 'Weekly Keywords',
      text: intl.formatMessage({ id: 'weekly-keyword-title' }),
      subtitle: intl.formatMessage({ id: 'weekly-keyword-desc' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          history.push({ pathname: '/weekly_keywords/lts' });
        }
      }
    }
    // {
    //   color: '#3999f8',
    //   imgSrc: 'img/E-medic.png',
    //   imgAlt: 'refer-medical-card',
    //   text: intl.formatMessage({ id: 'med-card' }),
    //   subtitle: intl.formatMessage({ id: 'do-med-card-desc' }),
    //   onClick: () => {
    //     if (shouldDisableFunctions) {
    //       openSubscriptionDialog();
    //     } else {
    //       tracking('Click on Medical Card - Door Opener');
    //       history.push('/medical-card');
    //     }
    //   }
    // }
  ];

  const guidance = [
    {
      label: intl.formatMessage({ id: 'guide-btn-CPI' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on CPI (Joyride) - Existing opportunities');
          history.push({ pathname: '/client' }, { guidance: 'CPI' });
        }
      }
    },
    {
      label: intl.formatMessage({ id: 'guide-btn-filter' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on Smart Filter (Joyride) - Existing opportunities');
          history.push({ pathname: '/client' }, { guidance: 'clientFilter' });
        }
      }
    },
    {
      label: intl.formatMessage({ id: 'guide-btn-lack' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on No Coverage (Joyride) - Existing opportunities');
          history.push({ pathname: '/advisorDashboard' }, { guidance: 'protectionLack' });
        }
      }
    },
    {
      label: intl.formatMessage({ id: 'guide-btn-reminder' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on Client Reminder (Joyride) - Existing opportunities');
          history.push({ pathname: '/advisorDashboard' }, { guidance: 'clientReminder' });
        }
      }
    }
  ];

  const profilePlus = () => {
    if (shouldDisableFunctions) {
      openSubscriptionDialog();
    } else {
      tracking('Click on Profile Plus - Existing opportunities');
      history.push('/profile-plus');
    }
  };

  return (
    <>
      <SubscriptionDialog
        intl={intl}
        userDetails={userDetails}
        stripePromise={stripePromise}
        open={subscriptionDialogOpen}
        onClose={() => setSubscriptionDialogOpen(false)}
        type="upgrade"
        currentSubscription={currentCoreSubscription}
        trialing={false}
        disableCoupon={true}
        defaultCoupon=""
      />

      <FunctionCardsView
        className="door-opener"
        getItems={getItems}
        prepend={<GuidanceGroup items={guidance} />}
        append={
          <GridButtonLayout
            items={[
              {
                image: <img src="img/profile-plus_icon.png" alt="profile-plus" />,
                text: (
                  <Grid container justify="center" alignItems="center" style={{ height: 20 }}>
                    <Grid item style={{ height: 20 }}>
                      <img
                        src="img/profile-plus.png"
                        alt="profile-plus"
                        style={{ height: 13, verticalAlign: 'unset' }}
                      />
                    </Grid>
                  </Grid>
                ),
                onClick: profilePlus
              }
            ]}
          />
        }
      />
    </>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    userSubscription: state.userSubscription.userSubscription
  }),
  {}
)(DoorOpener);

export default injectIntl(container);
