import React, { Fragment, PureComponent } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { createFilter } from 'react-search-input';
import { Button, Grid, IconButton, Typography, Box, Backdrop } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import Fab from '@material-ui/core/Fab';
import './clientPage.scss';
import './../styles.scss';
import MyTablePagination from '../../../../components/MyTablePagination/MyTablePagination';
import { LOADING } from '../../../../constants/phase';
import AddIcon from '@material-ui/icons/Add';
import MySwipeable from '../../../../components/MySwipeable';
import { Badge } from '@material-ui/core';
import { isSubscriptionAllowAction } from '../../../../utils/user-subscription-store';
import { DialogContentText, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import SearchFilter from './searchFilterExpansion/searchFilter';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Pagination } from '@material-ui/lab';
import Select from '@material-ui/core/Select';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import NotificationBellButton from '../../../../components/NotificationBellButton/NotificationBellButton';
import NotificationCenterModal from '../../../../components/NotificationCenterModal/NotificationCenterModal';
import {
  getClientConnectionStatus,
  getClientEmail,
  getPolicyDueGreetingMessage,
  goToAddClient,
  goToPortfolio,
  openChatGPTIceBreakingDrawer,
  openChatGPTMatchLazyPacks,
  openChatGPTPersonalAnalysis,
  toggleBirthdayGreetingDialog,
  toggleMeetingNoteDialog,
  togglePaidUpPoliciesGreetingDialog
} from '../../../../components/ClientEntry/utils';
import ClientEntryDialogs from '../../../../components/ClientEntry/ClientEntryDialogs';
import { findVirusRelatedClients } from '../../../../utils/virus';
import COVIDHintDialog from './COVIDHintDialog/COVIDHintDialog';
import COVIDShareToClientDialog from './COVIDShareToClientDialog/COVIDShareToClientDialog';
import ClientEntry, { BORDER_COLORS } from '../../../../components/ClientEntry/ClientEntry';
import { SlideTransition } from '../../../../components/Transition/Transition';
import BlockingOverlay from '../../../../components/SubscriptionComponents/BlockingOverlay/BlockingOverlay';
import {
  birthdaySort,
  isClientRemindMe,
  paidUpPolicySort,
  remindMeSort,
  trackingSort
} from '../../../../utils/adviser-client';
import CpiDetailPopup from './cpiDetailPopup/cpiDetailPopup.js';
import { TRACKING_MAX_DAYS } from '../../../../constants/tracking';
import { getRandomClient, getRandomTargetClient } from '../../../../utils/client';
import Lottie from '../../../../components/Lottie/Lottie';
import { isWidthExceed } from '../../../../utils/device';
import CustomFilterModal from './CustomFilterModal/CustomFilterModal';
import ResetFilterModal from './CustomFilterModal/ResetFilterModal';
import { isIOS, isMobile } from 'react-device-detect';
import TopNavHelpButton from '../../../../components/TopNavHelpButton/TopNavHelpButton';
import CustomFilterSwitch from './CustomFilterSwitch/CustomFilterSwitch';
import ClientSearchField from './ClientSearchField/ClientSearchField';
import AppContentContainer from '../../../../components/AppContentContainer/AppContentContainer';
import StickyHeader from '../../../../components/StickyComponents/StickyHeader';
import StickyFooter from '../../../../components/StickyComponents/StickyFooter';
import GuidanceTooltip from '../../../../components/guidelines/GuidanceTooltip/GuidanceTooltip';
import { tracking } from '../../../../utils/tracking';
import { getAgeFromDob } from '../../../../utils/date';
import { TopNavigationPortal } from '../../../../components/topNavigation';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';
import { toast } from 'react-toastify';
import IconToast from '../../../../components/NewToast';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import ClientEntryMailButton from '../../../../components/ClientEntry/ClientEntryMailButton/ClientEntryMailButton';
import SearchFilterBadge from './SearchFilterBadge/SearchFilterBadge';
import { sanitize } from 'dompurify';
import LoadingBackdrop from '../../../../components/LoadingBackdrop/LoadingBackdrop';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import ContactsIcon from '@material-ui/icons/Contacts';
import TableChartIcon from '@material-ui/icons/TableChart';
import { Capacitor } from '@capacitor/core';
import { Contacts } from '@capacitor-community/contacts';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import store from '../../../../store';
import { toggleDialog } from '../../../../store/control/duck';
import ImportContactsDialog from '../../../../components/ImportContactsDialog/ImportContactsDialog.js';

const defaultPageSize = 20;
const recruitmentHelpStyle = { marginTop: '12px' };

class ClientPageView extends PureComponent {
  constructor(props) {
    super(props);

    const { clientData } = props;

    const state = {
      searchTerm: '',
      clientState: 'all',
      selectedId: null,
      preSelectedClientIds: [],
      selectedLuckyClient: null,
      powerSearchModal: false,
      searchFilterOpen: false,
      page: 0,
      clientData: [],
      firstRendered: false,
      filterObject: {},
      showModal: false,
      clientBaseModal: false,
      loading: false,
      virusRelatedClients: [],
      covidShareDialog: false,
      covidHintDialog: false,
      connectedId: '',
      remindMeId: '',
      width: window.innerWidth,
      height: window.innerHeight,
      clearAllModalOpen: false,
      clearAllPhase: false,
      customFilterModalOpen: false,
      customFilterIndex: 0,
      resetFilterModalOpen: false,
      activeTab: [true, false, false, false, false, false, false],
      // Move Policy related states, info passed from router
      isMovePolicy: false,
      isMovePolicyReminderModalOpen: false,
      selectedClientForMovePolicy: '',
      portfolioToMove: null,
      families: [],
      menuOpen: false,
      importContactsDialogOpen: false,
      importAllContactsDialogOpen: false
    };
    this.searchUpdated = this.searchUpdated.bind(this);
    this.selectClient = this.selectClient.bind(this);
    this.runGuideline = this.runGuideline.bind(this);

    this.maxPage = (clientData || []).length > 0 ? Math.ceil((clientData || []).length / defaultPageSize) : 0;
    this.unmounted = false;

    if (props.location) {
      const { select, selectedClient, smartIdea, gpt } = props.location;
      if (select === 'lucky' && selectedClient && smartIdea) {
        props.toggleDialog('smartIdea', {
          client: selectedClient,
          extraHeading: typeof smartIdea === 'string' ? props.intl.formatMessage({ id: smartIdea }) : undefined
        });
      }

      if (gpt && selectedClient) {
        const clientIndex = (clientData || []).findIndex(client => client._id === selectedClient._id);
        const page = (clientData || []).length > 0 ? Math.floor(clientIndex / defaultPageSize) : 0;
        localStorage.removeItem('currentPage');
        state.page = page;

        if (gpt === 'ice-breaking') {
          props.toggleDialog('smartIdea', {
            client: selectedClient,
            extraHeading: typeof smartIdea === 'string' ? props.intl.formatMessage({ id: smartIdea }) : undefined
          });
          setTimeout(() => {
            openChatGPTIceBreakingDrawer(selectedClient);
          }, 1100);
        } else if (gpt === 'lazy-packs') {
          if (selectedClient) {
            props.toggleDialog('smartIdea', {
              client: selectedClient,
              extraHeading: typeof smartIdea === 'string' ? props.intl.formatMessage({ id: smartIdea }) : undefined
            });
            setTimeout(() => {
              openChatGPTMatchLazyPacks(selectedClient);
            }, 1100);
          } else {
            props.toggleDialog('smartIdea', {
              client: selectedClient,
              extraHeading: typeof smartIdea === 'string' ? props.intl.formatMessage({ id: smartIdea }) : undefined
            });
            setTimeout(() => {
              openChatGPTPersonalAnalysis(selectedClient);
            }, 1100);
          }
        } else if (gpt === 'personal-analysis') {
          props.toggleDialog('smartIdea', {
            client: selectedClient,
            extraHeading: typeof smartIdea === 'string' ? props.intl.formatMessage({ id: smartIdea }) : undefined
          });
          setTimeout(() => {
            openChatGPTPersonalAnalysis(selectedClient);
          }, 1100);
        }
      }
    }

    this.state = state;

    this.topNavHelpButtonRef = React.createRef();
    this.connectClientGuidanceFlag = false;
  }

  applySearchFilter = (families, editExistingFilter) => {
    if (editExistingFilter) {
      let newFilteredFamilies;
      if (this.state.filterObject.families) {
        newFilteredFamilies = [...this.state.filterObject.families];
      } else {
        newFilteredFamilies = [];
      }
      if (families.length > 1 && newFilteredFamilies?.includes(families[0])) {
        newFilteredFamilies.push(families[1]);
      }
      newFilteredFamilies = newFilteredFamilies.filter(family => family != families[0]);
      this.setState({ filterObject: { ...this.state.filterObject, families: newFilteredFamilies } });
    } else {
      // Cancel the families filter if they are exactly the same
      if (
        this.state.filterObject.families &&
        this.state.filterObject.families.sort().join(',') === families.sort().join(',')
      ) {
        this.setState({ filterObject: { ...this.state.filterObject, families: [] } });
      } else {
        this.setState({ filterObject: { ...this.state.filterObject, families: families } });
      }
    }
  };

  aiSuggestClient = isReset => {
    const { intl, clientData, toggleDialog } = this.props;

    if (isReset) {
      this.setState({ selectedId: null, page: 0, searchTerm: '', clientState: 'all', selectedLuckyClient: null });
    } else {
      const targetClient = getRandomTargetClient(clientData);

      if (targetClient) {
        const obj = {
          selectedId: targetClient._id,
          page: 0,
          searchTerm: '',
          clientState: 'lucky',
          selectedLuckyClient: targetClient
        };

        this.setState(obj, () => this.setLocalStorage(obj.selectedId));
        toggleDialog('smartIdea', {
          client: targetClient,
          extraHeading: intl.formatMessage({ id: 'smart-idea-lucky-hint' })
        });
      }
    }
  };

  openClientBaseModal = () => this.setState({ clientBaseModal: true });
  closeClientBaseModal = () => this.setState({ powerSearchModal: false });

  getFilterObject = data => {
    this.setState({
      filterObject: data,
      page: 0
    });
  };

  toggleDrawer = () => event => {
    isSubscriptionAllowAction(
      { type: 'core', nicknames: ['Pro', 'Ultra'] },
      {
        customMessage: this.props.intl.formatMessage({ id: 'upgrade-msg-filter' }),
        cb: () => this.setState({ searchFilterOpen: false })
      }
    );
    this.setState({ ...this.state, searchFilterOpen: !this.state.searchFilterOpen }, () => {
      if (this.state.searchFilterOpen) {
        // tracking
        tracking('Adv search');
      }
    });
  };

  getRequiredFilterObject = filter => {
    //Filter out the un-used filter /Array: did not pass if it is empty /Number: did not pass if it is 0 (ageMax is 100)
    Object.keys(filter).forEach(key => {
      if (Array.isArray(filter[key]) && filter[key].length === 0) {
        delete filter[key];
      } else if (filter[key] === 0 || (key === 'ageMax' && filter[key] === 100)) {
        delete filter[key];
      } else if (key === 'referrerName' && filter[key] === '') {
        delete filter[key];
      }
    });
    return filter;
  };

  filterByFilterObject = data => {
    let isSortDate = false;

    let filter = { ...this.state.filterObject };
    if (filter === undefined || filter === null || Object.values(filter).length === 0) {
      return data;
    }
    filter = this.getRequiredFilterObject(filter);
    delete filter.editFamilyDialogOpen;

    const usedTags = this.props.userDetails.usedTags ? this.props.userDetails.usedTags.map(e => e.text) : [];

    //If data filtration is not needed, set the clientStag
    Object.keys(filter).forEach(key => {
      if (Array.isArray(filter[key])) {
        data = data.filter(eachUser => {
          let factsheet = eachUser.factsheetId;
          // for personality：
          if (key === 'personality' && filter[key].includes('') && (!factsheet[key] || factsheet[key] === '')) {
            return true;
          }
          //Tag
          if (key === 'tags' && factsheet[key] && factsheet[key].length > 0) {
            const newTags = filter[key].filter(e => usedTags.includes(e));
            if (newTags.length === 0) {
              return true;
            }
            return factsheet[key].some(value => newTags.includes(value.text));
          }
          // Family
          if (key === 'families' && factsheet[key] && factsheet[key].length > 0) {
            return factsheet[key].some(value => filter[key].includes(value));
          }
          // need to filter entries that do not have gender value
          if (key === 'gender' && !factsheet[key]) {
            return filter[key].findIndex(filterValues => !filterValues) > -1;
          }
          //Normal Case
          if (factsheet[key]) {
            return filter[key].includes(factsheet[key]);
          }
          return false;
        });
      } else {
        data = data.filter(eachUser => {
          let factsheet = eachUser.factsheetId;
          //Birthday Month
          if (factsheet.dob && key === 'bdayMonth') {
            isSortDate = true;
            return moment(factsheet.dob).month() + 1 === filter[key];
          }
          // Age
          if (
            factsheet.age &&
            ((key === 'ageMin' && factsheet.age >= filter[key]) || (key === 'ageMax' && factsheet.age <= filter[key]))
          ) {
            return true;
          }

          // Coverage
          if (
            (key === 'ciCoverage' && eachUser.ciCoverage && eachUser.ciCoverage <= filter[key]) ||
            (key === 'lifeCoverage' && eachUser.lifeCoverage && eachUser.lifeCoverage <= filter[key])
          ) {
            return true;
          }

          //Monthly Income/Expense
          if (
            factsheet[key] &&
            ((key === 'monthlyIncome' && factsheet[key] >= filter[key]) ||
              (key === 'monthlyExpense' && factsheet[key] <= filter[key]))
          ) {
            return true;
          }

          // Monthly Saving (totalPremiumSaving & totalWithILAS are annual amounts)
          if (
            key === 'monthlySaving' &&
            _.get(eachUser, 'totalPremiumSaving', 0) + _.get(eachUser, 'totalWithILAS', 0) <= filter[key]
          ) {
            return true;
          }

          if (
            key === 'referrerName' &&
            (factsheet[key] || '')
              .toString()
              .toLowerCase()
              .includes((filter[key] || '').toString().toLowerCase())
          ) {
            return true;
          }

          return false;
        });
      }
    });

    // Sort the date if bday has activated
    if (isSortDate) {
      data = data.sort((a, b) => {
        let a_day = moment(a.factsheetId.dob)._a[2];
        let b_day = moment(b.factsheetId.dob)._a[2];
        return a_day - b_day;
      });
    }

    return data;
  };

  UNSAFE_componentWillMount() {
    const { history, location, clientData } = this.props;
    if (location.state) {
      if (location.state.connectedId) {
        this.setState({
          connectedId: location.state.connectedId
        });
        history.replace(_.omit(location, 'state.connectedId'));
      }
      if (location.state.remindMeId) {
        this.setState({
          remindMeId: location.state.remindMeId
        });
        history.replace(_.omit(location, 'state.remindMeId'));
      }
    }

    if (location.select && location.select === 'ClientToSend') {
      this.setState({
        clearAllModalOpen: true
      });
    }

    if (location.state && location.state?.action === 'movePolicy') {
      this.setState({
        isMovePolicy: true,
        isMovePolicyReminderModalOpen: true,
        portfolioToMove: location.state.portfolioToMove
      });
    }
    this.props.getUser();

    let localStorageOpeningClientId = window.localStorage.getItem('clientId'),
      localStorageCurrentPage = window.localStorage.getItem('currentPage'),
      localStorageClientState = window.localStorage.getItem('clientState'),
      localStorageSelectedClientIds = window.localStorage.getItem('preSelectedClientIds'),
      localStorageSelectedLuckyClient = window.localStorage.getItem('selectedLuckyClientObj'),
      localStorageSearchTerm = window.localStorage.getItem('searchTerm'),
      localStorageVirusRelatedClients = window.localStorage.getItem('virusRelatedClients');

    let openingClientId = localStorageOpeningClientId ? JSON.parse(localStorageOpeningClientId) : '';
    let currentPage = localStorageCurrentPage ? JSON.parse(localStorageCurrentPage) : this.state.page;
    let clientState = localStorageClientState ? JSON.parse(localStorageClientState) : '';
    let preSelectedClientIds = localStorageSelectedClientIds ? JSON.parse(localStorageSelectedClientIds) : [];
    let selectedLuckyClient = localStorageSelectedLuckyClient ? JSON.parse(localStorageSelectedLuckyClient) : -1;
    let searchTerm = localStorageSearchTerm ? JSON.parse(localStorageSearchTerm) : '';
    let virusRelatedClients = localStorageVirusRelatedClients ? JSON.parse(localStorageVirusRelatedClients) : [];
    const recruitmentActive = clientState === 'recruitment';
    const tabs = [!recruitmentActive, false, false, false, false, recruitmentActive];

    // if other page passes 'select' to client page
    if (this.props.location.select !== undefined) {
      clientState = this.props.location.select;
      // console.log(clientState);
      if (this.props.location.preSelectedClientIds) {
        preSelectedClientIds = this.props.location.preSelectedClientIds;
      } else {
        openingClientId = '';
      }
      if (this.props.location.select === 'covid') {
        this.handleVirus();
      }
      localStorage.removeItem('scrollCoordinate');
      localStorage.removeItem('changeStateCord');
    }

    if (this.props.location.selectedClient) {
      const selectedClient = this.props.location.selectedClient;
      selectedLuckyClient = selectedClient ? selectedClient : getRandomClient(clientData);
      openingClientId = selectedLuckyClient._id;
    }

    let obj = {
      selectedId: openingClientId,
      page: currentPage,
      searchTerm: searchTerm ? searchTerm : '',
      clientState: clientState ? clientState : '',
      preSelectedClientIds: preSelectedClientIds,
      selectedLuckyClient: selectedLuckyClient,
      virusRelatedClients: virusRelatedClients,
      activeTab: tabs
    };

    this.setState(obj, () => this.setLocalStorage(this.state.selectedId));

    localStorage.removeItem('clientPersonalData');
    localStorage.removeItem('clientFactsheetDataOne');
    localStorage.removeItem('clientFactsheetDataTwo');
    localStorage.removeItem('clientFactsheetDataThree');
    //clear the add or edit policy detail
    localStorage.removeItem('addRider');
    localStorage.removeItem('previousPolicyData');
    localStorage.removeItem('insurer');
    localStorage.removeItem('searchTerm');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clearAllThenFetchClient && this.state.clearAllPhase) {
      this.props.getClient();
      this.setState({
        clearAllPhase: false
      });
    } else if (localStorage.getItem('clientSortBy')) {
      this.props.getClient();
    }
    this.setState({ clientData: nextProps.clientData, families: nextProps.userDetails.families });
  }

  componentDidMount() {
    const { intl, history, runGuideline, clientData } = this.props;
    window.addEventListener('resize', this.handleResize, false);
    if (!this.unmounted) {
      this.scrollToSelected();

      // add first render block for showing loading gif
      if (!this.state.firstRendered) {
        // this.forceUpdate();
        setTimeout(() => {
          if (this && !this.unmounted) {
            this.setState({
              firstRendered: true,
              clientData: this.props.clientData
            });
          }
        }, 250);
      }
    }
    if (this.state.clientState === 'Connected' && this.state.connectedId !== '') {
      this.setState({
        connectedId: this.state.connectedId
      });
      let client = {
        _id: this.state.connectedId
      };
      this.selectClient(client, true);
    }
    if (this.props.location.state && this.props.location.state.path === 'adviser_blast')
      this.props.renderHeaderButton(false);
    else this.props.renderHeaderButton(true);

    if (this.state.clientState === 'allReminder' && this.state.remindMeId !== '') {
      this.setState({
        remindMeId: this.state.remindMeId
      });
      let client = {
        _id: this.state.remindMeId
      };
      this.selectClient(client, true);
    }
    // IN CASE THERE ARE TAG CHANGES IN PAGES OTHER THAN CLIENT PAGE
    // if(this.props.userDetails){
    //   this.updateCustomFilterTags();
    //   this.updateStateFilterTags();
    // }

    const guidanceState = _.get(history, 'location.state.guidance');
    if (guidanceState) {
      let state = { ...history.location.state };
      delete state.guidance;
      history.replace({ ...history.location, state });
      const run = () =>
        runGuideline(guidanceState, {
          parameters: {
            intl: intl
          },
          tooltipComponent: GuidanceTooltip
        });

      if (guidanceState === 'connectClient') {
        const selectedClient = (clientData || []).find(() => true);
        if (selectedClient) {
          this.setState({ selectedId: selectedClient._id }, () => {
            this.connectClientGuidanceFlag = true;
          });
        }
      } else {
        run();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { intl, runGuideline } = this.props;
    if (this.connectClientGuidanceFlag) {
      const targetElem = document.querySelector('.client-entry.expanded button.connect-inactive');
      if (targetElem) {
        this.connectClientGuidanceFlag = false;
        runGuideline('connectClient', {
          parameters: {
            intl: intl
          },
          tooltipComponent: GuidanceTooltip
        });
      }
    }
    if (this.props.location !== prevProps.location) {
      if (this.props.location.state && this.props.location.state.path === 'adviser_blast')
        this.props.renderHeaderButton(false);
      else this.props.renderHeaderButton(true);
    }
    // check web tour running status
    // if (!this.props.guidelineIsStarted && prevProps.guidelineIsStarted) {
    //   // list all client after the end of web tour
    //   let obj = {
    //     clientState: '',
    //     selectedId: null
    //   };
    //
    //   this.setState(obj, () => {
    //     this.setLocalStorage(this.state.selectedId);
    //   });
    // }
    if (
      prevProps.userDetails &&
      this.props.userDetails &&
      !_.isEqual(this.props.userDetails.usedTags, prevProps.userDetails.usedTags)
    ) {
      this.updateCustomFilterTags();
      this.updateStateFilterTags();
    }

    if (this.props.movePolicyStatus !== prevProps.movePolicyStatus) {
      if (this.props.movePolicyStatus === 'success') {
        toast.info(
          IconToast(
            'success',
            intl.formatMessage({
              id: 'move-policy-success'
            })
          ),
          { className: 'new-toast' }
        );
        this.props.history.go(-2);
        goToPortfolio(this.state.selectedClientForMovePolicy, this.props.history, true);
      }
      if (this.props.movePolicyStatus === 'error') {
        // FIXME: This should work only if the 403 reset flow is removed from utils/fetch.js
        toast.info(
          IconToast(
            'error',
            intl.formatMessage({
              id: 'move-policy-error'
            })
          ),
          { className: 'new-toast' }
        );
        this.props.history.goBack();
      }
    }
  }

  updateStateFilterTags = () => {
    if (!this.state.filterObject.tags) {
      return;
    }
    const usedTags = this.props.userDetails.usedTags ? this.props.userDetails.usedTags.map(e => e.text) : [];
    const customFilterTags = this.state.filterObject.tags.filter(e => usedTags.includes(e));
    const isEqual = _.isEqual(customFilterTags, this.state.filterObject.tags);
    if (!isEqual) {
      const newObject = { ...this.state.filterObject };
      if (customFilterTags.length > 0) {
        newObject.tags = customFilterTags;
      } else {
        delete newObject.tags;
      }
      this.setState({ filterObject: newObject });
    }
  };

  updateCustomFilterTags = () => {
    const usedTags = this.props.userDetails.usedTags ? this.props.userDetails.usedTags.map(e => e.text) : [];
    let requireUpdate = false;
    const newCustomFilters = this.props.adviserCustomFilters.map(e => {
      if (e.filterObject.tags) {
        const customFilterTags = e.filterObject.tags.filter(e => usedTags.includes(e));
        const isEqual = _.isEqual(customFilterTags, e.filterObject.tags);
        if (!isEqual) {
          const newObject = { ...e };
          const newFilterObject = { ...e.filterObject };
          if (customFilterTags.length > 0) {
            newFilterObject.tags = customFilterTags;
          } else {
            delete newFilterObject.tags;
          }
          requireUpdate = !isEqual ? true : requireUpdate;
          newObject.filterObject = newFilterObject;
          return newObject;
        }
      }
      return e;
    });

    if (requireUpdate) {
      this.props.updateAdviserCustomFilters({ customFilters: newCustomFilters });
    }
  };

  handleSwipe(direction) {
    // avoid change current page value when it is fetching client list
    if (this.props.fetchClientPhase !== LOADING) {
      if (direction === 'right' && this.state.page === this.maxPage) return;
      this.setState(
        {
          page: Math.max(0, this.state.page + (direction === 'right' ? 1 : -1))
        },
        () => this.setLocalStorage(undefined)
      );
    }
  }

  scrollToSelected() {
    setTimeout(() => {
      // let scrollCoordinate = window.localStorage.getItem('scrollCoordinate');
      // scrollTo(0, parseInt(scrollCoordinate));

      window.requestAnimationFrame(() => {
        let expandedClient = document.querySelector('.client-entry.expanded');
        if (expandedClient) {
          let elTop = expandedClient.getBoundingClientRect().top,
            bodyTop = document.body.getBoundingClientRect().top,
            offset = elTop - bodyTop;
          window.scrollTo(0, offset - 90);
        }
      });
    }, 500);
  }

  handleChange(e) {
    let obj = {
      [e.target.name]: e.target.value
    };
    if (e.target.name === 'clientState') {
      obj.selectedId = null;

      if (e.target.value === 'lucky') {
        const { clientData } = this.props;
        obj.selectedLuckyClient = getRandomClient(clientData);
      }
    }

    this.setState(obj, () => {
      this.setLocalStorage(this.state.selectedId);
    });
  }

  powerSearchModal = () => {
    this.setState({ powerSearchModal: true });
  };

  luckyClient() {
    const { clientData } = this.props;
    if (clientData && clientData.length === 0) {
      return;
    }

    let selectedLuckyClient = getRandomClient(clientData);
    let filteredEmails = [selectedLuckyClient];

    // getClientNoCoverage({userId: filteredEmails[0]._id})
    this.setState(
      {
        clientState: 'lucky',
        selectedId: selectedLuckyClient._id,
        selectedLuckyClient: selectedLuckyClient
        // selectedLuckyClientData: filteredEmails,
        // luckyClientDetailPopup: borderColor !== "rgb(255, 106, 116)" ? true : false,
        // luckyClientCPI:borderColor
      },
      () => {
        this.setLocalStorage(undefined, true);
      }
    );

    // uncheck the flag so that function in componentDidUpdate will run to expand the selected client
    this.selectClient(filteredEmails[0], true);
  }

  setLocalStorage(id, noClientState) {
    localStorage.setItem('currentPage', JSON.stringify(this.state.page));
    if (id !== undefined) {
      localStorage.setItem('clientId', JSON.stringify(id));
    }
    if (noClientState !== true) {
      localStorage.setItem('searchTerm', JSON.stringify(this.state.searchTerm));
      localStorage.setItem('clientState', JSON.stringify(this.state.clientState));
      localStorage.setItem('selectedLuckyClientObj', JSON.stringify(this.state.selectedLuckyClient));
      localStorage.setItem('preSelectedClientIds', JSON.stringify(this.state.preSelectedClientIds));
      localStorage.setItem('virusRelatedClients', JSON.stringify(this.state.virusRelatedClients));
    } else {
      localStorage.removeItem('clientState');
      localStorage.removeItem('selectedLuckyClientObj');
      localStorage.removeItem('preSelectedClientIds');
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
    this.props.initPhaseEmail();
    this.props.initPremiumViewPhase();
    this.props.endGuideline();
    window.removeEventListener('resize', this.handleResize, false);

    this.unsetMovePolicyStates();
  }

  handleResize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    this.setState({ width, height });
  };

  zodiac_sign(day, month) {
    let astro_sign = '';

    if (month === 12) {
      day < 22 ? (astro_sign = '/img/sagittarius.png') : (astro_sign = '/img/goat.png');
    } else if (month === 1) {
      day < 20 ? (astro_sign = '/img/goat.png') : (astro_sign = '/img/aquarius.png');
    } else if (month === 2) {
      day < 19 ? (astro_sign = '/img/aquarius.png') : (astro_sign = '/img/pisces.png');
    } else if (month === 3) {
      day < 21 ? (astro_sign = '/img/pisces.png') : (astro_sign = '/img/aries.png');
    } else if (month === 4) {
      day < 20 ? (astro_sign = '/img/aries.png') : (astro_sign = '/img/bull.png');
    } else if (month === 5) {
      day < 21 ? (astro_sign = '/img/bull.png') : (astro_sign = '/img/gemini.png');
    } else if (month === 6) {
      day < 21 ? (astro_sign = '/img/gemini.png') : (astro_sign = '/img/cancer.png');
    } else if (month === 7) {
      day < 23 ? (astro_sign = '/img/cancer.png') : (astro_sign = '/img/leo.png');
    } else if (month === 8) {
      day < 23 ? (astro_sign = '/img/leo.png') : (astro_sign = '/img/virgo.png');
    } else if (month === 9) {
      day < 23 ? (astro_sign = '/img/virgo.png') : (astro_sign = '/img/libra.png');
    } else if (month === 10) {
      day < 23 ? (astro_sign = '/img/libra.png') : (astro_sign = '/img/scorpion.png');
    } else if (month === 11) {
      day < 22 ? (astro_sign = '/img/scorpion.png') : (astro_sign = '/img/sagittarius.png');
    }
    return astro_sign;
  }

  //To handle the scroll functionality
  showCoords(event) {
    let clientArrow = window.localStorage.getItem('arrowRotateClient');
    let deviceFinalLength = window.screen.height / 2;
    let eventClick = event.clientY;
    if (clientArrow !== null && clientArrow !== undefined && clientArrow === 'false' && window.pageYOffset <= 250) {
      if (deviceFinalLength < eventClick) {
        this.setState({ changeStateCord: true });
        let scrollCord = eventClick - 135;
        localStorage.setItem('changeStateCord', JSON.stringify(true));
        localStorage.setItem('scrollCoordinate', JSON.stringify(scrollCord));
      } else {
        this.setState({ changeStateCord: false });
        localStorage.setItem('changeStateCord', JSON.stringify(false));
        localStorage.setItem('scrollCoordinate', JSON.stringify(window.pageYOffset));
      }
    } else if (
      clientArrow !== null &&
      clientArrow !== undefined &&
      clientArrow === 'true' &&
      window.pageYOffset <= 250
    ) {
      if (deviceFinalLength < eventClick) {
        this.setState({ changeStateCord: true });
        let scrollCord = eventClick - 135;
        localStorage.setItem('changeStateCord', JSON.stringify(true));
        localStorage.setItem('scrollCoordinate', JSON.stringify(scrollCord));
      } else {
        this.setState({ changeStateCord: false });
        localStorage.setItem('changeStateCord', JSON.stringify(false));
        localStorage.setItem('scrollCoordinate', JSON.stringify(window.pageYOffset));
      }
    }
    if (clientArrow !== null && clientArrow !== undefined && clientArrow === 'false' && window.pageYOffset >= 250) {
      let scroll = window.pageYOffset;
      if (deviceFinalLength < eventClick) {
        this.setState({ changeStateCord: true });
        let scrollCord = eventClick + scroll - 120;
        localStorage.setItem('changeStateCord', JSON.stringify(true));
        localStorage.setItem('scrollCoordinate', JSON.stringify(scrollCord));
      } else {
        this.setState({ changeStateCord: false });
        localStorage.setItem('changeStateCord', JSON.stringify(false));
        localStorage.setItem('scrollCoordinate', JSON.stringify(window.pageYOffset));
      }
    } else if (
      clientArrow !== null &&
      clientArrow !== undefined &&
      clientArrow === 'true' &&
      window.pageYOffset >= 250
    ) {
      let scroll = window.pageYOffset;
      if (deviceFinalLength < eventClick) {
        this.setState({ changeStateCord: true });
        let scrollCord = eventClick + scroll - 120;
        localStorage.setItem('changeStateCord', JSON.stringify(true));
        localStorage.setItem('scrollCoordinate', JSON.stringify(scrollCord));
      } else {
        this.setState({ changeStateCord: false });
        localStorage.setItem('changeStateCord', JSON.stringify(false));
        localStorage.setItem('scrollCoordinate', JSON.stringify(window.pageYOffset));
      }
    }
  }

  addClient = () => {
    goToAddClient(this.props.history);
  };

  updateSearchTerm = term => {
    localStorage.setItem('searchTerm', JSON.stringify(term));
  };

  searchUpdated(event) {
    const term = (event.target.value || '').replace(/vvip/gi, 'v.vip');
    this.setState({
      searchTerm: term,
      selectedId: null,
      page: 0
    });
    this.updateSearchTerm(term);
    // this.state.searchTerm.replace(/\bvv\b|\bvvip\b|\bvvi\b/gi, () => {
    //   this.setState({ searchTerm: 'v.vip' });
    // });
  }

  selectClient(client, expanded) {
    this.setState(
      {
        selectedId: expanded ? client._id : null,
        searchFilterOpen: false
      },
      () => this.setLocalStorage(client._id)
    );
  }

  hanldlefilter(key) {
    this.setState({ clientState: key, page: 0 }, () => this.setLocalStorage(undefined));
  }

  async handleVirus() {
    // tracking
    tracking('Access virus scan');

    const { clientData } = this.props;
    this.setState({ loading: true, covidHintDialog: true });
    const virusRelatedClients = await findVirusRelatedClients(clientData);
    // console.log(virusRelatedClients);
    this.setState({
      clientState: 'virus',
      virusRelatedClients: virusRelatedClients,
      loading: false
    });
  }

  changeClientToTackingOn() {
    this.setState({ clientState: this.state.clientState === 'TrackOn' ? 'all' : 'TrackOn', page: 0 }, () =>
      this.setLocalStorage(undefined)
    );
  }

  resetSearch() {
    this.props.getClient();
    this.setState({ clientState: '', searchTerm: '', page: 0, filterObject: {} }, () =>
      this.setLocalStorage(undefined)
    );
  }

  runGuideline() {
    this.setState({ searchFilterOpen: false });
    document.getElementById('menu-toggle').click();
    this.luckyClient();
    setTimeout(() => {
      this.props.runGuideline('client');
    }, 400);
  }

  handleBellClick() {
    this.setState({
      showModal: true
    });
  }

  handleLinkToClientState(flag, Id = null) {
    this.setState({
      clientState: flag
    });

    if (flag === 'Connected') {
      this.setState({
        connectedId: Id
      });
      let client = {
        _id: Id
      };
      this.selectClient(client, 'lucky');
    }
    if (flag === 'allReminder') {
      this.setState({
        remindMeId: Id
      });
      let client = {
        _id: Id
      };
      this.selectClient(client, 'lucky');
    }
  }

  closeNotificationModal() {
    this.setState({ showModal: false });
  }

  applyCustomFilter = i => {
    this.resetSearch();
    const newTabs = [false, false, false, false, false, false, false];
    newTabs[i + 1] = true;
    this.setState({
      activeTab: newTabs,
      customFiltersActive: true,
      customFilterIndex: i,
      filterObject: _.cloneDeep(this.props.adviserCustomFilters[i].filterObject),
      clientState: this.props.adviserCustomFilters[i].clientState,
      searchTerm: this.props.adviserCustomFilters[i].searchTerm
    });
  };

  // openAdviserHelpDialog = () => {
  //   const topNavHelpButton = _.get(this, 'topNavHelpButtonRef.current');
  //   if (topNavHelpButton) {
  //     const instance = topNavHelpButton.getWrappedInstance();
  //     if (instance) {
  //       instance.openAdviserHelpDialog();
  //     }
  //   }
  // };

  refreshData = (oldFamilyName, newFamilyName) => {
    this.props.getUser();
    if (oldFamilyName && newFamilyName) {
      this.props.getClient();
      this.applySearchFilter([oldFamilyName, newFamilyName], true);
    } else if (oldFamilyName) {
      this.props.getClient();
      this.applySearchFilter([oldFamilyName], true);
    }
  };

  unsetMovePolicyStates = () => {
    this.setState({
      clientState: 'all',
      portfolioToMove: null,
      selectedClientForMovePolicy: null,
      isMovePolicy: false,
      isMovePolicyReminderModalOpen: false
    });
  };

  handleMovePolicy = client => {
    this.setState({
      selectedClientForMovePolicy: client
    });
  };

  confirmMovePolicy = () => {
    const { portfolioToMove, selectedClientForMovePolicy } = this.state;
    const portfolioId = portfolioToMove.id;
    const originalClientId = portfolioToMove.userId;
    const clientId = selectedClientForMovePolicy._id;
    this.props.movePolicy(
      {
        clientId,
        portfolioId
      },
      originalClientId
    );
  };

  cancelMovePolicy = () => {
    this.props.history.goBack();
  };

  excelImportClients = () => {
    const { history } = this.props;
    tracking('Click on Import Clients - Clients');
    history.push({ pathname: '/import' });
  };

  importContacts = async () => {
    let permission = await Contacts.checkPermissions();
    if (permission.contacts !== 'granted') {
      permission = await Contacts.requestPermissions();
      if (permission.contacts !== 'granted') {
        await NativeSettings.open({
          optionAndroid: AndroidSettings.ApplicationDetails,
          optionIOS: IOSSettings.App
        });
        console.log('Permission not granted');
        return;
      }
    }

    this.setState({ importContactsDialogOpen: true });
  };

  openImportAllContactsDialog = async () => {
    const onClickDownloadApp = () => {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/iPhone|iPad|iPod/i.test(userAgent)) {
        window.open('https://itunes.apple.com/hk/app/portfoplus/id1590830597?mt=8', '_blank');
      } else {
        window.open('https://www.portfoplus.com/adviser/index.html', '_blank');
      }
    };

    tracking('Click on Import Contacts - Clients');
    this.setState({ menuOpen: false });
    if (Capacitor.isNativePlatform()) {
      let permission = await Contacts.checkPermissions();
      if (Capacitor.getPlatform() === "ios" && permission.contacts !== 'granted') {
        store.dispatch(
          toggleDialog('permission', {
            intlId: 'contacts-full-access-required-msg',
            onClose: this.importContacts
          })
        );
      } else {
        this.importContacts();
      }
    } else {
      toast.info(IconToast('info', this.props.intl.formatMessage({ id: 'download-portfoplus-app-to-use-this-feature' })), { className: 'new-toast', onClick: onClickDownloadApp });
    }
  }

  render() {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

    const {
      history,
      GetUsersPolicyDueData,
      policiesData,
      GetConnectedUsersBirthday,
      intl,
      PastBirthdayOfUsers,
      policyDueData,
      userDetails,
      toggleDialog,
      paidUpPolicyClients,
      hasNotifications,
      newNotificationNumber,
      clearAllMsgOpt,
      clientsToSendNum,
      adviserCustomFilters,
      advisorCustomFiltersState,
      showPadding,
      newReferralCount,
      referrerNames
    } = this.props;
    const {
      preSelectedClientIds,
      selectedLuckyClient,
      page,
      clientData,
      clientState,
      selectedId,
      showModal,
      clearAllModalOpen,
      filterObject
    } = this.state;

    let { searchTerm } = this.state;

    const usedTags = userDetails.usedTags ? userDetails.usedTags : [];

    let clientBaseColor = BORDER_COLORS[3];
    // let emailVerifyMessage = intl.formatMessage({ id: 'Email_Verify_Message' });
    if (clientData && clientData.length > 0) {
      let clientBaseLevel = clientData[0].clientBaseLevel;
      if (clientBaseLevel !== undefined && clientBaseLevel !== null) {
        clientBaseColor = BORDER_COLORS[clientBaseLevel];
      }
    }

    const user = this.props.userDetails;
    let mapData = clientData;
    mapData = this.filterByFilterObject(mapData);

    if (this.state.clientState === 'policyDue') {
      //mapData = GetUsersPolicyDueData;
      if (mapData && GetUsersPolicyDueData && mapData.length > 0) {
        // mapData = mapData.filter(function (userA) {
        // 	let found = GetUsersPolicyDueData.findIndex(function (userB) {
        // 		return userB._id == userA._id;
        // 	});
        // 	return found >= 0;
        // });
        const userData = GetUsersPolicyDueData.map(policy => {
          const user = _.find(mapData, { _id: policy._id });
          return user ? { ...user, policyDueDate: policy.policyDueDate } : false;
        });
        const arr = _.sortBy(userData, o => o.policyDueDate);
        mapData = _.uniqBy(arr, '_id');
      }
    } else if (this.state.clientState === 'paidUpPolicyClients') {
      if (mapData && paidUpPolicyClients && mapData.length > 0) {
        const userData = paidUpPolicyClients
          .map(entry => {
            const clientId = entry._id,
              paidUpPolicies = entry.paidUpPolicies;
            const user = _.find(mapData, { _id: clientId });
            return user ? { ...user, paidUpPolicies: paidUpPolicies } : false;
          })
          .filter(user => !!user);
        mapData = paidUpPolicySort(userData);
      }
    } else if (
      this.state.clientState === 'life' ||
      this.state.clientState === 'illness' ||
      this.state.clientState === 'medical' ||
      this.state.clientState === 'saving' ||
      this.state.clientState === 'ilas'
    ) {
      let inCountData = policiesData[this.state.clientState];

      if (mapData && policiesData && inCountData) {
        mapData = mapData.filter(function (userA) {
          let found = inCountData.users.find(function (userB) {
            return userB._id === userA._id;
          });
          return found === undefined ? false : true;
        });
      }
    } else if (this.state.clientState === 'particular') {
      mapData = _.intersectionWith(
        mapData,
        preSelectedClientIds,
        (client, preSelectedClientId) => client._id === preSelectedClientId
      );
    }

    let filteredEmails = [];
    if (mapData && mapData.length > 0) {
      const runSpecialFilter = (identifier, filterFunction, valueFormatter) => {
        let filters = searchTerm.split(' ');
        let specialFilterIndex = filters.findIndex(element => element[0] === identifier);

        if (specialFilterIndex > -1) {
          let specialFilter = filters[specialFilterIndex];

          if (specialFilter.length > 1) {
            let value = specialFilter.substring(1);

            if (valueFormatter !== undefined) {
              value = valueFormatter(value);
            }

            mapData = mapData && mapData.filter(data => filterFunction(data, value));
          }
          filters.splice(specialFilterIndex, 1);
          searchTerm = filters.join(' ');
        }
      };

      // Special filter for + (phone number)
      if (searchTerm.length > 1 && searchTerm.indexOf('+') > -1) {
        runSpecialFilter('+', (data, value) => {
          let phoneNumber = data.factsheetId.phoneNumber;
          return phoneNumber !== undefined && phoneNumber !== null && phoneNumber.indexOf(value) > -1;
        });
      }

      // Special filter for > (monthly income)
      if (searchTerm.length > 1 && searchTerm.indexOf('>') > -1) {
        runSpecialFilter('>', (data, value) => {
          let income = parseFloat(data.factsheetId.monthlyIncome);
          return !isNaN(value) && income !== undefined && income !== null && income >= parseFloat(value);
        });
      }

      // Special filter for < (monthly expense)
      if (searchTerm.length > 1 && searchTerm.indexOf('<') > -1) {
        runSpecialFilter('<', (data, value) => {
          let expense = parseFloat(data.factsheetId.monthlyExpense);
          return !isNaN(value) && expense !== undefined && expense !== null && expense <= parseFloat(value);
        });
      }

      // Special filter for # (tag)
      if (searchTerm.length > 1 && searchTerm.indexOf('#') > -1) {
        runSpecialFilter('#', (data, value) => {
          let tags = data.factsheetId.tags;
          return (
            tags !== undefined &&
            tags !== null &&
            tags.length > 0 &&
            tags.findIndex(tag => tag.text.toLowerCase().indexOf(value.toLowerCase()) > -1) > -1
          );
        });
      }

      // Special filter for @x-y (age)
      let atIndex = searchTerm.indexOf('@'),
        minusIndex = searchTerm.indexOf('-');
      if (searchTerm.length > 3 && atIndex > -1 && minusIndex > atIndex) {
        runSpecialFilter(
          '@',
          (data, value) => {
            if (!data.factsheetId) {
              return false;
            }

            const age = getAgeFromDob(data.factsheetId.dob, data.factsheetId.age);

            if (age >= parseInt(value[0]) && age <= parseInt(value[1])) {
              return true;
            }

            return false;
          },
          value => value.split('-')
        );
      }

      // Special filter for ^ (birth month)
      if (searchTerm.length > 1 && searchTerm.indexOf('^') > -1) {
        runSpecialFilter('^', (data, value) => {
          if (
            data.factsheetId === undefined ||
            data.factsheetId.dob === undefined ||
            data.factsheetId.dob === null ||
            data.factsheetId.dob === ''
          ) {
            return false;
          }

          let targetMonth = parseInt(value);
          if (isNaN(targetMonth) || targetMonth < 1 || targetMonth > 12) {
            return false;
          }
          return moment(data.factsheetId.dob).month() === targetMonth - 1;
        });
      }

      // Special filter for ! (DISC)
      if (searchTerm.length > 1 && searchTerm.indexOf('!') > -1) {
        runSpecialFilter('!', (data, value) => {
          const personality = data.factsheetId.personality;
          return personality && personality.toLowerCase() === _.get(searchTerm, '[1]', '').toLowerCase();
        });
      }

      // Special filter for $ (job)
      if (searchTerm.length > 1 && searchTerm.indexOf('$') > -1) {
        runSpecialFilter('$', (data, value) => {
          const occupation = _.get(data, 'factsheetId.occupation', '');
          if (!occupation) {
            return false;
          }

          return occupation.toLowerCase().indexOf((value || '').toLowerCase()) > -1;
        });
      }

      if (searchTerm.length > 1 && searchTerm.indexOf('&') > -1) {
        runSpecialFilter('&', (data, value) => {
          const source = _.get(data, 'factsheetId.source', '');
          const referrerName = _.get(data, 'factsheetId.referrerName', '');
          return referrerName.toLowerCase().indexOf((value || '').toLowerCase()) > -1;
        });
      }

      filteredEmails =
        mapData &&
        mapData.filter(
          createFilter(searchTerm, [
            //'name',
            'factsheetId.name',
            'factsheetId.nickname',
            'email',
            'factsheetId.workLocation',
            'factsheetId.phoneNumber',
            'factsheetId.homeLocation',
            'phoneNumber',
            'createDate',
            'factsheetId.importance',
            'factsheetId.tags.text',
            'factsheetId.personality'
          ])
        );
    }
    let arr = [];
    let flag = false;
    if (this.state.clientState === 'Connected') {
      flag = true; // this flag = true is set here to know that user accessed this function
      filteredEmails.length > 0 &&
        filteredEmails.map(word => {
          if (word.advisorDetails && word.advisorDetails.length > 0) {
            word.advisorDetails.map(x => {
              if (x.email === user.email && x.connection && !word.passwordChangeKey) {
                arr.push(word);
              }
              return true;
            });
          }
          return true;
        });
      if (this.state.connectedId && arr.length > 0) {
        let id = this.state.connectedId;
        let newArr = arr.filter((client, index) => {
          return client._id === id;
        });
        arr = newArr;
      }
    } else if (this.state.clientState === 'Disconnected') {
      flag = true; // this flag = true is set here to know that user accessed this function
      filteredEmails.length > 0 &&
        filteredEmails.map(word => {
          if (word.advisorDetails && word.advisorDetails.length > 0) {
            word.advisorDetails.map(x => {
              if (x.email === user.email && !x.connection && !word.passwordChangeKey) {
                arr.push(word);
              }
              return true;
            });
          }
          return true;
        });
    } else if (this.state.clientState === 'Inactive') {
      flag = true; // this flag = true is set here to know that user accessed this function
      filteredEmails.length > 0 &&
        filteredEmails.map(word => {
          if (word.inactiveUser) {
            arr.push(word);
          }
          return true;
        });
    } else if (this.state.clientState === 'Pending') {
      flag = true; // this flag = true is set here to know that user accessed this function
      filteredEmails.length > 0 &&
        filteredEmails.map(word => {
          if (getClientConnectionStatus(word) === 'Pending') {
            arr.push(word);
          }
          // if (word.advisorDetails && word.advisorDetails.length > 0) {
          //   word.advisorDetails.map(x => {
          //     if (x.email === user.email && x.connection && word.passwordChangeKey) {
          //       arr.push(word);
          //     }
          //   });
          // } else if (!!word.inactiveUser && !!word.connectionRequestId) {
          //   arr.push(word);
          // }
          return true;
        });
    } else if (this.state.clientState === 'TrackOn') {
      flag = true; // this flag = true is set here to know that user accessed this function
      filteredEmails.length > 0 &&
        filteredEmails.map(word => {
          if (word.trackingId && word.trackingId.isActive) {
            arr.push(word);
          }
          // to sort the clients as per the tracking days
          arr = trackingSort(arr);
          return true;
        });
    } else if (this.state.clientState === 'recruitment') {
      flag = true; // this flag = true is set here to know that user accessed this function
      filteredEmails.forEach(word => {
        if (word?.factsheetId?.isRecruitment) {
          arr.push(word);
        }
        // to sort the clients as per the tracking days
        arr = trackingSort(arr);
      });
    } else if (this.state.clientState === 'hightolow') {
      flag = true; // this flag = true is set here to know that user accessed this function

      if (filteredEmails) {
        filteredEmails = filteredEmails.sort(function (a, b) {
          if (!a.validScore && !b.validScore) {
            let textA, textB;
            if (a.factsheetId) {
              let aUserDetail = a.factsheetId;
              textA = aUserDetail.name.toUpperCase();
            } else {
              textA = a.name.toUpperCase();
            }
            if (b.factsheetId) {
              let bUserDetail = b.factsheetId;
              textB = bUserDetail.name.toUpperCase();
            } else {
              textB = b.name.toUpperCase();
            }
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          } else {
            if (!a.validScore && b.validScore) {
              return 1;
            } else if (a.validScore && !b.validScore) {
              return -1;
            } else {
              let levelDiff = -(b.scoreLevel - a.scoreLevel);
              return levelDiff === 0 ? b.Totalscore - a.Totalscore : levelDiff;
            }
          }
        });
        filteredEmails.map((list, i) => {
          arr.push(list);
          return <option key={i} />;
        });
      }
    } else if (this.state.clientState === 'lowtohigh') {
      flag = true; // this flag = true is set here to know that user accessed this function
      if (filteredEmails) {
        filteredEmails = filteredEmails.sort(function (a, b) {
          if (!a.validScore && !b.validScore) {
            let textA, textB;
            if (a.factsheetId) {
              let aUserDetail = a.factsheetId;
              textA = aUserDetail.name.toUpperCase();
            } else {
              textA = a.name.toUpperCase();
            }
            if (b.factsheetId) {
              let bUserDetail = b.factsheetId;
              textB = bUserDetail.name.toUpperCase();
            } else {
              textB = b.name.toUpperCase();
            }
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          } else {
            if (!a.validScore && b.validScore) {
              return 1;
            } else if (a.validScore && !b.validScore) {
              return -1;
            } else {
              let levelDiff = -(a.scoreLevel - b.scoreLevel);
              return levelDiff === 0 ? a.Totalscore - b.Totalscore : levelDiff;
            }
          }
        });
        filteredEmails.map((list, i) => {
          arr.push(list);
          return <option key={i} />;
        });
      }
    } else if (this.state.clientState === 'Nameatoz') {
      flag = true; // this flag = true is set here to know that user accessed this function
      if (filteredEmails) {
        filteredEmails = filteredEmails.sort(function (a, b) {
          let textA, textB;
          if (a.factsheetId) {
            let aUserDetail = a.factsheetId;
            textA = aUserDetail.name.toUpperCase();
          } else {
            textA = a.name.toUpperCase();
          }
          if (b.factsheetId) {
            let bUserDetail = b.factsheetId;
            textB = bUserDetail.name.toUpperCase();
          } else {
            textB = b.name.toUpperCase();
          }
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        filteredEmails.map((list, i) => {
          arr.push(list);
          return <option key={i} />;
        });
      }
    } else if (this.state.clientState === 'Nameztoa') {
      flag = true; // this flag = true is set here to know that user accessed this function
      if (filteredEmails) {
        filteredEmails = filteredEmails.sort(function (a, b) {
          let textA, textB;
          if (a.factsheetId) {
            let aUserDetail = a.factsheetId;
            textA = aUserDetail.name.toUpperCase();
          } else {
            textA = a.name.toUpperCase();
          }
          if (b.factsheetId) {
            let bUserDetail = b.factsheetId;
            textB = bUserDetail.name.toUpperCase();
          } else {
            textB = b.name.toUpperCase();
          }
          return textB < textA ? -1 : textB > textA ? 1 : 0;
        });
        filteredEmails.map((list, i) => {
          arr.push(list);
          return <option key={i} />;
        });
      }
    } else if (this.state.clientState === 'timeNew') {
      flag = true; // this flag = true is set here to know that user accessed this function
      if (filteredEmails) {
        filteredEmails = filteredEmails.sort(function (a, b) {
          return (
            new Date(b.createdate ? b.createdate : b.createDate) - new Date(a.createdate ? a.createdate : a.createDate)
          );
        });
        filteredEmails.map((list, i) => {
          arr.push(list);
          return <option key={i} />;
        });
      }
    } else if (this.state.clientState === 'timeOld') {
      flag = true; // this flag = true is set here to know that user accessed this function
      if (filteredEmails) {
        filteredEmails = filteredEmails.sort(function (a, b) {
          return (
            new Date(a.createdate ? a.createdate : a.createDate) - new Date(b.createdate ? b.createdate : b.createDate)
          );
        });

        filteredEmails.map((list, i) => {
          arr.push(list);
          return <option key={i} />;
        });
      }
    } else if (this.state.clientState === 'lucky') {
      flag = true; // this flag = true is set here to know that user accessed this function

      if (filteredEmails) {
        if (filteredEmails.length > 0) {
          const luckyClient = filteredEmails.find(client => client._id === selectedLuckyClient._id);
          filteredEmails = luckyClient ? [luckyClient] : [];
          filteredEmails.map((list, i) => {
            arr.push(list);
            return <option key={i} />;
          });
        }
      }
    } else if (this.state.clientState === 'policyDue') {
      flag = true;
      if (filteredEmails) {
        filteredEmails.map((list, i) => {
          arr.push(list);
          return <option key={i} />;
        });
      }
    } else if (this.state.clientState === 'paidUpPolicyClients') {
      flag = true;
      if (filteredEmails) {
        filteredEmails.map((list, i) => arr.push(list));
      }
    } else if (this.state.clientState === 'birthdayCount') {
      flag = true; // this flag = true is set here to know that user accessed this function
      if (filteredEmails) {
        if (filteredEmails.length > 0) {
          let clientData = (filteredEmails = filteredEmails.filter(function (userA) {
            let found =
              GetConnectedUsersBirthday && GetConnectedUsersBirthday.birthdayUsers
                ? GetConnectedUsersBirthday.birthdayUsers.findIndex(function (userB) {
                  return userB._id === userA._id;
                })
                : -1;
            return found >= 0;
          }));

          if (clientData) {
            let arr1 = [];
            let arr2 = [];
            // filteredEmails = _.sortBy(clientData, o =>
            // 	/*moment.parseZone is equivalent to parsing the string and using moment utcoffset and to handle the date for -utc as well as it works for +utc*/
            // 	moment.parseZone(o.factsheetId.dob).format('MM/DD')
            // );
            // let currentMonth = parseInt(
            // 	moment()
            // 		.add(2, 'M')
            // 		.format('MM')
            // );
            let currentMonth = parseInt(moment().format('MM'));
            // eslint-disable-next-line
            clientData.map(list => {
              let dobMonth = parseInt(moment(list.factsheetId.dob).format('MM'));
              if (dobMonth === 11 || dobMonth === 12) {
                arr1.push(list);
              }
              if (dobMonth < 11) {
                arr2.push(list);
              }
              // return true;
            });
            arr1 = birthdaySort(arr1);
            arr2 = birthdaySort(arr2);
            if (arr2.length > 0 && currentMonth !== 11 && currentMonth !== 12) {
              filteredEmails = arr2.concat(arr1);
            } else {
              filteredEmails = arr1.concat(arr2);
            }
          }
          filteredEmails.map((list, i) => {
            arr.push(list);
            return <option key={i} />;
          });
        }
      }
    } else if (this.state.clientState === 'dateBackBirthdayCount') {
      flag = true; // this flag = true is set here to know that user accessed this function
      if (filteredEmails) {
        if (filteredEmails.length > 0) {
          let clientDataDateBack = filteredEmails.filter(function (userA) {
            let found = PastBirthdayOfUsers.findIndex(function (userB) {
              return userB._id === userA._id;
            });
            return found >= 0;
          });
          let arr1 = [];
          let arr2 = [];
          // if (clientDataDateBack) {
          // 	filteredEmails = _.sortBy(clientDataDateBack, o =>
          // 		/*moment.parseZone is equivalent to parsing the string and using moment utcoffset and to handle the date for -utc as well as it works for +utc*/
          // 		moment.parseZone(o.factsheetId.dob).format('MM/DD')
          // 	);
          // }
          let currentMonth = parseInt(moment().format('MM'));
          // eslint-disable-next-line
          clientDataDateBack.map(list => {
            let dobMonth = parseInt(moment(list.factsheetId.dob).format('MM'));
            if (dobMonth === 1 || dobMonth === 2) {
              arr1.push(list);
            }
            if (dobMonth >= 3) {
              arr2.push(list);
            }
            // return true;
          });
          arr1 = _.sortBy(arr1, o =>
            /*moment.parseZone is equivalent to parsing the string and using moment utcoffset and to handle the date for -utc as well as it works for +utc*/
            moment.parseZone(o.factsheetId.dob).format('MM/DD')
          );
          arr2 = _.sortBy(arr2, o =>
            /*moment.parseZone is equivalent to parsing the string and using moment utcoffset and to handle the date for -utc as well as it works for +utc*/
            moment.parseZone(o.factsheetId.dob).format('MM/DD')
          );
          if (arr1.length > 0 && currentMonth !== 1 && currentMonth !== 2) {
            filteredEmails = arr1.concat(arr2);
          } else {
            filteredEmails = arr2.concat(arr1);
          }
          filteredEmails.map((list, i) => {
            arr.push(list);
            return <option key={i} />;
          });
        }
      }
    } else if (this.state.clientState === 'Long time no see') {
      /* to see the long time no see user (latest meeting user)*/
      flag = true;
      let clientArray = [];
      // eslint-disable-next-line
      filteredEmails.map(word => {
        if (word.advisorDetails && word.advisorDetails.length > 0) {
          // eslint-disable-next-line
          word.advisorDetails.map(x => {
            if (
              x.email === user.email &&
              x.connection &&
              word.factsheetId.latestMeetingDate !== null &&
              word.factsheetId.latestMeetingDate !== undefined
            ) {
              clientArray.push(word);
            }
            // return true;
          });
        } else {
          if (
            word.inactiveUser &&
            word.factsheetId.latestMeetingDate !== null &&
            word.factsheetId.latestMeetingDate !== undefined
          ) {
            clientArray.push(word);
          }
        }
        // return true;
      });
      if (clientArray) {
        filteredEmails = _.sortBy(clientArray, o => o.factsheetId.latestMeetingDate);
      }
      //arr = _.take(filteredEmails, 10);
      arr = filteredEmails;
      /*To sort the next  one month reminder*/
    } else if (this.state.clientState === 'remindMe') {
      /* Add reminder option and filter data from BigData to client Page*/
      flag = true;
      let clientArray = [];
      // eslint-disable-next-line
      filteredEmails.forEach(word => {
        if (isClientRemindMe(word, user.email)) {
          clientArray.push(word);
        }
      });
      if (clientArray) {
        filteredEmails = remindMeSort(clientArray);
      }
      arr = filteredEmails;
      /*To sort the today reminder*/
    } else if (this.state.clientState === 'Remind me Today') {
      flag = true;
      let clientArray = [];
      /*To get the local date of country*/
      let currentDate = new Date();
      let month = '' + (currentDate.getMonth() + 1); /*to get the month of current date*/
      let day = '' + currentDate.getDate(); /*to get the day of current date*/
      let year = currentDate.getFullYear(); /*to get the year of current date*/
      if (month.length < 2) month = '0' + month; /*to add 0 if before number if month lies between 1 to 9*/
      if (day.length < 2) day = '0' + day; /*to add 0 if before number if day lies between 1 to 9*/
      let todayDate = [year, month, day].join('-'); /*join the year , month and day to make today date in YYYY-MM-DD*/
      filteredEmails.map(word => {
        //Filter email on basic of Today reminder
        if (word.advisorDetails && word.advisorDetails.length > 0) {
          word.advisorDetails.map(x => {
            //condition of filter data for upcoming month
            if (
              x.email === user.email &&
              x.connection &&
              word.factsheetId.fsReminderActive &&
              word.factsheetId.fsReminderActive !== undefined &&
              word.factsheetId.fsReminderActive !== null &&
              word.factsheetId.fsReminderActive !== '' &&
              word.factsheetId.fsReminderDate !== null &&
              word.factsheetId.fsReminderDate !== undefined &&
              word.factsheetId.fsReminderDate !== ''
            ) {
              //condition of filter data for upcoming month of inactive clients
              /*comparing the date of rmeinder date to today date by isSame function , the isSame function returns the boolean value*/
              if (moment(x.factsheetId.fsReminderDate, 'YYYY-MM-DD').isSame(todayDate) === true) {
                clientArray.push(word);
              }
            }
            return true;
          });
        } else {
          if (
            word.inactiveUser &&
            word.factsheetId.fsReminderActive &&
            word.factsheetId.fsReminderActive !== null &&
            word.factsheetId.fsReminderActive !== undefined &&
            word.factsheetId.fsReminderActive !== '' &&
            word.factsheetId.fsReminderDate !== null &&
            word.factsheetId.fsReminderDate !== undefined &&
            word.factsheetId.fsReminderDate !== ''
          ) {
            //condition of filter data for upcoming month of inactive clients
            /*comparing the date of rmeinder date to today date by isSame function , the isSame function returns the boolean value*/
            if (moment(word.factsheetId.fsReminderDate, 'YYYY-MM-DD').isSame(todayDate) === true) {
              clientArray.push(word);
            }
          }
        }
        return true;
      });
      if (clientArray) {
        filteredEmails = _.sortBy(clientArray, o => o.factsheetId.fsReminderDate).reverse();
      }
      arr = filteredEmails;
      /*To sort the past reminder*/
    } else if (this.state.clientState === 'allReminder') {
      flag = true;
      let clientArray = [];
      /*To get the local date of country*/
      let currentDate = new Date();
      let month = '' + (currentDate.getMonth() + 1); /*to get the month of current date*/
      let day = '' + currentDate.getDate(); /*to get the day of current date*/
      if (month.length < 2) month = '0' + month; /*to add 0 if before number if month lies between 1 to 9*/
      if (day.length < 2) day = '0' + day; /*to add 0 if before number if day lies between 1 to 9*/
      filteredEmails.map(word => {
        //Filter email on basic of Today reminder
        if (word.advisorDetails && word.advisorDetails.length > 0) {
          word.advisorDetails.map(x => {
            //condition of filter data for upcoming month
            if (
              x.email === user.email &&
              x.connection &&
              word.factsheetId.fsReminderActive &&
              word.factsheetId.fsReminderActive !== undefined &&
              word.factsheetId.fsReminderActive !== null &&
              word.factsheetId.fsReminderActive !== '' &&
              word.factsheetId.fsReminderDate !== null &&
              word.factsheetId.fsReminderDate !== undefined &&
              word.factsheetId.fsReminderDate !== ''
            ) {
              /*comparing the date of reminder date to today date by isBefore function , the isBefore function returns the boolean value*/
              // if (
              // 	moment(x.factsheetId.fsReminderDate, 'YYYY-MM-DD').isBefore(
              // 		todayDate
              // 	) === true
              // ) {
              clientArray.push(word);
              //}
            }
            return true;
          });
        } else {
          if (
            word.inactiveUser &&
            word.factsheetId.fsReminderActive &&
            word.factsheetId.fsReminderActive !== undefined &&
            word.factsheetId.fsReminderActive !== null &&
            word.factsheetId.fsReminderActive !== '' &&
            word.factsheetId.fsReminderDate !== null &&
            word.factsheetId.fsReminderDate !== undefined &&
            word.factsheetId.fsReminderDate !== ''
          ) {
            /*comparing the date of reminder date to today date by isBefore function , the isBefore function returns the boolean value*/
            // if (
            // 	moment(word.factsheetId.fsReminderDate, 'YYYY-MM-DD').isBefore(
            // 		todayDate
            // 	) === true
            // ) {
            clientArray.push(word);
            //}
          }
        }
        return true;
      });
      if (clientArray) {
        filteredEmails = _.sortBy(clientArray, o => o.factsheetId.fsReminderDate);
      }
      arr = filteredEmails;

      if (this.state.remindMeId && filteredEmails.length > 0) {
        let id = this.state.remindMeId;
        let newArr = filteredEmails.filter((client, index) => {
          return client._id === id;
        });
        arr = newArr;
      }
    } else if (this.state.clientState === 'TodayAndTomorrowBirthday') {
      /*Filter for today and tomorrow birthday of connected , pending and inactive clients*/
      flag = true;
      let birthdayClientArray = [];
      let currentMonth, currentDay;
      let tomorrowMonth, tomorrowDay;
      if (this.props.location.state && this.props.location.state.BD) {
        let date = this.props.location.state.BD;
        currentMonth = moment(date).format('MM');
        currentDay = moment(date).format('DD');

        tomorrowDay = moment(date)
          .add(1, 'days')
          .format('DD');
        tomorrowMonth = moment(date)
          .add(1, 'days')
          .format('MM');
      } else {
        /*To get the local date of country*/
        currentMonth = moment().format('MM');
        currentDay = moment().format('DD');

        tomorrowDay = moment()
          .add(1, 'days')
          .format('DD');
        tomorrowMonth = moment()
          .add(1, 'days')
          .format('MM');
      }

      filteredEmails.map(word => {
        if (word.advisorDetails && word.advisorDetails.length > 0) {
          word.advisorDetails.map(x => {
            /*To check the today and tomorrow birthday of client to open the birthday popup*/

            if (
              x.email === user.email &&
              x.connection &&
              word.factsheetId.dob &&
              word.factsheetId.dob !== undefined &&
              word.factsheetId.dob !== null &&
              word.factsheetId.dob !== ''
            ) {
              /*condition of filter data for today and tomorrow of connected and pending clients*/
              if (
                (moment(x.factsheetId.dob).format('MM') === currentMonth &&
                  moment(x.factsheetId.dob).format('DD') === currentDay) ||
                (moment(x.factsheetId.dob).format('MM') === tomorrowMonth &&
                  moment(x.factsheetId.dob).format('DD') === tomorrowDay)
              ) {
                birthdayClientArray.push(word);
              }
            }
            return true;
          });
        } else {
          if (
            word.inactiveUser &&
            word.factsheetId.dob &&
            word.factsheetId.dob !== null &&
            word.factsheetId.dob !== undefined &&
            word.factsheetId.dob !== ''
          ) {
            /*condition of filter data for today and tomorrow of inactive clients*/
            /*comparing the date of dob date to today date and tomorrow date */
            if (
              (moment(word.factsheetId.dob).format('MM') === currentMonth &&
                moment(word.factsheetId.dob).format('DD') === currentDay) ||
              (moment(word.factsheetId.dob).format('MM') === tomorrowMonth &&
                moment(word.factsheetId.dob).format('DD') === tomorrowDay)
            ) {
              birthdayClientArray.push(word);
            }
          }
        }
        return true;
      });
      if (birthdayClientArray) {
        filteredEmails = birthdaySort(birthdayClientArray);
      }
      arr = filteredEmails;
    } else if (this.state.clientState === 'virus') {
      flag = true;
      filteredEmails = _.intersectionBy(this.state.virusRelatedClients, filteredEmails, '_id');
      arr = filteredEmails;
    } else if (this.state.clientState === 'ClientToSend') {
      flag = true; // this flag = true is set here to know that user accessed this function
      filteredEmails.length > 0 &&
        filteredEmails.map(word => {
          if (word.advisorDetails && word.advisorDetails.length > 0) {
            word.advisorDetails.map(x => {
              if (x.email === user.email && x.connection && !word.passwordChangeKey) {
                arr.push(word);
              }
              return true;
            });
          }
          return true;
        });
    } else if (this.state.clientState === 'longPendingCases') {
      flag = true;
      const nowMoment = moment();
      filteredEmails = filteredEmails.filter(client => {
        const trackingStartDate = _.get(client, 'trackingId.startDate');
        return trackingStartDate && nowMoment.diff(moment(trackingStartDate), 'days', true) > TRACKING_MAX_DAYS;
      });
      arr = trackingSort(filteredEmails);
    } else if (this.state.clientState === 'imported') {
      flag = true;
      filteredEmails = filteredEmails.filter(client => {
        const isImported = _.get(client, 'factsheetId.isImported', false);
        return isImported;
      });
      arr = filteredEmails;
    }

    filteredEmails = flag ? arr : filteredEmails;

    // pagination
    let pageTotalCount = filteredEmails.length;
    let maxPage = Math.ceil(pageTotalCount / defaultPageSize) - 1;
    this.maxPage = maxPage;
    let formattedPage = Math.min(maxPage, page);
    filteredEmails = filteredEmails.slice(formattedPage * defaultPageSize, (formattedPage + 1) * defaultPageSize);

    const clientEntryShowDetails = isWidthExceed(1024);

    const setLoading = (
      /** @type {boolean} */ isLoading
    ) => {
      this.setState({
        ...this.state,
        loading: isLoading
      });
    }

    return (
      <AppContentContainer>
        <ImportContactsDialog
          open={this.state.importContactsDialogOpen}
          onClose={() => this.setState({ importContactsDialogOpen: false })}
          refreshClients={this.resetSearch.bind(this)}
        />

        <div>
          {((this.props.fetchClientPhase === 'loading' && !this.props.lazyChange) ||
            this.props.updateLanguagePhase === 'loading' ||
            this.props.updateFactSheetPhase === 'loading' ||
            !this.state.firstRendered ||
            this.state.loading) && <LoadingBackdrop open={true} />}

          <div className="client">
            <NotificationCenterModal
              open={showModal}
              onClose={() => this.closeNotificationModal()}
              history={this.props.history}
              location={this.props.location}
              handleLinkToClientState={this.handleLinkToClientState.bind(this)}
            />
            <TopNavigationPortal>
              <IconButton className="plus-btn-client notification-btn" onClick={this.handleBellClick.bind(this)}>
                <NotificationBellButton
                  hasNotifications={hasNotifications}
                  newNotificationNumber={newNotificationNumber}
                />
              </IconButton>
              <div className="plus-btn-client client-guideline-icon">
                <div>
                  <TopNavHelpButton
                    ref={this.topNavHelpButtonRef}
                    onSelectCurrentTutorial={this.runGuideline}
                    buttonProps={{ notClientStyle: true }}
                  />
                </div>
              </div>
            </TopNavigationPortal>

            <MySwipeable handleSwipe={this.handleSwipe.bind(this)}>
              <div className="client-container">
                <StickyHeader>
                  <div>
                    <Grid container alignItems="center" className="client-search" wrap="nowrap">
                      <Grid
                        item
                        className="client-base-indicator client-base-indicator-animation"
                        style={{
                          backgroundColor: clientBaseColor
                        }}
                        onClick={this.openClientBaseModal}
                      />

                      <Grid item className="client-select">
                        <Grid container wrap="nowrap">
                          <Grid item style={{ flexGrow: 1 }}>
                            <Select
                              native
                              name="clientState"
                              onChange={event => {
                                this.handleChange(event);
                                this.setState({
                                  connectedId: '',
                                  remindMeId: '',
                                  page: 0
                                });
                              }}
                              value={this.state.clientState}
                              style={{ width: '100%', fontWeight: '500' }}
                            >
                              <option value="all">{intl.formatMessage({ id: 'All' })}</option>
                              <option value="recruitment">{intl.formatMessage({ id: 'recruit-lead' })}</option>
                              <option value="allReminder">{intl.formatMessage({ id: 'All Remind me' })}</option>
                              <option value="hightolow">{intl.formatMessage({ id: 'C.P.I Hi to Lo' })}</option>
                              <option value="Nameatoz">{intl.formatMessage({ id: 'Name A to Z' })}</option>
                              {/*<option value="lowtohigh">
									                                {intl.formatMessage({ id: 'C.P.I Lo to Hi' })}
									                              </option>*/}
                              <option value="Inactive">{intl.formatMessage({ id: 'Inactive' })}</option>
                              <option value="Pending">{intl.formatMessage({ id: 'Pending' })}</option>
                              <option value="Connected">{intl.formatMessage({ id: 'Connected' })}</option>
                              <option value="Disconnected">{intl.formatMessage({ id: 'Disconnected' })}</option>
                              <option value="longPendingCases">
                                {intl.formatMessage({ id: 'long-pending-cases' })}
                              </option>
                              <option value="imported">{intl.formatMessage({ id: 'Imported' })}</option>
                              {/* <option value="timeNew">
									                                {intl.formatMessage({ id: 'Time New to Old' })}
									                              </option>*/}
                              {/*<option value="timeOld">
									                                {intl.formatMessage({ id: 'Time Old to New' })}
									                              </option>*/}

                              {/*<option value="Nameztoa">
									                                {intl.formatMessage({ id: 'Name Z to A' })}
									                              </option>*/}

                              {this.state.clientState === 'lucky' ? (
                                <option value="lucky" className="hide-option">
                                  {intl.formatMessage({ id: 'Lucky' })}
                                </option>
                              ) : (
                                ''
                              )}
                              {this.state.clientState === 'policyDue' ? (
                                <option value="policyDue" className="hide-option">
                                  {intl.formatMessage({ id: 'Policy Due' })}
                                </option>
                              ) : (
                                ''
                              )}
                              {this.state.clientState === 'paidUpPolicyClients' && (
                                <option value="paidUpPolicyClients" className="hide-option">
                                  {intl.formatMessage({ id: 'Paid-up policy' })}
                                </option>
                              )}
                              {this.state.clientState === 'birthdayCount' ? (
                                <option value="birthdayCount" className="hide-option">
                                  {intl.formatMessage({
                                    id: 'Upcoming Birthday'
                                  })}
                                </option>
                              ) : (
                                ''
                              )}
                              {this.state.clientState === 'life' ? (
                                <option value="life" className="hide-option">
                                  {intl.formatMessage({ id: 'Without Life' })}
                                </option>
                              ) : (
                                ''
                              )}
                              {this.state.clientState === 'illness' ? (
                                <option value="illness" className="hide-option">
                                  {intl.formatMessage({ id: 'Without C.I.' })}
                                </option>
                              ) : (
                                ''
                              )}
                              {this.state.clientState === 'medical' ? (
                                <option value="medical" className="hide-option">
                                  {intl.formatMessage({
                                    id: 'Without Medical'
                                  })}
                                </option>
                              ) : (
                                ''
                              )}
                              {this.state.clientState === 'saving' ? (
                                <option value="saving" className="hide-option">
                                  {intl.formatMessage({
                                    id: 'Without Saving'
                                  })}
                                </option>
                              ) : (
                                ''
                              )}
                              {this.state.clientState === 'ilas' ? (
                                <option value="ilas" className="hide-option">
                                  {intl.formatMessage({ id: 'Without ILAS' })}
                                </option>
                              ) : (
                                ''
                              )}
                              {this.state.clientState === 'particular' ? (
                                <option value="particular" className="hide-option">
                                  {intl.formatMessage({
                                    id: 'Selected Clients'
                                  })}
                                </option>
                              ) : (
                                ''
                              )}
                              {this.state.clientState === 'Long time no see' ? (
                                <option value="Long time no see" className="hide-option">
                                  {intl.formatMessage({
                                    id: 'Long time no see'
                                  })}
                                </option>
                              ) : (
                                ''
                              )}
                              {this.state.clientState === 'remindMe' ? (
                                <option value="remindMe" className="hide-option">
                                  {intl.formatMessage({ id: 'Remind me' })}
                                </option>
                              ) : (
                                ''
                              )}
                              {this.state.clientState === 'Remind me Today' ? (
                                <option value="Remind me Today" className="hide-option">
                                  {intl.formatMessage({
                                    id: 'Remind me Today'
                                  })}
                                </option>
                              ) : (
                                ''
                              )}
                              {this.state.clientState === 'dateBackBirthdayCount' ? (
                                <option value="dateBackBirthdayCount" className="hide-option">
                                  {intl.formatMessage({ id: 'Date Back' })}
                                </option>
                              ) : (
                                ''
                              )}
                              {this.state.clientState === 'TodayAndTomorrowBirthday' ? (
                                <option value="TodayAndTomorrowBirthday" className="hide-option">
                                  {intl.formatMessage({
                                    id: 'Birthday_Pop_Up'
                                  })}
                                </option>
                              ) : (
                                ''
                              )}
                              {this.state.clientState === 'virus' ? (
                                <option value="virus" className="hide-option">
                                  {intl.formatMessage({ id: 'covid-cases' })}
                                </option>
                              ) : (
                                ''
                              )}
                              {this.state.clientState === 'ClientToSend' ? (
                                <option value="ClientToSend" className="hide-option">
                                  {intl.formatMessage({ id: 'clients-to-send' })}
                                </option>
                              ) : (
                                ''
                              )}
                            </Select>
                          </Grid>
                          {/* <Grid item>
                            <IconButton
                              style={{ width: 24, height: 36, paddingTop: isIOS ? 8 : 14 }}
                              onClick={this.openAdviserHelpDialog}
                            >
                              <img style={{ width: 24 }} src="img/help/stepbystep.svg" alt="go-to-help-center" />
                            </IconButton>
                          </Grid> */}
                          <Grid item>
                            <IconButton
                              style={{ width: 24, height: 36, paddingTop: isIOS ? 8 : 14 }}
                              onClick={this.resetSearch.bind(this)}
                            >
                              <RefreshIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item className="search-form" style={{ flexGrow: 1 }}>
                        <ClientSearchField
                          placeholder={
                            _.isEmpty(this.getRequiredFilterObject(this.state.filterObject))
                              ? intl.formatMessage({
                                id: 'Smart Search'
                              })
                              : intl.formatMessage({ id: 'using_filter' })
                          }
                          className={
                            _.isEmpty(this.getRequiredFilterObject(this.state.filterObject))
                              ? 'search-form-input '
                              : 'search-form-input search-placeholder-filtered '
                          }
                          onChange={this.searchUpdated}
                          value={this.state.searchTerm}
                          onClickPowerSearch={this.powerSearchModal}
                        />
                        {/*<span className="input-group-btn">*/}
                        {/*  <button*/}
                        {/*    className="btn btn-search"*/}
                        {/*    onClick={this.powerSearchModal}*/}
                        {/*    type="button"*/}
                        {/*    style={{ height: '32px' }}*/}
                        {/*  >*/}
                        {/*    <img alt="" src="img/client-search.png" />*/}
                        {/*  </button>*/}
                        {/*</span>*/}
                        {/*<SearchInput*/}
                        {/*  placeholder={*/}
                        {/*    _.isEmpty(this.getRequiredFilterObject(this.state.filterObject))*/}
                        {/*      ? intl.formatMessage({*/}
                        {/*          id: 'Smart Search'*/}
                        {/*        })*/}
                        {/*      : intl.formatMessage({ id: 'using_filter' })*/}
                        {/*  }*/}
                        {/*  className={*/}
                        {/*    _.isEmpty(this.getRequiredFilterObject(this.state.filterObject))*/}
                        {/*      ? 'search-form-input '*/}
                        {/*      : 'search-form-input search-placeholder-filtered '*/}
                        {/*  }*/}
                        {/*  onChange={this.searchUpdated}*/}
                        {/*  value={this.state.searchTerm}*/}
                        {/*/>*/}
                      </Grid>

                      {this.state.width <= 800 && (
                        <Grid item style={isIOS ? { marginTop: -2 } : {}}>
                          <IconButton onClick={this.toggleDrawer()} style={{ padding: 4 }} className="filter-toggle">
                            {!!newReferralCount ? (
                              <SearchFilterBadge badgeContent={newReferralCount} />
                            ) : (
                              <Badge
                                variant="dot"
                                className="navigation-referral-new-badge  client_badge"
                                color="error"
                                badgeContent=""
                              >
                                {''}
                              </Badge>
                            )}
                            <TuneOutlinedIcon color="primary" />
                            {window.innerWidth > 450 && (
                              <Typography color="primary" style={{ whiteSpace: 'nowrap', marginLeft: 3 }}>
                                {intl.formatMessage({ id: 'filter_words' })}
                              </Typography>
                            )}
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                </StickyHeader>

                <div className="page-body">
                  <div className="">
                    <SwipeableDrawer
                      anchor="right"
                      open={this.state.searchFilterOpen}
                      onClose={this.toggleDrawer()}
                      onOpen={this.toggleDrawer()}
                      disableBackdropTransition={!iOS}
                      disableDiscovery={iOS}
                      disableSwipeToOpen={true}
                      className="client-page-filter-SwipeableDrawer"
                    >
                      <SearchFilter
                        intl={intl}
                        enableEditFamily={false}
                        refreshData={this.refreshData}
                        advisorCustomFiltersState={advisorCustomFiltersState}
                        openResetFilterModal={() => this.setState({ resetFilterModalOpen: true })}
                        getFilterObject={this.getFilterObject}
                        powerSearchModal={this.powerSearchModal}
                        families={this.state.families}
                        usedTags={usedTags}
                        setState={this.state.filterObject}
                        drawer={true}
                        openFilterModal={i => this.setState({ customFilterModalOpen: true, customFilterIndex: i })}
                        filters={adviserCustomFilters}
                        applyCustomFilter={this.applyCustomFilter}
                        aiSuggestClient={this.aiSuggestClient}
                        newReferralCount={newReferralCount}
                        history={history}
                        referrerNames={referrerNames}
                        resetSearch={this.resetSearch.bind(this)}
                        closeDrawer={this.toggleDrawer()}
                      />
                    </SwipeableDrawer>
                  </div>

                  <div
                    className="client-page-desktop-wrapper"
                    style={this.state.width > 800 ? { overflowY: 'hidden' } : undefined}
                  >
                    <div
                      className="client-list"
                      id="accordion-main"
                      role="tablist"
                      aria-multiselectable="true"
                      style={
                        this.state.width > 800
                          ? {
                            overflowY: 'auto',
                            height: `calc(${window.innerHeight}px - 122px - ${!showPadding ? 50 : 0
                              }px - var(--bottom-padding))`
                          }
                          : undefined
                      }
                    >
                      {clientState === 'recruitment' && (
                        <div style={recruitmentHelpStyle}>
                          <AladdinHint title={intl.formatMessage({ id: 'recruitment-help' })} />
                        </div>
                      )}
                      {filteredEmails &&
                        filteredEmails.length > 0 &&
                        filteredEmails.map((client, index) => {
                          return (
                            <ClientEntry
                              key={index}
                              client={client}
                              clientState={clientState}
                              expanded={selectedId === client._id}
                              showDetails={clientEntryShowDetails}
                              selectedClient={!!selectedId}
                              onChange={(event, expanded) => this.selectClient(client, expanded)}
                              applySearchFilter={this.applySearchFilter}
                              setLoading={setLoading}
                              summaryPrepend={
                                <Fragment>
                                  {clientState === 'policyDue' && (
                                    <ClientEntryMailButton
                                      onClick={event => {
                                        event.stopPropagation();
                                        toggleDialog('greeting', {
                                          title: intl.formatMessage({ id: 'Client Reminder' }),
                                          phone: _.get(client, 'factsheetId.phoneNumber'),
                                          whatsapp: _.get(client, 'factsheetId.whatsapp'),
                                          email: getClientEmail(client),
                                          getMessage: language =>
                                            getPolicyDueGreetingMessage(client, policyDueData, language)
                                        });
                                      }}
                                    />
                                  )}
                                  {clientState === 'paidUpPolicyClients' && (
                                    <ClientEntryMailButton
                                      onClick={event => {
                                        event.stopPropagation();
                                        togglePaidUpPoliciesGreetingDialog(toggleDialog, intl, client);
                                      }}
                                    />
                                  )}
                                  {(clientState === 'birthdayCount' || clientState === 'TodayAndTomorrowBirthday') && (
                                    <ClientEntryMailButton
                                      onClick={event => {
                                        event.stopPropagation();
                                        toggleBirthdayGreetingDialog(toggleDialog, intl, client);
                                      }}
                                    />
                                  )}
                                  {clientState === 'virus' && (
                                    <ClientEntryMailButton
                                      onClick={event => {
                                        event.stopPropagation();
                                        this.setState({ covidShareDialog: { client: client } });
                                      }}
                                    />
                                  )}
                                  {(clientState === 'remindMe' ||
                                    clientState === 'Remind me Today' ||
                                    clientState === 'allReminder') && (
                                      <ClientEntryMailButton
                                        onClick={event => {
                                          event.stopPropagation();
                                          toggleMeetingNoteDialog(toggleDialog, client);
                                        }}
                                      />
                                    )}
                                </Fragment>
                              }
                              action={this.state.isMovePolicy ? 'movePolicy' : undefined}
                              movePolicyOptions={{
                                clientIdToMoveFrom: this.state.portfolioToMove
                                  ? this.state.portfolioToMove.userId
                                  : undefined,
                                onMovePolicy: client => this.handleMovePolicy(client)
                              }}
                              isClientConnected={
                                client.advisorDetails &&
                                !!client.advisorDetails.find(entry => entry.email === user.email && entry.connection)
                              }
                              refreshData={this.refreshData}
                            />
                          );
                        })}
                      {filteredEmails && filteredEmails.length > 0 && (
                        <div id="client-pagination">
                          <Pagination
                            page={this.state.page + 1}
                            count={this.maxPage + 1}
                            onChange={(e, i) => this.setState({ page: i - 1 })}
                          />
                        </div>
                      )}
                      <Fab
                        className="fab"
                        size="medium"
                        variant="round"
                        onClick={() => { this.setState({ menuOpen: !this.state.menuOpen }) }}
                        style={{ backgroundColor: "#3966F8", zIndex: 1001 }}
                      >
                        {this.state.menuOpen ?
                          <CloseIcon style={{ fontSize: 24, color: "white" }} /> :
                          <AddIcon style={{ fontSize: 28, color: "white" }} />
                        }
                      </Fab>

                      {this.state.menuOpen ?
                        <Backdrop open={this.state.menuOpen} onClick={() => this.setState({ menuOpen: false })} style={{ zIndex: 1000, color: '#fff', }}>
                          <Box
                            className="fab fab3"
                            onClick={() => {
                              tracking('Click on Add Client (floating) button - Client');
                              this.addClient();
                            }}
                          >
                            <Box className='fabText'>{intl.formatMessage({ id: 'add-client-manually' })}</Box>
                            <Fab
                              size="medium"
                              variant="round"
                              style={{ backgroundColor: "#EFF3FD" }}
                            >
                              <CreateIcon color='primary' style={{ fontSize: 24 }} />
                            </Fab>
                          </Box>

                          <Box className="fab fab2" onClick={this.openImportAllContactsDialog}>
                            <Box className='fabText'>{intl.formatMessage({ id: 'import-phone-contacts' })}</Box>
                            <Fab
                              size="medium"
                              variant="round"
                              style={{ backgroundColor: "#EFF3FD" }}
                            >

                              <ContactsIcon color='primary' style={{ fontSize: 24 }} />
                            </Fab>
                          </Box>

                          <Box className="fab fab1" onClick={this.excelImportClients}>
                            <Box className='fabText'>{intl.formatMessage({ id: 'import-clients-excel' })}</Box>
                            <Fab
                              size="medium"
                              variant="round"
                              style={{ backgroundColor: "#EFF3FD" }}
                            >
                              <TableChartIcon color='primary' style={{ fontSize: 24 }} />
                            </Fab>
                          </Box>
                        </Backdrop>
                        :
                        null
                      }
                    </div>

                    {this.state.width > 800 && (
                      <BlockingOverlay>
                        <div
                          className="search-filter-wrapper"
                          style={{
                            overflowY: 'auto',
                            height: `calc(${window.innerHeight}px - 122px - ${!showPadding ? 50 : 0
                              }px - var(--bottom-padding))`
                          }}
                        >
                          <SearchFilter
                            intl={intl}
                            enableEditFamily={true}
                            refreshData={this.refreshData}
                            advisorCustomFiltersState={advisorCustomFiltersState}
                            openResetFilterModal={() => this.setState({ resetFilterModalOpen: true })}
                            getFilterObject={this.getFilterObject}
                            powerSearchModal={this.powerSearchModal}
                            families={this.state.families}
                            usedTags={usedTags}
                            setState={this.state.filterObject}
                            openFilterModal={i => this.setState({ customFilterModalOpen: true, customFilterIndex: i })}
                            filters={adviserCustomFilters}
                            applyCustomFilter={this.applyCustomFilter}
                            aiSuggestClient={this.aiSuggestClient}
                            newReferralCount={newReferralCount}
                            history={history}
                            referrerNames={referrerNames}
                            resetSearch={this.resetSearch.bind(this)}
                          />
                        </div>
                      </BlockingOverlay>
                    )}
                  </div>
                </div>

                <StickyFooter>
                  <div className="pagination-wrapper">
                    <div className="paginator-button paginator-button-bottom" id="custom-page-tag">
                      <CustomFilterSwitch
                        intl={intl}
                        allFilter={() => {
                          this.setState({
                            customFiltersActive: false,
                            activeTab: [true, false, false, false, false, false]
                          });
                          this.resetSearch();
                          this.hanldlefilter('all');
                        }}
                        reminderFilter={() => {
                          this.setState({
                            customFiltersActive: false,
                            activeTab: [false, false, false, false, true, false]
                          });
                          this.hanldlefilter('allReminder');
                        }}
                        recruitmentFilter={() => {
                          if (this.state.clientState === 'recruitment') {
                            return;
                          } else {
                            this.hanldlefilter('recruitment');
                            this.setState({
                              customFiltersActive: false,
                              activeTab: [false, false, false, false, false, true]
                            });
                          }
                        }}
                        activeTab={this.state.activeTab}
                        customFilters={
                          isMobile && this.state.width <= 460 ? adviserCustomFilters?.slice(0, 2) : adviserCustomFilters
                        }
                        applyFilter={this.applyCustomFilter}
                        selectedCustomFilterIndex={this.state.customFilterIndex}
                        width={this.state.width}
                      />
                    </div>

                    <MyTablePagination
                      count={pageTotalCount}
                      page={page}
                      rowsPerPage={defaultPageSize}
                      rowsPerPageOptions={[]}
                      labelDisplayedRows={() => ''}
                      labelRowsPerPage=""
                      onChangePage={(event, page) => {
                        // avoid change current page value when it is fetching client list
                        if (this.props.fetchClientPhase !== LOADING) {
                          this.setState({ page: page }, () => this.setLocalStorage(undefined));
                        }
                      }}
                    />
                  </div>
                </StickyFooter>
              </div>
            </MySwipeable>
          </div>

          <ClientEntryDialogs />

          <Dialog
            open={this.state.powerSearchModal}
            onClose={this.closeClientBaseModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            TransitionComponent={SlideTransition}
            fullWidth={true}
          >
            <DialogTitle id="alert-dialog-slide-title"> {intl.formatMessage({ id: 'Smart Search' })}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {intl.formatMessage({
                  id: 'Basic search based on following items in FACTSHEET.'
                })}
              </DialogContentText>
              <div className="modal-text">
                <div className="client-base-modal-content">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: sanitize(intl.formatMessage({ id: 'power_search_include' }))
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: sanitize(intl.formatMessage({ id: 'power_search_example' }))
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: sanitize(
                        intl.formatMessage({
                          id: 'power_search_more_accurate'
                        })
                      )
                    }}
                  />
                  <div className="client-base-modal-colors">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitize(intl.formatMessage({ id: 'power_search_more' }))
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitize(intl.formatMessage({ id: 'power_search_less' }))
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitize(intl.formatMessage({ id: 'power_search_phone' }))
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitize(intl.formatMessage({ id: 'power_search_tag' }))
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitize(intl.formatMessage({ id: 'power_search_age' }))
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitize(intl.formatMessage({ id: 'power_search_bday' }))
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitize(intl.formatMessage({ id: 'power_search_disc' }))
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitize(intl.formatMessage({ id: 'power_search_occupation' }))
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitize(intl.formatMessage({ id: 'power_search_referrer' }))
                      }}
                    />
                  </div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: sanitize(intl.formatMessage({ id: 'power_search_eg' }))
                    }}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeClientBaseModal} color="primary">
                {intl.formatMessage({ id: 'OK' })}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={clearAllModalOpen && this.state.firstRendered}
            onClose={() => this.setState({ clearAllModalOpen: false })}
            TransitionComponent={SlideTransition}
          >
            <DialogTitle>{intl.formatMessage({ id: 'clear-all-title' })}</DialogTitle>
            <DialogContent>{intl.formatMessage({ id: 'clear-all-content' })}</DialogContent>
            <DialogActions style={{ justifyContent: 'space-between' }}>
              <Button
                onClick={() => {
                  if (clientsToSendNum > 0) {
                    clearAllMsgOpt();
                    this.setState({
                      clearAllPhase: true
                    });
                  }
                  this.setState({ clearAllModalOpen: false });
                }}
                color="primary"
              >
                {intl.formatMessage({ id: 'clear-all' })}
              </Button>
              <Button color="primary" onClick={() => this.setState({ clearAllModalOpen: false })}>
                {intl.formatMessage({ id: 'OK' })}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Move Policy Reminder Modal (Upon page enter) */}
          <Dialog
            open={this.state.isMovePolicyReminderModalOpen}
            onClose={() => this.setState({ isMovePolicyReminderModalOpen: false })}
            TransitionComponent={SlideTransition}
          >
            <DialogTitle>{intl.formatMessage({ id: 'move-policy-reminder-modal-title' })}</DialogTitle>
            <DialogContent>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    intl.formatMessage(
                      {
                        id: 'move-policy-reminder-modal-content'
                      },
                      {
                        policyName: this.state.portfolioToMove?.policyName
                      }
                    )
                  )
                }}
              ></Typography>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.setState({ isMovePolicyReminderModalOpen: false })}>
                {intl.formatMessage({ id: 'OK' })}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Move Policy Confirm Modal (Upon client selection) */}
          <Dialog
            open={!!this.state.selectedClientForMovePolicy}
            onClose={() => this.cancelMovePolicy()}
            TransitionComponent={SlideTransition}
          >
            <DialogTitle>{intl.formatMessage({ id: 'move-policy-confirm-modal-title' })}</DialogTitle>
            <DialogContent>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    intl.formatMessage(
                      {
                        id: 'move-policy-confirm-modal-content'
                      },
                      {
                        policyName: this.state.portfolioToMove?.policyName,
                        clientName: this.state.selectedClientForMovePolicy?.factsheetId?.name
                      }
                    )
                  )
                }}
              ></Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.cancelMovePolicy()}>{intl.formatMessage({ id: 'Cancel' })}</Button>
              <Button color="primary" onClick={() => this.confirmMovePolicy()}>
                {intl.formatMessage({ id: 'Confirm' })}
              </Button>
            </DialogActions>
          </Dialog>

          <CpiDetailPopup
            open={!!this.state.clientBaseModal}
            onClose={() => this.setState({ clientBaseModal: false, powerSearchModal: false })}
            intl={intl}
          />

          <COVIDHintDialog
            open={!!this.state.covidHintDialog}
            onClose={() => this.setState({ covidHintDialog: false })}
            closable={!this.state.loading}
          />
          <COVIDShareToClientDialog
            dialog={this.state.covidShareDialog}
            onClose={() => this.setState({ covidShareDialog: false })}
          />
          <CustomFilterModal
            intl={intl}
            open={!!this.state.customFilterModalOpen}
            filters={adviserCustomFilters}
            usedTags={usedTags.map(e => e.text)}
            customFilterIndex={this.state.customFilterIndex}
            searchTerm={searchTerm}
            clientState={clientState}
            filterObject={filterObject}
            onClose={() => this.setState({ customFilterModalOpen: false })}
          />
          <ResetFilterModal
            intl={intl}
            open={!!this.state.resetFilterModalOpen}
            onClose={() => this.setState({ resetFilterModalOpen: false })}
          />
        </div>
      </AppContentContainer>
    );
  }
}

export default ClientPageView;
