import React from 'react';
import { injectIntl } from 'react-intl';
import { Capacitor } from '@capacitor/core';
import { Grid, Typography, Button } from '@material-ui/core';
import QRCode from "react-qr-code";

const ImageStyle = {
  width: 20,
  height: 20
};

const TypoContainerStyle = {
  width: 'fit-content',
  maxWidth: '100%'
};

const TypoStyle = {
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  color: '#9e9e9e'
};

const AdviserInfoContactEntry = props => {
  const { intl, imgSrc, imgAlt, text, textStyle, type, adviserInfo, onClick } = props;

  const isMobileBrowser = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iphone|ipad|ipod|mobile/i.test(userAgent) && !Capacitor.isNativePlatform();
  };

  const openWechatMiniProgram = () => {
    let wechatMiniProgramUrl = `weixin://dl/business/?appid=wxd066ac768dfb3ef8&path=pages/qrCode/qrCode&query=userId%3D${adviserInfo?._id}&env_version=${process.env.STAGE === "production" ? "release" : "trial"}`;
    window.open(wechatMiniProgramUrl, '_blank');
  }

  return (
    <Grid container alignItems="center" spacing={2} wrap="nowrap">
      <Grid item xs={2}>
        <img
          src={imgSrc}
          alt={imgAlt}
          style={{ ...ImageStyle, cursor: onClick ? 'pointer' : 'none' }}
          onClick={onClick}
        />
      </Grid>
      <Grid item xs={10}>
        {type === "wechat" && adviserInfo?.wechatUrl ?
          (Capacitor.isNativePlatform() || isMobileBrowser()) ?
            <>
              <Button
                variant="outlined"
                color="primary"
                onClick={openWechatMiniProgram}
                style={{ marginBottom: 5, padding: "2px 6px", minWidth: "unset" }}
              >
                {intl.formatMessage({ id: "add-wechat-contact" })}
              </Button>
              {text !== "-" &&
                <Typography>
                  {intl.formatMessage({ id: "wechat-id-colon" })}
                  <strong onClick={openWechatMiniProgram} style={{ cursor: onClick ? 'pointer' : 'none' }}>{text}</strong>
                </Typography>
              }
            </>
            :
            <>
              <div style={{ height: "auto", margin: 0, maxWidth: 64 }}>
                <QRCode
                  size={64}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={adviserInfo.wechatUrl}
                  viewBox={`0 0 64 64`}
                />
              </div>
              {text === "-" ?
                <Typography> {intl.formatMessage({ id: "open-wechat-and-scan-above-qr-code" })}</Typography>
                :
                <Typography>
                  {intl.formatMessage({ id: "open-wechat-and-scan-above-qr-code-or-add-wechat-id" })}
                  <strong onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'none' }}>{text}</strong>
                </Typography>
              }
            </>
          :
          <div style={{ ...TypoContainerStyle, cursor: onClick ? 'pointer' : 'none' }} onClick={onClick}>
            <Typography style={{ ...TypoStyle, ...textStyle }}>{text}</Typography>
          </div>
        }
      </Grid>
    </Grid >
  );
};

export default injectIntl(AdviserInfoContactEntry);
