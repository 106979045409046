import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';
import { loginUser } from '../../../../store/user/api';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import IconToast from '../../../../components/NewToast';
import { handleSignOut } from '../../../../store/user/duck';
import NativeOrWeb from '../../../../utils/native';

const AskPasswordDialog = props => {
  const { intl, open, onClose, onSuccess, ...rest } = props;

  const email = useSelector(state => _.get(state, 'user.userDetails.email'));
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const onChange = event => {
    setError(undefined);
    setPassword(event.target.value);
  };

  const submit = async event => {
    event.preventDefault();
    try {
      setLoading(true);
      const apiResult = await loginUser({ email: email, password: password });

      if (apiResult.success === true) {
        onSuccess(email, password);
        onClose();
        setPassword('');
      } else {
        if (_.get(apiResult, 'data.passwordAttempt', 0) >= 5) {
          toast.info(IconToast('error', intl.formatMessage({ id: 'account_locked_message' })), {
            className: 'new-toast'
          });
          setTimeout(() => {
            dispatch(handleSignOut());
          }, 1500);
        } else {
          setError(intl.formatMessage({ id: 'Password do not match' }));
        }
      }
    } catch (error) {
      setError(error.toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} TransitionComponent={SlideTransition} maxWidth="xs" fullWidth={true} {...rest}>
      <form noValidate>
        <DialogTitle>{intl.formatMessage({ id: 'Enter Current Password' })}</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              type="password"
              label={intl.formatMessage({ id: 'Password' })}
              value={password}
              disabled={loading}
              error={!!error}
              helperText={!!error && error}
              onChange={onChange}
              inputProps={{
                style: {
                  boxSizing: 'content-box',
                  padding: '6px 0 7px'
                }
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button color="primary" type="submit" onClick={submit}>
            {intl.formatMessage({ id: 'Submit' })}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default injectIntl(AskPasswordDialog);
