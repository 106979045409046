import Pikto from '../components/Pikto/Pikto';
import { getLocalePikto } from '../components/ContentsDialog/ContentsDialog';
import React from 'react';
import { Badge } from '@material-ui/core';

/***
 * PLEASE ADD NEW PIKTOCHART IDS TO piktochart-scraper/constants/financial-charts.js
 */

export const PIKTO_INFLATION_EFFECT = {
  en: '57296899-inflation-effect_en',
  zh: '57297021-inflation-effect_zh',
  'zh-Hant-HK': '55909663-buying-power_money-note'
};

export const PIKTO_COMPOUND_INTEREST = {
  en: '57296870-compound-interest_en',
  zh: '57297020-compound-interest_zh',
  'zh-Hant-HK': '55792303-compound-interest'
};

export const PIKTO_DELAY_SAVING = {
  en: '57296771-delay-saving_en',
  zh: '57297018-delay-saving_zh',
  'zh-Hant-HK': '55792410-delay-saving'
};

export const PIKTO_INVESTING_MIND = {
  en: '57296924-investing-mind_en',
  zh: '57297017-investing-mind_zh',
  'zh-Hant-HK': '55702089-investing-mind'
};

export const PIKTO_AVERAGE_COST_METHOD = {
  en: '57296759-average-cost-method_en',
  zh: '57297016-average-cost-method_zh',
  'zh-Hant-HK': '55335094-average-cost-method'
};

export const PIKTO_MEDICAL_FINANCING = {
  en: '59255751-medical-financing_en',
  zh: '59255727-medical-financing_zh',
  'zh-Hant-HK': '59231663-medical-financing_hk'
};

export const PIKTO_HSI_RETURN_VS_PE_RATIO = {
  en: '58924243-hsi-pe-vs-return_en',
  zh: '58924944-hsi-pe-vs-return_zh',
  'zh-Hant-HK': '58923984-hsi-pe-vs-return'
};

export const PIKTO_INVESTMENT_TOOLS_COMPARISON = {
  en: '1f8fccc1c700-saving-en',
  zh: '0d1d554fc245-saving-sc',
  'zh-Hant-HK': 'a84ee1866c75-saving'
};

export const PIKTO_MED_VS_CI = {
  en: '57296968-med-vs-ci_en',
  zh: '57297014-med-vs-ci_zh',
  'zh-Hant-HK': '55703272-'
};

export const PIKTO_1_MTH_HIBOR = {
  en: '59668878-hibor-prime-en', //replaced previous H only chart on 20220926
  zh: '59668879-hibor-prime-zh',
  'zh-Hant-HK': '59646106-hibor-prime'
};

export const PIKTO_PREMIUM_FINANCING = {
  en: '2c851dcfba09-pf01-eg',
  zh: '4a25b39621a4-pf01-sc',
  'zh-Hant-HK': 'd57e9927f20f-pf01-tc'
};

export const PIKTO_US_DEBTS = {
  en: '59477500-us-debt_eng',
  zh: '59477481-us-deb_zh',
  'zh-Hant-HK': '59469489-us-debt'
};

export const PIKTO_PRIVATE_HOSPITAL_EXPENSES = {
  en: '57296838-private-hospital-expenses_en',
  zh: '57297029-private-hospital-expenses_zh',
  'zh-Hant-HK': '55318299-private-hospital-expenses'
};

export const PIKTO_THE_MAIN_COURSE_OF_DEAD_FROM_DISEASE = {
  en: '57296818-the-main-course-of-dead-from-disease_en',
  zh: '57297028-the-main-course-of-dead-from-disease_zh',
  'zh-Hant-HK': '55318289-the-main-course-of-dead-from-disease'
};

export const PIKTO_CANCER_STATISTICS = {
  en: '57296674-cancer-statistics_en',
  zh: '57297027-cancer-statistics_zh',
  'zh-Hant-HK': '56927510-cancer-statistics'
};

export const PIKTO_CANCERTREATMENTFEE = {
  en: '57296811-cancertreatmentfee_en',
  zh: '57297026-cancertreatmentfee_zh',
  'zh-Hant-HK': '55749399-cancertreatmentfee'
};

export const PIKTO_HIGHLIVINGSPAN = {
  en: '57296711-highlivingspan_en',
  zh: '57297025-highlivingspan_zh',
  'zh-Hant-HK': '55749192-highlivingspan'
};

export const PIKTO_AGING_POPULATION = {
  en: '64741a19ab28-aging-poplulation-en',
  zh: '04ed8fe0508e-aging-poplulation-zh',
  'zh-Hant-HK': 'cb9147e2a992-aging-poplulation'
};

export const PIKTO_HKVHIS = {
  en: '57296981-hkvhis_en',
  zh: '57297039-hkvhis_zh',
  'zh-Hant-HK': '55792264-hkvhis'
};

export const PIKTO_QDAP = {
  en: '57297004-qdap_en',
  zh: '57297038-qdap_zh',
  'zh-Hant-HK': '55703151-qdap'
};

export const PIKTO_LIFE_STAGES = {
  en: '57303234-life-stages_en',
  zh: '57303235-life-stages_zh',
  'zh-Hant-HK': '56617001-life-stages'
};

export const PIKTO_AVERAGE_HEALTH_EXPENDITURE = {
  en: '59951706-domestic-health-expenditure-en',
  zh: '59951664-domestic-health-expenditure-zh',
  'zh-Hant-HK': '59940521-domestic-health-expenses'
};

export const FINANCIAL_CHARTS_CONTENTS = [
  // {
  //   id: 'financial-concept',
  //   title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-1' }),
  //   type: 'custom',
  //   section: 'fin-charts-sub-1',
  //   color: '#5fc3d2',
  //   render: ({ intl }) => <PurchasingPowerChart />
  // },
  {
    id: 'financial-concept',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-1' }),
    type: 'pikto',
    section: 'fin-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_INFLATION_EFFECT, intl.locale)} />
  },
  //style 1 of compound int   //code style
  //   {
  //     id: 'compound-interest',
  //     title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-2' }),
  //     type: 'pikto',
  //     section: 'fin-charts-sub-1',
  //     color: '#5fc3d2',
  //     render: ({ intl }) => <Pikto uid="55265557-compound-interest" />
  //   },
  {
    id: 'compound-interest',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-2' }),
    type: 'pikto',
    section: 'fin-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_COMPOUND_INTEREST, intl.locale)} />
  },
  //code style
  //   {
  //     id: 'save-early',
  //     title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-3' }),
  //     type: 'custom',
  //     section: 'fin-charts-sub-1',
  //     color: '#5fc3d2',
  //     render: ({ intl }) => <SaveEarlyChart />
  //   },
  {
    id: 'save-early',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-3' }),
    redDot: true,
    type: 'pikto',
    section: 'fin-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_DELAY_SAVING, intl.locale)} />
  },
  {
    id: 'investing-mind',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-investmind' }),
    type: 'pikto',
    section: 'fin-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_INVESTING_MIND, intl.locale)} />
  },
  {
    id: 'average-cost-method',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-4' }),
    type: 'pikto',
    section: 'fin-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_AVERAGE_COST_METHOD, intl.locale)} />
  },

  // {
  //   id: 'medical-vs-ci',
  //   title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-11' }),
  //   type: 'pikto',
  //   section: 'fin-charts-sub-1',
  //   color: '#5fc3d2',
  //   render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_MED_VS_CI, intl.locale)} />
  // },
  {
    id: 'premium-financing',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-premium-financing' }),
    redDot: true,
    type: 'pikto',
    section: 'fin-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_PREMIUM_FINANCING, intl.locale)} />
  },
  {
    id: '1-mth-hibor',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-12' }),
    redDot: true,
    type: 'pikto',
    section: 'fin-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_1_MTH_HIBOR, intl.locale)} />
  },

  {
    id: 'us-debts',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-us-debts' }),
    type: 'pikto',
    section: 'fin-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_US_DEBTS, intl.locale)} />
  },

  {
    id: 'hsi-pe-vs-return',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-hsi-pe-vs-return' }),
    type: 'pikto',
    section: 'fin-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_HSI_RETURN_VS_PE_RATIO, intl.locale)} />
  },
  {
    id: 'a84ee1866c75-saving',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-investment-tool-comparison' }),
    redDot: true,
    type: 'pikto',
    section: 'fin-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_INVESTMENT_TOOLS_COMPARISON, intl.locale)} />
  },
  {
    id: '59231663-medical-financing_hk',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-medfund' }),
    redDot: true,
    type: 'pikto',
    section: 'fin-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_MEDICAL_FINANCING, intl.locale)} />
  },
  {
    id: 'cancer-chance',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-7' }),
    redDot: true,
    type: 'pikto',
    section: 'fin-charts-sub-2',
    color: 'var(--new-theme)',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_CANCER_STATISTICS, intl.locale)} />
  },
  {
    id: 'main-death-cause',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-6' }),
    type: 'pikto',
    section: 'fin-charts-sub-2',
    color: 'var(--new-theme)',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_THE_MAIN_COURSE_OF_DEAD_FROM_DISEASE, intl.locale)} />
  },
  {
    id: 'top-cancer-cost',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-9' }),
    type: 'pikto',
    section: 'fin-charts-sub-2',
    color: 'var(--new-theme)',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_CANCERTREATMENTFEE, intl.locale)} />
  },
  {
    id: 'private-hospital-cost',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-5' }),
    type: 'pikto',
    section: 'fin-charts-sub-2',
    color: 'var(--new-theme)',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_PRIVATE_HOSPITAL_EXPENSES, intl.locale)} />
  },
  {
    id: 'average-health-expenditure',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-avg-health-expenditure' }),
    type: 'pikto',
    section: 'fin-charts-sub-2',
    color: 'var(--new-theme)',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_AVERAGE_HEALTH_EXPENDITURE, intl.locale)} />
  },

  //code ver
  // {
  //   id: 'cancer-chance',
  //   title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-7' }),
  //   type: 'custom',
  //   section: 'fin-charts-sub-2',
  //   color: '#3966f8',
  //   render: ({ intl }) => <CancerChanceChart />
  // },
  //code ver
  // {
  //   id: 'top-cancer-cost',
  //   title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-9' }),
  //   type: 'custom',
  //   section: 'fin-charts-sub-2',
  //   color: '#3966f8',
  //   render: ({ intl }) => <TopCancerCostChart />
  // },

  {
    id: 'most-long-living-people',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-8' }),
    type: 'pikto',
    section: 'fin-charts-sub-2',
    color: 'var(--new-theme)',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_HIGHLIVINGSPAN, intl.locale)} />
  },

  {
    id: 'aging-population',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-13' }),
    type: 'pikto',
    section: 'fin-charts-sub-2',
    color: 'var(--new-theme)',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_AGING_POPULATION, intl.locale)} />
  },

  // code visual  {
  //     id: 'vhis-feature',
  //     title: ({ intl }) => intl.formatMessage({ id: 'HK VHIS Feature' }),
  //     type: 'custom',
  //     section: 'fin-charts-sub-3',
  //     color: '#2260a5',
  //     render: ({ intl }) => <VHISFeature />
  //   },
  // {
  //   id: 'vhis-feature',
  //   title: ({ intl }) => intl.formatMessage({ id: 'HK VHIS Feature' }),
  //   type: 'pikto',
  //   section: 'fin-charts-sub-3',
  //   color: '#2260a5',
  //   render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_HKVHIS, intl.locale)} />
  // },
  // {
  //   id: 'qdap',
  //   title: ({ intl }) => intl.formatMessage({ id: 'HK QDAP Feature' }),
  //   type: 'pikto',
  //   section: 'fin-charts-sub-3',
  //   color: '#2260a5',
  //   render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_QDAP, intl.locale)} />
  // },
  //following only show with specail code, eg Nova
  // {
  //   id: '57724820-nova-recruitment',
  //   title: '招募—團隊介紹',
  //   type: 'pikto',
  //   section: 'nova',
  //   color: '#F8B02F',
  //   render: ({ intl }) => <Pikto uid="57724820-nova-recruitment" />
  // },
  {
    id: '56778466-coupon-plan-vs-savings',
    title: '高派息儲蓄方案',
    type: 'pikto',
    section: 'nova',
    color: '#F8B02F',
    render: ({ intl }) => <Pikto uid="56778466-coupon-plan-vs-savings" />
  },

  {
    id: '56617001-life-stages',
    title: ({ intl }) => intl.formatMessage({ id: 'fin-charts-special-lifestage' }),
    type: 'pikto',
    section: 'nova',
    color: '#F8B02F',
    render: ({ intl }) => <Pikto uid={getLocalePikto(PIKTO_LIFE_STAGES, intl.locale)} />
  },

  {
    id: '53670869-interest-comparison',
    title: '收息方案大比拼',
    type: 'pikto',
    section: 'nova',
    color: '#F8B02F',
    render: ({ intl }) => <Pikto uid="53670869-interest-comparison" />
  },
  {
    id: '58849673-fs-indian-sub',
    title: 'Rolling: Indian Subcontinent',
    type: 'pikto',
    section: 'nova',
    color: '#F8B02F',
    render: ({ intl }) => <Pikto uid="58849673-fs-indian-sub" />
  },
  {
    id: '57290036-sun-life-mpf-hk',
    title: 'Rolling: Sunlife MPF HK (5年—100%勝率)',
    type: 'pikto',
    section: 'nova',
    color: '#F8B02F',
    render: ({ intl }) => <Pikto uid="57290036-sun-life-mpf-hk" />
  },
  {
    id: '55397670-allianz-income-and-growth',
    title: 'Rolling: Income & Growth (有派息)',
    type: 'pikto',
    section: 'nova',
    color: '#F8B02F',
    render: ({ intl }) => <Pikto uid="55397670-allianz-income-and-growth" />
  },
  {
    id: '55398311-franklin-technology-a-acc',
    title: 'Rolling: Technology (MPF沒有涵蓋)',
    type: 'pikto',
    section: 'nova',
    color: '#F8B02F',
    render: ({ intl }) => <Pikto uid="55398311-franklin-technology-a-acc" />
  },
  {
    id: '55398122-first-state-china-growth-i',
    title: 'Rolling: China Growth (表現高於MPF)',
    type: 'pikto',
    section: 'nova',
    color: '#F8B02F',
    render: ({ intl }) => <Pikto uid="55398122-first-state-china-growth-i" />
  },
  {
    id: '55427925-first-state-asian-equity-plus-i',
    title: 'Rolling: Asian Equity (5年->100%勝率)',
    type: 'pikto',
    section: 'nova',
    color: '#F8B02F',
    render: ({ intl }) => <Pikto uid="55427925-first-state-asian-equity-plus-i" />
  },
  {
    id: '55673066-first-state-asian-bridge',
    title: 'Rolling: Asian Bridge (5年->100%勝率)',
    type: 'pikto',
    section: 'nova',
    color: '#F8B02F',
    render: ({ intl }) => <Pikto uid="55673066-first-state-asian-bridge" />
  },
  {
    id: '58850571-indian-sub',
    title: 'LS vs RS: Indian Subcontinent (11% vs 11.5%)',
    type: 'pikto',
    section: 'nova',
    color: '#F8B02F',
    render: ({ intl }) => <Pikto uid="58850571-indian-sub" />
  },
  {
    id: '55419846-blackrock-new-energy',
    title: 'LS vs RS: New Energy (1.9% vs 9.8%)',
    type: 'pikto',
    section: 'nova',
    color: '#F8B02F',
    render: ({ intl }) => <Pikto uid="55419846-blackrock-new-energy" />
  },
  {
    id: '55419867-franklin-technology-a-acc',
    title: 'LS vs RS: Technology (16% vs 21.3%)',
    type: 'pikto',
    section: 'nova',
    color: '#F8B02F',
    render: ({ intl }) => <Pikto uid="55419867-franklin-technology-a-acc" />
  },
  {
    id: '55419831-first-state-china-growth-i',
    title: 'LS vs RS: China Growth (8.5% vs 12.1%)',
    type: 'pikto',
    section: 'nova',
    color: '#F8B02F',
    render: ({ intl }) => <Pikto uid="55419831-first-state-china-growth-i" />
  },
  {
    id: '55419852-fist-state-asian-growth-i',
    title: 'LS vs RS: Asian Growth (7% vs 8.7%)',
    type: 'pikto',
    section: 'nova',
    color: '#F8B02F',
    render: ({ intl }) => <Pikto uid="55419852-fist-state-asian-growth-i" />
  }
];

export const FINANCIAL_CHARTS_SECTION_ONE_CONTENTS = FINANCIAL_CHARTS_CONTENTS.filter(
  item => item.section === 'fin-charts-sub-1'
);
export const FINANCIAL_CHARTS_SECTION_TWO_CONTENTS = FINANCIAL_CHARTS_CONTENTS.filter(
  item => item.section === 'fin-charts-sub-2'
);
// const SECTION_THREE_CONTENTS = CONTENTS.filter(item => item.section === 'fin-charts-sub-3');
export const FINANCIAL_CHARTS_NOVA_CONTENTS = FINANCIAL_CHARTS_CONTENTS.filter(item => item.section === 'nova');
export const FINANCIAL_CHARTS_DEFAULT_ITEM = FINANCIAL_CHARTS_CONTENTS.find(item => item.id === 'save-early');
export const FINANCIAL_CHARTS_HIBOR_ITEM = FINANCIAL_CHARTS_CONTENTS.find(item => item.id === '1-mth-hibor');
export const FINANCIAL_CHARTS_MAIN_DEATH_CAUSE_ITEM = FINANCIAL_CHARTS_CONTENTS.find(
  item => item.id === 'main-death-cause'
);
export const FINANCIAL_CHARTS_CANCER_CHANCE_ITEM = FINANCIAL_CHARTS_CONTENTS.find(item => item.id === 'cancer-chance');
export const FINANCIAL_CHARTS_SAVE_EARLY_ITEM = FINANCIAL_CHARTS_CONTENTS.find(item => item.id === 'save-early');
