import React, { useState, useEffect, useRef, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { decimalToString, formatNumber } from '../../../utils/formatNumber';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import moment from 'moment';
import './SmartIdeaDialog.scss';
import _ from 'lodash';
import ProRequiredBlur from '../../../components/SubscriptionComponents/ProRequiredDialog/ProRequiredBlur';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import {
  getConstellation,
  getCPIIntl,
  getCPIScoreColor,
  getImportanceImage,
  getLastMeetingMonthsDays,
  geToFactsheetDetails,
  isClientDisconnected,
  openChatGPTIceBreakingDrawer
} from '../utils';
import { getClientCoverage, getClientDetails, initSmartIdeaData, updateFactSheet } from '../../../store/client/duck';
import { withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { getAgeFromDob } from '../../../utils/date';
import BottomSheetOrDialog from '../../BottomSheetOrDialog/BottomSheetOrDialog';
import EditIcon from '@material-ui/icons/Edit';
import { Dialog, IconButton, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { useHistoryChange, usePrevious } from '../../../utils/hooks';
import { shouldEnableChatGPT } from '../../../utils/user';
import { LOADING } from '../../../constants/phase';
import { SlideTransition } from '../../../components/Transition/Transition';

const SmartIdeaDialog = props => {
  const {
    intl,
    history,
    userDetails,
    teamCampaignCodes,
    coveragePhase,
    noCoverageData,
    smartIdeaDialog,
    updateFactSheetPhase,
    clientDetails,
    toggleDialog,
    getClientCoverage,
    initSmartIdeaData,
    getClientDetails,
    updateFactSheet,
    userSubscription
  } = props;

  const [hide, setHide] = useState(false);
  const [birthdayNumberDescriptionOpen, setBirthdayNumberDescriptionOpen] = useState(false);
  const [soulNumberDescriptionOpen, setSoulNumberDescriptionOpen] = useState(false);
  const prevSmartIdeaDialog = usePrevious(smartIdeaDialog);
  const prevUpdateFactSheetPhase = usePrevious(updateFactSheetPhase);

  // useEffect(
  //   () => {
  //     if (history.location.gpt) {
  //       console.log('Hide SmartIdeaDialog due to gpt dialog props:: ', history.location.gpt);
  //       setHide(true);
  //     }
  //   }, []
  // )

  const historyListener = useHistoryChange(() => {
    toggleDialog('smartIdea', false);
    initSmartIdeaData();
    historyListener();
  }, [toggleDialog, initSmartIdeaData]);

  useEffect(() => {
    if (!prevSmartIdeaDialog && smartIdeaDialog) {
      const { client } = smartIdeaDialog;
      const isDisconnected = isClientDisconnected(client);
      if (!isDisconnected) {
        getClientCoverage({ userId: client._id });
      } else {
        initSmartIdeaData();
      }
    }
  }, [smartIdeaDialog, prevSmartIdeaDialog, getClientCoverage, initSmartIdeaData]);

  useEffect(() => {
    if (smartIdeaDialog && !smartIdeaDialog.closed) {
      if (prevUpdateFactSheetPhase !== updateFactSheetPhase) {
        if (updateFactSheetPhase === true && prevUpdateFactSheetPhase === LOADING) {
          toggleDialog('smartIdea', {
            ...smartIdeaDialog,
            client: {
              ...smartIdeaDialog?.client,
              factsheetId: {
                ...smartIdeaDialog?.client?.factsheetId,
                personality: _.get(clientDetails, 'personality', undefined),
                existingClient: _.get(clientDetails, 'existingClient', undefined)
              }
            }
          });
        }
      }
    }
  }, [updateFactSheetPhase, prevUpdateFactSheetPhase, smartIdeaDialog, toggleDialog, clientDetails]);

  const { client, closed, extraHeading } = smartIdeaDialog || {};

  if (!smartIdeaDialog) {
    return null;
  }

  const factsheet = _.get(client, 'factsheetId', {});
  const personality = _.get(factsheet, 'personality', undefined);
  const isDisconnected = isClientDisconnected(client);
  const importanceImage = getImportanceImage(factsheet.importance);
  const lastMeetingDate = factsheet.latestMeetingDate && !isDisconnected ? factsheet.latestMeetingDate : undefined;
  const { lastMeetingDurationMonths, lastMeetingDurationDays } = getLastMeetingMonthsDays(lastMeetingDate);
  const lastLoginDate = client?.lastLogin ? moment(client.lastLogin).format('DD-MMM-YYYY') : undefined;
  const showChatGPT = shouldEnableChatGPT({
    user: userDetails,
    teamCampaignCodes: teamCampaignCodes,
    userSubscription: userSubscription
  });

  const genderShort = () => {
    if (factsheet.gender === 'Male') {
      return intl.formatMessage({ id: 'M_word' });
    }
    if (factsheet.gender === 'Female') {
      return intl.formatMessage({ id: 'F_word' });
    }
    if (!factsheet.gender) {
      return '';
    }
  };

  const CPIColor = getCPIScoreColor(client);
  const CPIText = getCPIIntl(client);
  const dob = _.get(factsheet, 'dob');
  const dobMoment = dob ? moment(factsheet.dob) : undefined;
  const age = getAgeFromDob(dob, _.get(factsheet, 'age'));
  const constellation = getConstellation(dobMoment);
  const existingClient = _.get(factsheet, 'existingClient');
  const remark = _.get(factsheet, 'remark');

  const onClose = () => {
    toggleDialog('smartIdea', { ...smartIdeaDialog, closed: true });
    initSmartIdeaData();
  };

  const onExited = () => {
    if (!hide) {
      toggleDialog('smartIdea', false);
      setHide(false);
    }
  };

  const chatGPT = () => {
    openChatGPTIceBreakingDrawer(client);
  };

  const edit = () => {
    onClose();
    geToFactsheetDetails(client, history, getClientDetails);
  };

  const editBirthday = () => {
    onClose();
    geToFactsheetDetails(client, history, getClientDetails, { page: 3 });
  };

  const CPIHint = () => {
    setHide(true);
    toggleDialog('CPIHint', {
      onClose: () => setHide(false)
    });
  };

  const DISC = () => {
    setHide(true);
    toggleDialog('DISC', {
      personality: _.get(smartIdeaDialog, 'client.factsheetId.personality', undefined),
      onClose: () => setHide(false),
      onSubmit: personality => {
        updateFactSheet({
          id: _.get(smartIdeaDialog, 'client.factsheetId._id', undefined),
          key: 'personality',
          value: personality,
          clientId: client._id
        });
      }
    });
  };

  const toggleExistingClient = value => {
    toggleDialog('smartIdea', {
      ...smartIdeaDialog,
      client: _.merge(smartIdeaDialog.client, {
        factsheetId: {
          existingClient: value
        }
      })
    });
    updateFactSheet({
      id: _.get(smartIdeaDialog, 'client.factsheetId._id', undefined),
      key: 'existingClient',
      value: value,
      clientId: client._id
    });
  };

  function calculateSoulNumber(birthdate) {
    birthdate = birthdate.replace(/[^0-9]/g, '');

    function reduceToSingleDigit(num) {
      while (num > 9) {
        let sum = 0;
        num
          .toString()
          .split('')
          .forEach(digit => (sum += Number.parseInt(digit)));
        num = sum;
      }
      return num;
    }

    let sum = 0;
    for (const char of birthdate) {
      sum += Number.parseInt(char);
    }
    return reduceToSingleDigit(sum);
  }

  var birthdayNumber = dobMoment ? dobMoment.format('D') : 0;
  var soulNumber = dobMoment ? calculateSoulNumber(dobMoment.format('YYYY-MM-DD')) : 0;

  return (
    <div>
      <Dialog
        open={birthdayNumberDescriptionOpen}
        onClose={() => setBirthdayNumberDescriptionOpen(false)}
        TransitionComponent={SlideTransition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        style={{ zIndex: 2000 }}
      >
        <DialogTitle> {intl.formatMessage({ id: 'birthday-number' })} </DialogTitle>
        <DialogContent> {intl.formatMessage({ id: 'birthday-number-introduction' })} </DialogContent>
        <DialogActions>
          <Button onClick={() => setBirthdayNumberDescriptionOpen(false)} color="primary">
            {intl.formatMessage({ id: 'Close' })}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={soulNumberDescriptionOpen}
        onClose={() => setSoulNumberDescriptionOpen(false)}
        TransitionComponent={SlideTransition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        style={{ zIndex: 2000 }}
      >
        <DialogTitle> {intl.formatMessage({ id: 'soul-number' })} </DialogTitle>
        <DialogContent> {intl.formatMessage({ id: 'soul-number-introduction' })} </DialogContent>
        <DialogActions>
          <Button onClick={() => setSoulNumberDescriptionOpen(false)} color="primary">
            {intl.formatMessage({ id: 'Close' })}
          </Button>
        </DialogActions>
      </Dialog>

      <BottomSheetOrDialog
        className="smart-idea-modal"
        open={!!smartIdeaDialog && !hide && !closed}
        onClose={onClose}
        onExited={onExited}
        header={intl.formatMessage({ id: 'Smart IDEA' })}
        headerCTAs={[
          showChatGPT && (
            <IconButton color="primary" onClick={chatGPT} style={{ padding: '0 2px' }}>
              <img src="img/icons/ai.svg" alt="A.I. Pro" width={22} height={22} />
            </IconButton>
          ),
          <IconButton color="primary" onClick={edit} style={{ padding: 2 }}>
            <EditIcon style={{ fontSize: 18 }} />
          </IconButton>
        ]}
        content={
          <div className="dialog-content-centered">
            <ProRequiredBlur>
              {extraHeading && (
                <Typography color="error" align="center" style={{ fontWeight: 500 }}>
                  {extraHeading}
                </Typography>
              )}
              <div>
                <h4 className="figureColor text-center">
                  {importanceImage && <img className="vip" alt="vip" src={importanceImage} />}
                  <span>{_.get(factsheet, 'name', '')}</span>
                </h4>
                <h6 className="figureColor text-center">
                  {_.get(factsheet, 'marriageStatus') && (
                    <span className="client__status--details">
                      {_.get(factsheet, 'marriageStatus')
                        ? intl.formatMessage({
                            id: _.get(factsheet, 'marriageStatus')
                          })
                        : ''}
                    </span>
                  )}
                  {genderShort() && <span className="client__status--details">{genderShort()}</span>}
                  {Number.parseInt(_.get(factsheet, 'child')) >= 0 && (
                    <span className="client__status--details">
                      {_.get(factsheet, 'child', '')}
                      {'   '}
                      {Number.parseInt(_.get(factsheet, 'child')) >= 0 ? intl.formatMessage({ id: 'Child' }) : ''}
                    </span>
                  )}
                </h6>
                <ul className="contact__modal no__coverage smart__idea">
                  <li>
                    <span className="center-col-right">
                      {intl.formatMessage({ id: 'C.P.I.' })} <HelpOutlineIcon color="primary" onClick={CPIHint} /> :
                    </span>
                    <span className="center-col-left-cpi">
                      <button className="smart-key-modal" style={{ backgroundColor: CPIColor }} />
                      <span className="cpi__key">{CPIText}</span>
                    </span>
                  </li>
                  <li>
                    <span className="center-col-right">{intl.formatMessage({ id: 'existing-client' })} :</span>
                    <span className="center-col-left-cpi">
                      <img src="img/existingClient.svg" alt="existing-client" width="20" style={{ margin: '0 6px' }} />
                      <Button
                        variant={existingClient === 'Yes' ? 'contained' : 'outlined'}
                        className={`plan-type-select-button source-button-responsive${
                          existingClient === 'Yes' ? ` selected` : ''
                        }`}
                        onClick={() => toggleExistingClient(existingClient === 'Yes' ? null : 'Yes')}
                      >
                        {intl.formatMessage({ id: 'Yes' })}
                      </Button>
                      <img
                        src="img/nonExistingClient.svg"
                        alt="non-existing-client"
                        width="20"
                        style={{ margin: '0 6px' }}
                      />
                      <Button
                        variant={existingClient === 'No' ? 'contained' : 'outlined'}
                        className={`plan-type-select-button source-button-responsive${
                          existingClient === 'No' ? ` selected` : ''
                        }`}
                        onClick={() => toggleExistingClient(existingClient === 'No' ? null : 'No')}
                      >
                        {intl.formatMessage({ id: 'No' })}
                      </Button>
                    </span>
                  </li>
                  <li>
                    <span className="center-col-right">{intl.formatMessage({ id: 'DoB' })} :</span>
                    {dobMoment ? (
                      <span className="center-col-left">
                        <span>{dobMoment.format('DD-MMM-YYYY')}</span>
                        {constellation && (
                          <img className="constellation" alt="constellation" src={constellation.image} />
                        )}
                        <span className="cpi__key">{age}</span>
                      </span>
                    ) : (
                      <span className="center-col-left">{'-'}</span>
                    )}
                  </li>
                  <li>
                    <span className="center-col-right">{intl.formatMessage({ id: 'Occupation' })} :</span>
                    <span className="center-col-left">
                      {_.get(factsheet, 'occupation') ? _.get(factsheet, 'occupation') : '-'}
                    </span>
                  </li>
                  <li>
                    <span className="center-col-right">{intl.formatMessage({ id: 'Income' })} :</span>
                    <span className="center-col-left">
                      {_.get(factsheet, 'monthlyIncome')
                        ? decimalToString(_.get(factsheet, 'monthlyIncome')) +
                          ' /' +
                          intl.formatMessage({ id: 'Month' })
                        : '-'}
                    </span>
                  </li>
                  <li>
                    <span className="center-col-right">{intl.formatMessage({ id: 'Last Met' })} :</span>
                    {lastMeetingDate ? (
                      <span className="latest__count center-col-left">
                        <span>{lastMeetingDurationMonths}</span>{' '}
                        <span>
                          {intl.formatMessage({
                            id: 'Mth'
                          })}
                        </span>{' '}
                        <span>{lastMeetingDurationDays}</span>{' '}
                        <span>
                          {intl.formatMessage({
                            id: 'daycount'
                          })}
                        </span>
                      </span>
                    ) : (
                      <span className="latest__count center-col-left">{'-'}</span>
                    )}
                  </li>
                  <li>
                    <span className="center-col-right">{intl.formatMessage({ id: 'last-login' })} :</span>
                    {lastLoginDate ? (
                      <span className="latest__count center-col-left">
                        <span>{lastLoginDate}</span>
                      </span>
                    ) : (
                      <span className="latest__count center-col-left">{'-'}</span>
                    )}
                  </li>
                  <li>
                    <span className="center-col-right">{intl.formatMessage({ id: 'Coverage' })} :</span>
                    {!isDisconnected && coveragePhase === true && (
                      <span className="center-col-left">
                        <div>
                          <span className="coverage__left">{intl.formatMessage({ id: 'Life' })}</span>
                          {noCoverageData && noCoverageData.lifeCount > 0 && client && client.lifeCoverage >= 0 ? (
                            <span className="coverage__right">{formatNumber(client.lifeCoverage, intl)}</span>
                          ) : (
                            <span className="coverage__empty">{'❌'}</span>
                          )}
                        </div>
                        <div>
                          <span className="coverage__left">{intl.formatMessage({ id: 'C.I.' })}</span>
                          {noCoverageData && noCoverageData.illnessCount > 0 && client && client.ciCoverage >= 0 ? (
                            <span className="coverage__right">{formatNumber(client.ciCoverage, intl)}</span>
                          ) : (
                            <span className="coverage__empty">{'❌'}</span>
                          )}
                        </div>
                        <div>
                          <span className="coverage__left">{intl.formatMessage({ id: 'Medical' })}</span>
                          {noCoverageData && noCoverageData.medicalCount > 0 ? (
                            <span className="coverage__right">
                              {intl.formatMessage({
                                id: _.get(noCoverageData, 'medicalData')
                              })}
                            </span>
                          ) : (
                            <span className="coverage__empty">{'❌'}</span>
                          )}
                        </div>
                        <div>
                          <span className="coverage__left">{intl.formatMessage({ id: 'Saving' })}</span>
                          {noCoverageData && noCoverageData.savingCount > 0 ? (
                            <span className="coverage__right">
                              {formatNumber(noCoverageData.savingData, intl) +
                                ' /' +
                                intl.formatMessage({ id: 'Month' })}
                            </span>
                          ) : (
                            <span className="coverage__empty">{'❌'}</span>
                          )}
                        </div>
                        <div>
                          <span className="coverage__left">{intl.formatMessage({ id: 'ILAS' })}</span>
                          {noCoverageData && noCoverageData.savingILASCount > 0 ? (
                            <span className="coverage__right">
                              {formatNumber(noCoverageData.ilasData, intl) + ' /' + intl.formatMessage({ id: 'Month' })}
                            </span>
                          ) : (
                            <span className="coverage__empty">{'❌'}</span>
                          )}
                        </div>
                      </span>
                    )}
                    {isDisconnected && (
                      <span className="center-col-left">{intl.formatMessage({ id: 'Disconnected' })}</span>
                    )}
                  </li>
                  <li>
                    <span className="center-col-right">{intl.formatMessage({ id: 'Tag' })} :</span>
                    <span className="center-col-left">
                      {factsheet.tags !== undefined && factsheet.tags !== null ? (
                        <div className="tagsClass react-tags-wrapper">
                          <div className="ReactTags__selected">
                            {factsheet.tags.map(value => {
                              return (
                                <span
                                  key={value.text}
                                  className="tag-wrapper ReactTags__tag-no-remove"
                                  draggable="true"
                                  style={{
                                    color: value.color
                                  }}
                                >
                                  {value.text}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </span>
                  </li>
                  <li>
                    <span className="center-col-right">{`${intl.formatMessage({ id: 'cstlt' })}:`}</span>
                    <span className="center-col-left">
                      {constellation ? (
                        <Grid container alignItems="flex-start" wrap="nowrap" spacing={1}>
                          <Grid item>
                            <img className="constellation" alt="constellation" src={constellation.image} />
                          </Grid>
                          <Grid item>
                            <Typography>{intl.formatMessage({ id: constellation.descIntl })}</Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        '-'
                      )}
                    </span>
                  </li>
                  <li>
                    <span className="center-col-right">
                      {intl.formatMessage({ id: 'birthday-number' })}{' '}
                      <HelpOutlineIcon color="primary" onClick={() => setBirthdayNumberDescriptionOpen(true)} /> :
                    </span>
                    {dobMoment ? (
                      <span className="center-col-left">
                        {intl.formatMessage({ id: `birthday-number-${birthdayNumber}-description` })}
                      </span>
                    ) : (
                      <span className="center-col-left">
                        {intl.formatMessage({ id: 'set-birthday-first' })}
                        <IconButton color="primary" size="small" component="span" onClick={editBirthday}>
                          <EditIcon style={{ fontSize: 16 }} />
                        </IconButton>
                      </span>
                    )}
                  </li>
                  <li>
                    <span className="center-col-right">
                      {intl.formatMessage({ id: 'soul-number' })}{' '}
                      <HelpOutlineIcon color="primary" onClick={() => setSoulNumberDescriptionOpen(true)} /> :
                    </span>
                    {dobMoment ? (
                      <span className="center-col-left">
                        {intl.formatMessage({ id: `soul-number-${soulNumber}-description` })}
                      </span>
                    ) : (
                      <span className="center-col-left">
                        {intl.formatMessage({ id: 'set-birthday-first' })}
                        <IconButton color="primary" size="small" component="span" onClick={editBirthday}>
                          <EditIcon style={{ fontSize: 16 }} />
                        </IconButton>
                      </span>
                    )}
                  </li>
                  <li>
                    <span className="center-col-right">
                      {intl.formatMessage({ id: 'Personality_short' })}{' '}
                      <HelpOutlineIcon color="primary" onClick={DISC} /> :
                    </span>
                    {personality ? (
                      <span className="center-col-left">
                        <Button
                          onClick={DISC}
                          className={
                            'portfolio-default-button disc-btn button-line-height client__personality ' +
                            (personality === 'D'
                              ? 'D_approach'
                              : personality === 'I'
                              ? 'I_approach'
                              : personality === 'S'
                              ? 'S_approach'
                              : 'C_approach')
                          }
                          variant="contained"
                        >
                          {intl.formatMessage({
                            id:
                              personality === 'D'
                                ? 'personality_D'
                                : personality === 'I'
                                ? 'personality_I'
                                : personality === 'S'
                                ? 'personality_S'
                                : 'personality_C'
                          })}
                        </Button>
                      </span>
                    ) : (
                      <span className="center-col-left">{'-'}</span>
                    )}
                  </li>
                  <li>
                    <span className="center-col-right">{intl.formatMessage({ id: 'Approach' })} :</span>
                    {personality ? (
                      <span className="center-col-left">
                        {personality === 'D' && intl.formatMessage({ id: 'D_approach' })}
                        {personality === 'I' && intl.formatMessage({ id: 'I_approach' })}
                        {personality === 'S' && intl.formatMessage({ id: 'S_approach' })}
                        {personality === 'C' && intl.formatMessage({ id: 'C_approach' })}
                      </span>
                    ) : (
                      <span className="center-col-left" onClick={DISC}>
                        {intl.formatMessage({ id: 'No_Disc_key' })}
                      </span>
                    )}
                  </li>
                  <li>
                    <span className="center-col-right">{intl.formatMessage({ id: 'Quick Notes' })} :</span>
                    <span className="center-col-left" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                      {remark ? remark : '-'}
                    </span>
                  </li>
                </ul>
              </div>
            </ProRequiredBlur>
          </div>
        }
      />
    </div>
  );
};

const container = connect(
  // Map state to props
  state => ({
    noCoverageData: state.client.noCoverageData,
    coveragePhase: state.client.coveragePhase,
    smartIdeaDialog: state.control.smartIdeaDialog,
    updateFactSheetPhase: state.client.updateFactSheetPhase,
    clientDetails: state.client.clientDetails,
    userDetails: state.user.userDetails,
    teamCampaignCodes: state.functions.campaignCodes,
    userSubscription: state.userSubscription.userSubscription
  }),
  // Map actions to props
  {
    toggleDialog,
    getClientDetails,
    getClientCoverage,
    initSmartIdeaData,
    updateFactSheet
  },
  // mergeProps
  null,
  // options
  {}
)(withRouter(SmartIdeaDialog));

export default injectIntl(container);
