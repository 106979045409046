import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Button, Typography, IconButton, Box, useMediaQuery } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import { tracking } from '../../../utils/tracking';

const ChatGPTPresetDrawer = props => {
  const { intl, open, onClose, onExited, onFeedback, children } = props;

  const isWideScreen = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const feedback = () => {
    tracking('Click on Feedback - ChatGPT');
    if (onFeedback) {
      onFeedback();
    }
  };

  return (
    <Drawer
      className="chat-gpt-preset-drawer"
      anchor="right"
      open={open}
      onClose={onClose}
      SlideProps={{
        onExited: onExited
      }}
    >
      <Box width={isWideScreen ? '40vw' : '85vw'} maxWidth="768px" height="100%">
        <Grid container direction="column">
          <Grid item style={{ borderBottom: '1px solid #D3D3D3', padding: 6 }}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <IconButton size="small" onClick={onClose}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <Grid container alignItems="center">
                          <Grid item style={{ height: 18 }}>
                            <img
                              src="img/gpt-green.svg"
                              alt="A.I. Pro"
                              width={18}
                              height={18}
                              style={{ verticalAlign: 'unset' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography>{intl.formatMessage({ id: 'chat-gpt-preset-dialog-title' })}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {onFeedback && (
                <Grid item>
                  <Button color="primary" onClick={feedback}>
                    {intl.formatMessage({ id: 'Feedback' })}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item style={{ padding: 12, flexGrow: 1 }}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default injectIntl(ChatGPTPresetDrawer);
