import React, { useEffect, useState, Fragment, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Card, CardContent, Grid, TextField, Typography, useTheme } from '@material-ui/core';
import {
  HOBBIES_CHOICES,
  MAIN_PRODUCTS_SERVICES_CHOICES,
  HOBBIES_TAG_STYLE,
  FAMILIAR_PLACES_TAG_STYLE,
  PERSONALITY_TAGS_TAG_STYLE,
  MAIN_PRODUCTS_SERVICES_TAG_STYLE
} from '../../../../../constants/my-details';
import _ from 'lodash';
import { PhoneNumberInputWrapper } from '../../../../NewSignUp/contents/PhoneNumberInputWrapper';
import SelectButtonGroup from '../../../../../components/SelectButtonGroup/SelectButtonGroup';
import { LOADING, SUCCESS } from '../../../../../constants/phase';
import { toast } from 'react-toastify';
import IconToast from '../../../../../components/NewToast';
import { tryTranslate } from '../../../../../utils/locale';
import { connect } from 'react-redux';
import { submitReferral, updateReferralById } from '../../../../../store/referral/duck';
import { usePrevious } from '../../../../../utils/hooks';
import ReferralSuccessModal from '../../../../Advisor/Referral/partials/responseSuccessModal/responseSuccessModal';
import { withRouter } from 'react-router';
import ReferralConfirmModal from '../../../../Advisor/Referral/partials/responseSuccessModal/referralConfirmModal';
import { getHobbyIntl, getHelpItemIntl } from '../../../../../utils/my-details';
import './ContactMeCard.scss';
import LikeCount from '../../../../../components/LikeCount/LikeCount';
import { sanitize } from 'dompurify';
import { getWhatsappUrl } from '../../../../../utils/whatsapp';
import { formatEmail } from '../../../../../utils/email';
import BottomSheetOrDialog from '../../../../../components/BottomSheetOrDialog/BottomSheetOrDialog';
import moment from 'moment-timezone';

const FullWidthStyle = {
  width: '100%'
};

const NameTypoStyle = {
  fontWeight: 'bold',
  fontSize: '150%'
};

const TitleGridStyle = {
  ...FullWidthStyle,
  textAlign: 'center'
};

const ContactMeCard = props => {
  const {
    intl,
    onSuccess,
    adviserProfilePlus,
    source,
    referById,
    medium,
    role,
    submitReferralPhase,
    message,
    submitReferralMessageExtras,
    updateReferralPhase,
    updateReferralPhaseMessageExtras,
    referral,
    submitReferral,
    updateReferralById,
    style,
    animToggle,
    defaultRemark,
    open,
    onClose
  } = props;

  const theme = useTheme();
  const prevSubmitReferralPhase = usePrevious(submitReferralPhase);
  const prevUpdateReferralPhase = usePrevious(updateReferralPhase);
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [remark, setRemark] = useState('');
  const [answerTags, setAnswerTags] = useState({});
  const [likedTags, setLikedTags] = useState([]);
  const [helpItems, setHelpItems] = useState([]);
  const [error, setError] = useState({});
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [checked, setChecked] = useState(false);
  const [likeChoices, setLikeChoices] = useState([]);
  const [helpChoices, setHelpChoices] = useState([]);
  const [isRemarkFocusing, setIsRemarkFocusing] = useState(false);
  const submittedRef = useRef(false);
  const animRef = useRef(null);

  const { adviserInfo, likes } = adviserProfilePlus || {};
  const {
    _id,
    phoneNumber: adviserPhoneNumber,
    whatsapp,
    mainProductsServices,
    hobbies,
    customizedHobby,
    familiarPlaces,
    customizedProductsOrServices = ''
  } = adviserInfo || {};
  const themeColor = _.get(adviserInfo, 'themeColor') || 'blue';

  const onChangeName = event => {
    setName(event.target.value);
    if (error.name) {
      setError(_.omit('name'));
    }
  };
  const onChangePhone = event => {
    setPhoneNumber(event.target.value);
    if (error.phoneNumber) {
      setError(_.omit('phoneNumber'));
    }
  };
  const onChangeEmail = event => setEmail(event.target.value);
  const onChangeRemark = event => setRemark(event.target.value);
  const onChangeAnswerTags = (event, question) => {
    let newAnswerTags = { ...answerTags, [question]: event.target.value };
    setAnswerTags(newAnswerTags);
  };
  const onChangeLikedTags = event => setLikedTags(event.target.value || []);
  const onChangeHelpItems = event => setHelpItems(event.target.value || []);
  const submit = async () => {
    const submitNew = () => {
      submittedRef.current = true;
      submitReferral({
        advisorId: _id,
        name,
        email,
        remark,
        phoneNumber,
        answerTags,
        likedTags,
        helpItems,
        referById: referById,
        source: source,
        medium: medium,
        role: role,
        timezone: moment.tz.guess(),
        language: navigator.language
      });
    };

    // update referral instead of creating a new one if it has been created
    let nfcReferral = sessionStorage.getItem('nfc_referral');
    if (referral && referral.advisorId === _id) {
      // submit new if the contents are different
      if (
        (referral.phoneNumber && referral.phoneNumber !== phoneNumber) ||
        (referral.email && referral.email !== email)
      ) {
        submitNew();
      } else {
        const updateResult = await updateReferralById(referral._id, {
          name,
          email,
          remark,
          phoneNumber,
          answerTags,
          likedTags,
          helpItems
        });
        if (!updateResult) {
          submitNew();
        }
      }
    } else if (nfcReferral && nfcReferral !== 'null') {
      nfcReferral = JSON.parse(nfcReferral);
      if (nfcReferral.advisorId === _id) {
        const updateResult = await updateReferralById(nfcReferral._id, {
          name,
          email,
          remark,
          phoneNumber,
          answerTags,
          likedTags,
          helpItems
        });
        if (!updateResult) {
          submitNew();
        }
      }
    } else {
      submitNew();
    }
    sessionStorage.removeItem('nfc_referral');
  };

  const openConfirmDialog = () => {
    const errorObj = {};
    if (!name) {
      errorObj.name = true;
    }

    if (!phoneNumber) {
      errorObj.phoneNumber = true;
    }

    if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formatEmail(email))) {
      errorObj.email = 'Invalid email';
    }

    setError(errorObj);

    if (Object.keys(errorObj).length === 0) {
      onClose();
      setConfirmDialog(true);
    }
  };
  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };
  const confirm = () => {
    if (!checked) {
      setError({ checked: '*Please check the info checkbox' });
    } else {
      closeConfirmDialog();
      submit();
    }
  };
  const openSuccessDialog = () => setSuccessDialog(true);
  const onCloseOnly = () => {
    closeSuccessDialog();
    if (onSuccess) {
      onSuccess();
    }
  };
  const closeSuccessDialog = () => {
    submittedRef.current = false;
    setSuccessDialog(false);
    setConfirmDialog(false);
    setName('');
    setPhoneNumber('');
    setEmail('');
    setRemark('');
    setAnswerTags({});
    setLikedTags([]);
    setHelpItems([]);
    setError({});
    setChecked(false);
  };
  const toggleChecked = () => {
    setError({});
    setChecked(!checked);
  };
  const onRemarkFocus = () => setIsRemarkFocusing(true);
  const onRemarkBlur = () => setIsRemarkFocusing(false);
  const onWhatsapp = () => {
    const whatsappUrl = getWhatsappUrl({ phone: whatsapp, text: remark });
    window.open(whatsappUrl, '_blank');
  };

  useEffect(() => {
    if (submittedRef.current) {
      if (submitReferralPhase !== prevSubmitReferralPhase && prevSubmitReferralPhase !== undefined) {
        if (submitReferralPhase === true) {
          toast.info(IconToast('success', intl.formatMessage({ id: 'referral-success' })), { className: 'new-toast' });
          openSuccessDialog();
        } else if (submitReferralPhase === false) {
          toast.info(IconToast('info', tryTranslate(intl, message, submitReferralMessageExtras)), {
            className: 'new-toast'
          });
        }
      }
    }
  }, [intl, prevSubmitReferralPhase, submitReferralPhase, message, submitReferralMessageExtras]);

  useEffect(() => {
    if (updateReferralPhase !== prevUpdateReferralPhase && prevUpdateReferralPhase !== undefined) {
      if (updateReferralPhase === true) {
        toast.info(IconToast('success', intl.formatMessage({ id: 'referral-success' })), { className: 'new-toast' });
        openSuccessDialog();
      } else if (updateReferralPhase === false) {
        toast.info(IconToast('info', tryTranslate(intl, message, updateReferralPhaseMessageExtras)), {
          className: 'new-toast'
        });
      }
    }
  }, [intl, prevUpdateReferralPhase, updateReferralPhase, message, updateReferralPhaseMessageExtras]);

  useEffect(() => {
    setTimeout(function() {
      if (open && animRef && animRef.current) {
        const run = async () => {
          const anim = await animRef.current.animationDidLoad();
          setTimeout(() => {
            if (anim) {
              anim.play();
            }
          }, 350);
        };
        run()
          .then()
          .catch();
      }
    }, 500);
  }, [open, animRef.current]);

  useEffect(() => {
    const filteredHobbies = (hobbies || [])
      .filter(hobby => HOBBIES_CHOICES.findIndex(choice => choice === hobby) > -1)
      .map(hobby => ({
        value: hobby,
        label: intl.formatMessage({ id: getHobbyIntl(hobby) }),
        style: isSelected => (!isSelected ? HOBBIES_TAG_STYLE : undefined)
      }));

    const filteredFamiliarPlaces = (familiarPlaces || []).map(familiarPlace => ({
      value: familiarPlace,
      label: familiarPlace,
      style: isSelected => (!isSelected ? FAMILIAR_PLACES_TAG_STYLE : undefined)
    }));

    setLikeChoices([
      ...filteredHobbies,
      ...(!!customizedHobby
        ? [
            {
              value: customizedHobby,
              label: customizedHobby,
              style: isSelected => (!isSelected ? HOBBIES_TAG_STYLE : undefined)
            }
          ]
        : []),
      ...filteredFamiliarPlaces
    ]);
  }, [intl, hobbies, customizedHobby, familiarPlaces]);

  useEffect(() => {
    const customProductsServicesTags = customizedProductsOrServices
      .split('#')
      .map(e => e.trim())
      .filter(item => item !== '');
    console.log('customProductsServicesTags: ', customProductsServicesTags);
    const mainProductsServicesTags = mainProductsServices || [];
    let filteredMainProductsServices = [];

    if (customProductsServicesTags || mainProductsServicesTags) {
      filteredMainProductsServices = [...(customProductsServicesTags || []), ...(mainProductsServicesTags || [])].map(
        mainProductService => ({
          value: mainProductService,
          label: intl.formatMessage({ id: getHelpItemIntl(mainProductService) }),
          style: isSelected => (!isSelected ? MAIN_PRODUCTS_SERVICES_TAG_STYLE : undefined)
        })
      );
    }

    console.log('filteredMainProductsServices: ', filteredMainProductsServices);
    setHelpChoices(filteredMainProductsServices);
  }, [intl, mainProductsServices]);

  useEffect(() => {
    if (defaultRemark) {
      setRemark(defaultRemark);
    }
  }, [defaultRemark]);

  return (
    <Fragment>
      <ReferralConfirmModal
        intl={intl}
        fromReferClient
        open={confirmDialog}
        onClose={closeConfirmDialog}
        submitForm={confirm}
        email={email}
        checked={checked}
        confirmEmail={toggleChecked}
        err={error.checked}
      />

      <ReferralSuccessModal
        val={adviserInfo}
        intl={intl}
        open={successDialog}
        onCloseOnly={onCloseOnly}
        closeSuccessModal={closeSuccessDialog}
      />

      <BottomSheetOrDialog
        open={open}
        onClose={onClose}
        header={
          <LikeCount
            likes={likes || 0}
            LottieProps={{
              ref: animRef
            }}
          />
        }
        content={
          <Grid container direction="column" alignItems="flex-start" justify="flex-start" spacing={2}>
            {!adviserInfo?.shouldHideProfilePlusCustomQuestions &&
              adviserInfo?.customQuestions?.length > 0 &&
              adviserInfo.customQuestions.map(customQuestion => {
                if (customQuestion.question) {
                  let answers = customQuestion.answers.split('#').filter(answer => answer && answer.trim());
                  if (answers.length >= 1) {
                    let formattedAnswers = answers.map(answer => ({
                      value: answer.trim(),
                      label: answer.trim(),
                      style: isSelected => (!isSelected ? PERSONALITY_TAGS_TAG_STYLE : undefined)
                    }));

                    return (
                      <Grid item style={{ width: '100%' }} key={customQuestion.question}>
                        <Grid container spacing={1}>
                          <Grid item style={TitleGridStyle}>
                            <Typography align="center">{customQuestion.question}</Typography>
                          </Grid>

                          <Grid item style={FullWidthStyle}>
                            <Grid container direction="column" spacing={1}>
                              <Grid item style={{ width: '100%' }}>
                                <SelectButtonGroup
                                  multi={true}
                                  choices={formattedAnswers}
                                  value={answerTags[customQuestion.question] ?? []}
                                  onChange={event => onChangeAnswerTags(event, customQuestion.question)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  }
                  return null;
                }
                return null;
              })}

            {likeChoices.length > 0 && (
              <Grid item style={{ width: '100%' }}>
                <Grid container spacing={1}>
                  <Grid item style={TitleGridStyle}>
                    <Typography display="inline" className="emoji" align="center">
                      👍🏻
                    </Typography>
                    <Typography display="inline" align="center">
                      {intl.formatMessage({ id: 'adv-info-contact-me-liked-tags' })}
                    </Typography>
                  </Grid>

                  <Grid item style={FullWidthStyle}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item style={{ width: '100%' }}>
                        <SelectButtonGroup
                          multi={true}
                          choices={likeChoices}
                          value={likedTags}
                          onChange={onChangeLikedTags}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item style={{ width: '100%' }}>
              <Grid container spacing={1}>
                <Grid item style={TitleGridStyle}>
                  <Typography display="inline" className="emoji" align="center">
                    ☕
                  </Typography>
                  <Typography display="inline" align="center">
                    {intl.formatMessage({ id: 'adv-info-contact-me-hint' })}
                  </Typography>
                </Grid>

                <Grid item style={FullWidthStyle}>
                  <SelectButtonGroup
                    multi={true}
                    choices={helpChoices}
                    value={helpItems}
                    onChange={onChangeHelpItems}
                  />
                </Grid>

                <Grid item style={FullWidthStyle}>
                  <TextField
                    value={remark}
                    onChange={onChangeRemark}
                    placeholder={intl.formatMessage({ id: 'consumer-comment' })}
                    onFocus={onRemarkFocus}
                    onBlur={onRemarkBlur}
                    multiline={true}
                    rows={2}
                    InputProps={{
                      endAdornment: whatsapp ? (
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          disableElevation={true}
                          onClick={onWhatsapp}
                          style={{ padding: 4, minWidth: 'unset' }}
                        >
                          Whatsapp
                        </Button>
                      ) : (
                        undefined
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={FullWidthStyle}>
              <TextField
                value={name}
                onChange={onChangeName}
                label={`${intl.formatMessage({ id: 'your-name' })}*`}
                error={error.name}
                helperText={error.name && intl.formatMessage({ id: 'Required input field' })}
              />
            </Grid>

            <Grid item style={FullWidthStyle}>
              <TextField
                className="phone-text-field"
                label={`${intl.formatMessage({ id: 'Tel' })}/${intl.formatMessage({ id: 'WhatsApp' })}*`}
                value={phoneNumber}
                onChange={onChangePhone}
                color="primary"
                InputProps={{
                  inputComponent: PhoneNumberInputWrapper,
                  inputProps: {
                    intl: intl
                  }
                }}
                InputLabelProps={{ shrink: false, className: 'non-shrink' }}
                error={error.phoneNumber}
                helperText={error.phoneNumber && intl.formatMessage({ id: 'Required input field' })}
              />
            </Grid>

            <Grid item style={FullWidthStyle}>
              <TextField
                label={intl.formatMessage({ id: 'Email' })}
                value={email}
                onChange={onChangeEmail}
                error={error.email}
                helperText={error.email && intl.formatMessage({ id: 'Invalid email' })}
              />
            </Grid>

            <Grid item style={FullWidthStyle}>
              <Button
                className="gradient-action-button"
                variant="contained"
                color="primary"
                fullWidth={true}
                onClick={openConfirmDialog}
                disabled={submitReferralPhase === LOADING}
              >
                {intl.formatMessage({ id: 'Submit' })}
              </Button>
            </Grid>

            <Grid item>
              <Typography align="center" style={{ fontSize: '70%' }}>
                {intl.formatMessage({ id: 'referral-privacy-policy' })}{' '}
                <a
                  href="https://www.portfoplus.com/privacy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'Privacy Policy' })) }}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                />
              </Typography>
            </Grid>
          </Grid>
        }
        actions={[]}
        BottomSheetProps={{
          expandOnContentDrag: false,
          disableAutoDismiss: true
        }}
        DialogProps={{
          maxWidth: 'xs',
          fullWidth: true
        }}
        DialogParams={{ dialogTitleCloseButton: true }}
      />
    </Fragment>
  );
};

const container = connect(
  state => ({
    adviserProfilePlus: state.adviserProfilePlus.adviserProfilePlus,
    submitReferralPhase: state.referral.submitReferralPhase,
    message: state.referral.message,
    submitReferralMessageExtras: state.referral.submitReferralMessageExtras,
    updateReferralPhase: state.referral.updateReferralPhase,
    updateReferralPhaseMessageExtras: state.referral.updateReferralPhaseMessageExtras,
    referral: state.referral.referral
  }),
  {
    submitReferral,
    updateReferralById
  }
)(ContactMeCard);

export default injectIntl(withRouter(container));
