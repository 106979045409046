import { CampaignCodeControl } from "./CampaignCodeControl";
import { connect } from "react-redux";

const container = connect(
  state => ({
    userCampaignCodes: state.user.userDetails.campaignCodes,
    teamCampaignCodes: state.functions.campaignCodes
  }),
  {}
)(CampaignCodeControl);

export default container;
