import React, { Fragment, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import LoadingBackdrop from '../../../LoadingBackdrop/LoadingBackdrop';
import { useTheme } from '@material-ui/core/styles';
import ChatGPTPresetDrawer from '../ChatGPTPresetDrawer';
import ContentBox from '../ContentBox/ContentBox';
import FollowUpButton from '../FollowUpButton/FollowUpButton';
import SendIcon from '@material-ui/icons/Send';
import { withRouter } from 'react-router';
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';
import { connect } from 'react-redux';
import { toggleControl } from '../../../../store/control/duck';
import { openaiGenerateWithTextStreaming } from '../../../../store/openai/api';
import { decreaseGPTQuota, handleError, resetGPTQuota, validateGPTQuota } from '../../../../utils/openai';
import { getIGPostPrompt } from '../../../../utils/aiPrompt';
import { useForceUpdate } from '../../../../utils/hooks';
import QuotaTypography from '../QuotaTypography/QuotaTypography';
import { toast } from 'react-toastify';
import IconToast from '../../../NewToast';
import { trackDirect } from '../../../../store/analytics/api';
import { tracking } from '../../../../utils/tracking';

const ChatGPTIGPostDrawer = props => {
  const { intl, history, chatGPTIGPostDrawer, toggleControl } = props;

  const [userText, setUserText] = useState('');
  const [userTextError, setUserTextError] = useState(undefined);
  const [text, setText] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const forceUpdate = useForceUpdate();
  const [options, setOptions] = useState([]);

  const theme = useTheme();

  const [language, setLanguage] = useState(
    history.location.state?.language ? history.location.state.language : 'Traditional Chinese'
  );

  const { client, closed } = chatGPTIGPostDrawer || {};

  const isOpen = !!chatGPTIGPostDrawer && !closed;

  useEffect(() => {
    setOptions(
      text
        .split('SEGREGATION')
        .filter(option => option.trim() !== '')
        .map(option => option.trim())
    );
  }, [text]);

  const onClose = () => {
    toggleControl('chatGPTIGPostDrawer', { ...chatGPTIGPostDrawer, closed: true });
  };

  const onExited = () => {
    toggleControl('chatGPTIGPostDrawer', false);
    setUserText('');
    setUserTextError(undefined);
    setText('');
    setChatMessages([]);
  };

  const onFeedback = () => {
    toggleControl('chatGPTFeedbackDialog', {
      client: client,
      extraContent: text,
      onSubmit: () => {
        resetGPTQuota();
        forceUpdate();
      }
    });
  };

  const onChangeInputMessage = event => {
    const targetValue = event.target.value;
    if (targetValue.length <= 20) {
      setUserText(event.target.value);
      setUserTextError(undefined);
    }
  };

  const send = async ({ inputMessage, previousMessages }) => {
    if ((userText || '').length > 20) {
      setUserTextError(intl.formatMessage({ id: 'chat-gpt-ig-post-drawer-max-20-char' }));
      return;
    }

    try {
      validateGPTQuota();
      setLoading(true);
      trackDirect('generateSocialMediaPost');
      tracking('AI: Generate Social Media Post');

      const addedChatMessages = [...(previousMessages || []), { content: inputMessage, role: 'user' }];
      await openaiGenerateWithTextStreaming('gpt-4o', addedChatMessages, 1, 1, 0, 0, setChatMessages, setText);

      decreaseGPTQuota();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const sendUserText = () => {
    const inputMessage = getIGPostPrompt({ text: userText, language });
    send({ inputMessage });
  };

  const clear = () => {
    setText('');
    setChatMessages([]);
  };

  const onValue = value => {
    try {
      navigator.clipboard.writeText(value);

      toast.info(IconToast('success', intl.formatMessage({ id: 'success' })), {
        autoClose: false,
        className: 'new-toast'
      });
    } catch (error) {
      toast.info(IconToast('error', intl.formatMessage({ id: 'error' })), { autoClose: false, className: 'new-toast' });
    }
  };

  return (
    <Fragment>
      <LoadingBackdrop open={loading} />
      <ChatGPTPresetDrawer open={isOpen} onClose={onClose} onExited={onExited} onFeedback={onFeedback}>
        <Grid container direction="column" spacing={2} style={{ height: '100%' }}>
          <Grid item>
            <Typography style={{ whiteSpace: 'pre-wrap' }}>
              {intl.formatMessage({ id: 'chat-gpt-ig-post-drawer-content' })}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              fullWidth
              value={userText}
              onChange={onChangeInputMessage}
              placeholder={intl.formatMessage({ id: 'chat-gpt-ig-post-drawer-input-placeholder' })}
              error={!!userTextError}
              helperText={userTextError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" color="primary" onClick={sendUserText} disabled={!userText || loading}>
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item>
            <RadioGroup
              row
              aria-label="language"
              name="language"
              value={language}
              onChange={event => setLanguage(event.target.value)}
            >
              <FormControlLabel
                value="Traditional Chinese"
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: 'traditional-chinese' })}
              />
              <FormControlLabel
                value="English"
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: 'standard-english' })}
              />
              <FormControlLabel
                value="Simplified Chinese"
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: 'simplified-chinese' })}
              />
            </RadioGroup>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              {options.length >= 1 && (
                <Grid item style={{ marginTop: 10 }}>
                  {<span style={{ fontSize: 16 }}>{intl.formatMessage({ id: 'click-option-to-copy' })}</span>}
                  {options.map((option, index) => (
                    <FollowUpButton
                      key={index}
                      onClick={() => onValue(option)}
                      color="primary"
                      style={{ color: theme.palette.primary.main, marginLeft: 10 }}
                    >
                      {intl.formatMessage(
                        { id: 'option-number' },

                        { number: index + 1 }
                      )}
                    </FollowUpButton>
                  ))}
                </Grid>
              )}
              <Grid item>
                <ContentBox
                  getHeight={({ innerHeight }) => `calc(${innerHeight}px - 350px - var(--top-padding))`}
                  text={text}
                  disableCopy={true}
                />
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Grid container justify="space-between">
                      <Grid item>{text && <Typography>{intl.formatMessage({ id: 'Follow Up' })}</Typography>}</Grid>
                      <Grid item>
                        <QuotaTypography />
                      </Grid>
                    </Grid>
                  </Grid>
                  {text && (
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <FollowUpButton onClick={clear}>
                            {intl.formatMessage({ id: 'chat-gpt-follow-up-clear' })}
                          </FollowUpButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ChatGPTPresetDrawer>
    </Fragment>
  );
};

const container = connect(
  state => ({
    chatGPTIGPostDrawer: state.control.chatGPTIGPostDrawer
  }),
  {
    toggleControl
  }
)(ChatGPTIGPostDrawer);

export default injectIntl(withRouter(container));
