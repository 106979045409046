import React, { useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { LinkerAdviserHeaderXL, LinkerAdviserHeaderXS } from './Responsiveness/Responsiveness';
import { ShortSubscriptions } from '../../utils/user-subscription';
import { connect } from 'react-redux';

function LinkerAdviserHeader({
  userDetails,
  onUpgrade,
  onEditProfile,
  onViewProfile,
  onCardLinkAlertClick,
  history,
  subscriptions
}) {
  const useWideLayout = useMediaQuery(theme => theme.breakpoints.up('md'));
  // const showNoNFCAlert = userDetails
  // showNoNFCAlert,
  // showLinkerProIcon,
  // onCardLinkAlertClick

  const showLinkerProIcon = new ShortSubscriptions(subscriptions).containLinkerProSubscription();
  // console.log("LinkerAdviserHeader userDetails", userDetails);
  // console.log("LinkerAdviserHeader showLinkerProIcon", showLinkerProIcon);
  const showNoNFCAlert = ((userDetails && userDetails.nfcCards) ?? []).length === 0;

  const _onEditProfile =
    onEditProfile ??
    (() => {
      const next = '/linker/my-details';
      history.push(next);
    });
  const _onViewProfile =
    onViewProfile ??
    (() => {
      const next = '/linker/adviser/' + userDetails._id + '?m=link';
      history.push(next);
    });

  if (useWideLayout) {
    return (
      <LinkerAdviserHeaderXL
        userDetails={userDetails}
        onEditProfile={_onEditProfile}
        onViewProfile={_onViewProfile}
        onUpgrade={onUpgrade}
        onCardLinkAlertClick={onCardLinkAlertClick}
        showNoNFCAlert={showNoNFCAlert}
        showLinkerProIcon={showLinkerProIcon}
      />
    );
  } else {
    return (
      <LinkerAdviserHeaderXS
        userDetails={userDetails}
        onEditProfile={_onEditProfile}
        onViewProfile={_onViewProfile}
        onUpgrade={onUpgrade}
        onCardLinkAlertClick={onCardLinkAlertClick}
        showNoNFCAlert={showNoNFCAlert}
        showLinkerProIcon={showLinkerProIcon}
      />
    );
  }
}

export default connect(state => ({
  subscriptions: state.userSubscription.subscriptions,
  userDetails: state.user.userDetails
}))(LinkerAdviserHeader);
