import React, { Fragment, useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { updateUserDetails } from '../../../store/user/duck';
import { Button, Container, Grid, Portal, Step, StepButton, StepLabel, Stepper } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SwiperCore, { HashNavigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import './MyDetails.scss';
import MyDetailsProfile from './MyDetailsProfile/MyDetailsProfile';
import _ from 'lodash';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';
import MyDetailsAboutMe from './MyDetailsAboutMe/MyDetailsAboutMe';
import { formatEditorStateValue, getEditorDefaultMessage, getSaveContentState } from '../../../utils/editor';
import MyDetailsBusiness from './MyDetailsBusiness/MyDetailsBusiness';
import MyDetailsSocial from './MyDetailsSocial/MyDetailsSocial';
import MyDetailsExtra from './MyDetailsExtra/MyDetailsExtra';
import { usePrevious } from '../../../utils/hooks';
import animateScrollTo from 'animated-scroll-to';
import { toggleDialog } from '../../../store/control/duck';
import LeavePageConfirm from '../../../components/LeavePageConfirm/LeavePageConfirm';
import ConfirmDialog from './ConfirmDialog/ConfirmDialog';
import { tagsStringToTags, tagsToTagsString } from '../../../utils/user';
import BeforeEditDialog from './BeforeEditDialog/BeforeEditDialog';
import { formatEmail, isEmailValid } from '../../../utils/email';
import { getTeams } from '../../../store/team/duck';
import { SUCCESS } from '../../../constants/phase';
import WhatsappReminderDialog from './MyDetailsProfile/WhatsappReminderDialog/WhatsappReminderDialog';
import PopupTextField from '../../../components/PopupTextField/PopupTextField';

SwiperCore.use([HashNavigation]);

export const MultipleChoicesSubTitleStyle = {
  width: '100%',
  textAlign: 'center'
};

const StepperStyle = {
  padding: '16px 0 0 0'
};

const ActionButtonStyle = {
  width: 100
};

const TOTAL_PAGES = 5;
const SLIDE_INDEXES = ['profile', 'about-me', 'business', 'social', 'extra'];

const BackButton = props => {
  const { intl, style, ...rest } = props;
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<NavigateBeforeIcon />}
      style={{ ...ActionButtonStyle, ...style }}
      {...rest}
    >
      {intl.formatMessage({ id: 'Back' })}
    </Button>
  );
};

const NextButton = props => {
  const { intl, style, ...rest } = props;
  return (
    <Button
      variant="contained"
      color="primary"
      endIcon={<NavigateNextIcon />}
      style={{ ...ActionButtonStyle, ...style }}
      {...rest}
    >
      {intl.formatMessage({ id: 'Next' })}
    </Button>
  );
};

const formatEditingDetails = (userDetails, intl) => ({
  ...userDetails,
  university: tagsToTagsString(_.get(userDetails, 'university', [])),
  secondarySchool: tagsToTagsString(_.get(userDetails, 'secondarySchool', [])),
  familiarPlaces: tagsToTagsString(_.get(userDetails, 'familiarPlaces', [])),
  otherHashtags: tagsToTagsString(_.get(userDetails, 'otherHashtags', [])),
  adviserMessage: formatEditorStateValue(
    _.get(userDetails, 'adviserMessage', getEditorDefaultMessage(intl.locale, 'adviser-message'))
  ),
  teamDescription: formatEditorStateValue(
    _.get(userDetails, 'teamDescription', getEditorDefaultMessage(intl.locale, 'profile-plus-team-description'))
  )
});

const formatSavingDetails = userDetails => ({
  ...userDetails,
  workEmail: userDetails.workEmail ? formatEmail(userDetails.workEmail) : userDetails.workEmail,
  university: tagsStringToTags(_.get(userDetails, 'university', '')),
  secondarySchool: tagsStringToTags(_.get(userDetails, 'secondarySchool', '')),
  familiarPlaces: tagsStringToTags(_.get(userDetails, 'familiarPlaces', '')),
  otherHashtags: tagsStringToTags(_.get(userDetails, 'otherHashtags', '')),
  adviserDisclaimerChecked: true,
  adviserMessage: getSaveContentState(_.get(userDetails, 'adviserMessage')),
  teamDescription: getSaveContentState(_.get(userDetails, 'teamDescription'))
});

const MyDetails = props => {
  const {
    intl,
    history,
    userDetails,
    getTeamsPhase,
    teams,
    updatePhase,
    message,
    updateMessageExtras,
    updateUserDetails,
    toggleDialog,
    getTeams
  } = props;

  const [page, setPage] = useState(0);
  const [editingDetails, setEditingDetails] = useState(formatEditingDetails(userDetails, intl));
  const [error, setError] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [beforeEditDialog, setBeforeEditDialog] = useState(true);
  const [whatsappReminderDialog, setWhatsappReminderDialog] = useState(false);
  const swiperRef = useRef(null);
  const containerRef = useRef(null);
  const prevUpdatePhase = usePrevious(updatePhase);
  const initialTextEditorUpdateDone = useRef({ adviserMessage: false, teamDescription: false });
  const [showPopupTextField, setShowPopupTextField] = useState(false);
  const myDetailsBusinessRef = useRef(null);
  // adviser can only enable team page if he belongs to a team
  //const allowProfilePlusTeamPage = getTeamsPhase === SUCCESS && (teams || []).length > 0; //update the condition, but still can't show the teampage, comment this line for further investigtaion
  const allowProfilePlusTeamPage = getTeamsPhase === SUCCESS && (teams || []).length > 0;
  const {
    name,
    email,
    phoneNumber,
    workEmail,
    mainLanguages,
    personalityTags,
    mainProductsServices,
    customizedProductsOrServices,
    hobbies
  } = editingDetails || {};

  const validateStep = (stepIndex, doNotSet) => {
    const errorObj = {};

    for (let index of stepIndex) {
      switch (index) {
        case 0:
          if (!(name || '').trim()) {
            errorObj.name = 'required';
          }
          if (!(email || '').trim()) {
            errorObj.email = 'required';
          } else if (!isEmailValid(email)) {
            errorObj.email = 'Invalid email';
          }
          if (!(phoneNumber || '').trim()) {
            errorObj.phoneNumber = 'required';
          }
          if (workEmail) {
            if (!isEmailValid(workEmail)) {
              errorObj.workEmail = 'Invalid email';
            }
          }

          break;
        case 1:
          if ((mainLanguages || []).length === 0) {
            errorObj.mainLanguages = 'required';
          }
          if ((personalityTags || []).length === 0) {
            errorObj.personalityTags = 'required';
          }
          break;
        case 2:
          if ((mainProductsServices || []).length === 0 && !customizedProductsOrServices) {
            errorObj.mainProductsServices = 'required';
          }
          break;
        case 3:
          if ((hobbies || []).length === 0) {
            errorObj.hobbies = 'required';
          }
          break;
        default:
          break;
      }
    }

    if (!doNotSet) {
      setError(errorObj);
    }

    if (Object.values(errorObj).length <= 0) {
      return true;
    } else {
      if (!doNotSet) {
        toast.info(IconToast('error', intl.formatMessage({ id: 'Please check the information entered.' })), {
          className: 'new-toast'
        });
        animateScrollTo(0).then();
      }

      return false;
    }
  };

  const back = () => {
    history.goBack();
  };
  const onSwiper = swiper => {
    swiperRef.current = swiper;
  };
  const onSlideChange = swiper => {};
  const onSlideChangeTransitionEnd = swiper => {
    animateScrollTo(0).then();
    setPage(swiper.activeIndex);
  };
  const slideBack = () => {
    if (swiperRef && swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };
  const slideNext = () => {
    // whatsapp checking
    const { whatsapp } = editingDetails || {};
    if (page === 0 && !whatsapp) {
      setWhatsappReminderDialog(true);
      return;
    }

    if (validateStep([page]) && swiperRef && swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };
  const editDetails = updates => {
    setEditingDetails({ ...editingDetails, ...updates });

    for (const updateKey of Object.keys(updates)) {
      const checkKey = ['adviserMessage', 'teamDescription'].find(key => key === updateKey);
      if (checkKey) {
        if (initialTextEditorUpdateDone.current[checkKey]) {
          setUpdated(true);
        } else {
          initialTextEditorUpdateDone.current[checkKey] = true;
        }
      } else {
        setUpdated(true);
      }
    }

    const errorKeys = Object.keys(updates);
    if (errorKeys.length > 0) {
      const errorObj = { ...error };
      errorKeys.forEach(key => {
        if (errorObj[key]) {
          delete errorObj[key];
        }
      });
      setError(errorObj);
    }
  };
  const updateError = errorObj => {
    setError({ ...error, ...errorObj });
  };

  const save = shouldRedirect => {
    if (shouldRedirect !== redirect) {
      setRedirect(shouldRedirect);
    }

    if (validateStep([0, 1, 2, 3])) {
      setConfirmDialog(true);
    }
  };
  const normalSave = () => {
    save(false);
  };
  const closeConfirmDialog = () => setConfirmDialog(false);
  const confirm = () => {
    setUpdated(false);
    updateUserDetails(formatSavingDetails(editingDetails));
  };
  const saveGoToMyBusiness = () => {
    save(true);
  };
  const onResize = () => {
    if (swiperRef && swiperRef.current) {
      swiperRef.current.updateAutoHeight();
    }
  };
  const closeBeforeEditDialog = () => setBeforeEditDialog(false);

  const onSkipWhatsapp = () => {
    setWhatsappReminderDialog(false);
    if (validateStep([page]) && swiperRef && swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };
  const onFillInWhatsapp = () => {
    setWhatsappReminderDialog(false);
  };

  useEffect(() => {
    if (updatePhase === true && prevUpdatePhase && prevUpdatePhase !== updatePhase) {
      toast.info(IconToast('info', intl.formatMessage({ id: 'Updated successfully' })), { className: 'new-toast' });

      if (!!redirect) {
        history.push('/profile-plus');
      }
    } else if (updatePhase === false && prevUpdatePhase && prevUpdatePhase !== updatePhase) {
      toast.info(IconToast('error', intl.formatMessage({ id: message }, updateMessageExtras)), {
        className: 'new-toast'
      });
    }
  }, [intl, history, updatePhase, message, updateMessageExtras, prevUpdatePhase, redirect]);

  useEffect(() => {
    setEditingDetails(formatEditingDetails(userDetails, intl));
  }, [userDetails, intl]);

  useEffect(() => {
    getTeams();
  }, [getTeams]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return () => setUpdated(false);
  }, []);

  const isPagesOK = new Array(TOTAL_PAGES).fill(0).map((_, index) => validateStep([index], true));

  const onPopupTextFieldCloseHandler = value => {
    if (myDetailsBusinessRef.current) {
      myDetailsBusinessRef.current.onHandleCustomizedProductsServicesComplete(value); // Call the resetFields method
    }
    setShowPopupTextField(false);
  };

  const StepLabelOrButton = (key, label, error, errorCheck) => {
    const isError = !!errorCheck.find(key => !!error[key]);

    let switchable = true;
    for (let i = 0; i <= key; i++) {
      if (!isPagesOK[i]) {
        switchable = false;
      }
    }

    return (
      <Step key={key} completed={key < page}>
        {switchable && isPagesOK[page] ? (
          <StepButton
            onClick={() => {
              // whatsapp checking
              const { whatsapp } = editingDetails || {};
              if (page === 0 && key !== 0 && !whatsapp) {
                setWhatsappReminderDialog(true);
                return;
              }

              if (swiperRef && swiperRef.current) {
                swiperRef.current.slideTo(key);
              }
            }}
          >
            <StepLabel error={isError}>{label}</StepLabel>
          </StepButton>
        ) : (
          <StepLabel error={isError}>{label}</StepLabel>
        )}
      </Step>
    );
  };

  return (
    <div className="my-details adviser">
      <LeavePageConfirm shouldShow={updated} save={confirm} />

      <ConfirmDialog open={confirmDialog} onClose={closeConfirmDialog} onConfirm={confirm} />

      <BeforeEditDialog open={beforeEditDialog} onClose={closeBeforeEditDialog} />

      <WhatsappReminderDialog open={whatsappReminderDialog} onSkip={onSkipWhatsapp} onFillIn={onFillInWhatsapp} />

      {/*<MyResizeObserver targetsSelector=".my-details.adviser .swiper-slide-active > div" onResize={onResize} />*/}
      <PopupTextField
        open={showPopupTextField}
        onConfirm={onPopupTextFieldCloseHandler}
        onClose={() => setShowPopupTextField(false)}
      />

      <Container className="container" maxWidth="sm" ref={containerRef}>
        <Grid container direction="column" spacing={3}>
          <Grid item style={{ width: '100%' }}>
            <Stepper nonLinear activeStep={page} alternativeLabel style={StepperStyle}>
              {StepLabelOrButton(0, intl.formatMessage({ id: 'mda-profile' }), error, ['name', 'email', 'phoneNumber'])}
              {StepLabelOrButton(1, intl.formatMessage({ id: 'mda-about-me' }), error, [
                'mainLanguages',
                'personalityTags'
              ])}
              {StepLabelOrButton(2, intl.formatMessage({ id: 'mda-business' }), error, ['mainProductsServices'])}
              {StepLabelOrButton(3, intl.formatMessage({ id: 'mda-social' }), error, ['hobbies'])}
              {StepLabelOrButton(4, intl.formatMessage({ id: 'mda-extra' }), error, [])}
            </Stepper>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              autoHeight={true}
              threshold={25}
              onSwiper={onSwiper}
              onSlideChange={onSlideChange}
              allowTouchMove={false}
              touchStartPreventDefault={false} // needed for MyEditor
              // hashNavigation={{
              // 	watchState: true
              // }}
              onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
            >
              <SwiperSlide data-hash={SLIDE_INDEXES[0]}>
                <MyDetailsProfile
                  details={editingDetails}
                  onChangeDetails={editDetails}
                  updateError={updateError}
                  error={error}
                />
              </SwiperSlide>
              <SwiperSlide data-hash={SLIDE_INDEXES[1]}>
                <MyDetailsAboutMe
                  details={editingDetails}
                  onChangeDetails={editDetails}
                  updateError={updateError}
                  error={error}
                  onResize={onResize}
                />
              </SwiperSlide>
              <SwiperSlide data-hash={SLIDE_INDEXES[2]}>
                <MyDetailsBusiness
                  ref={myDetailsBusinessRef}
                  intl={intl}
                  details={editingDetails}
                  onChangeDetails={editDetails}
                  updateError={updateError}
                  error={error}
                  onResize={onResize}
                  onPopupTextField={() => setShowPopupTextField(true)}
                />
              </SwiperSlide>
              <SwiperSlide data-hash={SLIDE_INDEXES[3]}>
                <MyDetailsSocial
                  userDetails={userDetails}
                  details={editingDetails}
                  onChangeDetails={editDetails}
                  updateError={updateError}
                  error={error}
                  onResize={onResize}
                />
              </SwiperSlide>
              <SwiperSlide data-hash={SLIDE_INDEXES[4]}>
                <MyDetailsExtra
                  intl={intl}
                  userDetails={userDetails}
                  details={editingDetails}
                  onChangeDetails={editDetails}
                  updateError={updateError}
                  error={error}
                  onResize={onResize}
                  allowProfilePlusTeamPage={allowProfilePlusTeamPage}
                />
              </SwiperSlide>
            </Swiper>
          </Grid>
          <Grid item style={{ width: '100%', marginBottom: 8 }}>
            <Grid container justify={page === 0 ? 'flex-end' : 'space-between'} style={{ padding: '0 16px' }}>
              {page === 0 && (
                <Grid item>
                  <NextButton intl={intl} onClick={slideNext} />
                </Grid>
              )}

              {(page === 1 || page === 2 || page === 3) && (
                <Fragment>
                  <Grid item>
                    <BackButton intl={intl} onClick={slideBack} />
                  </Grid>
                  <Grid item>
                    <NextButton intl={intl} onClick={slideNext} />
                  </Grid>
                </Fragment>
              )}

              {page === 4 && (
                <Fragment>
                  <Grid item>
                    <BackButton intl={intl} onClick={slideBack} />
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="flex-end" spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          style={ActionButtonStyle}
                          onClick={saveGoToMyBusiness}
                        >
                          {intl.formatMessage({ id: 'Save' })}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    getTeamsPhase: state.team.getTeamsPhase,
    teams: state.team.teams,
    updatePhase: state.user.updatePhase,
    message: state.user.message,
    updateMessageExtras: state.user.updateMessageExtras
  }),
  {
    updateUserDetails,
    toggleDialog,
    getTeams
  }
)(MyDetails);

export default injectIntl(container);
