import React from 'react';
import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { connect } from 'react-redux';
import { buildSearchString, getSearchObject } from '../../../utils/router';
import { getReferralProfile } from './utils/referralProfile';
import { messageListener } from './utils/messageListener';

// This is sceperated UX for NativeApp redirect to link card page
// If the card not linked && the user is not logged in, will redirect to login page and back to this page after login
// If the card not linked && the user is logged in, will redirect to [Linker] link card frame
// If the card linked, will redirect to [Linker] profile frame (or NFCView) [Linker]

const LinkerCardView = props => {
  const { history, userDetails } = props;

  const linkerUrl = process.env.LINKER_URL;

  const path = history.location.pathname.replaceAll('//', '/');

  const newSearch = buildSearchString([getSearchObject(history), getReferralProfile({})]);
  const nextUrl = `${linkerUrl}${path}?${newSearch}`;
  const isNative = Capacitor.isNativePlatform();

  const id = window.location.pathname.split('/').pop();
  // console.log("LinkerCardView: gate :10");

  // useEffect(() => {
  //   if (isNative || !isNative) {
  //     const server = process.env.PFPLUS_BACKEND_URL;
  //     const url = `${server}/card/${id}?minimal=true`;
  //     fetch(url).then(res =>
  //       res.json().then(d => {
  //         console.log("LinkerCardView: fetch card detail: ", d);
  //         if (!d.success) {
  //           setError(d.message);
  //         }
  //         setLoading(false);
  //         console.log("LinkerCardView: fetch card detail: ", d);
  //       })
  //     );
  //   } else {
  //     setLoading(false);
  //   }
  //   return;
  // }, []);
  // console.log("LinkCard: id: ", id);

  // useEffect(() => {
  //   const back = event => {
  //     if (event.data.success && event.origin === process.env.LINKER_URL) {
  //       window.location.href = `${process.env.LINKER_URL}/ca/${id}?s=card`;
  //     }
  //   };
  //   window.addEventListener("message", back, false);
  //   return () => window.removeEventListener("message", back);
  // }, []);

  // console.log("LinkerCardView userDetails: ", userDetails);
  // if (loading) return <h1> Loading </h1>;
  // else

  useEffect(() => {
    return messageListener(history, undefined, {}, userDetails);
  }, []);

  // if (!isNative) {
  //   window.location.href = nextUrl;
  //   return <></>;
  // }
  //  else if (!userDetails._id) {
  //   history.push("/login", { nextUrl: `${path}?${newSearch}` });
  //   return <></>;
  // } else if (error) {
  //   console.log(" Linker User Got : ", userDetails);
  //   console.log("LinkerCardView: error: ", error);
  //   return <LinkerCardReferenceBuilder props={{ ...props, id: id }} />;
  // }
  // else {
  // console.log(
  //   "All exception case ::: refer to linker card view: LinkerCardView: nextUrl: ",
  //   nextUrl
  // );
  return (
    <iframe
      allow="clipboard-read; clipboard-write"
      src={nextUrl}
      frameBorder="0"
      style={{
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        border: 'none',
        display: 'block'
      }}
    />
  );
  // }
};

const container = connect(state => ({
  userDetails: state.user.userDetails
}))(LinkerCardView);

export default container;
