import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toast } from 'react-toastify';
import NormalDialog from './NormalDialog/NormalDialog';
import ConnectionDialog from './ConnectionDialog/ConnectionDialog';
import ConsentDialog from './ConsentDialog/ConsentDialog';
import ConsentConfirmDialog from './ConsentConfirmDialog/ConsentConfirmDialog';
import ChooseClientLanguageDialog from './ChooseClientLanguageDialog/ChooseClientLanguageDialog';
import ConnectDialog from './ConnectDialog/ConnectDialog';
import ConnectConfirmDialog from './ConnectConfirmDialog/ConnectConfirmDialog';
import ShareConfirmDialog from './ShareConfirmDialog/ShareConfirmDialog';
import ResendDialog from './ResendDialog/ResendDialog';
import AddPortfolioHintDialog from './AddPortfolioHintDialog/AddPortfolioHintDialog';
import TrackOnDialog from './TrackOnDialog/TrackOnDialog';
import TrackOffDialog from './TrackOffDialog/TrackOffDialog';
import MeetingDialog from './MeetingDialog/MeetingDialog';
import TagsDialog from './TagsDialog/TagsDialog';
import ClientContactDialog from './ClientContactDialog/ClientContactDialog';
import SmartIdeaDialog from './SmartIdeaDialog/SmartIdeaDialog';
import DISCDialog from './DISCDialog/DISCDialog';
import CPIHintDialog from './CPIHintDialog/CPIHintDialog';
import GreetingDialog from './GreetingDialog/GreetingDialog';
import TrackDiscardWarningDialog from './TrackDiscardWarningDialog/TrackDiscardWarningDialog';
import ChangeEmailDialog from './ChangeEmailDialog/ChangeEmailDialog';
import IconToast from '../NewToast';
import IsDemoClientWarningDialog from './IsDemoClientWarningDialog/IsDemoClientWarningDialog';
import DeleteClientDialog from './DeleteClientDialog/DeleteClientDialog';
import ChatGPTDialog from './ChatGPTDialog/ChatGPTDialog';
import ConfirmDeleteConnectionRequestDialog from './ConfirmDeleteConnectionRequestDialog/ConfirmDeleteConnectionRequestDialog';
import ChatGPTIceBreakingDrawer from './ChatGPTPresetDrawer/ChatGPTIceBreakingDrawer/ChatGPTIceBreakingDrawer';
import ChatGPTMatchLazyPacks from './ChatGPTPresetDrawer/ChatGPTMatchLazyPacks/ChatGPTMatchLazyPacks';
import ChatGPTRewriteMessageDrawer from './ChatGPTPresetDrawer/ChatGPTRewriteMessage';
import ChatGPTPersonalAnalysis from './ChatGPTPresetDrawer/ChatGPTPersonalAnalysis/ChatGPTPersonalAnalysis';

class ClientEntryDialogs extends React.PureComponent {
  componentDidUpdate(prevProps, prevState) {
    const { intl, enableTrackingPhase, enableTrackingMessage } = this.props;
    if (enableTrackingPhase === true && prevProps.enableTrackingPhase !== true) {
      if (enableTrackingMessage !== 'Tracking updated successfully.') {
        toast.info(IconToast('success', intl.formatMessage({ id: enableTrackingMessage })), { className: 'new-toast' });
      }
    }
  }

  render() {
    return (
      <Fragment>
        <NormalDialog />
        <ConnectionDialog />
        <ConsentDialog />
        <ConsentConfirmDialog />
        <ChooseClientLanguageDialog />
        <ConnectDialog />
        <ConnectConfirmDialog />
        <ShareConfirmDialog />
        <ResendDialog />
        <AddPortfolioHintDialog />
        <TrackOnDialog />
        <TrackOffDialog />
        <MeetingDialog />
        <TagsDialog />
        <ClientContactDialog />
        <SmartIdeaDialog />
        <DISCDialog />
        <CPIHintDialog />
        <GreetingDialog />
        <TrackDiscardWarningDialog />
        <ChangeEmailDialog />
        <IsDemoClientWarningDialog />
        <DeleteClientDialog />
        <ChatGPTDialog />
        <ConfirmDeleteConnectionRequestDialog />
        <ChatGPTMatchLazyPacks />
        <ChatGPTPersonalAnalysis />
        <ChatGPTIceBreakingDrawer />
      </Fragment>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    enableTrackingPhase: state.client.enableTrackingPhase,
    enableTrackingMessage: state.client.message
  }),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(ClientEntryDialogs);

export default injectIntl(container);
