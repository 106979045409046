import React from 'react';
import { Button, Grid, Box } from '@material-ui/core';
import './SelectButtonGroup.scss';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';

const SelectButtonStyle = {
  borderRadius: 16,
  lineHeight: 1.1,
  minWidth: 60,
  padding: '4px 10px',
  color: '#9E9E9E'
};

export const SelectButton = props => {
  const { prefix, subfix, className, isSelected, selectedColor, style, children, ...rest } = props;
  return (
    <Button
      className={clsx('select-button', className)}
      variant={isSelected ? 'contained' : 'outlined'}
      color={isSelected ? 'primary' : 'inherit'}
      style={{
        ...SelectButtonStyle,
        ...(selectedColor && isSelected ? { backgroundColor: selectedColor } : undefined),
        ...(isSelected ? { color: '#fff' } : undefined),
        ...(typeof style === 'function' ? style(isSelected) : style)
      }}
      {...rest}
    >
      <Box style={(prefix || subfix) && { display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {prefix && prefix}
        {children}
        {subfix && <CloseIcon style={{ width: 12, height: 12, marginLeft: 4 }} />}
      </Box>
    </Button>
  );
};

const SelectButtonGroup = props => {
  const { choices, value, multi, max, onChange, onMax, buttonClassName, onDelete, ...rest } = props;

  const formattedValue = multi ? (typeof value === 'string' ? [value] : value || []) : value;

  const isChoiceSelected = choice => {
    if (multi) {
      return formattedValue.find(oneValue => oneValue === choice.value);
    } else {
      return formattedValue === choice.value;
    }
  };

  const createChoiceOnClickHandler = choice => () => {
    if (onChange) {
      if (multi) {
        if (isChoiceSelected(choice)) {
          onChange({ target: { value: formattedValue.filter(oneValue => oneValue !== choice.value) } });
        } else {
          if (!max || (max && formattedValue.length <= max - 1)) {
            onChange({ target: { value: [...formattedValue, choice.value] } });
          } else {
            if (onMax) {
              onMax();
            }
          }
        }
      } else {
        onChange({ target: { value: isChoiceSelected(choice) ? undefined : choice.value } });
      }
    }
  };

  return (
    <Grid container alignItems="center" justify="flex-start" spacing={1} {...rest}>
      {choices.map(choice => {
        const { value, label, xs, sm, md, lg, xl, ...rest } = choice;
        return (
          <Grid item key={value} xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
            <SelectButton
              isSelected={isChoiceSelected(choice)}
              onClick={choice.handler || createChoiceOnClickHandler(choice)}
              className={buttonClassName}
              style={choice.style}
              {...rest}
            >
              {label}
            </SelectButton>
          </Grid>
        );
      })}
    </Grid>
  );
};
export default SelectButtonGroup;
