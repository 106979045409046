import React from 'react';
import CampaignCodeControlContainer from './container';
export default CampaignCodeControlContainer;

export function CampaignCodeControl({
  targetCampaignCode,
  children,
  userCampaignCodes,
  teamCampaignCodes,
  excludeUserCampaignCodes = false,
  excludeTeamCampaignCodes = false
}) {
  if (!children) {
    return <></>;
  } else if (targetCampaignCode) {
    const _codeType = typeof targetCampaignCode;
    const _codes = [
      ...((excludeUserCampaignCodes ? [] : userCampaignCodes) || []),
      ...((excludeTeamCampaignCodes ? [] : teamCampaignCodes) || [])
    ].map(code => code.text);
    var _checker = false;
    // if targetCampaignCode is an array
    if (_codeType === 'object') {
      // check if every element in targetCampaignCode is in _codes
      _checker = targetCampaignCode.every(code => _codes.includes(code.toUpperCase()));
    } else if (_codeType === 'string') {
      _checker = _codes.includes(targetCampaignCode.toUpperCase());
    }
    if (_checker) {
      return children;
    } else {
      return <></>;
    }
  } else {
    return children;
  }
}
