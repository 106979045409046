import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import PolicyReview from './PolicyReview';
import PolicyReviewEditLayout from './PolicyReviewEditLayout/PolicyReviewEditLayout';
import { getUserCurrency } from '../../utils/user';
import './PolicyReviewContainer.scss';
import { handleDashboardPolicyData } from '../../store/policy/duck';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { iOS, safari } from '../../utils/device';
import { getPolicyReview, resetPolicyReview, updatePolicyReviewInputs } from '../../store/policyReview/duck';
import { LOADING } from '../../constants/phase';
import { checkPolicy, isCategory, isSubCategory } from '../../utils/policy';
import { getPDF } from '../../store/pdf/api';
import { toast } from 'react-toastify';
import fx from 'money';
import moment from 'moment-timezone';
import PolicyReviewChooseClient from './PolicyReviewChooseClient/PolicyReviewChooseClient';
import PolicyReviewRemark from './PolicyReviewRemark/PolicyReviewRemark';
import {
  goToAddClient,
  goToAddPortfolio,
  isClientDisconnected,
  setClientIdLocalStorage
} from '../../components/ClientEntry/utils';
import Grid from '@material-ui/core/Grid';
import { updateViewingClient } from '../../store/client/duck';
import AladdinHint from '../../components/AladdinHint/AladdinHint';
import { SHOW_SIDE_BAR_WIDTH } from '../App/component';
import { Button, CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { getWebImageUrl } from '../../utils/image';
import PreviewWrapper from '../../pdf-templates/PreviewWrapper';
import { tracking } from '../../utils/tracking';
import { renderToString } from 'react-dom/server';
import MyEditor from '../../components/MyEditor/MyEditor';
import LanguageRadioGroup from '../../components/LanguageRadioGroup/LanguageRadioGroup';
import PresentModeSwitch from './PresentModeSwitch/PresentModeSwitch';
import { DEFAULT_CATEGORIES } from '../../constants/category';
import NativeOrWeb from '../../utils/native';
import ExportHintDialog from '../../components/ExportHintDialog/ExportHintDialog';
import { isSubscriptionAllowAction } from '../../utils/user-subscription-store';
import { isAdviserType } from '../../utils/user';
import { isMobile } from 'react-device-detect';
import { trackDirect } from '../../store/analytics/api';

const includeIOSFix = iOS() || safari();

const PolicyReviewContainer = props => {
  const {
    intl,
    history,
    userDetails,
    policyReview,
    getPhase,
    updatePhase,
    updatePolicyReviewInputs,
    viewingClient,
    fetchClientPhase,
    updateViewingClient,
    handleDashboardPolicyData,
    presentMode,
    loading,
    selectLanguage,
    remark,
    getPolicyReview,
    updatePolicyReview,
    resetPolicyReview,
    ...rest
  } = props;

  const { categories, portfolio } = policyReview || {};

  let formattedCategories = (viewingClient ? categories : DEFAULT_CATEGORIES) || [];

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const onSelectClient = client => {
    updateViewingClient(client);
  };

  // const toggleShowSettings = () => setShowSettings(!presentMode);
  const switchPresentMode = event => updatePolicyReviewInputs('presentMode', event.target.checked);

  useEffect(() => {
    if (userDetails) {
      const language = _.get(userDetails, 'language', 'en');
      updatePolicyReviewInputs('selectLanguage', language);
    }
  }, [updatePolicyReviewInputs, userDetails]);

  useEffect(() => {
    const viewingClientId = _.get(viewingClient, '_id');
    setClientIdLocalStorage(_.get(viewingClient, '_id'));
    const isDisconnected = isClientDisconnected(viewingClient);
    if (!isDisconnected && viewingClientId) {
      getPolicyReview(viewingClientId);
    }
  }, [viewingClient, getPolicyReview]);

  useEffect(() => {
    return () => {
      resetPolicyReview();
    };
  }, [resetPolicyReview]);

  const partialScroll = useMediaQuery('(min-width: 768px)');
  const currency = getUserCurrency(userDetails);
  const clientName = _.get(viewingClient, 'factsheetId.name');
  const adviser = userDetails;
  const viewingClientId = _.get(viewingClient, '_id');
  const adviserTeamLogoUrl = _.get(adviser, 'teamLogoUrl');
  const isLoading = getPhase === LOADING || updatePhase === LOADING || fetchClientPhase === LOADING || loading;
  const back = () => history.goBack();

  const finalCategories = formattedCategories.map(category => ({
    image: category.image,
    subCategories: (category.subCategories || []).map(subCategory => ({
      image: subCategory.image,
      _id: subCategory._id,
      title: subCategory.title,
      value: subCategory.value,
      insurer: subCategory.insurer
    })),
    _id: category._id,
    title: category.title
  }));

  const generate = async options => {
    if (!loading) {
      updatePolicyReviewInputs('loading', true);
      try {
        options.clientName = clientName;
        options.currency = currency;
        options.categories = formattedCategories;
        options.portfolio = portfolio;
        options.currencyRates = fx.rates || {};
        options.adviser = adviser;
        options.viewingClientId = viewingClientId;

        // Tracking
        tracking(`Export Policy Review: export ${options.outputFormat}`, { adviser: adviser });

        const pdfBlob = await getPDF('policy-review', options);
        trackDirect("exportPolicyReview");
        await NativeOrWeb.downloadFile(
          pdfBlob,
          `Review_${(clientName || 'Client').split(' ')[0]}_${moment().format('DDMMMYYYY')}.${options.outputFormat}`,
          options.outputFormat === 'pdf' ? 'application/pdf' : 'image/png'
        );
      } catch (error) {
        toast.error(error.message);
      } finally {
        updatePolicyReviewInputs('loading', false);
      }
    }
  };

  const addClient = () => {
    // Tracking
    tracking('Policy Review: Add Client button clicked');
    goToAddClient(history);
  };

  const addPolicy = (category, policy) => {
    // Tracking
    tracking('Policy Review: Add Policy button clicked');

    if (viewingClient) {
      let subCategory = undefined;
      if (policy && policy.subCategoryId) {
        if (isSubCategory(policy.subCategoryId, 'accident')) {
          category = formattedCategories.find(oCategory => isCategory(oCategory._id, 'general'));
        }

        if (category) {
          subCategory = category.subCategories.find(subCategory => subCategory._id === policy.subCategoryId);
        }
      }

      goToAddPortfolio(viewingClient, history, userDetails, false, {
        category: category,
        subCategory: subCategory
      });
    }
  };
  const onClickAddPolicy = event => addPolicy();

  const editPolicy = policy => {
    if (viewingClient) {
      checkPolicy(policy, { history, handleDashboardPolicyData });
    }
  };

  // Edit remark
  const [remarkOpen, setRemarkOpen] = useState(false);
  const openRemark = () => {
    setRemarkOpen(true);
  };
  const closeRemark = () => {
    setRemarkOpen(false);
  };

  // Edit layout
  const [editLayoutOpen, setEditLayoutOpen] = useState(false);
  const openEditLayout = () => {
    setEditLayoutOpen(true);
  };
  const closeEditLayout = () => {
    setEditLayoutOpen(false);
  };

  // Export PDF
  const [hintDialog, setHintDialog] = useState(false);
  const openHintDialog = () => setHintDialog(true);
  const closeHintDialog = () => setHintDialog(false);

  const onClickPDF = () => {
    if (!isSubscriptionAllowAction({ type: 'core', nicknames: ['Pro', 'Ultra'] })) {
      return;
    }

    const isAdviser = isAdviserType(userDetails);
    if (isAdviser) {
      openHintDialog();
    }
  };
  const onConfirmGenerate = ({ password, isPrivateMode }) => {
    generate({
      ...props,
      title: encodeURIComponent(renderToString(<MyEditor readOnly={true} editorState={remark} textAlignment="center" />)),
      language: selectLanguage,
      outputFormat: 'pdf',
      password: password,
      isPrivateMode: isPrivateMode
    });
  };

  // Change language
  const onChangeLanguage = event => updatePolicyReviewInputs('selectLanguage', event.target.value);

  return (
    <div>
      <div
        className={`policy-review-container${presentMode ? ' present-mode' : ''}${presentMode && window.innerWidth >= SHOW_SIDE_BAR_WIDTH ? ' negative-margin' : ''
          }`}
      >
        {includeIOSFix && (
          <style
            dangerouslySetInnerHTML={{
              __html: `
				                .policy-review-container table:not(html) {
				                  transform: translate3d(0, 0, 0);
				                  -webkit-transform: translate3d(0, 0, 0);
				                  perspective: 1000px;
				                  -webkit-perspective: 1000px;
				                }
				
				                .policy-review-container table {
				                  border-collapse: separate;
				                  border-spacing: 0;
				                }
			                `
            }}
          />
        )}

        {!presentMode && (
          <AladdinHint
            title={intl.formatMessage({ id: 'aladdin-demo-video' })}
            helpCenter="c94"
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          />
        )}

        <PolicyReviewEditLayout
          open={editLayoutOpen}
          closeDialog={closeEditLayout}
          updatePolicyReviewInputs={updatePolicyReviewInputs}
          currency={currency}
        />

        <ExportHintDialog
          open={hintDialog}
          onClose={closeHintDialog}
          onConfirm={onConfirmGenerate}
          extraContent={intl.formatMessage({ id: 'page-split-hint' })}
          shouldUsePassword={true}
        />

        <PolicyReviewRemark
          open={remarkOpen}
          closeDialog={closeRemark}
        />

        {!presentMode && (
          <div className="client-step">
            {!viewingClient && (
              <Typography color="error">{intl.formatMessage({ id: 'select-client-hint' })}</Typography>
            )}
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <PolicyReviewChooseClient selectedClient={viewingClient || ''} setSelectedClient={onSelectClient} />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={addClient}
                  style={{ padding: 8, lineHeight: 1, minWidth: 'unset', textTransform: 'unset' }}
                >
                  {intl.formatMessage({ id: 'add-client' })}
                </Button>
              </Grid>
            </Grid>
          </div>
        )}

        <div className="policy-step">
          <Grid
            container
            alignItems="flex-start"
            justify={!presentMode ? 'space-between' : 'flex-end'}
            style={{ marginBottom: 8 }}
          >
            {!presentMode && (
              <Grid item xs={isSmallScreen ? 12 : undefined}>
                <Grid container direction="column" wrap="nowrap">
                  <Grid item>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <Button
                          className="orange action-button"
                          variant="contained"
                          color="primary"
                          onClick={onClickAddPolicy}
                          disabled={!viewingClient}
                          style={{
                            padding: 3,
                            lineHeight: 1,
                            minWidth: 'unset',
                            textTransform: 'unset'
                          }}
                        >
                          {intl.formatMessage({ id: 'Add Policy' })}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Typography color="textSecondary" style={{ fontWeight: 'bold' }}>
                          {intl.formatMessage({ id: 'or-wording' })}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography color="textSecondary">{intl.formatMessage({ id: 'pr-edit-hint-1' })}</Typography>
                      </Grid>
                      <Grid item>
                        <img
                          src={getWebImageUrl('img/draft.png')}
                          alt="draft"
                          style={{ width: 16, verticalAlign: 'text-bottom' }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography color="textSecondary">{intl.formatMessage({ id: 'pr-edit-hint-2' })}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              justify={isSmallScreen ? 'space-between' : 'flex-end'}
              alignItems="flex-end"
              direction={isSmallScreen ? 'row' : 'column'}
            >
              <Grid item>
                <PresentModeSwitch
                  checked={presentMode}
                  onChange={switchPresentMode}
                  label={intl.formatMessage({ id: 'present-mode' })}
                />
              </Grid>

              {!presentMode && isMobile && (
                <Grid item>
                  <LanguageRadioGroup value={selectLanguage} onChange={onChangeLanguage} />
                </Grid>
              )}

              {!presentMode && (
                <Grid item style={{ display: "flex" }}>
                  {!isMobile && <LanguageRadioGroup value={selectLanguage} onChange={onChangeLanguage} style={{ marginRight: 10 }} />}

                  <Button
                    variant="contained"
                    color="primary"
                    className="new-export-button"
                    onClick={openEditLayout}
                    disabled={isLoading}
                    style={{ marginRight: 10 }}
                  >
                    {intl.formatMessage({ id: 'edit-layout' })}
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className="new-export-button"
                    onClick={onClickPDF}
                    disabled={!viewingClient || isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress size={28.5} />
                    ) : (
                      `${intl.formatMessage({ id: 'Export_action' })} PDF`
                    )}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>

          <PreviewWrapper locale={selectLanguage}>
            <PolicyReview
              history={history}
              currency={currency}
              categories={finalCategories}
              portfolio={portfolio}
              clientName={clientName}
              editable={!presentMode}
              disableActions={!viewingClient}
              partialScroll={partialScroll}
              adviser={adviser}
              viewingClientId={viewingClientId}
              adviserTeamLogoUrl={adviserTeamLogoUrl}
              explicitLoading={
                (getPhase === LOADING || updatePhase === LOADING || fetchClientPhase === LOADING) &&
                !(viewingClient === null || (viewingClient && viewingClient._id === _.get(portfolio, '[0].userId')))
              }
              onAddPolicy={addPolicy}
              onEditPolicy={editPolicy}
              template={false}
              remarkOnClick={openRemark}
              remarkDisabled={!viewingClient || isLoading}
              title={encodeURIComponent(
                renderToString(<MyEditor readOnly={true} editorState={remark} textAlignment="center" />)
              )}
              {...rest}
            />
          </PreviewWrapper>
        </div>

        {!presentMode && (
          <div className="export-step">
            <PolicyReviewRemark onGenerate={generate} />
          </div>
        )}
      </div>
    </div>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    viewingClient: state.client.viewingClient,
    fetchClientPhase: state.client.fetchClientPhase,
    ...state.policyReview
  }),
  {
    handleDashboardPolicyData,
    updatePolicyReviewInputs,
    updateViewingClient,
    getPolicyReview,
    resetPolicyReview
  }
)(PolicyReviewContainer);

export default injectIntl(container);
