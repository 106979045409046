import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import './Login.css';
import { getRegionSuffixInUrlSearch, saveRegion } from '../../utils/region';
import Backend from '../../backend';
import _ from 'lodash';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { getProFlagFromLocalStorage, ShortSubscriptions } from '../../utils/user-subscription';
import { getSearchParam } from '../../utils/url';
import ProCornerTriangle from '../../components/CornerTriangle/ProCornerTriangle';
import { SlideTransition } from '../../components/Transition/Transition';
import { formatEmail, isEmailValid } from '../../utils/email';
import { switchLanguage, tryTranslate } from '../../utils/locale';
import { ERROR, INIT, LOADING, SUCCESS } from '../../constants/phase';
import IconToast from '../../components/NewToast';
import { shouldShowWelcomeDialog, isUserConnected, getUserLogo } from '../../utils/user';
import { getDefaultRoute, getSearchObject } from '../../utils/router';
import { LOGIN_BANNER_IMG } from '../../constants/images';
import NativeOrWeb from '../../utils/native';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import { AUTO_TRIED_BIOMETRIC_LOGIN_SESSION_KEY } from '../../constants/session';
import VerifyDialog from './VerifyDialog/VerifyDialog';
import LoginBannerContainer from '../../components/LoginBannerContainer/LoginBannerContainer';
import { withStyles } from '@material-ui/styles';
import LoginStyles from './LoginStyles';
import TermsAndPolicyTypography from '../../components/TermsAndPolicyTypography/TermsAndPolicyTypography';
import { ADVISER_LOGO_FUNCTIONS } from '../../constants/user';
import LoginLockAnim from './LoginLockAnim/LoginLockAnim';
import EmailVerificationModal from '../../components/EmailVerificationModal/EmailVerificationModal';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

const REVISION = process.env.REVISION;
if (REVISION) {
  console.log(REVISION);
}

const LargeATypographyContainerStyle = { textAlign: 'center' };
const LargeATypographyStyle = {
  fontWeight: 'bold',
  textDecoration: 'underline',
  cursor: 'pointer'
};
export const LargeATypography = props => {
  const { ...rest } = props;
  return (
    <div style={LargeATypographyContainerStyle}>
      <Typography component="span" color="primary" style={LargeATypographyStyle} {...rest} />
    </div>
  );
};

class LoginForm extends PureComponent {
  constructor(props) {
    super(props);

    NativeOrWeb.getStorage('teamShortName')
      .then(teamShortName => {
        this.teamShortNameGot = true;
        if (teamShortName) {
          const logoUri = Backend.api('logo') + `/${teamShortName}`;
          const userLogo = getUserLogo(props.userDetails, logoUri, ADVISER_LOGO_FUNCTIONS.LOGIN);
          this.setState({ logoUri: userLogo, ready: false });
        } else {
          this.setState({ ready: true });
        }
      })
      .catch();

    let isPro = getProFlagFromLocalStorage();
    const proInSearch = getSearchParam(this.props.location.search, 'pro');
    if (proInSearch !== null && proInSearch !== 'false' && proInSearch !== 'undefined') {
      isPro = true;
    }

    localStorage.removeItem('clientId');

    const searchObject = getSearchObject(props.history);
    const email = _.get(searchObject, 'email');

    this.state = {
      email: email || '',
      password: '',
      pendingApprovalModal: false,
      emailNotVerifiedModal: false,
      err: {},
      nextLocation: undefined,
      accountLockedModal: false,
      logoUri: LOGIN_BANNER_IMG,
      isPro: isPro,
      showTimeoutModal: this.props.timeout ? true : false,
      inputDisabled: false,
      forceDisableBioAuth: false,
      verifyDialog: false,
      lastUserEmail: undefined,
      lastUserName: undefined,
      ready: false,
      lockAnimStatus: 'init'
    };
    this.closePendingApprovalModal = this.closePendingApprovalModal.bind(this);
    this.closeEmailNotVerifiedModal = this.closeEmailNotVerifiedModal.bind(this);
    this.closeAccountLockedModal = this.closeAccountLockedModal.bind(this);
    this.redirectToLinker = this.redirectToLinker.bind(this);
  }

  onBannerLoad = () => {
    if (this.teamShortNameGot) {
      this.setState({ ready: true });
    }
  };

  UNSAFE_componentWillMount() {
    const { history, location } = this.props;

    // check whether nextUrl is set via location

    let nextLocation = undefined;
    if (location.search.includes('next=')) {
      nextLocation = new URLSearchParams(location.search).get('next');
      console.log('Login - nextLocation: ', nextLocation);
    } else if (location.state && location.state.from) {
      nextLocation = location.state.from;
    } else if (location.nextUrl) {
      nextLocation = location.nextUrl;
    } else if (location.state && location.state.nextUrl) {
      nextLocation = location.state.nextUrl;
    }

    let showTimeoutModal = this.state.showTimeoutModal;
    if (location.state && location.state.timeout) {
      showTimeoutModal = location.state.timeout;
    }

    if (!nextLocation) {
      history.replace();
    }

    // save formatted region got from url search to local storage
    saveRegion(getRegionSuffixInUrlSearch(this.props.location.search));

    this.setState({ nextLocation: nextLocation, showTimeoutModal });
  }

  autoTryBiometricLogin = async verifyDialog => {
    if (!sessionStorage.getItem(AUTO_TRIED_BIOMETRIC_LOGIN_SESSION_KEY)) {
      this.biometricLogin(verifyDialog)
        .then()
        .catch();
    } else {
      this.setState({ inputDisabled: false });
    }
  };

  verifyLogout = async () => {
    const { handleSignOut } = this.props;
    handleSignOut(null, true, true);
    this.setState({ verifyDialog: false, inputDisabled: false });
  };

  cancelVerification = async () => {
    this.setState({ verifyDialog: false, inputDisabled: false });
  };

  verifyPassword = async password => {
    const { loginUser } = this.props;
    const { lastUserEmail } = this.state;
    this.isBiometricLogin = false;
    loginUser({ email: lastUserEmail, password: password });
  };

  async componentDidMount() {
    const { intl, isBiometricSet, isAuthenticated, authenticatedUser } = this.props;
    const { email } = this.state;
    /*to display the page language as per the browser language*/
    var user = this.props.userDetails;
    if (_.isEmpty(user)) {
      // var userLang = navigator.language || navigator.userLanguage;
      // const language = userLang === 'zh-HK' ? 'zh-Hant-HK' : userLang === 'zh-CN' ? 'zh' : 'en';
      // this.props.selectedLocale(language);
    }

    // const isIos = () => {
    //   const userAgent = window.navigator.userAgent.toLowerCase();
    //   return /iphone|ipad|ipod/.test(userAgent);
    // };
    // Detects if device is in standalone mode
    // const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;

    // Checks if should display install popup notification:
    // if (isIos() && isInStandaloneMode()) {
    //   toast.info(
    //     IconToast(
    //       'info',
    //       'Due to IOS limitation, the APP would reload everytime. To avoid, go app.portfoplus.com use Safari.'
    //     ),
    //     { className: 'new-toast' }
    //   );
    // }

    // PWA install prompt
    // if (!window.matchMedia('(display-mode: standalone)').matches && !sessionStorage.getItem('pwaInstalled')) {
    //   toast.info(
    //     IconToast('pwa', intl.formatMessage({ id: 'add-to-home' }), () =>
    //       window.open('http://bit.ly/pfpicon', '_blank')
    //     ),
    //     {
    //       className: 'new-toast',
    //       onClick: () => window.open('http://bit.ly/pfpicon', '_blank'),
    //       autoClose: 15000
    //     }
    //   );
    // }

    window.addEventListener('appinstalled', event => {
      sessionStorage.setItem('pwaInstalled', 'true');
    });

    if (!!isAuthenticated) {
      const shortSubscriptions = new ShortSubscriptions(_.get(authenticatedUser, 'subscriptions', []));

      if (!this.historyPushObject) {
        if (shortSubscriptions.containValidCoreSubscription()) {
          sessionStorage.setItem(AUTO_TRIED_BIOMETRIC_LOGIN_SESSION_KEY, 'true');
          this.setState({
            inputDisabled: true,
            verifyDialog: false,
            lockAnimStatus: LOADING
          });
          await this.continueLogin(this.props);
          this.setState({
            lastUserEmail: undefined,
            lastUserName: undefined,
            lockAnimStatus: SUCCESS
          });
        }
      }
    } else {
      // Native flow
      if (!email) {
        let lastUserEmail = await NativeOrWeb.getUserEmail();
        if (lastUserEmail) {
          lastUserEmail = (lastUserEmail || '').trim().toLowerCase();
          const lastUserName = ((await NativeOrWeb.getUserName()) || '').trim().toLowerCase();
          const credentials = await NativeOrWeb.getBiometricCredentials();
          if (credentials && credentials.username) {
            const credentialsUsername = (credentials.username || '').trim().toLowerCase();
            if (isBiometricSet && lastUserEmail === credentialsUsername) {
              this.setState({
                lastUserEmail: lastUserEmail,
                lastUserName: lastUserName,
                inputDisabled: true
              });
              this.autoTryBiometricLogin(true)
                .then()
                .catch();
            } else {
              this.setState({
                verifyDialog: true,
                lastUserEmail: lastUserEmail,
                lastUserName: lastUserName,
                inputDisabled: true
              });
            }
          } else {
            this.setState({
              verifyDialog: true,
              lastUserEmail: lastUserEmail,
              lastUserName: lastUserName,
              inputDisabled: true
            });
          }
        } else {
          // if (isBiometricSet) {
          //   this.autoTryBiometricLogin()
          //     .then()
          //     .catch();
          // }
        }
      }
    }
  }

  redirectToLinker(subscriptions) {
    const containLinkerSubscription = new ShortSubscriptions(subscriptions).containLinkerSubscription();
    const containProSubscription = new ShortSubscriptions(subscriptions).containProSubscription();
    if (!containLinkerSubscription && !containProSubscription) {
      const linkerUrl = process.env.LINKER_URL;
      const nextPath = window.location.search?.split('path=')[1]?.split('&')[0];
      if (linkerUrl) {
        const nextUrl = [linkerUrl, nextPath].join('');
        window.location.href = nextUrl;
        return;
      }
      return;
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { intl, loginPhase, userDetails, subscriptions, authenticatedUser, history } = this.props;

    const obj = {};

    if (loginPhase === false && prevProps.loginPhase !== false) {
      sessionStorage.setItem(AUTO_TRIED_BIOMETRIC_LOGIN_SESSION_KEY, 'true');

      if (this.isBiometricLogin) {
        await NativeOrWeb.disableBiometricAuth();
        this.setState({ forceDisableBioAuth: true });
        alert(intl.formatMessage({ id: 'biometric-re-enable' }));
      }
      if (_.get(this.props, 'userDetails.passwordAttempt') && _.get(this.props, 'userDetails.passwordAttempt') > 5) {
        obj.accountLockedModal = true;
      }
      obj.verifyDialog = false;
      obj.lastUserEmail = undefined;
      obj.lastUserName = undefined;
      obj.lockAnimStatus = ERROR;
    } else if (
      (loginPhase === 'pending_approval' || loginPhase === 'email_not_verified') &&
      prevProps.loginPhase !== 'pending_approval' &&
      prevProps.loginPhase !== 'email_not_verified'
    ) {
      sessionStorage.setItem(AUTO_TRIED_BIOMETRIC_LOGIN_SESSION_KEY, 'true');

      if (loginPhase === 'pending_approval') {
        obj.pendingApprovalModal = true;
        obj.emailNotVerifiedModal = false;
        obj.email = '';
      } else {
        obj.pendingApprovalModal = false;
        obj.emailNotVerifiedModal = true;
        obj.email = this.state.email;
      }
      obj.password = '';
      obj.verifyDialog = false;
      obj.lastUserEmail = undefined;
      obj.lastUserName = undefined;
      obj.lockAnimStatus = INIT;
    } else if (loginPhase === true && prevProps.loginPhase !== true) {
      sessionStorage.setItem(AUTO_TRIED_BIOMETRIC_LOGIN_SESSION_KEY, 'true');

      obj.inputDisabled = true;
      obj.verifyDialog = false;
      obj.lastUserEmail = undefined;
      obj.lastUserName = undefined;

      const isLinkerFreemium = new ShortSubscriptions(subscriptions).containLinkerFreeSubscription();
      if (!(subscriptions.length > 0 && !userDetails.emailVerified) || isLinkerFreemium) {
        obj.lockAnimStatus = SUCCESS;
        const promises = [this.continueLogin(this.props)];

        const { email, password } = this.state;
        if (email && password) {
          promises.push(NativeOrWeb.askBiometricAuth(email, password));
        }
        await Promise.all(promises);
      }
    } else if (loginPhase === LOADING && prevProps.loginPhase !== LOADING) {
      obj.lockAnimStatus = LOADING;
    }
    if (!prevProps.authenticatedUser && authenticatedUser) {
      if (this.state.nextLocation === 'linker') {
        this.redirectToLinker(subscriptions);
      }
      // else {
      //   history.push(this.state.nextLocation);
      // }
    }

    this.setState(obj);
  }

  biometricLogin = async verifyDialog => {
    try {
      sessionStorage.setItem(AUTO_TRIED_BIOMETRIC_LOGIN_SESSION_KEY, 'true');

      const { loginUser } = this.props;
      let credentials = await NativeOrWeb.getBiometricCredentialsWithVerification();
      if (credentials && credentials.username && credentials.password) {
        this.isBiometricLogin = true;
        this.setState({ inputDisabled: false });
        loginUser({
          email: credentials.username,
          password: credentials.password
        });
      } else {
        if (verifyDialog === true) {
          credentials = await NativeOrWeb.getBiometricCredentials();
          if (credentials) {
            this.setState({
              lastUserEmail: credentials.username,
              verifyDialog: true,
              inputDisabled: true
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  async continueLogin(nextProps) {
    const {
      userDetails,
      setShownDashHelp,
      currencyExchangeRate,
      loginFetch,
      updateFirstGuidelineState,
      shortSubscriptions
    } = nextProps;

    if (nextProps.exchangeRate && _.isEmpty(nextProps.exchangeRate)) {
      currencyExchangeRate();
    }

    const showAdviserBlast = userDetails.userType === 'User';
    loginFetch(userDetails._id, userDetails.userType, {
      state: { showAdviserBlast: showAdviserBlast }
    });

    // sending the welcome message , remind target & reminderTarget(to show the popup of reminder) on dashboard so that they display only once when user is login
    // logic to display the welcome message , reminder target and reminder popup
    var birthdayPopUp = userDetails.userType === 'Adviser' ? true : false;
    let routePath = getDefaultRoute(userDetails);
    let path = userDetails.hideWelcome ? routePath : '/welcome';
    var welcomeMessage = shouldShowWelcomeDialog(userDetails);
    var remindTarget = userDetails.userType === 'Adviser' && !welcomeMessage ? userDetails.remindTarget : false;
    if (userDetails.userType === 'User' && !isUserConnected(userDetails)) {
      setShownDashHelp(false);
    } else if (userDetails.userType === 'Adviser') {
    }
    updateFirstGuidelineState(false);

    this.historyPushObject = {
      pathname: path,
      state: {
        remindTarget: remindTarget,
        welcomeMessage: welcomeMessage,
        birthdayPopUp: birthdayPopUp,
        showAdviserBlast: showAdviserBlast,
        showTeamBlast: userDetails.userType === 'Adviser',
        noBackButton: true,
        loginRedirect: true,
        nextUrl: undefined
      }
    };

    setTimeout(() => {
      if (this.state.nextLocation !== undefined && this.state.nextLocation !== 'linker') {
        this.setState({ nextLocation: undefined });
        this.props.history.push(this.state.nextLocation);
      }
    }, 0);
  }

  handleChange(e) {
    if (e.target.name === 'email') {
      delete this.state.err.email;
    }
    if (e.target.name === 'password') {
      delete this.state.err.password;
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  async submitForm(event) {
    event.preventDefault();
    console.log('Login submitForm: ', this.state);
    const { inputDisabled } = this.state;
    if (inputDisabled) {
      return;
    }
    const { loginUser } = this.props;
    const err = {};
    if (this.state.email === '' || this.state.email.trim() === '') {
      err.email = 'Email is required';
    } else if (!isEmailValid(this.state.email)) {
      err.email = 'Invalid email';
    }
    if (this.state.password === '' || this.state.password.trim() === '') {
      err.password = 'Password is required';
    }
    this.setState({ err });
    if (!Object.keys(err).length) {
      var postData = {};
      postData.password = this.state.password.trim();
      postData.email = formatEmail(this.state.email);
      this.setState({ inputDisabled: true });
      this.isBiometricLogin = false;
      loginUser(postData);
    }
  }

  closePendingApprovalModal() {
    this.setState({
      pendingApprovalModal: false
    });
  }

  closeEmailNotVerifiedModal() {
    this.setState({
      emailNotVerifiedModal: false
    });
  }

  closeAccountLockedModal() {
    this.setState({
      accountLockedModal: false
    });
    this.props.history.push({
      pathname: '/forgot_password'
    });
  }

  animationEnd = status => {
    const { intl, loginMessage, loginMessageExtras } = this.props;
    if (status === SUCCESS) {
      const { history, location, userDetails, selectedLocale, checkIsBiometricSet } = this.props;
      const { forceDisableBioAuth } = this.state;
      if (this.historyPushObject) {
        const replaceLocation = _.omit(location, ['state.from', 'nextUrl']);
        history.replace(replaceLocation);
        history.push(this.historyPushObject);
        switchLanguage(selectedLocale, userDetails.language);
        if (forceDisableBioAuth) {
          checkIsBiometricSet();
        }
      }
    } else if (status === ERROR) {
      toast.info(IconToast('error', intl.formatMessage({ id: loginMessage }, loginMessageExtras)), {
        className: 'new-toast'
      });
      this.setState({ inputDisabled: false });
    } else if (status === LOADING) {
      this.setState({ inputDisabled: true });
    }
  };

  closeTimeoutModal = () => this.setState({ showTimeoutModal: false });

  /* closeEmailVerfiyModal() {
              this.setState({
                emailVerfiyModal: false
              });
              }*/

  /* resendEmailVerification(){
              let obj = {}
              const {approveUser} = this.props
              obj.email = _.get(this.state,'email','')
              obj.resendVerification = 'resendVerification'
              approveUser(obj)
              }
          */
  render() {
    const { history, intl, isBiometricSet } = this.props;
    const {
      logoUri,
      isPro,
      nextLocation,
      inputDisabled,
      forceDisableBioAuth,
      verifyDialog,
      lastUserEmail,
      lastUserName,
      ready
    } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        {REVISION && <div style={{ position: 'fixed', top: 0, zIndex: 1 }}>{REVISION}</div>}

        <VerifyDialog
          open={verifyDialog}
          email={lastUserEmail}
          name={lastUserName}
          onLogout={this.verifyLogout}
          onCancel={this.cancelVerification}
          onSubmit={this.verifyPassword}
        />

        <Dialog
          open={this.state.showTimeoutModal}
          onClose={this.closeTimeoutModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'timeout-title' })}</DialogTitle>
          <DialogContent>{intl.formatMessage({ id: 'timeout-content' })}</DialogContent>
          <DialogActions>
            <Button onClick={this.closeTimeoutModal} color="primary">
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.pendingApprovalModal}
          onClose={this.closependingApprovalModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Pending Approval' })}</DialogTitle>
          <DialogContent>{tryTranslate(intl, this.props.loginMessage, this.props.loginMessageExtras)}</DialogContent>
          <DialogActions>
            <Button onClick={this.closependingApprovalModal} color="primary" autoFocus>
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>

        <EmailVerificationModal history={history} open={this.state.emailNotVerifiedModal} email={this.state.email} />

        <Dialog
          open={this.state.accountLockedModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Account Locked' })}</DialogTitle>
          <DialogContent>
            {this.props.loginMessage
              ? intl.formatMessage({ id: 'account_locked_message' }, this.props.loginMessageExtras)
              : ''}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeAccountLockedModal} color="primary" autoFocus>
              {intl.formatMessage({ id: 'Reset Password' })}
            </Button>
          </DialogActions>
        </Dialog>

        <LoginBannerContainer src={logoUri} loginBanner={true} ready={ready} onLoad={this.onBannerLoad}>
          <ProCornerTriangle explicitShow={isPro} />

          {/*<LoginBannerImage src={logoUri} ready={ready} onLoad={this.onBannerLoad} />*/}

          <Grid item xs={12}>
            <div className={classes.paper}>
              <Typography component="h1" variant="h3" className={classes.desktopLoginwrapper}>
                {intl.formatMessage({ id: 'Sign In' })}
              </Typography>
              <LoginLockAnim status={this.state.lockAnimStatus} onCompleteAnimation={this.animationEnd} />
              {nextLocation && (
                <Typography color="error" style={{ fontWeight: 500 }}>
                  {intl.formatMessage({ id: 'login-redirect-hint' })}
                </Typography>
              )}
              {/*<Avatar className={classes.avatar}>*/}
              {/*  <LockOutlinedIcon />*/}
              {/*</Avatar>*/}
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange.bind(this)}
                  required
                  autoComplete="username email"
                  fullWidth
                  id="email"
                  className={classes.logindetails}
                  InputProps={{
                    classes: {
                      root: classes.inputBase,
                      input: classes.input
                    }
                  }}
                  disabled={inputDisabled}
                  label={intl.formatMessage({ id: 'Login Email' })}
                />
                {this.state.err.email ? (
                  <span className="error_field">{intl.formatMessage({ id: this.state.err.email })}</span>
                ) : (
                  ''
                )}

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label={intl.formatMessage({ id: 'Password' })}
                  type="password"
                  id="password"
                  value={this.state.password}
                  onChange={this.handleChange.bind(this)}
                  required
                  autoComplete="current-password"
                  className={classes.logindetails}
                  InputProps={{
                    classes: {
                      root: classes.inputBase,
                      input: classes.input
                    }
                  }}
                  disabled={inputDisabled}
                />
                {this.state.err.password ? (
                  <span className="error_field">{intl.formatMessage({ id: 'password-required' })}</span>
                ) : (
                  ''
                )}

                <Grid container spacing={1} alignItems="center">
                  <Grid item style={{ flexGrow: 1 }}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={this.submitForm.bind(this)}
                    >
                      {intl.formatMessage({ id: 'LOGIN' })}
                    </Button>
                  </Grid>

                  {isBiometricSet && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={inputDisabled || forceDisableBioAuth}
                        onClick={this.biometricLogin}
                        style={{ minWidth: 'unset', width: 48, height: 48 }}
                      >
                        <FingerprintIcon />
                      </Button>
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Link to="/forgot_password">{intl.formatMessage({ id: 'FORGOT PASSWORD' })}</Link>
                </Grid>
              </form>
            </div>
          </Grid>
          <Grid item xs={12} style={{ zIndex: '1' }}>
            {!NativeOrWeb.isIOSPlatform() && (
              <div className="btn-signUp">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.signupButton}
                  onClick={() => {
                    history.push('/userType');
                  }}
                >
                  {intl.formatMessage({ id: 'SIGN UP HERE' })}
                </Button>
              </div>
            )}
            <TermsAndPolicyTypography />
            {!NativeOrWeb.isIOSPlatform() && (
              <LargeATypography
                onClick={() =>
                  window.open('https://www.portfoplus.com/?utm_source=applogin&utm_medium=button', '_blank')
                }
              >
                {intl.formatMessage({ id: 'home-page' })}
              </LargeATypography>
            )}
          </Grid>
        </LoginBannerContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(LoginStyles)(LoginForm);
