import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Phone from 'react-phone-number-input';
import countryList from '../../../store/locale/country-list';
import { openWhatsappUrl, getClientWhatsappUrl } from '../../../utils/client';
import _ from 'lodash';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';
import IconToast from '../../../components/NewToast';
import { getAdminEmail } from '../../../utils/email';
import { sanitize } from 'dompurify';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';
import './signup.scss';

export default class SignupView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: '',
      userType: 'Adviser',
      name: '',
      email: '',
      workEmail: '',
      password: '',
      confirmPassword: '',
      modalIsOpen: false,
      err: {},
      pendingModal: false,
      doubleChecked: false,
      dmChecked: false,
      phoneNumber: '',
      whatsapp: '',
      language: 'en',
      remark: '',
      wechatId: '',
      lineId: ''
    };
    this.submitForm = this.submitForm.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closePendingModal = this.closePendingModal.bind(this);
  }

  componentDidMount() {
    //to display the page language as per the browser language
    var user = this.props.userDetails;
    if (_.isEmpty(user)) {
      var userLang = navigator.language || navigator.userLanguage;
      const language = userLang === 'zh-HK' ? 'zh-Hant-HK' : userLang === 'zh-CN' ? 'zh' : 'en';
      this.props.selectedLocale(language);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl } = this.props;
    if (nextProps.createPhase === true) {
      const state = this.state;
      state.name = '';
      state.email = '';
      state.workEmail = '';
      state.password = '';
      state.confirmPassword = '';
      state.err = {};
      state.lineId = '';
      state.wechatId = '';
      state.isLoading = true;
      this.setState(state);
      toast.info(IconToast('info', intl.formatMessage({ id: nextProps.createMessage })), { className: 'new-toast' });
      this.props.history.push({ pathname: '/welcome' });
    }
    if (nextProps.createPhase === false) {
      this.setState({
        isLoading: true,
        modalIsOpen: false
      });
      toast.info(IconToast('error', intl.formatMessage({ id: nextProps.createMessage })), { className: 'new-toast' });
    }
    if (nextProps.createPhase === 'hold') {
      this.setState({
        pendingModal: true,
        modalIsOpen: false
      });
    }
  }

  handleChange(e) {
    if (e.target.name === 'name') {
      delete this.state.err.name;
    }
    if (e.target.name === 'email') {
      delete this.state.err.email;
    }
    if (this.state.userType === 'Adviser') {
      if (e.target.name === 'workEmail') {
        delete this.state.err.workEmail;
      }
    }
    if (e.target.name === 'password') {
      delete this.state.err.password;
    }
    if (e.target.name === 'confirmPassword') {
      delete this.state.err.confirmPassword;
    }
    if (e.target.name === 'phoneNumber') {
      delete this.state.err.phoneNumber;
    }
    if (e.target.name === 'whatsapp') {
      delete this.state.err.whatsapp;
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  opneModal() {
    const err = {};
    const { intl } = this.props;
    if (this.state.name === '' || this.state.name.trim() === '') {
      err.name = 'Name is required';
    }
    if (this.state.email === '' || this.state.email.trim() === '') {
      err.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
      err.email = 'Invalid email';
    }
    if (this.state.userType === 'Adviser') {
      if (this.state.workEmail !== '' && this.state.workEmail.trim() !== '') {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.workEmail)) {
          err.workEmail = 'Invalid work email';
        }
      }
    }
    if (this.state.password === '' || this.state.password.trim() === '') {
      err.password = 'Password is required';
    }
    if (this.state.confirmPassword === '' || this.state.confirmPassword.trim() === '') {
      err.confirmPassword = 'Confirm Password is required';
    }
    if (this.state.password.length < 6) {
      err.password = 'Password must be at least 6 characters.';
    } else if (this.state.confirmPassword !== this.state.password) {
      err.confirmPassword = 'Password do not match';
    }
    if (this.state.doubleChecked === false) {
      err.doubleChecked = intl.formatMessage({
        id: 'Please read and accept beforehand'
      });
    }
    if (this.state.dmChecked === false) {
      err.dmChecked = intl.formatMessage({ id: 'register-dm-check-error' });
    }
    if (
      this.state.userType === 'Adviser' &&
      (this.state.phoneNumber === '' ||
        this.state.phoneNumber === undefined ||
        this.state.phoneNumber === null ||
        this.state.phoneNumber.trim() === '')
    ) {
      err.phoneNumber = 'input_phone_number';
    }

    this.setState({ err });
    if (!Object.keys(err).length) {
      this.setState({ modalIsOpen: true });
    }
  }

  submitForm() {
    const { createUser } = this.props;
    var postData = {};
    let Date1 = new Date();
    let timezone = Date1.getTimezoneOffset();
    postData.password = this.state.password.trim();
    postData.name = this.state.name.trim();
    postData.email = this.state.email.trim();
    postData.userType = this.state.userType;
    postData.disabled = false;
    postData.workEmail = '';
    postData.emailVerified = false;
    postData.language = this.state.language;
    postData.timeZone = -timezone;
    if (this.state.userType === 'Adviser') {
      //adding this three extra keys for adviser
      postData.workEmail = this.state.workEmail.trim();
      postData.phoneNumber = this.state.phoneNumber;
      postData.whatsapp = this.state.whatsapp;
      postData.disabled = true;
      postData.remark = this.state.remark;
      postData.wechatId = this.state.wechatId;
      postData.lineId = this.state.lineId;
    }
    if (this.state.doubleChecked) {
      createUser(postData);
    }
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  closePendingModal() {
    this.setState({
      pendingModal: false
    });
  }
  whatsApp() {
    console.log('+++++');
  }

  render() {
    const { intl, createPhase } = this.props;
    let productionEnv = process.env.NODE_ENV === 'production' ? true : false;
    const adminEmail = getAdminEmail();
    return (
      <div>
        {createPhase === 'loading' && <LoadingBackdrop open={true} />}
        <div id="loginWrapper" className="sign-up-wrapper">
          <form className="myForm" style={{ paddingTop: '80px' }}>
            <div className="btn-radio radioButton">
              {!productionEnv && (
                <div>
                  <input
                    type="radio"
                    name="userType"
                    checked={this.state.userType === 'User'}
                    value="User"
                    onChange={this.handleChange.bind(this)}
                  />
                  <span>{intl.formatMessage({ id: 'User' })}</span>
                </div>
              )}
              <div>
                <input
                  type="radio"
                  name="userType"
                  checked={this.state.userType === 'Adviser'}
                  value="Adviser"
                  onChange={this.handleChange.bind(this)}
                />
                <span>{intl.formatMessage({ id: 'Adviser' })}</span>
              </div>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.handleChange.bind(this)}
                required="required"
              />
              <label className="control-label" htmlFor="input">
                {intl.formatMessage({ id: 'Name' })}*
              </label>
              <i className="bar" />
              {this.state.err.name ? (
                <span className="error_field">{intl.formatMessage({ id: this.state.err.name })}</span>
              ) : (
                ''
              )}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="email"
                value={this.state.email}
                onChange={this.handleChange.bind(this)}
                required="required"
              />
              <label className="control-label" htmlFor="input">
                {intl.formatMessage({ id: 'login-email-unchangeable' })}*
              </label>
              <i className="bar" />
              {this.state.err.email ? (
                <span className="error_field">{intl.formatMessage({ id: this.state.err.email })}</span>
              ) : (
                ''
              )}
            </div>
            {this.state.userType === 'Adviser' && (
              <div>
                <div className="form-group">
                  <input
                    type="text"
                    name="workEmail"
                    value={this.state.workEmail}
                    onChange={this.handleChange.bind(this)}
                    required="required"
                  />
                  <label className="control-label" htmlFor="input">
                    {intl.formatMessage({ id: 'work-email-changeable' })}
                  </label>
                  <i className="bar" />
                  {this.state.err.workEmail ? (
                    <span className="error_field">{intl.formatMessage({ id: this.state.err.workEmail })}</span>
                  ) : (
                    ''
                  )}
                </div>

                <div className="form-group phone_module clearfix">
                  <label className="phone-module-label signup-phone-label" htmlFor="input">
                    {intl.formatMessage({ id: 'Phone Number' })}*
                  </label>
                  <Phone
                    international={true}
                    // inputComponent={SmartInput}
                    country="HK"
                    placeholder={intl.formatMessage({
                      id: 'Enter phone number'
                    })}
                    value={this.state.phoneNumber}
                    onChange={value =>
                      this.setState({
                        phoneNumber: value,
                        err: {
                          ...this.state.err,
                          phoneNumber: ''
                        }
                      })
                    }
                    //labels={countryList.labels}
                    countries={countryList.labels}
                    flagsPath={'img/flags/'}
                    indicateInvalid
                  />

                  <i className="bar" />

                  {this.state.err.phoneNumber ? (
                    <span className="error_field">{intl.formatMessage({ id: this.state.err.phoneNumber })}</span>
                  ) : (
                    ''
                  )}
                </div>

                <div className="form-group phone_module clearfix signup-phone">
                  <label className="phone-module-label signup-phone-label" htmlFor="input">
                    {intl.formatMessage({ id: 'WhatsApp' })}
                  </label>

                  <Phone
                    international={true}
                    // inputComponent={SmartInput}
                    country="HK"
                    placeholder={intl.formatMessage({
                      id: 'Enter whatsapp number'
                    })}
                    value={this.state.whatsapp}
                    onChange={value =>
                      this.setState({
                        whatsapp: value,
                        err: {
                          ...this.state.err,
                          whatsapp: ''
                        }
                      })
                    }
                    countries={countryList.labels}
                    flagsPath={'img/flags/'}
                    //labels={countryList.labels}
                    indicateInvalid
                  />

                  <i className="bar" />

                  {this.state.err.whatsapp ? (
                    <span className="error_field">{intl.formatMessage({ id: this.state.err.whatsapp })}</span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6 col-xs-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="wechatId"
                        value={this.state.wechatId}
                        onChange={this.handleChange.bind(this)}
                        required="required"
                      />
                      <label className="control-label" htmlFor="input">
                        {intl.formatMessage({ id: 'WeChat ID' })}
                      </label>
                      <i className="bar" />
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="lineId"
                        value={this.state.lineId}
                        onChange={this.handleChange.bind(this)}
                        required="required"
                      />
                      <label className="control-label" htmlFor="input">
                        {intl.formatMessage({ id: 'Line ID' })}
                      </label>
                      <i className="bar" />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="form-group">
              <input
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange.bind(this)}
                required="required"
              />
              <label className="control-label" htmlFor="input">
                {intl.formatMessage({ id: 'Password' })}*
              </label>
              <i className="bar" />
              {this.state.err.password ? (
                <span className="error_field">{intl.formatMessage({ id: this.state.err.password })}</span>
              ) : (
                ''
              )}
            </div>
            <div className="form-group">
              <input
                type="password"
                name="confirmPassword"
                value={this.state.confirmPassword}
                onChange={this.handleChange.bind(this)}
                required="required"
              />
              <label className="control-label" htmlFor="input">
                {intl.formatMessage({ id: 'Confirm Password' })}*
              </label>
              <i className="bar" />
              {this.state.err.confirmPassword ? (
                <span className="error_field">{intl.formatMessage({ id: this.state.err.confirmPassword })}</span>
              ) : (
                ''
              )}
            </div>

            <div className="form-group">
              <textarea
                type="text"
                name="remark"
                value={this.state.remark}
                onChange={this.handleChange.bind(this)}
                required="required"
              />
              <label className="control-label" htmlFor="input">
                {intl.formatMessage({ id: 'Remark' })}
              </label>
              <i className="bar" />
            </div>

            <div className="signup-doublecheck">
              <FormControlLabel
                control={
                  <Checkbox
                    name="doubleChecked"
                    value="doubleChecked"
                    checked={this.state.doubleChecked}
                    onChange={e => {
                      this.setState({
                        doubleChecked: !this.state.doubleChecked
                      });
                      delete this.state.err.doubleChecked;
                    }}
                    color="primary"
                  />
                }
                className="double-check-label"
              />
              {intl.formatMessage({
                id: 'By signing up I agree with PortfoPlus'
              })}{' '}
              <a
                rel="noopener noreferrer"
                className="login-link"
                href="https://www.portfoplus.com/terms.html"
                target="_blank"
              >
                {intl.formatMessage({ id: 'Terms of Service' })}
              </a>{' '}
              {intl.formatMessage({ id: 'and' })}{' '}
              <a
                rel="noopener noreferrer"
                className="login-link"
                href="https://www.portfoplus.com/privacy.html"
                target="_blank"
              >
                {intl.formatMessage({ id: 'Privacy Policy' })}
              </a>
              {this.state.err.doubleChecked ? <div className="error_field">{this.state.err.doubleChecked}</div> : ''}
            </div>

            <div className="dm-doublecheck">
              <FormControlLabel
                control={
                  <Checkbox
                    name="dmChecked"
                    value="dmChecked"
                    checked={this.state.dmChecked}
                    onChange={e => {
                      this.setState({
                        dmChecked: !this.state.dmChecked
                      });
                      delete this.state.err.dmChecked;
                    }}
                    color="primary"
                  />
                }
                className="double-check-label"
              />
              {intl.formatMessage({ id: 'register-dm-check' })}
              {this.state.err.dmChecked && <div className="error_field">{this.state.err.dmChecked}</div>}
            </div>

            <div>
              {intl.formatMessage({
                id: 'Language Preference'
              })}
              <br />
              <Button
                variant={this.state.language === 'en' ? 'contained' : 'outlined'}
                className={
                  this.state.language === 'en'
                    ? 'option-select-button plan-type-select-button selected'
                    : 'option-select-button plan-type-select-button'
                }
                onClick={() => {
                  this.setState({ language: 'en' });
                }}
              >
                {intl.formatMessage({ id: 'English' })}
              </Button>
              <Button
                variant={this.state.language === 'zh-Hant-HK' ? 'contained' : 'outlined'}
                className={
                  this.state.language === 'zh-Hant-HK'
                    ? 'option-select-button plan-type-select-button selected'
                    : 'option-select-button plan-type-select-button'
                }
                onClick={() => {
                  this.setState({ language: 'zh-Hant-HK' });
                }}
              >
                {intl.formatMessage({ id: 'Chinese HK' })}
              </Button>
              <Button
                variant={this.state.language === 'zh' ? 'contained' : 'outlined'}
                className={
                  this.state.language === 'zh'
                    ? 'option-select-button plan-type-select-button selected'
                    : 'option-select-button plan-type-select-button'
                }
                onClick={() => {
                  this.setState({ language: 'zh' });
                }}
              >
                {intl.formatMessage({ id: 'Chinese CN' })}
              </Button>
              <br />
            </div>
            <h4 type="submit" className="btn-blue" onClick={this.opneModal.bind(this)}>
              {intl.formatMessage({ id: 'Sign Up' })}
            </h4>
          </form>
          <div className="signup-points">
            <h3>
              {intl.formatMessage({
                id: 'Welcome! We are in Soft Launch phase:'
              })}
            </h3>
            <ul>
              <li>{intl.formatMessage({ id: 'Reminded_reset' })}</li>
              <li>
                {intl.formatMessage({
                  id: 'User sign up themselves is disabled in this phase.'
                })}
              </li>
              <li>
                {intl.formatMessage({ id: 'Approval_Needed' })}{' '}
                <span>
                  <a
                    className="login-link"
                    onClick={() =>
                      openWhatsappUrl(
                        getClientWhatsappUrl(
                          intl,
                          undefined,
                          intl.formatMessage({
                            id: 'signupwhatsappmsg'
                          }),
                          '85266370320'
                        )
                      )
                    }
                  >
                    {intl.formatMessage({ id: 'WhatsApp' })}
                  </a>
                </span>
                {'/'}
                <span>
                  <a
                    className="login-link"
                    href={`mailto:${adminEmail}?subject=${intl.formatMessage({
                      id: 'sign_up_enquiry'
                    })}&body=${encodeURI(intl.formatMessage({ id: 'signupwhatsappmsg' }))}`}
                    target="_top"
                  >
                    {adminEmail}
                  </a>
                </span>{' '}
                <span>{intl.formatMessage({ id: 'for enquiry' })}</span>
              </li>
            </ul>
          </div>
        </div>
        <Dialog
          open={this.state.modalIsOpen}
          onClose={this.closeModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Sign Up' })}</DialogTitle>
          <DialogContent>
            {intl.formatMessage({ id: 'Sign up as' })} <b>{this.state.userType}</b>{' '}
            {intl.formatMessage({ id: 'signupwith' })} <b>{this.state.email}</b>?
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeModal} color="primary">
              {intl.formatMessage({ id: 'No' })}
            </Button>
            <Button onClick={this.submitForm} color="primary" autoFocus>
              {intl.formatMessage({ id: 'Yes' })}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.pendingModal}
          onClose={this.closePendingModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Thanks_wording' })}</DialogTitle>
          <DialogContent>
            <span
              dangerouslySetInnerHTML={{
                __html: sanitize(intl.formatMessage({ id: 'Enquire_Message' }))
              }}
            />{' '}
            <span>
              <a
                className="login-link"
                onClick={() =>
                  openWhatsappUrl(
                    getClientWhatsappUrl(
                      intl,
                      undefined,
                      intl.formatMessage({ id: 'signupwhatsappmsg' }),
                      '85266370320'
                    )
                  )
                }
              >
                {intl.formatMessage({ id: 'WhatsApp' })}
              </a>
            </span>{' '}
            <span>{intl.formatMessage({ id: 'if any inquiries' })}</span>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closePendingModal} color="primary" autoFocus>
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
