import React from 'react';
import QrCodeWithLogo from 'qr-code-with-logo';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import { getWebImageUrl } from '../utils/image';

class QRCodeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rendered: false,
      width: 200
    };

    this.renderQR = this.renderQR.bind(this);
    this.unmounted = false;
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderQR();
    window.addEventListener('resize', this.renderQR);
  }

  componentWillUnmount() {
    this.unmounted = true;
    if (this && this.renderQR) {
      window.removeEventListener('resize', this.renderQR);
    }
  }

  renderQR() {
    const { content, isStyle, logo } = this.props;

    const targetContainer = _.get(this.containerRef, 'current', document.querySelector(`.${this.getQRClass(isStyle)}`));

    let calculatedWidth = 0;
    if (isStyle === true) {
      calculatedWidth = targetContainer.offsetWidth * 0.8;
    } else if (isStyle === 'client-export') {
      calculatedWidth = 100 * 0.8;
    } else {
      calculatedWidth = targetContainer.offsetWidth * 0.8;
    }
    // With promises
    QrCodeWithLogo.toImage({
      image: targetContainer.querySelector('#qr-code'),
      content: content,
      width: !isStyle ? calculatedWidth : 500,
      logo:
        logo !== false
          ? {
              src: '/img/QRCode.svg',
              logoRadius: 0,
              borderRadius: 0,
              borderSize: 0,
              bgColor: 'transparent'
            }
          : undefined,
      nodeQrCodeOptions: {
        margin: 2,
        errorCorrectionLevel: 'L'
      }
      // nodeQrCodeOptions: {
      //   color: {
      //     //dark:'#FF0000',
      //     light: isStyle === 'client-export' ? '#3eeef8' : ''
      //   }
      // }
    })
      .then(() => {
        if (this) {
          if (!this.unmounted) {
            this.setState({ rendered: true, width: calculatedWidth });
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  getQRClass(isStyle) {
    if (isStyle === true) {
      return 'qr-code-refer';
    } else if (isStyle === 'client-export') {
      return 'emergency_reference--qrcode';
    } else {
      return 'qr-code-wrapper';
    }
  }

  render() {
    const { rendered, calculatedWidth } = this.state;
    const { isStyle, style, imgStyle } = this.props;

    let classType = this.getQRClass(isStyle);

    return (
      <div className={classType} ref={this.containerRef} style={style}>
        {!rendered && (
          <div
            style={{
              position: 'absolute',
              width: 50,
              height: 50,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            <CircularProgress color="primary" />
          </div>
        )}
        <img
          alt="referral-qr-code"
          id="qr-code"
          width={calculatedWidth}
          className={isStyle === true ? 'qr_code' : ''}
          color={'#FF0000'}
          style={{ visibility: !rendered ? 'hidden' : 'unset', ...imgStyle }}
        />
      </div>
    );
  }
}

export default QRCodeComponent;
