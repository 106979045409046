import React, { PureComponent } from 'react';
import ReferralRecordView from './partials/referralRecords';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer.js';
import SubscriptionDialog from '../../Subscription/SubscriptionDialog/SubscriptionDialog.jsx';
import { getCurrentCoreSubscription, UserSubscription } from '../../../utils/user-subscription.js';

class ReferralView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionDialogOpen: false
    };
    this.handleOpenSubscriptionDialog = this.handleOpenSubscriptionDialog.bind(this);
    this.handleCloseSubscriptionDialog = this.handleCloseSubscriptionDialog.bind(this);
  }

  handleOpenSubscriptionDialog = () => {
    this.setState({ subscriptionDialogOpen: true });
  };
  handleCloseSubscriptionDialog = () => {
    this.setState({ subscriptionDialogOpen: false });
  };

  render() {
    const currentLocation = this.props.location.pathname;
    const { userDetails, stripePromise, userSubscription } = this.props;
    const { subscriptionDialogOpen } = this.state;
    const { intl } = this.props;
    const subscriptions = new UserSubscription(userSubscription);
    const currentSubscription = subscriptions.getCurrentCoreSubscription();
    const isPortfoplusUser = subscriptions.containVaildProSubscription();

    return (
      <AppContentContainer>
        <div>
          <div id="wrapper">
            {currentLocation === '/referral_records' && (
              <ReferralRecordView
                handleOpenSubscriptionDialog={this.handleOpenSubscriptionDialog}
                disableAddClient={!isPortfoplusUser}
                {...this.props}
              />
            )}
          </div>
          <SubscriptionDialog
            intl={intl}
            userDetails={userDetails}
            stripePromise={stripePromise}
            open={subscriptionDialogOpen}
            onClose={this.handleCloseSubscriptionDialog}
            type="upgrade"
            currentSubscription={currentSubscription}
            trialing={false}
            disableCoupon={true}
            defaultCoupon=""
          />
        </div>
      </AppContentContainer>
    );
  }
}

export default ReferralView;
