import NativeOrWeb from '../../native';
import { versionString } from '../../../contexts/version';
import { getDeviceOS } from '../../device';

const featureMap = {
  Home: ['/', '/home']
};

export const enrichGlobalPropsPlugin = (props /** @type {Object([key:string]: any)} */) => {
  return {
    execute: async event => {
      const path = window.location.pathname;
      event.event_properties = {
        ...event.event_properties,
        'Page Path': path,
        'Page Search': window.location.search,
        Platform: NativeOrWeb.getPlatform(),
        DeviceOS: getDeviceOS(),
        Feature: Object.entries(featureMap).find(([k, v]) => v.includes(path))?.[0] ?? 'Undefined',
        AppVersion: versionString,
        Domain: window.location.hostname,
        ...props
      };
      return event;
    }
  };
};
