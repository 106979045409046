import React, { Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import IconToast from '../NewToast';
import { handleError } from '../../utils/openai';
import { getMeetingNoteSpeechExtractionPrompt } from '../../utils/aiPrompt';
import { openaiGenerateWithTextPolling } from '../../store/openai/api';
import RecordDialog from '../RecordDialog/RecordDialog';
import LoadingBackdrop from '../LoadingBackdrop/LoadingBackdrop';
import { speechToText } from '../../store/speech/api';
import { LANGUAGE } from '../../constants/language';
import { injectIntl } from 'react-intl';
import NativeOrWeb from '../../utils/native';
import { tracking, voiceRecordTrackingEvent, gptTrackingEvent } from '../../utils/tracking';
import { trackDirect } from '../../store/analytics/api';

const RecordMeetingRemarkButton = forwardRef((props, ref) => {
  const { intl, ButtonProps, IconProps, onResult } = props;

  const [initializing, setInitializing] = useState(false);
  const [recordingDialogOpen, setRecordingDialogOpen] = useState(false);
  const [recording, setRecording] = useState(false);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    openDialog: openDialog
  }));

  const openDialog = () => {
    setRecordingDialogOpen(true);
  };

  const startRecording = async () => {
    try {
      tracking(voiceRecordTrackingEvent, {
        feature: 'meeting_note',
        status: 'start'
      });
      trackDirect('convertVoiceToMeetingNote');
      tracking('AI: Convert Voice to Meeting Note');

      setRecording(true);
      setInitializing(true);
      await NativeOrWeb.startRecordingVoice();
    } catch (error) {
      console.log(error);
      tracking(voiceRecordTrackingEvent, {
        feature: 'meeting_note',
        status: 'error',
        ...error
      });
      toast.info(IconToast('error', intl.formatMessage({ id: 'error-msg' })), { className: 'new-toast' });
      setRecordingDialogOpen(false);
    } finally {
      setInitializing(false);
    }
  };

  const cancelRecording = () => {
    setRecordingDialogOpen(false);
    setRecording(false);
    if (recording) {
      NativeOrWeb.stopRecordingVoice()
        .then()
        .catch(console.log);
    }
    tracking(voiceRecordTrackingEvent, {
      feature: 'meeting_note',
      status: 'cancel'
    });
  };

  console.log(intl.locale);

  const stopRecording = async () => {
    try {
      setRecordingDialogOpen(false);
      setLoading(true);

      tracking(voiceRecordTrackingEvent, {
        feature: 'meeting_note',
        status: 'complete'
      });
      const recordingData = await NativeOrWeb.stopRecordingVoice();
      const { mimeType, msDuration, recordDataBase64 } = recordingData?.value || {};
      const speechToTextResult = await speechToText({ mimeType, msDuration, recordDataBase64 });
      // console.log({ speechToTextResult });

      const { text: speechText, language: detectedLanguage } = speechToTextResult?.result || {};

      let speechLanguage;

      switch (detectedLanguage) {
        case 'en-US':
          speechLanguage = LANGUAGE.EN;
          break;
        case 'zh-HK':
          speechLanguage = LANGUAGE.ZH_HANT_HK;
          break;
        case 'zh-CN':
          speechLanguage = LANGUAGE.ZH;
          break;
        default:
          speechLanguage = LANGUAGE.EN;
          break;
      }

      const systemPrompt = getMeetingNoteSpeechExtractionPrompt({
        language: intl.locale
      });

      const addedChatMessages = [
        {
          content: systemPrompt,
          role: 'system'
        },
        { content: `User's meeting record: \n ${speechText}`, role: 'user' }
      ];

      const response = await openaiGenerateWithTextPolling(
        {
          model: 'gpt-4o',
          messages: addedChatMessages,
          temperature: 0
        },
        {
          pollTimeout: 7000
        }
      );

      const { result, error } = response || {};

      if (error) {
        tracking(gptTrackingEvent, {
          feature: 'meeting_note',
          status: 'error',
          ...error
        });
        throw new Error(error);
      }

      tracking(gptTrackingEvent, {
        feature: 'meeting_note',
        status: 'success'
      });

      onResult(result);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
      setRecording(false);
    }
  };

  return (
    <Fragment>
      <RecordDialog
        title={intl.formatMessage({ id: 'ai-voice-to-notes' })}
        content={intl.formatMessage({ id: 'ai-voice-to-notes-content' })}
        timeLimit={180}
        open={recordingDialogOpen}
        initializing={initializing}
        recording={recording}
        onClose={cancelRecording}
        onStartRecording={startRecording}
        onDoneRecording={stopRecording}
      />
      <LoadingBackdrop open={loading} />
      <Button
        {...ButtonProps}
        color="primary"
        variant="outlined"
        style={{ padding: '0px 4px', fontSize: 12, marginLeft: 4, minWidth: 'unset' }}
        className="record-meeting-remark-button"
        onClick={openDialog}
      >
        <img
          style={{
            width: '14px',
            height: '14px',
            paddingRight: '2px'
          }}
          src="/img/aiGPT.svg"
        />
        {intl.formatMessage({ id: 'record-meeting-remark-button' })}
      </Button>
    </Fragment>
  );
});

export default injectIntl(RecordMeetingRemarkButton, { withRef: true });
