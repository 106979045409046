import React, { useState, useRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  LinearProgress,
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import moment from 'moment';

const RecordDialog = props => {
  const {
    intl,
    title,
    content,
    timeLimit,
    recording,
    initializing,
    open,
    onClose,
    onStartRecording,
    onDoneRecording,
    style,
    ...rest
  } = props;

  const [startTime, setStartTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(timeLimit);
  const recordingTimeoutRef = useRef(null);
  const intervalRef = useRef(null);

  const useStyles = makeStyles({
    progressBar: {
      height: 10,
      borderRadius: 5
    },
    barColor: {
      backgroundImage: 'repeating-linear-gradient(45deg, red, red 10px, orange 10px, orange 20px)'
    }
  });
  const classes = useStyles();

  useEffect(() => {
    if (recording && startTime) {
      intervalRef.current = setInterval(() => {
        const elapsedSeconds = Math.floor((moment().valueOf() - moment(startTime).valueOf()) / 1000);
        const timeLeft = timeLimit - elapsedSeconds;
        setRemainingTime(timeLeft > 0 ? timeLeft : 0);
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [recording, startTime, timeLimit]);

  const startRecording = async () => {
    const currentTime = moment().valueOf();
    setStartTime(currentTime);
    setRemainingTime(timeLimit);

    recordingTimeoutRef.current = setTimeout(() => {
      stopRecording();
    }, timeLimit * 1000);

    onStartRecording();
  };

  const stopRecording = async () => {
    setStartTime(null);
    setRemainingTime(timeLimit);

    if (recordingTimeoutRef.current) {
      clearTimeout(recordingTimeoutRef.current);
    }
    clearInterval(intervalRef.current);

    onDoneRecording();
  };

  function formatTime(seconds) {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;
    if (minutes <= 0) {
      return `${remainingSeconds} ${
        remainingSeconds > 1 ? intl.formatMessage({ id: 'seconds' }) : intl.formatMessage({ id: 'second' })
      }`;
    } else if (remainingSeconds <= 0) {
      return `${minutes} ${minutes > 1 ? intl.formatMessage({ id: 'minutes' }) : intl.formatMessage({ id: 'minute' })}`;
    } else {
      return `${minutes} ${minutes > 1 ? intl.formatMessage({ id: 'minutes' }) : intl.formatMessage({ id: 'minute' })} 
      ${remainingSeconds} ${
        remainingSeconds > 1 ? intl.formatMessage({ id: 'seconds' }) : intl.formatMessage({ id: 'second' })
      }`;
    }
  }

  return (
    <Dialog open={open} style={{ ...style }} {...rest}>
      <DialogTitle disableTypography={true}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <RecordVoiceOverIcon color="primary" />
          </Grid>
          <Grid item>
            <Typography color="primary">
              {recording
                ? initializing
                  ? intl.formatMessage({ id: 'recording-dialog-initializing' })
                  : intl.formatMessage({ id: 'recording-dialog-recording' })
                : title}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ whiteSpace: 'pre-wrap' }}>{content}</Typography>

        {recording && timeLimit && (
          <>
            <Box width="100%" mt={3} mb={1}>
              <LinearProgress
                variant="determinate"
                value={((timeLimit - remainingTime) * 100) / timeLimit}
                color={remainingTime / timeLimit < 0.1 ? 'secondary' : 'primary'}
                classes={{ root: classes.progressBar }}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography style={{ whiteSpace: 'nowrap' }}>
                {formatTime(timeLimit - remainingTime)} / {formatTime(timeLimit)}
              </Typography>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        {recording ? (
          <Button
            color="primary"
            variant="contained"
            disableElevation={true}
            disabled={initializing}
            onClick={stopRecording}
          >
            {intl.formatMessage({ id: 'recording-dialog-done' })}
          </Button>
        ) : (
          <Button color="primary" variant="contained" disableElevation={true} onClick={startRecording}>
            {intl.formatMessage({ id: 'recording-dialog-start-recording' })}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(RecordDialog);
