import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Button, IconButton, Typography, Checkbox, withWidth } from '@material-ui/core';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Capacitor } from '@capacitor/core';
import { mergeDialog, toggleDialog } from '../../../store/control/duck';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import MetControl from '../controls/MetControl/MetControl';
import OpportunityControl from '../controls/OpportunityControl/OpportunityControl';
import BusinessVolControl from '../controls/BusinessVolControl/BusinessVolControl';
import RemindDateControl from '../controls/RemindDateControl/RemindDateControl';
import ReminderActiveControl from '../controls/ReminderActiveControl/ReminderActiveControl';
import RemarkControl from '../controls/RemarkControl/RemarkControl';
import TagsControl from '../controls/TagsControl/TagsControl';
import { enableTracking, submitClient } from '../../../store/client/duck';
import LatestMeetingDateControl from '../controls/LatestMeetingDateControl/LatestMeetingDateControl';
import { isSubscriptionAllowAction } from '../../../utils/user-subscription-store';
import { getClientConnectionStatus, getClientEmail, goToFactsheet } from '../utils';
import LocationControl from '../controls/LocationControl/LocationControl';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import TodoList from '../../TodoList/TodoList';
import Template from '../../Template/Template';
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import { addAdviserTodo } from '../../../store/todoList/duck';
import './MeetingDialog.scss';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { DatePicker } from '@material-ui/pickers';
import NextMeetingDateControl from '../controls/NextMeetingDateControl/NextMeetingDateControl';
import BottomSheetOrDialog from '../../BottomSheetOrDialog/BottomSheetOrDialog';
import { LOADING, SUCCESS } from '../../../constants/phase';
import LoadingBackdrop from '../../LoadingBackdrop/LoadingBackdrop';
import { usePrevious, useWindowSize } from '../../../utils/hooks';
// import RecordMeetingRemarkButton from '../../RecordMeetingRemarkButton/RecordMeetingRemarkButton';
import { shouldEnableSpeechToText } from '../../../utils/user';
import { trackDirect } from '../../../store/analytics/api';
import { addEventToCalendarPrompt } from '../../../utils/native/calendar';
import { downloadApp } from '../../../utils/download';
import IconToast from '../../../components/NewToast';

const isSummaryMode = mode => mode === 'summary';

const INITIAL_STATE = {
  businessVolError: undefined,
  reminderDateError: undefined,
  width: window.innerWidth,
  todoOpen: false,
  templateOpen: false,
  loading: false
};

const MeetingDialog = props => {
  const {
    intl,
    history,
    userDetails,
    teamCampaignCodes,
    meetingDialog,
    createClientPhase,
    getClientByIdPhase,
    getUserPhase,
    enableTracking,
    submitClient,
    toggleDialog,
    mergeDialog,
    addAdviserTodo,
    userSubscription
  } = props;

  const {
    client,
    mode,
    met,
    opportunity,
    businessVol,
    fsReminderDate,
    fsReminderActive,
    latestMeetingDate,
    trackingStartDate,
    lastMeetingLocation,
    remark,
    tags,
    isRecruitment,
    nextMeetingDate,
    closed,
    triggerRecordDialog
  } = meetingDialog;

  const [businessVolError, setBusinessVolError] = useState(undefined);
  const [reminderDateError, setReminderDateError] = useState(undefined);
  const [todoOpen, setTodoOpen] = useState(false);
  const [templateOpen, setTemplateOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addToCalendar, setAddToCalendar] = useState(false);
  const [innerWidth, innerHeight] = useWindowSize();
  const prevCreateClientPhase = usePrevious(createClientPhase);
  const prevGetClientByIdPhase = usePrevious(getClientByIdPhase);
  const prevGetUserPhase = usePrevious(getUserPhase);
  const ref = useRef(null);
  const recordMeetingRemarkButtonRef = useRef(null);
  const [shouldTrackPotentialRecruitment, setShouldTrackPotentialRecruitment] = useState(false);

  const enableSpeechToText = shouldEnableSpeechToText({
    user: userDetails,
    teamCampaignCodes: teamCampaignCodes,
    userSubscription: userSubscription
  });

  useEffect(() => {
    if (createClientPhase === true) {
      if (
        (getClientByIdPhase === SUCCESS && prevGetClientByIdPhase === LOADING && getUserPhase !== LOADING) ||
        (getUserPhase === true && prevGetUserPhase === LOADING && getClientByIdPhase !== LOADING)
      ) {
        setTimeout(async () => {
          setLoading(false);
          mergeDialog('meeting', { closed: true });
          if (addToCalendar) {
            const result = await handleAddToCalendar();
            if (!result) {
              toast.error(IconToast('error', intl.formatMessage({ id: 'unable-to-add-meeting-notes-to-calendar' })), {
                className: 'new-toast',
                autoClose: 8000
              });
            }
            setAddToCalendar(false);
          }
        }, 300);
      }
    }
  }, [
    meetingDialog,
    createClientPhase,
    getClientByIdPhase,
    getUserPhase,
    prevGetClientByIdPhase,
    prevGetUserPhase,
    mergeDialog
  ]);

  useEffect(() => {
    if (triggerRecordDialog) {
      setTimeout(() => {
        const instance = recordMeetingRemarkButtonRef.current?._wrappedInstance;
        if (instance) {
          instance.openDialog();
          mergeDialog('meeting', { triggerRecordDialog: false });
        }
      }, 1100);
    }
  }, [triggerRecordDialog, mergeDialog]);

  const handleAddToCalendar = async () => {
    let result = false;
    if (Capacitor.isNativePlatform()) {
      result = await addEventToCalendarPrompt({
        title: intl.formatMessage({ id: 'meeting-with-client-name' }, { name: client.factsheetId.name }),
        startDate: moment
          .tz(nextMeetingDate, 'Asia/Hong_Kong')
          .startOf('day')
          .valueOf(),
        endDate: moment
          .tz(nextMeetingDate, 'Asia/Hong_Kong')
          .startOf('day')
          .add(1, 'day')
          .valueOf(),
        location: lastMeetingLocation,
        isAllDay: true,
        url: process.env.PUBLIC_URL,
        notes: `${remark ? `${remark}\n\n` : ''}${intl.formatMessage({ id: 'view-more-client-details-in-portfoplus' })}`
      });
    } else {
      toast.info(IconToast('info', intl.formatMessage({ id: 'download-portfoplus-app-to-use-this-feature' })), {
        className: 'new-toast',
        onClick: downloadApp
      });
      result = true;
    }
    return result;
  };

  const close = () => {
    if (isSummaryMode) {
      toggleDialog('discardWarning', {
        onConfirm: () => {
          toggleDialog('meeting', { ...meetingDialog, closed: true });
        }
      });
    } else {
      toggleDialog('meeting', { ...meetingDialog, closed: true });
    }
  };
  const onExited = () => {
    toggleDialog('meeting', false);
    setBusinessVolError(undefined);
    setReminderDateError(undefined);
    setTodoOpen(false);
    setTemplateOpen(false);
    setLoading(false);
  };

  const changeReminderDate = date => {
    setReminderDateError(undefined);
    toggleDialog('meeting', {
      ...meetingDialog,
      fsReminderDate: date,
      fsReminderActive: true
    });
  };

  const changeReminderActive = checked => {
    if (!isSubscriptionAllowAction({ type: 'core', nicknames: ['Pro', 'Ultra'] })) return;

    setReminderDateError(undefined);
    toggleDialog('meeting', {
      ...meetingDialog,
      fsReminderDate: checked ? fsReminderDate : null,
      fsReminderActive: checked
    });
  };

  const toggleIsRecruitment = value => {
    toggleDialog('meeting', { ...meetingDialog, isRecruitment: value });
    const ori = _.get(client, 'factsheetId.isRecruitment', false);
    if (!ori && value) {
      setShouldTrackPotentialRecruitment(true);
    } else {
      setShouldTrackPotentialRecruitment(false);
    }
  };

  const submit = async () => {
    if (!meetingDialog) return;

    const summaryMode = isSummaryMode(mode);

    if (summaryMode) {
      if (!businessVol) {
        setBusinessVolError(intl.formatMessage({ id: 'Required input field' }));
        return;
      }

      const obj = {};
      obj.businessVol = businessVol;
      obj.trackingId = client.trackingId._id;
      obj.met = met;
      obj.opportunity = opportunity;
      const formattedStartDate = trackingStartDate
        ? moment.utc(moment(trackingStartDate).format('YYYY-MM-DD')).format('YYYY-MM-DDT00:00:00.00Z')
        : '';
      obj.startDate = formattedStartDate;
      if (opportunity === 'H') {
        const date_high = moment().format('YYYY-MM-DD');
        const startDate1 = client.trackingId.startDate;
        obj.dateHigh = moment.utc(date_high).format('YYYY-MM-DDT00:00:00.00Z');
        obj.dayCount = moment().diff(moment(startDate1, 'YYYY-MM-DD'), 'days');
      }
      obj.clientId = client._id;
      enableTracking(obj);
      toggleDialog('meeting', { ...meetingDialog, closed: true });
    }

    if (!summaryMode) {
      if (fsReminderActive && !fsReminderDate) {
        setReminderDateError(intl.formatMessage({ id: 'Please set date' }));
        return;
      }

      const formData = {
        clientId: client._id,
        email: getClientEmail(client),
        advisorId: userDetails._id,
        tags: tags,
        remark: remark,
        isRecruitment
      };

      if (fsReminderActive) {
        formData.fsReminderActive = true;
        /* To set the local date*/
        formData.fsReminderDate = moment
          .utc(moment(fsReminderDate).format('YYYY-MM-DD'))
          .format('YYYY-MM-DDT00:00:00.00Z');
      } else {
        formData.fsReminderActive = false;
        formData.fsReminderDate = null;
      }

      if (latestMeetingDate) {
        /* To set the local date*/
        formData.latestMeetingDate = moment
          .utc(moment(latestMeetingDate).format('YYYY-MM-DD'))
          .format('YYYY-MM-DDT00:00:00.00Z');
      } else {
        formData.latestMeetingDate = null;
      }

      if (nextMeetingDate) {
        formData.nextMeetingDate = moment
          .utc(moment(nextMeetingDate).format('YYYY-MM-DD'))
          .format('YYYY-MM-DDT00:00:00.00Z');
      } else {
        formData.nextMeetingDate = null;
      }

      formData.lastMeetingLocation = lastMeetingLocation;

      const old_data = client.factsheetId.tags ? client.factsheetId.tags : [];
      const new_data = tags ? tags : [];
      formData.usedTags = {
        old_data: old_data,
        new_data: new_data
      };

      setLoading(true);
      submitClient(formData);
      trackDirect('editMeetingNote');
      if (shouldTrackPotentialRecruitment) {
        const trackAddedPotentialRecruitment = require('../../../utils/tracking/index').trackAddedPotentialRecruitment;
        trackAddedPotentialRecruitment({
          'Facesheet ID': _.get(client, 'factsheetId._id', false),
          Source: _.get(client, 'factsheetId.source', false),
          'Create Date': _.get(client, 'factsheetId.createdate', false),
          'Latest Meeting Date': formData.latestMeetingDate
        });
      }
    }
  };

  const factsheet = () => {
    const client = { ..._.get(meetingDialog, 'client') };
    setAddToCalendar(false);
    submit();
    goToFactsheet(client, history);
  };

  const closeCase = () => {
    const client = { ..._.get(meetingDialog, 'client') };
    const connectionStatus = getClientConnectionStatus(client);

    if (connectionStatus === 'Disconnected') {
      return;
    }

    if (!client.trackingId || !client.trackingId.isActive) {
      toggleDialog('trackOn', { client: client, startDate: moment(), expectedBusinessAmount: '' });
    } else {
      toggleDialog('trackOff', {
        client: client,
        businessVol: _.get(client, 'trackingId.businessVol', null),
        met: _.get(client, 'trackingId.met', 0)
      });
    }
    toggleDialog('meeting', { ...meetingDialog, closed: true });
  };

  const openTodo = () => setTodoOpen(true);
  const closeTodo = () => setTodoOpen(false);
  const openTemplate = () => {
    setTodoOpen(false);
    setTemplateOpen(true);
  };
  const closeTemplate = () => setTemplateOpen(false);

  const addToTodo = () => {
    setTodoOpen(true);
    setTimeout(() => {
      if (fsReminderDate && fsReminderActive) {
        addAdviserTodo({
          todo: {
            todo: client.factsheetId.name,
            important: false,
            dueDate: fsReminderDate,
            completed: false
          }
        });
      }
    }, 200);
  };

  // const appendRemark = text => {
  //   toggleDialog('meeting', {
  //     ...meetingDialog,
  //     remark: `${remark}${remark ? `\n${!(remark || '').endsWith('\n') ? '\n' : ''}` : ''}${text}`
  //   });
  // };

  if (!meetingDialog) return null;

  const summaryMode = isSummaryMode(mode);
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const header = intl.formatMessage({ id: summaryMode ? 'Follow Up' : 'meetingNotes' });

  const actionsPrepend = [
    !summaryMode ? (
      <Button onClick={factsheet} color="primary">
        {intl.formatMessage({ id: 'Factsheet' })}
      </Button>
    ) : (
      <Button variant="outlined" onClick={closeCase} color="primary">
        {intl.formatMessage({ id: 'Case Closing' })}
      </Button>
    )
  ];

  const actions = [
    nextMeetingDate && (
      <Button
        onClick={() => {
          setAddToCalendar(true);
          submit();
        }}
        color="primary"
        autoFocus
      >
        {intl.formatMessage({ id: 'save-and-add-to-calendar' })}
      </Button>
    ),
    <Button
      onClick={() => {
        setAddToCalendar(false);
        submit();
      }}
      color="primary"
      autoFocus
    >
      {intl.formatMessage({ id: 'Save' })}
    </Button>
  ].filter(Boolean);

  const content = (
    <div
      className="form-group"
      style={{ marginBottom: 0, height: '100%', display: 'flex', flexDirection: 'column' }}
      ref={ref}
    >
      {summaryMode && (
        <Fragment>
          <div className="case-close-met">
            <div className="col-md-6 col-xs-6 text-left caseclosing_padding">
              {intl.formatMessage({ id: 'No. of Meeting' })}
            </div>
            <MetControl
              className="col-md-6 col-xs-6"
              style={{ paddingLeft: '22px' }}
              met={met}
              onChange={met => toggleDialog('meeting', { ...meetingDialog, met: met })}
            />
          </div>
          &nbsp;
          <div className="row">
            <div className="col-md-6 col-xs-6 text-left fontWeight--none">
              {intl.formatMessage({ id: 'Opportunity' })}
            </div>
            <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
              <div className="clientList">
                <OpportunityControl
                  opportunity={opportunity}
                  onChange={opp =>
                    toggleDialog('meeting', {
                      ...meetingDialog,
                      opportunity: opp
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-xs-6 text-left fontWeight--none padding__left-0">
              {intl.formatMessage({ id: 'Expected Business' })}
            </div>
            <div className="col-md-6 col-xs-6">
              <BusinessVolControl
                style={{
                  borderBottom: '1px solid gray',
                  height: '25px',
                  lineHeight: 'normal',
                  color: '#3966f8'
                }}
                value={businessVol}
                onChange={value => {
                  setBusinessVolError(undefined);
                  toggleDialog('meeting', { ...meetingDialog, businessVol: value });
                }}
              />
              {businessVolError && <span className="error_field">{businessVolError}</span>}
            </div>
          </div>
        </Fragment>
      )}
      {summaryMode && (
        <>
          <div className="row datepicker-row">
            <div className="col-md-6 col-xs-6 text-left fontWeight--none">
              {intl.formatMessage({ id: 'Tracking start on' })}
            </div>
            <div className="col-md-6 col-xs-6 text-left">
              <DatePicker
                className="case-datepicker close-case-date case-open-datepicker tracking-start-datepicker"
                maxDate={new Date()}
                name="startDate"
                format="DD-MMM-YYYY"
                value={trackingStartDate}
                onChange={date =>
                  toggleDialog('meeting', {
                    ...meetingDialog,
                    trackingStartDate: date
                  })
                }
                inputProps={{ style: { fontSize: '14px' } }}
                style={{ width: '100%' }}
                autoOk={true}
                invalidDateMessage={null}
                minDateMessage={null}
                maxDateMessage={null}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-xs-6 text-left fontWeight--none">
              {intl.formatMessage({ id: 'day-count' })}
            </div>
            <div className="col-md-6 col-xs-6 day-count">
              <span>{`${
                trackingStartDate
                  ? moment(moment(), 'YYYY-MM-DD').diff(
                      moment.parseZone(trackingStartDate).format('YYYY-MM-DD'),
                      'days'
                    )
                  : 0
              } ${intl.formatMessage({ id: 'Days' })}`}</span>
            </div>
          </div>
        </>
      )}
      {!summaryMode && (
        <div className="row">
          <div className="col-md-6 col-xs-6">
            <div className="form-group">
              <label className="control-label" htmlFor="input">
                {intl.formatMessage({ id: 'Latest Meeting' })}
              </label>
            </div>
          </div>
          <div className="col-md-6 col-xs-6 padding_left_zero">
            <div className="form-group datepicker-group left-side">
              <LatestMeetingDateControl
                className="date-picker"
                latestMeetingDate={latestMeetingDate}
                onChange={date =>
                  toggleDialog('meeting', {
                    ...meetingDialog,
                    latestMeetingDate: date
                  })
                }
              />
            </div>
          </div>
        </div>
      )}
      {!summaryMode && (
        <div className="row">
          <div className="col-md-6 col-xs-6">
            <div className="form-group">
              <label className="control-label" htmlFor="input">
                {intl.formatMessage({ id: 'next-meeting-date' })}
              </label>
            </div>
          </div>
          <div className="col-md-6 col-xs-6 padding_left_zero">
            <div className="form-group datepicker-group left-side">
              <NextMeetingDateControl
                className="date-picker"
                nextMeetingDate={nextMeetingDate}
                onChange={date =>
                  toggleDialog('meeting', {
                    ...meetingDialog,
                    nextMeetingDate: date
                  })
                }
                client={client}
              />
            </div>
          </div>
        </div>
      )}
      {!summaryMode && (
        <>
          <div className="row location">
            <div className="col-xs-6">
              <div className="form-group">
                <label className="control-label" htmlFor="input">
                  {intl.formatMessage({ id: 'location' })}
                </label>
              </div>
            </div>
            <div className="col-xs-6 padding_left_zero">
              <div className="left-side">
                <LocationControl
                  multiline={false}
                  value={lastMeetingLocation}
                  onChange={event =>
                    toggleDialog('meeting', {
                      ...meetingDialog,
                      lastMeetingLocation: event.target.value
                    })
                  }
                />
              </div>
            </div>
          </div>
          <RemarkControl
            data-body-scroll-lock-ignore="true"
            remark={remark}
            onChange={event =>
              toggleDialog('meeting', {
                ...meetingDialog,
                remark: event.target.value
              })
            }
            meetingDialog={meetingDialog}
            recordMeetingRemarkButtonRef={recordMeetingRemarkButtonRef}
            enableSpeechToText={enableSpeechToText}
            InputProps={{
              style: {
                height: '100%'
              }
            }}
            setLoading={setLoading}
            fullHeight={true}
            style={{ marginTop: -16, marginBottom: 16, flexGrow: 1 }}
          />
          <div className="row">
            <div className={`col-md-6 col-xs-6`}>
              {
                <>
                  <div className="form-group">
                    <label className="control-label" htmlFor="input" style={{ position: 'relative !important' }}>
                      {intl.formatMessage({ id: 'Remind me' })}
                    </label>
                  </div>
                  <Button
                    className="reminder-date-add-todo-btn"
                    style={{
                      position: 'absolute',
                      marginLeft: 85,
                      top: 8,
                      padding: '0 16px',
                      borderColor: fsReminderDate && fsReminderActive ? '#3966f8' : 'grey',
                      color: fsReminderDate && fsReminderActive ? '#3966f8' : 'grey'
                    }}
                    variant="outlined"
                    disabled={!fsReminderDate || !fsReminderActive}
                    onClick={addToTodo}
                  >
                    {intl.formatMessage({ id: 'Add to Todo' })}
                  </Button>
                  <IconButton
                    className="reminder-date-add-todo-icon-btn"
                    disabled={!fsReminderDate || !fsReminderActive}
                    style={{
                      position: 'absolute',
                      marginLeft: 70,
                      top: -3,
                      color: fsReminderDate && fsReminderActive ? '#3966f8' : 'grey'
                    }}
                    onClick={addToTodo}
                  >
                    <DoneOutlineRoundedIcon />
                  </IconButton>
                </>
              }
            </div>
            <div className={`col-md-6 col-xs-6 padding_left_zero`}>
              <div className={`form-group datepicker-group left-side`}>
                <Grid container direction="column">
                  <Grid item>
                    <RemindDateControl
                      className="date-picker"
                      fsReminderDate={fsReminderDate}
                      onChange={changeReminderDate}
                      minDate={moment()}
                      disabled={!fsReminderActive}
                    />
                    <ReminderActiveControl
                      className="reminder-checkbox"
                      fsReminderActive={fsReminderActive}
                      onChange={changeReminderActive}
                    />
                  </Grid>
                  {reminderDateError && (
                    <Grid item>
                      <span className="error_field" style={{ fontSize: '13px' }}>
                        {intl.formatMessage({ id: reminderDateError })}
                      </span>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          </div>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography style={{ color: '#333', fontSize: '14px' }}>
                {intl.formatMessage({ id: 'recruit-lead' })}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'center' }}>
              <Checkbox
                name="isRecruitment"
                value="isRecruitment"
                checked={isRecruitment ?? false}
                onChange={event => toggleIsRecruitment(event.target.checked)}
                color="primary"
              />
            </Grid>
          </Grid>
          &nbsp;
          <div className="form-group" style={{ marginBottom: 0 }}>
            <TagsControl
              tags={tags}
              onChange={tags =>
                toggleDialog('meeting', {
                  ...meetingDialog,
                  tags: tags
                })
              }
              addTagButton={true}
            />
            <label className="control-label" htmlFor="input" style={{ top: '0px' }}>
              {intl.formatMessage({ id: 'Tag' })}
            </label>
            <i className="bar" />
          </div>
        </>
      )}
    </div>
  );

  return (
    <Fragment>
      <LoadingBackdrop open={loading} />
      <BottomSheetOrDialog
        className="meeting-dialog"
        open={!!meetingDialog && !closed}
        onClose={close}
        onExited={onExited}
        header={header}
        headerCTAs={[]}
        content={content}
        actions={actions}
        actionsPrepend={actionsPrepend}
        BottomSheetProps={{
          snapPoints: ({ minHeight, maxHeight }) => (!summaryMode ? maxHeight * 0.9 : minHeight),
          defaultSnap: ({ lastSnap, snapPoints }) => lastSnap ?? Math.max(...snapPoints),
          expandOnContentDrag: false,
          disableAutoDismiss: true,
          BoxProps: {
            height: !summaryMode ? `calc(${innerHeight * 0.9}px - 54px - 65px - var(--top-padding))` : undefined
          }
        }}
        DialogProps={{
          fullWidth: true,
          PaperProps: {
            style: !summaryMode
              ? {
                  height: '100%'
                }
              : undefined
          }
        }}
      />
      <SwipeableDrawer
        anchor="right"
        PaperProps={{ elevation: 10, style: { zIndex: 1300 } }}
        variant={innerWidth > 800 ? 'persistent' : 'temporary'}
        onClose={closeTodo}
        onOpen={openTodo}
        open={todoOpen}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        disableSwipeToOpen={true}
        className="client-page-filter-SwipeableDrawer"
      >
        <TodoList close={closeTodo} openTemplate={openTemplate} />
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        PaperProps={{ elevation: 10, style: { zIndex: 1300 } }}
        variant={innerWidth > 800 ? 'persistent' : 'temporary'}
        onClose={closeTemplate}
        onOpen={openTemplate}
        open={templateOpen}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        disableSwipeToOpen={true}
        className="client-page-filter-SwipeableDrawer"
      >
        <Template close={closeTemplate} />
      </SwipeableDrawer>
    </Fragment>
  );
};

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    subscriptions: state.userSubscription.subscriptions,
    meetingDialog: state.control.meetingDialog,
    createClientPhase: state.client.createClientPhase,
    getClientByIdPhase: state.client.getClientByIdPhase,
    getUserPhase: state.client.getUserPhase,
    teamCampaignCodes: state.functions.campaignCodes,
    userSubscription: state.userSubscription.userSubscription
  }), // Map actions to props
  {
    toggleDialog,
    mergeDialog,
    enableTracking,
    submitClient,
    addAdviserTodo
  }, // mergeProps
  null, // options
  {}
)(withRouter(MeetingDialog));

export default injectIntl(withWidth()(container));
