import React, { useEffect, forwardRef, useImperativeHandle, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  TextField,
  Typography,
  Checkbox,
  Backdrop
} from '@material-ui/core';
import SelectButtonGroup, { SelectButton } from '../../../../components/SelectButtonGroup/SelectButtonGroup';
import { MultipleChoicesSubTitleStyle } from '../MyDetails';
import {
  MAIN_PRODUCTS_CHOICES,
  MAIN_SERVICES_CHOICES,
  MDRT_CHOICES,
  SENIORITY_CHOICES
} from '../../../../constants/my-details';
import { getIsMDRTIntl, getMainProductIntl, getMainServiceIntl, getSeniorityIntl } from '../../../../utils/my-details';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';
import { sanitize } from 'dompurify';
import { formatTwoRowsString } from '../../../../utils/user';
import { toast } from 'react-toastify';
import { AddRounded } from '@material-ui/icons';

const MarginBottomStyle = {
  marginBottom: 16
};

const MyDetailsBusiness = (props, ref) => {
  const { intl, details, error, onChangeDetails, onPopupTextField } = props;
  const {
    company,
    jobTitle,
    seniority,
    license,
    shouldShowLicense,
    mainProductsServices,
    isMDRT,
    otherAwards,
    jobExperience,
    customizedProductsOrServices
  } = details || {};

  useImperativeHandle(ref, () => {
    return {
      onHandleCustomizedProductsServicesComplete: onHandleCustomizedProductsServicesComplete
    };
  });

  const createSimpleOnChangeHandler = (key, emptyValue, formatter = val => val) => event =>
    onChangeDetails({ [key]: formatter(event.target.value || emptyValue) });
  const onChangeCompany = createSimpleOnChangeHandler('company', '', formatTwoRowsString);
  const onChangeJobTitle = createSimpleOnChangeHandler('jobTitle', '', formatTwoRowsString);
  const onChangeSeniority = createSimpleOnChangeHandler('seniority', null);
  const OnChangeLicense = createSimpleOnChangeHandler('license', '');
  const onChangeMainProductsServices = createSimpleOnChangeHandler('mainProductsServices', []);
  const onChangeIsMDRT = createSimpleOnChangeHandler('isMDRT', null);
  const onChangeOtherAwards = createSimpleOnChangeHandler('otherAwards', '');
  const onChangeJobExperience = createSimpleOnChangeHandler('jobExperience', '', formatTwoRowsString);
  const onChangeshouldShowLicense = event => {
    onChangeDetails({ shouldShowLicense: !!event.target.checked });
  };
  const onCustomizedProductsServices = createSimpleOnChangeHandler('customizedProductsOrServices', '');

  function getCustomizedProductsOrServicesArray() {
    if (!customizedProductsOrServices || customizedProductsOrServices.length === 0) return [];
    return customizedProductsOrServices
      .split('#')
      .map(e => e.trim())
      .filter(e => e != '');
  }

  const customizedProductsOrServicesTags = getCustomizedProductsOrServicesArray();
  const productOrServiceTagsTotalCount = mainProductsServices.length + customizedProductsOrServicesTags.length;

  const productOrServiceTagsTotalCountCheck = () => {
    if (productOrServiceTagsTotalCount >= 6) {
      toast.error(intl.formatMessage({ id: 'mda-mc-max-reached-hint' }, { count: 6 }));
      return false;
    }
    return true;
  };

  const onHandleCustomizedProductsServicesComplete = tag => {
    if (tag && tag !== '') {
      const nextTags = `${customizedProductsOrServices || ''}#${tag}`;
      onCustomizedProductsServices({ target: { value: nextTags } });
    }
  };

  const onHandleRemoveCustomizedProductsServices = tag => {
    if (tag && tag !== '') {
      const copy = customizedProductsOrServices;
      var nextTags = copy.replace(`#${tag}`, '');
      if (copy.includes(tag)) {
        nextTags = nextTags.replace(tag, '');
      }
      onCustomizedProductsServices({ target: { value: nextTags.trim() } });
    }
  };

  const onHandleProductsServiceChange = v => {
    if (mainProductsServices.includes(v)) {
      const nextTags = mainProductsServices.filter(tag => tag !== v);
      onChangeMainProductsServices({ target: { value: nextTags } });
    } else {
      productOrServiceTagsTotalCountCheck() &&
        onChangeMainProductsServices({ target: { value: [...mainProductsServices, v] } });
    }
  };

  if (!license || license.replaceAll('\n', '').replaceAll(' ', '') === '') {
    onChangeDetails({
      license: `${intl.formatMessage({ id: 'individual_insurance_agent_license' })}: \n${intl.formatMessage({
        id: 'mpf_intermediary-reg-no'
      })}: `
    });
  }

  return (
    <Fragment>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <AladdinHint title={intl.formatMessage({ id: 'mda-business-title' })} />
        </Grid>
        <Grid item>
          <TextField
            label={intl.formatMessage({ id: 'Company' })}
            value={company}
            onChange={onChangeCompany}
            multiline={true}
            rows={2}
          />
        </Grid>
        <Grid item>
          <TextField
            label={intl.formatMessage({ id: 'job-title' })}
            value={jobTitle}
            onChange={onChangeJobTitle}
            multiline={true}
            rows={2}
          />
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="flex-start" spacing={1}>
            <Grid item>
              <Typography className="field-label" color="textSecondary">
                {intl.formatMessage({ id: 'seniority' })}
              </Typography>
            </Grid>
            <Grid item>
              <SelectButtonGroup
                choices={SENIORITY_CHOICES.map(seniority => ({
                  value: seniority,
                  label: intl.formatMessage({ id: getSeniorityIntl(seniority) })
                }))}
                value={seniority}
                onChange={onChangeSeniority}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography color="primary">{intl.formatMessage({ id: 'license' })}</Typography>
            <Checkbox
              id="shouldShowLicense"
              checked={!!shouldShowLicense}
              onChange={onChangeshouldShowLicense}
              style={{ padding: 4 }}
            />
          </div>

          <TextField value={license} onChange={OnChangeLicense} multiline={true} />
        </Grid>

        <Grid item style={MarginBottomStyle}>
          <ExpansionPanel className="mc-expansion-panel" expanded={true}>
            <ExpansionPanelSummary>
              <Typography
                className="field-label"
                color={!!error.mainProductsServices ? 'error' : undefined}
              >{`${intl.formatMessage({
                id: 'main-products-services'
              })}*`}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container direction="column" alignItems="flex-start" spacing={1}>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'mda-mc-6-hint' })) }}
                  />
                </Grid>
                <Grid item style={MultipleChoicesSubTitleStyle}>
                  <Typography color="primary">{intl.formatMessage({ id: 'mda-main-products' })}</Typography>
                </Grid>
                <Grid item style={MarginBottomStyle}>
                  <SelectButtonGroup
                    multi={true}
                    max={6}
                    choices={MAIN_PRODUCTS_CHOICES.map(mainProduct => ({
                      value: mainProduct,
                      label: intl.formatMessage({ id: getMainProductIntl(mainProduct) }),
                      handler: () => onHandleProductsServiceChange(mainProduct)
                    }))}
                    value={mainProductsServices}
                  />
                </Grid>
                <Grid item style={MultipleChoicesSubTitleStyle}>
                  <Typography color="primary">{intl.formatMessage({ id: 'mda-main-services' })}</Typography>
                </Grid>
                <Grid item style={MarginBottomStyle}>
                  <SelectButtonGroup
                    multi={true}
                    max={6}
                    choices={MAIN_SERVICES_CHOICES.map(mainService => ({
                      value: mainService,
                      label: intl.formatMessage({ id: getMainServiceIntl(mainService) }),
                      handler: () => onHandleProductsServiceChange(mainService)
                    }))}
                    value={mainProductsServices}
                  />
                </Grid>

                <Grid item style={MultipleChoicesSubTitleStyle}>
                  <Typography color="primary">
                    {intl.formatMessage({ id: 'customized-products-or-services-title' })}
                  </Typography>
                </Grid>
                <Grid item style={MarginBottomStyle}>
                  <SelectButtonGroup
                    multi={true}
                    max={6}
                    value={customizedProductsOrServicesTags}
                    choices={[
                      ...customizedProductsOrServicesTags.map(s => ({
                        value: s,
                        label: s,
                        subfix: true,
                        handler: () => onHandleRemoveCustomizedProductsServices(s)
                      })),
                      {
                        value: 'mda-add-custom-tag',
                        label: intl.formatMessage({ id: 'mda-add-custom-tag' }),
                        handler: () => {
                          productOrServiceTagsTotalCountCheck() && onPopupTextField();
                        },
                        style: {
                          color: '#3966f8'
                        },
                        prefix: <AddRounded style={{ width: 12, height: 12 }} />
                      }
                    ]}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <Grid></Grid>
        </Grid>

        <Grid item>
          <Grid container direction="column" alignItems="flex-start" spacing={1}>
            <Grid item>
              <Typography className="field-label">{intl.formatMessage({ id: 'mdrt-title' })}</Typography>
            </Grid>
            <Grid item>
              <SelectButtonGroup
                multi={true}
                choices={MDRT_CHOICES.map(MDRTType => ({
                  value: MDRTType,
                  label: intl.formatMessage({ id: getIsMDRTIntl(MDRTType) })
                }))}
                value={isMDRT}
                onChange={onChangeIsMDRT}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <TextField
            label={intl.formatMessage({ id: 'is-mdrt' })}
            value={otherAwards}
            multiline={true}
            rows={5}
            onChange={onChangeOtherAwards}
            InputLabelProps={{
              'data-placeholder': !otherAwards ? intl.formatMessage({ id: 'mda-other-awards-hint' }) : undefined
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            label={intl.formatMessage({ id: 'job-experience' })}
            value={jobExperience || ''}
            onChange={onChangeJobExperience}
            InputLabelProps={{ shrink: true }}
            placeholder={intl.formatMessage({ id: 'mda-job-experience-hint' })}
            multiline={true}
            rows={2}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default forwardRef(MyDetailsBusiness);
