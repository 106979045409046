import React, { useEffect } from "react";
import { getReferralProfile } from "./utils/referralProfile";
import { buildSearchString, getSearchObject } from "../../../utils/router";
import { messageListener } from "./utils/messageListener";
import SubscriptionDialog from "../../../views/Subscription/SubscriptionDialog/SubscriptionDialog";
import { connect } from "react-redux";
import { UserSubscription } from "../../../utils/user-subscription";
import { injectIntl } from "react-intl";

const LinkerPublicProfileView = props => {
  const {
    userSubscription,
    stripePromise,
    showPadding,
    history,
    userDetails,
    intl
  } = props;
  const linkerUrl = process.env.LINKER_URL;
  const path = `adviser/${props.match.params.userId}`;
  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = React.useState(
    false
  );

  const currentCoreSubscription = new UserSubscription(
    userSubscription
  ).getCurrentCoreSubscription();

  const s = buildSearchString([
    getReferralProfile({}),
    getSearchObject(history)
  ]);
  const nextLink = `${linkerUrl}/${path}?${s}`;

  const handleSubscriptionOpen = () => {
    setSubscriptionDialogOpen(true);
  };
  const handleSubscriptionClose = () => {
    setSubscriptionDialogOpen(false);
  };

  useEffect(() => {
    return messageListener(history, undefined, {
      SHOW_UPGRADE_MODAL: () => handleSubscriptionOpen()
    }, userDetails);
  }, []);
  return (
    <>
      <SubscriptionDialog
        intl={intl}
        userDetails={userDetails}
        stripePromise={stripePromise}
        open={subscriptionDialogOpen}
        onClose={handleSubscriptionClose}
        type="upgrade"
        currentSubscription={currentCoreSubscription}
        trialing={false}
        disableCoupon={true}
        defaultCoupon=""
      />
      <iframe
        src={nextLink}
        frameBorder="0"
        style={{
          overflow: "hidden",
          width: "100%",
          height: "100%",
          border: "none",
          display: "block"
        }}
        scrolling="no"
        allow="clipboard-read; clipboard-write"
      />
    </>
  );
};

const container = connect(state => ({
  userDetails: state.user.userDetails,
  userSubscription: state.userSubscription.userSubscription
}))(LinkerPublicProfileView);

export default injectIntl(container);
