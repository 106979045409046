import React from 'react';
import { Button, Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

const Select = props => {
    const {
        intl,
        loading,
        onClose,
        currentSubscription,
        setStep,
        setChangeablePlanNickname,
        changeablePlans,
        couponId,
        setSelectedPlan,
        setOption,
        applyCoupon
    } = props;

    const useStyles = makeStyles({
        table: {
            '& .MuiTableCell-root': {
                borderBottom: '1px solid lightgrey'
            },
            '& .MuiTableHead-root .MuiTableCell-root': {
                fontSize: '14px',
                paddingLeft: 8,
                paddingRight: 8
            },
            '& .MuiTableBody-root .MuiTableCell-root': {
                fontSize: '14px',
                padding: '8px 4px'
            },
            '& .MuiTableHead-root .MuiTableRow-root:first-child .MuiTableCell-root': {
                borderBottom: 'none'
            },
            '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
                borderBottom: 'none'
            }
        },
        productName: {
            fontSize: 15,
            marginBottom: 5
        },
        sameLine: {
            display: "flex",
            flexWrap: "nowrap",
            whiteSpace: "nowrap"
        },
        paymentFrequency: {
            fontSize: 12,
            color: "grey"
        }
    });

    const classes = useStyles();

    function proceedToChange(nickname) {
        let filteredChangeablePlans = changeablePlans.filter(plan => plan.nickname === nickname);
        if (filteredChangeablePlans.length === 1) {
            setSelectedPlan(filteredChangeablePlans[0]);
            if (filteredChangeablePlans[0].options.length === 1) {
                setOption(filteredChangeablePlans[0].options[0]);
            } else {
                setOption("");
            }
            if (couponId) {
                applyCoupon(couponId, filteredChangeablePlans[0]._id);
            }
        }
        setChangeablePlanNickname(nickname);
        setStep(2);
    }

    function createData(feature, linker, linkerPro, portfoplus) {
        return { feature, linker, linkerPro, portfoplus };
    }

    const rows = [
        createData(intl.formatMessage({ id: 'linker-online-card' }), <CheckIcon />, <CheckIcon />, <CheckIcon />),
        createData(intl.formatMessage({ id: 'linker-physical-card' }), <CheckIcon />, <CheckIcon />, <CheckIcon />),
        createData(intl.formatMessage({ id: 'one-click-whatsapp' }), "", <CheckIcon />, <CheckIcon />),
        createData(intl.formatMessage({ id: 'one-click-wechat' }), "", <CheckIcon />, <CheckIcon />),
        createData(intl.formatMessage({ id: 'add-card-to-ewallet' }), "", <CheckIcon />, <CheckIcon />),
        createData(intl.formatMessage({ id: 'client-questionnaire' }), "", "", <CheckIcon />),
        createData(intl.formatMessage({ id: 'insurance-gap-analysis' }), "", "", <CheckIcon />),
        createData(intl.formatMessage({ id: 'ten-seconds-closing-tool' }), "", "", <CheckIcon />),
        createData(intl.formatMessage({ id: 'social-media-post-generation' }), "", "", <CheckIcon />),
        createData(intl.formatMessage({ id: 'exclusive-premium-financing-calculator' }), "", "", <CheckIcon />),
        createData(intl.formatMessage({ id: 'referral-management-system' }), "", "", <CheckIcon />),
        createData(intl.formatMessage({ id: 'ai-record-meeting-summary' }), "", "", <CheckIcon />),
        createData(intl.formatMessage({ id: 'unlimited-insurance-sales-online-classes' }), "", "", <CheckIcon />),
    ];

    return (
        <Box style={{ textAlign: "center" }}>
            {loading && <CircularProgress size={36} />}
            {!loading && changeablePlans &&
                <>
                    <TableContainer>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <Box style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                            <Box className={`${classes.sameLine} ${classes.productName}`}>Linker</Box>
                                            <Box className={classes.sameLine}>$0</Box>
                                            <Box className={`${classes.sameLine} ${classes.paymentFrequency}`}>{intl.formatMessage({ id: 'free-to-use' })}</Box>
                                            <Button
                                                disabled={true}
                                                className={classes.sameLine}
                                                style={{ marginTop: 4, background: "#f0f0f0" }}
                                            >
                                                {currentSubscription.subscriptionPlan.nickname === "LinkerPro" ?
                                                    intl.formatMessage({ id: 'included' })
                                                    :
                                                    intl.formatMessage({ id: 'current' })
                                                }
                                            </Button>
                                        </Box>
                                    </TableCell>

                                    <TableCell>
                                        <Box style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                            <Box className={`${classes.sameLine} ${classes.productName}`}>Linker Pro</Box>
                                            <Box className={classes.sameLine}>$32 <span style={{ color: "grey" }}>/{intl.formatMessage({ id: 'month-lowercase' })}</span></Box>
                                            <Box className={`${classes.sameLine} ${classes.paymentFrequency}`}>{intl.formatMessage({ id: 'annual-plan' })}</Box>
                                            {currentSubscription.subscriptionPlan.nickname === "LinkerPro" ?
                                                <Button
                                                    disabled={true}
                                                    className={classes.sameLine}
                                                    style={{ marginTop: 4, background: "#f0f0f0" }}
                                                >
                                                    {intl.formatMessage({ id: 'current' })}
                                                </Button>
                                                :
                                                <Button
                                                    disabled={loading}
                                                    onClick={() => proceedToChange("LinkerPro")}
                                                    className={classes.sameLine}
                                                    style={{ marginTop: 4, background: "#f0f0f0" }}
                                                >
                                                    {intl.formatMessage({ id: 'upgrade' })}
                                                </Button>
                                            }
                                        </Box>
                                    </TableCell>

                                    <TableCell>
                                        <Box style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                            <Box className={`${classes.sameLine} ${classes.productName}`}>PortfoPlus</Box>
                                            <Box className={classes.sameLine}>$399 <span style={{ color: "grey" }}>/{intl.formatMessage({ id: 'month-lowercase' })}</span></Box>
                                            <Box className={`${classes.sameLine} ${classes.paymentFrequency}`}>{intl.formatMessage({ id: 'annual-plan' })}</Box>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                                onClick={() => proceedToChange("Ultra")}
                                                className={classes.sameLine}
                                                style={{ marginTop: 4 }}
                                            >
                                                {intl.formatMessage({ id: 'upgrade' })}
                                            </Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.feature}>
                                        <TableCell> {row.feature} </TableCell>
                                        <TableCell align='center'>{row.linker}</TableCell>
                                        <TableCell align='center'>{row.linkerPro}</TableCell>
                                        <TableCell align='center'>{row.portfoplus}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Button
                        variant="contained"
                        color='primary'
                        fullWidth={true}
                        onClick={() => window.open("https://api.whatsapp.com/send?phone=85266370320&text=%E4%BD%A0%E5%A5%BD%EF%BC%81%E6%88%91%E6%83%B3%E5%8D%87%E7%B4%9A", '_blank')}
                        style={{ height: 42, fontSize: 15, borderRadius: 8 }}
                    >
                        {intl.formatMessage({ id: 'contact-us-for-more-information' })}
                    </Button>

                    <Button
                        variant="text"
                        fullWidth={true}
                        disabled={loading}
                        onClick={onClose}
                        style={{ color: "grey", marginTop: 10, fontSize: 15 }}
                    >
                        {intl.formatMessage({ id: 'maybe-later' })}
                    </Button>
                </>
            }
        </Box>
    );
};

export default Select;
