import { fetch } from '../../utils';
import Backend from '../../backend';

export async function getMyActiveAppointment() {
  return fetch(Backend.methodUrl("appointment"))
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export async function cancelAppointment(id) {
  return fetch(Backend.methodUrl(`/appointment/${id}/cancel`), { method: 'POST' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
