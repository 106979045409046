import React from 'react';
import { Button, Typography, CircularProgress } from '@material-ui/core';

const Complete = props => {
    const {
        intl,
        type,
        currentCredits,
        loading,
        option,
        couponValue,
        selectedPlan,
        newCredits,
        amountDueNextBillingPeriod,
        formattedNextBillingDate,
        refreshPage
    } = props;

    return (
        <>
            <Typography>
                {intl.formatMessage({ id: `${type}-plan-success` })}
            </Typography>
            <br />
            <Typography>{intl.formatMessage({ id: "reminder" })}</Typography>
            <Typography>
                {
                     intl.formatMessage(
                        {
                            id: option === "endWithCredits" && (currentCredits + newCredits > selectedPlan.amount - couponValue) ?
                                'plan-renew-automatically-for-amount-on-date-with-credits' :
                                'plan-renew-automatically-for-amount-on-date'
                        },
                        {
                            amount: amountDueNextBillingPeriod,
                            credits: (currentCredits + newCredits - (selectedPlan.amount - couponValue)) / 100,
                            date: formattedNextBillingDate
                        }
                    ) + intl.formatMessage({ id: ". " }) + intl.formatMessage({ id: "you-can-cancel-anytime" })
                }
            </Typography >

            <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                disabled={loading}
                onClick={refreshPage}
                style={{ marginTop: 12 }}
            >
                {loading ?
                    <><CircularProgress size={18} style={{ marginRight: 8 }} /> {intl.formatMessage({ id: 'page-will-be-refreshed' })}</> :
                    intl.formatMessage({ id: 'start-now' })
                }
            </Button>
        </>
    );
};

export default Complete;