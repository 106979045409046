import React, { Fragment, useState, useEffect } from 'react';
import ChatGPTPresetDrawer from '../ChatGPTPresetDrawer';
import { connect } from 'react-redux';
import { toggleControl } from '../../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import FollowUpButton from '../FollowUpButton/FollowUpButton';
import ActionButton from '../ActionButton/ActionButton';
import { decreaseGPTQuota, handleError, resetGPTQuota, validateGPTQuota } from '../../../../utils/openai';
import { getIceBreakingPrompt } from '../../../../utils/aiPrompt';
import LoadingBackdrop from '../../../LoadingBackdrop/LoadingBackdrop';
import ContentBox from '../ContentBox/ContentBox';
import { openaiGenerateWithTextStreaming } from '../../../../store/openai/api';
import { useForceUpdate } from '../../../../utils/hooks';
import QuotaTypography from '../QuotaTypography/QuotaTypography';
import { trackDirect } from '../../../../store/analytics/api';
import { tracking } from '../../../../utils/tracking';
import { updateFactSheet } from '../../../../store/client/duck';
import SmallRoundedButton from '../../../SmallRoundedButton/SmallRoundedButton';
import { withRouter } from 'react-router-dom';

export const DISCMapping = {
  D: { outgoing: true, introverted: false, logical: true, emotional: false },
  I: { outgoing: true, introverted: false, logical: false, emotional: true },
  S: { outgoing: false, introverted: true, logical: false, emotional: true },
  C: { outgoing: false, introverted: true, logical: true, emotional: false }
};

const ChatGPTIceBreakingDrawer = props => {
  const { intl, chatGPTIceBreakingDrawer, toggleControl, updateFactSheet, location } = props;
  const [text, setText] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [personality, setPersonality] = useState('');
  const [traits, setTraits] = useState({
    outgoing: false,
    introverted: false,
    logical: false,
    emotional: false
  });
  const forceUpdate = useForceUpdate();

  const { client, closed } = chatGPTIceBreakingDrawer || {};
  const isOpen = !!chatGPTIceBreakingDrawer && !closed;

  useEffect(() => {
    if (location.state?.gpt === 'ice-breaking' && location.state?.selectedClient) {
      toggleControl('chatGPTIceBreakingDrawer', {
        client: location.state.selectedClient,
        closed: false
      });
    }
  }, [location.state, toggleControl]);

  useEffect(() => {
    if (client?.factsheetId?.personality) {
      const existingPersonality = client.factsheetId.personality;
      setPersonality(existingPersonality);
      setTraits(
        DISCMapping[existingPersonality] || {
          outgoing: false,
          introverted: false,
          logical: false,
          emotional: false
        }
      );
    }
  }, [client?.factsheetId?.personality]);

  const onClose = () => {
    toggleControl('chatGPTIceBreakingDrawer', { ...chatGPTIceBreakingDrawer, closed: true });
  };

  const onExited = () => {
    toggleControl('chatGPTIceBreakingDrawer', false);
    setText('');
    setChatMessages([]);
    setPersonality('');
    setTraits({
      outgoing: false,
      introverted: false,
      logical: false,
      emotional: false
    });
  };

  const onFeedback = () => {
    toggleControl('chatGPTFeedbackDialog', {
      client: client,
      extraContent: text,
      onSubmit: () => {
        resetGPTQuota();
        forceUpdate();
      }
    });
  };

  const updatePersonality = newTraits => {
    setTraits(newTraits);

    // Determine personality based on traits
    let newPersonality = '';
    if (newTraits.outgoing && newTraits.logical) newPersonality = 'D';
    else if (newTraits.outgoing && newTraits.emotional) newPersonality = 'I';
    else if (newTraits.introverted && newTraits.emotional) newPersonality = 'S';
    else if (newTraits.introverted && newTraits.logical) newPersonality = 'C';

    setPersonality(newPersonality);

    if (newPersonality && client?.factsheetId?._id) {
      updateFactSheet({
        id: client.factsheetId._id,
        key: 'personality',
        value: newPersonality,
        clientId: client._id
      });
    }
  };

  const toggleTrait = trait => {
    const newTraits = { ...traits };

    // Reset opposing traits
    if (trait === 'outgoing' || trait === 'introverted') {
      newTraits.outgoing = false;
      newTraits.introverted = false;
    }
    if (trait === 'logical' || trait === 'emotional') {
      newTraits.logical = false;
      newTraits.emotional = false;
    }

    // Toggle the selected trait
    newTraits[trait] = !traits[trait];

    updatePersonality(newTraits);
  };

  const send = async ({ inputMessage, temperature, previousMessages }) => {
    try {
      validateGPTQuota();
      setLoading(true);
      trackDirect('generateIceBreakingScript');
      tracking('AI: Generate Ice Breaking Script');

      const addedChatMessages = [...(previousMessages || []), { content: inputMessage, role: 'user' }];
      await openaiGenerateWithTextStreaming(
        'gpt-4o',
        addedChatMessages,
        Number.parseFloat(temperature),
        1,
        0,
        0,
        setChatMessages,
        setText
      );

      decreaseGPTQuota();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const generateScript = temperature => {
    if (!personality) return;
    const inputMessage = getIceBreakingPrompt({
      client: {
        ...client,
        factsheetId: {
          ...client?.factsheetId,
          personality
        }
      }
    });
    send({ inputMessage, temperature });
  };

  const clear = () => {
    setText('');
    setChatMessages([]);
  };

  return (
    <Fragment>
      <LoadingBackdrop open={loading} />
      <ChatGPTPresetDrawer open={isOpen} onClose={onClose} onExited={onExited} onFeedback={onFeedback}>
        <Grid container direction="column" spacing={2} style={{ height: '100%' }}>
          <Grid item>
            <Typography style={{ whiteSpace: 'pre-wrap' }}>
              {intl.formatMessage({ id: 'chat-gpt-ice-breaking-drawer-content' })}
              <span style={{ color: 'red' }}>
                {intl.formatMessage({ id: 'chat-gpt-ice-breaking-drawer-content-warning' })}
              </span>
              {'\n\n'}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography>{intl.formatMessage({ id: 'choose-personality' })}</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Grid container justify="center" spacing={1}>
                      <Grid item>
                        <SmallRoundedButton selected={traits.outgoing} onClick={() => toggleTrait('outgoing')}>
                          {intl.formatMessage({ id: 'outgoing' })}
                        </SmallRoundedButton>
                      </Grid>
                      <Grid item>
                        <SmallRoundedButton selected={traits.introverted} onClick={() => toggleTrait('introverted')}>
                          {intl.formatMessage({ id: 'introverted' })}
                        </SmallRoundedButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container justify="center" spacing={1}>
                      <Grid item>
                        <SmallRoundedButton selected={traits.logical} onClick={() => toggleTrait('logical')}>
                          {intl.formatMessage({ id: 'logical' })}
                        </SmallRoundedButton>
                      </Grid>
                      <Grid item>
                        <SmallRoundedButton selected={traits.emotional} onClick={() => toggleTrait('emotional')}>
                          {intl.formatMessage({ id: 'emotional' })}
                        </SmallRoundedButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography>
                  {personality
                    ? intl.formatMessage(
                        { id: 'chat-gpt-ice-breaking-drawer-content-with-disc' },
                        { personality: intl.formatMessage({ id: `personality_${personality}` }) }
                      )
                    : intl.formatMessage({ id: 'chat-gpt-ice-breaking-drawer-content-no-disc' })}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>{intl.formatMessage({ id: 'answer-style' })}</Typography>
                <Grid container spacing={1}>
                  <Grid item>
                    <ActionButton onClick={() => generateScript(0)} disabled={!personality}>
                      {intl.formatMessage({ id: 'chat-gpt-ice-breaking-action-accurate' })}
                    </ActionButton>
                  </Grid>
                  <Grid item>
                    <ActionButton onClick={() => generateScript(0.3)} disabled={!personality}>
                      {intl.formatMessage({ id: 'chat-gpt-ice-breaking-action-balanced' })}
                    </ActionButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <ContentBox
              getHeight={({ innerHeight }) => `calc(${innerHeight}px - 330px - var(--top-padding))`}
              text={text}
            />
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Grid container justify="space-between">
                  <Grid item>{text && <Typography>{intl.formatMessage({ id: 'Follow Up' })}</Typography>}</Grid>
                  <Grid item>
                    <QuotaTypography />
                  </Grid>
                </Grid>
              </Grid>
              {text && (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <FollowUpButton onClick={clear}>
                        {intl.formatMessage({ id: 'chat-gpt-follow-up-clear' })}
                      </FollowUpButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </ChatGPTPresetDrawer>
    </Fragment>
  );
};

const container = connect(
  state => ({
    chatGPTIceBreakingDrawer: state.control.chatGPTIceBreakingDrawer
  }),
  {
    toggleControl,
    updateFactSheet
  }
)(withRouter(ChatGPTIceBreakingDrawer));

export default injectIntl(container);
