import React from 'react';
import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { StripeElementWrapper } from '../../../components/StripeElement/StripeElementWrapper';

const Payment = props => {
    const {
        intl,
        userDetails,
        stripePromise,
        setStep,
        option,
        selectedPlan,
        extension,
        newCredits,
        clientSecret,
        setErrorMessage,
        setCompleteTime,
        amountDueToday,
        amountDueNextBillingPeriod,
        nextBillingDate
    } = props;

    function proceedToComplete() {
        setErrorMessage("");
        setCompleteTime(new Date());
        setStep(4);
    }

    // Data to pass to Apple Pay
    var recurringIntervalUnit = selectedPlan.interval === "week" ? "day" : selectedPlan.interval;
    var recurringIntervalCount = selectedPlan.interval === "week" ? selectedPlan.intervalCount * 7 : selectedPlan.intervalCount;
    if (option === "end" && amountDueToday === amountDueNextBillingPeriod) {
        var recurringAmount = amountDueToday * 100;
        var recurringStartDate = new Date();
        var recurringLabel = intl.formatMessage({ id: "portfoplus-subscription" });
    } else {
        var recurringAmount = amountDueNextBillingPeriod * 100;
        var recurringStartDate = nextBillingDate.toDate();

        var trialAmount = amountDueToday * 100;
        var trialStartDate = new Date();
        var trialEndDate = nextBillingDate.toDate();
        var trialIntervalUnit = selectedPlan.interval === "week" ? "day" : selectedPlan.interval;
        var trialIntervalCount = selectedPlan.interval === "week" ? selectedPlan.intervalCount * 7 : selectedPlan.intervalCount;

        if (option === "endWithExtension") {
            var recurringLabel = intl.formatMessage({ id: "change-plan-apple-pay-subsequent" });
            var trialLabel = selectedPlan.intervalCount > 1 ?
                intl.formatMessage({ id: "change-plan-apple-pay-first-count-interval-extension" }, { count: selectedPlan.intervalCount, interval: selectedPlan.interval, number: extension }) :
                intl.formatMessage({ id: "change-plan-apple-pay-first-interval-extension" }, { interval: selectedPlan.interval, number: extension });
        } else if (option === "endWithCredits") {
            var recurringLabel = intl.formatMessage({ id: "change-plan-apple-pay-subsequent" });
            var trialLabel = selectedPlan.intervalCount > 1 ?
                intl.formatMessage({ id: "change-plan-apple-pay-first-count-interval-credits" }, { count: selectedPlan.intervalCount, interval: selectedPlan.interval, amount: newCredits / 100 }) :
                intl.formatMessage({ id: "change-plan-apple-pay-first-interval-credits" }, { interval: selectedPlan.interval, amount: newCredits / 100 });
        } else if (option === "scheduleChange") {
            var recurringLabel = intl.formatMessage({ id: "new-subscription" });
            var trialLabel = intl.formatMessage({ id: "current-subscription" });
        } else {
            var recurringLabel = intl.formatMessage({ id: "change-plan-apple-pay-subsequent" });
            var trialLabel = selectedPlan.intervalCount > 1 ?
                intl.formatMessage({ id: "change-plan-apple-pay-first-count-interval" }, { count: selectedPlan.intervalCount, interval: selectedPlan.interval }) :
                intl.formatMessage({ id: "change-plan-apple-pay-first-interval" }, { interval: selectedPlan.interval });
        }
    }

    return (
        <>
            <Button
                onClick={() => setStep(2)}
                variant="text"
                style={{ color: "grey", marginTop: -8, marginBottom: 4 }}
            >
                <ArrowBackIosIcon fontSize='small' /> {intl.formatMessage({ id: 'back-to-previous' })}
            </Button>

            <StripeElementWrapper
                intl={intl}
                stripePromise={stripePromise}
                clientSecret={clientSecret}
                userDetails={userDetails}
                buttonText={option === "scheduleChange" ? intl.formatMessage({ id: "Update" }) : intl.formatMessage({ id: "pay-amount" }, { amount: amountDueToday })}
                isSetup={option === "scheduleChange"}
                isRecurring={true}
                paymentDescription={intl.formatMessage({ id: "portfoplus-subscription" })}
                billingAgreement={intl.formatMessage({ id: 'billing-agreement' })}
                recurringAmount={recurringAmount}
                recurringLabel={recurringLabel}
                recurringStartDate={recurringStartDate}
                recurringIntervalUnit={recurringIntervalUnit}
                recurringIntervalCount={recurringIntervalCount}
                trialAmount={trialAmount}
                trialLabel={trialLabel}
                trialStartDate={trialStartDate}
                trialEndDate={trialEndDate}
                trialIntervalUnit={trialIntervalUnit}
                trialIntervalCount={trialIntervalCount}
                completePay={proceedToComplete}
            />
        </>
    );
};

export default Payment;