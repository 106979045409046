import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import FunctionCardsView from '../../../components/FunctionCard/FunctionCardsView';
import { tracking } from '../../../utils/tracking';
import { toggleDialog } from '../../../store/control/duck';
import _ from 'lodash';
import { showShowTeamLibrary, shouldEnableTeamChatBot } from '../../../utils/user';
import { UserSubscription } from '../../../utils/user-subscription';
import SubscriptionDialog from '../../Subscription/SubscriptionDialog/SubscriptionDialog';
import TeamBotDialog from '../../../components/TeamBot/TeamBotDialog';

const Recruitment = props => {
  const { intl, userDetails, stripePromise, teamCampaignCodes, wordpressPages, toggleDialog } = props;

  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);

  const userSubscriptionO = new UserSubscription(props.userSubscription);
  const currentCoreSubscription = userSubscriptionO.getCurrentCoreSubscription();
  const shouldDisableFunctions = !userSubscriptionO.containVaildProSubscription();

  function openSubscriptionDialog() {
    setSubscriptionDialogOpen(true);
  }

  function openTeamBotDialog() {
    console.log('openTeamBotDialog');
    toggleDialog('teamBot', true);
  }

  const shouldShowTeam =
    _.get(userDetails, 'teamLeaderOf.length', 0) > 0 || _.get(userDetails, 'companyLeaderOf.length', 0) > 0;
  const showTeamLibrary = showShowTeamLibrary({ user: userDetails, teamCampaignCodes: teamCampaignCodes });
  const showTeamChatBot = shouldEnableTeamChatBot({
    user: userDetails,
    teamCampaignCodes: teamCampaignCodes,
    userSubscription: userSubscriptionO
  });

  const getItems = ({ intl, history }) => [
    ...(wordpressPages || []).map(page => {
      const nameLocale = _.get(page.name, intl.locale, '');
      return {
        color: '#5fc3d2',
        imgSrc: page.iconImg,
        imgAlt: nameLocale,
        text: nameLocale,
        subtitle: intl.formatMessage({ id: 'team-page-desc' }),
        onClick: () => {
          if (shouldDisableFunctions) {
            openSubscriptionDialog();
          } else {
            const nameEn = _.get(page.name, 'en', '');
            tracking(`Click on ${nameEn} - Recruitment`);
            history.push({ pathname: '/frame', state: { url: page.link, title: nameLocale } });
          }
        }
      };
    }),
    shouldShowTeam && {
      color: '#5fc3d2',
      imgSrc: 'img/icons/speaker_active_color.svg',
      imgAlt: 'team Blast',
      text: intl.formatMessage({ id: 'team-blast' }),
      subtitle: intl.formatMessage({ id: 'team-blast-subtitle' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on Team Blast - Recruitment');
          history.push({ pathname: '/team_blast' });
        }
      }
    },
    {
      color: '#3999f8',
      imgSrc: 'img/icons/team_add_active_color.svg',
      imgAlt: 'recruitment-tools',
      text: intl.formatMessage({ id: 'recruitment-tools' }),
      subtitle: intl.formatMessage({ id: 'recruitment-tools-desc' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on Recruitment Tools - Recruitment');
          toggleDialog('recruitment', {});
        }
      }
    },
    showTeamLibrary && {
      color: '#3999f8',
      imgSrc: 'img/icons/team_folder_active_color.svg',
      imgAlt: 'recruitment-tools',
      text: intl.formatMessage({ id: 'team-library' }),
      subtitle: intl.formatMessage({ id: 'team-library-desc' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on Recruitment Tools - Team Library');
          history.push({ pathname: '/team_library' });
        }
      }
    },
    {
      color: '#2260a5',
      imgSrc: 'img/icons/team_active_color.svg',
      imgAlt: 'recruitment-target',
      text: intl.formatMessage({ id: 'recruitment-target-title' }),
      subtitle: intl.formatMessage({ id: 'recruitment-target-subtitle' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          window.localStorage.setItem('clientState', JSON.stringify('recruitment'));
          history.push({ pathname: '/client' });
        }
      }
    },
    showTeamChatBot && {
      color: '#2260a5',
      imgSrc: 'img/icons/message_people_active_color.svg',
      imgAlt: 'team-bot',
      text: intl.formatMessage({ id: 'team-bot' }),
      subtitle: intl.formatMessage({ id: 'team-bot-subtitle' }),
      onClick: () => {
        openTeamBotDialog();
      }
    },
    shouldShowTeam && {
      color: '#453bd1',
      imgSrc: 'img/icons/target_active_color.svg',
      imgAlt: 'team-performance',
      text: intl.formatMessage({ id: 'team-performance' }),
      subtitle: intl.formatMessage({ id: 'team-performance-desc' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on Team Performance - Recruitment');
          history.push('/advisor_team');
        }
      }
    },
    {
      color: '#5fc3d2',
      imgSrc: 'img/icons/team_search_active_color.svg',
      imgAlt: 'team-recruitment',
      text: intl.formatMessage({ id: 'team-form' }),
      subtitle: intl.formatMessage({ id: 'team-recruitment-desc' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on Team & Recruit - Recruitment');
          history.push({ pathname: '/recruitment-form' });
        }
      }
    }
  ];

  return (
    <>
      <SubscriptionDialog
        intl={intl}
        userDetails={userDetails}
        stripePromise={stripePromise}
        open={subscriptionDialogOpen}
        onClose={() => setSubscriptionDialogOpen(false)}
        type="upgrade"
        currentSubscription={currentCoreSubscription}
        trialing={false}
        disableCoupon={true}
        defaultCoupon=""
      />

      {showTeamChatBot && <TeamBotDialog />}

      <FunctionCardsView className="recruitment" getItems={getItems} />
    </>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    teamCampaignCodes: state.functions.campaignCodes,
    wordpressPages: state.functions.wordpressPages,
    userSubscription: state.userSubscription.userSubscription
  }),
  {
    toggleDialog
  }
)(Recruitment);

export default injectIntl(container);
