import { fetch } from '../../../utils';
import Backend from '../../../backend';

export const azureOpenaiGenerateWithTextStreaming = async (
  { model, messages, temperature, topP, frequencyPenalty, presencePenalty },
  options = {},
  callbacks = {}
) => {
  const { pollTimeout = 7000 } = options;
  const { setChatMessages, setText, completeAction, setLoading } = callbacks;

  try {
    const response = await fetch(Backend.methodUrl('azureOpenaiGenerateWithTextStreaming'), {
      method: 'POST',
      body: JSON.stringify({ model, messages, temperature, topP, frequencyPenalty, presencePenalty }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let responseText = '';

    function read() {
      return reader.read().then(({ done, value }) => {
        if (done) {
          if (completeAction) {
            completeAction(responseText);
          }
          if (setLoading) {
            setLoading(false);
          }
          return;
        }

        const text = decoder.decode(value, { stream: true });
        responseText += text;

        if (setChatMessages) {
          setChatMessages([...messages, { content: responseText, role: 'assistant' }]);
        }
        if (setText) {
          setText(responseText);
        }

        return read();
      });
    }

    return read();
  } catch (error) {
    if (setLoading) {
      setLoading(false);
    }
    throw error;
  }
};

export const azureOpenaiTeamBot = async ({ messages }, options = {}, callbacks = {}) => {
  const { setChatMessages, setLoading } = callbacks;

  try {
    const response = await fetch(Backend.methodUrl('teamBot'), {
      method: 'POST',
      body: JSON.stringify({ messages }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const data = await response.json();

    if (setChatMessages) {
      setChatMessages([...messages, { content: data.text, role: 'assistant' }]);
    }

    return data;
  } catch (error) {
    if (setLoading) {
      setLoading(false);
    }
    throw error;
  }
};

export const azureOpenaiTeamBotFollowUpQuestion = async ({ messages }, options = {}, callbacks = {}) => {
  const { setChatMessages, setLoading } = callbacks;

  try {
    const response = await fetch(Backend.methodUrl('teamBotFollowUpQuestion'), {
      method: 'POST',
      body: JSON.stringify({ messages }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const data = await response.json();
    return data;
  } catch (error) {
    if (setLoading) {
      setLoading(false);
    }
    throw error;
  }
};
