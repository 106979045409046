import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import { THEME_COLOR_PALETTES,getValidThemeColorOption } from '../../../constants/my-details';
import AdviserInfoTagChip from '../AdviserInfoTagChip/AdviserInfoTagChip';
import { getHobbyIntl, getMainLanguageIntl } from '../../../utils/my-details';
import { tryTranslate } from '../../../utils/locale';
import { tagsToTagsString } from '../../../utils/user';
import AdviserInfoCardAbstract from '../AdviserInfoCardAbstract/AdviserInfoCardAbstract';

const FullWidthStyle = {
  width: '100%'
};

const AdviserInfoPageSocial = props => {
  const { intl, adviserInfo, isIntoView } = props;

  const { mainLanguages, hobbies, customizedHobby, university, secondarySchool, familiarPlaces, otherHashtags } =
    adviserInfo || {};
  const themeColor = getValidThemeColorOption(adviserInfo);
  const borderColor = THEME_COLOR_PALETTES[themeColor][1];
  const formattedMainLanguages = mainLanguages || [];
  const formattedHobbies = hobbies || [];
  const formattedUniversity = university || [];
  const formattedSecondarySchool = secondarySchool || [];
  const schools = [...formattedUniversity, ...formattedSecondarySchool];
  const formattedFamiliarPlaces = familiarPlaces || [];

  return (
    <AdviserInfoCardAbstract
      className="adviser-info-page-social"
      title={intl.formatMessage({ id: 'adv-info-social-title' })}
      adviserInfo={adviserInfo}
      contentHeight="calc(100% - 110px)"
      isIntoView={isIntoView}
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid
          item
          style={{
            ...FullWidthStyle,
            flexGrow: 1,
            paddingBottom: 34
          }}
          className="content-container"
        >
          <Grid container direction="column" alignItems="flex-start" spacing={3}>
            <Grid item style={FullWidthStyle}>
              <Grid container direction="column" alignItems="flex-start" justify="center" spacing={1}>
                <Grid item>
                  <Typography display="inline" className="emoji">
                    ⚽
                  </Typography>
                  <Typography display="inline" className="title">
                    {intl.formatMessage({ id: 'hobbies' })}
                  </Typography>
                </Grid>
                <Grid item>
                  {formattedHobbies.length > 0 ? (
                    <Grid container alignItems="center" justify="flex-start" spacing={1}>
                      {formattedHobbies.map(tag => (
                        <Grid item key={tag}>
                          <AdviserInfoTagChip
                            color={borderColor}
                            label={tryTranslate(intl, getHobbyIntl(tag))}
                            isIntoView={isIntoView}
                          />
                        </Grid>
                      ))}
                      {!!customizedHobby && (
                        <Grid item>
                          <AdviserInfoTagChip color={borderColor} label={customizedHobby} isIntoView={isIntoView} />
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    <Typography color="textSecondary">-</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={FullWidthStyle}>
              <Grid container direction="column" alignItems="flex-start" spacing={1}>
                <Grid item>
                  <Typography display="inline" className="emoji">
                    ✈️
                  </Typography>
                  <Typography display="inline">{intl.formatMessage({ id: 'familiar-places' })}</Typography>
                </Grid>
                <Grid item>
                  {formattedFamiliarPlaces.length > 0 ? (
                    <Grid container alignItems="center" justify="flex-start" spacing={1}>
                      {formattedFamiliarPlaces.map(familiarPlace => (
                        <Grid item key={familiarPlace}>
                          <AdviserInfoTagChip color={borderColor} label={familiarPlace} isIntoView={isIntoView} />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography color="textSecondary">-</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={FullWidthStyle}>
              <Grid container direction="column" alignItems="flex-start" spacing={1}>
                <Grid item>
                  <Typography display="inline" className="emoji">
                    🎓
                  </Typography>
                  <Typography display="inline" className="title">
                    {intl.formatMessage({ id: 'school' })}
                  </Typography>
                </Grid>
                <Grid item>
                  {schools.length > 0 ? (
                    <Grid container alignItems="center" justify="flex-start" spacing={1}>
                      {schools.map(school => (
                        <Grid item key={school}>
                          <AdviserInfoTagChip color={borderColor} label={school} isIntoView={isIntoView} />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography color="textSecondary">-</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={FullWidthStyle}>
              <Grid container direction="column" alignItems="flex-start" spacing={1}>
                <Grid item>
                  <Typography display="inline" className="emoji">
                    📣
                  </Typography>
                  <Typography display="inline" className="title">
                    {intl.formatMessage({ id: 'main-lang' })}
                  </Typography>
                </Grid>
                <Grid item>
                  {formattedMainLanguages.length > 0 ? (
                    <Grid container alignItems="center" justify="flex-start" spacing={1}>
                      {formattedMainLanguages.map(tag => (
                        <Grid item key={tag}>
                          <AdviserInfoTagChip
                            color={borderColor}
                            label={tryTranslate(intl, getMainLanguageIntl(tag))}
                            isIntoView={isIntoView}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography color="textSecondary">-</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {otherHashtags && (
              <Grid item style={FullWidthStyle}>
                <Typography display="inline" className="emoji">
                  #️⃣
                </Typography>
                <Typography display="inline">{tagsToTagsString(otherHashtags)}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </AdviserInfoCardAbstract>
  );
};

export default injectIntl(AdviserInfoPageSocial);
