import React, { Fragment, useState } from 'react';
import ChatGPTPresetDrawer from '../ChatGPTPresetDrawer';
import { connect } from 'react-redux';
import { toggleControl } from '../../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { Grid, Radio, RadioGroup, FormControlLabel, Typography } from '@material-ui/core';
import FollowUpButton from '../FollowUpButton/FollowUpButton';
import ActionButton from '../ActionButton/ActionButton';
import { decreaseGPTQuota, handleError, resetGPTQuota, validateGPTQuota } from '../../../../utils/openai';
import { getPersonalMessagePrompt } from '../../../../utils/aiPrompt';
import { withRouter } from 'react-router';
import LoadingBackdrop from '../../../LoadingBackdrop/LoadingBackdrop';
import ContentBox from '../ContentBox/ContentBox';
import { openaiGenerateWithTextStreaming } from '../../../../store/openai/api';
import { useForceUpdate } from '../../../../utils/hooks';
import QuotaTypography from '../QuotaTypography/QuotaTypography';
import { trackDirect } from '../../../../store/analytics/api';
import { tracking } from '../../../../utils/tracking';

const ChatGPTPersonalMessageDrawer = props => {
  const { intl, history, chatGPTPersonalMessageDrawer, toggleControl } = props;

  const [text, setText] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const [insuranceType, setType] = useState('');
  const [language, setLanguage] = useState(
    history.location.state?.language ? history.location.state.language : 'Traditional Chinese'
  );
  const forceUpdate = useForceUpdate();

  const { client, closed } = chatGPTPersonalMessageDrawer || {};

  const isOpen = !!chatGPTPersonalMessageDrawer && !closed;

  const onClose = () => {
    toggleControl('chatGPTPersonalMessageDrawer', { ...chatGPTPersonalMessageDrawer, closed: true });
  };

  const onExited = () => {
    toggleControl('chatGPTPersonalMessageDrawer', false);
    setText('');
    setChatMessages([]);
  };

  const onFeedback = () => {
    toggleControl('chatGPTFeedbackDialog', {
      client: client,
      extraContent: text,
      onSubmit: () => {
        resetGPTQuota();
        forceUpdate();
      }
    });
  };

  const send = async () => {
    if (!insuranceType) {
      return;
    }

    try {
      validateGPTQuota();
      setLoading(true);
      trackDirect('generatePersonalizedMessage');
      tracking('AI: Generate Personalized Message');

      const inputMessage = getPersonalMessagePrompt({ insuranceType: insuranceType, language: language });

      const addedChatMessages = [{ content: inputMessage, role: 'user' }];

      await openaiGenerateWithTextStreaming('gpt-4o', addedChatMessages, 1, 1, 0, 0, setChatMessages, setText);

      decreaseGPTQuota();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const clear = () => {
    setText('');
    setChatMessages([]);
  };

  return (
    <Fragment>
      <LoadingBackdrop open={loading} />
      <ChatGPTPresetDrawer open={isOpen} onClose={onClose} onExited={onExited} onFeedback={onFeedback}>
        <Grid container direction="column" spacing={2} style={{ height: '100%' }}>
          <Grid item>
            <Typography style={{ whiteSpace: 'pre-wrap' }}>
              {intl.formatMessage({ id: 'chat-gpt-personal-msg-drawer-content' })}
            </Typography>
          </Grid>
          <Grid item>
            <RadioGroup
              row
              aria-label="type"
              name="type"
              value={insuranceType}
              onChange={event => setType(event.target.value)}
            >
              <FormControlLabel
                value="Life"
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: 'life' })}
              />
              <FormControlLabel
                value="C.I."
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: 'C.I.' })}
              />
              <FormControlLabel
                value="Medical"
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: 'Medical' })}
              />
              <FormControlLabel
                value="Saving"
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: 'Saving' })}
              />
            </RadioGroup>
          </Grid>
          <Grid item>
            <RadioGroup
              row
              aria-label="language"
              name="language"
              value={language}
              onChange={event => setLanguage(event.target.value)}
            >
              <FormControlLabel
                value="Traditional Chinese"
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: 'traditional-chinese' })}
              />
              <FormControlLabel
                value="English"
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: 'standard-english' })}
              />
              <FormControlLabel
                value="Simplified Chinese"
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: 'simplified-chinese' })}
              />
            </RadioGroup>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <ActionButton onClick={send} style={{ padding: 5, width: '100%' }}>
              {intl.formatMessage({ id: 'submit' })}
            </ActionButton>
          </Grid>
          <Grid item>
            <ContentBox
              getHeight={({ innerHeight }) => `calc(${innerHeight}px - 300px - var(--top-padding))`}
              text={text}
            />
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Grid container justify="space-between">
                  <Grid item>{text && <Typography>{intl.formatMessage({ id: 'Follow Up' })}</Typography>}</Grid>
                  <Grid item>
                    <QuotaTypography />
                  </Grid>
                </Grid>
              </Grid>
              {text && (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <FollowUpButton onClick={clear}>
                        {intl.formatMessage({ id: 'chat-gpt-follow-up-clear' })}
                      </FollowUpButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </ChatGPTPresetDrawer>
    </Fragment>
  );
};

const container = connect(
  state => ({
    chatGPTPersonalMessageDrawer: state.control.chatGPTPersonalMessageDrawer
  }),
  {
    toggleControl
  }
)(ChatGPTPersonalMessageDrawer);

export default injectIntl(withRouter(container));
