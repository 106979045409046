import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { fetchMePolicy, insurerInfo } from '../../../store/policy/duck';
import { createDemoClient } from '../../../store/client/api';
import { getEmergencyExport } from '../../../store/emergencyExport/api';
import { getPDF } from '../../../store/pdf/api';
import { trackDirect } from '../../../store/analytics/api';
import {
    TextField,
    Button,
    Typography,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Box,
    RadioGroup,
    Radio,
    FormControlLabel,
    useMediaQuery
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AladdinHint from '../../../components/AladdinHint/AladdinHint';
import BottomSheetOrDialog from '../../../components/BottomSheetOrDialog/BottomSheetOrDialog';
import VoiceCreateSosPaperButton from '../../../components/VoiceCreateSosPaperButton/VoiceCreateSosPaperButton';
import { SUB_CATEGORY_COLORS } from '../../../utils/policy';
import { insurerRegionFilter } from '../../../utils/region';
import { isInsurerOther, isInsurerWhatIf } from '../../../utils/insurer';
import { translateMessage } from '../../../utils/locale';
import NativeOrWeb from '../../../utils/native';
import { tracking } from '../../../utils/tracking';
import '../../PolicyReview/PolicyReviewContainer.scss';
import './CreateDemoClient.scss';

const CreateDemoClient = ({ intl, history }) => {
    var isWideScreen = useMediaQuery(theme => theme.breakpoints.up('sm'));
    var adviser = useSelector(s => s.user.userDetails);
    var insurers = useSelector(s => s.policy.insurers);

    const [clientName, setClientName] = useState('');
    const [policies, setPolicies] = useState([]);
    const [currentPolicyIndex, setCurrentPolicyIndex] = useState(-1);
    const [currentOtherPolicyIndex, setCurrentOtherPolicyIndex] = useState(-1);
    const [selectInsurerDialogOpen, setSelectInsurerDialogOpen] = useState(false);
    const [clientCreatedDialogOpen, setClientCreatedDialogOpen] = useState(false);
    const [sendToClientDialogOpen, setSendToClientDialogOpen] = useState(false);
    const [clientNameError, setClientNameError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pdfLanguage, setPdfLanguage] = useState(adviser.language || "zh-Hant-HK");
    const [newClientId, setNewClientId] = useState("");
    const clientNameRef = useRef(null);
    const otherInsurerRef = useRef(null);
    var dispatch = useDispatch();

    var defaultPolicies = {
        life: { category: "life", name: intl.formatMessage({ id: 'life-portfolio' }) },
        criticalIllness: { category: "criticalIllness", name: intl.formatMessage({ id: 'ci-portfolio' }) },
        medical: { category: "medical", name: intl.formatMessage({ id: 'med-portfolio' }) },
        saving: { category: "saving", name: intl.formatMessage({ id: 'saving-portfolio' }) },
        ilas: { category: "ilas", name: intl.formatMessage({ id: 'ilas-portfolio' }) },
        accident: { category: "accident", name: intl.formatMessage({ id: 'accident-portfolio' }) },
        providentFund: { category: "providentFund", name: intl.formatMessage({ id: 'provident-fund-portfolio' }) }
    };

    useEffect(() => {
        dispatch(fetchMePolicy());
        dispatch(insurerInfo());
    }, [dispatch]);

    useEffect(() => {
        if (insurers) {
            let initialPolicies = Object.values(defaultPolicies);
            initialPolicies[0].insurer = selectRandomInsurer();
            initialPolicies[1].insurer = selectRandomInsurer();
            initialPolicies[6].insurer = selectRandomInsurer();
            setPolicies(initialPolicies);
        }
    }, [insurers, intl]);

    function onChangeClientName(event) {
        setClientNameError(false);
        setClientName(event.target.value);
    };

    function onChangePolicyName(event, index) {
        let newPolicies = [...policies];
        newPolicies[index].name = event.target.value;
        newPolicies[index].error = false;
        setPolicies(newPolicies);
    };

    function onChangeOtherInsurerName(event, index) {
        let newPolicies = [...policies];
        newPolicies[index].insurer = {
            _id: newPolicies[index].insurer._id,
            image: newPolicies[index].insurer.image,
            insurerId: "Other",
            title: { en: event.target.value, zh: event.target.value, "zh-Hant-HK": event.target.value }
        };
        setPolicies(newPolicies);
    };

    function finishTypingOtherInsurer() {
        let insurerTitle = policies[currentOtherPolicyIndex].insurer.title.en;
        let formattedInsurerTitle = policies[currentOtherPolicyIndex].insurer.title.en.trim();
        let currentPolicyCategory = policies[currentOtherPolicyIndex].category;

        let newPolicies = [...policies];
        if (!formattedInsurerTitle) {
            if (policies[currentOtherPolicyIndex + 1]?.category === currentPolicyCategory || policies[currentOtherPolicyIndex - 1]?.category === currentPolicyCategory) {
                newPolicies.splice(currentOtherPolicyIndex, 1);
            } else {
                newPolicies[currentOtherPolicyIndex].insurer = null;
            }
            setPolicies(newPolicies);
        } else if (formattedInsurerTitle !== insurerTitle) {
            let newPolicies = [...policies];
            newPolicies[currentOtherPolicyIndex].insurer.title = { en: formattedInsurerTitle, zh: formattedInsurerTitle, "zh-Hant-HK": formattedInsurerTitle };
            setPolicies(newPolicies);
        }
        setCurrentOtherPolicyIndex(-1);
    }

    function openSelectInsurerDialog(index) {
        setCurrentPolicyIndex(index);
        setSelectInsurerDialogOpen(true);
    }

    function selectInsurer(insurer) {
        let newPolicies = [...policies];
        let currentPolicyCategory = newPolicies[currentPolicyIndex].category;
        if (!insurer && (policies[currentPolicyIndex + 1]?.category === currentPolicyCategory || policies[currentPolicyIndex - 1]?.category === currentPolicyCategory)) {
            newPolicies.splice(currentPolicyIndex, 1);
        } else if (insurer?.insurerId === "Other") {
            setCurrentOtherPolicyIndex(currentPolicyIndex);
            if (!policies[currentPolicyIndex].insurer || policies[currentPolicyIndex].insurer.insurerId !== "Other") {
                newPolicies[currentPolicyIndex].insurer = {
                    _id: insurer._id,
                    image: insurer.image,
                    insurerId: "Other",
                    title: { en: "", zh: "", "zh-Hant-HK": "" }
                };
            }
        } else {
            newPolicies[currentPolicyIndex].insurer = insurer;
        }
        setCurrentPolicyIndex(-1);
        setPolicies(newPolicies);
        setSelectInsurerDialogOpen(false);


        if (insurer?.insurerId === "Other") {
            // To be updated after upgraded to React 16.8.6
            let timeout = setTimeout(() => { otherInsurerRef.current.focus(); }, 200);
            return () => { clearTimeout(timeout); };
        }
    }

    function addPolicy(index, category) {
        let newPolicies = [...policies];
        newPolicies.splice(index + 1, 0, defaultPolicies[category]);
        newPolicies[index + 1].insurer = selectMostLikelyInsurer(category);
        setPolicies(newPolicies);
    }

    function addPoliciesByVoice(rawData) {
        try {
            let data = JSON.parse(rawData.replace('```json', '').replace('```', ''));
            if (data) {
                if (data.clientName) {
                    setClientName(data.clientName);
                }
                let tempPolicies = Object.values([]);
                if (data.policies && data.policies.length > 0) {
                    for (let policy of data.policies) {
                        if (policy.category) {
                            tempPolicies.push({ ...defaultPolicies[policy.category] });
                            if (policy.name) {
                                tempPolicies[tempPolicies.length - 1].name = policy.name;
                            }
                            if (policy.insurer) {
                                tempPolicies[tempPolicies.length - 1].insurer = insurers.find(insurer => insurer._id === policy.insurer);
                            }
                        }
                    }
                }
                if (tempPolicies.length > 0) {
                    setPolicies([...tempPolicies]);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    function selectRandomInsurer() {
        let filteredInsurers = insurers.filter(insurerRegionFilter).filter(insurer => !isInsurerWhatIf(insurer) && !isInsurerOther(insurer));
        let randomInsurer = filteredInsurers[Math.floor(Math.random() * filteredInsurers.length)];
        return randomInsurer;
    }

    function selectMostLikelyInsurer(category) {
        let otherCategoriesInsurers = [];
        let sameCategoryInsurers = []
        for (let policy of policies) {
            if (policy.insurer && policy.insurer.insurerId !== "Other") {
                if (policy.category === category) {
                    sameCategoryInsurers.push(policy.insurer);
                } else {
                    otherCategoriesInsurers.push(policy.insurer);
                }
            }
        }
        otherCategoriesInsurers = otherCategoriesInsurers.filter(otherCategoriesInsurer => !sameCategoryInsurers.includes(otherCategoriesInsurer))

        if (otherCategoriesInsurers.length > 0) {
            var randomInsurer = otherCategoriesInsurers[Math.floor(Math.random() * otherCategoriesInsurers.length)];
        } else {
            var randomInsurer = selectRandomInsurer();
        }
        return randomInsurer;
    }

    function reArrangeInsurers(insurers) {
        let filteredInsurers = insurers.filter(insurerRegionFilter).filter(insurer => !isInsurerWhatIf(insurer));
        let result = ['disabled', ...filteredInsurers];
        let other = result.find(insurer => isInsurerOther(insurer));
        if (other) {
            result.splice(result.indexOf(other), 1);
            result.push(other);
        }
        return result;
    };

    async function createClientAndPolicies() {
        let error = false;
        let newPolicies = [];
        let newPoliciesWithErrors = [...policies];

        for (let index = 0; index < policies.length; index++) {
            if (policies[index].insurer?.title.en) {
                if (!policies[index].name) {
                    error = true;
                    newPoliciesWithErrors[index].error = true;
                } else {
                    newPolicies.push(policies[index]);
                    newPoliciesWithErrors[index].error = false;
                }
            }
        }
        setPolicies(newPoliciesWithErrors);

        if (!clientName) {
            error = true;
            setClientNameError(true);

            // To be updated after upgraded to React 16.8.6
            let timeout = setTimeout(() => { clientNameRef.current.focus(); }, 200);
            return () => { clearTimeout(timeout); };
        } else {
            setClientNameError(false);
        }

        if (!error) {
            tracking('Click on Create Demo Client Sample - Add Demo');
            setLoading(true);
            let result = await createDemoClient({ clientName, policies: newPolicies });
            setLoading(false);
            if (result.success) {
                setNewClientId(result.data);
                setClientCreatedDialogOpen(true);
                dispatch({
                    type: 'GET_CLIENT_BY_ID',
                    payload: { clientId: result.data }
                });
            }
        }
    };

    async function generate() {
        setLoading(true);
        let POLICY_INFO_TYPE = {
            POLICY_NO: 0,
            POLICY_NAME: 1
        };
        let result = await getEmergencyExport(newClientId);
        let options = {
            language: pdfLanguage,
            data: {
                ...result.emergencyExport,
                adviserId: adviser._id,
                clientId: newClientId
            },
            selectName: true,
            selectPolicyInfo: true,
            selectPolicyInfoType: POLICY_INFO_TYPE.POLICY_NAME,
            selectPersonalPolicy: true,
            selectWorkplacePolicy: true,
            selectGeneralPolicy: true,
            selectMacauInfo: false,
            useTeamLogo: true,
            changeHeaderImage: false,
            showReferIcon: true,
            remark: translateMessage('export_default_remark_with_style', pdfLanguage),
            outputFormat: 'pdf'
        };

        let pdfBlob = await getPDF('export', options);
        trackDirect("exportEmergencyReference", "demo");
        await NativeOrWeb.downloadFile(pdfBlob, `Summary_${(clientName || 'Client').split(' ')[0]}_${moment().format('DDMMMYYYY')}.pdf`, 'application/pdf');
        setLoading(false);
        setClientCreatedDialogOpen(false);
        setSendToClientDialogOpen(true);
    };

    function goToClientPage() {
        localStorage.setItem('clientId', JSON.stringify(newClientId));
        history.replace('/client');
    }

    function exportPdf() {
        tracking('Click on Export Demo Client PDF - Add Demo');
        generate();
    }

    return (
        <>
            <BottomSheetOrDialog
                open={selectInsurerDialogOpen}
                onClose={() => setSelectInsurerDialogOpen(false)}
                header={intl.formatMessage({ id: 'Select-insurer' })}
                content={
                    <ul className="card-list-insurance insure">
                        {reArrangeInsurers(insurers).map((insurer, index) =>
                            insurer === 'disabled' ?
                                <li
                                    key={index}
                                    className={policies[currentPolicyIndex]?.insurer ? '' : 'ins-info-selected'}
                                    onClick={() => selectInsurer(null)}
                                >
                                    <Button variant="contained" disabled>
                                        {intl.formatMessage({ id: 'demo-disabled' })}
                                    </Button>
                                </li>
                                :
                                <li
                                    key={index}
                                    className={insurer._id === policies[currentPolicyIndex]?.insurer?._id ? 'ins-info-selected' : ''}
                                    onClick={() => selectInsurer(insurer)}
                                >
                                    <img className="image1" src={insurer.image} alt="" />
                                    <h4>{insurer.title[intl.locale]}</h4>
                                </li>
                        )}
                    </ul>
                }
                actions={[]}
                BottomSheetProps={{
                    expandOnContentDrag: false,
                    disableAutoDismiss: true
                }}
                DialogProps={{
                    maxWidth: 'sm',
                    fullWidth: true
                }}
            />

            <Dialog open={clientCreatedDialogOpen}>
                <DialogTitle>{intl.formatMessage({ id: 'client-created-dialog-title' })}</DialogTitle>
                <DialogContent>
                    <Typography>{intl.formatMessage({ id: 'client-created-dialog-content' }, { name: clientName })}</Typography>
                    <br />

                    <div style={{ display: 'flex', flexDirection: isWideScreen ? 'row' : 'column', alignItems: 'center', justifyItems: "center" }}>
                        <Typography style={{ marginRight: isWideScreen && 5, fontWeight: "bold" }}>{intl.formatMessage({ id: 'pdf-language' })}</Typography>
                        <RadioGroup
                            row={true}
                            aria-label="pdfLanguage"
                            name="pdfLanguage"
                            value={pdfLanguage}
                            onChange={event => setPdfLanguage(event.target.value)}
                        >
                            <FormControlLabel
                                value="en"
                                control={<Radio color="primary" />}
                                label={intl.formatMessage({ id: 'standard-english' })}
                                style={{ margin: 0 }}
                            />
                            <FormControlLabel
                                value="zh-Hant-HK"
                                control={<Radio color="primary" />}
                                label={intl.formatMessage({ id: 'traditional-chinese' })}
                                style={{ margin: 0 }}
                            />
                            <FormControlLabel
                                value="zh"
                                control={<Radio color="primary" />}
                                label={intl.formatMessage({ id: 'simplified-chinese' })}
                                style={{ margin: 0 }}
                            />
                        </RadioGroup>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Grid container justify="space-between" alignItems="center" style={{ width: '100%' }}>
                        <Grid item>
                            {!loading && <Button color="primary" onClick={goToClientPage}>{intl.formatMessage({ id: 'check-client' })}</Button>}
                        </Grid>
                        <Grid item>
                            <Button color="primary" variant='contained' disabled={loading} onClick={exportPdf}>
                                {loading ? <CircularProgress size={24} /> : intl.formatMessage({ id: 'export-pdf' })}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <Dialog open={sendToClientDialogOpen}>
                <DialogTitle>{intl.formatMessage({ id: 'send-to-client-dialog-title' })}</DialogTitle>
                <DialogContent>
                    {intl.formatMessage({ id: 'send-to-client-dialog-content' })}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={goToClientPage}> {intl.formatMessage({ id: 'OK' })} </Button>
                </DialogActions>
            </Dialog>

            <div
                className="policy-review-container create-demo-client-container"
                style={{ maxWidth: 800, margin: 'auto', marginBottom: 20 }}
            >
                <AladdinHint
                    stepIndex={1}
                    title={intl.formatMessage({ id: 'cd-client-name' })}
                    hint={intl.formatMessage({ id: 'cd-client-name-hint' })}
                    titleTypoProps={{ style: { fontSize: '125%' } }}
                />

                <TextField
                    label={intl.formatMessage({ id: 'client-name' })}
                    inputRef={clientNameRef}
                    variant='outlined'
                    value={clientName}
                    onChange={(event) => onChangeClientName(event)}
                    error={clientNameError}
                    helperText={clientNameError && intl.formatMessage({ id: 'Name is required' })}
                    FormHelperTextProps={{ style: { fontSize: 14 } }}
                    required
                    InputProps={{ style: { fontSize: 16 } }}
                    style={{ marginBottom: '20px', width: "50%" }}
                />

                <AladdinHint
                    stepIndex={2}
                    title={intl.formatMessage({ id: 'cd-choose-insurer' })}
                    hint={intl.formatMessage({ id: 'cd-choose-insurer-hint' })}
                    titleTypoProps={{ style: { fontSize: '125%' } }}
                />

                <TableContainer className="policy-category-table info create-demo-policy-table">
                    <Table size="small">
                        <TableHead>
                            <TableRow className="sub-header">
                                <TableCell style={{ fontSize: 14, width: "20%", textAlign: 'center' }}>{intl.formatMessage({ id: 'Type' })}</TableCell>
                                <TableCell style={{ fontSize: 14, width: "40%", textAlign: 'center' }}>{intl.formatMessage({ id: 'Insurer' })}</TableCell>
                                <TableCell style={{ fontSize: 14, width: "35%", textAlign: 'center' }}>{intl.formatMessage({ id: 'pf-plan-name' })}</TableCell>
                                <TableCell style={{ width: "5%" }}>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {policies.map((policy, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        style={{
                                            fontSize: 15,
                                            textAlign: 'center',
                                            borderRight: `3px solid ${SUB_CATEGORY_COLORS[policy.category]}`,
                                            color: SUB_CATEGORY_COLORS[policy.category]
                                        }}
                                    >
                                        {policies[index - 1]?.category !== policy.category && intl.formatMessage({ id: policy.category })}
                                    </TableCell>
                                    <TableCell style={{ fontSize: 14, textAlign: 'center' }}>
                                        {policy.insurer ?
                                            <>
                                                {policy.insurer.insurerId === "Other" && index === currentOtherPolicyIndex ?
                                                    <TextField
                                                        inputRef={otherInsurerRef}
                                                        variant='outlined'
                                                        size='small'
                                                        value={policy.insurer.title[intl.locale]}
                                                        inputProps={{ style: { textAlign: 'center' } }}
                                                        onChange={event => onChangeOtherInsurerName(event, index)}
                                                        onBlur={finishTypingOtherInsurer}
                                                        style={{ maxWidth: isWideScreen ? 150 : "90%" }}
                                                    />
                                                    :
                                                    <Typography style={{ marginLeft: 20, whiteSpace: "nowrap" }}>
                                                        {policy.insurer.title[intl.locale]}&nbsp;
                                                        <IconButton onClick={() => openSelectInsurerDialog(index)}>
                                                            <EditIcon color='primary' style={{ fontSize: 20 }} />
                                                        </IconButton>
                                                    </Typography>
                                                }
                                            </>
                                            :
                                            <IconButton onClick={() => openSelectInsurerDialog(index)}>
                                                <EditIcon color='primary' style={{ fontSize: 20, marginRight: 0 }} />
                                            </IconButton>
                                        }
                                    </TableCell>
                                    <TableCell style={{ fontSize: 14, textAlign: 'center' }}>
                                        {policy.insurer?.title.en.trim() ?
                                            <TextField
                                                variant='standard'
                                                size='small'
                                                value={policy.name}
                                                disabled={!policy.insurer}
                                                inputProps={{ style: { textAlign: 'center', marginBottom: 3 } }}
                                                onChange={(event) => onChangePolicyName(event, index)}
                                                error={policy.error}
                                                helperText={policy.error && intl.formatMessage({ id: 'Name is required' })}
                                                FormHelperTextProps={{ style: { fontSize: 12 } }}
                                                style={{ width: isWideScreen ? "90%" : "100%", marginTop: policy.error ? 14 : 0 }}
                                            />
                                            :
                                            <Typography style={{ color: "grey" }}><i>{intl.formatMessage({ id: 'demo-disabled' })}</i></Typography>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {policy.insurer?.title.en && policies[index + 1]?.category !== policy.category &&

                                            <IconButton onClick={() => addPolicy(index, policy.category)}>
                                                <AddCircleOutlineIcon color='primary' style={{ margin: "2px 4px" }} />
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <AladdinHint
                        stepIndex={3}
                        title={intl.formatMessage({ id: 'cd-make-appointments' })}
                        hint={intl.formatMessage({ id: 'cd-make-appointments-hint' })}
                        titleTypoProps={{ style: { fontSize: '125%' } }}
                    />
                    <VoiceCreateSosPaperButton addPoliciesByVoice={addPoliciesByVoice} />
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={createClientAndPolicies}
                    disabled={loading}
                    fullWidth
                    style={{ marginBottom: 25 }}
                >
                    {loading ? <CircularProgress size={24} /> : intl.formatMessage({ id: 'create-demo-cta' })}
                </Button>
            </div >
        </>
    );
};

export default injectIntl(withRouter(CreateDemoClient));
