import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import moment from 'moment';
import { Contacts } from '@capacitor-community/contacts';
import { Button, Checkbox, Paper, TableContainer, Table, TableRow, TableCell, Typography, CircularProgress, Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import IconToast from '../NewToast';
import { importContactsApi, checkPhonesAndEmailsApi } from '../../store/newUser/api';
import { tracking } from '../../utils/tracking';
import "./ImportContactsDialog.css";

const ImportContactsDialog = props => {
  var { intl, userDetails, open, onClose, refreshClients } = props;

  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [existingEmails, setExistingEmails] = useState([]);
  const [existingPhones, setExistingPhones] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);

  useEffect(() => {
    async function loadContacts() {
      setLoading(true);

      let projection = { name: true, organization: true, birthday: true, phones: true, emails: true, postalAddresses: true };
      let result1 = await Contacts.getContacts({ projection });

      let id = 0;
      let newContacts = [];
      for (let contact of result1.contacts) {
        let name = "";
        let phoneNumber = "";
        let email = "";
        let workLocation = "";
        let homeLocation = "";
        let dob = null;
        let age = "";
        let occupation = "";
        let remark = "";

        if (contact.name) {
          name = contact.name.display;
        }
        if (contact.phones && contact.phones.length > 0) {
          phoneNumber = contact.phones[0].number.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "");
          if (!phoneNumber.startsWith("+") && phoneNumber.length === 8) {
            phoneNumber = "+852" + phoneNumber;
          } else if (!phoneNumber.startsWith("+") && phoneNumber.length === 11) {
            phoneNumber = "+86" + phoneNumber;
          }
        }
        if (contact.emails && contact.emails.length > 0) {
          email = contact.emails[0].address.trim().toLowerCase();
        }
        if (contact.postalAddresses && contact.postalAddresses.length > 0) {
          for (let address of contact.postalAddresses) {
            let formattedAddress = `${address.street || ""} ${address.neighborhood || ""} ${address.city || ""} ${address.region || ""} ${address.country || ""} ${address.postcode || ""}`;
            if (address.type === "work" && (!workLocation || address.isPrimary)) {
              workLocation = formattedAddress;
            } else if (address.type === "home" && (!homeLocation || address.isPrimary)) {
              homeLocation = formattedAddress
            }
          }
        }
        if (contact.birthday && contact.birthday.year && contact.birthday.month && contact.birthday.day) {
          dob = moment(`${contact.birthday.year}-${contact.birthday.month}-${contact.birthday.day}`, "YYYY-MM-DD").toISOString();
          age = Math.floor(moment().diff(moment(dob), 'years'));
        }
        if (contact.organization?.company || contact.organization?.jobTitle || contact.organization?.department) {
          occupation = `${contact.organization.company || ""} ${contact.organization.department || ""} ${contact.organization.jobTitle || ""}`;
        }
        if (contact.note) {
          remark = contact.note;
        }

        if (name && phoneNumber) {
          newContacts.push({ id, name, phoneNumber, email, workLocation, homeLocation, dob, age, occupation, remark });
          id += 1;
        }
      }

      let result2 = await checkPhonesAndEmailsApi(newContacts);
      if (result2.success) {
        setExistingEmails(result2.existingEmails);
        setExistingPhones(result2.existingPhones);
        setContacts(newContacts);
        setSelectedContacts(newContacts.filter(contact => !result2.existingEmails.includes(contact.email)).filter(contact => !result2.existingPhones.includes(contact.phoneNumber)));
      }

      setLoading(false);
    }

    if (open) {
      loadContacts();
    } else {
      setContacts([]);
      setSelectedContacts([]);
    }
  }, [open]);

  async function handleImport() {
    let contact = {
      "advisorId": userDetails._id,
      "clientId": null,
      "connectedAdviserEmail": userDetails.email,
      "nickname": "",
      "existingClient": "",
      "source": "Warm",
      "importance": "",
      "network": intl.formatMessage({ id: 'Imported' }),
      "lastMeetingLocation": "",
      "latestMeetingDate": "",
      "nextMeetingDate": null,
      "msgOpt": false,
      "consent": false,
      "tags": [],
      "usedTags": {
        "old_data": [],
        "new_data": []
      },
      "personality": "",
      "referrerName": "",
      "isRecruitment": false,
      "isImported": true,
      "whatsapp": "",
      "wechatId": "",
      "lineId": "",
      "nationality": "",
      "birthPlace": "",
      "marriageStatus": "",
      "child": "",
      "gender": "",
      "smoker": "",
      "monthlyIncome": null,
      "monthlyExpense": null,
      "liquidAsset": "",
      "debt": "",
      "MPF": "",
      "insurance": "",
      "investment": "",
      "budget": "",
      "interest": "",
      "factsheetRemark": "",
      "enableTracking": "false"
    };

    let data = [];
    selectedContacts.forEach(selectedContact => {
      data.push({
        ...contact,
        name: selectedContact.name,
        phoneNumber: selectedContact.phoneNumber,
        email: existingEmails.includes(selectedContact.email) ? "" : selectedContact.email,
        workLocation: selectedContact.workLocation,
        homeLocation: selectedContact.homeLocation,
        dob: selectedContact.dob,
        age: selectedContact.age,
        occupation: selectedContact.occupation,
        remark: selectedContact.remark
      });
    });

    setImportLoading(true);
    tracking('Click on Import - Import Contact');
    let result = await importContactsApi(data);
    setImportLoading(false);
    if (result.success) {
      toast.info(IconToast('info', intl.formatMessage({ id: 'contacts-import-in-progress' })), { className: 'new-toast' });
    } else {
      toast.error(IconToast('error', intl.formatMessage({ id: 'error-importing-contacts' })), { className: 'new-toast' });
    }
    refreshClients();
    onClose();
  }

  function sortContacts(a, b) {
    let aExisted = existingEmails.includes(a.email) || existingPhones.includes(a.phoneNumber);
    let bExisted = existingEmails.includes(b.email) || existingPhones.includes(b.phoneNumber);
    if (aExisted - bExisted === 0) {
      return a.name.localeCompare(b.name, ["en", "zh-Hant-HK", "zh-Hans-CN"]);
    } else {
      return aExisted - bExisted;
    }
  }

  function filterContacts(contact) {
    let formattedSearchQuery = searchQuery.toLowerCase().trim();
    return contact.name.toLowerCase().includes(formattedSearchQuery) ||
      contact.phoneNumber.toLowerCase().includes(formattedSearchQuery) ||
      contact.email.toLowerCase().includes(formattedSearchQuery);
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>
        {intl.formatMessage({ id: 'import-phone-contacts-title' })}
      </DialogTitle>
      <DialogContent>
        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 16 }}>
          <Typography>{intl.formatMessage({ id: 'import-phone-contacts-description' })}</Typography>
        </Box>
        {loading ?
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={36} />
          </Box>
          :
          <>
            <TextField
              variant="outlined"
              color="primary"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              label={intl.formatMessage({ id: 'search-contacts' })}
              style={{ marginBottom: 16 }}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
              }}
            />
            <TableContainer component={Paper}>
              <Table>
                {contacts && contacts.filter(filterContacts).sort(sortContacts).map((contact) => (
                  <TableRow
                    item
                    key={contact.id}
                    onClick={() => selectedContacts.find(selectedContact => selectedContact.id === contact.id) ?
                      setSelectedContacts(selectedContacts.filter(selectedContact => selectedContact.id !== contact.id)) :
                      setSelectedContacts([...selectedContacts, contact])
                    }
                  >
                    <TableCell>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Typography component="span" style={{ fontWeight: 500, fontSize: 17 }}>{contact.name}</Typography>
                        {(existingPhones.includes(contact.phoneNumber) || existingEmails.includes(contact.email)) &&
                          <Box className='tag'>{intl.formatMessage({ id: 'existing-client' })}</Box>
                        }
                      </Box>
                      <Typography>{intl.formatMessage({ id: 'Phone' }) + intl.formatMessage({ id: ':' }) + contact.phoneNumber.replace("+852", "🇭🇰 ").replace("+86", "🇨🇳 ")}</Typography>
                      {contact.email &&
                        <>
                          <Box>
                            <Typography component='span'>{intl.formatMessage({ id: 'Email' }) + intl.formatMessage({ id: ':' })}</Typography>
                            <Typography component='span' style={{ textDecoration: existingEmails.includes(contact.email) ? "line-through" : undefined }}>{contact.email}</Typography>
                            {existingEmails.includes(contact.email) && <Typography component='span' style={{ color: "grey" }}>{intl.formatMessage({ id: 'email-will-not-be-imported' })}</Typography>}
                          </Box>
                        </>
                      }
                      {/* {contact.occupation && <><br /> <Typography>{intl.formatMessage({ id: 'occupation' }) + intl.formatMessage({ id: ':' }) + contact.occupation}</Typography></>}
                      {contact.dob && <><br /> <Typography>{intl.formatMessage({ id: 'dob' }) + intl.formatMessage({ id: ':' }) + moment(contact.dob).format("DD/MM/YYYY")}</Typography></>}
                      {contact.homeLocation && <><br /> <Typography>{intl.formatMessage({ id: 'home-location' }) + intl.formatMessage({ id: ':' }) + contact.homeLocation}</Typography></>}
                      {contact.workLocation && <><br /> <Typography>{intl.formatMessage({ id: 'work-location' }) + intl.formatMessage({ id: ':' }) + contact.workLocation}</Typography></>}
                      {contact.remark && <><br /> <Typography>{intl.formatMessage({ id: 'remark' }) + intl.formatMessage({ id: ':' }) + contact.remark}</Typography></>} */}
                    </TableCell>
                    <TableCell
                      style={{ paddingLeft: 4, textAlign: "center" }}
                    >
                      <Checkbox
                        checked={!!selectedContacts.find(selectedContact => selectedContact.id === contact.id)}
                        name="selectedContact"
                        color="primary"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </TableContainer>
          </>
        }
      </DialogContent>
      <DialogActions style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {contacts.length === 0 ?
              null :
              selectedContacts.length === contacts.length ?
                <Button
                  color='primary'
                  onClick={() => setSelectedContacts([])}
                >
                  {intl.formatMessage({ id: "deselect-all" })}
                </Button>
                :
                <Button
                  color='primary'
                  onClick={() => setSelectedContacts([...contacts])}
                >
                  {intl.formatMessage({ id: "select-all" })}
                </Button>
            }
            <Typography>{intl.formatMessage({ id: 'count-selected' }, { selected: selectedContacts.length, total: contacts.length })}</Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Button
              color='primary'
              variant='text'
              onClick={onClose}
              disabled={importLoading}
            >
              {intl.formatMessage({ id: "Cancel" })}
            </Button>
            {importLoading ?
              <CircularProgress size={24} />
              :
              <Button
                color='primary'
                variant='contained'
                onClick={handleImport}
                disabled={selectedContacts.length === 0}
              >
                {intl.formatMessage({ id: "import" })}
              </Button>
            }
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(state => ({
  userDetails: state.user.userDetails
}))(ImportContactsDialog);

export default injectIntl(container);
