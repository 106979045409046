import React, { useState, useEffect } from 'react';
import { Button, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import BottomSheetOrDialog from '../BottomSheetOrDialog/BottomSheetOrDialog';
import { getFamiliesApi, addNewFamilyApi, editFamilyNameApi, deleteFamilyApi } from '../../store/newUser/api'

const EditFamilyDialog = props => {
  var { intl, open, closeDialog, selectedFamilies, savedFamilies, setSelectedFamilies, saveFactSheet, refreshData, manageOnly } = props;

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(manageOnly ? "manage" : "edit");
  const [families, setFamilies] = useState([]);
  const [newFamily, setNewFamily] = useState("");
  const [oldFamilyName, setOldFamilyName] = useState("");
  const [newFamilyName, setNewFamilyName] = useState("");
  const [deletingFamily, setDeletingFamily] = useState("");
  const [deleteFamilyDialogOpen, setDeleteFamilyDialogOpen] = useState(false);
  const [addFamilyError, setAddFamilyError] = useState("");
  const [newFamilyNameError, setNewFamilyNameError] = useState("");
  const [pendingRefreshData, setPendingRefreshData] = useState(false);

  function closeDeleteFamilyDialog() {
    setDeleteFamilyDialogOpen(false);
  }

  function closeDialogWithoutSave() {
    if (saveFactSheet) {
      setSelectedFamilies(savedFamilies);
    }
    closeDialog();
  }

  function onChangeAddNewFamily(event) {
    setAddFamilyError("");
    setNewFamily(event.target.value);
  }

  function onChangeNewFamilyName(event) {
    setNewFamilyNameError("");
    setNewFamilyName(event.target.value);
  }

  function onAddFamilyKeyDown(event) {
    if (event.key === 'Enter') {
      addNewFamily();
    }
  }

  function onNewFamilyNameKeyDown(event) {
    if (event.key === 'Enter' && !loading) {
      confirmEditFamilyName();
    }
  }

  function switchMode() {
    if (mode === "edit") {
      setMode("manage");
    } else {
      setMode("edit");
    }
  }

  async function getFamilies() {
    let result = await getFamiliesApi();
    if (result.success) {
      if (result.families) {
        setFamilies(result.families);
      } else {
        setFamilies([]);
      }
    }
  }

  async function addNewFamily() {
    if (families?.length > 50) {
      setAddFamilyError(intl.formatMessage({ id: "family-number-exceeds" }, { count: 50 }));
      return;
    }
    if (!newFamily) {
      setAddFamilyError(intl.formatMessage({ id: "enter-family-name" }));
      return;
    }
    if (families.includes(newFamily)) {
      setAddFamilyError(intl.formatMessage({ id: "family-already-exists" }));
      return;
    }
    let result = await addNewFamilyApi(newFamily);
    if (result.success) {
      setNewFamily("");
      getFamilies();
      setSelectedFamilies([...selectedFamilies, newFamily]);
      setPendingRefreshData(true);
    } else {
      setAddFamilyError(result.message);
    }
  }

  function deleteFamily(family) {
    setDeletingFamily(family);
    setDeleteFamilyDialogOpen(true);
  }

  async function confirmDeleteFamily() {
    setLoading(true);
    let result = await deleteFamilyApi(deletingFamily);
    if (result.success) {
      getFamilies();
      if (selectedFamilies) {
        let newSelectedFamilies = selectedFamilies.filter(f => f !== deletingFamily);
        setSelectedFamilies(newSelectedFamilies);
      }
      if (refreshData) {
        await refreshData(deletingFamily);
      }
      setLoading(false);
      setDeletingFamily("");
      setDeleteFamilyDialogOpen(false);
    }
  }

  async function confirmEditFamilyName() {
    if (!oldFamilyName) {
      setNewFamilyNameError(intl.formatMessage({ id: "select-family-to-edit" }));
      return;
    }
    if (!newFamilyName) {
      setNewFamilyNameError(intl.formatMessage({ id: "enter-family-name" }));
      return;
    }
    if (families.includes(newFamily)) {
      setNewFamilyNameError(intl.formatMessage({ id: "family-already-exists" }));
      return;
    }

    setLoading(true);
    let result = await editFamilyNameApi(oldFamilyName, newFamilyName);
    if (result.success) {
      getFamilies();
      if (selectedFamilies) {
        let newSelectedFamilies = selectedFamilies.filter(f => f !== oldFamilyName);
        newSelectedFamilies.push(newFamilyName);
        setSelectedFamilies(newSelectedFamilies);
      }
      if (refreshData) {
        await refreshData(oldFamilyName, newFamilyName);
      }
      setLoading(false);
      setOldFamilyName("");
      setNewFamilyName("");
    }
  }

  function selectFamilies(family) {
    let newFamilies = selectedFamilies?.includes(family) ? selectedFamilies.filter(f => f !== family) : [...selectedFamilies, family];
    setSelectedFamilies(newFamilies);
  }

  function save() {
    if (saveFactSheet) {
      saveFactSheet(selectedFamilies);
    }
    closeDialog();
  }

  useEffect(() => {
    if (open) {
      getFamilies();
      if (!manageOnly) {
        setMode("edit");
        setNewFamily("🏠 ");
      }
    } else if (pendingRefreshData && refreshData) {
      setPendingRefreshData(false);
      refreshData();
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={deleteFamilyDialogOpen}
        onClose={closeDeleteFamilyDialog}
      >
        <DialogTitle>
          {intl.formatMessage({ id: 'delete-family' })}
        </DialogTitle>
        <DialogContent>
          {intl.formatMessage({ id: 'confirm-delete-family' })}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteFamilyDialog} color="primary">
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button onClick={confirmDeleteFamily} disabled={loading} color="primary">
            {loading ? <CircularProgress size={20} /> : intl.formatMessage({ id: 'Confirm' })}
          </Button>
        </DialogActions>
      </Dialog>

      <BottomSheetOrDialog
        className="policy-review-edit-layout-dialog"
        open={open}
        onClose={closeDialogWithoutSave}
        header={mode === "edit" ? intl.formatMessage({ id: 'edit-family' }) : intl.formatMessage({ id: 'manage-family' })}
        content={
          <>
            {mode === "edit" &&
              <div>
                {
                  selectedFamilies?.map((family, index) => (
                    <span
                      className='familyTag'
                      key={index}
                      style={{ marginBottom: 15 }}
                    >
                      {family}
                      <CloseIcon
                        onClick={() => selectFamilies(family)}
                        style={{
                          verticalAlign: "middle",
                          fontSize: 16,
                          marginLeft: 4,
                          color: "#aaa"
                        }}
                      />
                    </span>
                  ))
                }

                <TextField
                  variant='outlined'
                  label={intl.formatMessage({ id: "add-new-family" })}
                  value={newFamily}
                  onChange={onChangeAddNewFamily}
                  onKeyDown={onAddFamilyKeyDown}
                  InputProps={{
                    endAdornment:
                      <Button
                        color="primary"
                        variant='contained'
                        onClick={addNewFamily}
                      >
                        {intl.formatMessage({ id: 'Add' })}
                      </Button>
                  }}
                />
                {addFamilyError && <div style={{ color: "red", marginTop: 10 }}>{addFamilyError}</div>}

                <br />
                <br />
              </div>
            }

            {mode === "edit" ?
              <Typography variant='h5' style={{ textAlign: "center", marginBottom: 8 }}>
                {intl.formatMessage({ id: "previously-added-families" })}
              </Typography>
              :
              <Typography variant='h5' style={{ textAlign: "center", marginBottom: 8 }}>
                {intl.formatMessage({ id: "edit-family-name-or-delete-families" })}
              </Typography>
            }

            <div style={{ border: "1px solid #e0e0e0", borderRadius: 8, padding: 9 }}>
              {
                families.map((family, index) => (
                  <span
                    className='familyTag'
                    key={index}
                    onClick={mode === "edit" ? () => selectFamilies(family) : null}
                    style={{
                      background: mode === "edit" ?
                        selectedFamilies?.includes(family) ? "#ff570e28" : "white" :
                        family === oldFamilyName ? "#ff570e28" : "white"
                    }}
                  >
                    {family}
                    {mode === "manage" && <EditIcon
                      onClick={() => setOldFamilyName(family)}
                      color='primary'
                      style={{
                        verticalAlign: "middle",
                        fontSize: 12,
                        marginLeft: 6
                      }}
                    />
                    }
                    {mode === "manage" && <CloseIcon
                      onClick={() => deleteFamily(family)}
                      style={{
                        verticalAlign: "middle",
                        fontSize: 16,
                        marginLeft: 4,
                        color: "#aaa"
                      }}
                    />
                    }
                  </span>
                ))
              }

              {mode === "manage" && oldFamilyName &&
                <>
                  <TextField
                    variant='outlined'
                    placeholder={oldFamilyName}
                    value={newFamilyName}
                    onChange={onChangeNewFamilyName}
                    onKeyDown={onNewFamilyNameKeyDown}
                    InputProps={{
                      endAdornment:
                        <Button
                          color="primary"
                          variant='contained'
                          onClick={confirmEditFamilyName}
                          disabled={loading}
                        >
                          {loading ? <CircularProgress size={20} /> : intl.formatMessage({ id: 'Confirm' })}
                        </Button>
                    }}
                    style={{ marginTop: 10 }}
                  />
                  {newFamilyNameError && <div style={{ color: "red", marginTop: 10 }}>{newFamilyNameError}</div>}
                </>
              }
            </div>
          </>
        }
        actions={mode === "edit" && saveFactSheet ?
          [
            <Button
              color='primary'
              onClick={save}
            >
              {intl.formatMessage({ id: "Save" })}
            </Button>
          ] : []
        }
        actionsPrepend={[
          !manageOnly && <Button color="primary" onClick={switchMode}>
            {mode === "edit" ? intl.formatMessage({ id: 'manage-family' }) : intl.formatMessage({ id: 'edit-family' })}
          </Button>
        ]}
        BottomSheetProps={{
          expandOnContentDrag: false,
          disableAutoDismiss: true
        }}
        DialogProps={{
          maxWidth: 'sm',
          fullWidth: true
        }}
      />
    </>
  );
};

export default EditFamilyDialog;
