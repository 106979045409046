import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';

const LANGUAGE_OPTIONS = [
  { code: 'en', text: 'ENG' },
  { code: 'zh-Hant-HK', text: '繁中' },
  { code: 'zh', text: '简中' }
];

const LanguageButtons = props => {
  const { value, onChange, className, ...rest } = props;

  const createOnClickHandler = option => () => onChange({ target: { name: 'language', value: option.code } });

  useEffect(() => {
    if (value) {
      onChange({ target: { name: 'language', value } });
    }
  }, []);

  return (
    <Grid className={`language-buttons${className ? ` ${className}` : ''}`} container justify="flex-start" {...rest}>
      {LANGUAGE_OPTIONS.map(option => (
        <Grid item key={option.code}>
          <Button
            variant="contained"
            color="primary"
            className={`${value === option.code ? 'selected' : ''}`}
            style={{
              backgroundColor: value === option.code ? '#3966f8' : '#fff',
              color: value === option.code ? '#fff' : '#3966f8'
            }}
            onClick={createOnClickHandler(option)}
          >
            {option.text}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default LanguageButtons;
