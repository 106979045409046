import React, { useState, useRef, forwardRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Button, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { updatePolicyReview, updatePolicyReviewInputs } from '../../../store/policyReview/duck';
import { shouldEnableChatGPT } from '../../../utils/user';
import MyToolbar from '../../../components/MyEditor/MyToolbar';
import MyEditor from '../../../components/MyEditor/MyEditor';
import { formatEditorStateValue, getEditorDefaultMessage, getSaveContentState } from '../../../utils/editor';
import _ from 'lodash';
import AlertPopUp from '../../../components/AlertPopup';
import { toggleControl } from '../../../store/control/duck';
import ChatGPTPersonalMessageDrawer from '../../../components/ClientEntry/ChatGPTPresetDrawer/ChatGPTPersonalMessageDrawer/ChatGPTPersonalMessageDrawer';
import { tracking } from '../../../utils/tracking';
import BottomSheetOrDialog from '../../../components/BottomSheetOrDialog/BottomSheetOrDialog';
import './PolicyReviewRemark.scss';

const PolicyReviewRemark = forwardRef((props, ref) => {
  const {
    intl,
    open,
    closeDialog,
    userDetails,
    viewingClient,
    teamCampaignCodes,
    selectLanguage,
    remark,
    policyReview,
    loading,
    updatePolicyReviewInputs,
    updatePolicyReview,
    toggleControl,
    userSubscription
  } = props;

  const toolbarRef = useRef(null);
  const editorRef = useRef(null);

  const [defaultRemarkDialog, setDefaultRemarkDialog] = useState(false);

  const showChatGPT = shouldEnableChatGPT({
    user: userDetails,
    teamCampaignCodes: teamCampaignCodes,
    userSubscription: userSubscription
  });

  const saveRemark = () => {
    const viewingClientId = _.get(viewingClient, '_id');
    if (viewingClientId) {
      updatePolicyReview(viewingClientId, { remark: getSaveContentState(remark) });
    }
    closeDialog();
  };
  const changeDefaultRemark = () => {
    updatePolicyReviewInputs(
      'remark',
      formatEditorStateValue(getEditorDefaultMessage(selectLanguage, 'policy-review-title'))
    );
    setDefaultRemarkDialog(false);
  };
  const openDefaultRemarkDialog = () => setDefaultRemarkDialog(true);
  const closeDefaultRemarkDialog = () => setDefaultRemarkDialog(false);

  const onChangeTitle = editorState => {
    updatePolicyReviewInputs('remark', editorState);
  };

  const chatGPT = event => {
    event.stopPropagation();
    tracking('Click on ChatGPT - Policy Review');
    toggleControl('chatGPTPersonalMessageDrawer', { client: viewingClient });
  };

  useEffect(() => {
    const clientRemark = _.get(policyReview, 'remark');
    updatePolicyReviewInputs(
      'remark',
      clientRemark
        ? formatEditorStateValue(clientRemark)
        : formatEditorStateValue(getEditorDefaultMessage(selectLanguage, 'policy-review-title'))
    );
  }, [selectLanguage, updatePolicyReviewInputs, policyReview]);

  return (
    <BottomSheetOrDialog
      className="policy-review-remark-dialog"
      open={open}
      onClose={closeDialog}
      header={intl.formatMessage({ id: 'pr-cus-title' })}
      content={
        <div>
          <ChatGPTPersonalMessageDrawer />
          <AlertPopUp
            intl={intl}
            open={defaultRemarkDialog}
            handleClose={closeDefaultRemarkDialog}
            handleUpdateUserProfile={changeDefaultRemark}
            title={intl.formatMessage({ id: 'Confirm' })}
            description={intl.formatMessage({ id: 'adviser_message_delete' })}
            showUpdateButton={true}
          />

          <MyToolbar
            editorState={remark}
            onChange={onChangeTitle}
            ref={toolbarRef}
            editorRef={editorRef}
            undo={true}
            textAlign={true}
            list={true}
          />

          <MyEditor
            wrapperClassName="export-remark-input"
            editorState={remark}
            textAlignment="center"
            onChange={onChangeTitle}
            ref={editorRef}
            toolbarRef={toolbarRef}
          />
        </div>
      }
      actions={[
        <Button color="primary" onClick={saveRemark} disabled={!viewingClient || loading}>
          {intl.formatMessage({ id: 'Save' })}
        </Button>
      ]}
      actionsPrepend={[
        showChatGPT && (
          <Button color="primary" onClick={chatGPT}>
            <img src="img/icons/ai.svg" alt="A.I. Pro" width={22} height={22} />
          </Button>
        ),
        <Button color="primary" onClick={openDefaultRemarkDialog} disabled={loading}>
          {intl.formatMessage({ id: 'Default Message' })}
        </Button>
      ]}
      BottomSheetProps={{
        expandOnContentDrag: false,
        disableAutoDismiss: true
      }}
    />
  );
});

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    viewingClient: state.client.viewingClient,
    teamCampaignCodes: state.functions.campaignCodes,
    userSubscription: state.userSubscription.userSubscription,
    ...state.policyReview
  }),
  {
    updatePolicyReviewInputs,
    updatePolicyReview,
    toggleControl
  },
  null,
  {
    forwardRef: true
  }
)(PolicyReviewRemark);

export default injectIntl(container, { withRef: true });
