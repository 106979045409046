import { Button, Checkbox, FormControlLabel, TextField, Typography, CircularProgress } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { Fragment, useState, useEffect } from 'react';
import { PhoneNumberInputWrapper } from './PhoneNumberInputWrapper';
import './RegisterDetails.scss';
import Grid from '@material-ui/core/Grid';
import { SignInHomePageGrid } from '../../ChooseUserType/component';
import YellowHint from '../../../components/YellowHint/YellowHint';
import { checkEmail } from '../../../store/user/api';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';
import LanguageButtons from '../../../components/LanguageButtons/LanguageButtons';
import TermsCheckbox from '../../../components/TermsCheckbox/TermsCheckbox';
import DirectMarketingCheckbox from '../../../components/DirectMarketingCheckbox/DirectMarketingCheckbox';
import { validateEmailBackend } from '../../../utils/validators';
import PasswordRulesInfoTooltip from '../../../components/PasswordRulesInfoTooltip/PasswordRulesInfoTooltip';
import { ga4Tracking } from '../../../utils/tracking';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

const RegisterDetails = props => {
  const {
    intl,
    onLogin,
    yellowHint,
    language,
    name,
    email,
    emailDisabled,
    workEmail,
    phoneNumber,
    password,
    confirmPassword,
    tacChecked,
    dmChecked,
    errors,
    handleValidate,
    handleChange,
    handleCheck,
    handleCreate,
    handleEmailUsed,
    loading,
    classes,
    campaignCode,
    campaignCodeDisabled,
    handleEmailAccepted,
    setSkipLogin
  } = props;

  const [page, setPage] = useState(0);
  const [passwordRulesInfoOpen, setPasswordRulesInfoOpen] = useState(false);
  const [confirmPasswordRulesInfoOpen, setConfirmPasswordRulesInfoOpen] = useState(false);

  useEffect(() => {
    setPage(0);

    return () => {
      setPage(0);
    };
  }, []);

  const next = async () => {
    if (handleValidate()) {
      const emailValidationResult = await validateEmailBackend(email);
      if (!emailValidationResult) {
        setSkipLogin(false);
        setPage(1);
        handleEmailAccepted?.();
      } else {
        if (emailValidationResult === 'existing-user') {
          handleEmailUsed('user');
        } else if (emailValidationResult === 'existing-adviser') {
          handleEmailUsed('adviser');
        } else {
          ga4Tracking('email_check', {
            email: email,
            status: 'rejected',
            err: emailValidationResult,
            err_msg: 'This Email is invalid'
          });
          toast.info(IconToast('error', intl.formatMessage({ id: 'invalid-email-address' })), {
            className: 'new-toast'
          });
        }
      }
    }
  };

  const onFocusPassword = () => {
    setPasswordRulesInfoOpen(true);
  };

  const onBlurPassword = () => {
    setPasswordRulesInfoOpen(false);
  };

  const onFocusConfirmPassword = () => {
    setConfirmPasswordRulesInfoOpen(true);
  };

  const onBlurConfirmPassword = () => {
    setConfirmPasswordRulesInfoOpen(false);
  };

  return (
    <div className="section register-details">
      <Grid container justify="center" alignItems="center" spacing={1}>
        <Grid item alignItems="center">
          <Typography variant="h4" component="h4" align="center" style={{ display: 'flex' }}>
            <BusinessCenterIcon color="primary" style={{ fontSize: 36, marginRight: 5 }} />{' '}
            {intl.formatMessage({ id: 'adviser-register-or-login' })}
          </Typography>
        </Grid>
      </Grid>

      <div style={{ padding: '24px 0' }}>
        {page === 0 && (
          <Fragment>
            <LanguageButtons className="language button-options" value={language} onChange={handleChange} />
            {/*<Grid className="language button-options" container justify="flex-start">*/}
            {/*  {[*/}
            {/*    { code: 'en', text: 'ENG' },*/}
            {/*    { code: 'zh-Hant-HK', text: '繁中' },*/}
            {/*    { code: 'zh', text: '简中' }*/}
            {/*  ].map(option => (*/}
            {/*    <Grid item key={option.code}>*/}
            {/*      <Button*/}
            {/*        variant="contained"*/}
            {/*        color="primary"*/}
            {/*        className={`${language === option.code ? 'selected' : ''}`}*/}
            {/*        onClick={() => handleChange({ target: { name: 'language', value: option.code } })}*/}
            {/*      >*/}
            {/*        {option.text}*/}
            {/*      </Button>*/}
            {/*    </Grid>*/}
            {/*  ))}*/}
            {/*</Grid>*/}

            {yellowHint && (
              <YellowHint>
                <Typography color="textSecondary">{yellowHint}</Typography>
              </YellowHint>
            )}

            <form>
              <TextField
                className="input"
                variant="outlined"
                name="email"
                autoComplete="email"
                label={intl.formatMessage({ id: 'login-email' })}
                error={!!errors?.email}
                helperText={errors?.email ? errors.email : undefined}
                value={email}
                onChange={handleChange}
                required={true}
                disabled={emailDisabled}
                InputProps={{
                  classes: {
                    root: classes.inputBase,
                    input: classes.input
                  }
                }}
              />

              <Typography style={{ color: 'grey', fontSize: 13 }}>
                * {intl.formatMessage({ id: 'email-unchangeable' })}
              </Typography>

              {/* <TextField
                className="input"
                variant="outlined"
                name="workEmail"
                autoComplete="email"
                label={intl.formatMessage({ id: 'work-email-changeable' })}
                error={!!errors?.workEmail}
                helperText={errors?.workEmail ? errors.workEmail : undefined}
                value={workEmail}
                onChange={handleChange}
                InputProps={{
                  classes: {
                    root: classes.inputBase,
                    input: classes.input
                  }
                }}
              /> */}

              {/* <TextField
                className="input"
                variant="outlined"
                name="campaignCode"
                label={intl.formatMessage({ id: 'referral-code' })}
                value={campaignCode}
                disabled={campaignCodeDisabled}
                onChange={handleChange}
                InputProps={{
                  classes: {
                    root: classes.inputBase,
                    input: classes.input
                  }
                }}
              /> */}
            </form>

            <Button className="action" variant="contained" color="primary" fullWidth={true} onClick={next}>
              {intl.formatMessage({ id: 'next-step' })}
            </Button>
          </Fragment>
        )}

        {page === 1 && (
          <Fragment>
            <form>
              <TextField
                className="input"
                variant="outlined"
                name="name"
                autoComplete="name"
                label={intl.formatMessage({ id: 'Name' })}
                error={!!errors?.name}
                helperText={errors?.name ? errors.name : undefined}
                value={name}
                onChange={handleChange}
                required={true}
                InputProps={{
                  classes: {
                    root: classes.inputBase,
                    input: classes.input
                  }
                }}
              />

              <TextField
                className="input phone"
                variant="outlined"
                name="phoneNumber"
                label={intl.formatMessage({ id: 'Phone Number' })}
                error={!!errors?.phoneNumber}
                helperText={errors?.phoneNumber ? errors.phoneNumber : undefined}
                value={phoneNumber}
                onChange={handleChange}
                InputProps={{
                  inputComponent: PhoneNumberInputWrapper,
                  inputProps: {
                    intl: intl
                  },
                  classes: {
                    root: classes.inputBase,
                    input: classes.input
                  }
                }}
                InputLabelProps={{ shrink: true }}
                required={true}
              />

              <PasswordRulesInfoTooltip password={password} open={passwordRulesInfoOpen}>
                <TextField
                  className="input"
                  variant="outlined"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  label={intl.formatMessage({ id: 'Password' })}
                  error={!!errors?.password}
                  helperText={errors?.password ? errors.password : undefined}
                  value={password}
                  onChange={handleChange}
                  required={true}
                  InputProps={{
                    classes: {
                      root: classes.inputBase,
                      input: classes.input
                    }
                  }}
                  onFocus={onFocusPassword}
                  onBlur={onBlurPassword}
                />
              </PasswordRulesInfoTooltip>

              <PasswordRulesInfoTooltip password={confirmPassword} open={confirmPasswordRulesInfoOpen}>
                <TextField
                  className="input"
                  variant="outlined"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  label={intl.formatMessage({ id: 'Confirm Password' })}
                  error={!!errors?.confirmPassword}
                  helperText={errors?.confirmPassword ? errors.confirmPassword : undefined}
                  value={confirmPassword}
                  onChange={handleChange}
                  required={true}
                  InputProps={{
                    classes: {
                      root: classes.inputBase,
                      input: classes.input
                    }
                  }}
                  onFocus={onFocusConfirmPassword}
                  onBlur={onBlurConfirmPassword}
                />
              </PasswordRulesInfoTooltip>
            </form>

            <Button
              id="adviser-sign-up"
              className="action"
              variant="contained"
              color="primary"
              fullWidth={true}
              disabled={loading}
              onClick={handleCreate}
            >
              {loading ? <CircularProgress size={24} /> : intl.formatMessage({ id: 'create-ac' })}
            </Button>

            <TermsCheckbox checked={tacChecked} onChange={handleCheck} error={errors && errors.tacChecked} />

            <DirectMarketingCheckbox checked={dmChecked} onChange={handleCheck} error={errors && errors.dmChecked} />

            {!dmChecked && (
              <Typography color="error" style={{ margin: 0 }}>
                {intl.formatMessage({ id: 'dm-uncheck-warn' })}
              </Typography>
            )}
          </Fragment>
        )}
      </div>

      {/* <SignInHomePageGrid intl={intl} onLogin={onLogin} /> */}
    </div>
  );
};

export default RegisterDetails;
