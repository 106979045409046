import moment from 'moment';
import { AppReview } from '@capawesome/capacitor-app-review';
import { Preferences } from '@capacitor/preferences';
import * as api from '../user/api';
import { UPDATE_SUBSCRIPTIONS } from '../userSubscription/duck';
import { LOCALE_SELECTED } from '../locale/action';
import { ERROR, INIT, LOADING, SUCCESS } from '../../constants/phase';
import { GET_USER_SUCCESS, loginFetch } from '../user/duck';
import NativeOrWeb from '../../utils/native';

/***********************************
 * Action Types
 ***********/
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_ERROR = 'AUTHENTICATE_USER_ERROR';
export const UPDATE_IS_BIOMETRIC_AVAILABLE = 'UPDATE_IS_BIOMETRIC_AVAILABLE';
export const UPDATE_IS_BIOMETRIC_SET = 'UPDATE_IS_BIOMETRIC_SET';
export const RESET_AUTH = 'RESET_AUTH';

/***********************************
 * Initial State
 ***********/
const InitialState = {
  initialAuthDone: false,
  isAuthenticated: false,
  authenticatedUser: null,
  authenticationPhase: INIT,
  authenticationError: null,

  isBiometricAvailable: false,
  isBiometricSet: false
};

/***********************************
 * Reducer
 ***********/
export default function (state = InitialState, action = {}) {
  switch (action.type) {
    case AUTHENTICATE_USER: {
      return {
        ...state,
        authenticationPhase: LOADING,
        authenticationError: null
      };
    }

    case AUTHENTICATE_USER_SUCCESS: {
      NativeOrWeb.saveAccessTokenCookie()
        .then()
        .catch();
      return {
        ...state,
        initialAuthDone: true,
        isAuthenticated: !!action.payload,
        authenticatedUser: action.payload,
        authenticationPhase: SUCCESS,
        authenticationError: null
      };
    }

    case AUTHENTICATE_USER_ERROR: {
      return {
        ...state,
        initialAuthDone: true,
        isAuthenticated: false,
        authenticatedUser: null,
        authenticationPhase: ERROR,
        authenticationError: action.payload
      };
    }

    case UPDATE_IS_BIOMETRIC_AVAILABLE: {
      return {
        ...state,
        isBiometricAvailable: action.payload
      };
    }

    case UPDATE_IS_BIOMETRIC_SET: {
      return {
        ...state,
        isBiometricSet: action.payload
      };
    }

    case RESET_AUTH: {
      localStorage.removeItem('clientId');
      return {
        ...state,
        initialAuthDone: false,
        isAuthenticated: false,
        authenticatedUser: null,
        authenticationPhase: INIT,
        authenticationError: null
      };
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/
export const authenticateUser = () => async (dispatch, getState) => {
  try {
    dispatch({ type: AUTHENTICATE_USER });

    // check biometric authentication
    await checkIsBiometricSet()(dispatch, getState);

    // for native
    const accessToken = await NativeOrWeb.getAccessTokenCookie();

    const payload = await api.authenticateUser(accessToken);

    dispatch({
      type: UPDATE_SUBSCRIPTIONS,
      payload: payload.subscriptions ? payload.subscriptions : []
    });

    if (payload) {
      if (payload.language) {
        dispatch({ type: LOCALE_SELECTED, locale: payload.language });
      }

      if (payload.userType) {
        const isUser = payload.userType === 'User';

        if (isUser) {
          localStorage.removeItem('clientId');
        }

        const showAdviserBlast = isUser;
        const result = await Promise.all([
          loginFetch(payload._id, payload.userType, { state: { showAdviserBlast: showAdviserBlast } })(dispatch, getState),
          api.getUser()
        ]);
        dispatch({ type: GET_USER_SUCCESS, payload: result[1] });
      }
    }

    dispatch({
      type: AUTHENTICATE_USER_SUCCESS,
      payload: payload
    });

    if (NativeOrWeb.isNativePlatform()) {
      let { value: firstLoginDate } = await Preferences.get({ key: 'firstLoginDate' });
      if (firstLoginDate) {
        let { value: reviewRequestedDate } = await Preferences.get({ key: 'reviewRequestedDate' });
        if (moment(firstLoginDate).add(2, "weeks").isBefore(moment()) && (!reviewRequestedDate || moment(reviewRequestedDate).add(2, "months").isBefore(moment()))) {
          try {
            await AppReview.requestReview();
            await Preferences.set({ key: 'reviewRequestedDate', value: moment().format() });
          } catch (e) {
            console.log(e);
          }
        }
      } else {
        await Preferences.set({ key: 'firstLoginDate', value: moment().format() });
      }
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: AUTHENTICATE_USER_ERROR, payload: error.toString() });
    dispatch({ type: UPDATE_SUBSCRIPTIONS, payload: [] });
  }
};

export const checkIsBiometricSet = () => async (dispatch, getState) => {
  const isBiometricAvailable = await NativeOrWeb.isBiometricAvailable();
  dispatch({ type: UPDATE_IS_BIOMETRIC_AVAILABLE, payload: isBiometricAvailable });

  const isBiometricSet = await NativeOrWeb.isBiometricSet();
  dispatch({ type: UPDATE_IS_BIOMETRIC_SET, payload: isBiometricSet });
};

export const updateIsBiometricSet = isBiometricSet => ({
  type: UPDATE_IS_BIOMETRIC_SET,
  payload: isBiometricSet
});

export const resetAuth = () => ({
  type: RESET_AUTH
});
