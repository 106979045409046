import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlProvider } from 'react-intl';
import { Redirect } from 'react-router';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer';
import { Grid, Typography } from '@material-ui/core';
import ReferralCard from '../Referral/partials/ReferralCard/ReferralCard';
import LanguageRadioGroup from '../../../components/LanguageRadioGroup/LanguageRadioGroup';
import { getLocaleMessages } from '../../../utils/locale';
import LocaleMessageExtractor from '../../../components/LocaleMessageExtractor/LocaleMessageExtractor';
import ShareButtonGroup from '../../../components/ShareButtonGroup/ShareButtonGroup';
import _ from 'lodash';
import { createWalletPassLink } from '../../../store/wallet-pass/api';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';
import { usePrevious } from '../../../utils/hooks';
import moment from 'moment';

const { PFPLUS_URL } = process.env;

const EmergencyEWalletShare = props => {
  const { location, intl, userDetails, viewingClient } = props;

  const [link, setLink] = useState('');
  const [locale, setLocale] = useState(intl.locale);
  const [message, setMessage] = useState(
    intl.formatMessage({ id: 'emergency-e-wallet-share-message' }, { link: link })
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const previousViewingClient = usePrevious(viewingClient);

  const { factsheetId } = viewingClient || {};
  const { whatsapp } = factsheetId || {};

  const onChangeLocale = event => setLocale(event.target.value);

  useEffect(() => {
    const getLink = async () => {
      const viewingClientId = _.get(viewingClient, '_id');
      const previousViewingClientId = _.get(previousViewingClient, '_id');
      if (viewingClientId && viewingClientId !== previousViewingClientId) {
        try {
          setLoading(true);

          const walletPassLink = await createWalletPassLink('emergency-reference', {
            clientId: viewingClientId,
            lastUpdate: moment()
              .local()
              .format('D-MMM-YYYY'),
            options: location.state.options
          });
          const { error } = walletPassLink;
          if (error) {
            setError(error.toString());
          } else {
            if (walletPassLink) {
              const { token } = walletPassLink;
              setLink(`${PFPLUS_URL}/web/wallet-pass-link/${token}`);
            } else {
              setError('Error creating link');
            }
          }
        } catch (error) {
          setError(error.toString());
        } finally {
          setLoading(false);
        }
      }
    };

    getLink()
      .then()
      .catch(console.log);
  }, [previousViewingClient, viewingClient, location]);

  if (!viewingClient) {
    return <Redirect to="/client" />;
  }

  return (
    <AppContentContainer>
      {error && (
        <Typography align="center" color="error">
          {error}
        </Typography>
      )}

      {loading && <LoadingBackdrop open={loading} />}

      {!error && !loading && (
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems="center"
          justify="center"
          style={{ paddingBottom: 8 }}
        >
          <Grid item style={{ width: '100%' }}>
            <ReferralCard
              details={userDetails}
              link={link}
              customCTAMessage={intl.formatMessage({ id: 'emergency-e-wallet-share-add-to-wallet-cta' })}
            />
          </Grid>
          <Grid item>
            <Typography style={{ fontWeight: 500 }}>{intl.formatMessage({ id: 'referral-division-hint' })}</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item style={{ width: '100%' }}>
                <LanguageRadioGroup value={locale} onChange={onChangeLocale} />
              </Grid>

              <Grid item style={{ width: '100%' }}>
                <IntlProvider
                  key={locale}
                  locale={locale}
                  defaultLocale={locale}
                  messages={getLocaleMessages(locale).messages}
                >
                  <LocaleMessageExtractor
                    locale={locale}
                    id="emergency-e-wallet-share-message"
                    extras={{ link: link }}
                    onChange={setMessage}
                  />
                </IntlProvider>
              </Grid>

              <Grid item style={{ width: '100%', padding: '0 16px' }}>
                <ShareButtonGroup
                  title={message}
                  url={link}
                  isCTA
                  phone={whatsapp}
                  CopyTextFieldProps={{
                    multiline: true,
                    rows: 2
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </AppContentContainer>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    viewingClient: state.client.viewingClient
  }),
  {}
)(EmergencyEWalletShare);

export default injectIntl(container);
