import React, { Fragment, useState } from 'react';
import BottomSheetOrDialog from '../BottomSheetOrDialog/BottomSheetOrDialog';
import { TextField, Typography, Box, Button } from '@material-ui/core';
import { injectIntl } from 'react-intl';

/**
 * PopupTextField component props.
 *
 * @typedef {Object} PopupTextFieldProps
 * @property {string} [label="新增標籤"] - The label for the popup.
 * @property {string} [placeholder="Enter text"] - The placeholder text for the input field.
 * @property {boolean} [open=false] - Whether the popup is open or not.
 * @property {function} onClose - Callback function to handle the close event.
 */

// max === 16

export function PopupTextField({
  label,
  placeholder,
  open = false,
  onClose,
  onConfirm,
  onCancel,
  resetOnClose = true,
  autoFocus = true,
  ...props
}) {
  const [value, setValue] = useState('');
  const { intl } = props;

  const handleValueChange = e => {
    setValue(e.target.value);
  };
  const handleClose = () => {
    const v = value;
    onClose?.(v);
    if (resetOnClose) {
      setTimeout(() => {
        setValue('');
      }, 100);
    }
  };
  // 3eeef8
  return (
    <BottomSheetOrDialog
      open={open}
      onClose={handleClose}
      header={label || intl.formatMessage({ id: 'mda-add-custom-tag-title' })}
      content={
        <TextField
          onKeyPress={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onConfirm?.(value);
              handleClose();
            }
          }}
          value={value}
          autoFocus={autoFocus}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={handleValueChange}
          placeholder={placeholder || intl.formatMessage({ id: 'mda-add-custom-tag-placeholder' })}
          inputProps={{ maxLength: 16 }}
        />
      }
      actions={[
        <Button
          onClick={() => {
            onConfirm?.(value);
            handleClose();
          }}
          color="primary"
          autoFocus
        >
          {intl.formatMessage({ id: 'mda-add-custom-tag-confirm-cta' })}
        </Button>
      ]}
    />
  );
}
// mda-add-custom-tag-confirm-cta
export default injectIntl(PopupTextField);
