import { fetch } from '../../utils';
import Backend from '../../backend';
import { handleJobResponse } from '../../utils/job';

export const openaiGenerateWithTextPolling = async (
  { model, messages, temperature, topP, frequencyPenalty, presencePenalty },
  options
) => {
  const { pollTimeout } = options || {};
  const response = await handleJobResponse(
    fetch(Backend.methodUrl('openaiGenerateWithTextPolling'), {
      method: 'POST',
      body: JSON.stringify({ model, messages, temperature, topP, frequencyPenalty, presencePenalty }),
      headers: {
        'Content-Type': 'application/json'
      }
    }),
    {
      pollTimeout: pollTimeout || 7000
    }
  );

  if (response.error) {
    throw new Error(response.error);
  }

  const { completed, failed } = response;

  const completedTask = completed?.[0];
  const failedTask = failed?.[0];

  if (failedTask) {
    throw new Error(failed.message);
  }

  return completedTask?.extras;
};

export const openaiGenerateWithTextStreaming = async (
  model,
  messages,
  temperature,
  topP,
  frequencyPenalty,
  presencePenalty,
  setChatMessages = null,
  setText = null,
  completeAction = null,
  setLoading = null
) => {
  try {
    await fetch(Backend.methodUrl('openaiGenerateWithTextStreaming'), {
      method: 'POST',
      body: JSON.stringify({ model, messages, temperature, topP, frequencyPenalty, presencePenalty }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let responseText = '';

        function read() {
          reader.read().then(({ done, value }) => {
            if (done) {
              if (completeAction) {
                console.log(responseText)
                completeAction(responseText);
              }
              if (setLoading){
                setLoading(false);
              }
              return;
            }
            const text = decoder.decode(value, { stream: true });
            responseText += text;
            if (setChatMessages) {
              setChatMessages([...messages, { content: responseText, role: 'assistant' }]);
            }
            if (setText) {
              setText(responseText);
            }
            read();
          });
        }

        read();
      })
      .catch(error => {
        console.error('Error streaming data:', error);
        throw error;
      });
  } catch (error) {
    throw error;
  }
};
