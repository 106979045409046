import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer';
import { Button, Container, Grid, Typography, useTheme } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import Dropzone from '../../../components/Dropzone/Dropzone';
import NativeOrWeb from '../../../utils/native';
import { importClients } from '../../../store/import/api';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';
import * as Sentry from '@sentry/browser';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';
import ImportSuccessDialog from './ImportSuccessDialog/ImportSuccessDialog';
import { getClient } from '../../../store/client/duck';
import ImportFailureDialog from './ImportFailureDialog/ImportFailureDialog';
import ImportUnexpectedErrorDialog from './ImportUnexpectedErrorDialog/ImportUnexpectedErrorDialog';
import AladdinHint from '../../../components/AladdinHint/AladdinHint';
import { tracking } from '../../../utils/tracking';
import { getJob, JOB_TYPE } from '../../../store/job/api';

const PULL_JOB_INTERVAL = 5000;

const GridContainerStyle = {
  height: '100%',
  padding: 8
};

const GridItemStyle = {
  width: '100%'
};

const ButtonGridItemStyle = {
  ...GridItemStyle,
  textAlign: 'center'
};

const ButtonStyle = {};

const Import = props => {
  const { history, intl, getClient } = props;
  const theme = useTheme();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [importSuccessDialog, setImportSuccessDialog] = useState(false);
  const [importFailureDialog, setImportFailureDialog] = useState(undefined);
  const [importUnexpectedErrorDialog, setImportUnexpectedErrorDialog] = useState(undefined);
  const [errorFileBuffer, setErrorFileBuffer] = useState(undefined);

  const downloadTemplate = () => {
    NativeOrWeb.downloadFile(
      'https://static.portfoplus.com/import/PFP_Data_Import_Template.xlsx',
      'PFP_Data_Import_Template.xlsx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      {
        fetchIncludeCredentials: false
      }
    )
      .then()
      .catch(console.log);
  };

  const onDropAccepted = files => {
    setFiles(files);
  };

  const importData = async () => {
    tracking('Click on Import - Import Clients');
    try {
      setLoading(true);
      setErrorFileBuffer(undefined);
      const response = await importClients(files);
      const { workbook, error, job } = response || {};

      if (error) {
        throw new Error(error);
      }

      if (job) {
        const importClientJobId = job._id;
        const continueFetchImportClientJobUntilDone = async () => {
          if (!importClientJobId) {
            return;
          }

          const importClientJob = await getJob(JOB_TYPE.IMPORT_CLIENT, importClientJobId);
          if (importClientJob) {
            const { tasks, completed, failed } = importClientJob;
            if (tasks.length === completed.length + failed.length) {
              if (failed.length > 0) {
                throw new Error('ImportError');
              }
              return;
            }
          }

          return new Promise((resolve, reject) => {
            setTimeout(async () => {
              try {
                resolve(await continueFetchImportClientJobUntilDone());
              } catch (error) {
                reject(error);
              }
            }, PULL_JOB_INTERVAL);
          });
        };

        try {
          await continueFetchImportClientJobUntilDone();

          getClient();
          // error workbook returned
          if (workbook) {
            setErrorFileBuffer(new Buffer(workbook.data));
            setImportFailureDialog(true);
          } else {
            setImportSuccessDialog(true);
          }
        } catch (error) {
          if (error.message === 'ImportError') {
            setImportUnexpectedErrorDialog(importClientJobId);
          } else if (error.message === 'Authentication failed') {
          } else {
            throw error;
          }
        }
      } else {
        // error workbook returned
        if (workbook) {
          setErrorFileBuffer(new Buffer(workbook.data));
          setImportFailureDialog(true);
        }
      }
    } catch (error) {
      if (error.message === 'Sheet not found') {
        toast.info(IconToast('error', intl.formatMessage({ id: 'import-sheet-not-found' })), {
          className: 'new-toast'
        });
      } else if (error.message === 'Data not provided') {
        toast.info(IconToast('error', intl.formatMessage({ id: 'import-no-data' })), {
          className: 'new-toast'
        });
      } else {
        console.log(error);
        Sentry.captureException(error);
        toast.info(IconToast('error', intl.formatMessage({ id: 'error-msg' })), { className: 'new-toast' });
      }
    } finally {
      setLoading(false);
    }
  };

  const closeImportSuccessDialog = () => {
    setImportSuccessDialog(false);
    history.push({ pathname: '/client', select: null });
  };

  const closeImportFailureDialog = () => {
    downloadErrorFile();
    setImportFailureDialog(undefined);
    setFiles([]);
  };

  const closeImportUnexpectedErrorDialog = () => {
    setImportUnexpectedErrorDialog(undefined);
    setFiles([]);
  };

  const showImportedClients = () => {
    history.push({ pathname: '/client', select: 'imported' });
  };

  const downloadErrorFile = () => {
    if (errorFileBuffer) {
      NativeOrWeb.downloadFile(
        new Blob([errorFileBuffer]),
        'PFP_Import_Failed_DONT_Import_ME.xlsx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
        .then()
        .catch(console.log);
    }
  };

  return (
    <AppContentContainer style={{ height: 'auto' }}>
      <LoadingBackdrop open={loading} />

      <ImportSuccessDialog open={importSuccessDialog} onClose={closeImportSuccessDialog} />

      <ImportFailureDialog open={!!importFailureDialog} onClose={closeImportFailureDialog} />

      <ImportUnexpectedErrorDialog
        open={!!importUnexpectedErrorDialog}
        refId={importUnexpectedErrorDialog}
        onClose={closeImportUnexpectedErrorDialog}
      />
      <AladdinHint title={intl.formatMessage({ id: 'aladdin-import-client' })} helpCenter="c51" />
      <Container maxWidth="sm">
        <Grid container direction="column" spacing={3} alignItems="center" style={GridContainerStyle}>
          <Grid item style={GridItemStyle}>
            <Grid container direction="column" spacing={2} justify="center" alignItems="center">
              <Grid item style={GridItemStyle}>
                <Typography style={{ whiteSpace: 'pre-wrap' }}>
                  {intl.formatMessage({ id: 'import-desc-0' })}
                </Typography>
              </Grid>
              <Grid item style={GridItemStyle}>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'import-desc-1' })}</Typography>
              </Grid>
              <Grid item style={ButtonGridItemStyle}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<GetAppIcon />}
                  fullWidth={true}
                  onClick={downloadTemplate}
                  style={ButtonStyle}
                >
                  {intl.formatMessage({ id: 'import-dl-template' })}
                </Button>
              </Grid>
              <Grid item style={GridItemStyle}>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'import-desc-2' })}</Typography>
              </Grid>
              <Grid item style={GridItemStyle}>
                <Grid container direction="column" alignItems="center">
                  <Grid item style={{ width: 220 }}>
                    <Dropzone
                      files={files}
                      onDropAccepted={onDropAccepted}
                      accept={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={GridItemStyle}>
                <Typography color="error">{intl.formatMessage({ id: 'import-desc-3' })}</Typography>
              </Grid>
              <Grid item style={ButtonGridItemStyle}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  disabled={!files || files.length === 0}
                  fullWidth={true}
                  onClick={importData}
                  style={ButtonStyle}
                >
                  {intl.formatMessage({ id: 'import-cta' })}
                </Button>
              </Grid>
              {errorFileBuffer && (
                <Grid item style={ButtonGridItemStyle}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    fullWidth={true}
                    onClick={downloadErrorFile}
                    style={{
                      ...ButtonStyle,
                      color: theme.palette.error.main,
                      borderColor: theme.palette.error.main
                    }}
                  >
                    {intl.formatMessage({ id: 'import-dl-error' })}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item style={GridItemStyle}>
            <Grid container direction="column" spacing={2} justify="center" alignItems="center">
              <Grid item style={ButtonGridItemStyle}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth={true}
                  onClick={showImportedClients}
                  style={ButtonStyle}
                >
                  {intl.formatMessage({ id: 'import-show-imported-clients' })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </AppContentContainer>
  );
};

const container = connect(state => ({}), {
  getClient
})(Import);

export default injectIntl(container);
