import mixpanel from 'mixpanel-browser';
// import amplitude from 'amplitude-js';
// import ReactPixel from 'react-facebook-pixel';
import _ from 'lodash';
import NativeOrWeb from './native';
import TagManager from 'react-gtm-module';
import store from '../store';

import * as amplitude from '@amplitude/analytics-browser';
/* 
From JAN 2024, tracking sdk change from amplitude-js/amplitude to @amplitude/analytics-browser
SDK document: https://www.docs.developers.amplitude.com/data/sdks/browser-2/
*/

const voiceRecordTrackingEvent = 'AI: Voice record';
const gptTrackingEvent = 'AI: GPT';

export { voiceRecordTrackingEvent, gptTrackingEvent };

export const tracking = (eventName, properties, toggle) => {
  const platformType = NativeOrWeb.getPlatform() || 'web';
  if (_.get(toggle, 'mixpanel', true) !== false) {
    mixpanel.track(eventName, { platformType: platformType, ...properties });
  }

  if (_.get(toggle, 'amplitude', true) !== false) {
    amplitude.logEvent(eventName, { platformType: platformType, ...properties });
  }
};

export const ga4Tracking = (eventName, eventData) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...eventData
    }
  });
};

export const ga4TrackSupport = props => {
  const { email, userType, phoneNumber, name } = store.getState().user.userDetails;

  return ga4Tracking('support', {
    ...props,
    email: email,
    user_type: userType,
    phone: phoneNumber,
    username: name
  });
};

export const ga4TrackPayment = props => {
  const event = props.event ?? 'payment';
  const { email, userType, phoneNumber, name } = store.getState().user.userDetails;
  const datas = {
    ...props,
    trial_mode: props.trial_mode,
    payment_channel: props.channel,
    payment_status: props.status,
    sales_volumn: props.amount,
    campaign_code: props.campaignCode,
    plan_mode: props.planMode,
    coupon_code: props.couponCode,
    plan_nickname: props.plan_nickname,
    email: email,
    user_type: userType,
    phone: phoneNumber,
    username: name,
    payment_error: props.err,
    payment_error_msg: props.errMessage
  };
  // console.log('payment: ', datas);
  ga4Tracking(event, datas);
};

export const pixelTracking = (eventName, eventData) => {
  // ReactPixel.track(eventName, eventData);
};
