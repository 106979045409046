import React from "react";
import { Box } from "@material-ui/core";
function ProIcon({ showLinkerProIcon /** @type {boolean} */ }) {
  if (!showLinkerProIcon) {
    return <> </>;
  } else {
    return (
      <Box
        style={{
          backgroundColor: "#EFF3FD",
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img src="/img/diamond.svg" style={{ width: "15px" }} />
      </Box>
    );
  }
}
export const LinkerProIcon = ProIcon;
export default LinkerProIcon;
