import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import SwiperCore, { Pagination, Navigation, EffectCoverflow, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';
import Card from '@material-ui/core/Card';
import { toast } from 'react-toastify';
import './AdviserHomePage.scss';
import TopNavHelpButton from '../../components/TopNavHelpButton/TopNavHelpButton';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import IconToast from '../../components/NewToast';
import { tracking } from '../../utils/tracking';
import { withRouter } from 'react-router';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';
import { hasQuickClosingCampaignCode, shouldEnableChatGPT, shouldShowAdviserFirstGuideline } from '../../utils/user';
import AdviserFirstGuidelineTooltip from '../../components/guidelines/AdviserFirstGuidelineTooltip/AdviserFirstGuidelineTooltip';
import { endGuideline, runGuideline } from '../../store/guideline/duck';
import { getNewReferralCount } from '../../store/referral/duck';
import { goToAddClient } from '../../components/ClientEntry/utils';
import DateClientDialog from '../../components/DateClientDialog/DateClientDialog';
import { ADVISER_HOME_PAGE_BANNERS } from '../../constants/adviser-home-page';
import { Button, Container, Grid, IconButton, useMediaQuery } from '@material-ui/core';
import AdviserLoginLogic from '../../components/LoginLogic/AdviserLoginLogic';
import AppContentContainer from '../../components/AppContentContainer/AppContentContainer';
import BusinessToolsSection from './BusinessToolsSection/BusinessToolsSection';
import MoreToolsSection from './MoreToolsSection/MoreToolsSection';
import DashboardSection from './DashboardSection/DashboardSection';
import ShortcutsSection from './ShortcutsSection/ShortcutsSection';
import AdviserSwiperDecoration from './AdviserSwiperDecoration/AdviserSwiperDecoration';
import LinkerNfcCardInstruction from './LinkerNfcCardInstruction';
import { toggleControl } from '../../store/control/duck';
import { TopNavigationPortal } from '../../components/topNavigation';
import TeamBlastPopUp from './TeamBlastPopUp';
import { markNotificationAsRead } from '../../store/notificationCenter/duck';
import VideoDialog from '../../components/VideoPlayer/VideoDialog';
import { updateFirstGuidelineState } from '../../store/user/duck';
import ChatGPTFab from './ChatGPTFab/ChatGPTFab';
import { hasCampaignCode } from '../../utils/user';
import { ShortSubscriptions, UserSubscription } from '../../utils/user-subscription';
import SubscriptionDialog from '../Subscription/SubscriptionDialog/SubscriptionDialog';
import {
  getMyActiveAppointment as getMyActiveAppointmentApi,
  cancelAppointment as cancelAppointmentApi
} from '../../store/appointment/api';
import LinkerAdviserHeader from '../../components/LinkerAdviserHeader/LinkerAdviserHeader';

SwiperCore.use([Pagination, Navigation, EffectCoverflow, Autoplay]);

const FullWidthStyle = {
  width: '100%'
};

const AdviserHomePage = props => {
  const {
    intl,
    history,
    location,
    userDetails,
    userSubscription,
    stripePromise,
    teamCampaignCodes,
    renderHeaderButton,
    runGuideline,
    getNewReferralCount,
    toggleControl,
    endGuideline,
    hasTeamBlast,
    teamBlastPop,
    markNotificationAsRead,
    firstGuidelineRan,
    updateFirstGuidelineState
  } = props;

  const [dateClientDialog, setDateClientDialog] = useState(false);
  const [showTeamBlast, setShowTeamBlast] = useState(false);
  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [linkNfcCardDialogOpen, setLinkNfcCardDialogOpen] = useState(false);
  const narrowLayout = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const showGreeting = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const topNavHelpButtonRef = useRef(null);
  const videoDialogRef = useRef(null);

  const userSubscriptionO = new UserSubscription(userSubscription);
  const isLinkerUser = userSubscriptionO.containVaildLinkerSubscription();
  const isProUser = userSubscriptionO.containVaildProSubscription();

  const currentCoreSubscription = userSubscriptionO.getCurrentCoreSubscription();
  const shouldDisableFunctions = userSubscriptionO.containVaildLinkerSubscription();

  const showChatGPT = shouldEnableChatGPT({ user: userDetails, teamCampaignCodes: teamCampaignCodes, userSubscription: userSubscription });
  const isChubbUser = hasCampaignCode(userDetails, teamCampaignCodes, 'ENTCBB');

  const openSubscriptionDialog = () => {
    setSubscriptionDialogOpen(true);
  };

  const getTopNavHelpButtonInstance = () => {
    const topNavHelpButton = _.get(topNavHelpButtonRef, 'current');
    return topNavHelpButton ? topNavHelpButton.getWrappedInstance() : undefined;
  };

  const openDateClientDialog = () => {
    setDateClientDialog(true);
  };

  const closeDateClientDialog = () => {
    setDateClientDialog(false);
  };

  const onCloseAllDialogs = () => {
    const runAdviserFirstGuideline = () => {
      if (shouldShowAdviserFirstGuideline(userDetails)) {
        setTimeout(() => {
          runGuideline('adviserWelcome', {
            parameters: {
              intl: intl,
              onFinish: () => {
                const topNavHelpButtonInstance = getTopNavHelpButtonInstance();
                if (topNavHelpButtonInstance) {
                  topNavHelpButtonInstance.open();
                }
              }
            },
            tooltipComponent: AdviserFirstGuidelineTooltip
          });
        }, 400);
      }
    };

    if (!firstGuidelineRan && shouldShowAdviserFirstGuideline(userDetails)) {
      updateFirstGuidelineState(true);
      runAdviserFirstGuideline();
    } else {
      toggleControl('addToHomeSnackbar', true);
    }
  };
  const onClickBannerHoldCover = () => {
    tracking('Click on banners - HoldCover');
    history.push('/partnership');
  };
  const onPFPOlympic = () => {
    tracking('Click on banners - Events');
    history.push('/events');
  };

  const onClickBanner0 = () => {
    tracking('Click on banners - Homepage');
    openDateClientDialog();
  };
  const onClickBanner1 = () => {
    tracking('Click on banners - Homepage');
    // history.push('/new_help_center#c38');
    //window.open('http://bit.ly/UpgradeforTeam', '_blank');
    // window.open('https://www.instagram.com/portfoplus/', '_blank');
    // history.push('/nfc-card');
    // window.open('https://bit.ly/pf_seminar', '_blank');
    // history.push('/new_help_center#t04');
    // window.open('https://www.instagram.com/p/CnlW8N7u5_g', '_blank');
    history.push('/hot_topic');
  };
  const onClickBanner2 = event => {
    tracking('Click on banners - Homepage');
    // window.open('http://bit.ly/advisersgroup', '_blank');
    // history.push('/profile-plus');
    // const videoDialogInstance = _.get(videoDialogRef, 'current._wrappedInstance');
    // if (videoDialogInstance) {
    //   videoDialogInstance.open(event, 'https://youtu.be/oqrF2gx9zP8');
    // }
    history.push('/new_help_center');
  };

  const goPremiumFinancing = () => {
    history.push('/premium_financing');
  };

  const markAsRead = data => {
    if (data.receipients.ids.state === 'new') {
      markNotificationAsRead(data._id);
    }
  };

  const getMyActiveAppointment = async () => {
    let result = await getMyActiveAppointmentApi();
    if (result.success && result.data.length >= 1) {
      setAppointment(result.data[0]);
    } else {
      setAppointment(null);
    }
  };

  const cancelAppointment = async () => {
    await cancelAppointmentApi(appointment._id);
    await getMyActiveAppointment();
    toast.info(IconToast('success', intl.formatMessage({ id: 'appointment-cancelled' })), { className: 'new-toast' });
  };

  useEffect(() => {
    renderHeaderButton(true);
  }, [renderHeaderButton]);

  useEffect(() => {
    if (_.get(userDetails, 'userType') === 'Adviser') {
      getNewReferralCount();
    }
  }, [userDetails, getNewReferralCount]);

  useEffect(() => {
    return () => {
      endGuideline();
    };
  }, [endGuideline]);

  useEffect(() => {
    let showTeamBlast = location.state && location.state.showTeamBlast ? location.state.showTeamBlast : false;
    setShowTeamBlast(showTeamBlast);
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getMyActiveAppointment();
  }, []);

  useEffect(() => {
    let refresh = false;
    let state = { ...history.location.state };
    let search = history.location.search;

    if (history.location.state?.appointmentChanged) {
      getMyActiveAppointment();
      state.appointmentChanged = false;
      toast.info(IconToast('success', intl.formatMessage({ id: 'appointment-confirmed' })), { className: 'new-toast' });
      refresh = true;
    }

    if (history.location.search) {
      let searchParams = new URLSearchParams(location.search);
      let upgrade = searchParams.get(`upgrade`);
      if (upgrade === 'true') {
        searchParams.delete('upgrade');
        setSubscriptionDialogOpen(true);
        refresh = true;
      }
      search = searchParams.toString();
    }

    if (refresh) {
      history.replace({ ...history.location, state, search });
    }
  }, [history.location.state, history.location.location]);

  return (
    <AppContentContainer>
      <SubscriptionDialog
        intl={intl}
        userDetails={userDetails}
        stripePromise={stripePromise}
        open={subscriptionDialogOpen}
        onClose={() => setSubscriptionDialogOpen(false)}
        type="upgrade"
        currentSubscription={currentCoreSubscription}
        trialing={false}
        disableCoupon={true}
        defaultCoupon=""
      />

      <AdviserLoginLogic onCloseAllDialogs={onCloseAllDialogs} />

      <VideoDialog
        ref={videoDialogRef}
        appendActions={
          <Button color="primary" onClick={goPremiumFinancing}>
            {intl.formatMessage({ id: 'go-to' })}
          </Button>
        }
      />

      {!shouldDisableFunctions && showChatGPT && <ChatGPTFab />}

      <div className="home-page adviser">
        {!shouldDisableFunctions && (
          <TopNavigationPortal>
            <div className="plus-btn-client dashboard-guideline-icon">
              <TopNavHelpButton ref={topNavHelpButtonRef} buttonProps={{ notClientStyle: true }} />
            </div>
          </TopNavigationPortal>
        )}

        <DateClientDialog open={dateClientDialog} onClose={closeDateClientDialog} />

        <div style={{ position: 'relative' }}>
          {!narrowLayout && <AdviserSwiperDecoration />}

          <Container maxWidth="sm" style={{ padding: 0 }}>
            {!isLinkerUser && (
              <Swiper
                spaceBetween={15}
                centeredSlides={true}
                centeredSlidesBounds={true}
                autoHeight={true}
                initialSlide={0}
                slidesPerView={narrowLayout ? 1.15 : 1.35}
                loop={true}
                loopedSlides={0}
                navigation={false}
                pagination={false}
                effect="coverflow"
                coverflowEffect={{
                  rotate: 0,
                  depth: 350,
                  modifier: 2,
                  stretch: 75,
                  slideShadows: false
                }}
                autoplay={{
                  delay: 3000
                }}
              >
                <SwiperSlide>
                  <Card onClick={onClickBanner0}>
                    <img alt="adviser-help" src={ADVISER_HOME_PAGE_BANNERS[0][intl.locale]} />
                  </Card>
                </SwiperSlide>

                {/* {
                    !isChubbUser &&
                    <SwiperSlide>
                      <Card onClick={onClickBannerHoldCover}>
                        <img alt="holdcover" src={ADVISER_HOME_PAGE_BANNERS[3][intl.locale]} />
                      </Card>
                    </SwiperSlide>
                  } */}

                {!shouldDisableFunctions && (
                  <SwiperSlide>
                    <Card onClick={onClickBanner1}>
                      <img alt="lazy-pack" src={ADVISER_HOME_PAGE_BANNERS[1][intl.locale]} />
                    </Card>
                  </SwiperSlide>
                )}

                <SwiperSlide>
                  <Card onClick={onClickBanner2}>
                    <img alt="classroom" src={ADVISER_HOME_PAGE_BANNERS[2][intl.locale]} />
                  </Card>
                </SwiperSlide>
              </Swiper>
            )}
          </Container>
        </div>
        {isLinkerUser && (
          // <AppointmentCard appointment={appointment} cancelAppointment={cancelAppointment} intl={intl} history={history} />}
          <LinkerAdviserHeader
            history={history}
            onUpgrade={openSubscriptionDialog}
            onCardLinkAlertClick={() => setLinkNfcCardDialogOpen(true)}
          />
        )}

        <LinkerNfcCardInstruction
          linkNfcCardDialogOpen={linkNfcCardDialogOpen}
          setLinkNfcCardDialogOpen={setLinkNfcCardDialogOpen}
        />

        <Grid
          container
          direction="column"
          alignItems="flex-start"
          spacing={narrowLayout ? 2 : 3}
          style={{ marginBottom: 60 }}
        >
          <Grid item style={FullWidthStyle}>
            {showGreeting ||
              (!isLinkerUser && (
                <Typography color="textSecondary" style={{ fontSize: '150%' }} component="div">
                  {intl.formatMessage({ id: 'home-greeting' })}
                  <Typography color="primary" display="inline" style={{ fontSize: '100%' }}>
                    {_.get(userDetails, 'name', '')}!
                  </Typography>
                </Typography>
              ))}

            {!shouldDisableFunctions && (
              <Grid container alignItems="center" wrap="nowrap" spacing={2}>
                <Grid item xs={11} sm={7} md={8}>
                  <DashboardSection />
                </Grid>
                <Grid item xs={1} sm={5} md={4} style={{ padding: 2 }}>
                  <ShortcutsSection
                    shouldDisableFunctions={shouldDisableFunctions}
                    openSubscriptionDialog={openSubscriptionDialog}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item style={FullWidthStyle}>
            <BusinessToolsSection disabled={shouldDisableFunctions} actionReplacement={openSubscriptionDialog} />
          </Grid>

          <Grid item style={FullWidthStyle}>
            <MoreToolsSection
              shouldDisableFunctions={shouldDisableFunctions}
              openSubscriptionDialog={openSubscriptionDialog}
              getTopNavHelpButtonInstance={getTopNavHelpButtonInstance}
            />
          </Grid>
        </Grid>
      </div>
      {hasTeamBlast &&
        showTeamBlast &&
        teamBlastPop?.map(e => <TeamBlastPopUp markAsRead={markAsRead} teamBlastPop={e} />)}
    </AppContentContainer>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    newReferralCount: state.referral.newReferralCount,
    hasTeamBlast: state.notificationCenter.hasTeamBlast,
    teamBlastPop: state.notificationCenter.teamBlastPop,
    firstGuidelineRan: state.user.firstGuidelineRan,
    teamCampaignCodes: state.functions.campaignCodes,
    userSubscription: state.userSubscription.userSubscription
  }),
  {
    renderHeaderButton,
    runGuideline,
    getNewReferralCount,
    toggleControl,
    endGuideline,
    markNotificationAsRead,
    updateFirstGuidelineState
  }
)(AdviserHomePage);

export default injectIntl(withRouter(container));
