import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import ChangePasswordView from './partials/changePassword';
import AboutView from './partials/about';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import 'react-phone-number-input/rrui.css';
import 'react-phone-number-input/style.css';
import { FormControlLabel, Grid, makeStyles, Radio, RadioGroup, Switch } from '@material-ui/core';
import _ from 'lodash';
import './styles.scss';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Typography, Checkbox } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { SlideTransition } from '../../components/Transition/Transition';
import { switchLanguage } from '../../utils/locale';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { withStyles } from '@material-ui/core/styles';
import AddCampaignCode from './partials/addCampaignCode';
import AppContentContainer from '../../components/AppContentContainer/AppContentContainer';
import BiometricAuthSwitch from './BiometricAuthSwitch/BiometricAuthSwitch';
import NotificationSwitch from './NotificationSwitch/NotificationSwitch';
import StaySignedInSwitch from './StaySignedInSwitch/StaySignedInSwitch';
import NativeOrWeb from '../../utils/native';
import AndroidAppIconHintDialog from './AndroidAppIconHintDialog/AndroidAppIconHintDialog';
import TempSelect from '../../components/TempSelect/TempSelect';
import { WebAppVersionContext } from '../../contexts/WebAppVersionContext';
import { browserName, fullBrowserVersion, osName, osVersion, mobileVendor, mobileModel } from 'react-device-detect';
import SelectCurrencyDialog from '../AddPolicy/partials/Payment/SelectCurrencyDialog/SelectCurrencyDialog';


const useStyles = makeStyles({
  root: {
    marginRight: -8
  }
});

const AppIconSwitch = props => {
  const classes = useStyles();
  return <Switch classes={classes} {...props} />;
};

class SettingsView extends PureComponent {
  static contextType = WebAppVersionContext;

  constructor(props) {
    super(props);
    this.state = {
      err: {},
      timeZone: '',
      errMessage: '',
      gitLastUpdate: '',
      selectedCurrency: 'HKD',
      languageModal: false,
      timezoneModal: false,
      isUseTeamLogo: false,
      isShareData: false,
      timeZoneChange: false,
      language: '',
      open: false,
      currencyModal: false,
      preferenceModal: false,
      preferenceIndex: 0,
      openOptOutModal: false,
      optOutOfEmail: false,
      openCampaignCode: false,
      appIconChangeable: false,
      appIconChanged: false,
      androidAppIconHintDialog: false,
      buildNumber: ''
    };
    this.closeModal = this.closeModal.bind(this);
    this.handleTimeZone = this.handleTimeZone.bind(this);

    if (props.userSubscription === undefined) {
      props.getUserSubscription();
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    if (name === 'timeZone') {
      this.setState({ timeZoneChange: true, open: false });
    }
    this.setState({ [name]: value });
  }
  async componentDidMount() {
    if (NativeOrWeb.isNativePlatform()) {
      const appInfo = await NativeOrWeb.getAppInfo();
      if (appInfo && appInfo.build) {
        this.setState({ buildNumber: appInfo.build });
      }
    }

    this.props.renderHeaderButton(true);
    this.checkAppIcon()
      .then()
      .catch();
  }

  //To update the language and currency state
  UpdateState = (language, selectedCurrency, optOutOfEmail) => {
    this.setState({ optOutOfEmail, language, selectedCurrency });
  };

  UNSAFE_componentWillMount() {
    //let selectedCurrency = localStorage.getItem('selectedCurrency');
    // this.setState({
    //   selectedCurrency: selectedCurrency ? selectedCurrency : 'HKD'})
    const { userDetails } = this.props;
    let language = _.get(userDetails, 'language', '');
    let selectedCurrency = userDetails && userDetails.currency ? userDetails.currency : 'HKD';
    let optOutOfEmail = userDetails && userDetails.optOutOfEmail ? userDetails.optOutOfEmail : false;
    this.UpdateState(language, selectedCurrency, optOutOfEmail);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.userDetails) {
      let language = _.get(nextProps, 'userDetails.language', '');
      let selectedCurrency = nextProps.userDetails.currency ? nextProps.userDetails.currency : 'HKD';
      let optOutOfEmail = nextProps.userDetails.optOutOfEmail ? nextProps.userDetails.optOutOfEmail : false;
      this.UpdateState(language, selectedCurrency, optOutOfEmail);
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    await this.checkAppIcon(prevProps);
  }

  checkAppIcon = async prevProps => {
    const { userDetails } = this.props;

    const lastCampaignCodes = _.get(prevProps, 'userDetails.campaignCodes', []);
    const thisCampaignCodes = _.get(userDetails, 'campaignCodes', []);
    if (!_.isEqual(thisCampaignCodes, lastCampaignCodes)) {
      const appIconChangeable = await NativeOrWeb.isIconChangeable(thisCampaignCodes);
      if (appIconChangeable) {
        const appIconChanged = await NativeOrWeb.isIconChanged();
        this.setState({ appIconChangeable: appIconChangeable, appIconChanged: appIconChanged });
      } else {
        this.setState({ appIconChangeable: false, appIconChanged: false });
      }
    }
  };

  closeModal() {
    const { userDetails } = this.props;
    this.setState({
      timezoneModal: false,
      err: {},
      languageModal: false,
      timeZone: _.get(userDetails, 'timeZone', '480'),
      language: _.get(userDetails, 'language', ''),
      currencyModal: false,
      selectedCurrency: _.get(userDetails, 'currency', 'HKD'),
      openOptOutModal: false
    });
  }

  onChangeCurrency = currency => {
    this.props.updateUserDetails({ currency });
    this.setState({ currencyModal: false });
  };

  SetLanguageModal() {
    this.setState({ languageModal: true });
  }

  handleSwitchChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  //To update the language
  handleChangeLanguage = () => {
    const { language } = this.state;
    const { updateUserDetails, selectedLocale } = this.props;
    updateUserDetails({ language });
    switchLanguage(selectedLocale, language);
    this.setState({ languageModal: false });
  };

  openTimeZoneModal() {
    this.setState({ timezoneModal: true });
  }

  handleTimeZone() {
    this.props.updateUserDetails({ timeZone: parseInt(this.state.timeZone) });
    this.setState({ timeZoneChange: false, timezoneModal: false, open: false });
  }

  handleCurrencyModal = () => {
    this.setState({ currencyModal: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  closeOptOutModal = () =>
    this.setState({ openOptOutModal: false, optOutOfEmail: this.props.userDetails.optOutOfEmail ? true : false });
  updateOptOut = () => {
    if (this.state.optOutOfEmail !== this.props.userDetails.optOutOfEmail) {
      this.props.updateUserDetails({ optOutOfEmail: this.state.optOutOfEmail });
    }
    this.closeOptOutModal();
  };

  onChangeAppIcon = async () => {
    if (NativeOrWeb.getPlatform() === 'ios') {
      await this.processChangeAppIcon();
    } else {
      this.setState({ androidAppIconHintDialog: true });
    }
  };

  closeAndroidAppIconHintDialog = () => this.setState({ androidAppIconHintDialog: false });

  processChangeAppIcon = async () => {
    const { appIconChanged } = this.state;
    try {
      if (!appIconChanged) {
        await NativeOrWeb.changeAppIcon();
      } else {
        await NativeOrWeb.resetIcon();
      }
      this.setState({ appIconChanged: !appIconChanged });
    } catch (error) {
      console.error(error);
    }
  };

  goExport = () => {
    const { history } = this.props;
    history.push('/adviser-export');
  };

  render() {
    const { intl, userDetails, isBiometricAvailable } = this.props;
    const {
      selectedCurrency,
      language,
      appIconChangeable,
      appIconChanged,
      androidAppIconHintDialog,
      buildNumber
    } = this.state;
    const currentLocation = this.props.location.pathname;
    const timeZone = !this.state.timeZoneChange
      ? _.get(userDetails, 'timeZone', '480')
        ? _.get(userDetails, 'timeZone', '480')
        : '480'
      : this.state.timeZone;
    // let status =
    //   location && location.state && location.state.userLoginDetail && location.state.userLoginDetail.passwordChangeKey
    //     ? location.state.userLoginDetail.passwordChangeKey
    //     : false;
    const platform = NativeOrWeb.getPlatform();
    const mobileString = `${mobileVendor && mobileVendor !== 'none' ? `${mobileVendor} ` : ''}${mobileModel && mobileModel !== 'none' ? mobileModel : ''
      }`;

    return (
      <AppContentContainer>
        <AndroidAppIconHintDialog
          open={androidAppIconHintDialog}
          onClose={this.closeAndroidAppIconHintDialog}
          onConfirm={this.processChangeAppIcon}
        />

        <div className="settings-wrapper">
          {currentLocation === '/settings' && (
            <div className="container-fluid">
              <div className="col-md-12 padding-0">
                <div className="setting__wrapper">
                  <ul>
                    <Link to="/change_password" className="setting-options">
                      <li>
                        {intl.formatMessage({ id: 'Change Password' })} <img alt="" src="img/forwardArrow.png" />
                      </li>
                    </Link>
                    <div className="setting-language">
                      <div className="setting-head-language" onClick={this.SetLanguageModal.bind(this)}>
                        {intl.formatMessage({ id: 'Language' })}
                      </div>
                      <div className="setting-select-language" onClick={this.SetLanguageModal.bind(this)}>
                        {_.get(userDetails, 'language') === 'en'
                          ? 'English'
                          : _.get(userDetails, 'language') === 'zh-Hant-HK'
                            ? '繁體中文'
                            : _.get(userDetails, 'language') === 'zh'
                              ? '简体中文'
                              : ''}
                      </div>
                    </div>
                    <br />
                    <div className="setting-language">
                      <div className="setting-head-language" onClick={this.handleCurrencyModal.bind(this)}>
                        {intl.formatMessage({ id: 'CurrencySetting' })}
                      </div>
                      <div className="setting-select-language" onClick={this.handleCurrencyModal.bind(this)}>
                        {intl.formatMessage({ id: selectedCurrency })}
                      </div>
                    </div>
                    <br />
                    {/* <Link to="/change_timezone" className="setting-options"> */}
                    <div className="setting-language">
                      <div className="setting-head-language" onClick={this.openTimeZoneModal.bind(this)}>
                        {intl.formatMessage({ id: 'Time Zone' })}
                      </div>
                      <div className="setting-select-language" onClick={this.openTimeZoneModal.bind(this)}>
                        {timeZone.toString() === '-720' && <span>GMT-12:00</span>}
                        {timeZone.toString() === '-660' && <span>GMT-11:00</span>}
                        {timeZone.toString() === '-600' && <span>GMT-10:00</span>}
                        {timeZone.toString() === '-540' && <span>GMT-09:00</span>}
                        {timeZone.toString() === '-480' && <span>GMT-08:00</span>}
                        {timeZone.toString() === '-420' && <span>GMT-07:00</span>}
                        {timeZone.toString() === '-360' && <span>GMT-06:00</span>}
                        {timeZone.toString() === '-300' && <span>GMT-05:00</span>}
                        {timeZone.toString() === '-240' && <span>GMT-04:00</span>}
                        {timeZone.toString() === '-210' && <span>GMT-03:30</span>}
                        {timeZone.toString() === '-180' && <span>GMT-03:00</span>}
                        {timeZone.toString() === '-120' && <span>GMT-02:00</span>}
                        {timeZone.toString() === '-60' && <span>GMT-01:00</span>}
                        {timeZone.toString() === '0' && <span>GMT+00:00</span>}
                        {timeZone.toString() === '60' && <span>GMT+01:00</span>}
                        {timeZone.toString() === '120' && <span>GMT+02:00</span>}
                        {timeZone.toString() === '210' && <span>(GMT+03:30)</span>}
                        {timeZone.toString() === '240' && <span>GMT+04:00</span>}
                        {timeZone.toString() === '270' && <span>GMT+04:30</span>}
                        {timeZone.toString() === '300' && <span>GMT+05:00</span>}
                        {timeZone.toString() === '330' && <span>GMT+05:30</span>}
                        {timeZone.toString() === '345' && <span>GMT+05:45</span>}
                        {timeZone.toString() === '360' && <span>GMT+06:00</span>}
                        {timeZone.toString() === '390' && <span>GMT+06:30</span>}
                        {timeZone.toString() === '420' && <span>GMT+07:00</span>}
                        {timeZone.toString() === '480' && <span>GMT+08:00</span>}
                        {timeZone.toString() === '540' && <span>GMT+09:00</span>}
                        {timeZone.toString() === '570' && <span>GMT+09:30</span>}
                        {timeZone.toString() === '600' && <span>GMT+10:00</span>}
                        {timeZone.toString() === '660' && <span>GMT+11:00</span>}
                        {timeZone.toString() === '720' && <span>GMT+12:00</span>}
                        {timeZone.toString() === '780' && <span>GMT+13:00</span>}
                      </div>
                    </div>

                    {/* </Link> */}
                    <br />

                    {userDetails.userType === 'Adviser' && (
                      <>
                        {!NativeOrWeb.isIOSPlatform() &&
                          <div className="setting-language" onClick={() => this.props.history.push('/subscription')}>
                            <div className="setting-head-language">{intl.formatMessage({ id: 'subscription' })}</div>

                            <div className="setting-select-language">
                              <img alt="" src="img/forwardArrow.png" style={{ width: '24px' }} />
                            </div>
                          </div>
                        }
                        <br />
                        <div className="setting-language" onClick={() => this.setState({ openCampaignCode: true })}>
                          <div className="setting-head-language">{intl.formatMessage({ id: 'campaign' })}</div>

                          <div className="setting-select-language">
                            <img alt="" src="img/forwardArrow.png" style={{ width: '24px' }} />
                          </div>
                        </div>
                        <br />
                        <div className="setting-language" onClick={this.goExport}>
                          <div className="setting-head-language">{intl.formatMessage({ id: 'adviser-export' })}</div>

                          <div className="setting-select-language">
                            <img alt="" src="img/forwardArrow.png" style={{ width: '24px' }} />
                          </div>
                        </div>
                      </>
                    )}

                    {userDetails.userType === 'User' && (
                      <div className="setting-language" onClick={() => this.setState({ openOptOutModal: true })}>
                        <div className="setting-head-language">{intl.formatMessage({ id: 'preference-options' })}</div>
                        <div className="setting-select-language">
                          {intl.formatMessage({ id: !userDetails.optOutOfEmail ? 'Yes' : 'No' })}
                        </div>
                      </div>
                    )}


                    {NativeOrWeb.isNativePlatform() &&
                      <Fragment>
                        <br />
                        <div className="setting-language">
                          <div className="setting-head-language">{intl.formatMessage({ id: 'notification' })}</div>
                          <div className="setting-select-language">
                            <NotificationSwitch />
                          </div>
                        </div>
                      </Fragment>
                    }

                    {NativeOrWeb.isNativePlatform() &&
                      <Fragment>
                        <br />
                        <div className="setting-language">
                          <div className="setting-head-language">{intl.formatMessage({ id: 'stay-signed-in' })}</div>
                          <div className="setting-select-language">
                            <StaySignedInSwitch />
                          </div>
                        </div>
                      </Fragment>
                    }

                    {isBiometricAvailable && (
                      <Fragment>
                        <br />
                        <div className="setting-language">
                          <div className="setting-head-language" style={{width: "75%"}}>{
                            NativeOrWeb.isIOSPlatform() ? intl.formatMessage({ id: 'face-id-auth' }) : intl.formatMessage({ id: 'bio-auth' })
                          }</div>
                          <div className="setting-select-language" style={{width: "25%"}}>
                            <BiometricAuthSwitch />
                          </div>
                        </div>
                      </Fragment>
                    )}

                    {appIconChangeable && (
                      <Fragment>
                        <br />
                        <div className="setting-language">
                          <div className="setting-head-language">
                            {intl.formatMessage({ id: 'cap-settings-app-icon' })}
                          </div>
                          <div className="setting-select-language">
                            <AppIconSwitch
                              color="primary"
                              checked={appIconChanged}
                              onChange={this.onChangeAppIcon}
                              style={{ marginRight: -8 }}
                            />
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </ul>
                </div>

                <div className="setting__footer--wrapper">
                  <ul>
                    <br />
                    <div>
                      <div className="setting__footer--option">
                        <Link to="/about">{intl.formatMessage({ id: 'About' })}</Link>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div className="setting__footer--option">
                        <a rel="noopener noreferrer" href="https://www.portfoplus.com/terms.html" target="_blank">
                          {intl.formatMessage({ id: 'Terms of Service' })}
                        </a>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div className="setting__footer--option">
                        <a rel="noopener noreferrer" href="https://www.portfoplus.com/privacy.html" target="_blank">
                          {intl.formatMessage({ id: 'Privacy Policy' })}
                        </a>
                      </div>
                    </div>
                  </ul>
                  <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography color="textSecondary" align="center">
                        {intl.formatMessage({ id: 'Version' }, this.context)}{' '}
                        {platform !== 'web' ? `(${buildNumber})` : null}{' '}
                        {platform === 'ios' ? 'iOS' : _.capitalize(platform)}
                      </Typography>
                      <Typography color="textSecondary" align="center">
                        {osName} {osVersion} {_.capitalize(browserName)[0]} {fullBrowserVersion}
                      </Typography>
                      {mobileVendor !== 'none' && mobileModel !== 'none' && (
                        <Typography color="textSecondary" align="center">
                          {mobileString}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>

              <Dialog
                open={this.state.languageModal}
                onClose={this.closeModal}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                TransitionComponent={SlideTransition}
                fullWidth={true}
              >
                <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Language' })}</DialogTitle>
                <DialogContent>
                  <div className="caseclose-form  caseclose-form-business">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-2 col-xs-2" />
                        <div className="col-md-10 col-xs-10 text-right adv_biz_modal_key">
                          <FormControl className="d-flex-box">
                            <RadioGroup
                              className="language-setting-option"
                              aria-label="inputGroupOption"
                              name="language"
                              value={language.toString()}
                              onChange={this.handleChange.bind(this)}
                            >
                              <FormControlLabel
                                className="text-left"
                                value={'en'}
                                control={<Radio color="primary" />}
                                label="English"
                              />
                              <FormControlLabel
                                className="text-left"
                                value={'zh-Hant-HK'}
                                control={<Radio color="primary" />}
                                label="繁體中文"
                              />
                              <FormControlLabel
                                className="text-left"
                                value={'zh'}
                                control={<Radio color="primary" />}
                                label="简体中文"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeModal} color="primary">
                    {intl.formatMessage({ id: 'Cancel' })}
                  </Button>
                  <Button onClick={this.handleChangeLanguage.bind(this)} color="primary" autoFocus>
                    {intl.formatMessage({ id: 'OK' })}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.timezoneModal}
                onClose={this.closeModal}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                TransitionComponent={SlideTransition}
                fullWidth={true}
              >
                <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Time Zone' })}</DialogTitle>
                <DialogContent>
                  <div className="caseclose-form  caseclose-form-business">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-2 col-xs-2" />
                        <div className="col-md-10 col-xs-10 text-right adv_biz_modal_key">
                          <FormControl>
                            {/* <InputLabel htmlFor="language-select">
                            {intl.formatMessage({ id: 'Time Zone' })}
                          </InputLabel> */}
                            <TempSelect
                              open={this.state.open}
                              onClose={this.handleClose.bind(this)}
                              onOpen={this.handleOpen.bind(this)}
                              value={timeZone.toString()}
                              onChange={this.handleChange.bind(this)}
                              inputProps={{
                                name: 'timeZone',
                                id: 'language-select'
                              }}
                            >
                              <MenuItem value="-720">GMT-12:00</MenuItem>
                              <MenuItem value="-660">GMT-11:00</MenuItem>
                              <MenuItem value="-600">GMT-10:00</MenuItem>
                              <MenuItem value="-540">GMT-09:00</MenuItem>
                              <MenuItem value="-480">GMT-08:00</MenuItem>
                              <MenuItem value="-420">GMT-07:00</MenuItem>
                              <MenuItem value="-360">GMT-06:00</MenuItem>
                              <MenuItem value="-300">GMT-05:00</MenuItem>
                              <MenuItem value="-240">GMT-04:00</MenuItem>
                              <MenuItem value="-210">GMT-03:30</MenuItem>
                              <MenuItem value="-180">GMT-03:00</MenuItem>
                              <MenuItem value="-120">GMT-02:00</MenuItem>
                              <MenuItem value="-60">GMT-01:00</MenuItem>
                              <MenuItem value="0">GMT+00:00</MenuItem>
                              <MenuItem value="60">GMT+01:00</MenuItem>
                              <MenuItem value="120">GMT+02:00</MenuItem>
                              <MenuItem value="210">GMT+03:30</MenuItem>
                              <MenuItem value="240">GMT+04:00</MenuItem>
                              <MenuItem value="270">GMT+04:30</MenuItem>
                              <MenuItem value="300">GMT+05:00</MenuItem>
                              <MenuItem value="330">GMT+05:30</MenuItem>
                              <MenuItem value="345">GMT+05:45</MenuItem>
                              <MenuItem value="360">GMT+06:00</MenuItem>
                              <MenuItem value="390">GMT+06:30</MenuItem>
                              <MenuItem value="420">GMT+07:00</MenuItem>
                              <MenuItem value="480">GMT+08:00</MenuItem>
                              <MenuItem value="540">GMT+09:00</MenuItem>
                              <MenuItem value="570">GMT+09:30</MenuItem>
                              <MenuItem value="600">GMT+10:00</MenuItem>
                              <MenuItem value="660">GMT+11:00</MenuItem>
                              <MenuItem value="720">GMT+12:00</MenuItem>
                              <MenuItem value="780">GMT+13:00</MenuItem>
                            </TempSelect>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeModal} color="primary">
                    {intl.formatMessage({ id: 'Cancel' })}
                  </Button>
                  <Button onClick={this.handleTimeZone.bind(this)} color="primary" autoFocus>
                    {intl.formatMessage({ id: 'OK' })}
                  </Button>
                </DialogActions>
              </Dialog>

              <SelectCurrencyDialog
                open={this.state.currencyModal}
                currency={this.state.selectedCurrency}
                onClose={this.closeModal}
                onSubmit={this.onChangeCurrency}
              />

              {userDetails.userType === 'User' && (
                <Dialog
                  open={this.state.openOptOutModal}
                  onClose={this.closeOptOutModal}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                  TransitionComponent={SlideTransition}
                >
                  <DialogTitle id="alert-dialog-slide-title">
                    {intl.formatMessage({ id: 'preference-options' })}
                  </DialogTitle>
                  <DialogContent>
                    <FormControlLabel
                      className="checkbox"
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                          color="textSecondary"
                          name="optOutOfEmail"
                          checked={!this.state.optOutOfEmail}
                          onChange={() => this.setState({ optOutOfEmail: !this.state.optOutOfEmail })}
                        />
                      }
                      label={
                        <Fragment>
                          <Typography variant="overline" color="textSecondary" style={{ fontSize: 13 }}>
                            {intl.formatMessage({ id: 'register-dm-check' })}{' '}
                          </Typography>
                        </Fragment>
                      }
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.closeOptOutModal} color="primary" autoFocus>
                      {intl.formatMessage({ id: 'Cancel' })}
                    </Button>
                    <Button onClick={this.updateOptOut} color="primary" autoFocus>
                      {intl.formatMessage({ id: 'Submit' })}
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </div>
          )}
          {userDetails.userType === 'Adviser' && (
            <AddCampaignCode
              intl={intl}
              onClose={() => this.setState({ openCampaignCode: false })}
              open={this.state.openCampaignCode}
            />
          )}
          {currentLocation === '/change_password' && <ChangePasswordView {...this.props} />}
          {currentLocation === '/about' && <AboutView {...this.props} />}
        </div>
      </AppContentContainer>
    );
  }
}

const styles = {
  fontSize: {
    fontSize: 15,
    marginLeft: 10
  }
};

export default withStyles(styles)(SettingsView);
