import './index.scss';
import { jsonArrayFormatValidator } from '../../../../utils/json';
import React, { Fragment, useEffect, useState } from 'react';
import ChatGPTPresetDrawer from '../ChatGPTPresetDrawer';
import { connect } from 'react-redux';
import { toggleControl } from '../../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { Grid, FormControl, FormControlLabel, RadioGroup, Radio, TextareaAutosize, Chip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { withRouter } from 'react-router';
import FollowUpButton from '../FollowUpButton/FollowUpButton';
import ActionButton from '../ActionButton/ActionButton';
import { decreaseGPTQuota, handleError, resetGPTQuota, validateGPTQuota } from '../../../../utils/openai';
import LoadingBackdrop from '../../../LoadingBackdrop/LoadingBackdrop';
import ContentBox from '../ContentBox/ContentBox';
import QuotaTypography from '../QuotaTypography/QuotaTypography';
import { getMessageRewritePromp } from '../../../../utils/aiPrompt';
import { vertexGenerateWithText } from '../../../../store/vertex/api';
import './index.scss';
import { trackDirect } from '../../../../store/analytics/api';
import { tracking } from '../../../../utils/tracking';

const ChatGPTRewriteMessage = props => {
  const { intl, chatGPTRewriteMessageDrawer, toggleControl, extraInformation } = props;
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const toneOptions = [
    { value: 'emoji', label: 'gpt-message-rewite-tone-emoji' },
    { value: 'warm', label: 'gpt-message-rewite-tone-warm' },
    { value: 'local', label: 'gpt-message-rewite-tone-local' },
    { value: 'professional', label: 'gpt-message-rewite-tone-professional' },
    { value: 'humble', label: 'gpt-message-rewite-tone-humble' },
    { value: 'sarcastic', label: 'gpt-message-rewite-tone-sarcastic' }
  ];
  const dialetOptions = [
    { value: 'cantonese', label: 'gpt-message-rewite-dialet-cantonese' },
    { value: 'putonghua', label: 'gpt-message-rewite-dialet-putonghua' },
    { value: 'shshanghai', label: 'gpt-message-rewite-dialet-shshanghai' },
    { value: 'sichuan', label: 'gpt-message-rewite-dialet-sichuan' },
    { value: 'beijing', label: 'gpt-message-rewite-dialet-beijing' },
    { value: 'minnan', label: 'gpt-message-rewite-dialet-minnan' },
    { value: 'hakka Chinese', label: 'gpt-message-rewite-dialet-Hakka' },
    { value: 'english', label: 'gpt-message-rewite-dialet-english' },
    { value: 'japanese', label: 'gpt-message-rewite-dialet-japanese' }
  ];
  const [promps, setPromps] = useState({
    client: chatGPTRewriteMessageDrawer?.client,
    dialet: dialetOptions[0].value,
    tone: toneOptions[0].value,
    extra: extraInformation,
    message: chatGPTRewriteMessageDrawer?.message
  });

  const counterEmoji = ['1️⃣', '2️⃣', '3️⃣'];

  useEffect(() => {
    setPromps({
      ...promps,
      client: chatGPTRewriteMessageDrawer.client,
      message: chatGPTRewriteMessageDrawer.message
    });
  }, [chatGPTRewriteMessageDrawer.client, chatGPTRewriteMessageDrawer.message]);

  const [options, setOptions] = useState([]);

  const isOpen = !!chatGPTRewriteMessageDrawer && chatGPTRewriteMessageDrawer.open;

  const onToneChange = event => {
    setPromps({ ...promps, tone: event.target.value });
  };

  const onDialetChange = event => {
    setPromps({ ...promps, dialet: event.target.value });
  };

  const onClose = () => {
    // console.log('chatGPTRewriteMessageDrawer onClose callled');
    toggleControl('chatGPTRewriteMessageDrawer', { open: false });
    setOptions([]);
    // history.push({pathname: history.location.pathname, state: { } });
  };

  const onExited = () => {
    // console.log('chatGPTRewriteMessageDrawer onExit callled');
    toggleControl('chatGPTRewriteMessageDrawer', { open: false });
    setOptions([]);
    // history.push({pathname: history.location.pathname, state: { } });
  };

  const onFeedback = () => {
    toggleControl('chatGPTFeedbackDialog', {
      client: chatGPTRewriteMessageDrawer.client,
      extraContent: options.toString(),
      onSubmit: () => {
        resetGPTQuota();
      }
    });
  };

  const onValue = value => {
    if (chatGPTRewriteMessageDrawer.onValue) chatGPTRewriteMessageDrawer.onValue(value);
    // Close and clear state
    onClose();
    // console.log('chatGPTRewriteMessageDrawer onValue callled', value, " state && value should closed");
  };
  const send = async () => {
    if (!!!promps.message) return;
    const p = getMessageRewritePromp(promps);

    try {
      validateGPTQuota();
      setLoading(true);
      trackDirect('rewritePersonalizedMessage');
      tracking('AI: Rewrite Personalized Message');

      const contents = [
        {
          role: 'user',
          parts: [{ text: p }]
        }
      ];

      const onSetText = text => {
        // console.log('onSetText jsonArrayFormatValidator::', text)
        const json = jsonArrayFormatValidator(text);
        setOptions(json);
      };
      const result = await vertexGenerateWithText('gemini-1.5-flash', contents, 0.8, 1, 2048);
      if (result.success) {
        const data = result.response?.candidates[0]?.content?.parts[0]?.text.replace('```json', '').replace('```', '');
        onSetText(data);
      } else {
        onSetText([]);
      }
      decreaseGPTQuota();
    } catch (error) {
      handleError(error);
    } finally {
      setPromps({ ...promps, details: p });
      setLoading(false);
    }
  };

  return (
    <ChatGPTPresetDrawer open={isOpen} onClose={onClose} onExited={onExited} onFeedback={onFeedback}>
      <LoadingBackdrop open={loading} />
      <Grid container direction="column" spacing={2} style={{ height: '100%' }}>
        <Grid item>{intl.formatMessage({ id: 'gpt-message-rewite-description' })}</Grid>
        <Grid item>
          <TextField
            variant="outlined"
            fullWidth
            defaultValue={promps.message}
            onChange={event => {
              setPromps({ ...promps, message: event.target.value });
            }}
            placeholder={intl.formatMessage({ id: 'gpt-message-rewite-input-placeholder' })}
            error={promps.message === '' || promps.message === undefined}
            multiline
            InputProps={{
              rows: 3
              // step: 100
              // endAdornment: (
              //     <InputAdornment position="end">
              //         <IconButton edge="end" color="primary" onClick={sendUserText} disabled={!userText || loading}>
              //             <SendIcon />
              //         </IconButton>
              //     </InputAdornment>
              // )
            }}
          />
        </Grid>

        <Grid item>
          {intl.formatMessage({ id: 'gpt-message-rewite-targeted-message-tone-title' })}
          <FormControl style={{ padding: '0px 8px' }}>
            <RadioGroup
              row
              aria-label="targeted-message-tone"
              name="targeted-message-tone"
              value={promps.tone}
              onChange={onToneChange}
            >
              {toneOptions.map((option, index) => (
                <FormControlLabel
                  style={{ padding: '0px 2px 0px 0px' }}
                  key={option.value + index}
                  value={option.value}
                  control={<Radio color="primary" style={{ padding: '0px 2px 2px 0px' }} />}
                  label={intl.formatMessage({ id: option.label })}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item>
          {intl.formatMessage({ id: 'gpt-message-rewite-targeted-message-dialet-title' })}
          <FormControl style={{ padding: '0px 8px' }}>
            <RadioGroup
              row
              aria-label="gpt-message-rewite-dialet"
              name="targeted-message-dialet"
              value={promps.dialet}
              onChange={onDialetChange}
            >
              {dialetOptions.map((option, index) => (
                <FormControlLabel
                  style={{ padding: '0px 2px 0px 0px' }}
                  key={option.value + index}
                  value={option.value}
                  control={<Radio color="primary" style={{ padding: '0px 2px 2px 0px' }} />}
                  label={intl.formatMessage({ id: option.label })}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item>
          <Grid container justify="space-between">
            <Grid item style={{ width: '100%' }}>
              <ActionButton fullWidth onClick={send} style={{ width: '100%', padding: 5 }}>
                {intl.formatMessage({ id: 'gpt-message-rewite-generate' })}
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>

        {options.length >= 1 && (
          <Grid item style={{ marginTop: 10 }}>
            {<span style={{ fontSize: 16 }}>{intl.formatMessage({ id: 'choice' })}</span>}
            {options.map((option, index) => (
              <FollowUpButton
                key={index}
                onClick={() => onValue(option)}
                color="primary"
                style={{ color: theme.palette.primary.main, marginLeft: 10 }}
              >
                {intl.formatMessage(
                  { id: 'option-number' },

                  { number: index + 1 }
                )}
              </FollowUpButton>
            ))}
          </Grid>
        )}
        <Grid item style={{ width: '100%' }}>
          <ContentBox
            getHeight={({ innerHeight }) => `calc(${innerHeight}px - 550px - var(--top-padding))`}
            text={
              process.env.NODE_ENV === 'development'
                ? (promps.details ? promps.details + '\n' : '') +
                  (options.length === 0
                    ? ''
                    : options.map((e, index) => `💬 選項 ${counterEmoji[index]} \n${e}\n\n`).join(''))
                : options.length === 0
                ? ''
                : options.map((e, index) => `選項${index + 1}:\n${e}\n\n`).join('')
            }
            // text={promps.detail}
            disableCopy={false}
          />
          <br />
        </Grid>
        <Grid item>
          <Grid container justify="space-between">
            <Grid item></Grid>
            <Grid item>
              <QuotaTypography />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ChatGPTPresetDrawer>
  );
};

const container = connect(
  state => ({
    chatGPTRewriteMessageDrawer: state.control.chatGPTRewriteMessageDrawer
  }),
  {
    toggleControl,
    resetGPTQuota
  }
)(ChatGPTRewriteMessage);

export default injectIntl(container);
