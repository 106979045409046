import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Card, Grid, Typography } from '@material-ui/core';
import './NameCard.scss';
import { getUserInitial, isAdviserType } from '../../../utils/user';
import { withRouter } from 'react-router';
import { THEME_COLOR_PALETTES,getValidThemeColorOption } from '../../../constants/my-details';
import MyDetailsProfilePicture from '../../Advisor/MyDetails/MyDetailsProfile/MyDetailsProfilePicture/MyDetailsProfilePicture';

const CardStyle = {
  padding: 16,
  boxShadow: 'var(--my-shadow)',
  background: '#3966f8',
  color: '#fff',
  cursor: 'pointer'
};

const ContainerStyle = {};

const LeftStyle = {
  maxWidth: 130
};

const RightStyle = {
  maxWidth: 230
};

const AvatarWrapperStyle = {
  marginRight: 16
};

const AvatarStyle = {
  width: 50,
  height: 50,
  fontSize: 25,
  lineHeight: '50px'
};

const NameStyle = {
  fontSize: '125%'
};

const RightContainerStyle = {
  height: '100%'
};

const NameCard = props => {
  const { userDetails, history } = props;

  const name = _.get(userDetails, 'name', '');
  const email = _.get(userDetails, 'email', '');
  const gender = _.get(userDetails, 'gender');
  const avatar = _.get(userDetails, 'avatar');
  const themeColor = getValidThemeColorOption(userDetails);
  const initial = getUserInitial(userDetails);
  const isAdviser = isAdviserType(userDetails);

  const onClick = () => history.push(isAdviser ? '/my-details' : '/personalData');

  return (
    <Card className="name-card" style={CardStyle} onClick={onClick}>
      <Grid container justify="center" alignItems="stretch" style={ContainerStyle}>
        <Grid item style={LeftStyle} className="left">
          <div style={AvatarWrapperStyle}>
            <MyDetailsProfilePicture
              initial={initial}
              src={avatar}
              width={52}
              borderColor={THEME_COLOR_PALETTES[themeColor][1]}
            />
          </div>
        </Grid>
        <Grid item style={RightStyle} className="right">
          <Grid container direction="column" justify="center" alignItems="flex-start" style={RightContainerStyle}>
            <Grid item>
              <Typography style={NameStyle}>{name}</Typography>
            </Grid>
            <Grid item>
              <Typography>{email}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails
  }),
  {}
)(NameCard);

export default injectIntl(withRouter(container));
