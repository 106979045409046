import palette from '../views/App/palette.scss';
import _ from 'lodash';

export const AVATARS = [
  'img/avatar/man1.png',
  'img/avatar/woman1.png',
  'img/avatar/man3.png',
  'img/avatar/woman3.png',
  'female',
  'img/avatar/man4.png',
  'img/avatar/woman6.png',
  'img/avatar/man7.png',
  'img/avatar/woman7.png',
  'male'
];

export const MAIN_LANGUAGES_CHOICES = [
  'zh-Hant-HK',
  'en',
  'zh',
  'kr',
  'jp',
  'th',
  'es',
  'fr',
  'de',
  'it',
  'fuki',
  'hakka',
  'chiuchow',
  'other-chinese',
  'others'
];
export const PERSONALITY_TYPES_CHOICES = [
  'gentle',
  'sporty',
  'family',
  'logical',
  'philosophical',
  'senior',
  'youthful'
];
export const PERSONALITY_WORK_ATTITUDE_CHOICES = [
  'passionate',
  'professional',
  'cautious',
  'perfectionist',
  'responsible',
  'not-hard-sell',
  'high-efficiency'
];
export const PERSONALITY_TREAT_PEOPLE_CHOICES = [
  'approachable',
  'artless',
  'talkative',
  'tidy',
  'smiley',
  'punctual',
  'good-listener'
];
export const PERSONALITY_LIFE_ATTITUDE_CHOICES = [
  'emotional',
  'selfless',
  'hedonistic',
  'lively',
  'buddha-like',
  'low-profile',
  'yolo'
];

export const PERSONALITY_CHOICES = [
  ...PERSONALITY_TYPES_CHOICES,
  ...PERSONALITY_WORK_ATTITUDE_CHOICES,
  ...PERSONALITY_TREAT_PEOPLE_CHOICES,
  ...PERSONALITY_LIFE_ATTITUDE_CHOICES
];

export const SENIORITY_CHOICES = ['1-5', '5-10', '10-20', '>20'];
export const MAIN_PRODUCTS_CHOICES = [
  'life',
  'ci',
  'high-end-medical',
  'vhis',
  'saving',
  'accident',
  'mpf',
  'ilas',
  'car',
  'life-annuity',
  'home',
  'travel',
  'qdap',
  'edu',
  'general',
  'business',
  'coupon-plan',
  'passive-income',
  'u-life'
];
export const MAIN_SERVICES_CHOICES = [
  'children-protection',
  'asset-management',
  'policy-consolidation',
  'claim',
  'medical-card',
  'risk-assessment',
  'family-heritage',
  'orphan-policy',
  'estate-planning',
  'financial-planning',
  'retirement-plan',
  'prem-financing'
];
export const MAIN_PRODUCTS_SERVICES_CHOICES = [...MAIN_PRODUCTS_CHOICES, ...MAIN_SERVICES_CHOICES];
export const MDRT_CHOICES = [
  'mdrt-mdrt',
  'mdrt-cot',
  'mdrt-tot',
  'mdrt-cfp',
  'mdrt-afp',
  'mdrt-cwm',
  'mdrt-cfa',
  'mdrt-cpa',
  'mdrt-frm',
  'mdrt-mba'
];
export const MAIN_PRODUCTS_CHOICES_INTL_MAP = {
  life: 'Life',
  ci: 'Critical Illness',
  'high-end-medical': 'high-end-medical',
  vhis: 'VHIS',
  saving: 'Saving',
  accident: 'Accident',
  mpf: 'MPF',
  ilas: 'ILAS',
  car: 'car-insurance',
  'life-annuity': 'life-annuity',
  home: 'Home',
  travel: 'Travel',
  qdap: 'QDAP',
  edu: 'edu-fund',
  general: 'general-insurance',
  business: 'business-insurance',
  'coupon-plan': 'coupon-plan',
  'passive-income': 'passive-income',
  'u-life': 'u-life'
};

export const HOBBIES_CHOICES = [
  'have-fun',
  'travel',
  'movie',
  'shopping',
  'basketball',
  'football',
  'computer',
  'photography',
  'run-hike',
  'ball-games',
  'water-sports',
  'skiing',
  'wine-tasting',
  'music',
  'video-games',
  'car',
  'binge-watching',
  'investment',
  'karaoke',
  'mahjong',
  'chit-chat',
  'trendy-info',
  'sleeping',
  'work',
  'reading',
  'art',
  'pet',
  'beauty-slim',
  'kids-family',
  'anime',
  'cat-lover',
  'dog-lover',
  'yoga',
  'watch',
  'dance',
  'fitness',
  'lego',
  'hi-fi',
  'handbag'
];
export const THEME_COLOR_CHOICES = ['blue', 'orange', 'violet', 'green', 'earth'];
export const getValidThemeColorOption = (adviserInfo)=>{
  const themeColorFromAdviserInfo = _.get(adviserInfo, 'themeColor' || 'blue');
  const themeColor =THEME_COLOR_CHOICES.includes(themeColorFromAdviserInfo) ?
   themeColorFromAdviserInfo : 'blue';
  return themeColor;
}
export const THEME_STYLE_CHOICES = ['classic', 'tech'];
export const THEME_COLOR_PALETTES = {
  [THEME_COLOR_CHOICES[0]]: [
    palette['bizPageBlue1'],
    palette['bizPageBlue2'],
    palette['bizPageBlue3'],
    palette['bizPageBlue4'],
    palette['bizPageBlue5'],
    palette['bizPageBlue6']
  ],
  [THEME_COLOR_CHOICES[1]]: [
    palette['bizPageOrange1'],
    palette['bizPageOrange2'],
    palette['bizPageOrange3'],
    palette['bizPageOrange4'],
    palette['bizPageOrange5'],
    palette['bizPageOrange6']
  ],
  [THEME_COLOR_CHOICES[2]]: [
    palette['bizPageViolet1'],
    palette['bizPageViolet2'],
    palette['bizPageViolet3'],
    palette['bizPageViolet4'],
    palette['bizPageViolet5'],
    palette['bizPageViolet6']
  ],
  [THEME_COLOR_CHOICES[3]]: [
    palette['bizPageGreen1'],
    palette['bizPageGreen2'],
    palette['bizPageGreen3'],
    palette['bizPageGreen4'],
    palette['bizPageGreen5'],
    palette['bizPageGreen6']
  ],
  [THEME_COLOR_CHOICES[4]]: [
    palette['bizPageEarth1'],
    palette['bizPageEarth2'],
    palette['bizPageEarth3'],
    palette['bizPageEarth4'],
    palette['bizPageEarth5'],
    palette['bizPageEarth6']
  ]
};
export const THEME_COLOR_BACKGROUNDS = {
  [THEME_COLOR_CHOICES[0]]: palette['bizPageBlueBackground'],
  [THEME_COLOR_CHOICES[1]]: palette['bizPageOrangeBackground'],
  [THEME_COLOR_CHOICES[2]]: palette['bizPageVioletBackground'],
  [THEME_COLOR_CHOICES[3]]: palette['bizPageGreenBackground'],
  [THEME_COLOR_CHOICES[4]]: palette['bizPageEarthBackground']
};
export const THEME_COLOR_IMAGES = {
  [THEME_STYLE_CHOICES[0]]: {
    [THEME_COLOR_CHOICES[0]]: {
      preview: 'img/adviser-info-page/blue/other_bottom.svg',
      mainTop: 'img/adviser-info-page/blue/main_top.png',
      mainFooter: 'img/adviser-info-page/blue/main_bottom.svg',
      otherTop: 'img/adviser-info-page/blue/other_top.svg',
      otherBottom: 'img/adviser-info-page/blue/other_bottom.svg'
    },
    [THEME_COLOR_CHOICES[1]]: {
      preview: 'img/adviser-info-page/orange/other_bottom.svg',
      mainTop: 'img/adviser-info-page/orange/main_top.png',
      mainFooter: 'img/adviser-info-page/orange/main_bottom.svg',
      otherTop: 'img/adviser-info-page/orange/other_top.svg',
      otherBottom: 'img/adviser-info-page/orange/other_bottom.svg'
    },
    [THEME_COLOR_CHOICES[2]]: {
      preview: 'img/adviser-info-page/violet/other_bottom.svg',
      mainTop: 'img/adviser-info-page/violet/main_top.png',
      mainFooter: 'img/adviser-info-page/violet/main_bottom.svg',
      otherTop: 'img/adviser-info-page/violet/other_top.svg',
      otherBottom: 'img/adviser-info-page/violet/other_bottom.svg'
    },
    [THEME_COLOR_CHOICES[3]]: {
      preview: 'img/adviser-info-page/green/other_bottom.svg',
      mainTop: 'img/adviser-info-page/green/main_top.png',
      mainFooter: 'img/adviser-info-page/green/main_bottom.svg',
      otherTop: 'img/adviser-info-page/green/other_top.svg',
      otherBottom: 'img/adviser-info-page/green/other_bottom.svg'
    },
    [THEME_COLOR_CHOICES[4]]: {
      preview: 'img/adviser-info-page/earth/other_bottom.svg',
      mainTop: 'img/adviser-info-page/earth/main_top.png',
      mainFooter: 'img/adviser-info-page/earth/main_bottom.svg',
      otherTop: 'img/adviser-info-page/earth/other_top.svg',
      otherBottom: 'img/adviser-info-page/earth/other_bottom.svg'
    }
  },
  [THEME_STYLE_CHOICES[1]]: {
    [THEME_COLOR_CHOICES[0]]: {
      preview: 'img/adviser-info-page/other_card_bottom.png',
      mainTop: 'img/adviser-info-page/main_top_blue.png',
      mainFooter: 'img/adviser-info-page/main_bottom.png',
      otherTop: 'img/adviser-info-page/other_card_top.png',
      otherBottom: 'img/adviser-info-page/other_card_bottom.png'
    },
    [THEME_COLOR_CHOICES[1]]: {
      preview: 'img/adviser-info-page/orange/other_bottom.svg',
      mainTop: 'img/adviser-info-page/orange/main_top.svg',
      mainFooter: 'img/adviser-info-page/orange/main_bottom.svg',
      otherTop: 'img/adviser-info-page/orange/other_top.svg',
      otherBottom: 'img/adviser-info-page/orange/other_bottom.svg'
    },
    [THEME_COLOR_CHOICES[2]]: {
      preview: 'img/adviser-info-page/violet/other_bottom.svg',
      mainTop: 'img/adviser-info-page/violet/main_top.svg',
      mainFooter: 'img/adviser-info-page/violet/main_bottom.svg',
      otherTop: 'img/adviser-info-page/violet/other_top.svg',
      otherBottom: 'img/adviser-info-page/violet/other_bottom.svg'
    },
    [THEME_COLOR_CHOICES[3]]: {
      preview: 'img/adviser-info-page/green/other_bottom.svg',
      mainTop: 'img/adviser-info-page/green/main_top.svg',
      mainFooter: 'img/adviser-info-page/green/main_bottom.svg',
      otherTop: 'img/adviser-info-page/green/other_top.svg',
      otherBottom: 'img/adviser-info-page/green/other_bottom.svg'
    },
    [THEME_COLOR_CHOICES[4]]: {
      preview: 'img/adviser-info-page/earth/other_bottom.svg',
      mainTop: 'img/adviser-info-page/earth/main_top.svg',
      mainFooter: 'img/adviser-info-page/earth/main_bottom.svg',
      otherTop: 'img/adviser-info-page/earth/other_top.svg',
      otherBottom: 'img/adviser-info-page/earth/other_bottom.svg'
    }
  }
};

export const HOBBIES_TAG_STYLE = {
  borderColor: '#3966f899',
  color: '#9E9E9E',
  fontSize: '85%'
};

export const FAMILIAR_PLACES_TAG_STYLE = {
  borderColor: '#5ee09299',
  color: '#9E9E9E',
  fontSize: '85%'
};

export const PERSONALITY_TAGS_TAG_STYLE = {
  borderColor: '#ffaf1999',
  color: '#9E9E9E',
  fontSize: '85%'
};

export const MAIN_PRODUCTS_SERVICES_TAG_STYLE = {
  borderColor: '#3eeef899',
  color: '#9E9E9E',
  fontSize: '85%'
};
