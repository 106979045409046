import React, { Fragment, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import LoadingBackdrop from '../../../LoadingBackdrop/LoadingBackdrop';
import ChatGPTPresetDrawer from '../ChatGPTPresetDrawer';
import { Grid, Radio, RadioGroup, FormControlLabel, FormControl, FormGroup, Checkbox } from '@material-ui/core';
import FollowUpButton from '../FollowUpButton/FollowUpButton';
import { connect } from 'react-redux';
import { toggleControl } from '../../../../store/control/duck';
import { withRouter } from 'react-router';
import { decreaseGPTQuota, handleError, resetGPTQuota, validateGPTQuota } from '../../../../utils/openai';
import { toast } from 'react-toastify';
import {
  getMatchLazyPacksPromptR1,
  getPersonalAnalysisMatchLazyPacksPrompt,
  getPersonalAnalysisMatchLazyPacksPromptWithPiority
} from '../../../../utils/aiPrompt';
import { useForceUpdate } from '../../../../utils/hooks';
import IconToast from '../../../NewToast';
import { vertexGenerateWithText } from '../../../../store/vertex/api';
import ActionButton from '../ActionButton/ActionButton';
import { useTheme } from '@material-ui/core/styles';
import ContentBox from '../ContentBox/ContentBox';
import QuotaTypography from '../QuotaTypography/QuotaTypography';
import { hasCampaignCode } from '../../../../utils/user';
import { trackDirect } from '../../../../store/analytics/api';
import { tracking } from '../../../../utils/tracking';

const ChatGPTPersonalAnalysis = props => {
  const { intl, history, ChatGPTPersonalAnalysis, toggleControl, userDetails, teamCampaignCodes, team } = props;
  const [loading, setLoading] = useState(false);
  const { client, closed } = ChatGPTPersonalAnalysis || {};

  console.log('client: ', client);
  const [language, setLanguage] = useState(
    history.location.state?.language ? history.location.state.language : 'Traditional Chinese'
  );
  const forceUpdate = useForceUpdate();
  const [options, setOptions] = useState(history.location.state?.options ? history.location.state.options : []);

  // const [ageRange, setAgeRange] = useState('');
  const [gender, setGender] = useState('');
  const [lifeStage, setLifeStage] = useState(history.location.state?.lifeStage ? history.location.state.lifeStage : '');
  const [target, setTarget] = useState([]);
  const [interested, setInterested] = useState([]);
  const [characters, setCharacters] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [chatPurpose, setChatPurpose] = useState(
    history.location.state?.chatPurpose ? history.location.state.chatPurpose : ''
  );

  const isOpen = !!ChatGPTPersonalAnalysis && !closed;

  const theme = useTheme();

  const teamShortNames = team.map(team => team.teamShortName);
  const isChubbOrTestTeam =
    teamShortNames.includes('CBB') ||
    teamShortNames.includes('YRTeam') ||
    teamShortNames.includes('CBBTrainer') ||
    teamShortNames.includes('pfplus');
  const isPriorityLazypackEnabled = !!hasCampaignCode(userDetails, teamCampaignCodes, 'FEATURE_CHUBB_AI_LAZYPACK');
  const shouldProvideChubbLazyPack = isChubbOrTestTeam && isPriorityLazypackEnabled;

  const onClose = () => {
    toggleControl('ChatGPTPersonalAnalysis', { ...ChatGPTPersonalAnalysis, closed: true });
  };

  const onExited = () => {
    toggleControl('ChatGPTPersonalAnalysis', false);
  };

  const onFeedback = () => {
    toggleControl('chatGPTFeedbackDialog', {
      client: client,
      extraContent: options.toString(),
      onSubmit: () => {
        resetGPTQuota();
        forceUpdate();
      }
    });
  };

  const getAIPrompt = () => {
    if (client) {
      const promptSetting = {
        client: client,
        lifeStage: lifeStage,
        chatPurpose: chatPurpose,
        adviserLanguage: intl.locale,
        clientLanguage: language,
        // ageRange: ageRange,
        gender: gender,
        target: target,
        interested: interested,
        characters: characters,
        hobbies: hobbies
      };
      // console.log('promptSetting', promptSetting);

      return getMatchLazyPacksPromptR1(promptSetting);
    } else {
      const promptSetting = {
        // ageRange: ageRange,
        gender: gender,
        lifeStage: lifeStage,
        target: target,
        interested: interested,
        characters: characters,
        hobbies: hobbies,
        chatPurpose: chatPurpose,
        adviserLanguage: intl.locale,
        clientLanguage: language
      };

      return shouldProvideChubbLazyPack
        ? getPersonalAnalysisMatchLazyPacksPromptWithPiority(promptSetting)
        : getPersonalAnalysisMatchLazyPacksPrompt(promptSetting);
    }
  };

  const send = async () => {
    try {
      if (!chatPurpose) {
        toast.info(IconToast('error', intl.formatMessage({ id: 'select-chat-purpose' })), { className: 'new-toast' });
        return;
      }

      validateGPTQuota();
      setLoading(true);
      trackDirect('suggestHotTopic');
      tracking('AI: ChatGPT Personal Analysis');

      const fileUri = 'gs://hot-topic/hot-topic-summarize-250331.json';
      const priority_fileUri = 'gs://hot-topic/hot-topic-summary-chubb-250331.json';

      const mimeType = 'text/plain';
      const prompt = getAIPrompt();

      const textPart = { text: prompt };
      const filePart = {
        fileData: { fileUri: shouldProvideChubbLazyPack ? priority_fileUri : fileUri, mimeType: mimeType }
      };
      const promptWithFile = [{ role: 'user', parts: [textPart, filePart] }];

      const result = await vertexGenerateWithText('gemini-1.5-pro', promptWithFile, 0.8, 1, 2048);

      if (result.success) {
        let data = result.response?.candidates[0]?.content?.parts[0]?.text.replace('```json', '').replace('```', '');
        data = JSON.parse(data);
        setOptions(data);
        // console.log('GPT Result: ', data);
      } else {
        setOptions([]);
      }
      decreaseGPTQuota();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const goToLazyPack = (id, message) => {
    history.push({ pathname: history.location.pathname, state: { options, lifeStage, chatPurpose, language } });
    history.push({ pathname: '/hot_topic', hash: `#${id}`, state: { share_message: message, language: language } });
  };

  const text = options
    .map(
      (option, index) =>
        `${intl.formatMessage({ id: 'option-number' }, { number: index + 1 })}\n` +
        `${intl.formatMessage({ id: 'lazy-pack-title' })}: ${option.title}\n` +
        `${intl.formatMessage({ id: 'reason' })}: ${option.reason}\n\n` +
        `${intl.formatMessage({ id: 'personalized-message' })}: ${option.personalized_message}\n`
    )
    .join('\n\n');

  useEffect(() => {
    if (client) {
      setGender(client.factsheetId.gender || '');
    }
  }, [client]);
  return (
    <Fragment>
      <LoadingBackdrop open={loading} />
      <ChatGPTPresetDrawer open={isOpen} onClose={onClose} onExited={onExited} onFeedback={onFeedback}>
        <Grid container direction="column" spacing={2} style={{ height: '100%' }}>
          {/* <Grid item>
            {intl.formatMessage({ id: 'clients-age-range' })} <span style={{ color: 'red' }}>*</span>
            <FormControl>
              <RadioGroup
                row
                aria-label="clients-age-range"
                name="clients-age-range"
                value={ageRange}
                onChange={event => setAgeRange(event.target.value)}
              >
                <FormControlLabel value="18-25" control={<Radio color="primary" />} label="18-25" />
                <FormControlLabel value="31-45" control={<Radio color="primary" />} label="31-45" />
                <FormControlLabel value="46-60" control={<Radio color="primary" />} label="46-60" />
                <FormControlLabel value="60+" control={<Radio color="primary" />} label="60+" />
              </RadioGroup>
            </FormControl>
          </Grid> */}
          <Grid item>
            {intl.formatMessage({ id: 'clients-gender' })} <span style={{ color: 'red' }}>*</span>
            <FormControl>
              <RadioGroup
                row
                aria-label="clients-gender"
                name="clients-gender"
                value={gender}
                onChange={event => setGender(event.target.value)}
              >
                <FormControlLabel
                  value="Male"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'male' })}
                />
                <FormControlLabel
                  value="Female"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'female' })}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            {intl.formatMessage({ id: 'clients-life-stage' })} <span style={{ color: 'red' }}>*</span>
            <FormControl>
              <RadioGroup
                row
                aria-label="clients-life-stage"
                name="clients-life-stage"
                value={lifeStage}
                onChange={event => setLifeStage(event.target.value)}
              >
                <FormControlLabel
                  value="Career Start"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'career-start' })}
                />
                <FormControlLabel
                  value="Career & Family Growth"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'career-and-family-growth' })}
                />
                <FormControlLabel
                  value="Striving for career success"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'striving-for-career-success' })}
                />
                <FormControlLabel
                  value="Family with Children"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'family-with-children' })}
                />
                <FormControlLabel
                  value="Retired"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'retired' })}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            {intl.formatMessage({ id: 'clients-target' })} <span style={{ color: 'red' }}>*</span>
            <FormControl>
              <FormGroup
                row
                aria-label="clients-target"
                name="clients-target"
                value={target}
                onChange={event => {
                  if (event.target.checked) {
                    setTarget([...target, event.target.value]);
                  }
                  if (!event.target.checked) {
                    setTarget(target.filter(value => value !== event.target.value));
                  }
                }}
              >
                <FormControlLabel
                  value="Entertainment Fund"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'entertainment-fund' })}
                />
                <FormControlLabel
                  value="Marriage"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'marriage' })}
                />
                <FormControlLabel
                  value="Property Purchase"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'property-purchase' })}
                />
                <FormControlLabel
                  value="Retired"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'retired' })}
                />
                <FormControlLabel
                  value="Continuing Education"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'continuing-education' })}
                />
                <FormControlLabel
                  value="Entrepreneurship"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'entrepreneurship' })}
                />
                <FormControlLabel
                  value="Children Education"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'children-education' })}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item>
            {intl.formatMessage({ id: 'clients-interested' })} <span style={{ color: 'red' }}>*</span>
            <FormControl>
              <FormGroup
                row
                aria-label="clients-interested"
                name="clients-interested"
                value={interested}
                onChange={event => {
                  if (event.target.checked) {
                    setInterested([...interested, event.target.value]);
                  }
                  if (!event.target.checked) {
                    setInterested(interested.filter(value => value !== event.target.value));
                  }
                }}
              >
                <FormControlLabel
                  value="Life Insurance"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'life-insurance' })}
                />
                <FormControlLabel
                  value="Critical Illness"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'critical-illness' })}
                />
                <FormControlLabel
                  value="Mdeical"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'medical' })}
                />
                <FormControlLabel
                  value="Savings"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'savings' })}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item>
            {intl.formatMessage({ id: 'clients-characters' })}
            <FormControl>
              <FormGroup
                row
                aria-label="clients-characters"
                name="clients-characters"
                value={characters}
                onChange={event => {
                  if (event.target.checked) {
                    setCharacters([...characters, event.target.value]);
                  }
                  if (!event.target.checked) {
                    setCharacters(characters.filter(value => value !== event.target.value));
                  }
                }}
              >
                <FormControlLabel
                  value="Outgoing"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'outgoing' })}
                />
                <FormControlLabel
                  value="Introverted"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'introverted' })}
                />
                <FormControlLabel
                  value="Emotional"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'emotional' })}
                />
                <FormControlLabel
                  value="Rational"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'rational' })}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item>
            {intl.formatMessage({ id: 'clients-hobbies' })}
            <FormControl>
              <FormGroup
                row
                aria-label="clients-hobbies"
                name="clients-hobbies"
                value={hobbies}
                onChange={event => {
                  if (event.target.checked) {
                    setHobbies([...hobbies, event.target.value]);
                  }
                  if (!event.target.checked) {
                    setHobbies(hobbies.filter(value => value !== event.target.value));
                  }
                }}
              >
                <FormControlLabel
                  value="Pets"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'pets' })}
                />
                <FormControlLabel
                  value="Arts"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'arts' })}
                />
                <FormControlLabel
                  value="Sports"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'sports' })}
                />
                <FormControlLabel
                  value="Media"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'media' })}
                />
                <FormControlLabel
                  value="Travel"
                  control={<Checkbox color="primary" />}
                  label={intl.formatMessage({ id: 'travel' })}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item>
            {intl.formatMessage({ id: 'chat-purpose' })} <span style={{ color: 'red' }}>*</span>
            <FormControl>
              <RadioGroup
                row
                aria-label="chat-purpose"
                name="chat-purpose"
                value={chatPurpose}
                onChange={event => setChatPurpose(event.target.value)}
              >
                <FormControlLabel
                  value="Keep Connection"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'keep-connection' })}
                />
                <FormControlLabel
                  value="Product Selling"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'product-selling' })}
                />
                <FormControlLabel
                  value="Instill concepts"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'instill-concepts' })}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <Grid container justify="center">
              <Grid item>
                <RadioGroup
                  row
                  aria-label="language"
                  name="language"
                  value={language}
                  onChange={event => setLanguage(event.target.value)}
                >
                  <FormControlLabel
                    value="Traditional Chinese"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({ id: 'traditional-chinese' })}
                  />
                  <FormControlLabel
                    value="English"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({ id: 'standard-english' })}
                  />
                  <FormControlLabel
                    value="Simplified Chinese"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({ id: 'simplified-chinese' })}
                  />
                </RadioGroup>
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <ActionButton onClick={send} style={{ padding: 5, width: '100%' }}>
                  {intl.formatMessage({ id: 'chat-gpt-options-lazy-packs' })}
                </ActionButton>
              </Grid>
              <Grid item style={{ marginTop: 10 }}>
                {options?.length > 0 && <span style={{ fontSize: 16 }}>{intl.formatMessage({ id: 'choice' })}</span>}
                {options.map((option, index) => (
                  <FollowUpButton
                    key={option.id}
                    onClick={() => goToLazyPack(option.id, option.personalized_message)}
                    color="primary"
                    style={{ color: theme.palette.primary.main, marginLeft: 10 }}
                  >
                    {intl.formatMessage({ id: 'option-number' }, { number: index + 1 })}
                  </FollowUpButton>
                ))}
              </Grid>
            </Grid>
            <Grid item>
              <ContentBox
                getHeight={({ innerHeight }) => `calc(${innerHeight}px - 330px - var(--top-padding))`}
                text={text}
                disableCopy={true}
              />
            </Grid>
            <Grid item>
              <Grid container justify="space-between">
                <Grid item />
                <Grid item>
                  <QuotaTypography />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ChatGPTPresetDrawer>
    </Fragment>
  );
};

const container = connect(
  state => ({
    ChatGPTPersonalAnalysis: state.control.ChatGPTPersonalAnalysis,
    userDetails: state.user.userDetails,
    teamCampaignCodes: state.functions.campaignCodes,
    team: state.team.teams
  }),
  {
    toggleControl
  }
)(ChatGPTPersonalAnalysis);

export default injectIntl(withRouter(container));
