import React, { Fragment, forwardRef } from 'react';
import { getGPTRecommendation, getGPTRecommendationSample } from '../../../../store/vertex/vertex.js';
import LoadingBackdrop from '../../../LoadingBackdrop/LoadingBackdrop.js';
import { handleError } from '../../../../utils/openai.js';


export const GPTProductRecommendationButton = forwardRef(({
      /** @type {()=>void} */ onStart,
      /** @type {()=>void} */ onFinish,
      /** @type {(object[])=>void} */ onComplete,
      /** @type {()=>void} */ onFail,
      /** @type {(bool) => void} */ loadingControl,
      /** @type {object} */ client,
      /** @type {any} */ toggleControl,
      /** @type {bool} */ useErrorHandling = true,
      /** @type {bool} */ useDrawer = true,
      /** @type {bool} */ useLoadingBackdrop = false,
      /** @type {React.ReactNode} */ children,
      /** @type {bool} */ useSampleData = false,
      /** @type {string} */ language
}, ref) => {
  const [loading, setLoading] = React.useState(false);

  const handleCheckAndSend = async () => {
    onStart?.();
    loadingControl?.(true);
    if (useLoadingBackdrop) { setLoading(true); }

    let result;
    try {
      if (useSampleData) {
        result = getGPTRecommendationSample;
      } else {
        result = await getGPTRecommendation(client, 'product_comparison', 'chubb_ci_recommendation_demo.json', language);
      }
      if (useLoadingBackdrop) { setLoading(false); }
      loadingControl?.(false);

      onFinish?.();
      if (result && useDrawer) {
        toggleControl(
          'gptProductRecommendationDrawer', {
          showSendButton: false,
          result: result,
          client: client,
          open: true
        });
      }
    } catch (e) {
      onFail?.();
      loadingControl?.(false);
      if (useLoadingBackdrop) { setLoading(false); }
      if (useErrorHandling) { handleError(e, true) }
      return;
    } finally {
      onComplete?.(result);
    }
  };

  ref.current = { handleCheckAndSend: handleCheckAndSend };
  return (
    <Fragment>
      {useLoadingBackdrop && <LoadingBackdrop open={loading} />}
      {children}
    </Fragment>)
});

