import store from '../../store';
import { ShortSubscriptions } from '../user-subscription';
import { getAccessableRoute } from './getAccessableRoute';

export function checkAccessable(target /** @type {string} */) {
  const _subscriptions = store.getState().userSubscription.subscriptions;
  if (!_subscriptions) return true;
  const _shortSubscriptions = new ShortSubscriptions(_subscriptions);
  if (!_shortSubscriptions || !_shortSubscriptions.shortSubscriptions?.[0]?.nickname) return true;
  if (_shortSubscriptions.containProSubscription()) return true;

  let accessableRoute;
  if (_shortSubscriptions.containLinkerProSubscription()) {
    accessableRoute = getAccessableRoute('LinkerPro');
  } else if (_shortSubscriptions.containLinkerSubscription()) {
    accessableRoute = getAccessableRoute('Linker');
  } else {
    const planName = _shortSubscriptions?.[0]?.nickname;
    accessableRoute = getAccessableRoute(planName || 'DEFAULT');
  }

  const isWildcard = accessableRoute.includes('*');
  const isHomeDir = target === '/' || target === '/home';

  if (isWildcard) return true;
  if (isHomeDir) return true;

  const isAccessable = accessableRoute
    .map(route => {
      if (route instanceof RegExp) {
        return route.test(target);
      } else return route === target;
    })
    ?.includes(true);

  if (!isAccessable) return false;
  return true;
}
