import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    Typography,
    Grid,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    CircularProgress,
    TextField,
    IconButton,
    InputAdornment,
    MenuItem
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PlanDisplay from './component/PlanDisplay';
import { changePlanApi, getCouponApi, cancelPendingSubscriptionChangeApi } from '../../../store/userSubscription/api';

const Change = props => {
    const {
        intl,
        type,
        currentSubscription,
        currentSubscriptionPlan,
        trialing,
        disableCoupon,
        defaultPaymentMethod,
        currentCredits,
        disableExtension,
        amountDueToday,
        amountDueNextBillingPeriod,
        formattedNextBillingDate,
        currentSubscriptionExpiredAt,
        loading,
        setLoading,
        setStep,
        option,
        setOption,
        coupon,
        setCoupon,
        couponId,
        setCouponId,
        couponValue,
        setCouponValue,
        invalidCoupon,
        setInvalidCoupon,
        changeablePlans,
        selectedPlan,
        setSelectedPlan,
        extension,
        newCredits,
        clientSecret,
        setClientSecret,
        setErrorMessage,
        setCompleteTime,
        applyCoupon
    } = props;

    const theme = useTheme();

    async function changePlan(paymentMethodId) {
        setErrorMessage("");
        setLoading(true);

        let couponResult = await getCouponApi(couponId, selectedPlan._id);
        if (couponResult?.coupon) {
            setInvalidCoupon(false);
            setCoupon(couponResult.coupon);
            setCouponValue(couponResult.couponValue);
        }

        let finalCouponValue = couponResult.couponValue || 0;
        if (option === 'endWithCredits') {
            var authentication = currentCredits + newCredits >= selectedPlan.amount - finalCouponValue;
        } else {
            var authentication = currentCredits + finalCouponValue >= selectedPlan.amount;
        }

        let result = await changePlanApi(currentSubscription._id, selectedPlan._id, couponId, option, paymentMethodId, authentication);
        setLoading(false);
        if (result.success) {
            if (result.finalized) {
                proceedToComplete();
            } else {
                setClientSecret(result.clientSecret);
                setStep(3);
            }
            if (result.message) {
                setErrorMessage(result.message);
            }
        } else {
            setErrorMessage(result.message);
        }
    }

    function selectPlan(event) {
        setErrorMessage("");
        if (clientSecret) {
            cancelPendingSubscriptionChangeApi();
            setClientSecret("");
        }

        let newPlan = changeablePlans.find(changeablePlan => changeablePlan._id === event.target.value);
        setSelectedPlan(newPlan);
        if (newPlan.options.length === 1) {
            setOption(newPlan.options[0]);
        } else {
            setOption("");
        }
        if (couponId) {
            applyCoupon(couponId, newPlan._id);
        }
    };

    function proceedToPayment() {
        if (clientSecret) {
            setErrorMessage("");
            setStep(3);
        } else {
            changePlan(null);
        }
    }

    function proceedToComplete() {
        setErrorMessage("");
        setCompleteTime(new Date());
        setStep(4);
    }

    function onChangeCouponId(event) {
        setCouponId(event.target.value);
        setInvalidCoupon(false);
        setErrorMessage("");
        if (clientSecret) {
            cancelPendingSubscriptionChangeApi();
            setClientSecret("");
        }
    }

    function selectOption(event) {
        setOption(event.target.value);
        if (clientSecret) {
            cancelPendingSubscriptionChangeApi();
            setClientSecret("");
        }
    }

    function getCardIconSrc(brand) {
        switch (brand.toLowerCase()) {
            case 'visa':
                return 'img/payments/visa.svg';
            case 'mastercard':
                return 'img/payments/mastercard.svg';
            case 'amex':
                return 'img/payments/american_express.svg';
            case 'jcb':
                return 'img/payments/jcb.svg';
            case 'unionpay':
                return 'img/payments/unionpay.svg';
            default:
                return 'img/payments/generic.svg';
        }
    };

    return (
        <>
            {type !== "change" &&
                <Button
                    onClick={() => setStep(1)}
                    variant="text"
                    style={{ color: "grey", marginTop: -8, marginBottom: 4 }}
                >
                    <ArrowBackIosIcon fontSize='small' /> {intl.formatMessage({ id: 'back-to-previous' })}
                </Button>
            }

            {!trialing &&
                <>
                    <Typography style={{ fontSize: 16, fontWeight: "bold" }}> {intl.formatMessage({ id: 'current-plan' })} </Typography>
                    <PlanDisplay intl={intl} plan={currentSubscriptionPlan} coupon={coupon} />
                    <br />
                </>
            }

            <Grid container direction="row">
                <Grid item xs={8}>
                    <Typography style={{ fontSize: 16, fontWeight: "bold" }}> {changeablePlans?.length > 1 ? intl.formatMessage({ id: 'select-new-plan' }) : intl.formatMessage({ id: 'new-plan' })} </Typography>
                </Grid>

                {selectedPlan && !disableCoupon &&
                    <Grid item xs={4}>
                        <Typography style={{ fontSize: 16, fontWeight: "bold" }}> {intl.formatMessage({ id: 'coupon' })} </Typography>
                    </Grid>
                }
            </Grid>

            <Grid container direction="row">
                <Grid item xs={7}>
                    {changeablePlans?.length > 1 ?
                        <TextField
                            select
                            size='small'
                            key={selectedPlan?._id || 'none'}
                            id="changeablePlans"
                            variant='outlined'
                            value={selectedPlan?._id}
                            onChange={selectPlan}
                            autoWidth
                        >
                            {changeablePlans.map(changeablePlan =>
                                <MenuItem key={changeablePlan._id} value={changeablePlan._id}>
                                    <PlanDisplay intl={intl} plan={changeablePlan} coupon={coupon} />
                                </MenuItem>
                            )}
                        </TextField>
                        :
                        changeablePlans?.length === 1 ?
                            <PlanDisplay intl={intl} plan={changeablePlans[0]} coupon={coupon} />
                            :
                            loading ?
                                <CircularProgress size={24} /> :
                                <Typography style={{ fontSize: 15 }}>{intl.formatMessage({ id: `no-available-plan` })}</Typography>
                    }
                </Grid>

                <Grid item xs={1}></Grid>

                {selectedPlan && !disableCoupon &&
                    <Grid item xs={4}>
                        <TextField
                            variant='outlined'
                            size='small'
                            error={invalidCoupon}
                            helperText={
                                invalidCoupon ?
                                    intl.formatMessage({ id: 'invalid-coupon' }) :
                                    couponValue ?
                                        intl.formatMessage({ id: 'coupon-has-been-applied' }, { amount: couponValue / 100 }) :
                                        ""
                            }
                            value={couponId}
                            onChange={onChangeCouponId}
                            onBlur={() => applyCoupon(couponId, selectedPlan._id)}
                            FormHelperTextProps={{
                                style: { fontSize: 12, color: invalidCoupon ? theme.palette.error.main : 'green' }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton style={{ padding: 0 }} onClick={() => applyCoupon(couponId, selectedPlan._id)}>
                                            <CheckCircleOutlineIcon style={{ fontSize: 20, color: invalidCoupon ? theme.palette.error.main : undefined }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                }
            </Grid>

            <br />

            {selectedPlan &&
                <>
                    <Typography style={{ fontSize: 16, fontWeight: "bold" }}> {intl.formatMessage({ id: 'options' })} </Typography>

                    <FormControl component="fieldset">
                        <RadioGroup aria-label="option" name="option" value={option} onChange={selectOption}>
                            {selectedPlan.options.includes("end") && <FormControlLabel value="end" control={<Radio />} label={intl.formatMessage({ id: 'start-new-plan-now' })} />}
                            {selectedPlan.options.includes("endWithExtension") && !disableExtension && <FormControlLabel value="endWithExtension" control={<Radio />} label={intl.formatMessage({ id: 'end-plan-and-get-extension' }, { number: extension })} />}
                            {selectedPlan.options.includes("endWithCredits") && <FormControlLabel value="endWithCredits" control={<Radio />} label={intl.formatMessage({ id: 'end-plan-and-get-credits' }, { amount: newCredits / 100 })} />}
                            {selectedPlan.options.includes("scheduleChange") && <FormControlLabel value="scheduleChange" control={<Radio />} label={intl.formatMessage({ id: 'change-after-current-plan-expires' }, { date: currentSubscriptionExpiredAt })} />}
                        </RadioGroup>
                    </FormControl>

                    <br />

                    {option &&
                        <>
                            {!loading && <>
                                <Typography style={{ fontSize: 16, fontWeight: "bold" }}> {intl.formatMessage({ id: 'summary' })} </Typography>
                                {currentCredits > 0 && <Typography>{intl.formatMessage({ id: 'you-have-credits-available' }, { amount: currentCredits / 100 })}</Typography>}
                                <Typography>
                                    {currentCredits > 0 ? intl.formatMessage({ id: 'after-applying-current-credits' }) : ""}
                                    {option === "scheduleChange" || (option === "endWithCredits" && amountDueToday <= 0) ?
                                        intl.formatMessage({ id: 'you-dont-need-to-pay-today' }) :
                                        intl.formatMessage({ id: 'you-need-to-pay-amount-today' }, { amount: amountDueToday })
                                    }
                                </Typography>
                                <Typography>
                                    {intl.formatMessage(
                                        {
                                            id: option === "endWithCredits" && (currentCredits + newCredits > selectedPlan.amount - couponValue) ?
                                                'plan-renew-automatically-for-amount-on-date-with-credits' :
                                                'plan-renew-automatically-for-amount-on-date'
                                        },
                                        {
                                            amount: amountDueNextBillingPeriod,
                                            credits: (currentCredits + newCredits - (selectedPlan.amount - couponValue)) / 100,
                                            date: formattedNextBillingDate
                                        }
                                    )}
                                </Typography>
                            </>
                            }

                            {defaultPaymentMethod?.card ?
                                <>
                                    {amountDueToday !== 0 &&
                                        <Button
                                            variant="text"
                                            disabled={loading}
                                            onClick={proceedToPayment}
                                            style={{ color: "grey", float: "right" }}
                                        >
                                            {loading ? "" : intl.formatMessage({ id: 'pay-with-other-credit-card' })}
                                        </Button>
                                    }
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth={true}
                                        disabled={loading}
                                        onClick={() => changePlan(defaultPaymentMethod.id)}
                                        style={{ marginTop: amountDueToday === 0 ? 5 : 0 }}
                                    >
                                        {loading ?
                                            <CircularProgress size={24} /> :
                                            <FormattedMessage
                                                id={amountDueToday === 0 ? "Confirm" : "pay-with-card"}
                                                values={{
                                                    amount: amountDueToday,
                                                    card:
                                                        <span>
                                                            <img src={getCardIconSrc(defaultPaymentMethod.card.brand)} alt={`${defaultPaymentMethod.card.brand} icon`} height="24" style={{ marginLeft: 3, marginRight: 3 }} />
                                                            •⁠•⁠•⁠⁠• {defaultPaymentMethod.card.last4}
                                                        </span>
                                                }}
                                            />

                                        }
                                    </Button>
                                </>
                                :
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    disabled={loading}
                                    onClick={proceedToPayment}
                                    style={{ marginTop: 5 }}
                                >
                                    {loading ? <CircularProgress size={24} /> : intl.formatMessage({ id: 'proceed-to-payment' })}
                                </Button>
                            }
                        </>
                    }
                </>
            }
        </>
    );
};

export default Change;