import React from 'react';
import { Typography } from '@material-ui/core';

const PlanDisplay = ({ intl, plan, coupon }) => {
    function calculateDisplayPrice(plan, coupon) {
        let price = 0;
        if (!coupon) {
            price = plan.amount / 100;
        } else if (coupon.percentageOff) {
            price = Math.round(plan.amount * (1 - coupon.percentageOff / 100)) / 100;
        } else if (coupon.amountOff) {
            price = (plan.amount - coupon.amountOff) / 100
        }
        if (price < 0) {
            price = 0;
        }
        return price;
    }

    let displayPrice = coupon
        ? (
            <>
                <span>&nbsp;<s>${plan.amount / 100}</s></span>&nbsp;
                ${calculateDisplayPrice(plan, coupon)}
            </>
        )
        : `$${calculateDisplayPrice(plan, coupon)}`;

    let intervalText = plan.intervalCount === 1
        ? intl.formatMessage({ id: `per-${plan.interval}` })
        : intl.formatMessage({ id: `for-number-${plan.interval}` }, { number: plan.intervalCount });

    return (
        plan.amount === 0 ?
            <Typography style={{ fontSize: 15 }}>{intl.formatMessage({ id: 'free' })}</Typography> :
            <Typography style={{ fontSize: 15 }}>
                {intl.locale !== "en" && intervalText}
                {displayPrice}
                {intl.locale === "en" && intervalText}
            </Typography>
    );
};

export default PlanDisplay;
