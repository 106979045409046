import { injectIntl } from 'react-intl';
import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { maxWidth } from '@material-ui/system';

const buttonStyle = {
  boxShadow: 'none',
  width: '152px',
  minHeight: '44px',
  maxWidth: 'calc(50vw - 32px)',
  borderRadius: '10px'
};

function ButtonBar({
  intl /** @type {any} */,
  onEditProfile = () => {} /** @type { ()=> void} */,
  onViewProfile = () => {} /** @type { ()=> void} */
}) {
  return (
    <Grid
      item
      style={{
        display: 'flex',
        gap: '10px'
      }}
    >
      <Button variant="outlined" style={buttonStyle} onClick={onEditProfile}>
        {intl.formatMessage({ id: 'linker-cta-edit' })}
      </Button>
      <Button variant="contained" color="primary" style={buttonStyle} onClick={onViewProfile}>
        {intl.formatMessage({ id: 'linker-cta-view' })}
      </Button>
    </Grid>
  );
}
export const ActionButtonBar = ButtonBar;
