import React from 'react';
import { MenuItem, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ProBadge from '../SubscriptionComponents/ProBadge/ProBadge';
import { switchLanguage } from '../../utils/locale';
import './NavDrawer.scss';
import { getUserInitial, getUserLogo } from '../../utils/user';
import _ from 'lodash';
import MyDetailsProfilePicture from '../../views/Advisor/MyDetails/MyDetailsProfile/MyDetailsProfilePicture/MyDetailsProfilePicture';
import { getAvatar } from '../../utils/my-details';
import { tracking } from '../../utils/tracking';
import { ADVISER_LOGO_FUNCTIONS } from '../../constants/user';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

const NoProfileStyle = {
  paddingTop: 'var(--top-padding)'
};

export default class NavDrawer extends React.PureComponent {
  handleLogout = () => {
    this.props.handleSignOut();
  };

  submit = language => {
    const { updateUserDetails, selectedLocale } = this.props;
    updateUserDetails({ language });
    switchLanguage(selectedLocale, language);
  };

  render() {
    const { intl, history, userDetails, userSubscription, teamLogoUrl, shouldDisableFunctions } = this.props;
    const user = userDetails;
    const linkerUrl = `${process.env.LINKER_URL}/adviser/${user._id}`;
    const intials = getUserInitial(user);
    const containerStyle = { height: 'calc(100% - 60px)', overflowX: 'hidden', overflowY: 'scroll' };
    const userLogo = getUserLogo(user, teamLogoUrl, ADVISER_LOGO_FUNCTIONS.MENU);
    const isLinkerProUser = !!userSubscription.containVaildLinkerProSubscription();
    const isLinkerUser = !!userSubscription.containVaildLinkerSubscription();

    return (
      <>
        {user && user.userType === 'User' && (
          <div
            style={containerStyle}
            tabIndex={0}
            className="nav-drawer"
            role="button"
            onClick={() => this.props.closeHeaderMenu()}
            onKeyDown={() => this.props.closeHeaderMenu()}
          >
            <MenuItem className="menuSide">
              <div className="profile-sidebar">
                <Link
                  to="/personalData"
                  onClick={() => {
                    // Tracking
                    tracking('User Drawer Accessed: Profile');
                  }}
                >
                  <div className="profile-userpic" style={NoProfileStyle}>
                    <div
                      id="profileImage"
                      className={user && user.gender === 'Female' ? 'femaleProfile' : 'maleProfile'}
                    >
                      {intials}
                    </div>
                  </div>
                </Link>
                <div className="profile-usertitle">
                  <div className="profile-usertitle-name" id="firstName">
                    {user.name}
                    <br />
                    <span>{user.email}</span>
                  </div>
                </div>
              </div>
            </MenuItem>
            <div className="side-functions">
              <MenuItem className="menuSide">
                <Link
                  to="/personalData"
                  className="sideMenuAnchor"
                  onClick={() => {
                    // Tracking
                    tracking('User Drawer Accessed: Profile');
                  }}
                >
                  <div className="sideMenuItem">
                    {user && user.gender === 'Female' ? (
                      <img src="img/profile-F.png" className="img-responsive" alt="" />
                    ) : user && user.gender === 'Male' ? (
                      <img src="img/profile-M.png" className="img-responsive" alt="" />
                    ) : (
                      <img alt="" src="img/myprofile.png" className="img-responsive" />
                    )}
                    <span> {intl.formatMessage({ id: 'Profile' })} </span>
                  </div>
                </Link>
              </MenuItem>
              <MenuItem className="menuSide">
                <Link
                  to="/existing_policy"
                  className="sideMenuAnchor"
                  onClick={() => {
                    // Tracking
                    tracking('User Drawer Accessed: Add Policy');
                  }}
                >
                  <div className="sideMenuItem">
                    <img alt="" src="img/useraddpolicy.png" className="img-responsive" />
                    <span> {intl.formatMessage({ id: 'Add Policy' })} </span>
                  </div>
                </Link>
              </MenuItem>
              <MenuItem className="menuSide">
                <Link to="/share_options" className="sideMenuAnchor">
                  <div className="sideMenuItem">
                    <img alt="" src="img/usershare.png" className="img-responsive" />
                    <span> {intl.formatMessage({ id: 'Share' })} </span>
                  </div>
                </Link>
              </MenuItem>
              {/* <MenuItem className="menuSide">
		                      <Link to="/insurer_info" className="sideMenuAnchor">
		                        <div className="sideMenuItem">
		                          <img src="img/discussion1.png" className="img-responsive" />
		                          <span> {intl.formatMessage({ id: 'Useful Info' })} </span>
		                        </div>
		                      </Link>
		                    </MenuItem> */}
              <MenuItem className="menuSide" id="help_center_menu_item">
                <a
                  href="https://w3.portfoplus.com/help-center"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="sideMenuAnchor"
                >
                  <div
                    className="sideMenuItem"
                    onClick={() => {
                      // Tracking
                      tracking('User Drawer Accessed: Help Center');
                    }}
                  >
                    <img alt="" src="img/helpCenter.png" className="img-responsive" />
                    <span> {intl.formatMessage({ id: 'Help Center' })} </span>
                  </div>
                </a>
              </MenuItem>
              <MenuItem className="menuSide">
                <Link
                  to="/settings"
                  className="sideMenuAnchor"
                  onClick={() => {
                    // Tracking
                    tracking('User Drawer Accessed: Settings');
                  }}
                >
                  <div className="sideMenuItem">
                    <img alt="" src="img/settings1.png" className="img-responsive" />
                    <span> {intl.formatMessage({ id: 'Settings' })} </span>
                  </div>
                </Link>
              </MenuItem>
            </div>
            <MenuItem className="menuSide">
              {/*<Link to="#" className="sideMenuAnchor">*/}
              <div className="sideMenuItem" onClick={this.handleLogout.bind(this)}>
                <img alt="" src="img/logout1.png" className="img-responsive" />
                <span> {intl.formatMessage({ id: 'Logout' })} </span>
              </div>
              {/*</Link>*/}
            </MenuItem>

            <br />
            <br />
          </div>
        )}
        {user && user.userType === 'Adviser' && (
          <div
            style={containerStyle}
            tabIndex={0}
            className="nav-drawer"
            role="button"
            onClick={() => this.props.closeHeaderMenu()}
            onKeyDown={() => this.props.closeHeaderMenu()}
          >
            <div className="grouped-for-adviser-welcome">
              <MenuItem className="menuSide">
                <div
                  className="profile-sidebar"
                  onClick={() => {
                    // Tracking
                    tracking('Adviser Drawer Accessed: Profile+');
                    if (isLinkerUser) {
                      history.push('/linker/my-details');
                    } else {
                      history.push('/my-details');
                    }
                  }}
                >
                  {userLogo ? (
                    <div className="profile-teampic" style={NoProfileStyle}>
                      <img src={userLogo} alt="Team-Icon" />
                    </div>
                  ) : (
                    <MyDetailsProfilePicture
                      initial={getUserInitial(user)}
                      src={getAvatar(user)}
                      width={100}
                      style={{ border: 0, borderRadius: '50%', marginTop: 10 }}
                      imgStyle={{ borderRadius: '50%' }}
                    />
                  )}
                  <div className="profile-usertitle">
                    <div className="profile-usertitle-name" id="firstName">
                      {user.name} <ProBadge />
                      <br />
                      <span>{user.email}</span>
                    </div>
                  </div>
                </div>
              </MenuItem>
              <MenuItem className="menuSide">
                {shouldDisableFunctions ? (
                  <div
                    className="sideMenuItem"
                    onClick={() => {
                      history.push('/linker/my-details');
                    }}
                  >
                    <img
                      alt=""
                      src="img/linker-menu-icon-contained.svg"
                      className="img-responsive"
                      style={{ width: '32px', height: '32px' }}
                    />
                    <span> Linker </span>
                  </div>
                ) : (
                  <Link
                    to="/my-details"
                    className="sideMenuAnchor"
                    onClick={() => {
                      // Tracking
                      tracking('Adviser Drawer Accessed: Profile+');
                    }}
                  >
                    <div className="sideMenuItem">
                      <img
                        alt=""
                        src="img/icons/e_card.svg"
                        className="img-responsive"
                        style={{ width: '32px', height: '32px' }}
                      />
                      <span> {intl.formatMessage({ id: 'e-name-card' })} </span>
                    </div>
                  </Link>
                )}
              </MenuItem>

              {isLinkerProUser && (
                <MenuItem className="menuSide">
                  <div
                    className="sideMenuItem"
                    onClick={() => {
                      history.push('/referral_records');
                    }}
                  >
                    <img
                      alt="referral_records"
                      src="img/linker-referral-records-menu-icon.svg"
                      style={{ width: '32px', height: '32px' }}
                    />
                    <span> {intl.formatMessage({ id: 'linker-referral-records-title' })} </span>
                  </div>
                </MenuItem>
              )}
            </div>

            <MenuItem className="menuSide" id="help_center_menu_item" style={{ filter: 'none' }}>
              <Link
                to={{
                  pathname: '/new_help_center',
                  state: { previousPath: this.props.location.pathname }
                }}
                className="sideMenuAnchor"
                onClick={() => {
                  // Tracking
                  tracking('Adviser Drawer Accessed: Help Center');
                }}
              >
                <div className="sideMenuItem">
                  <img
                    alt=""
                    src="img/icons/education.svg"
                    className="img-responsive"
                    style={{ width: '32px', height: '32px' }}
                  />
                  <span> {intl.formatMessage({ id: 'Closing-class' })} </span>
                </div>
              </Link>
            </MenuItem>

            {!shouldDisableFunctions && (
              <MenuItem className="menuSide">
                <Link
                  to="/share_from"
                  className="sideMenuAnchor"
                  onClick={() => {
                    // Tracking
                    tracking('Adviser Drawer Accessed: Connection');
                  }}
                >
                  <div className="sideMenuItem">
                    <img
                      alt=""
                      src="img/icons/two_way_link.svg"
                      className="img-responsive"
                      style={{ width: '32px', height: '32px' }}
                    />
                    <span> {intl.formatMessage({ id: 'Connection' })} </span>
                  </div>
                </Link>
              </MenuItem>
            )}

            <MenuItem className="menuSide">
              <Link
                to="/settings"
                className="sideMenuAnchor"
                onClick={() => {
                  // Tracking
                  tracking('Adviser Drawer Accessed: Setting');
                }}
              >
                <div className="sideMenuItem">
                  <img
                    alt=""
                    src="img/icons/settings.svg"
                    className="img-responsive"
                    style={{ width: '32px', height: '32px' }}
                  />
                  <span> {intl.formatMessage({ id: 'nav-drawer-settings' })} </span>
                </div>
              </Link>
            </MenuItem>
            <MenuItem className="menuSide">
              {/*<Link to="#" className="sideMenuAnchor">*/}
              <div className="sideMenuItem" onClick={this.handleLogout.bind(this)}>
                <img
                  alt=""
                  src="img/icons/logout.svg"
                  className="img-responsive"
                  style={{ width: '32px', height: '32px' }}
                />
                <span> {intl.formatMessage({ id: 'Logout' })} </span>
              </div>
              {/*</Link>*/}
            </MenuItem>
            <br />
            <br />
          </div>
        )}
        <MenuItem button={false} className="menuSide language-btn" tabIndex={1} style={{ marginBottom: 0 }}>
          <div className="sideMenuItem" onClick={() => this.props.closeHeaderMenu()}>
            <Button
              onClick={e => {
                e.stopPropagation();
                this.submit(intl.formatMessage({ id: 'translationKey1' }));
              }}
              style={{ marginRight: 10 }}
            >
              {intl.formatMessage({ id: 'translation1' })}
            </Button>
            <span>/</span>
            <Button
              onClick={e => {
                e.stopPropagation();
                this.submit(intl.formatMessage({ id: 'translationKey2' }));
              }}
              style={{ marginLeft: 10 }}
            >
              {intl.formatMessage({ id: 'translation2' })}
            </Button>
          </div>
        </MenuItem>
      </>
    );
  }
}
