

const invaildJsonToken = {
    "```json": "",
    "```": "",
    "\\&": "&",
    "}, ]": "}]",
}
export const getObjectContent = (
    /** @type {Object} */ obj,
    /** @type {string} [gemini] */ model
) => {
    let text;
    switch (model) {
        case "gemini":
            text = obj.response?.candidates[0]?.content?.parts[0]?.text;
            Object.entries(invaildJsonToken).forEach(([key, value]) => {
                text = text.replaceAll(key, value);
            });
    }

    if (!text) {
        return undefined;
    } else {
        try {
            return JSON.parse(text);
        } catch (error) {
            console.log('error: ', error);
            console.log('text: ', text);
            return [text];
        }
    }
}