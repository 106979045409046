import { connect } from 'react-redux';
import {
  loginUser,
  approveUser,
  currencyExchangeRate,
  verifyEmail,
  handleSignOut,
  resetState,
  loginFetch,
  setShownDashHelp,
  updateFirstGuidelineState
} from '../../store/user/duck';
import LoginForm from './component';
import { selectedLocale } from '../../store/locale/action';
import { injectIntl } from 'react-intl';
import withRouter from 'react-router/withRouter';
import { checkIsBiometricSet } from '../../store/auth/duck';


const Login = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    loginMessage: state.user.loginMessage,
    loginMessageExtras: state.user.loginMessageExtras,
    loginPhase: state.user.loginPhase,
    approvePhase: state.user.approvePhase,
    exchangeRate: state.user.exchangeRate,
    isAuthenticated: state.auth.isAuthenticated,
    authenticatedUser: state.auth.authenticatedUser,
    isBiometricSet: state.auth.isBiometricSet,
    subscriptions: state.userSubscription.subscriptions
  }),
  // Map actions to props
  {
    loginUser,
    approveUser,
    verifyEmail,
    selectedLocale,
    currencyExchangeRate,
    handleSignOut,
    resetState,
    loginFetch,
    setShownDashHelp,
    updateFirstGuidelineState,
    checkIsBiometricSet,
  }
)(LoginForm);

export default injectIntl(withRouter(Login));
