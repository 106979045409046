import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";

export function UpgradeButton({ msg, onUpgrade }) {
  return (
    <Box
      container
      spacing={1}
      display="flex"
      alignItems="center"
      onClick={onUpgrade}
      style={{ cursor: "pointer" }}
    >
      <Typography variant="body1">{msg}</Typography>
      <Box style={{ width: "12px" }}></Box>
      <ArrowForwardIos
        style={{
          width: "10px",
          height: "10px"
        }}
      />
    </Box>
  );
}
