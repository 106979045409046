import React, { Fragment, useState, forwardRef } from 'react';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import IconToast from '../NewToast';
import { handleError } from '../../utils/openai';
import { getVoiceCreateSosPaperPrompt } from '../../utils/aiPrompt';
import { openaiGenerateWithTextStreaming } from '../../store/openai/api';
import RecordDialog from '../RecordDialog/RecordDialog';
import LoadingBackdrop from '../LoadingBackdrop/LoadingBackdrop';
import { speechToText } from '../../store/speech/api';
import { LANGUAGE } from '../../constants/language';
import { injectIntl } from 'react-intl';
import NativeOrWeb from '../../utils/native';
import { tracking } from '../../utils/tracking';
import { trackDirect } from '../../store/analytics/api';

const VoiceCreateSosPaperButton = forwardRef(props => {
  const { intl, addPoliciesByVoice } = props;

  const [initializing, setInitializing] = useState(false);
  const [recordingDialogOpen, setRecordingDialogOpen] = useState(false);
  const [recording, setRecording] = useState(false);
  const [loading, setLoading] = useState(false);

  const openDialog = () => {
    setRecordingDialogOpen(true);
  };

  const startRecording = async () => {
    try {
      setRecording(true);
      setInitializing(true);
      trackDirect('createEmergencyReferenceByVoice');
      tracking('AI: Create Emergency Reference By Voice');

      await NativeOrWeb.startRecordingVoice();
    } catch (error) {
      console.log(error);
      toast.info(IconToast('error', intl.formatMessage({ id: 'error-msg' })), { className: 'new-toast' });
      setRecordingDialogOpen(false);
    } finally {
      setInitializing(false);
    }
  };

  const cancelRecording = () => {
    setRecordingDialogOpen(false);
    setRecording(false);
    if (recording) {
      NativeOrWeb.stopRecordingVoice()
        .then()
        .catch(console.log);
    }
  };

  const stopRecording = async () => {
    try {
      setRecordingDialogOpen(false);
      setLoading(true);

      const recordingData = await NativeOrWeb.stopRecordingVoice();
      const { mimeType, msDuration, recordDataBase64 } = recordingData?.value || {};
      const speechToTextResult = await speechToText({ mimeType, msDuration, recordDataBase64 });

      const { text: speechText, language: detectedLanguage } = speechToTextResult?.result || {};
      console.log(speechText);

      let speechLanguage;
      switch (detectedLanguage) {
        case 'en-US':
          speechLanguage = LANGUAGE.EN;
          break;
        case 'zh-HK':
          speechLanguage = LANGUAGE.ZH_HANT_HK;
          break;
        case 'zh-CN':
          speechLanguage = LANGUAGE.ZH;
          break;
        default:
          speechLanguage = LANGUAGE.EN;
          break;
      }

      const inputMessage = getVoiceCreateSosPaperPrompt(speechText);
      const addedChatMessages = [{ content: inputMessage, role: 'user' }];

      await openaiGenerateWithTextStreaming(
        'gpt-4o',
        addedChatMessages,
        1,
        1,
        0,
        0,
        null,
        null,
        addPoliciesByVoice,
        setLoading
      );
    } catch (error) {
      handleError(error);
      setLoading(false);
    } finally {
      setRecording(false);
    }
  };

  return (
    <Fragment>
      <RecordDialog
        title={intl.formatMessage({ id: 'ai-voice-create-sos-paper' })}
        content={intl.formatMessage({ id: 'ai-voice-create-sos-paper-content' })}
        timeLimit={120}
        open={recordingDialogOpen}
        initializing={initializing}
        recording={recording}
        onClose={cancelRecording}
        onStartRecording={startRecording}
        onDoneRecording={stopRecording}
      />
      <LoadingBackdrop open={loading} />
      <Button
        color="primary"
        variant="outlined"
        style={{ padding: '0px 4px', fontSize: 14, marginLeft: 4, minWidth: 'unset' }}
        className="record-meeting-remark-button"
        onClick={openDialog}
      >
        <img
          style={{
            width: '18px',
            height: '18px',
            paddingRight: '4px'
          }}
          src="/img/aiGPT.svg"
        />
        {intl.formatMessage({ id: 'record-meeting-remark-button' })}
      </Button>
    </Fragment>
  );
});

export default injectIntl(VoiceCreateSosPaperButton);
