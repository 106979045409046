import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { toggleDialog } from '../../store/control/duck';
import { injectIntl } from 'react-intl';
import { CircularProgress, Grid, TextField, Typography, Button, Chip } from '@material-ui/core';
import { azureOpenaiTeamBot, azureOpenaiTeamBotFollowUpQuestion } from '../../store/ai/azure/openai';
import BottomSheetOrDialog from '../BottomSheetOrDialog/BottomSheetOrDialog';
import { decreaseGPTQuota, handleError, validateGPTQuota } from '../../utils/openai';
import './TeamBotDialog.scss';
import { withRouter } from 'react-router';
import { Markdown } from './markdown';
import { useMediaQuery } from '@material-ui/core';

const TeamBotDialog = props => {
  const { intl, teamBotDialog, toggleDialog } = props;

  const contentDivRef = useRef(null);
  const inputRef = useRef(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [followUpQuestions, setFollowUpQuestions] = useState([]);
  const [loadingFollowUp, setLoadingFollowUp] = useState(false);

  // Add preset questions
  const presetQuestions = [
    { id: 1, text: "What are the team's current goals?" },
    { id: 2, text: 'How can I improve team collaboration?' },
    { id: 3, text: 'What are the best practices for our team?' },
    { id: 4, text: 'How to schedule a team meeting?' },
    { id: 5, text: "What's the team's communication protocol?" }
  ];

  const { closed } = teamBotDialog || {};

  const isMobile = useMediaQuery('(max-width: 600px)');

  const onChangeInputMessage = event => {
    setInputMessage(event.target.value);
  };

  const handlePresetQuestion = questionText => {
    setInputMessage(questionText);
    setTimeout(() => {
      sendMessage(questionText);
    }, 100);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (inputMessage && !loading) {
        sendMessage();
      }
    }
  };

  const clearConversation = () => {
    setChatMessages([]);
    setFollowUpQuestions([]);
  };

  const getFollowUpQuestions = async () => {
    try {
      // Get the last AI message from the chat history
      const lastAIMessage = [...chatMessages].reverse().find(msg => msg.role === 'assistant');

      if (!lastAIMessage) {
        return;
      }

      setLoadingFollowUp(true);
      const result = await azureOpenaiTeamBotFollowUpQuestion(
        {
          messages: [lastAIMessage]
        },
        {},
        {
          setChatMessages,
          setLoading
        }
      );

      if (result.text) {
        try {
          const jsonResponse = JSON.parse(result.text);
          if (jsonResponse.followUpQuestions && Array.isArray(jsonResponse.followUpQuestions)) {
            setFollowUpQuestions(jsonResponse.followUpQuestions);
          }
        } catch (parseError) {
          console.error('Error parsing follow-up questions JSON:', parseError);
        }
      }
    } catch (error) {
      console.error('Error getting follow-up questions:', error);
    } finally {
      setLoadingFollowUp(false);
    }
  };

  const sendMessage = async (messageText = inputMessage) => {
    try {
      validateGPTQuota();

      setLoading(true);
      const addedChatMessages = [...chatMessages, { content: messageText, role: 'user' }];

      await azureOpenaiTeamBot(
        {
          messages: addedChatMessages
        },
        {
          pollTimeout: 7000
        },
        {
          setChatMessages,
          setLoading
        }
      );

      decreaseGPTQuota();
      setInputMessage('');

      // Get follow-up questions after the response
      await getFollowUpQuestions();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  };

  const close = () => {
    toggleDialog('teamBot', { ...teamBotDialog, closed: true });
  };

  const onExited = () => {
    toggleDialog('teamBot', false);
  };

  useEffect(() => {
    if (!teamBotDialog) {
      setInputMessage('');
      setChatMessages([]);
      setLoading(false);
    }
  }, [teamBotDialog]);

  useEffect(() => {
    const contentDiv = contentDivRef.current;
    if (contentDiv) {
      contentDiv.scrollTop = contentDiv.scrollHeight;
    }
  }, [chatMessages]);

  useEffect(() => {
    if (teamBotDialog && !closed) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [teamBotDialog, closed]);

  const header = intl.formatMessage({ id: 'team-bot-dialog-title' });

  const questionsSection = (
    <Grid item style={{ width: '100%', marginBottom: 2 }}>
      <Typography>
        {intl.formatMessage({ id: followUpQuestions.length > 0 ? 'follow-up-questions' : 'preset-questions' })}
        {loadingFollowUp && (
          <CircularProgress
            size={16}
            style={{
              marginLeft: 8,
              verticalAlign: 'middle'
            }}
          />
        )}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          opacity: loadingFollowUp ? 0.6 : 1,
          pointerEvents: loadingFollowUp ? 'none' : 'auto'
        }}
      >
        {(followUpQuestions.length > 0 ? followUpQuestions : presetQuestions).map(question => (
          <Button
            key={question.id}
            variant="outlined"
            color="primary"
            onClick={() => handlePresetQuestion(question.text)}
            disabled={loading || loadingFollowUp}
            style={{
              textTransform: 'none',
              padding: '8px 16px',
              borderRadius: '8px',
              height: 'auto',
              whiteSpace: 'normal',
              textAlign: 'left',
              lineHeight: '1.4',
              margin: '6px',
              minWidth: 'auto',
              flex: '0 1 auto'
            }}
          >
            {question.text}
          </Button>
        ))}
      </div>
    </Grid>
  );

  const content = (
    <Grid container direction="column" spacing={1} style={{ height: '100%', flexFlow: 'column' }}>
      <Grid item style={{ width: '100%', flex: 1 }}>
        <div
          style={{
            height: '100%',
            position: 'relative',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: 4,
            padding: '8px 0'
          }}
        >
          <label
            htmlFor="team-bot-dialog-respond"
            style={{
              transform: 'translate(14px, -6px) scale(0.75)',
              transformOrigin: 'top left',
              zIndex: 1,
              top: 0,
              left: 0,
              position: 'absolute',
              color: 'rgba(0, 0, 0, 0.54)',
              background: '#fff',
              padding: '0 6px'
            }}
          >
            {intl.formatMessage({ id: 'team-bot-dialog-respond' })}
          </label>

          <div
            style={{
              height: isMobile ? 'calc(100vh - 340px)' : 'calc(60vh - 200px)',
              padding: '10.5px 14px',
              zIndex: 2,
              whiteSpace: 'pre-wrap',
              overflow: 'auto'
            }}
            ref={contentDivRef}
          >
            <Grid container direction="column" spacing={2}>
              {chatMessages.map((message, index) => (
                <Grid item key={`${message.role}-${index}`} style={{ width: '100%' }}>
                  <Typography
                    align={message.role === 'assistant' ? 'left' : 'right'}
                    style={{
                      marginBottom: '1rem'
                    }}
                  >
                    <strong>{message.role === 'assistant' ? 'AI:\n' : 'Question:\n'}</strong>
                    {message.role === 'assistant' ? (
                      <div className="markdown-content">
                        <Markdown className="markdown-content">{(message.content || '').trim()}</Markdown>
                      </div>
                    ) : (
                      (message.content || '').trim()
                    )}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </Grid>
      {questionsSection}
      <Grid item style={{ width: '100%' }}>
        <Grid container direction="column" alignItems="flex-end" spacing={1}>
          <Grid item style={{ width: '100%' }}>
            <TextField
              variant="outlined"
              label={intl.formatMessage({ id: 'team-bot-dialog-input' })}
              value={inputMessage}
              onChange={onChangeInputMessage}
              onKeyPress={handleKeyPress}
              inputRef={inputRef}
              multiline
              rows={6}
              disabled={loading}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={clearConversation}
              disabled={chatMessages.length === 0 || loading}
            >
              {intl.formatMessage({ id: 'clear-conversation' })}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={e => sendMessage()}
              disabled={!inputMessage || loading}
              endIcon={loading && <CircularProgress size={20} color="inherit" />}
            >
              {intl.formatMessage({ id: 'send' })}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <BottomSheetOrDialog
      className="team-bot-dialog"
      open={!!teamBotDialog && !closed}
      onClose={close}
      onExited={onExited}
      header={header}
      content={content}
      BottomSheetProps={{
        snapPoints: ({ maxHeight }) => maxHeight * 0.95,
        defaultSnap: ({ lastSnap, snapPoints }) => lastSnap ?? Math.max(...snapPoints),
        expandOnContentDrag: false,
        disableAutoDismiss: true,
        BoxProps: {
          height: 'calc(95vh - 54px - var(--top-padding))'
        }
      }}
      DialogProps={{
        maxWidth: 'md',
        fullWidth: true
      }}
      DialogParams={{
        leftCloseButton: true
      }}
    />
  );
};

const mapStateToProps = state => ({
  teamBotDialog: state.control.teamBotDialog
});

export default withRouter(injectIntl(connect(mapStateToProps, { toggleDialog })(TeamBotDialog)));
