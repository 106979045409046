import React, { useState, useRef, forwardRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Button } from '@material-ui/core';
import MyToolbar from '../../components/MyEditor/MyToolbar';
import MyEditor from '../../components/MyEditor/MyEditor';
import { formatEditorStateValue } from '../../utils/editor';
import _ from 'lodash';
import AlertPopUp from '../../components/AlertPopup';
import ChatGPTPersonalMessageDrawer from '../../components/ClientEntry/ChatGPTPresetDrawer/ChatGPTPersonalMessageDrawer/ChatGPTPersonalMessageDrawer';
import { tracking } from '../../utils/tracking';
import BottomSheetOrDialog from '../../components/BottomSheetOrDialog/BottomSheetOrDialog';
import './index.scss';

const PopupDraftEditor = forwardRef((props, ref) => {
  const {
    currentRemark,
    defaultRemark,
    client,
    open = false,
    myEditorRef,
    toolbarRef,
    intl,
    onClose,
    defaultRemarkGetter,
    onSave,
    onChange,
    translations,
    options,
    controllers
  } = props;

  useEffect(() => {
    setEditorState(formatEditorStateValue(currentRemark));
  }, [currentRemark]);

  const [defaultRemarkDialog, setDefaultRemarkDialog] = useState(false);
  const openDefaultRemarkDialog = () => setDefaultRemarkDialog(true);
  const closeDefaultRemarkDialog = () => setDefaultRemarkDialog(false);

  const onChatGPTPersonalMessage = event => {
    if (controllers.toggleControl) {
      event.stopPropagation();
      tracking('Click on ChatGPT - Policy Review');
      controllers.toggleControl('chatGPTPersonalMessageDrawer', { client: client });
    }
  };
  const handleSetDefaultRemark = () => {
    const _e = defaultRemark ?? defaultRemarkGetter();
    // console.log('handleSetDefaultRemark default: ', _e)
    const nextState = formatEditorStateValue(_e ?? '');
    setEditorState(nextState);
    onChange?.(nextState);
    closeDefaultRemarkDialog();
  };

  const [loading, setLoading] = useState(false);

  const [editorState, setEditorState] = useState(currentRemark);
  return (
    <BottomSheetOrDialog
      className="policy-review-remark-dialog"
      open={open}
      onClose={onClose}
      header={translations?.header ?? intl.formatMessage({ id: 'pr-cus-title' })}
      content={
        <div>
          {options?.ChatGPTPersonalMessage && controllers?.toggleControl && <ChatGPTPersonalMessageDrawer />}
          <AlertPopUp
            intl={intl}
            open={defaultRemarkDialog}
            handleClose={closeDefaultRemarkDialog}
            handleUpdateUserProfile={handleSetDefaultRemark}
            title={translations?.defaultRemarkDialogTitle ?? intl.formatMessage({ id: 'Confirm' })}
            description={
              translations?.defaultRemarkDialogDescription ?? intl.formatMessage({ id: 'adviser_message_delete' })
            }
            showUpdateButton={true}
          />

          <MyToolbar
            editorState={currentRemark}
            onChange={editorState => {
              setEditorState(editorState);
              onChange?.(editorState);
            }}
            ref={toolbarRef}
            editorRef={myEditorRef}
            undo={true}
            textAlign={true}
            list={true}
          />

          <MyEditor
            wrapperClassName="export-remark-input"
            editorState={editorState}
            textAlignment="center"
            onChange={editorState => {
              setEditorState(editorState);
              onChange?.(editorState);
            }}
            ref={myEditorRef}
            toolbarRef={toolbarRef}
          />
        </div>
      }
      actions={[
        <Button
          color="primary"
          onClick={editorState => {
            onSave(editorState);
            onClose();
          }}
          disabled={!onSave || loading}
        >
          {translations?.save ?? intl.formatMessage({ id: 'Save' })}
        </Button>
      ]}
      actionsPrepend={[
        options?.ChatGPTPersonalMessage && controllers?.toggleControl && (
          <Button color="primary" onClick={onChatGPTPersonalMessage}>
            <img src="img/icons/ai.svg" alt="A.I. Pro" width={22} height={22} />
          </Button>
        ),
        <Button color="primary" onClick={openDefaultRemarkDialog} disabled={loading}>
          {translations?.setDefaultRemark ?? intl.formatMessage({ id: 'Default Message' })}
        </Button>
      ]}
      BottomSheetProps={{
        expandOnContentDrag: options?.expandOnContentDrag ?? false,
        disableAutoDismiss: options?.disableAutoDismiss ?? true
      }}
    />
  );
});

export default injectIntl(PopupDraftEditor, { withRef: true });
