import React, { useEffect, useState, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { renderHeaderButton } from '../../../store/headerButtonControl/duck';
import { useDispatch } from 'react-redux';
import './PartnershipDetails.scss';
import Container from '@material-ui/core/Container';
import { sanitize } from 'dompurify';
import { SlideTransition } from '../../../components/Transition/Transition';

const PartnershipDetails = props => {
  const { intl, history } = props;

  const dispatch = useDispatch();
  const [partner, setPartner] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!history.location.state || !history.location.state.back) {
      dispatch(renderHeaderButton(true));
    }
    if (history.location.state && history.location.state.partner) {
      setPartner(history.location.state.partner);
    }
    return () => null;
  }, [history.location]);
  if (!partner) {
    return null;
  }

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);
  const redirect = partner ? () => window.open(partner.link, '_blank') : undefined;

  return (
    <Fragment >
      <Dialog open={open} fullWidth={true} TransitionComponent={SlideTransition}>
        <DialogTitle>{intl.formatMessage({ id: 'partner-redirect-dialog-title' })}</DialogTitle>
        <DialogContent>{intl.formatMessage({ id: 'partner-redirect-dialog-content' })}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button color="primary" onClick={redirect}>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ background: '#fff' }}>
        <Container className="partnership-details" maxWidth="sm">
          <div className="partnership-content">
            {partner.bannerSrc &&
              <div className="partnership-main-img">
                <img src={partner.bannerSrc} />
              </div>
            }
            {!partner.hideTitle &&
              <div className="partnership-title">
                <h3>{partner.title}</h3>
              </div>
            }
            <div
              className="partnership-main-text"
              dangerouslySetInnerHTML={{ __html: sanitize(partner.content) }}
            ></div>
            {partner.terms && <div className="partnership-terms">{partner.terms}</div>}
          </div>
          <div className="more-details" >
            <Button variant="contained" color="primary" onClick={openDialog}>
              {partner.cta ?? intl.formatMessage({ id: 'Partnership-question' })}
            </Button>
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

export default injectIntl(PartnershipDetails);
