import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import NativeOrWeb from '../../../utils/native';
import BottomSheetOrDialog from '../../../components/BottomSheetOrDialog/BottomSheetOrDialog';
import { getPaymentMethodApi, getCustomerCreditsApi } from '../../../store/stripe/api';
import { getChangeablePlansApi, getCouponApi } from '../../../store/userSubscription/api';
import Select from './Select';
import Change from './Change';
import Payment from './Payment';
import Complete from './Complete';

const SubscriptionDialog = props => {
  const {
    history,
    intl,
    userDetails,
    stripePromise,
    open,
    onClose,
    type,
    currentSubscription,
    trialing,
    disableCoupon,
    defaultCoupon
  } = props;

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [option, setOption] = useState('');
  const [coupon, setCoupon] = useState(null);
  const [couponId, setCouponId] = useState('');
  const [couponValue, setCouponValue] = useState(0);
  const [invalidCoupon, setInvalidCoupon] = useState(false);
  const [changeablePlans, setChangeablePlans] = useState([]);
  const [changeablePlanNickname, setChangeablePlanNickname] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [extension, setExtension] = useState(0);
  const [currentCredits, setCurrentCredits] = useState(0);
  const [newCredits, setNewCredits] = useState(0);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [completeTime, setCompleteTime] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();

  useEffect(() => {
    async function getChangeablePlans() {
      setLoading(true);
      let result = await getChangeablePlansApi(currentSubscription._id);
      if (result.success) {
        setExtension(result.extension);
        setNewCredits(result.credits);
        setChangeablePlans(result.changeablePlans);
        if (type === 'change' && result.changeablePlans.length === 1) {
          let plan = result.changeablePlans[0];
          setSelectedPlan(plan);
          if (plan.options.length === 1) {
            setOption(plan.options[0]);
          } else {
            setOption('');
          }
          if (defaultCoupon) {
            applyCoupon(defaultCoupon, plan._id);
          }
        } else if (defaultCoupon) {
          applyCoupon(defaultCoupon);
        }
      }
      setLoading(false);
    }

    async function initializePaymentMethodAndCredits() {
      setLoading(true);
      let [paymentMethodResult, creditsResult] = await Promise.all([
        getPaymentMethodApi(currentSubscription.stripeSubscriptionId),
        getCustomerCreditsApi()
      ]);
      if (paymentMethodResult.defaultPaymentMethod) {
        setDefaultPaymentMethod(paymentMethodResult.defaultPaymentMethod);
      } else {
        setDefaultPaymentMethod(null);
      }
      if (creditsResult.success) {
        setCurrentCredits(creditsResult.credits);
      }
      setLoading(false);
    }

    async function initialize() {
      await getChangeablePlans();
      await initializePaymentMethodAndCredits();
    }

    if (open) {
      if (type === 'change') {
        setStep(2);
        initialize();
      } else {
        setStep(1);
        getChangeablePlans();
        initializePaymentMethodAndCredits();
      }
      setOption('');
      setCoupon(null);
      setCouponId(defaultCoupon);
      setCouponValue(0);
      setInvalidCoupon(false);
      setChangeablePlans([]);
      setChangeablePlanNickname('');
      setSelectedPlan(null);
      setExtension(0);
      setNewCredits(0);
      setErrorMessage('');
      setClientSecret('');
    }
  }, [open]);

  async function applyCoupon(couponId, planId) {
    if (couponId) {
      let result = await getCouponApi(couponId, planId);
      if (result?.coupon) {
        setInvalidCoupon(false);
        setCoupon(result.coupon);
        setCouponValue(result.couponValue || 0);
      } else {
        setInvalidCoupon(true);
        setCoupon('');
        setCouponValue(0);
      }
    } else {
      setInvalidCoupon(false);
      setCoupon('');
      setCouponValue(0);
    }
  }

  function refreshPage() {
    if (!loading) {
      setLoading(true);
      if (clientSecret) {
        var delay = 8000 - (new Date() - completeTime);
      } else {
        var delay = 2000 - (new Date() - completeTime);
      }
      if (delay > 0) {
        const timer = setTimeout(async () => {
          history.go(0);
        }, delay);
        return () => clearTimeout(timer);
      } else {
        history.go(0);
      }
    }
  }

  if (selectedPlan) {
    let newEndDate = moment()
      .add(selectedPlan.intervalCount, selectedPlan.interval)
      .add(extension, 'days');
    if (newEndDate.diff(moment(), 'days') >= 730) {
      var disableExtension = true;
    } else {
      var disableExtension = false;
    }

    if (option === 'endWithCredits') {
      var amountDueToday = (selectedPlan.amount - couponValue - newCredits - currentCredits) / 100;
    } else if (option === 'scheduleChange') {
      var amountDueToday = 0;
    } else {
      var amountDueToday = (selectedPlan.amount - couponValue - currentCredits) / 100;
    }
    if (amountDueToday < 0) {
      amountDueToday = 0;
    }

    if (coupon && (coupon.duration === 'forever' || (coupon.duration === 'repeating' && coupon.InMonths !== 1))) {
      var amountDueNextBillingPeriod = (selectedPlan.amount - couponValue) / 100;
    } else {
      var amountDueNextBillingPeriod = selectedPlan.amount / 100;
    }

    if (option === 'endWithExtension') {
      var nextBillingDate = moment()
        .add(extension, 'days')
        .add(selectedPlan.intervalCount, selectedPlan.interval);
    } else {
      var nextBillingDate = moment().add(selectedPlan.intervalCount, selectedPlan.interval);
    }

    if (intl.locale === 'zh-Hant-HK' || intl.locale === 'zh') {
      var formattedNextBillingDate = nextBillingDate.format('YYYY年M月D日');
      var currentSubscriptionExpiredAt = moment(currentSubscription.expiredAt).format('YYYY年M月D日');
    } else {
      var formattedNextBillingDate = nextBillingDate.format('D MMM YYYY');
      var currentSubscriptionExpiredAt = moment(currentSubscription.expiredAt).format('D MMM YYYY');
    }
  }

  return (
    <BottomSheetOrDialog
      open={open}
      onClose={step === 4 ? refreshPage : onClose}
      header={
        trialing
          ? currentSubscription?.subscriptionPlan.interval === 'year'
            ? intl.formatMessage({ id: 'start-annual-plan-early' })
            : intl.formatMessage({ id: 'start-monthly-plan-early' })
          : intl.formatMessage({ id: `${type}-subscription-plan` })
      }
      content={
        <>
          {NativeOrWeb.isIOSPlatform() && (
            <Typography>
              {intl.formatMessage({ id: 'enterprise-only-content' })}
              <a
                href="https://api.whatsapp.com/send?phone=85266370320&&text=(APP-IOS)%E6%88%91%E6%83%B3%E5%8D%87%E7%B4%9A%E8%A8%82%E9%96%B1%E8%A8%88%E5%8A%83"
                target="_blank"
                style={{ color: 'blue' }}
              >
                PortfoPlus
              </a>
              {intl.formatMessage({ id: '.' })}
            </Typography>
          )}
          {step === 1 && !NativeOrWeb.isIOSPlatform() && (
            <Select
              intl={intl}
              loading={loading}
              onClose={onClose}
              currentSubscription={currentSubscription}
              setStep={setStep}
              setChangeablePlanNickname={setChangeablePlanNickname}
              changeablePlans={changeablePlans}
              couponId={couponId}
              setSelectedPlan={setSelectedPlan}
              setOption={setOption}
              applyCoupon={applyCoupon}
            />
          )}
          {step === 2 && !NativeOrWeb.isIOSPlatform() && (
            <Change
              intl={intl}
              type={type}
              currentSubscription={currentSubscription}
              currentSubscriptionPlan={currentSubscription?.subscriptionPlan}
              trialing={trialing}
              disableCoupon={disableCoupon}
              defaultPaymentMethod={defaultPaymentMethod}
              currentCredits={currentCredits}
              disableExtension={disableExtension}
              amountDueToday={amountDueToday}
              amountDueNextBillingPeriod={amountDueNextBillingPeriod}
              formattedNextBillingDate={formattedNextBillingDate}
              currentSubscriptionExpiredAt={currentSubscriptionExpiredAt}
              loading={loading}
              setLoading={setLoading}
              setStep={setStep}
              option={option}
              setOption={setOption}
              coupon={coupon}
              setCoupon={setCoupon}
              couponId={couponId}
              setCouponId={setCouponId}
              couponValue={couponValue}
              setCouponValue={setCouponValue}
              invalidCoupon={invalidCoupon}
              setInvalidCoupon={setInvalidCoupon}
              changeablePlans={
                changeablePlanNickname
                  ? changeablePlans.filter(plan => plan.nickname === changeablePlanNickname)
                  : changeablePlans
              }
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              extension={extension}
              newCredits={newCredits}
              clientSecret={clientSecret}
              setClientSecret={setClientSecret}
              setErrorMessage={setErrorMessage}
              setCompleteTime={setCompleteTime}
              applyCoupon={applyCoupon}
            />
          )}

          {step === 3 && !NativeOrWeb.isIOSPlatform() && (
            <Payment
              intl={intl}
              userDetails={userDetails}
              stripePromise={stripePromise}
              setStep={setStep}
              option={option}
              selectedPlan={selectedPlan}
              extension={extension}
              newCredits={newCredits}
              clientSecret={clientSecret}
              setErrorMessage={setErrorMessage}
              setCompleteTime={setCompleteTime}
              amountDueToday={amountDueToday}
              amountDueNextBillingPeriod={amountDueNextBillingPeriod}
              nextBillingDate={nextBillingDate}
            />
          )}

          {step === 4 && !NativeOrWeb.isIOSPlatform() && (
            <Complete
              intl={intl}
              type={type}
              currentCredits={currentCredits}
              loading={loading}
              option={option}
              couponValue={couponValue}
              selectedPlan={selectedPlan}
              newCredits={newCredits}
              amountDueNextBillingPeriod={amountDueNextBillingPeriod}
              formattedNextBillingDate={formattedNextBillingDate}
              refreshPage={refreshPage}
            />
          )}

          {errorMessage && (
            <Typography style={{ color: theme.palette.error.main, marginTop: 5 }}>{errorMessage}</Typography>
          )}
        </>
      }
      actions={[]}
      BottomSheetProps={{
        expandOnContentDrag: false,
        disableAutoDismiss: true
      }}
      DialogProps={{
        maxWidth: 'xs',
        fullWidth: true
      }}
      DialogActionsProps={{
        style: { padding: 4 }
      }}
      DialogParams={{ dialogTitleCloseButton: true }}
    />
  );
};

export default withRouter(SubscriptionDialog);
