const special_token_tr = {
    "：": ":",
    " ": "",
}
const fieldTags = {
    "remark.name": {
        special_token: ["客戶姓名", "Client Name"],
        separator: ":-"
    },
    "remark.profile": {
        special_token: ["客戶資料", "Client Profile"],
        separator: ":-"
    },
    "remark.hobbies": {
        special_token: ["習慣/興趣", "Habits / Interest"],
        separator: ":-"
    },
    "remark.family_status": {
        special_token: ["家庭狀況", "Family Status"],
        separator: ":-"
    },
    "remark.financial_status": {
        special_token: ["財務狀況", "Financial Situation"],
        separator: ":-"
    },
    "remark.liquid_asset": {
        special_token: ["可動用資金", "Budget for Insurance Product"],
        separator: ":-"
    },
    "remark.insurance_product": {
        special_token: ["購買的保險產品", "Purchased Insurance Product"],
        separator: ":-"
    },
    "remark.attitude": {
        special_token: ["對保險的態度", "Attitude to Insurance"],
        separator: ":-"
    },
    "remark.next_meeting": {
        ignore: true,
        special_token: ["下次會議的主題和目標", "Themes & Goals for next meeting"],
        separator: ":-"
    },

}
const preEdit = (/**@type {String} */ remark) => {
    let nx = remark;
    Object.entries(special_token_tr).forEach((val) => {
        nx = nx.replaceAll(val[0], val[1]);
    })
    return nx;
}

const getRemarkDataGrid = (remarks) => {
    return remarks.reduce((acc, val) => {
        const next = { ...acc }
        val.forEach(element => {
            Object.entries(fieldTags).forEach((field) => {
                if (field[1].ignore) return;
                const contentKey = field[0];
                const special_token = field[1].special_token;
                // console.log('combineRemark key:', contentKey, ' ');
                // console.log('combineRemark element:', element);
                // console.log('combineRemark special_token:', special_token);
                // console.log('combineRemark element.includes(field.special_token):', element.includes(special_token));
                if (special_token.map((e) => element.includes(e)).includes(true) && !element.endsWith(field[1].separator)) {
                    if (next[contentKey]) {
                        next[contentKey] = [...next[contentKey], element];
                    } else {
                        // console.log('if2 combineRemark element:', element);
                        next[contentKey] = [element];
                    }
                }
            })
        });
        return next;
    }, {});
}

const getCombineRemark = (
    /** @type {String} */ remark) => {
    if (!remark) return;
    if (!remark.includes('📅')) remark;
    const vRemark = preEdit(remark);
    const remarks = vRemark.split('📅')
    const datas = remarks.map((val) => val.split('\n'));
    const dataGrid = getRemarkDataGrid(datas);
    const dataGridLength = Object.keys(dataGrid).length;

    let combinedRemark;
    if (dataGridLength) {
        let nx;
        fieldTags["remark.next_meeting"].special_token.forEach((val) => {
            if (!nx) {
                nx = remarks[remarks.length - 1].split(val);
            } else {
                nx = nx[1].split(val);
            }
        })

        combinedRemark = Object.values(dataGrid).map((val, index) =>
            val[0]).join('\n') + `\n${fieldTags["remark.next_meeting"].special_token}` + nx[nx.length - 1];
    } else {
        combinedRemark = remark;
    }
    return {
        remark: combinedRemark,
        grid: dataGridLength === 0 ? datas : dataGrid,
        length: dataGridLength === 0 ? datas.length : dataGridLength
    };

}


export { getCombineRemark, getRemarkDataGrid }


