import React from 'react';
import { injectIntl } from 'react-intl';
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react';

const WechatQrCodeDescriptionDialog = props => {
  const { intl, open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{intl.formatMessage({ id: 'how-to-find-wechat-qr-code' })}</DialogTitle>
      <DialogContent>
        <Swiper
          updateOnWindowResize={true}
          spaceBetween={0}
          slidesPerView="auto"
          autoHeight={true}
          allowTouchMove={false}
          autoplay={{ delay: 2500 }}
        >
          <SwiperSlide data-hash="wechat-qr-code-step-1" style={{width: "fit-content"}}>
            <img src="img/wechat_qr_code_step_1.png" width={220} height={400} />
          </SwiperSlide>
          <SwiperSlide data-hash="wechat-qr-code-step-2" style={{width: "fit-content"}}>
            <img src="img/wechat_qr_code_step_2.png" width={220} height={400} />
          </SwiperSlide>
          <SwiperSlide data-hash="wechat-qr-code-step-3" style={{width: "fit-content"}}>
            <img src="img/wechat_qr_code_step_3.png" width={220} height={400} />
          </SwiperSlide>
        </Swiper>
      </DialogContent>
    </Dialog>
  );
};

export default injectIntl(WechatQrCodeDescriptionDialog);
