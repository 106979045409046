import React, { useState, useRef, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Prompt } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../Transition/Transition';

const LeavePageConfirm = props => {
  const { intl, history, shouldShow, save, render } = props;

  const [open, setOpen] = useState(false);

  const confirmedRef = useRef(false);
  const actionRef = useRef(null);
  const locationRef = useRef(null);

  const onPromptMessage = (location, action) => {
    if (location.pathname !== history.location.pathname) {
      if (!confirmedRef.current) {
        actionRef.current = action;
        locationRef.current = location;
        setOpen(true);
        return false;
      }
    }
    return true;
  };
  const cancel = () => setOpen(false);
  const exit = () => {
    confirmedRef.current = true;
    if (actionRef && actionRef.current) {
      if (actionRef.current === 'POP') {
        history.goBack();
      } else if (actionRef.current === 'PUSH') {
        history.push(locationRef.current);
      } else if (actionRef.current === 'REPLACE') {
        history.replace(locationRef.current);
      }
    }
  };

  function saveAndExit() {
    save();
    exit();
  }

  useEffect(() => {
    return () => {
      confirmedRef.current = false;
    };
  }, []);

  if (render) {
    return render({
      open,
      setOpen,
      onPromptMessage,
      prompt: <Prompt when={shouldShow} message={onPromptMessage} />,
      cancel,
      exit,
      saveAndExit
    });
  } else
    return (
      <Fragment>
        <Prompt when={shouldShow} message={onPromptMessage} />
        <Dialog open={open} TransitionComponent={SlideTransition}>
          <DialogTitle>{intl.formatMessage({ id: 'leave-page' })}</DialogTitle>
          <DialogContent>{intl.formatMessage({ id: 'changes-that-you-made-will-not-be-saved' })}</DialogContent>
          <DialogActions>
            <Button color="primary" onClick={exit}>
              {intl.formatMessage({ id: "Don't Save" })}
            </Button>
            <div style={{ flex: '1 0 0' }} />
            <Button color="primary" onClick={cancel}>
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button variant="contained" color="primary" onClick={saveAndExit}>
              {intl.formatMessage({ id: 'Save' })}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
};

export default injectIntl(withRouter(LeavePageConfirm));
