import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import MyDetailsProfilePicture from '../../../views/Advisor/MyDetails/MyDetailsProfile/MyDetailsProfilePicture/MyDetailsProfilePicture.js';
import { injectIntl } from 'react-intl';
import { getUserInitial } from '../../../utils/user.js';
import { THEME_COLOR_PALETTES, getValidThemeColorOption } from '../../../constants/my-details.js';
import { NFCPersistentAlert, LinkerProIcon, ActionButtonBar, UpgradeButton } from '../Components/Components.js';

function XL({
  userDetails /** @type {UserDetails} */,
  intl,
  onEditProfile,
  onViewProfile,
  onUpgrade,
  showNoNFCAlert,
  showLinkerProIcon,
  onCardLinkAlertClick
}) {
  const cardStyle = {
    boxShadow: '0px 2px 11.5px rgba(85, 85, 90, 0.16)',
    borderRadius: '16px',
    backgroundColor: '#FFFFFF',
    padding: '20px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px'
  };
  const themeColor = getValidThemeColorOption(userDetails);
  const initial = getUserInitial(userDetails);

  return (
    <Box>
      <Box style={{ height: '36px', width: '36px' }} />
      <Grid container spacing={2} display="flex" alignItems="center">
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant="h4" style={{ fontWeight: '600' }}>
            {intl.formatMessage({ id: 'linker-profile-header-title' })}
          </Typography>
        </Grid>
        <Grid item style={{ zIndex: 1 }}>
          {<NFCPersistentAlert show={showNoNFCAlert} intl={intl} onCardLinkAlertClick={onCardLinkAlertClick} />}
        </Grid>
      </Grid>
      <Box style={{ height: '36px', width: '36px' }} />
      <Box style={cardStyle}>
        <Grid container spacing={2} display="flex" alignItems="center">
          <Grid item>
            <MyDetailsProfilePicture
              width={110}
              initial={initial}
              src={userDetails.avatar}
              borderColor={THEME_COLOR_PALETTES[themeColor || 'blue'][1]}
            />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <Box style={{ marginBottom: '24px' }}>
              <Box style={{ wordBreak: 'break-all', display: 'flex', gap: '10px', alignItems: 'center' }}>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: '600',
                    color: 'black'
                  }}
                >
                  {userDetails.name}
                </Typography>
                <LinkerProIcon showLinkerProIcon={showLinkerProIcon} />
              </Box>
              <Typography variant="body1" style={{ wordBreak: 'break-all', color: '#9F9F9F' }}>
                {userDetails.email}
              </Typography>
            </Box>

            <Grid item>
              <UpgradeButton msg={intl.formatMessage({ id: 'linker-upgrade-cta' })} onUpgrade={onUpgrade} />
            </Grid>
          </Grid>
          <ActionButtonBar intl={intl} onEditProfile={onEditProfile} onViewProfile={onViewProfile} />
        </Grid>
      </Box>
    </Box>
  );
}

export const LinkerAdviserHeaderXL = injectIntl(XL);
