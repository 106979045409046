import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { SlideTransition } from "../../Transition/Transition";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import StyledTextField from "../../StyledTextField/StyledTextField";
import "./QuickNotesRawDialog.scss";
import { scrollAndFocusTextareaEnd } from "../../../utils/dom";
import RecordMeetingRemarkButton from "../../RecordMeetingRemarkButton/RecordMeetingRemarkButton";
import { getRemarkDateLine } from "../../../utils/client";
import { shouldEnableSpeechToText } from "../../../utils/user";
import { GPTProductRecommendationButton } from "../ChatGPTPresetDrawer/GPTProductRecommendation/GPTProductRecommendation";
import CampaignCodeControl from "../../CampaignCodeControl/CampaignCodeControl";

const QuickNotesRawDialog = props => {
  const {
    intl,
    userDetails,
    teamCampaignCodes,
    client,
    remark,
    open,
    onClose,
    onExited,
    onSubmit,
    toggleControl,
    userSubscription,
    ...rest
  } = props;
  const [internalRemark, setInternalRemark] = useState(remark);
  const shouldFullScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const inputRef = useRef(null);
  const gptRecommendationRef = useRef(null);

  const enableSpeechToText = shouldEnableSpeechToText({
    user: userDetails,
    teamCampaignCodes: teamCampaignCodes,
    userSubscription: userSubscription
  });

  const onChange = event => setInternalRemark(event.target.value);

  const appendRemark = text => {
    setInternalRemark(
      `${internalRemark}${internalRemark
        ? `\n${!(internalRemark || "").endsWith("\n") ? "\n" : ""}`
        : ""
      }${text}`
    );
  };

  const submit = () => {
    if (onSubmit) {
      onSubmit(internalRemark);
    }
    onClose();
  };

  const addDate = () => {
    appendRemark(getRemarkDateLine());

    setTimeout(() => {
      if (inputRef.current) {
        const textareaElem = inputRef.current.querySelector("textarea");
        scrollAndFocusTextareaEnd(textareaElem);
      }
    }, 150);
  };

  useEffect(() => {
    setInternalRemark(remark);

    return () => {
      setInternalRemark("");
    };
  }, [remark]);

  return (
    <Dialog
      data-body-scroll-lock-ignore="true"
      className="quick-notes-dialog"
      open={open}
      onClose={onClose}
      onExited={onExited}
      TransitionComponent={SlideTransition}
      fullWidth={true}
      fullScreen={shouldFullScreen}
      PaperProps={
        !shouldFullScreen
          ? {
            style: {
              height: "80%"
            }
          }
          : undefined
      }
      {...rest}
    >
      <DialogTitle disableTypography={true}>
        <Grid
          container
          alignItems="center"
          spacing={1}
          onClick={event => event.preventDefault()}
        >
          <Grid item>{intl.formatMessage({ id: "Quick Notes" })}</Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={addDate}
                  style={{
                    padding: "0px 4px",
                    fontSize: 12,
                    marginLeft: 4,
                    marginRight: 4,
                    minWidth: "unset"
                  }}
                >
                  {intl.formatMessage({ id: "add-date" })}
                </Button>
                {enableSpeechToText && (
                  <RecordMeetingRemarkButton
                    ButtonProps={{
                      color: "primary",
                      size: "small",
                      style: { pointerEvents: "auto" }
                    }}
                    IconProps={{
                      style: { fontSize: 20 }
                    }}
                    onResult={result =>
                      appendRemark(`${getRemarkDateLine()}${result}`)
                    }
                  />
                )}
                <CampaignCodeControl targetCampaignCode={["FEATURE_PRODUCT_RECOMMENDATION_CHUBB", "FEATURE_GPT"]}>
                  {client && (
                    <GPTProductRecommendationButton
                      ref={gptRecommendationRef}
                      useLoadingBackdrop={true}
                      toggleControl={toggleControl}
                      client={client}
                      language={intl.locale}
                    >
                      <Button
                        onClick={() =>
                          gptRecommendationRef.current.handleCheckAndSend?.()
                        }
                        color="primary"
                        variant="outlined"
                        style={{
                          padding: "0px 4px",
                          fontSize: 12,
                          marginLeft: 4,
                          marginRight: 4,
                          minWidth: "unset"
                        }}
                      >
                        <img
                          style={{
                            width: "14px",
                            height: "14px",
                            paddingRight: "2px"
                          }}
                          src="/img/aiGPT.svg"
                        />
                        {intl.formatMessage({
                          id: "gpt-product-recommendation-client-entry-title"
                        })}
                      </Button>
                    </GPTProductRecommendationButton>
                  )}
                </CampaignCodeControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid container spacing={1} justify="space-between">
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography color="primary">{intl.formatMessage({ id: 'Quick Notes' })}</Typography>
              </Grid>
              <Grid item>
                <IconButton size="small">
                  <AlarmAddIcon color="primary" style={{ fontSize: 20 }} onClick={addDate} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          {enableSpeechToText && (
            <Grid item>
              <RecordMeetingRemarkButton
                ButtonProps={{
                  color: 'primary',
                  size: 'small'
                }}
                IconProps={{
                  style: { fontSize: 20 }
                }}
                onResult={result => appendRemark(`${getRemarkDateLine()}${result}`)}
              />
            </Grid>
          )}
        </Grid> */}
      </DialogTitle>
      <DialogContent>
        <StyledTextField
          value={internalRemark}
          onChange={onChange}
          multiline={true}
          InputProps={{ style: { height: "100%" } }}
          style={{ height: "100%" }}
          ref={inputRef}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={submit} color="primary" autoFocus>
          {intl.formatMessage({ id: "OK" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    teamCampaignCodes: state.functions.campaignCodes,
    userSubscription: state.userSubscription.userSubscription
  }),
  {}
)(QuickNotesRawDialog);

export default injectIntl(container);
