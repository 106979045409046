import React, { Fragment, PureComponent } from 'react';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import Phone from 'react-phone-number-input';
import 'react-phone-number-input/rrui.css';
import 'react-phone-number-input/style.css';
import NumberFormat from 'react-number-format';
import countryList from '../../../store/locale/country-list';
import { decimalToString } from '../../../utils/formatNumber';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AladdinHint from '../../../components/AladdinHint/AladdinHint';
import FilesUploadButton from '../../../components/FilesUploadButton/FilesUploadButton';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  InputAdornment,
  Portal,
  Fab,
  Typography
} from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';
import { convertToDateOnly } from '../../../utils/date';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import TodayIcon from '@material-ui/icons/Today';
import MyToggleButton from '../../../components/MyToggleButton/MyToggleButton';

import './styles.scss';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { isNullOrUndefined } from '../../../utils/helpers';
import { getAddOnsIntlId, getTermsIntl, isCategory, isSubCategory } from '../../../utils/policy';
import { getUrlWithProtocol } from '../../../utils/url';
import DeleteIconButton from '../../../components/DeleteIconButton';
import MenuButton from '../../../components/MenuButton/MenuButton';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer.js';
import { TopNavigationPortal } from '../../../components/topNavigation';
import { isEmailValid, formatEmail } from '../../../utils/email';
import { uploadAttachments } from '../../../store/media/api';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';
import { ShortSubscriptions } from '../../../utils/user-subscription';
import { hasCampaignCode, isAdviserType } from '../../../utils/user';
import { ADD_ONS_OPTIONS, TERMS_OPTIONS } from '../../../constants/policy';
import { sanitize } from 'dompurify';
import { getInsurerTitleEn, isInsurerOther } from '../../../utils/insurer';
import { MEDIA_UPLOAD_TYPE } from '../../../constants/media';

const categoryDetails = [
  { id: '59b0eaee14ea7f1aafb6d0b5', title: 'Personal' },
  { id: '59b0eb2314ea7f1aafb6d0b6', title: 'Company' },
  { id: '59b0eb3814ea7f1aafb6d0b7', title: 'General' }
];
const subCategoryDetails = [
  { id: '59b0ed2cdae1831be084f804', title: 'Life' },
  { id: '59b0ed6fdae1831be084f805', title: 'Critical Illness' },
  { id: '59b0ed8fdae1831be084f806', title: 'Medical' },
  { id: '59b0ed98dae1831be084f807', title: 'Other' },
  { id: '59b0edd8dae1831be084f808', title: 'Motor' },
  { id: '59b0eddfdae1831be084f809', title: 'Home' },
  { id: '59b0ede7dae1831be084f80a', title: 'Travel' },
  { id: '5b9a5bfee624cdb543a476d1', title: 'Maid' },
  { id: '5b9a5bfee624cdb543a476d2', title: 'Accident' },
  { id: '5baf2af899d85c2d38c8f00f', title: 'Saving' },
  { id: '5c18ebd120ca05b7e2b32565', title: 'Provident Fund' },
  { id: '5c41b85a83230016450b2214', title: 'Mortgage' }
];

const EditFabStyle = { position: 'fixed', bottom: 'calc(55px + var(--bottom-padding))', right: 12 };

const TriggerButtonsEdit = props => {
  const { intl, title, value, originalValue, isEdit, onEdit, onChange, onCancel, onSave, yesValue, noValue } = props;
  const yes = yesValue !== undefined ? yesValue : true;
  const no = noValue !== undefined ? noValue : false;
  const selectYes = () => onChange(yes);
  const selectNo = () => onChange(no);
  const yesText = intl.formatMessage({ id: 'Yes' });
  const noText = intl.formatMessage({ id: 'No' });

  return (
    <li>
      <div className="left-title">{title}</div>
      <div className="left-value">
        {isEdit ? (
          <Grid container direction="column">
            <Grid item>
              <MyToggleButton value={value} targetValue={yes} onClick={selectYes}>
                {intl.formatMessage({ id: 'Yes' })}
              </MyToggleButton>

              <MyToggleButton value={value} targetValue={no} onClick={selectNo}>
                {intl.formatMessage({ id: 'No' })}
              </MyToggleButton>
            </Grid>

            <Grid item>
              <Button color="primary" variant="contained" className="insure-details-edit-button" onClick={onCancel}>
                {intl.formatMessage({ id: 'Cancel' })}
              </Button>
              <Button color="primary" variant="contained" className="insure-details-edit-button" onClick={onSave}>
                {intl.formatMessage({ id: 'Save' })}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <div className="fs-value" onClick={onEdit}>
            {value || value === false ? (
              value === yes ? (
                yesText
              ) : (
                noText
              )
            ) : originalValue || originalValue === false ? (
              originalValue === yes ? (
                yesText
              ) : (
                noText
              )
            ) : (
              <span className="left-null-value">N/A</span>
            )}
            <EditIcon className="fs-value-edit-icon" />
          </div>
        )}
      </div>
    </li>
  );
};

class InsuredPolicyView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      val: '',
      edit: '',
      policyName: '',
      guaranteeValue: '',
      policyNumber: '',
      currency: '',
      premium: '',
      lifeCoverage: '',
      //protectionAmountInHKD: '',
      sumInsured: '',
      remark: undefined,
      feature: '',
      planType: '',
      addOns: undefined,
      levelPremium: '',
      paymentMethod: '',
      paymentTerms1: '',
      protectionTerms1: '',
      policyOwner: '',
      policyOwnerSelected: '',
      modalIsOpen: false,
      modalIsOpen1: false,
      modalIsOpen3: false,
      lifeInsured: undefined,
      lifeInsuredAge: '',
      paymentTerms: undefined,
      protectionTerms: undefined,
      //annualPremiumInHKD: '',
      beneficial: undefined,
      beneficialSelected: '',
      policyStartDate: null,
      option: false,
      premiumTerms: '',
      life: '',
      categoryType: '',
      subCategoryType: '',
      err: {},
      deductible: '',
      smm: '',
      hospitalCash: '',
      outPatient: '',
      dental: '',
      roomClass: '',
      coverageType: '',
      taxDeductible: '',
      orphanCase: '',
      isDraft: null,
      permanentDisablement: '',
      medicalExpenses: '',
      chineseDoctor: '',
      chineseBonesetter: '',
      physiotherapy: '',
      hospitalCashDetail: '',
      employersLiability: '',
      outPatientDetail: '',
      dentalDetail: '',
      repatriation: '',
      accident: '',
      maidReplacement: '',
      lumpSumAmount: '',
      policyStatus: undefined,
      premiumHolidayDate: null,
      totalWithdrawn: '',
      marketValue: '',
      surrenderValue: '',
      oldValue: '',
      advisorPhoneNo: '',
      advisorPhoneNo2: '',
      propertyValue: '',
      downPayment: '',
      loanRatio: '',
      outStandingLoan: '',
      interestRate: '',
      interestTerms: '',
      penaltyPeriod: '',
      vhisPlan: '',
      claimLimit: '',
      isAnnuity: '',
      paidUpDate: null,
      selectedPolicyStatus: undefined,
      multiClaim: undefined,
      earlyStage: undefined,
      productLink: undefined,
      accountTypes: [],
      marketValueUpdateDate: null,
      isFileUploadLoading: false
    };
    this.save = this.save.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModal1 = this.closeModal1.bind(this);
    this.closeModal3 = this.closeModal3.bind(this);

    const { dashboardPolicyData, policyInfo } = props;
    if (dashboardPolicyData && dashboardPolicyData._id) {
      policyInfo(dashboardPolicyData._id);
    }

    this.fullEditTrigger = false;
  }

  componentDidMount() {
    this.props.renderHeaderButton(false);
  }

  UNSAFE_componentWillMount() {
    const { dashboardPolicyData } = this.props;
    var categoryType = '';
    var subCategoryType = '';
    categoryDetails.map(function(data) {
      if (data.id === dashboardPolicyData.categoryId) {
        categoryType = data.title;
      }
      return true;
    });
    subCategoryDetails.map(function(data) {
      if (data.id === dashboardPolicyData.subCategoryId) {
        subCategoryType = data.title;
      }
      return true;
    });
    this.setState({
      categoryType: categoryType,
      subCategoryType: subCategoryType,
      policyStartDate: dashboardPolicyData.policyStartDate,
      premiumHolidayDate: dashboardPolicyData.premiumHolidayDate,
      vhisPlan:
        dashboardPolicyData.vhisPlan !== undefined &&
        dashboardPolicyData.vhisPlan !== null &&
        dashboardPolicyData.vhisPlan !== ''
          ? dashboardPolicyData.vhisPlan
          : '',
      coverageType: dashboardPolicyData.coverageType,
      taxDeductible: dashboardPolicyData.taxDeductible,
      isAnnuity: _.get(dashboardPolicyData, 'isAnnuity', ''),
      guaranteeValue: _.get(dashboardPolicyData, 'guaranteeValue', ''),
      paidUpDate: dashboardPolicyData.paidUpDate,
      accountTypes: dashboardPolicyData.accountTypes || [],
      marketValueUpdateDate: dashboardPolicyData.marketValueUpdateDate || null
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.fullEditTrigger && nextProps.policyInfoPhase === true) {
      this.fullEditTrigger = false;
      let value = {};
      value.title = _.get(nextProps, 'policyInfoData.insurer', '');
      value.id = _.get(nextProps, 'policyInfoData.insurerId._id', '');
      localStorage.setItem('insurer', JSON.stringify(value));
      let clientFactsheetDetail = {};
      clientFactsheetDetail.clientIncData =
        this.props.location.state && this.props.location.state.clientFactsheetDetail.clientIncData;

      sessionStorage.setItem('editPolicyDetails', JSON.stringify(nextProps.policyInfoData));
      sessionStorage.setItem('editPolicy', JSON.stringify(true));

      this.props.history.push({
        state: {
          clientFactsheetDetail
        },
        pathname: '/plan'
      });
    }
  }

  openModal(index) {
    this.setState({ removeId: index, modalIsOpen: true });
  }

  closeModal1() {
    this.setState({ modalIsOpen1: false });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  closeModal3() {
    this.setState({ modalIsOpen3: false });
  }

  deletePolicy() {
    const { userDetails, dashboardPolicyData, deletePolicy } = this.props;
    const { _id, userId } = dashboardPolicyData || {};
    deletePolicy(_id, isAdviserType(userDetails) ? userId : undefined);
    this.setState({ modalIsOpen: false });
  }

  handleEdit(name, value) {
    if (name === 'premiumTerms') {
      this.setState({ editValue: value });
    }
    this.setState({ oldValue: value, edit: name, [name]: value });
  }

  handleInsurerEdit(val, index) {
    this.setState({
      edit: val.title,
      insurerData: val,
      selectedInsurerIndex: index
    });
  }

  handleLifeInsuredEdit(name, value1, value2) {
    this.setState({
      oldValue: value1,
      edit: name,
      [name]: value1,
      lifeInsuredSelected: value2
    });
  }

  handlePolicyOwnerEdit(name, value1, value2) {
    this.setState({
      oldValue: value1,
      edit: name,
      [name]: value1,
      policyOwnerSelected: value2
    });
  }

  handleBeneficialEdit(name, value1, value2) {
    this.setState({
      oldValue: value1,
      edit: name,
      [name]: value1,
      beneficialSelected: value2
    });
  }

  handleSelectedPolicyStatus(name, value1, value2) {
    this.setState({
      oldValue: value1,
      edit: name,
      [name]: value1,
      selectedPolicyStatus: value2,
      selectedPolicyStatus1: value2
    });
  }

  cancel(name) {
    const { selectedPolicyStatus1 } = this.state;
    if (this.state.edit === 'premiumTerms') {
      this.setState({ editValue: '' });
    }
    if (name === 'policyStatus') {
      this.setState({ selectedPolicyStatus: selectedPolicyStatus1 });
    }
    this.setState({ name: '', edit: '', [name]: this.state.oldValue, err: {} });
  }

  save(fieldValue, name, premium) {
    const {
      categoryType,
      lifeInsuredSelected,
      selectedPolicyStatus,
      policyStatus,
      policyOwnerSelected,
      beneficialSelected
    } = this.state;

    const err = {};
    var paymentTerms = '';
    var protectionTerms = '';
    let id = this.props.dashboardPolicyData._id;
    const data = {};

    data.id = id;

    if (name === 'orphanCase') {
      data.orphanCase = this.state.orphanCase;
    }
    if (name === 'isDraft') {
      data.isDraft = this.state.isDraft;
    }
    if (name === 'premium' || name === 'premiumTerms') {
      if (Number.isInteger(fieldValue) === true) {
        data.premium = fieldValue;
      } else {
        data.premium = parseFloat(premium).toFixed(2);
      }

      localStorage.setItem('payment', JSON.stringify(data));
      this.setState({
        premium: premium
      });
    } else if (name === 'lifeCoverage' || name === 'premiumTerms') {
      if (Number.isInteger(fieldValue) === true) {
        data.lifeCoverage = fieldValue;
      } else {
        data.lifeCoverage = parseFloat(fieldValue).toFixed(2);
      }
      localStorage.setItem('payment', JSON.stringify(data));
      this.setState({
        lifeCoverage: fieldValue
      });
    } else if (name === 'sumInsured') {
      data.sumInsured = fieldValue !== '' ? fieldValue : 0;
    } else if (name === 'advisorPhoneNo' && fieldValue === undefined) {
      data[name] = null;
    } else if (name === 'advisorPhoneNo2' && fieldValue === undefined) {
      data[name] = null;
    } else if (name === 'advisorEmail') {
      data[name] = formatEmail(fieldValue);
    } else {
      data[name] = fieldValue;
    }
    if (name === 'advisorEmail' && fieldValue !== '' && !isEmailValid(fieldValue)) {
      err.advisorEmail = 'Invalid email';
    }
    if (categoryType === 'Personal' || categoryType === 'General') {
      if (name === 'policyName' && fieldValue === '') {
        err.policyName = 'Enter Policy Name.';
      }
    }
    if (categoryType !== 'General' && categoryType !== 'Company') {
      if (this.state.edit === 'feature') {
        if (this.state.feature === '') {
          err.feature = 'Please pick one.';
        }
      }
      if (this.state.edit === 'companyName') {
        if (this.state.companyName === '') {
          err.companyName = 'Enter Workplace Name.';
        }
      }
      if (this.state.edit === 'sumInsured') {
        if (this.state.sumInsured === '') {
          err.sumInsured = 'Enter C.I. Coverage Amount.';
        }
      }
      if (this.state.edit === 'coverageType') {
        if (this.state.coverageType === '') {
          err.coverageType = 'Please choose coverage type.';
        }
      }
      if (this.state.edit === 'roomClass') {
        if (this.state.roomClass === '') {
          err.roomClass = 'Please choose room class.';
        }
      }
    }
    if (categoryType === 'Company') {
      if (this.state.edit === 'companyName') {
        if (this.state.companyName === '') {
          err.companyName = 'Enter Workplace Name.';
        }
      }
    }
    if (this.state.edit === 'planType') {
      if (this.state.planType === '') {
        err.planType = 'Please pick one.';
      }
    }
    if (this.state.edit === 'levelPremium') {
      if (this.state.levelPremium === '') {
        err.levelPremium = 'Please pick one.';
      }
    }
    if (this.state.edit === 'vhisPlan') {
      if (this.state.vhisPlan === '') {
        err.vhisPlan = 'Please choose VHIS plan';
      }
    }
    if (this.state.edit === 'guaranteeValue') {
      if (this.state.guaranteeValue === '') {
        err.guaranteeValue = 'Please pick one.';
      }
    }
    if (this.state.edit === 'isAnnuity') {
      if (
        this.state.isAnnuity === '' &&
        (this.state.guaranteeValue === 'Yes' || this.state.guaranteeValue === 'No Idea')
      ) {
        err.isAnnuity = 'Please pick one.';
      }
    }

    if (this.state.edit === 'orphanCase') {
      if (!this.state.orphanCase || this.state.orphanCase === '' || this.state.orphanCase === null) {
        err.orphanCase = 'Please pick one.';
      }
    }
    if (this.state.edit === 'premiumTerms') {
      if (this.state.editValue === '') {
        err.premiumTerms = 'Please pick one.';
      }
    }
    if (this.state.edit === 'premiumTerms') {
      if (this.state.editValue === '') {
        err.premiumTerms = 'Please pick one.';
      }
    }
    if (name === 'lifeCoverage' && fieldValue === '') {
      if (categoryType !== 'General') {
        err.lifeCoverage = 'Enter Life Coverage.';
      } else {
        err.lifeCoverage = 'Enter Coverage Amount.';
      }
    }
    if (name === 'premium' && fieldValue === '') {
      err.premium = 'Enter Premium.';
    }
    this.setState({ err });
    if (name === 'advisorEmail') {
      if (Object.keys(err).length) {
        this.setState({ edit: 'advisorEmail' });
      }
    }
    if (name === 'policyName') {
      if (Object.keys(err).length) {
        this.setState({ edit: 'policyName' });
      }
    }
    if (name === 'advisorPhoneNo') {
      if (Object.keys(err).length) {
        this.setState({ edit: 'advisorPhoneNo' });
      }
    }
    if (name === 'advisorPhoneNo2') {
      if (Object.keys(err).length) {
        this.setState({ edit: 'advisorPhoneNo2' });
      }
    }

    if (name === 'lifeInsured') {
      if (lifeInsuredSelected === undefined || lifeInsuredSelected === '') {
        err.lifeInsuredSelected = 'Please choose life insured';
      } else {
        data.lifeInsuredSelected = lifeInsuredSelected;
      }
    }
    if (name === 'policyOwner') {
      data.policyOwnerSelected = policyOwnerSelected;
    }
    if (name === 'beneficial') {
      data.beneficialSelected = beneficialSelected;
    }
    if (!Object.keys(err).length) {
      this.setState({
        [name]: fieldValue,
        edit: '',
        //premiumTerms: this.state.edit === 'premiumTerms' ? this.state.editValue : '',
        editValue: '',
        oldValue: ''
      });
      if (this.state.edit === 'paymentTerms') {
        if (this.state.paymentTerms === 'Other') {
          paymentTerms = this.state.paymentTerms1;
          data.paymentTerms = paymentTerms;
        } else {
          paymentTerms = this.state.paymentTerms;
          data.paymentTerms = paymentTerms;
        }
        this.setState({ paymentTerms: paymentTerms });
      }
      if (this.state.edit === 'protectionTerms') {
        if (this.state.protectionTerms === 'Other') {
          protectionTerms = this.state.protectionTerms1;
          data.protectionTerms = protectionTerms;
        } else {
          protectionTerms = this.state.protectionTerms;
          data.protectionTerms = protectionTerms;
        }
        this.setState({ protectionTerms: protectionTerms });
      }
      if (data.vhisPlan !== undefined && data.vhisPlan !== 'Yes' && this.state.coverageType === 'Flexi') {
        data.coverageType = '';
        this.setState({ coverageType: '' });
      }
      if (data.guaranteeValue === 'No') {
        data.isAnnuity = '';
        this.setState({ isAnnuity: '' });
      }
      if (name === 'policyStatus') {
        data.selectedPolicyStatus = selectedPolicyStatus;
        data.policyStatus = policyStatus || '';
        this.setState({
          selectedPolicyStatus: selectedPolicyStatus,
          policyStatus: policyStatus || ''
        });
      }
      if (name === 'advisorEmail') {
        this.setState({ advisorEmail: formatEmail(this.state.advisorEmail) });
      }

      this.proceedEditPolicy(data);
    }
  }

  handleChange(e) {
    let name = e.target.name;
    if (e.target.name === 'advisorEmail') {
      this.setState({ err: {} });
    }
    if (
      name === 'lifeCoverage' ||
      name === 'sumInsured' ||
      name === 'premium' ||
      name === 'lifeInsuredAge' ||
      name === 'advisorPhoneNo' ||
      name === 'advisorPhoneNo2'
    ) {
      if (isNaN(e.target.value)) {
        return;
      }
    }
    if (e.target.name === 'policyName') {
      delete this.state.err.policyName;
    }
    if (
      (name === 'lifeCoverage' || name === 'premium' || name === 'sumInsured') &&
      e.target.value !== '' &&
      e.target.value.indexOf('.') === -1
    ) {
      this.setState({ [e.target.name]: parseInt(e.target.value) });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  handleDateChange(stateName) {
    this.setState({ edit: '' });
    const data = {};
    data[stateName] = convertToDateOnly(this.state[stateName]);
    data.id = this.props.dashboardPolicyData._id;
    this.proceedEditPolicy(data);
  }

  handleChangePremiumDate() {
    this.setState({ edit: '' });
    const data = {};
    let date =
      this.state.premiumHolidayDate !== null &&
      this.state.premiumHolidayDate !== undefined &&
      this.state.premiumHolidayDate !== ''
        ? moment(this.state.premiumHolidayDate).format('YYYY-MM-DD')
        : null;
    data.premiumHolidayDate = date !== null ? moment.utc(date).format('YYYY-MM-DDT00:00:00.00Z') : null;
    data.id = this.props.dashboardPolicyData._id;
    this.proceedEditPolicy(data);
  }

  proceedEditPolicy = data => {
    const { userDetails, dashboardPolicyData, editPolicy } = this.props;
    const { userId } = dashboardPolicyData || {};
    editPolicy(data, isAdviserType(userDetails) ? userId : undefined);
  };

  editPolicy() {
    // Avoid copying data saved in localstorage in AddPolicy view
    localStorage.removeItem('category');
    localStorage.removeItem('subCategory');
    localStorage.removeItem('insurer');
    localStorage.removeItem('plan');
    localStorage.removeItem('payment');
    localStorage.removeItem('detail');
    localStorage.removeItem('apendix');
    localStorage.removeItem('editPlan');
    localStorage.removeItem('editPayment');
    localStorage.removeItem('editDetail');
    localStorage.removeItem('editApendix');
    const { dashboardPolicyData } = this.props;
    let data = dashboardPolicyData._id;
    this.fullEditTrigger = true;
    this.props.policyInfo(data);
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  insurerInfo(val) {
    let ins = this.state.insurerData ? this.state.insurerData : val.insurerId;
    this.props.history.push({
      pathname: '/insurer_detail',
      state: { analysisInfo: ins, showEmergency: _.get(ins, 'title', '') === 'Other' ? true : false }
    });
  }

  handlePhoneNumber(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  openInsModal() {
    this.setState({ modalIsOpen1: true });
  }

  isNullOrEmpty(str) {
    return !str.trim();
  }

  render() {
    const { intl, history, userDetails, subscriptions, teamCampaignCodes, dashboardPolicyData } = this.props;
    const isFreeTrial = new ShortSubscriptions(subscriptions).isCoreSubscriptionFreeTrial();
    const isShowFileAttachments = !isFreeTrial && hasCampaignCode(userDetails, teamCampaignCodes, 'POLICYFILE');

    const {
      policyName,
      guaranteeValue,
      companyName,
      policyNumber,
      feature,
      planType,
      addOns,
      levelPremium,
      paymentMethod,
      policyOwner,
      policyOwnerSelected,
      lifeInsured,
      lifeInsuredSelected,
      lifeInsuredAge,
      paymentTerms,
      protectionTerms,
      beneficial,
      beneficialSelected,
      advisorName,
      advisorPhoneNo,
      advisorPhoneNo2,
      advisorEmail,
      productLink,
      remark,
      emergencyTel,
      rentalCarCover,
      mobilePhoneCover,
      lossItemCover,
      tripDelayCover,
      coverageDay,
      homeContentsValue,
      homeSize,
      ncb,
      vehicleYear,
      vehicleBrand,
      vehicleModel,
      coverageDetail,
      deductible,
      subCategoryType,
      categoryType,
      smm,
      hospitalCash,
      outPatient,
      dental,
      orphanCase,
      isDraft,
      insurerData,
      permanentDisablement,
      physiotherapy,
      medicalExpenses,
      chineseDoctor,
      chineseBonesetter,
      hospitalCashDetail,
      employersLiability,
      maidReplacement,
      //lumpSumAmount,
      policyStatus,
      propertyValue,
      downPayment,
      loanRatio,
      outStandingLoan,
      interestRate,
      interestTerms,
      penaltyPeriod,
      vhisPlan,
      claimLimit,
      isAnnuity,
      selectedPolicyStatus,
      accountTypes
    } = this.state;
    var val = dashboardPolicyData;
    let premiumTermsValue = '';
    let frequencyData = this.state.premiumTerms ? this.state.premiumTerms : val.frequency;

    if (!this.isEmpty(dashboardPolicyData)) {
      var lifeCoverage = this.state.lifeCoverage !== '' ? this.state.lifeCoverage : val.lifeCoverage;
      if (Number.isInteger(lifeCoverage) === true || lifeCoverage.toString().indexOf('.') === -1) {
        lifeCoverage = lifeCoverage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        lifeCoverage =
          lifeCoverage &&
          parseFloat(lifeCoverage)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }

      //comment code to create the protectionAmountInHKD  key to policy

      // var protectionAmountInHKD = this.state.protectionAmountInHKD
      //   ? parseFloat(this.state.protectionAmountInHKD)
      //   : currencyExchange(
      //       parseFloat(val.lifeCoverage ? val.lifeCoverage : 0),
      //       'HKD',
      //       val.currency
      //     );
      // if (Number.isInteger(protectionAmountInHKD) === true) {
      //   protectionAmountInHKD = protectionAmountInHKD
      //     .toString()
      //     .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // } else {
      //   protectionAmountInHKD =
      //     protectionAmountInHKD &&
      //     parseFloat(protectionAmountInHKD)
      //       .toFixed(2)
      //       .toString()
      //       .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // }
      var premium = this.state.premium !== '' ? this.state.premium : val.premium;
      if (Number.isInteger(premium) === true || premium.toString().indexOf('.') === -1) {
        premium = premium.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        premium =
          premium &&
          parseFloat(premium)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      var lumpSumAmount = this.state.lumpSumAmount !== '' ? this.state.lumpSumAmount : val.lumpSumAmount;
      if (lumpSumAmount === null || lumpSumAmount === undefined) {
        lumpSumAmount = lumpSumAmount && parseFloat(lumpSumAmount);
      } else if (Number.isInteger(lumpSumAmount) === true || lumpSumAmount.toString().indexOf('.') === -1) {
        lumpSumAmount = lumpSumAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        lumpSumAmount =
          lumpSumAmount &&
          parseFloat(lumpSumAmount)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      var totalWithdrawn = this.state.totalWithdrawn !== '' ? this.state.totalWithdrawn : val.totalWithdrawn;
      if (totalWithdrawn === null || totalWithdrawn === undefined) {
        totalWithdrawn = totalWithdrawn && parseFloat(totalWithdrawn);
      } else if (Number.isInteger(totalWithdrawn) === true || totalWithdrawn.toString().indexOf('.') === -1) {
        totalWithdrawn = totalWithdrawn.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        totalWithdrawn =
          totalWithdrawn &&
          parseFloat(totalWithdrawn)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      var marketValue = this.state.marketValue !== '' ? this.state.marketValue : val.marketValue;
      if (marketValue === null || marketValue === undefined) {
        marketValue = marketValue && parseFloat(marketValue);
      } else if (Number.isInteger(marketValue) === true || marketValue.toString().indexOf('.') === -1) {
        marketValue = marketValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        marketValue =
          marketValue &&
          parseFloat(marketValue)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      var surrenderValue = this.state.surrenderValue !== '' ? this.state.surrenderValue : val.surrenderValue;
      if (surrenderValue === null || surrenderValue === undefined) {
        surrenderValue = surrenderValue && parseFloat(surrenderValue);
      } else if (Number.isInteger(surrenderValue) === true || surrenderValue.toString().indexOf('.') === -1) {
        surrenderValue = surrenderValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        surrenderValue =
          surrenderValue &&
          parseFloat(surrenderValue)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }

      /*comment code to create the annualPremiumInHKD  key to policy*/

      // var annualPremiumInHKD = this.state.annualPremiumInHKD
      //   ? parseFloat(this.state.annualPremiumInHKD)
      //   : currencyExchange(
      //       parseFloat(val.premium ? val.premium : 0),
      //       'HKD',
      //       val.currency
      //     ) * premiumTime;
      // if (
      //   Number.isInteger(annualPremiumInHKD) === true ||
      //   annualPremiumInHKD.toString().indexOf('.') == -1
      // ) {
      //   annualPremiumInHKD = annualPremiumInHKD
      //     .toString()
      //     .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // } else {
      //   annualPremiumInHKD =
      //     annualPremiumInHKD &&
      //     parseFloat(annualPremiumInHKD)
      //       .toFixed(2)
      //       .toString()
      //       .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // }
      var sumInsured = this.state.sumInsured !== '' ? this.state.sumInsured : val.sumInsured;
      // val.sumInsured && val.sumInsured.indexOf('.') === -1
      // ? parseInt(val.sumInsured)
      // : val.sumInsured;
      if (sumInsured !== '' && sumInsured !== undefined && sumInsured !== null) {
        if (Number.isInteger(sumInsured) === true || sumInsured.toString().indexOf('.') === -1) {
          sumInsured = sumInsured.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
          sumInsured =
            sumInsured &&
            parseFloat(sumInsured)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      }
      if (frequencyData === '1') {
        premiumTermsValue = 'Per Month';
      }
      if (frequencyData === '3') {
        premiumTermsValue = 'Per Quarter';
      }
      if (frequencyData === '6') {
        premiumTermsValue = 'Per Half Year';
      }
      if (frequencyData === '12') {
        premiumTermsValue = 'Per Year';
      }
    }
    var showIntl = true;
    if (!this.isEmpty(dashboardPolicyData)) {
      if (
        getInsurerTitleEn(dashboardPolicyData.insurerId) !== val.insurer ||
        (insurerData && getInsurerTitleEn(insurerData) !== val.insurer)
      ) {
        showIntl = false;
      }
    }

    const isOtherInsurer = isInsurerOther(val);
    return (
      <div>
        <Portal container={document.body}>
          <Fab color="primary" size="medium" variant="round" onClick={this.editPolicy.bind(this)} style={EditFabStyle}>
            <EditIcon />
          </Fab>
        </Portal>

        <TopNavigationPortal>
          <span onClick={() => history.goBack()} className="back_arrow">
            <ArrowBackIcon />
          </span>

          {/* <DeleteIconButton className="edit__pen" handleClick={this.openModal.bind(this)} /> */}
          <MenuButton
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              zIndex: 1043,
              color: '#fff'
            }}
            menuItems={[
              {
                title: intl.formatMessage({ id: 'Delete' }),
                icon: DeleteIcon,
                handler: this.openModal.bind(this)
              },
              isAdviserType(userDetails) && {
                title: intl.formatMessage({ id: 'Move Policy' }),
                icon: LowPriorityIcon,
                handler: () => {
                  history.push({
                    pathname: '/client',
                    state: {
                      action: 'movePolicy',
                      path: 'insured_details',
                      portfolioToMove: dashboardPolicyData
                    }
                  });
                }
              }
            ]}
          />
        </TopNavigationPortal>

        {!this.isEmpty(dashboardPolicyData) && (
          <div id="wrapper">
            <div id="insuredDetailCSS" style={{ paddingBottom: '120px' }}>
              <div className="container-fluid">
                <div className="row">
                  <div>
                    <div className="listPipeline_details">
                      <div>
                        <ul>
                          <li
                            className="head"
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                          >
                            <AladdinHint title={intl.formatMessage({ id: 'click-for-edit' })} />
                          </li>
                          <li>
                            <div className="left-title">
                              {val.subCategoryId === '5c41b85a83230016450b2214'
                                ? intl.formatMessage({ id: 'MortageCompany' })
                                : val.subCategoryId === '5c18ebd120ca05b7e2b32565'
                                ? intl.formatMessage({ id: 'pf-trustee' })
                                : intl.formatMessage({ id: 'Insurer' })}
                              :
                            </div>
                            <div className="left-value">
                              <div className="info-btn-text-insured-detail payment-info-detail">
                                <span className="ins-info InsurerTitle" onClick={this.openInsModal.bind(this, val)}>
                                  {showIntl ? (
                                    insurerData ? (
                                      insurerData.title[intl.locale]
                                    ) : (
                                      <img alt="" src={val.insurerId.image} className="InsurerImg" />
                                    )
                                  ) : insurerData ? (
                                    insurerData.title[intl.locale]
                                  ) : (
                                    val.insurer
                                  )}
                                </span>
                                {!isOtherInsurer && (
                                  <Fragment>
                                    &nbsp;
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      style={{ padding: '0px', minWidth: '40px' }}
                                      onClick={this.insurerInfo.bind(this, val)}
                                    >
                                      {intl.formatMessage({ id: 'Info' })}
                                    </Button>
                                  </Fragment>
                                )}
                              </div>
                            </div>
                          </li>

                          {(val.categoryId === '59b0eb2314ea7f1aafb6d0b6' &&
                            val.subCategoryId === '59b0ed98dae1831be084f807') ||
                          (val.categoryId === '59b0eb3814ea7f1aafb6d0b7' &&
                            val.subCategoryId === '59b0ed98dae1831be084f807') ||
                          (val.categoryId === '59b0eaee14ea7f1aafb6d0b5' &&
                            val.subCategoryId === '59b0ed98dae1831be084f807') ? (
                            <li>
                              <div className="left-title">{intl.formatMessage({ id: 'Type' })}:</div>
                              <span className="left-value">
                                {intl.formatMessage({ id: 'Other' })} {this.state.type ? this.state.type : val.type}
                              </span>
                            </li>
                          ) : (
                            <li>
                              <div className="left-title">{intl.formatMessage({ id: 'Type' })}:</div>
                              <div className="left-value">
                                <div>
                                  {this.state.categoryType === 'Company'
                                    ? intl.formatMessage({ id: 'Workplace' })
                                    : intl.formatMessage({
                                        id: this.state.categoryType
                                      })}{' '}
                                  {intl.formatMessage({
                                    id: this.state.subCategoryType
                                  })}
                                </div>
                              </div>
                            </li>
                          )}

                          {val.categoryId !== '59b0eb2314ea7f1aafb6d0b6' ? (
                            <li>
                              <div className="left-title">
                                {val.subCategoryId === '5c18ebd120ca05b7e2b32565'
                                  ? intl.formatMessage({ id: 'pf-plan-name' })
                                  : intl.formatMessage({ id: 'Policy Name' })}
                                :
                              </div>
                              <div className="left-value">
                                {this.state.edit === 'policyName' ? (
                                  <div>
                                    <input
                                      type="text"
                                      name="policyName"
                                      className="left-value-input"
                                      onChange={this.handleChange.bind(this)}
                                      value={policyName || ''}
                                    />
                                    {this.state.err.policyName ? (
                                      <div className="error_field">
                                        {intl.formatMessage({
                                          id: this.state.err.policyName
                                        })}
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.cancel.bind(this, 'policyName')}
                                    >
                                      {intl.formatMessage({ id: 'Cancel' })}
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.save.bind(this, policyName, 'policyName')}
                                    >
                                      {intl.formatMessage({ id: 'Save' })}
                                    </Button>
                                  </div>
                                ) : (
                                  <div
                                    className="fs-value"
                                    onClick={this.handleEdit.bind(
                                      this,
                                      'policyName',
                                      policyName ? policyName : val.policyName
                                    )}
                                  >
                                    {policyName && !this.isNullOrEmpty(policyName) ? (
                                      policyName
                                    ) : val.policyName && !this.isNullOrEmpty(val.policyName) ? (
                                      val.policyName
                                    ) : (
                                      <span
                                        className="left-null-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'policyName',
                                          policyName ? policyName : val.policyName
                                        )}
                                      >
                                        N/A
                                      </span>
                                    )}
                                    <EditIcon className="fs-value-edit-icon" />
                                  </div>
                                )}
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="left-title">{intl.formatMessage({ id: 'Workplace Name' })}:</div>
                              <div className="left-value">
                                {this.state.edit === 'companyName' ? (
                                  <div>
                                    <input
                                      type="text"
                                      name="companyName"
                                      className="left-value-input"
                                      onChange={this.handleChange.bind(this)}
                                      value={companyName || ''}
                                    />
                                    {this.state.err.companyName ? (
                                      <div className="error_field">
                                        {intl.formatMessage({
                                          id: this.state.err.companyName
                                        })}
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.cancel.bind(this, 'companyName')}
                                    >
                                      {intl.formatMessage({ id: 'Cancel' })}
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.save.bind(this, companyName, 'companyName')}
                                    >
                                      {intl.formatMessage({ id: 'Save' })}
                                    </Button>
                                  </div>
                                ) : (
                                  <div
                                    className="fs-value"
                                    onClick={this.handleEdit.bind(
                                      this,
                                      'companyName',
                                      companyName ? companyName : val.companyName
                                    )}
                                  >
                                    {companyName && !this.isNullOrEmpty(companyName) ? (
                                      companyName
                                    ) : val.companyName && !this.isNullOrEmpty(val.companyName) ? (
                                      val.companyName
                                    ) : (
                                      <span
                                        className="left-null-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'companyName',
                                          companyName ? companyName : val.companyName
                                        )}
                                      >
                                        N/A
                                      </span>
                                    )}
                                    <EditIcon className="fs-value-edit-icon" />
                                  </div>
                                )}
                              </div>
                            </li>
                          )}

                          {val.subCategoryId === '5baf2af899d85c2d38c8f00f' && (
                            <div>
                              <li>
                                <div className="left-title">
                                  {intl.formatMessage({
                                    id: 'Got Guarantee Value'
                                  })}
                                  :
                                </div>
                                <div className="left-value">
                                  <div
                                    onClick={() => {
                                      this.setState({ modalIsOpen1: true });
                                    }}
                                  >
                                    {intl.formatMessage({
                                      id: val.guaranteeValue
                                    })}
                                  </div>
                                </div>
                              </li>
                              {(guaranteeValue === 'Yes' || guaranteeValue === 'No Idea') && (
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'annuity-plan'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'isAnnuity' ? (
                                      <div>
                                        <Button
                                          variant={this.state.isAnnuity === 'Yes' ? 'contained' : 'outlined'}
                                          className={
                                            this.state.isAnnuity === 'Yes'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.isAnnuity;
                                            this.setState({
                                              isAnnuity: this.state.isAnnuity === 'Yes' ? '' : 'Yes'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({ id: 'Yes' })}
                                        </Button>

                                        <Button
                                          variant={this.state.isAnnuity === 'No' ? 'contained' : 'outlined'}
                                          className={
                                            this.state.isAnnuity === 'No'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.isAnnuity;
                                            this.setState({
                                              isAnnuity: this.state.isAnnuity === 'No' ? '' : 'No'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({ id: 'No' })}
                                        </Button>

                                        <Button
                                          variant={this.state.isAnnuity === 'No Idea' ? 'contained' : 'outlined'}
                                          className={
                                            this.state.isAnnuity === 'No Idea'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.isAnnuity;
                                            this.setState({
                                              isAnnuity: this.state.isAnnuity === 'No Idea' ? '' : 'No Idea'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id: 'No Idea'
                                          })}
                                        </Button>

                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'isAnnuity')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, isAnnuity, 'isAnnuity')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                        <i className="bar" />
                                        {this.state.err.isAnnuity ? (
                                          <div className="error_field">
                                            {intl.formatMessage({
                                              id: this.state.err.isAnnuity
                                            })}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'isAnnuity',
                                          isAnnuity ? isAnnuity : val.isAnnuity ? val.isAnnuity : ''
                                        )}
                                      >
                                        {isAnnuity ? (
                                          intl.formatMessage({
                                            id: isAnnuity === 'No' ? 'No' : isAnnuity
                                          })
                                        ) : val.isAnnuity ? (
                                          intl.formatMessage({
                                            id: val.isAnnuity === 'No' ? 'No' : val.isAnnuity
                                          })
                                        ) : (
                                          <span style={{ opacity: 0 }}>"N/A"</span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                              )}
                            </div>
                          )}

                          <li>
                            <div className="left-title">
                              {val.subCategoryId === '5c18ebd120ca05b7e2b32565'
                                ? intl.formatMessage({ id: 'pf-member-number' })
                                : intl.formatMessage({ id: 'Policy Number' })}
                              :
                            </div>
                            <div className="left-value">
                              {this.state.edit === 'policyNumber' ? (
                                <div>
                                  <input
                                    type="text"
                                    name="policyNumber"
                                    className="left-value-input"
                                    onChange={this.handleChange.bind(this)}
                                    value={policyNumber || ''}
                                  />
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="insure-details-edit-button"
                                    onClick={this.cancel.bind(this, 'policyNumber')}
                                  >
                                    {intl.formatMessage({ id: 'Cancel' })}
                                  </Button>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="insure-details-edit-button"
                                    onClick={this.save.bind(this, policyNumber, 'policyNumber')}
                                  >
                                    {intl.formatMessage({ id: 'Save' })}
                                  </Button>
                                </div>
                              ) : (
                                <div
                                  className="fs-value"
                                  onClick={this.handleEdit.bind(
                                    this,
                                    'policyNumber',
                                    policyNumber ? policyNumber : val.policyNumber
                                  )}
                                >
                                  {policyNumber && !this.isNullOrEmpty(policyNumber) ? (
                                    policyNumber
                                  ) : val.policyNumber && !this.isNullOrEmpty(val.policyNumber) ? (
                                    val.policyNumber
                                  ) : (
                                    <span
                                      className="left-null-value"
                                      onClick={this.handleEdit.bind(
                                        this,
                                        'policyNumber',
                                        policyNumber ? policyNumber : val.policyNumber
                                      )}
                                    >
                                      N/A
                                    </span>
                                  )}
                                  <EditIcon className="fs-value-edit-icon" />
                                </div>
                              )}
                            </div>
                          </li>

                          {val.subCategoryId === '5c18ebd120ca05b7e2b32565' && (
                            <li>
                              <div className="left-title">
                                {intl.formatMessage({
                                  id: 'account-types'
                                })}
                                :
                              </div>
                              <div className="left-value">
                                {this.state.edit === 'accountTypes' ? (
                                  <div>
                                    {['personal', 'voluntary', 'contribution', 'provident-fund'].map(choice => {
                                      const isSelected = (accountTypes || []).findIndex(v => v === choice) > -1;
                                      return (
                                        <Button
                                          key={choice}
                                          variant={isSelected ? 'contained' : 'outlined'}
                                          className={
                                            isSelected
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            this.setState({
                                              accountTypes: isSelected
                                                ? (accountTypes || []).filter(v => v !== choice)
                                                : [...accountTypes, choice]
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({ id: `at-${choice}` })}
                                        </Button>
                                      );
                                    })}

                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.cancel.bind(this, 'accountTypes')}
                                    >
                                      {intl.formatMessage({ id: 'Cancel' })}
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.save.bind(this, accountTypes, 'accountTypes')}
                                    >
                                      {intl.formatMessage({ id: 'Save' })}
                                    </Button>

                                    <i className="bar" />
                                  </div>
                                ) : (
                                  <div
                                    className="fs-value"
                                    onClick={this.handleEdit.bind(
                                      this,
                                      'accountTypes',
                                      accountTypes ? accountTypes : val.accountTypes
                                    )}
                                  >
                                    {accountTypes && accountTypes.length > 0 ? (
                                      accountTypes.map(v => intl.formatMessage({ id: `at-${v}` })).join(' ')
                                    ) : val.accountTypes && val.accountTypes.length > 0 ? (
                                      val.accountTypes.map(v => intl.formatMessage({ id: `at-${v}` })).join(' ')
                                    ) : (
                                      <span style={{ opacity: 0 }}>"N/A"</span>
                                    )}
                                    <EditIcon className="fs-value-edit-icon" />
                                  </div>
                                )}
                              </div>
                            </li>
                          )}

                          {val.subCategoryId === '59b0ed8fdae1831be084f806' ? (
                            <div>
                              {val.categoryId === '59b0eaee14ea7f1aafb6d0b5' && (
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'VHIS Plan'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'vhisPlan' ? (
                                      <div>
                                        <Button
                                          variant={this.state.vhisPlan === 'Yes' ? 'contained' : 'outlined'}
                                          className={
                                            this.state.vhisPlan === 'Yes'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.vhisPlan;
                                            this.setState({
                                              vhisPlan: this.state.vhisPlan === 'Yes' ? '' : 'Yes'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({ id: 'Yes' })}
                                        </Button>

                                        <Button
                                          variant={this.state.vhisPlan === 'No' ? 'contained' : 'outlined'}
                                          className={
                                            this.state.vhisPlan === 'No'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.vhisPlan;
                                            this.setState({
                                              vhisPlan: this.state.vhisPlan === 'No' ? '' : 'No'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({ id: 'No' })}
                                        </Button>

                                        <Button
                                          variant={this.state.vhisPlan === 'No Idea' ? 'contained' : 'outlined'}
                                          className={
                                            this.state.vhisPlan === 'No Idea'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.vhisPlan;
                                            this.setState({
                                              vhisPlan: this.state.vhisPlan === 'No Idea' ? '' : 'No Idea'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id: 'No Idea'
                                          })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'vhisPlan')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, vhisPlan, 'vhisPlan')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>

                                        <i className="bar" />
                                        {this.state.err.vhisPlan ? (
                                          <div className="error_field">
                                            {intl.formatMessage({
                                              id: this.state.err.vhisPlan
                                            })}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'vhisPlan',
                                          vhisPlan ? vhisPlan : val.vhisPlan
                                        )}
                                      >
                                        {vhisPlan ? (
                                          intl.formatMessage({
                                            id: vhisPlan
                                          })
                                        ) : val.vhisPlan ? (
                                          intl.formatMessage({
                                            id: val.vhisPlan
                                          })
                                        ) : (
                                          <span style={{ opacity: 0 }}>"N/A"</span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                              )}
                              <li>
                                <div className="left-title">{intl.formatMessage({ id: 'Coverage Type' })}:</div>
                                <div className="left-value">
                                  {this.state.edit === 'coverageType' ? (
                                    <div>
                                      <Button
                                        variant={this.state.coverageType === 'Regular' ? 'contained' : 'outlined'}
                                        className={
                                          this.state.coverageType === 'Regular'
                                            ? 'option-select-button plan-type-select-button selected'
                                            : 'option-select-button plan-type-select-button'
                                        }
                                        onClick={() => {
                                          delete this.state.err.coverageType;
                                          this.setState({
                                            coverageType: this.state.coverageType === 'Regular' ? '' : 'Regular'
                                          });
                                        }}
                                      >
                                        {intl.formatMessage({ id: 'Regular' })}
                                      </Button>
                                      {this.state.vhisPlan === 'Yes' && val.categoryId === '59b0eaee14ea7f1aafb6d0b5' && (
                                        <Button
                                          variant={this.state.coverageType === 'Flexi' ? 'contained' : 'outlined'}
                                          className={
                                            this.state.coverageType === 'Flexi'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.coverageType;
                                            this.setState({
                                              coverageType: this.state.coverageType === 'Flexi' ? '' : 'Flexi'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id: 'Flexi'
                                          })}
                                        </Button>
                                      )}
                                      <Button
                                        variant={this.state.coverageType === 'Highend' ? 'contained' : 'outlined'}
                                        className={
                                          this.state.coverageType === 'Highend'
                                            ? 'option-select-button plan-type-select-button selected'
                                            : 'option-select-button plan-type-select-button'
                                        }
                                        onClick={() => {
                                          delete this.state.err.coverageType;
                                          this.setState({
                                            coverageType: this.state.coverageType === 'Highend' ? '' : 'Highend'
                                          });
                                        }}
                                      >
                                        {intl.formatMessage({ id: 'Highend' })}
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="insure-details-edit-button"
                                        onClick={this.cancel.bind(this, 'coverageType')}
                                      >
                                        {intl.formatMessage({ id: 'Cancel' })}
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="insure-details-edit-button"
                                        onClick={this.save.bind(this, this.state.coverageType, 'coverageType')}
                                      >
                                        {intl.formatMessage({ id: 'Save' })}
                                      </Button>

                                      {this.state.err.coverageType ? (
                                        <div className="error_field">
                                          {intl.formatMessage({
                                            id: this.state.err.coverageType
                                          })}
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      className="fs-value"
                                      onClick={this.handleEdit.bind(
                                        this,
                                        'coverageType',
                                        this.state.coverageType ? this.state.coverageType : val.coverageType
                                      )}
                                    >
                                      {this.state.coverageType ? (
                                        intl.formatMessage({
                                          id: this.state.coverageType
                                        })
                                      ) : val.coverageType ? (
                                        intl.formatMessage({
                                          id: val.coverageType
                                        })
                                      ) : (
                                        <span
                                          className="left-null-value"
                                          onClick={this.handleEdit.bind(
                                            this,
                                            'coverageType',
                                            this.state.coverageType ? this.state.coverageType : val.coverageType
                                          )}
                                        >
                                          N/A
                                        </span>
                                      )}
                                      <EditIcon className="fs-value-edit-icon" />
                                    </div>
                                  )}
                                </div>
                              </li>
                              <li>
                                <div className="left-title">{intl.formatMessage({ id: 'Room Class' })}:</div>
                                <div className="left-value">
                                  {this.state.edit === 'roomClass' ? (
                                    <div>
                                      <Button
                                        variant={this.state.roomClass === 'Semi Ward' ? 'contained' : 'outlined'}
                                        className={
                                          this.state.roomClass === 'Semi Ward'
                                            ? 'option-select-button plan-type-select-button selected'
                                            : 'option-select-button plan-type-select-button'
                                        }
                                        onClick={() => {
                                          delete this.state.err.roomClass;
                                          this.setState({
                                            roomClass: this.state.roomClass === 'Semi Ward' ? '' : 'Semi Ward'
                                          });
                                        }}
                                      >
                                        {intl.formatMessage({
                                          id: 'Semi Ward'
                                        })}
                                      </Button>
                                      <Button
                                        variant={this.state.roomClass === 'Ward' ? 'contained' : 'outlined'}
                                        className={
                                          this.state.roomClass === 'Ward'
                                            ? 'option-select-button plan-type-select-button selected'
                                            : 'option-select-button plan-type-select-button'
                                        }
                                        onClick={() => {
                                          delete this.state.err.roomClass;
                                          this.setState({
                                            roomClass: this.state.roomClass === 'Ward' ? '' : 'Ward'
                                          });
                                        }}
                                      >
                                        {intl.formatMessage({ id: 'Ward' })}
                                      </Button>
                                      <Button
                                        variant={this.state.roomClass === 'Semi Private' ? 'contained' : 'outlined'}
                                        className={
                                          this.state.roomClass === 'Semi Private'
                                            ? 'option-select-button plan-type-select-button selected'
                                            : 'option-select-button plan-type-select-button'
                                        }
                                        onClick={() => {
                                          delete this.state.err.roomClass;
                                          this.setState({
                                            roomClass: this.state.roomClass === 'Semi Private' ? '' : 'Semi Private'
                                          });
                                        }}
                                      >
                                        {intl.formatMessage({
                                          id: 'Semi Private'
                                        })}
                                      </Button>
                                      <Button
                                        variant={this.state.roomClass === 'Private' ? 'contained' : 'outlined'}
                                        className={
                                          this.state.roomClass === 'Private'
                                            ? 'option-select-button plan-type-select-button selected'
                                            : 'option-select-button plan-type-select-button'
                                        }
                                        onClick={() => {
                                          delete this.state.err.roomClass;
                                          this.setState({
                                            roomClass: this.state.roomClass === 'Private' ? '' : 'Private'
                                          });
                                        }}
                                      >
                                        {intl.formatMessage({ id: 'Private' })}
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="insure-details-edit-button"
                                        onClick={this.cancel.bind(this, 'roomClass')}
                                      >
                                        {intl.formatMessage({ id: 'Cancel' })}
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="insure-details-edit-button"
                                        onClick={this.save.bind(this, this.state.roomClass, 'roomClass')}
                                      >
                                        {intl.formatMessage({ id: 'Save' })}
                                      </Button>

                                      {this.state.err.roomClass ? (
                                        <div className="error_field">
                                          {intl.formatMessage({
                                            id: this.state.err.roomClass
                                          })}{' '}
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      className="fs-value"
                                      onClick={this.handleEdit.bind(
                                        this,
                                        'roomClass',
                                        this.state.roomClass ? this.state.roomClass : val.roomClass
                                      )}
                                    >
                                      {this.state.roomClass ? (
                                        intl.formatMessage({
                                          id: this.state.roomClass
                                        })
                                      ) : val.roomClass ? (
                                        intl.formatMessage({
                                          id: val.roomClass
                                        })
                                      ) : (
                                        <span style={{ opacity: 0 }}>"N/A"</span>
                                      )}
                                      <EditIcon className="fs-value-edit-icon" />
                                    </div>
                                  )}
                                </div>
                              </li>
                            </div>
                          ) : (
                            ''
                          )}
                          <li>
                            <div className="left-title">{intl.formatMessage({ id: 'Currency' })}:</div>
                            <div className="left-value">
                              <div
                                onClick={() => {
                                  this.setState({ modalIsOpen1: true });
                                }}
                              >
                                {intl.formatMessage({ id: val.currency })}
                              </div>
                            </div>
                          </li>
                          {val.subCategoryId !== '5c18ebd120ca05b7e2b32565' &&
                            val.subCategoryId !== '5c41b85a83230016450b2214' && (
                              <li>
                                <div className="left-title">
                                  {val.categoryId === '59b0eb3814ea7f1aafb6d0b7' &&
                                  val.subCategoryId !== '5b9a5bfee624cdb543a476d2' &&
                                  val.subCategoryId !== '5baf2af899d85c2d38c8f00f'
                                    ? intl.formatMessage({
                                        id: 'Coverage Amount'
                                      })
                                    : val.subCategoryId === '5b9a5bfee624cdb543a476d2'
                                    ? intl.formatMessage({
                                        id: 'Accidental Death'
                                      })
                                    : intl.formatMessage({
                                        id: 'Life Coverage Amount'
                                      })}
                                  :
                                </div>
                                <div className="left-value">
                                  {this.state.edit === 'lifeCoverage' ? (
                                    <div>
                                      <NumberFormat
                                        //type="number"
                                        thousandSeparator={true}
                                        //name="lifeCoverage"
                                        className="left-value-input"
                                        onValueChange={values => {
                                          delete this.state.err.lifeCoverage;
                                          const { value } = values;
                                          this.setState({
                                            lifeCoverage: value
                                          });
                                        }}
                                        value={this.state.lifeCoverage}
                                        inputMode="decimal"
                                      />
                                      {this.state.err.lifeCoverage ? (
                                        <div className="error_field">
                                          {intl.formatMessage({
                                            id: this.state.err.lifeCoverage
                                          })}
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="insure-details-edit-button"
                                        onClick={this.cancel.bind(this, 'lifeCoverage')}
                                      >
                                        {intl.formatMessage({ id: 'Cancel' })}
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="insure-details-edit-button"
                                        onClick={this.save.bind(
                                          this,
                                          this.state.lifeCoverage,
                                          'lifeCoverage',
                                          this.state.premium ? this.state.premium : val.premium,
                                          val.currency
                                        )}
                                      >
                                        {intl.formatMessage({ id: 'Save' })}
                                      </Button>
                                    </div>
                                  ) : (
                                    <div
                                      className="fs-value"
                                      onClick={this.handleEdit.bind(
                                        this,
                                        'lifeCoverage',
                                        this.state.lifeCoverage ? this.state.lifeCoverage : val.lifeCoverage
                                      )}
                                    >
                                      {intl.formatMessage({ id: val.currency })} {lifeCoverage}
                                      <EditIcon className="fs-value-edit-icon" />
                                    </div>
                                  )}
                                </div>
                              </li>
                            )}
                          {(val.subCategoryId === '5c18ebd120ca05b7e2b32565' ||
                            val.subCategoryId === '5baf2af899d85c2d38c8f00f' ||
                            (val.categoryId === '59b0eaee14ea7f1aafb6d0b5' &&
                              val.subCategoryId === '59b0ed8fdae1831be084f806')) && (
                            <li>
                              <div className="left-title">{intl.formatMessage({ id: 'tax-deductible' })}:</div>
                              <div className="left-value">
                                {this.state.edit === 'taxDeductible' ? (
                                  <div>
                                    <Button
                                      variant={this.state.taxDeductible === 'Yes' ? 'contained' : 'outlined'}
                                      className={
                                        this.state.taxDeductible === 'Yes'
                                          ? 'option-select-button plan-type-select-button selected'
                                          : 'option-select-button plan-type-select-button'
                                      }
                                      onClick={() => {
                                        delete this.state.err.taxDeductible;
                                        this.setState({
                                          taxDeductible: this.state.taxDeductible === 'Yes' ? '' : 'Yes'
                                        });
                                      }}
                                    >
                                      {intl.formatMessage({ id: 'Yes' })}
                                    </Button>
                                    <Button
                                      variant={this.state.taxDeductible === 'No' ? 'contained' : 'outlined'}
                                      className={
                                        this.state.taxDeductible === 'No'
                                          ? 'option-select-button plan-type-select-button selected'
                                          : 'option-select-button plan-type-select-button'
                                      }
                                      onClick={() => {
                                        delete this.state.err.taxDeductible;
                                        this.setState({
                                          taxDeductible: this.state.taxDeductible === 'No' ? '' : 'No'
                                        });
                                      }}
                                    >
                                      {intl.formatMessage({ id: 'No' })}
                                    </Button>
                                    <Button
                                      variant={this.state.taxDeductible === 'No Idea' ? 'contained' : 'outlined'}
                                      className={
                                        this.state.taxDeductible === 'No Idea'
                                          ? 'option-select-button plan-type-select-button selected'
                                          : 'option-select-button plan-type-select-button'
                                      }
                                      onClick={() => {
                                        delete this.state.err.taxDeductible;
                                        this.setState({
                                          taxDeductible: this.state.taxDeductible === 'No Idea' ? '' : 'No Idea'
                                        });
                                      }}
                                    >
                                      {intl.formatMessage({ id: 'No Idea' })}
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.cancel.bind(this, 'taxDeductible')}
                                    >
                                      {intl.formatMessage({ id: 'Cancel' })}
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.save.bind(this, this.state.taxDeductible, 'taxDeductible')}
                                    >
                                      {intl.formatMessage({ id: 'Save' })}
                                    </Button>

                                    {this.state.err.taxDeductible ? (
                                      <div className="error_field">
                                        {intl.formatMessage({
                                          id: this.state.err.taxDeductible
                                        })}
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    className="fs-value"
                                    onClick={this.handleEdit.bind(
                                      this,
                                      'taxDeductible',
                                      this.state.taxDeductible ? this.state.taxDeductible : val.taxDeductible
                                    )}
                                  >
                                    {this.state.taxDeductible ? (
                                      intl.formatMessage({
                                        id: this.state.taxDeductible
                                      })
                                    ) : val.taxDeductible ? (
                                      intl.formatMessage({
                                        id: val.taxDeductible
                                      })
                                    ) : (
                                      <span
                                        className="left-null-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'taxDeductible',
                                          this.state.taxDeductible ? this.state.taxDeductible : val.taxDeductible
                                        )}
                                      >
                                        N/A
                                      </span>
                                    )}
                                    <EditIcon className="fs-value-edit-icon" />
                                  </div>
                                )}
                              </div>
                            </li>
                          )}
                          {val.subCategoryId === '59b0ed6fdae1831be084f805' ? (
                            <li>
                              <div className="left-title">
                                {intl.formatMessage({
                                  id: 'C.I. Coverage Amount'
                                })}
                                :
                              </div>
                              <div className="left-value">
                                {this.state.edit === 'sumInsured' ? (
                                  <div>
                                    <NumberFormat
                                      //type="number"
                                      className="left-value-input"
                                      //name="sumInsured"
                                      thousandSeparator={true}
                                      onValueChange={values => {
                                        const { value } = values;
                                        delete this.state.err.sumInsured;
                                        this.setState({ sumInsured: value });
                                      }}
                                      value={this.state.sumInsured}
                                      inputMode="decimal"
                                    />
                                    {this.state.err.sumInsured ? (
                                      <div className="error_field">
                                        {intl.formatMessage({
                                          id: this.state.err.sumInsured
                                        })}
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.cancel.bind(this, 'sumInsured')}
                                    >
                                      {intl.formatMessage({ id: 'Cancel' })}
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.save.bind(
                                        this,
                                        this.state.sumInsured,
                                        'sumInsured',
                                        this.state.premium ? this.state.premium : val.premium,
                                        val.currency
                                      )}
                                    >
                                      {intl.formatMessage({ id: 'Save' })}
                                    </Button>
                                  </div>
                                ) : (
                                  <div
                                    className="fs-value"
                                    onClick={this.handleEdit.bind(
                                      this,
                                      'sumInsured',
                                      this.state.sumInsured ? this.state.sumInsured : val.sumInsured
                                    )}
                                  >
                                    {intl.formatMessage({ id: val.currency })} {sumInsured}
                                    <EditIcon className="fs-value-edit-icon" />
                                  </div>
                                )}
                              </div>
                            </li>
                          ) : (
                            ''
                          )}
                          {!val.naPremium && (
                            <Fragment>
                              <li>
                                <div className="left-title">
                                  {val.subCategoryId !== '5c41b85a83230016450b2214' &&
                                  val.subCategoryId !== '5c18ebd120ca05b7e2b32565'
                                    ? intl.formatMessage({ id: 'Premium' })
                                    : intl.formatMessage({ id: 'Payment' })}
                                  :
                                </div>
                                <div className="left-value">
                                  {this.state.edit === 'premium' ? (
                                    <div>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        className="left-value-input"
                                        onValueChange={values => {
                                          delete this.state.err.premium;
                                          const { value } = values;
                                          this.setState({ premium: value });
                                        }}
                                        value={this.state.premium}
                                        inputMode="decimal"
                                      />
                                      {this.state.err.premium ? (
                                        <div className="error_field">
                                          {intl.formatMessage({
                                            id: this.state.err.premium
                                          })}
                                        </div>
                                      ) : (
                                        ''
                                      )}

                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="insure-details-edit-button"
                                        onClick={this.cancel.bind(this, 'premium')}
                                      >
                                        {intl.formatMessage({ id: 'Cancel' })}
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="insure-details-edit-button"
                                        onClick={this.save.bind(
                                          this,
                                          this.state.premium,
                                          'premium',
                                          this.state.premium ? this.state.premium : val.premium,
                                          val.currency
                                        )}
                                      >
                                        {intl.formatMessage({ id: 'Save' })}
                                      </Button>
                                    </div>
                                  ) : (
                                    <div
                                      className="fs-value"
                                      onClick={this.handleEdit.bind(
                                        this,
                                        'premium',
                                        this.state.premium ? this.state.premium : val.premium
                                      )}
                                    >
                                      {intl.formatMessage({ id: val.currency })} {premium}
                                      {(levelPremium ? levelPremium : val.levelPremium) === 'No' &&
                                        intl.formatMessage({ id: 'premium-unfixed' })}
                                      <EditIcon className="fs-value-edit-icon" />
                                    </div>
                                  )}
                                </div>
                              </li>
                              <li>
                                <div className="left-title">{intl.formatMessage({ id: 'Frequency' })}:</div>
                                <div className="left-value">
                                  <div
                                    onClick={() => {
                                      this.setState({ modalIsOpen1: true });
                                    }}
                                  >
                                    {intl.formatMessage({ id: premiumTermsValue })}
                                  </div>
                                </div>
                              </li>
                            </Fragment>
                          )}

                          {((isCategory(val.categoryId, 'personal') &&
                            isSubCategory(val.subCategoryId, ['life', 'others'])) ||
                            (isCategory(val.categoryId, 'general') &&
                              isSubCategory(val.subCategoryId, ['saving', 'provident', 'others']))) && (
                            <li>
                              <div className="left-title">
                                {intl.formatMessage({
                                  id: 'LumpSum amount'
                                })}
                              </div>
                              <div className="left-value">
                                {this.state.edit === 'lumpSumAmount' ? (
                                  <div>
                                    <NumberFormat
                                      //type="number"
                                      //name="lumpSumAmount"
                                      thousandSeparator={true}
                                      className="left-value-input"
                                      onValueChange={values => {
                                        const { value } = values;
                                        this.setState({
                                          lumpSumAmount: value
                                        });
                                      }}
                                      value={this.state.lumpSumAmount || ''}
                                    />
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.cancel.bind(this, 'lumpSumAmount')}
                                    >
                                      {intl.formatMessage({ id: 'Cancel' })}
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.save.bind(
                                        this,
                                        this.state.lumpSumAmount,
                                        'lumpSumAmount',
                                        this.state.lumpSumAmount ? this.state.lumpSumAmount : val.lumpSumAmount
                                      )}
                                    >
                                      {intl.formatMessage({ id: 'Save' })}
                                    </Button>
                                  </div>
                                ) : (
                                  <div
                                    className="fs-value"
                                    onClick={this.handleEdit.bind(
                                      this,
                                      'lumpSumAmount',
                                      this.state.lumpSumAmount ? this.state.lumpSumAmount : val.lumpSumAmount
                                    )}
                                  >
                                    {lumpSumAmount ? (
                                      lumpSumAmount
                                    ) : val.lumpSumAmount ? (
                                      val.lumpSumAmount
                                    ) : (
                                      <span
                                        className="left-null-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'lumpSumAmount',
                                          this.state.lumpSumAmount ? this.state.lumpSumAmount : val.lumpSumAmount
                                        )}
                                      >
                                        N/A
                                      </span>
                                    )}
                                    <EditIcon className="fs-value-edit-icon" />
                                  </div>
                                )}
                              </div>
                            </li>
                          )}

                          <li>
                            <div className="left-title">{intl.formatMessage({ id: 'Policy Start Date' })}</div>
                            <div className="left-value">
                              {this.state.edit === 'policyStartDate' ? (
                                <div>
                                  <DatePicker
                                    name="policyStartDate"
                                    maxDate={moment().add(3, 'months')}
                                    format="DD-MMM-YYYY"
                                    clearable={true}
                                    className="left-value-input"
                                    value={
                                      this.state.policyStartDate === '' ||
                                      this.state.policyStartDate === undefined ||
                                      this.state.policyStartDate === null
                                        ? null
                                        : moment.parseZone(this.state.policyStartDate)
                                    }
                                    openTo="year"
                                    onChange={date => this.setState({ policyStartDate: date })}
                                    autoOk={true}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton style={{ padding: '0 5px 0 0', marginLeft: '-12px' }}>
                                            <TodayIcon color="action" style={{ fontSize: 20 }} />
                                          </IconButton>
                                        </InputAdornment>
                                      )
                                    }}
                                    inputProps={{ style: { textAlign: 'center' } }}
                                  />
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="insure-details-edit-button"
                                    onClick={() =>
                                      this.setState({
                                        edit: '',
                                        policyStartDate: this.state.editValue
                                      })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'Cancel' })}
                                  </Button>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="insure-details-edit-button"
                                    onClick={this.handleDateChange.bind(this, 'policyStartDate')}
                                  >
                                    {intl.formatMessage({ id: 'Save' })}
                                  </Button>
                                </div>
                              ) : (
                                <div
                                  className="fs-value"
                                  onClick={() =>
                                    this.setState({
                                      edit: 'policyStartDate',
                                      editValue:
                                        this.state.policyStartDate !== null &&
                                        this.state.policyStartDate !== undefined &&
                                        this.state.policyStartDate !== ''
                                          ? moment.parseZone(this.state.policyStartDate)
                                          : null
                                    })
                                  }
                                >
                                  {this.state.policyStartDate === undefined ||
                                  this.state.policyStartDate === null ||
                                  this.state.policyStartDate === '' ? (
                                    <span
                                      className="left-null-value"
                                      onClick={() =>
                                        this.setState({
                                          edit: 'policyStartDate',
                                          editValue:
                                            this.state.policyStartDate !== null &&
                                            this.state.policyStartDate !== undefined &&
                                            this.state.policyStartDate !== ''
                                              ? moment.parseZone(this.state.policyStartDate)
                                              : null
                                        })
                                      }
                                    >
                                      N/A
                                    </span>
                                  ) : this.state.policyStartDate !== null &&
                                    this.state.policyStartDate !== undefined &&
                                    this.state.policyStartDate !== '' ? (
                                    moment.parseZone(this.state.policyStartDate).format('DD-MMM-YYYY')
                                  ) : null}
                                  <EditIcon className="fs-value-edit-icon" />
                                </div>
                              )}
                            </div>
                          </li>

                          <div>
                            {/*{val.subCategoryId !==
                                '5c18ebd120ca05b7e2b32565' &&
                                val.subCategoryId !==
                                  '5c41b85a83230016450b2214' && (
                                  <li>
                                    <div className="left-title">
                                      {intl.formatMessage({
                                        id: 'Protection amount in HKD'
                                      })}
                                      :
                                    </div>
                                    <div className="left-value">
                                      {intl.formatMessage({ id: 'HKD' })}{' '}
                                      {protectionAmountInHKD}
                                    </div>
                                  </li>
                                )}
                              <li>
                                <div className="left-title">
                                  {intl.formatMessage({
                                    id: 'Annual Premium in HKD'
                                  })}
                                  :
                                </div>
                                <div className="left-value">
                                  {intl.formatMessage({ id: 'HKD' })}{' '}
                                  {annualPremiumInHKD}
                                </div>
                              </li>*/}

                            {val.categoryId === '59b0eaee14ea7f1aafb6d0b5' ? (
                              <div>
                                <li>
                                  <div className="left-title">{intl.formatMessage({ id: 'Feature' })}:</div>
                                  <div className="left-value">
                                    {this.state.edit === 'feature' ? (
                                      <div>
                                        <Button
                                          variant={this.state.feature === 'Saving' ? 'contained' : 'outlined'}
                                          className={
                                            this.state.feature === 'Saving'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.feature;
                                            this.setState({
                                              feature: this.state.feature === 'Saving' ? '' : 'Saving'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id: 'Saving'
                                          })}
                                        </Button>

                                        <Button
                                          variant={this.state.feature === 'Non Saving' ? 'contained' : 'outlined'}
                                          className={
                                            this.state.feature === 'Non Saving'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.feature;
                                            this.setState({
                                              feature: this.state.feature === 'Non Saving' ? '' : 'Non Saving'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id: 'Non Saving'
                                          })}
                                        </Button>

                                        <Button
                                          variant={this.state.feature === 'No Idea' ? 'contained' : 'outlined'}
                                          className={
                                            this.state.feature === 'No Idea'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.feature;
                                            this.setState({
                                              feature: this.state.feature === 'No Idea' ? '' : 'No Idea'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id: 'No Idea'
                                          })}
                                        </Button>

                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'feature')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, feature, 'feature')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                        <i className="bar" />
                                        {this.state.err.feature ? (
                                          <div className="error_field">
                                            {intl.formatMessage({
                                              id: this.state.err.feature
                                            })}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(this, 'feature', feature ? feature : val.feature)}
                                      >
                                        {feature ? (
                                          intl.formatMessage({ id: feature })
                                        ) : val.feature ? (
                                          intl.formatMessage({
                                            id: val.feature
                                          })
                                        ) : (
                                          <span style={{ opacity: 0 }}>"N/A"</span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>

                                <li>
                                  <div className="left-title">{intl.formatMessage({ id: 'Plan Type' })}:</div>
                                  <div className="left-value">
                                    {this.state.edit === 'planType' ? (
                                      <div>
                                        {this.state.planType === 'Rider' && (
                                          <img alt="" className="not_me_icon" src="img/rider_small.png" />
                                        )}
                                        <Button
                                          variant={planType === 'Basic' ? 'contained' : 'outlined'}
                                          className={
                                            planType === 'Basic'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.planType;
                                            this.setState({
                                              planType: planType === 'Basic' ? '' : 'Basic'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id: 'Basic'
                                          })}
                                        </Button>

                                        <Button
                                          variant={planType === 'Rider' ? 'contained' : 'outlined'}
                                          className={
                                            planType === 'Rider'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.planType;
                                            this.setState({
                                              planType: planType === 'Rider' ? '' : 'Rider'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id: 'Rider'
                                          })}
                                        </Button>
                                        <Button
                                          variant={planType === 'No Idea' ? 'contained' : 'outlined'}
                                          className={
                                            planType === 'No Idea'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.planType;
                                            this.setState({
                                              planType: planType === 'No Idea' ? '' : 'No Idea'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id: 'No Idea'
                                          })}
                                        </Button>

                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'planType')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, planType, 'planType')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                        <i className="bar" />
                                        {this.state.err.planType ? (
                                          <div className="error_field">
                                            {intl.formatMessage({
                                              id: this.state.err.planType
                                            })}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'planType',
                                          planType ? planType : val.planType
                                        )}
                                      >
                                        {planType && planType === 'Rider' ? (
                                          <img alt="" className="not_me_icon" src="img/rider_small.png" />
                                        ) : val.planType && val.planType === 'Rider' && !planType ? (
                                          <img alt="" className="not_me_icon" src="img/rider_small.png" />
                                        ) : (
                                          ''
                                        )}

                                        {planType ? (
                                          intl.formatMessage({ id: planType })
                                        ) : val.planType ? (
                                          intl.formatMessage({
                                            id: val.planType
                                          })
                                        ) : (
                                          <span style={{ opacity: 0 }}>"N/A"</span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>

                                {categoryType === 'Personal' && (
                                  <li>
                                    <div className="left-title">
                                      {intl.formatMessage({
                                        id: 'add-ons'
                                      })}
                                      :
                                    </div>
                                    <div className="left-value">
                                      {this.state.edit === 'addOns' ? (
                                        <div>
                                          {Object.values(ADD_ONS_OPTIONS).map(option => (
                                            <Button
                                              variant={addOns.includes(option) ? 'contained' : 'outlined'}
                                              className={
                                                addOns.includes(option)
                                                  ? 'option-select-button plan-type-select-button selected'
                                                  : 'option-select-button plan-type-select-button'
                                              }
                                              onClick={() => {
                                                if (addOns.includes(option)) {
                                                  this.setState({ addOns: addOns.filter(addOn => addOn !== option) });
                                                } else {
                                                  this.setState({ addOns: [...addOns, option] });
                                                }
                                              }}
                                            >
                                              {intl.formatMessage({ id: getAddOnsIntlId(option) })}
                                            </Button>
                                          ))}

                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="insure-details-edit-button"
                                            onClick={this.cancel.bind(this, 'addOns')}
                                          >
                                            {intl.formatMessage({ id: 'Cancel' })}
                                          </Button>
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="insure-details-edit-button"
                                            onClick={this.save.bind(this, addOns, 'addOns')}
                                          >
                                            {intl.formatMessage({ id: 'Save' })}
                                          </Button>
                                        </div>
                                      ) : (
                                        <div
                                          className="fs-value"
                                          onClick={this.handleEdit.bind(this, 'addOns', addOns || val.addOns)}
                                        >
                                          <div style={{ marginRight: 19.2 }}>
                                            {(val.addOns || []).map(addOn => (
                                              <Typography key={addOn}>
                                                {intl.formatMessage({ id: `add-ons-${addOn}` })}
                                              </Typography>
                                            ))}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                )}

                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Level Premium'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'levelPremium' ? (
                                      <div>
                                        {this.state.levelPremium === 'No' && (
                                          <img alt="" className="not_level_icon" src="img/not-level.png" />
                                        )}
                                        <Button
                                          variant={this.state.levelPremium === 'Yes' ? 'contained' : 'outlined'}
                                          className={
                                            this.state.levelPremium === 'Yes'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.levelPremium;
                                            this.setState({
                                              levelPremium: this.state.levelPremium === 'Yes' ? '' : 'Yes'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({ id: 'Yes' })}
                                        </Button>

                                        <Button
                                          variant={this.state.levelPremium === 'No' ? 'contained' : 'outlined'}
                                          className={
                                            this.state.levelPremium === 'No'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.levelPremium;
                                            this.setState({
                                              levelPremium: this.state.levelPremium === 'No' ? '' : 'No'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({ id: 'No' })}
                                        </Button>

                                        <Button
                                          variant={this.state.levelPremium === 'No Idea' ? 'contained' : 'outlined'}
                                          className={
                                            this.state.levelPremium === 'No Idea'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.levelPremium;
                                            this.setState({
                                              levelPremium: this.state.levelPremium === 'No Idea' ? '' : 'No Idea'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id: 'No Idea'
                                          })}
                                        </Button>
                                        <div>
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="insure-details-edit-button"
                                            onClick={this.cancel.bind(this, 'levelPremium')}
                                          >
                                            {intl.formatMessage({ id: 'Cancel' })}
                                          </Button>
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="insure-details-edit-button"
                                            onClick={this.save.bind(this, levelPremium, 'levelPremium')}
                                          >
                                            {intl.formatMessage({ id: 'Save' })}
                                          </Button>
                                        </div>

                                        <i className="bar" />
                                        {this.state.err.levelPremium ? (
                                          <div className="error_field">
                                            {intl.formatMessage({
                                              id: this.state.err.levelPremium
                                            })}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'levelPremium',
                                          levelPremium ? levelPremium : val.levelPremium
                                        )}
                                      >
                                        {(levelPremium ? levelPremium === 'No' : val.levelPremium === 'No') && (
                                          <img alt="" className="not_level_icon" src="img/not-level.png" />
                                        )}
                                        {levelPremium ? (
                                          intl.formatMessage({
                                            id: levelPremium
                                          })
                                        ) : val.levelPremium ? (
                                          intl.formatMessage({
                                            id: val.levelPremium
                                          })
                                        ) : (
                                          <span style={{ opacity: 0 }}>"N/A"</span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                              </div>
                            ) : (
                              ''
                            )}

                            {val.categoryId !== '59b0eb2314ea7f1aafb6d0b6' &&
                              val.subCategoryId !== '5c18ebd120ca05b7e2b32565' &&
                              val.subCategoryId !== '5c41b85a83230016450b2214' && (
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Payment Method'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'paymentMethod' ? (
                                      <div>
                                        <Button
                                          variant={paymentMethod === 'Bank' ? 'contained' : 'outlined'}
                                          className={
                                            paymentMethod === 'Bank'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.paymentMethod;
                                            this.setState({
                                              paymentMethod: paymentMethod === 'Bank' ? '' : 'Bank'
                                            });
                                          }}
                                        >
                                          <span className="autopay-option-button">
                                            {intl.formatMessage({
                                              id: 'AutoPay by Bank'
                                            })}
                                          </span>
                                        </Button>
                                        <Button
                                          variant={paymentMethod === 'Credit Card' ? 'contained' : 'outlined'}
                                          className={
                                            paymentMethod === 'Credit Card'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.paymentMethod;
                                            this.setState({
                                              paymentMethod: paymentMethod === 'Credit Card' ? '' : 'Credit Card'
                                            });
                                          }}
                                        >
                                          <span className="autopay-option-button">
                                            {intl.formatMessage({
                                              id: 'AutoPay by Credit Card'
                                            })}
                                          </span>
                                        </Button>
                                        <Button
                                          variant={paymentMethod === 'Manual' ? 'contained' : 'outlined'}
                                          className={
                                            paymentMethod === 'Manual'
                                              ? 'option-select-button plan-type-select-button selected'
                                              : 'option-select-button plan-type-select-button'
                                          }
                                          onClick={() => {
                                            delete this.state.err.paymentMethod;
                                            this.setState({
                                              paymentMethod: paymentMethod === 'Manual' ? '' : 'Manual'
                                            });
                                          }}
                                        >
                                          <span className="autopay-option-button">
                                            {intl.formatMessage({
                                              id: 'Manual'
                                            })}
                                          </span>
                                        </Button>
                                        <br />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'paymentMethod')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, paymentMethod, 'paymentMethod')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                        <i className="bar" />
                                        {this.state.err.paymentMethod ? (
                                          <div className="error_field">
                                            {intl.formatMessage({
                                              id: this.state.err.paymentMethod
                                            })}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    ) : (
                                      <div className="info-btn-text-insured-detail payment-info-detail">
                                        <span
                                          className="ins-info"
                                          onClick={this.handleEdit.bind(
                                            this,
                                            'paymentMethod',
                                            paymentMethod ? paymentMethod : val.paymentMethod
                                          )}
                                        >
                                          {paymentMethod ? (
                                            intl.formatMessage({
                                              id: paymentMethod
                                            })
                                          ) : val.paymentMethod ? (
                                            intl.formatMessage({
                                              id: val.paymentMethod
                                            })
                                          ) : (
                                            <span style={{ opacity: 0 }}>"N/A"</span>
                                          )}
                                        </span>
                                        {!isOtherInsurer && (
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            style={{ padding: '0px', minWidth: '40px', marginLeft: '5px' }}
                                            onClick={this.insurerInfo.bind(this, val)}
                                          >
                                            {intl.formatMessage({ id: 'Info' })}
                                          </Button>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </li>
                              )}

                            {val.subCategoryId !== '59b0ede7dae1831be084f80a' &&
                            val.subCategoryId !== '59b0ed98dae1831be084f807' &&
                            val.subCategoryId !== '5b9a5bfee624cdb543a476d2' &&
                            val.subCategoryId !== '5b9a5bfee624cdb543a476d1' &&
                            val.subCategoryId !== '5c41b85a83230016450b2214'
                              ? val.categoryId !== '59b0eb2314ea7f1aafb6d0b6' && (
                                  <li>
                                    <div className="left-title">
                                      {intl.formatMessage({
                                        id: 'Policy Owner'
                                      })}
                                      :
                                    </div>
                                    <div className="left-value">
                                      {this.state.edit === 'policyOwner' ? (
                                        <div>
                                          <Button
                                            variant={policyOwnerSelected === 'Self' ? 'contained' : 'outlined'}
                                            className={
                                              'option-select-button plan-type-select-button ' +
                                              (policyOwnerSelected === 'Self' ? 'selected' : '')
                                            }
                                            onClick={() => {
                                              this.setState({
                                                policyOwnerSelected: 'Self'
                                              });
                                            }}
                                          >
                                            {intl.formatMessage({ id: 'Self' })}
                                          </Button>
                                          <Button
                                            variant={policyOwnerSelected === 'Not Me' ? 'contained' : 'outlined'}
                                            className={
                                              'option-select-button plan-type-select-button ' +
                                              (policyOwnerSelected === 'Not Me' ? 'selected' : '')
                                            }
                                            onClick={() => {
                                              this.setState({
                                                policyOwnerSelected: 'Not Me'
                                              });
                                            }}
                                          >
                                            {intl.formatMessage({
                                              id: 'Not Me'
                                            })}
                                          </Button>
                                          <Button
                                            variant={policyOwnerSelected === 'No Idea' ? 'contained' : 'outlined'}
                                            className={
                                              'option-select-button plan-type-select-button ' +
                                              (policyOwnerSelected === 'No Idea' ? 'selected' : '')
                                            }
                                            onClick={() => {
                                              this.setState({
                                                policyOwnerSelected: 'No Idea'
                                              });
                                            }}
                                          >
                                            {intl.formatMessage({
                                              id: 'No Idea'
                                            })}
                                          </Button>
                                          <div>
                                            <input
                                              type="text"
                                              name="policyOwner"
                                              className="life-insured-inline-input left-value-input"
                                              onChange={this.handleChange.bind(this)}
                                              value={policyOwner || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'policyOwner')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(this, policyOwner, 'policyOwner')}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className="fs-value"
                                          onClick={this.handlePolicyOwnerEdit.bind(
                                            this,
                                            'policyOwner',
                                            policyOwner ? policyOwner : val.policyOwner,
                                            policyOwnerSelected ? policyOwnerSelected : val.policyOwnerSelected
                                          )}
                                        >
                                          {(policyOwnerSelected !== '' &&
                                            policyOwnerSelected !== null &&
                                            policyOwnerSelected !== undefined) ||
                                          (val.policyOwnerSelected !== undefined &&
                                            val.policyOwnerSelected !== null &&
                                            val.policyOwnerSelected !== '') ? (
                                            <div className="life-insured-seleced-button">
                                              {policyOwnerSelected
                                                ? intl.formatMessage({
                                                    id: policyOwnerSelected
                                                  })
                                                : val.policyOwnerSelected
                                                ? intl.formatMessage({
                                                    id: val.policyOwnerSelected
                                                  })
                                                : ''}
                                            </div>
                                          ) : (
                                            ' '
                                          )}
                                          {policyOwner && !this.isNullOrEmpty(policyOwner) ? (
                                            policyOwner
                                          ) : val.policyOwner && !this.isNullOrEmpty(val.policyOwner) ? (
                                            val.policyOwner
                                          ) : (
                                            <span
                                              className="left-null-value"
                                              onClick={this.handlePolicyOwnerEdit.bind(
                                                this,
                                                'policyOwner',
                                                policyOwner ? policyOwner : val.policyOwner,
                                                policyOwnerSelected ? policyOwnerSelected : val.policyOwnerSelected
                                              )}
                                            >
                                              N/A
                                            </span>
                                          )}
                                          <EditIcon className="fs-value-edit-icon" />
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                )
                              : ''}
                            {val.subCategoryId === '59b0ed98dae1831be084f807' &&
                              val.categoryId === '59b0eaee14ea7f1aafb6d0b5' && (
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Policy Owner'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'policyOwner' ? (
                                      <div>
                                        <Button
                                          variant={policyOwnerSelected === 'Self' ? 'contained' : 'outlined'}
                                          className={
                                            'option-select-button plan-type-select-button ' +
                                            (policyOwnerSelected === 'Self' ? 'selected' : '')
                                          }
                                          onClick={() => {
                                            this.setState({
                                              policyOwnerSelected: 'Self'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({ id: 'Self' })}
                                        </Button>
                                        <Button
                                          variant={policyOwnerSelected === 'Not Me' ? 'contained' : 'outlined'}
                                          className={
                                            'option-select-button plan-type-select-button ' +
                                            (policyOwnerSelected === 'Not Me' ? 'selected' : '')
                                          }
                                          onClick={() => {
                                            this.setState({
                                              policyOwnerSelected: 'Not Me'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id: 'Not Me'
                                          })}
                                        </Button>
                                        <Button
                                          variant={policyOwnerSelected === 'No Idea' ? 'contained' : 'outlined'}
                                          className={
                                            'option-select-button plan-type-select-button ' +
                                            (policyOwnerSelected === 'No Idea' ? 'selected' : '')
                                          }
                                          onClick={() => {
                                            this.setState({
                                              policyOwnerSelected: 'No Idea'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id: 'No Idea'
                                          })}
                                        </Button>
                                        <div>
                                          <input
                                            type="text"
                                            name="policyOwner"
                                            className="life-insured-inline-input left-value-input"
                                            onChange={this.handleChange.bind(this)}
                                            value={policyOwner || ''}
                                          />
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="insure-details-edit-button"
                                            onClick={this.cancel.bind(this, 'policyOwner')}
                                          >
                                            {intl.formatMessage({ id: 'Cancel' })}
                                          </Button>
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="insure-details-edit-button"
                                            onClick={this.save.bind(this, policyOwner, 'policyOwner')}
                                          >
                                            {intl.formatMessage({ id: 'Save' })}
                                          </Button>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handlePolicyOwnerEdit.bind(
                                          this,
                                          'policyOwner',
                                          policyOwner ? policyOwner : val.policyOwner,
                                          policyOwnerSelected ? policyOwnerSelected : val.policyOwnerSelected
                                        )}
                                      >
                                        {(policyOwnerSelected !== '' &&
                                          policyOwnerSelected !== null &&
                                          policyOwnerSelected !== undefined) ||
                                        (val.policyOwnerSelected !== undefined &&
                                          val.policyOwnerSelected !== null &&
                                          val.policyOwnerSelected !== '') ? (
                                          <div className="life-insured-seleced-button">
                                            {policyOwnerSelected
                                              ? intl.formatMessage({
                                                  id: policyOwnerSelected
                                                })
                                              : val.policyOwnerSelected
                                              ? intl.formatMessage({
                                                  id: val.policyOwnerSelected
                                                })
                                              : ''}
                                          </div>
                                        ) : (
                                          ' '
                                        )}
                                        {policyOwner && !this.isNullOrEmpty(policyOwner) ? (
                                          policyOwner
                                        ) : val.policyOwner && !this.isNullOrEmpty(val.policyOwner) ? (
                                          val.policyOwner
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handlePolicyOwnerEdit.bind(
                                              this,
                                              'policyOwner',
                                              policyOwner ? policyOwner : val.policyOwner,
                                              policyOwnerSelected ? policyOwnerSelected : val.policyOwnerSelected
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                              )}

                            {val.subCategoryId === '5b9a5bfee624cdb543a476d2' && (
                              <div>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Permanent Disablement'
                                    })}{' '}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'permanentDisablement' ? (
                                      <div>
                                        <input
                                          type="text"
                                          name="permanentDisablement"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={permanentDisablement || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'permanentDisablement')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, permanentDisablement, 'permanentDisablement')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'permanentDisablement',
                                          permanentDisablement ? permanentDisablement : val.permanentDisablement
                                        )}
                                      >
                                        {permanentDisablement && !this.isNullOrEmpty(permanentDisablement) ? (
                                          permanentDisablement
                                        ) : val.permanentDisablement &&
                                          !this.isNullOrEmpty(val.permanentDisablement) ? (
                                          val.permanentDisablement
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'permanentDisablement',
                                              permanentDisablement ? permanentDisablement : val.permanentDisablement
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Medical Expenses'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'medicalExpenses' ? (
                                      <div>
                                        <input
                                          type="text"
                                          name="medicalExpenses"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={medicalExpenses || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'medicalExpenses')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, medicalExpenses, 'medicalExpenses')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'medicalExpenses',
                                          medicalExpenses ? medicalExpenses : val.medicalExpenses
                                        )}
                                      >
                                        {medicalExpenses && !this.isNullOrEmpty(medicalExpenses) ? (
                                          medicalExpenses
                                        ) : val.medicalExpenses && !this.isNullOrEmpty(val.medicalExpenses) ? (
                                          val.medicalExpenses
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'medicalExpenses',
                                              medicalExpenses ? medicalExpenses : val.medicalExpenses
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Physiotherapy'
                                    })}{' '}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'physiotherapy' ? (
                                      <div>
                                        <input
                                          type="text"
                                          name="physiotherapy"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={physiotherapy || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'physiotherapy')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, physiotherapy, 'physiotherapy')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'physiotherapy',
                                          physiotherapy ? physiotherapy : val.physiotherapy
                                        )}
                                      >
                                        {physiotherapy && !this.isNullOrEmpty(physiotherapy) ? (
                                          physiotherapy
                                        ) : val.physiotherapy && !this.isNullOrEmpty(val.physiotherapy) ? (
                                          val.physiotherapy
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'physiotherapy',
                                              physiotherapy ? physiotherapy : val.physiotherapy
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Chinese Doctor'
                                    })}{' '}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'chineseDoctor' ? (
                                      <div>
                                        <input
                                          type="text"
                                          name="chineseDoctor"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={chineseDoctor || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'chineseDoctor')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, chineseDoctor, 'chineseDoctor')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'chineseDoctor',
                                          chineseDoctor ? chineseDoctor : val.chineseDoctor
                                        )}
                                      >
                                        {chineseDoctor && !this.isNullOrEmpty(chineseDoctor) ? (
                                          chineseDoctor
                                        ) : val.chineseDoctor && !this.isNullOrEmpty(val.chineseDoctor) ? (
                                          val.chineseDoctor
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'chineseDoctor',
                                              chineseDoctor ? chineseDoctor : val.chineseDoctor
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Chinese Bonesetter'
                                    })}{' '}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'chineseBonesetter' ? (
                                      <div>
                                        <input
                                          type="text"
                                          name="chineseBonesetter"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={chineseBonesetter || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'chineseBonesetter')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, chineseBonesetter, 'chineseBonesetter')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'chineseBonesetter',
                                          chineseBonesetter ? chineseBonesetter : val.chineseBonesetter
                                        )}
                                      >
                                        {chineseBonesetter && !this.isNullOrEmpty(chineseBonesetter) ? (
                                          chineseBonesetter
                                        ) : val.chineseBonesetter && !this.isNullOrEmpty(val.chineseBonesetter) ? (
                                          val.chineseBonesetter
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'chineseBonesetter',
                                              chineseBonesetter ? chineseBonesetter : val.chineseBonesetter
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Hospital Cash'
                                    })}{' '}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'hospitalCashDetail' ? (
                                      <div>
                                        <input
                                          type="text"
                                          name="hospitalCashDetail"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={hospitalCashDetail || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'hospitalCashDetail')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, hospitalCashDetail, 'hospitalCashDetail')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'hospitalCashDetail',
                                          hospitalCashDetail ? hospitalCashDetail : val.hospitalCashDetail
                                        )}
                                      >
                                        {hospitalCashDetail && !this.isNullOrEmpty(hospitalCashDetail) ? (
                                          hospitalCashDetail
                                        ) : val.hospitalCashDetail && !this.isNullOrEmpty(val.hospitalCashDetail) ? (
                                          val.hospitalCashDetail
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'hospitalCashDetail',
                                              hospitalCashDetail ? permanentDisablement : val.hospitalCashDetail
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                              </div>
                            )}
                            {val.subCategoryId === '5c41b85a83230016450b2214' && (
                              <div>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Payment Terms'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'paymentTerms' ? (
                                      <div>
                                        <select
                                          name="paymentTerms"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={paymentTerms || ''}
                                        >
                                          {paymentTerms && (
                                            <option value={paymentTerms}>
                                              {paymentTerms &&
                                                paymentTerms.replace(/Years|Till age|Whole Life|Other/gi, function(
                                                  paymentTerm
                                                ) {
                                                  return intl.formatMessage({
                                                    id: paymentTerm
                                                  });
                                                })}
                                            </option>
                                          )}
                                          <option value="" />
                                          {Object.values(TERMS_OPTIONS).map(option => (
                                            <option value={option} key={option}>
                                              {getTermsIntl(intl, option)}
                                            </option>
                                          ))}
                                        </select>
                                        <div>
                                          {paymentTerms === 'Other' && (
                                            <input
                                              type="text"
                                              name="paymentTerms1"
                                              placeholder={intl.formatMessage({ id: 'Enter Payment Terms' })}
                                              value={this.state.paymentTerms1 || ''}
                                              onChange={this.handleChange.bind(this)}
                                            />
                                          )}
                                        </div>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'paymentTerms')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, paymentTerms, 'paymentTerms')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'paymentTerms',
                                          !isNullOrUndefined(paymentTerms) ? paymentTerms : val.paymentTerms
                                        )}
                                      >
                                        {paymentTerms !== undefined ? (
                                          paymentTerms.replace(/Years|Till age|Whole Life|Other/gi, function(
                                            paymentTerm
                                          ) {
                                            return intl.formatMessage({
                                              id: paymentTerm
                                            });
                                          })
                                        ) : val.paymentTerms !== undefined ? (
                                          val.paymentTerms.replace(/Years|Till age|Whole Life|Other/gi, function(
                                            paymentTerm
                                          ) {
                                            return intl.formatMessage({
                                              id: paymentTerm
                                            });
                                          })
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'paymentTerms',
                                              !isNullOrUndefined(paymentTerms) ? paymentTerms : val.paymentTerms
                                            )}
                                          />
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Property Value'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'propertyValue' ? (
                                      <div>
                                        <NumberFormat
                                          // type="number"
                                          // name="marketValue"
                                          thousandSeparator={true}
                                          className="left-value-input"
                                          onValueChange={values => {
                                            const { value } = values;
                                            this.setState({
                                              propertyValue: value
                                            });
                                          }}
                                          value={this.state.propertyValue || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'propertyValue')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(
                                            this,
                                            this.state.propertyValue,
                                            'propertyValue',
                                            this.state.propertyValue ? this.state.propertyValue : val.propertyValue
                                          )}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'propertyValue',
                                          this.state.propertyValue ? this.state.propertyValue : val.propertyValue
                                        )}
                                      >
                                        {propertyValue ? (
                                          intl.formatMessage({
                                            id: val.currency
                                          }) +
                                          ' ' +
                                          decimalToString(propertyValue)
                                        ) : val.propertyValue ? (
                                          intl.formatMessage({
                                            id: val.currency
                                          }) +
                                          ' ' +
                                          decimalToString(val.propertyValue)
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'propertyValue',
                                              propertyValue ? propertyValue : val.propertyValue
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Down Payment'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'downPayment' ? (
                                      <div>
                                        <NumberFormat
                                          // type="number"
                                          // name="marketValue"
                                          thousandSeparator={true}
                                          className="left-value-input"
                                          onValueChange={values => {
                                            const { value } = values;
                                            this.setState({
                                              downPayment: value
                                            });
                                          }}
                                          value={this.state.downPayment || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'downPayment')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(
                                            this,
                                            this.state.downPayment,
                                            'downPayment',
                                            this.state.downPayment ? this.state.downPayment : val.downPayment
                                          )}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'downPayment',
                                          this.state.downPayment ? this.state.downPayment : val.downPayment
                                        )}
                                      >
                                        {downPayment ? (
                                          intl.formatMessage({
                                            id: val.currency
                                          }) +
                                          ' ' +
                                          decimalToString(downPayment)
                                        ) : val.downPayment ? (
                                          intl.formatMessage({
                                            id: val.currency
                                          }) +
                                          ' ' +
                                          decimalToString(val.downPayment)
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'downPayment',
                                              downPayment ? downPayment : val.downPayment
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Loan Ratio'
                                    })}{' '}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'loanRatio' ? (
                                      <div>
                                        <input
                                          type="text"
                                          name="loanRatio"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={loanRatio || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'loanRatio')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, loanRatio, 'loanRatio')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'loanRatio',
                                          loanRatio ? loanRatio : val.loanRatio
                                        )}
                                      >
                                        {loanRatio && !this.isNullOrEmpty(loanRatio) ? (
                                          loanRatio
                                        ) : val.loanRatio && !this.isNullOrEmpty(val.loanRatio) ? (
                                          val.loanRatio
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'loanRatio',
                                              loanRatio ? loanRatio : val.loanRatio
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Outstanding Loan'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'outStandingLoan' ? (
                                      <div>
                                        <NumberFormat
                                          // type="number"
                                          // name="marketValue"
                                          thousandSeparator={true}
                                          className="left-value-input"
                                          onValueChange={values => {
                                            const { value } = values;
                                            this.setState({
                                              outStandingLoan: value
                                            });
                                          }}
                                          value={this.state.outStandingLoan || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'outStandingLoan')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(
                                            this,
                                            this.state.outStandingLoan,
                                            'outStandingLoan',
                                            this.state.outStandingLoan
                                              ? this.state.outStandingLoan
                                              : val.outStandingLoan
                                          )}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'outStandingLoan',
                                          this.state.outStandingLoan ? this.state.outStandingLoan : val.outStandingLoan
                                        )}
                                      >
                                        {outStandingLoan ? (
                                          intl.formatMessage({
                                            id: val.currency
                                          }) +
                                          ' ' +
                                          decimalToString(outStandingLoan)
                                        ) : val.outStandingLoan ? (
                                          intl.formatMessage({
                                            id: val.currency
                                          }) +
                                          ' ' +
                                          decimalToString(val.outStandingLoan)
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'outStandingLoan',
                                              outStandingLoan ? outStandingLoan : val.outStandingLoan
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Interest Rate'
                                    })}{' '}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'interestRate' ? (
                                      <div>
                                        <input
                                          type="text"
                                          name="interestRate"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={interestRate || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'interestRate')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, interestRate, 'interestRate')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'interestRate',
                                          interestRate ? interestRate : val.interestRate
                                        )}
                                      >
                                        {interestRate && !this.isNullOrEmpty(interestRate) ? (
                                          interestRate
                                        ) : val.interestRate && !this.isNullOrEmpty(val.interestRate) ? (
                                          val.interestRate
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'interestRate',
                                              interestRate ? interestRate : val.interestRate
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Interest Terms'
                                    })}{' '}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'interestTerms' ? (
                                      <div>
                                        <input
                                          type="text"
                                          name="interestTerms"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={interestTerms || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'interestTerms')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, interestTerms, 'interestTerms')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'interestTerms',
                                          interestTerms ? interestTerms : val.interestTerms
                                        )}
                                      >
                                        {interestTerms && !this.isNullOrEmpty(interestTerms) ? (
                                          interestTerms
                                        ) : val.interestTerms && !this.isNullOrEmpty(val.interestTerms) ? (
                                          val.interestTerms
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'interestTerms',
                                              interestTerms ? interestTerms : val.interestTerms
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Penalty period'
                                    })}{' '}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'penaltyPeriod' ? (
                                      <div>
                                        <input
                                          type="text"
                                          name="penaltyPeriod"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={penaltyPeriod || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'penaltyPeriod')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, penaltyPeriod, 'penaltyPeriod')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'penaltyPeriod',
                                          penaltyPeriod ? penaltyPeriod : val.penaltyPeriod
                                        )}
                                      >
                                        {penaltyPeriod && !this.isNullOrEmpty(penaltyPeriod) ? (
                                          penaltyPeriod
                                        ) : val.penaltyPeriod && !this.isNullOrEmpty(val.penaltyPeriod) ? (
                                          val.penaltyPeriod
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'penaltyPeriod',
                                              penaltyPeriod ? penaltyPeriod : val.penaltyPeriod
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                              </div>
                            )}
                            {val.subCategoryId === '5b9a5bfee624cdb543a476d1' && (
                              <div>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: "Employer's Liability"
                                    })}{' '}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'employersLiability' ? (
                                      <div>
                                        <input
                                          type="text"
                                          name="employersLiability"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={employersLiability || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'employersLiability')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, employersLiability, 'employersLiability')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'employersLiability',
                                          employersLiability ? employersLiability : val.employersLiability
                                        )}
                                      >
                                        {employersLiability && !this.isNullOrEmpty(employersLiability) ? (
                                          employersLiability
                                        ) : val.employersLiability && !this.isNullOrEmpty(val.employersLiability) ? (
                                          val.employersLiability
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'employersLiability',
                                              employersLiability ? employersLiability : val.employersLiability
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Medical Expenses'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'medicalExpenses' ? (
                                      <div>
                                        <input
                                          type="text"
                                          name="medicalExpenses"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={medicalExpenses || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'medicalExpenses')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, medicalExpenses, 'medicalExpenses')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'medicalExpenses',
                                          medicalExpenses ? medicalExpenses : val.medicalExpenses
                                        )}
                                      >
                                        {medicalExpenses && !this.isNullOrEmpty(medicalExpenses) ? (
                                          medicalExpenses
                                        ) : val.medicalExpenses && !this.isNullOrEmpty(val.medicalExpenses) ? (
                                          val.medicalExpenses
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'medicalExpenses',
                                              medicalExpenses ? medicalExpenses : val.medicalExpenses
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>

                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Maid Replacement'
                                    })}{' '}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'maidReplacement' ? (
                                      <div>
                                        <input
                                          type="text"
                                          name="maidReplacement"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={maidReplacement || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'maidReplacement')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, maidReplacement, 'maidReplacement')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'maidReplacement',
                                          maidReplacement ? maidReplacement : val.maidReplacement
                                        )}
                                      >
                                        {maidReplacement && !this.isNullOrEmpty(maidReplacement) ? (
                                          maidReplacement
                                        ) : val.maidReplacement && !this.isNullOrEmpty(val.maidReplacement) ? (
                                          val.maidReplacement
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'maidReplacement',
                                              maidReplacement ? maidReplacement : val.maidReplacement
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                              </div>
                            )}
                            {val.categoryId === '59b0eb3814ea7f1aafb6d0b7' &&
                              val.subCategoryId === '59b0ed98dae1831be084f807' && (
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Coverage detail'
                                    })}{' '}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'coverageDetail' ? (
                                      <div>
                                        <textarea
                                          type="text"
                                          name="coverageDetail"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={coverageDetail || ''}
                                          rows="3"
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'coverageDetail')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, coverageDetail, 'coverageDetail')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'coverageDetail',
                                          coverageDetail ? coverageDetail : val.coverageDetail
                                        )}
                                      >
                                        {coverageDetail && !this.isNullOrEmpty(coverageDetail) ? (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: sanitize(
                                                coverageDetail ? coverageDetail.replace(/\n/gi, '<br/>') : ''
                                              )
                                            }}
                                          />
                                        ) : val.coverageDetail && !this.isNullOrEmpty(val.coverageDetail) ? (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: sanitize(
                                                val.coverageDetail ? val.coverageDetail.replace(/\n/gi, '<br/>') : ''
                                              )
                                            }}
                                          />
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'coverageDetail',
                                              coverageDetail ? coverageDetail : val.coverageDetail
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                              )}
                            <div>
                              {/*<li>
                                    <div className="left-title">
                                      {intl.formatMessage({ id: 'Feature' })}:
                                    </div>
                                    <div className="left-value">
                                      {this.state.edit === 'feature' ? (
                                        <div>
                                          <Button
                                            variant={
                                              this.state.feature == 'Saving'
                                                ? 'contained'
                                                : 'outlined'
                                            }
                                            className={
                                              this.state.feature == 'Saving'
                                                ? 'option-select-button plan-type-select-button selected'
                                                : 'option-select-button plan-type-select-button'
                                            }
                                            onClick={() => {
                                              this.state.err.feature = '';
                                              this.setState({
                                                feature:
                                                  this.state.feature == 'Saving'
                                                    ? ''
                                                    : 'Saving'
                                              });
                                            }}
                                          >
                                            {intl.formatMessage({
                                              id: 'Saving'
                                            })}
                                          </Button>

                                          <Button
                                            variant={
                                              this.state.feature == 'Non Saving'
                                                ? 'contained'
                                                : 'outlined'
                                            }
                                            className={
                                              this.state.feature == 'Non Saving'
                                                ? 'option-select-button plan-type-select-button selected'
                                                : 'option-select-button plan-type-select-button'
                                            }
                                            onClick={() => {
                                              this.state.err.feature = '';
                                              this.setState({
                                                feature:
                                                  this.state.feature ==
                                                  'Non Saving'
                                                    ? ''
                                                    : 'Non Saving'
                                              });
                                            }}
                                          >
                                            {intl.formatMessage({
                                              id: 'Non Saving'
                                            })}
                                          </Button>

                                          <Button
                                            variant={
                                              this.state.feature == 'No Idea'
                                                ? 'contained'
                                                : 'outlined'
                                            }
                                            className={
                                              this.state.feature == 'No Idea'
                                                ? 'option-select-button plan-type-select-button selected'
                                                : 'option-select-button plan-type-select-button'
                                            }
                                            onClick={() => {
                                              this.state.err.feature = '';
                                              this.setState({
                                                feature:
                                                  this.state.feature ==
                                                  'No Idea'
                                                    ? ''
                                                    : 'No Idea'
                                              });
                                            }}
                                          >
                                            {intl.formatMessage({
                                              id: 'No Idea'
                                            })}
                                          </Button>

                                          <button
                                            className="info-btns"
                                            onClick={this.cancel.bind(
                                              this,
                                              'feature'
                                            )}
                                          >
                                            {intl.formatMessage({
                                              id: 'Cancel'
                                            })}
                                          </button>
                                          <button
                                            className="info-btns"
                                            onClick={this.save.bind(
                                              this,
                                              feature,
                                              'feature'
                                            )}
                                          >
                                            {intl.formatMessage({ id: 'Save' })}
                                          </button>
                                          <i className="bar" />
                                          {this.state.err.feature ? (
                                            <div className="error_field">
                                              {intl.formatMessage({
                                                id: this.state.err.feature
                                              })}
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      ) : (
                                        <div
                                          onClick={this.handleEdit.bind(
                                            this,
                                            'feature',
                                            feature ? feature : val.feature
                                          )}
                                        >
                                          {feature ? (
                                            intl.formatMessage({ id: feature })
                                          ) : val.feature ? (
                                            intl.formatMessage({
                                              id: val.feature
                                            })
                                          ) : (
                                            <span style={{ opacity: 0 }}>
                                              "N/A"
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </li>

                                  <li>
                                    <div className="left-title">
                                      {intl.formatMessage({ id: 'Plan Type' })}:
                                    </div>
                                    <div className="left-value">
                                      {this.state.edit === 'planType' ? (
                                        <div>
                                          <Button
                                            variant={
                                              planType == 'Basic'
                                                ? 'contained'
                                                : 'outlined'
                                            }
                                            className={
                                              planType == 'Basic'
                                                ? 'option-select-button plan-type-select-button selected'
                                                : 'option-select-button plan-type-select-button'
                                            }
                                            onClick={() => {
                                              this.state.err.planType = '';
                                              this.setState({
                                                planType:
                                                  planType == 'Basic'
                                                    ? ''
                                                    : 'Basic'
                                              });
                                            }}
                                          >
                                            {intl.formatMessage({
                                              id: 'Basic'
                                            })}
                                          </Button>

                                          <Button
                                            variant={
                                              planType == 'Rider'
                                                ? 'contained'
                                                : 'outlined'
                                            }
                                            className={
                                              planType == 'Rider'
                                                ? 'option-select-button plan-type-select-button selected'
                                                : 'option-select-button plan-type-select-button'
                                            }
                                            onClick={() => {
                                              this.state.err.planType = '';
                                              this.setState({
                                                planType:
                                                  planType == 'Rider'
                                                    ? ''
                                                    : 'Rider'
                                              });
                                            }}
                                          >
                                            {intl.formatMessage({
                                              id: 'Rider'
                                            })}
                                          </Button>
                                          <Button
                                            variant={
                                              planType === 'No Idea'
                                                ? 'contained'
                                                : 'outlined'
                                            }
                                            className={
                                              planType === 'No Idea'
                                                ? 'option-select-button plan-type-select-button selected'
                                                : 'option-select-button plan-type-select-button'
                                            }
                                            onClick={() => {
                                              this.state.err.planType = '';
                                              this.setState({
                                                planType:
                                                  planType === 'No Idea'
                                                    ? ''
                                                    : 'No Idea'
                                              });
                                            }}
                                          >
                                            {intl.formatMessage({
                                              id: 'No Idea'
                                            })}
                                          </Button>

                                          <button
                                            className="info-btns"
                                            onClick={this.cancel.bind(
                                              this,
                                              'planType'
                                            )}
                                          >
                                            {intl.formatMessage({
                                              id: 'Cancel'
                                            })}
                                          </button>
                                          <button
                                            className="info-btns"
                                            onClick={this.save.bind(
                                              this,
                                              planType,
                                              'planType'
                                            )}
                                          >
                                            {intl.formatMessage({ id: 'Save' })}
                                          </button>
                                          <i className="bar" />
                                          {this.state.err.planType ? (
                                            <div className="error_field">
                                              {intl.formatMessage({
                                                id: this.state.err.planType
                                              })}
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      ) : (
                                        <div
                                          onClick={this.handleEdit.bind(
                                            this,
                                            'planType',
                                            planType ? planType : val.planType
                                          )}
                                        >
                                          {planType ? (
                                            intl.formatMessage({ id: planType })
                                          ) : val.planType ? (
                                            intl.formatMessage({
                                              id: val.planType
                                            })
                                          ) : (
                                            <span style={{ opacity: 0 }}>
                                              "N/A"
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </li>*/}
                            </div>

                            {(val.categoryId === '59b0eaee14ea7f1aafb6d0b5' ||
                              val.subCategoryId === '5baf2af899d85c2d38c8f00f' ||
                              val.subCategoryId === '5b9a5bfee624cdb543a476d2') && (
                              <li>
                                <div className="left-title">{intl.formatMessage({ id: 'paid-up-date' })}</div>
                                <div className="left-value">
                                  {this.state.edit === 'paidUpDate' ? (
                                    <div>
                                      <DatePicker
                                        name="paidUpDate"
                                        maxDate={moment().add(120, 'years')}
                                        format="DD-MMM-YYYY"
                                        clearable={true}
                                        className="left-value-input"
                                        value={!this.state.paidUpDate ? null : moment.parseZone(this.state.paidUpDate)}
                                        openTo="year"
                                        onChange={date => this.setState({ paidUpDate: date })}
                                        autoOk={true}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton style={{ padding: '0 5px 0 0', marginLeft: '-12px' }}>
                                                <TodayIcon color="action" style={{ fontSize: 20 }} />
                                              </IconButton>
                                            </InputAdornment>
                                          )
                                        }}
                                        inputProps={{ style: { textAlign: 'center' } }}
                                      />
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="insure-details-edit-button"
                                        onClick={() =>
                                          this.setState({
                                            edit: '',
                                            paidUpDate: this.state.editValue
                                          })
                                        }
                                      >
                                        {intl.formatMessage({ id: 'Cancel' })}
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="insure-details-edit-button"
                                        onClick={this.handleDateChange.bind(this, 'paidUpDate')}
                                      >
                                        {intl.formatMessage({ id: 'Save' })}
                                      </Button>
                                    </div>
                                  ) : (
                                    <div
                                      className="fs-value"
                                      onClick={() =>
                                        this.setState({
                                          edit: 'paidUpDate',
                                          editValue: this.state.paidUpDate
                                            ? moment.parseZone(this.state.paidUpDate)
                                            : null
                                        })
                                      }
                                    >
                                      {!this.state.paidUpDate ? (
                                        <span
                                          className="left-null-value"
                                          onClick={() =>
                                            this.setState({
                                              edit: 'paidUpDate',
                                              editValue: this.state.paidUpDate
                                                ? moment.parseZone(this.state.paidUpDate)
                                                : null
                                            })
                                          }
                                        >
                                          N/A
                                        </span>
                                      ) : this.state.paidUpDate ? (
                                        moment.parseZone(this.state.paidUpDate).format('DD-MMM-YYYY')
                                      ) : null}
                                      <EditIcon className="fs-value-edit-icon" />
                                    </div>
                                  )}
                                </div>
                              </li>
                            )}

                            {val.categoryId === '59b0eaee14ea7f1aafb6d0b5' ||
                            val.subCategoryId === '5baf2af899d85c2d38c8f00f' ||
                            val.subCategoryId === '5b9a5bfee624cdb543a476d2' ? (
                              <div>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Life Insured'
                                    })}
                                    * :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'lifeInsured' ? (
                                      <div>
                                        {lifeInsuredSelected === 'Not Me' && (
                                          <img alt="" className="not_me_icon" src="img/notme.png" />
                                        )}

                                        <Button
                                          variant={lifeInsuredSelected === 'Self' ? 'contained' : 'outlined'}
                                          className={
                                            'option-select-button plan-type-select-button ' +
                                            (lifeInsuredSelected === 'Self' ? 'selected' : '')
                                          }
                                          onClick={() => {
                                            delete this.state.err.lifeInsuredSelected;
                                            this.setState({
                                              lifeInsuredSelected: 'Self'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({ id: 'Self' })}
                                        </Button>
                                        <Button
                                          variant={lifeInsuredSelected === 'Not Me' ? 'contained' : 'outlined'}
                                          className={
                                            'option-select-button plan-type-select-button ' +
                                            (lifeInsuredSelected === 'Not Me' ? 'selected' : '')
                                          }
                                          onClick={() => {
                                            delete this.state.err.lifeInsuredSelected;
                                            this.setState({
                                              lifeInsuredSelected: 'Not Me'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id: 'Not Me'
                                          })}
                                        </Button>
                                        <Button
                                          variant={lifeInsuredSelected === 'No Idea' ? 'contained' : 'outlined'}
                                          className={
                                            'option-select-button plan-type-select-button ' +
                                            (lifeInsuredSelected === 'No Idea' ? 'selected' : '')
                                          }
                                          onClick={() => {
                                            delete this.state.err.lifeInsuredSelected;
                                            this.setState({
                                              lifeInsuredSelected: 'No Idea'
                                            });
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id: 'No Idea'
                                          })}
                                        </Button>
                                        <div>
                                          <input
                                            className="life-insured-inline-input left-value-input"
                                            placeholder={intl.formatMessage({
                                              id: 'Enter Life Insured'
                                            })}
                                            type="text"
                                            name="lifeInsured"
                                            onChange={this.handleChange.bind(this)}
                                            value={lifeInsured || ''}
                                          />
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="insure-details-edit-button"
                                            onClick={this.cancel.bind(this, 'lifeInsured')}
                                          >
                                            {intl.formatMessage({ id: 'Cancel' })}
                                          </Button>
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="insure-details-edit-button"
                                            onClick={this.save.bind(this, lifeInsured, 'lifeInsured')}
                                          >
                                            {intl.formatMessage({ id: 'Save' })}
                                          </Button>
                                          {this.state.err.lifeInsuredSelected && (
                                            <div className="error_field">
                                              {intl.formatMessage({
                                                id: this.state.err.lifeInsuredSelected
                                              })}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        // className="self__btn--grp"
                                        onClick={this.handleLifeInsuredEdit.bind(
                                          this,
                                          'lifeInsured',
                                          lifeInsured !== undefined ? lifeInsured : val.lifeInsured,
                                          lifeInsuredSelected ? lifeInsuredSelected : val.lifeInsuredSelected
                                        )}
                                      >
                                        {lifeInsuredSelected && lifeInsuredSelected === 'Not Me' ? (
                                          <img alt="" className="not_me_icon" src="img/notme.png" />
                                        ) : val.lifeInsuredSelected &&
                                          val.lifeInsuredSelected === 'Not Me' &&
                                          !lifeInsuredSelected ? (
                                          <img alt="" className="not_me_icon" src="img/notme.png" />
                                        ) : (
                                          ''
                                        )}
                                        {(lifeInsuredSelected !== '' &&
                                          lifeInsuredSelected !== null &&
                                          lifeInsuredSelected !== undefined) ||
                                        (val.lifeInsuredSelected !== undefined &&
                                          val.lifeInsuredSelected !== null &&
                                          val.lifeInsuredSelected !== '') ? (
                                          <div className="life-insured-seleced-button">
                                            {lifeInsuredSelected
                                              ? intl.formatMessage({
                                                  id: lifeInsuredSelected
                                                })
                                              : val.lifeInsuredSelected
                                              ? intl.formatMessage({
                                                  id: val.lifeInsuredSelected
                                                })
                                              : ''}
                                          </div>
                                        ) : (
                                          ' '
                                        )}
                                        {lifeInsured !== undefined ? (
                                          lifeInsured
                                        ) : val.lifeInsured && !this.isNullOrEmpty(val.lifeInsured) ? (
                                          val.lifeInsured
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleLifeInsuredEdit.bind(
                                              this,
                                              'lifeInsured',
                                              lifeInsured !== undefined ? lifeInsured : val.lifeInsured,
                                              lifeInsuredSelected ? lifeInsuredSelected : val.lifeInsuredSelected
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>

                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Age of owner at policy start'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'lifeInsuredAge' ? (
                                      <div>
                                        <input
                                          type="text"
                                          name="lifeInsuredAge"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={lifeInsuredAge || ''}
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'lifeInsuredAge')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, lifeInsuredAge, 'lifeInsuredAge')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'lifeInsuredAge',
                                          lifeInsuredAge ? lifeInsuredAge : val.lifeInsuredAge
                                        )}
                                      >
                                        {lifeInsuredAge && !this.isNullOrEmpty(lifeInsuredAge) ? (
                                          lifeInsuredAge
                                        ) : val.lifeInsuredAge && !this.isNullOrEmpty(val.lifeInsuredAge) ? (
                                          val.lifeInsuredAge
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'lifeInsuredAge',
                                              lifeInsuredAge ? lifeInsuredAge : val.lifeInsuredAge
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                {subCategoryType === 'Critical Illness' && (
                                  <div>
                                    <TriggerButtonsEdit
                                      intl={intl}
                                      title={intl.formatMessage({ id: 'multi-claim' })}
                                      value={this.state.multiClaim}
                                      originalValue={val.multiClaim}
                                      isEdit={this.state.edit === 'multiClaim'}
                                      onEdit={this.handleEdit.bind(
                                        this,
                                        'multiClaim',
                                        this.state.multiClaim !== undefined ? this.state.multiClaim : val.multiClaim
                                      )}
                                      onChange={value => this.setState({ multiClaim: value })}
                                      onCancel={this.cancel.bind(this, 'multiClaim')}
                                      onSave={this.save.bind(this, this.state.multiClaim, 'multiClaim')}
                                    />
                                    <TriggerButtonsEdit
                                      intl={intl}
                                      title={intl.formatMessage({ id: 'early-stage' })}
                                      value={this.state.earlyStage}
                                      originalValue={val.earlyStage}
                                      isEdit={this.state.edit === 'earlyStage'}
                                      onEdit={this.handleEdit.bind(
                                        this,
                                        'earlyStage',
                                        this.state.earlyStage !== undefined ? this.state.earlyStage : val.earlyStage
                                      )}
                                      onChange={value => this.setState({ earlyStage: value })}
                                      onCancel={this.cancel.bind(this, 'earlyStage')}
                                      onSave={this.save.bind(this, this.state.earlyStage, 'earlyStage')}
                                    />
                                  </div>
                                )}
                                {subCategoryType === 'Medical' && (
                                  <div>
                                    <TriggerButtonsEdit
                                      intl={intl}
                                      title={intl.formatMessage({ id: 'SMM' })}
                                      value={this.state.smm}
                                      originalValue={val.smm}
                                      isEdit={this.state.edit === 'smm'}
                                      onEdit={this.handleEdit.bind(this, 'smm', this.state.smm || val.smm)}
                                      onChange={value => this.setState({ smm: value })}
                                      onCancel={this.cancel.bind(this, 'smm')}
                                      onSave={this.save.bind(this, this.state.smm, 'smm')}
                                      yesValue="Yes"
                                      noValue="No"
                                    />

                                    <TriggerButtonsEdit
                                      intl={intl}
                                      title={intl.formatMessage({ id: 'Hospital Cash' })}
                                      value={this.state.hospitalCash}
                                      originalValue={val.hospitalCash}
                                      isEdit={this.state.edit === 'hospitalCash'}
                                      onEdit={this.handleEdit.bind(
                                        this,
                                        'hospitalCash',
                                        this.state.hospitalCash || val.hospitalCash
                                      )}
                                      onChange={value => this.setState({ hospitalCash: value })}
                                      onCancel={this.cancel.bind(this, 'hospitalCash')}
                                      onSave={this.save.bind(this, this.state.hospitalCash, 'hospitalCash')}
                                      yesValue="Yes"
                                      noValue="No"
                                    />

                                    <TriggerButtonsEdit
                                      intl={intl}
                                      title={intl.formatMessage({ id: 'Outpatient' })}
                                      value={this.state.outPatient}
                                      originalValue={val.outPatient}
                                      isEdit={this.state.edit === 'outPatient'}
                                      onEdit={this.handleEdit.bind(
                                        this,
                                        'outPatient',
                                        this.state.outPatient || val.outPatient
                                      )}
                                      onChange={value => this.setState({ outPatient: value })}
                                      onCancel={this.cancel.bind(this, 'outPatient')}
                                      onSave={this.save.bind(this, this.state.outPatient, 'outPatient')}
                                      yesValue="Yes"
                                      noValue="No"
                                    />

                                    <TriggerButtonsEdit
                                      intl={intl}
                                      title={intl.formatMessage({ id: 'Dental' })}
                                      value={this.state.dental}
                                      originalValue={val.dental}
                                      isEdit={this.state.edit === 'dental'}
                                      onEdit={this.handleEdit.bind(this, 'dental', this.state.dental || val.dental)}
                                      onChange={value => this.setState({ dental: value })}
                                      onCancel={this.cancel.bind(this, 'dental')}
                                      onSave={this.save.bind(this, this.state.dental, 'dental')}
                                      yesValue="Yes"
                                      noValue="No"
                                    />
                                  </div>
                                )}
                                {subCategoryType === 'Medical' && (
                                  <li>
                                    <div className="left-title">
                                      {intl.formatMessage({
                                        id: 'Deductible'
                                      })}
                                      :
                                    </div>
                                    <div className="left-value">
                                      {this.state.edit === 'deductible' ? (
                                        <div>
                                          <input
                                            type="text"
                                            name="deductible"
                                            className="left-value-input"
                                            onChange={this.handleChange.bind(this)}
                                            value={deductible || ''}
                                          />
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="insure-details-edit-button"
                                            onClick={this.cancel.bind(this, 'deductible')}
                                          >
                                            {intl.formatMessage({ id: 'Cancel' })}
                                          </Button>
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="insure-details-edit-button"
                                            onClick={this.save.bind(this, deductible, 'deductible')}
                                          >
                                            {intl.formatMessage({ id: 'Save' })}
                                          </Button>
                                        </div>
                                      ) : (
                                        <div
                                          className="fs-value"
                                          onClick={this.handleEdit.bind(
                                            this,
                                            'deductible',
                                            deductible ? deductible : val.deductible
                                          )}
                                        >
                                          {deductible && !this.isNullOrEmpty(deductible) ? (
                                            deductible
                                          ) : val.deductible && !this.isNullOrEmpty(val.deductible) ? (
                                            val.deductible
                                          ) : (
                                            <span
                                              className="left-null-value"
                                              onClick={this.handleEdit.bind(
                                                this,
                                                'deductible',
                                                deductible ? deductible : val.deductible
                                              )}
                                            >
                                              N/A
                                            </span>
                                          )}
                                          <EditIcon className="fs-value-edit-icon" />
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                )}
                                {subCategoryType === 'Medical' && (
                                  <li>
                                    <div className="left-title">
                                      {intl.formatMessage({
                                        id: 'Max. Claim Limit'
                                      })}
                                      :
                                    </div>
                                    <div className="left-value">
                                      {this.state.edit === 'claimLimit' ? (
                                        <div>
                                          <input
                                            type="text"
                                            name="claimLimit"
                                            className="left-value-input"
                                            onChange={this.handleChange.bind(this)}
                                            value={claimLimit || ''}
                                          />
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="insure-details-edit-button"
                                            onClick={this.cancel.bind(this, 'claimLimit')}
                                          >
                                            {intl.formatMessage({ id: 'Cancel' })}
                                          </Button>
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="insure-details-edit-button"
                                            onClick={this.save.bind(this, claimLimit, 'claimLimit')}
                                          >
                                            {intl.formatMessage({ id: 'Save' })}
                                          </Button>
                                        </div>
                                      ) : (
                                        <div
                                          className="fs-value"
                                          onClick={this.handleEdit.bind(
                                            this,
                                            'claimLimit',
                                            claimLimit ? claimLimit : val.claimLimit
                                          )}
                                        >
                                          {claimLimit && !this.isNullOrEmpty(claimLimit) ? (
                                            claimLimit
                                          ) : val.claimLimit && !this.isNullOrEmpty(val.claimLimit) ? (
                                            val.claimLimit
                                          ) : (
                                            <span
                                              className="left-null-value"
                                              onClick={this.handleEdit.bind(
                                                this,
                                                'claimLimit',
                                                claimLimit ? claimLimit : val.claimLimit
                                              )}
                                            >
                                              N/A
                                            </span>
                                          )}
                                          <EditIcon className="fs-value-edit-icon" />
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                )}
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Payment Terms'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'paymentTerms' ? (
                                      <div>
                                        <select
                                          name="paymentTerms"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={paymentTerms || ''}
                                        >
                                          {paymentTerms && (
                                            <option value={paymentTerms}>
                                              {paymentTerms &&
                                                paymentTerms.replace(/Years|Till age|Whole Life|Other/gi, function(
                                                  paymentTerm
                                                ) {
                                                  return intl.formatMessage({
                                                    id: paymentTerm
                                                  });
                                                })}
                                            </option>
                                          )}
                                          <option value="" />
                                          {Object.values(TERMS_OPTIONS).map(option => (
                                            <option value={option} key={option}>
                                              {getTermsIntl(intl, option)}
                                            </option>
                                          ))}
                                        </select>
                                        <div>
                                          {paymentTerms === 'Other' && (
                                            <input
                                              type="text"
                                              name="paymentTerms1"
                                              placeholder={intl.formatMessage({ id: 'Enter Payment Terms' })}
                                              value={this.state.paymentTerms1 || ''}
                                              onChange={this.handleChange.bind(this)}
                                            />
                                          )}
                                        </div>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'paymentTerms')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(
                                            this,
                                            isNullOrUndefined(paymentTerms) ? '' : paymentTerms,
                                            'paymentTerms'
                                          )}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'paymentTerms',
                                          !isNullOrUndefined(paymentTerms) ? paymentTerms : val.paymentTerms
                                        )}
                                      >
                                        {paymentTerms !== undefined ? (
                                          paymentTerms.replace(/Years|Till age|Whole Life|Other/gi, function(
                                            paymentTerm
                                          ) {
                                            return intl.formatMessage({
                                              id: paymentTerm
                                            });
                                          })
                                        ) : val.paymentTerms !== undefined ? (
                                          val.paymentTerms.replace(/Years|Till age|Whole Life|Other/gi, function(
                                            paymentTerm
                                          ) {
                                            return intl.formatMessage({
                                              id: paymentTerm
                                            });
                                          })
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'paymentTerms',
                                              !isNullOrUndefined(paymentTerms) ? paymentTerms : val.paymentTerms
                                            )}
                                          />
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>

                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Protection Terms'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'protectionTerms' ? (
                                      <div>
                                        <select
                                          name="protectionTerms"
                                          className="left-value-input"
                                          onChange={this.handleChange.bind(this)}
                                          value={protectionTerms || ''}
                                        >
                                          {protectionTerms && (
                                            <option value={protectionTerms}>
                                              {protectionTerms &&
                                                protectionTerms.replace(/Years|Till age|Whole Life|Other/gi, function(
                                                  protectionTerms
                                                ) {
                                                  return intl.formatMessage({
                                                    id: protectionTerms
                                                  });
                                                })}
                                            </option>
                                          )}
                                          <option value="" />
                                          {Object.values(TERMS_OPTIONS).map(option => (
                                            <option value={option} key={option}>
                                              {getTermsIntl(intl, option)}
                                            </option>
                                          ))}
                                        </select>
                                        <div>
                                          {protectionTerms === 'Other' && (
                                            <input
                                              type="text"
                                              name="protectionTerms1"
                                              placeholder={intl.formatMessage({
                                                id: 'Enter Protection Terms'
                                              })}
                                              value={this.state.protectionTerms1 || ''}
                                              onChange={this.handleChange.bind(this)}
                                            />
                                          )}
                                        </div>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'protectionTerms')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, protectionTerms, 'protectionTerms')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'protectionTerms',
                                          !isNullOrUndefined(protectionTerms) ? protectionTerms : val.protectionTerms
                                        )}
                                      >
                                        {protectionTerms !== undefined ? (
                                          protectionTerms.replace(/Years|Till age|Whole Life|Other/gi, function(
                                            protectionTerm
                                          ) {
                                            return intl.formatMessage({
                                              id: protectionTerm
                                            });
                                          })
                                        ) : val.protectionTerms !== undefined ? (
                                          val.protectionTerms.replace(/Years|Till age|Whole Life|Other/gi, function(
                                            protectionTerm
                                          ) {
                                            return intl.formatMessage({
                                              id: protectionTerm
                                            });
                                          })
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'protectionTerms',
                                              !isNullOrUndefined(protectionTerms)
                                                ? protectionTerms
                                                : val.protectionTerms
                                            )}
                                          />
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                              </div>
                            ) : (
                              <div>
                                {val.subCategoryId === '59b0edd8dae1831be084f808' ? (
                                  <div>
                                    <li>
                                      <div className="left-title">
                                        {' '}
                                        {intl.formatMessage({
                                          id: 'Vehicle Brand'
                                        })}{' '}
                                        :
                                      </div>
                                      <div className="left-value">
                                        {this.state.edit === 'vehicleBrand' ? (
                                          <div>
                                            <input
                                              type="text"
                                              name="vehicleBrand"
                                              className="left-value-input"
                                              onChange={this.handleChange.bind(this)}
                                              value={vehicleBrand || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'vehicleBrand')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(this, vehicleBrand, 'vehicleBrand')}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <div
                                            className="fs-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'vehicleBrand',
                                              vehicleBrand ? vehicleBrand : val.vehicleBrand
                                            )}
                                          >
                                            {vehicleBrand && !this.isNullOrEmpty(vehicleBrand) ? (
                                              vehicleBrand
                                            ) : val.vehicleBrand && !this.isNullOrEmpty(val.vehicleBrand) ? (
                                              val.vehicleBrand
                                            ) : (
                                              <span
                                                className="left-null-value"
                                                onClick={this.handleEdit.bind(
                                                  this,
                                                  'vehicleBrand',
                                                  vehicleBrand ? vehicleBrand : val.vehicleBrand
                                                )}
                                              >
                                                N/A
                                              </span>
                                            )}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </div>
                                        )}
                                      </div>
                                    </li>

                                    <li>
                                      <div className="left-title">
                                        {' '}
                                        {intl.formatMessage({
                                          id: 'Vehicle Model'
                                        })}
                                        :
                                      </div>
                                      <div className="left-value">
                                        {this.state.edit === 'vehicleModel' ? (
                                          <div>
                                            <input
                                              type="text"
                                              name="vehicleModel"
                                              className="left-value-input"
                                              onChange={this.handleChange.bind(this)}
                                              value={vehicleModel || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'vehicleModel')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(this, vehicleModel, 'vehicleModel')}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <div
                                            className="fs-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'vehicleModel',
                                              vehicleModel ? vehicleModel : val.vehicleModel
                                            )}
                                          >
                                            {vehicleModel && !this.isNullOrEmpty(vehicleModel) ? (
                                              vehicleModel
                                            ) : val.vehicleModel && !this.isNullOrEmpty(val.vehicleModel) ? (
                                              val.vehicleModel
                                            ) : (
                                              <span
                                                className="left-null-value"
                                                onClick={this.handleEdit.bind(
                                                  this,
                                                  'vehicleModel',
                                                  vehicleModel ? vehicleModel : val.vehicleModel
                                                )}
                                              >
                                                N/A
                                              </span>
                                            )}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </div>
                                        )}
                                      </div>
                                    </li>

                                    <li>
                                      <div className="left-title">
                                        {' '}
                                        {intl.formatMessage({
                                          id: 'Vehicle Year'
                                        })}
                                        :
                                      </div>
                                      <div className="left-value">
                                        {this.state.edit === 'vehicleYear' ? (
                                          <div>
                                            <input
                                              type="text"
                                              name="vehicleYear"
                                              className="left-value-input"
                                              onChange={this.handleChange.bind(this)}
                                              value={vehicleYear || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'vehicleYear')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(this, vehicleYear, 'vehicleYear')}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <div
                                            className="fs-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'vehicleYear',
                                              vehicleYear ? vehicleYear : val.vehicleYear
                                            )}
                                          >
                                            {vehicleYear && !this.isNullOrEmpty(vehicleYear) ? (
                                              vehicleYear
                                            ) : val.vehicleYear && !this.isNullOrEmpty(val.vehicleYear) ? (
                                              val.vehicleYear
                                            ) : (
                                              <span
                                                className="left-null-value"
                                                onClick={this.handleEdit.bind(
                                                  this,
                                                  'vehicleYear',
                                                  vehicleYear ? vehicleYear : val.vehicleYear
                                                )}
                                              >
                                                N/A
                                              </span>
                                            )}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </div>
                                        )}
                                      </div>
                                    </li>

                                    <li>
                                      <div className="left-title"> {intl.formatMessage({ id: 'NCB' })}:</div>
                                      <div className="left-value">
                                        {this.state.edit === 'ncb' ? (
                                          <div>
                                            <input
                                              type="text"
                                              name="ncb"
                                              className="left-value-input"
                                              onChange={this.handleChange.bind(this)}
                                              value={ncb || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'ncb')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(this, ncb, 'ncb')}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <span
                                            className="fs-value"
                                            onClick={this.handleEdit.bind(this, 'ncb', ncb ? ncb : val.ncb)}
                                          >
                                            {ncb && !this.isNullOrEmpty(ncb) ? (
                                              ncb
                                            ) : val.ncb && !this.isNullOrEmpty(val.ncb) ? (
                                              val.ncb
                                            ) : (
                                              <span
                                                className="left-null-value"
                                                onClick={this.handleEdit.bind(this, 'ncb', ncb ? ncb : val.ncb)}
                                              >
                                                N/A
                                              </span>
                                            )}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </span>
                                        )}
                                      </div>
                                    </li>
                                  </div>
                                ) : (
                                  ''
                                )}

                                {val.subCategoryId === '59b0eddfdae1831be084f809' && (
                                  <div>
                                    <li>
                                      <div className="left-title">
                                        {' '}
                                        {intl.formatMessage({
                                          id: 'Home Size'
                                        })}{' '}
                                        :
                                      </div>
                                      <div className="left-value">
                                        {this.state.edit === 'homeSize' ? (
                                          <div>
                                            <input
                                              type="text"
                                              name="homeSize"
                                              className="left-value-input"
                                              onChange={this.handleChange.bind(this)}
                                              value={homeSize || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'homeSize')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(this, homeSize, 'homeSize')}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <span
                                            className="fs-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'homeSize',
                                              homeSize ? homeSize : val.homeSize
                                            )}
                                          >
                                            {homeSize && !this.isNullOrEmpty(homeSize) ? (
                                              homeSize
                                            ) : val.homeSize && !this.isNullOrEmpty(val.homeSize) ? (
                                              val.homeSize
                                            ) : (
                                              <span
                                                className="left-null-value"
                                                onClick={this.handleEdit.bind(
                                                  this,
                                                  'homeSize',
                                                  homeSize ? homeSize : val.homeSize
                                                )}
                                              >
                                                N/A
                                              </span>
                                            )}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </span>
                                        )}
                                      </div>
                                    </li>

                                    <li>
                                      <div className="left-title">
                                        {' '}
                                        {intl.formatMessage({
                                          id: 'Home contents value'
                                        })}
                                        :
                                      </div>
                                      <div className="left-value">
                                        {this.state.edit === 'homeContentsValue' ? (
                                          <div>
                                            <input
                                              type="text"
                                              name="homeContentsValue"
                                              className="left-value-input"
                                              onChange={this.handleChange.bind(this)}
                                              value={homeContentsValue || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'homeContentsValue')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(this, homeContentsValue, 'homeContentsValue')}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <div
                                            className="fs-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'homeContentsValue',
                                              homeContentsValue ? homeContentsValue : val.homeContentsValue
                                            )}
                                          >
                                            {homeContentsValue && !this.isNullOrEmpty(homeContentsValue) ? (
                                              homeContentsValue
                                            ) : val.homeContentsValue && !this.isNullOrEmpty(val.homeContentsValue) ? (
                                              val.homeContentsValue
                                            ) : (
                                              <span
                                                className="left-null-value"
                                                onClick={this.handleEdit.bind(
                                                  this,
                                                  'homeContentsValue',
                                                  homeContentsValue ? homeContentsValue : val.homeContentsValue
                                                )}
                                              >
                                                N/A
                                              </span>
                                            )}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </div>
                                        )}
                                      </div>
                                    </li>
                                  </div>
                                )}

                                {val.subCategoryId === '59b0ede7dae1831be084f80a' ? (
                                  <div>
                                    <li>
                                      <div className="left-title">
                                        {' '}
                                        {intl.formatMessage({
                                          id: 'Coverage no. of day'
                                        })}{' '}
                                        :
                                      </div>
                                      <div className="left-value">
                                        {this.state.edit === 'coverageDay' ? (
                                          <div>
                                            <input
                                              type="number"
                                              name="coverageDay"
                                              className="left-value-input"
                                              onChange={this.handleChange.bind(this)}
                                              value={coverageDay || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'coverageDay')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(this, coverageDay, 'coverageDay')}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <div
                                            className="fs-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'coverageDay',
                                              coverageDay ? coverageDay : val.coverageDay
                                            )}
                                          >
                                            {coverageDay ? (
                                              coverageDay
                                            ) : val.coverageDay ? (
                                              val.coverageDay
                                            ) : (
                                              <span
                                                className="left-null-value"
                                                onClick={this.handleEdit.bind(
                                                  this,
                                                  'coverageDay',
                                                  coverageDay ? coverageDay : val.coverageDay
                                                )}
                                              >
                                                N/A
                                              </span>
                                            )}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </div>
                                        )}
                                      </div>
                                    </li>

                                    <li>
                                      <div className="left-title">
                                        {' '}
                                        {intl.formatMessage({
                                          id: 'Trip delay cover'
                                        })}
                                        :
                                      </div>
                                      <div className="left-value">
                                        {this.state.edit === 'tripDelayCover' ? (
                                          <div>
                                            <input
                                              type="text"
                                              name="tripDelayCover"
                                              className="left-value-input"
                                              onChange={this.handleChange.bind(this)}
                                              value={tripDelayCover || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'tripDelayCover')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(this, tripDelayCover, 'tripDelayCover')}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <div
                                            className="fs-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'tripDelayCover',
                                              tripDelayCover ? tripDelayCover : val.tripDelayCover
                                            )}
                                          >
                                            {tripDelayCover && !this.isNullOrEmpty(tripDelayCover) ? (
                                              tripDelayCover
                                            ) : val.tripDelayCover && !this.isNullOrEmpty(val.tripDelayCover) ? (
                                              val.tripDelayCover
                                            ) : (
                                              <span
                                                className="left-null-value"
                                                onClick={this.handleEdit.bind(
                                                  this,
                                                  'tripDelayCover',
                                                  tripDelayCover ? tripDelayCover : val.tripDelayCover
                                                )}
                                              >
                                                N/A
                                              </span>
                                            )}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </div>
                                        )}
                                      </div>
                                    </li>

                                    <li>
                                      <div className="left-title">
                                        {' '}
                                        {intl.formatMessage({
                                          id: 'Loss item cover'
                                        })}
                                        :
                                      </div>
                                      <div className="left-value">
                                        {this.state.edit === 'lossItemCover' ? (
                                          <div>
                                            <input
                                              type="text"
                                              name="lossItemCover"
                                              className="left-value-input"
                                              onChange={this.handleChange.bind(this)}
                                              value={lossItemCover || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'lossItemCover')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(this, lossItemCover, 'lossItemCover')}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <div
                                            className="fs-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'lossItemCover',
                                              lossItemCover ? lossItemCover : val.lossItemCover
                                            )}
                                          >
                                            {lossItemCover && !this.isNullOrEmpty(lossItemCover) ? (
                                              lossItemCover
                                            ) : val.lossItemCover && !this.isNullOrEmpty(val.lossItemCover) ? (
                                              val.lossItemCover
                                            ) : (
                                              <span
                                                className="left-null-value"
                                                onClick={this.handleEdit.bind(
                                                  this,
                                                  'lossItemCover',
                                                  lossItemCover ? lossItemCover : val.lossItemCover
                                                )}
                                              >
                                                N/A
                                              </span>
                                            )}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </div>
                                        )}
                                      </div>
                                    </li>

                                    <li>
                                      <div className="left-title">
                                        {' '}
                                        {intl.formatMessage({
                                          id: 'Mobile phone cover'
                                        })}
                                        :
                                      </div>
                                      <div className="left-value">
                                        {this.state.edit === 'mobilePhoneCover' ? (
                                          <div>
                                            <input
                                              type="text"
                                              name="mobilePhoneCover"
                                              className="left-value-input"
                                              onChange={this.handleChange.bind(this)}
                                              value={mobilePhoneCover || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'mobilePhoneCover')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(this, mobilePhoneCover, 'mobilePhoneCover')}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <div
                                            className="fs-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'mobilePhoneCover',
                                              mobilePhoneCover ? mobilePhoneCover : val.mobilePhoneCover
                                            )}
                                          >
                                            {mobilePhoneCover && !this.isNullOrEmpty(mobilePhoneCover) ? (
                                              mobilePhoneCover
                                            ) : val.mobilePhoneCover && !this.isNullOrEmpty(val.mobilePhoneCover) ? (
                                              val.mobilePhoneCover
                                            ) : (
                                              <span
                                                className="left-null-value"
                                                onClick={this.handleEdit.bind(
                                                  this,
                                                  'mobilePhoneCover',
                                                  mobilePhoneCover ? mobilePhoneCover : val.mobilePhoneCover
                                                )}
                                              >
                                                N/A
                                              </span>
                                            )}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </div>
                                        )}
                                      </div>
                                    </li>

                                    <li>
                                      <div className="left-title">
                                        {' '}
                                        {intl.formatMessage({
                                          id: 'Rental car cover'
                                        })}
                                        :
                                      </div>
                                      <div className="left-value">
                                        {this.state.edit === 'rentalCarCover' ? (
                                          <div>
                                            <input
                                              type="text"
                                              name="rentalCarCover"
                                              className="left-value-input"
                                              onChange={this.handleChange.bind(this)}
                                              value={rentalCarCover || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'rentalCarCover')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(this, rentalCarCover, 'rentalCarCover')}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <div
                                            className="fs-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'rentalCarCover',
                                              rentalCarCover ? rentalCarCover : val.rentalCarCover
                                            )}
                                          >
                                            {rentalCarCover && !this.isNullOrEmpty(rentalCarCover) ? (
                                              rentalCarCover
                                            ) : val.rentalCarCover && !this.isNullOrEmpty(val.rentalCarCover) ? (
                                              val.rentalCarCover
                                            ) : (
                                              <span
                                                className="left-null-value"
                                                onClick={this.handleEdit.bind(
                                                  this,
                                                  'rentalCarCover',
                                                  rentalCarCover ? rentalCarCover : val.rentalCarCover
                                                )}
                                              >
                                                N/A
                                              </span>
                                            )}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </div>
                                        )}
                                      </div>
                                    </li>

                                    <li>
                                      <div className="left-title">
                                        {' '}
                                        {intl.formatMessage({
                                          id: 'Emergency Tel'
                                        })}
                                        :
                                      </div>
                                      <div className="left-value">
                                        {this.state.edit === 'emergencyTel' ? (
                                          <div>
                                            <input
                                              type="text"
                                              name="emergencyTel"
                                              className="left-value-input"
                                              onChange={this.handleChange.bind(this)}
                                              value={emergencyTel || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'emergencyTel')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(this, emergencyTel, 'emergencyTel')}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <div
                                            className="fs-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'emergencyTel',
                                              emergencyTel ? emergencyTel : val.emergencyTel
                                            )}
                                          >
                                            {emergencyTel && !this.isNullOrEmpty(emergencyTel) ? (
                                              emergencyTel
                                            ) : val.emergencyTel && !this.isNullOrEmpty(val.emergencyTel) ? (
                                              val.emergencyTel
                                            ) : (
                                              <span
                                                className="left-null-value"
                                                onClick={this.handleEdit.bind(
                                                  this,
                                                  'emergencyTel',
                                                  emergencyTel ? emergencyTel : val.emergencyTel
                                                )}
                                              >
                                                N/A
                                              </span>
                                            )}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </div>
                                        )}
                                      </div>
                                    </li>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                            {subCategoryType === 'Medical' && categoryType === 'Company' && (
                              <div>
                                <li>
                                  <div className="left-title">{intl.formatMessage({ id: 'SMM' })}:</div>
                                  <div className="left-value">
                                    {this.state.edit === 'smm' ? (
                                      <div>
                                        <Button
                                          variant={smm === 'Yes' ? 'contained' : 'outlined'}
                                          className={
                                            smm === 'Yes'
                                              ? 'plan-type-select-button selected'
                                              : 'plan-type-select-button'
                                          }
                                          onClick={() => this.setState({ smm: 'Yes' })}
                                        >
                                          {intl.formatMessage({
                                            id: 'Yes'
                                          })}
                                        </Button>
                                        <Button
                                          variant={smm === 'No' ? 'contained' : 'outlined'}
                                          className={
                                            smm === 'No'
                                              ? 'plan-type-select-button selected'
                                              : 'plan-type-select-button'
                                          }
                                          onClick={() => this.setState({ smm: 'No' })}
                                        >
                                          {intl.formatMessage({ id: 'No' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'smm')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, smm, 'smm')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(this, 'smm', smm ? smm : val.smm)}
                                      >
                                        {smm ? (
                                          smm
                                        ) : val.smm ? (
                                          val.smm
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(this, 'smm', smm ? smm : val.smm)}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Hospital Cash'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'hospitalCash' ? (
                                      <div>
                                        <Button
                                          variant={hospitalCash === 'Yes' ? 'contained' : 'outlined'}
                                          className={
                                            hospitalCash === 'Yes'
                                              ? 'plan-type-select-button selected'
                                              : 'plan-type-select-button'
                                          }
                                          onClick={() =>
                                            this.setState({
                                              hospitalCash: 'Yes'
                                            })
                                          }
                                        >
                                          {intl.formatMessage({
                                            id: 'Yes'
                                          })}
                                        </Button>
                                        <Button
                                          variant={hospitalCash === 'No' ? 'contained' : 'outlined'}
                                          className={
                                            hospitalCash === 'No'
                                              ? 'plan-type-select-button selected'
                                              : 'plan-type-select-button'
                                          }
                                          onClick={() =>
                                            this.setState({
                                              hospitalCash: 'No'
                                            })
                                          }
                                        >
                                          {intl.formatMessage({ id: 'No' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'hospitalCash')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, hospitalCash, 'hospitalCash')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'hospitalCash',
                                          hospitalCash ? hospitalCash : val.hospitalCash
                                        )}
                                      >
                                        {hospitalCash ? (
                                          hospitalCash
                                        ) : val.hospitalCash ? (
                                          val.hospitalCash
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'hospitalCash',
                                              hospitalCash ? hospitalCash : val.hospitalCash
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">
                                    {intl.formatMessage({
                                      id: 'Outpatient'
                                    })}
                                    :
                                  </div>
                                  <div className="left-value">
                                    {this.state.edit === 'outPatient' ? (
                                      <div>
                                        <Button
                                          variant={outPatient === 'Yes' ? 'contained' : 'outlined'}
                                          className={
                                            outPatient === 'Yes'
                                              ? 'plan-type-select-button selected'
                                              : 'plan-type-select-button'
                                          }
                                          onClick={() =>
                                            this.setState({
                                              outPatient: 'Yes'
                                            })
                                          }
                                        >
                                          {intl.formatMessage({
                                            id: 'Yes'
                                          })}
                                        </Button>
                                        <Button
                                          variant={outPatient === 'No' ? 'contained' : 'outlined'}
                                          className={
                                            outPatient === 'No'
                                              ? 'plan-type-select-button selected'
                                              : 'plan-type-select-button'
                                          }
                                          onClick={() =>
                                            this.setState({
                                              outPatient: 'No'
                                            })
                                          }
                                        >
                                          {intl.formatMessage({ id: 'No' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'outPatient')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, outPatient, 'outPatient')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'outPatient',
                                          outPatient ? outPatient : val.outPatient
                                        )}
                                      >
                                        {outPatient ? (
                                          outPatient
                                        ) : val.outPatient ? (
                                          val.outPatient
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'outPatient',
                                              outPatient ? outPatient : val.outPatient
                                            )}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="left-title">{intl.formatMessage({ id: 'Dental' })}:</div>
                                  <div className="left-value">
                                    {this.state.edit === 'dental' ? (
                                      <div>
                                        <Button
                                          variant={dental === 'Yes' ? 'contained' : 'outlined'}
                                          className={
                                            dental === 'Yes'
                                              ? 'plan-type-select-button selected'
                                              : 'plan-type-select-button'
                                          }
                                          onClick={() => this.setState({ dental: 'Yes' })}
                                        >
                                          {intl.formatMessage({
                                            id: 'Yes'
                                          })}
                                        </Button>
                                        <Button
                                          variant={dental === 'No' ? 'contained' : 'outlined'}
                                          className={
                                            dental === 'No'
                                              ? 'plan-type-select-button selected'
                                              : 'plan-type-select-button'
                                          }
                                          onClick={() => this.setState({ dental: 'No' })}
                                        >
                                          {intl.formatMessage({ id: 'No' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'dental')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, dental, 'dental')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        className="fs-value"
                                        onClick={this.handleEdit.bind(this, 'dental', dental ? dental : val.dental)}
                                      >
                                        {dental ? (
                                          dental
                                        ) : val.dental ? (
                                          val.dental
                                        ) : (
                                          <span
                                            className="left-null-value"
                                            onClick={this.handleEdit.bind(this, 'dental', dental ? dental : val.dental)}
                                          >
                                            N/A
                                          </span>
                                        )}
                                        <EditIcon className="fs-value-edit-icon" />
                                      </div>
                                    )}
                                  </div>
                                </li>
                              </div>
                            )}
                            {subCategoryType === 'Medical' && categoryType === 'Company' && (
                              <li>
                                <div className="left-title">{intl.formatMessage({ id: 'Deductible' })}:</div>
                                <div className="left-value">
                                  {this.state.edit === 'deductible' ? (
                                    <div>
                                      <input
                                        type="text"
                                        name="deductible"
                                        className="left-value-input"
                                        onChange={this.handleChange.bind(this)}
                                        value={deductible || ''}
                                      />
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="insure-details-edit-button"
                                        onClick={this.cancel.bind(this, 'deductible')}
                                      >
                                        {intl.formatMessage({ id: 'Cancel' })}
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="insure-details-edit-button"
                                        onClick={this.save.bind(this, deductible, 'deductible')}
                                      >
                                        {intl.formatMessage({ id: 'Save' })}
                                      </Button>
                                    </div>
                                  ) : (
                                    <div
                                      className="fs-value"
                                      onClick={this.handleEdit.bind(
                                        this,
                                        'deductible',
                                        deductible ? deductible : val.deductible
                                      )}
                                    >
                                      {deductible && !this.isNullOrEmpty(deductible) ? (
                                        deductible
                                      ) : val.deductible && !this.isNullOrEmpty(val.deductible) ? (
                                        val.deductible
                                      ) : (
                                        <span
                                          className="left-null-value"
                                          onClick={this.handleEdit.bind(
                                            this,
                                            'deductible',
                                            deductible ? deductible : val.deductible
                                          )}
                                        >
                                          N/A
                                        </span>
                                      )}
                                      <EditIcon className="fs-value-edit-icon" />
                                    </div>
                                  )}
                                </div>
                              </li>
                            )}
                            {subCategoryType === 'Medical' && categoryType === 'Company' && (
                              <li>
                                <div className="left-title">
                                  {intl.formatMessage({
                                    id: 'Max. Claim Limit'
                                  })}
                                  :
                                </div>
                                <div className="left-value">
                                  {this.state.edit === 'claimLimit' ? (
                                    <div>
                                      <input
                                        type="text"
                                        name="claimLimit"
                                        className="left-value-input"
                                        onChange={this.handleChange.bind(this)}
                                        value={claimLimit || ''}
                                      />
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="insure-details-edit-button"
                                        onClick={this.cancel.bind(this, 'claimLimit')}
                                      >
                                        {intl.formatMessage({ id: 'Cancel' })}
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="insure-details-edit-button"
                                        onClick={this.save.bind(this, claimLimit, 'claimLimit')}
                                      >
                                        {intl.formatMessage({ id: 'Save' })}
                                      </Button>
                                    </div>
                                  ) : (
                                    <div
                                      className="fs-value"
                                      onClick={this.handleEdit.bind(
                                        this,
                                        'claimLimit',
                                        claimLimit ? claimLimit : val.claimLimit
                                      )}
                                    >
                                      {claimLimit && !this.isNullOrEmpty(claimLimit) ? (
                                        claimLimit
                                      ) : val.claimLimit && !this.isNullOrEmpty(val.claimLimit) ? (
                                        val.claimLimit
                                      ) : (
                                        <span
                                          className="left-null-value"
                                          onClick={this.handleEdit.bind(
                                            this,
                                            'claimLimit',
                                            claimLimit ? claimLimit : val.claimLimit
                                          )}
                                        >
                                          N/A
                                        </span>
                                      )}
                                      <EditIcon className="fs-value-edit-icon" />
                                    </div>
                                  )}
                                </div>
                              </li>
                            )}

                            {val.categoryId !== '59b0eb3814ea7f1aafb6d0b7' ||
                            val.subCategoryId === '5baf2af899d85c2d38c8f00f' ? (
                              <li>
                                <div className="left-title">{intl.formatMessage({ id: 'Beneficiary' })}:</div>
                                <div className="left-value">
                                  {this.state.edit === 'beneficial' ? (
                                    <div>
                                      <Button
                                        variant={beneficialSelected === 'Self' ? 'contained' : 'outlined'}
                                        className={
                                          'option-select-button plan-type-select-button ' +
                                          (beneficialSelected === 'Self' ? 'selected' : '')
                                        }
                                        onClick={() => {
                                          this.setState({
                                            beneficialSelected: 'Self'
                                          });
                                        }}
                                      >
                                        {intl.formatMessage({ id: 'Self' })}
                                      </Button>
                                      <Button
                                        variant={beneficialSelected === 'Not Me' ? 'contained' : 'outlined'}
                                        className={
                                          'option-select-button plan-type-select-button ' +
                                          (beneficialSelected === 'Not Me' ? 'selected' : '')
                                        }
                                        onClick={() => {
                                          this.setState({
                                            beneficialSelected: 'Not Me'
                                          });
                                        }}
                                      >
                                        {intl.formatMessage({
                                          id: 'Not Me'
                                        })}
                                      </Button>
                                      <Button
                                        variant={beneficialSelected === 'No Idea' ? 'contained' : 'outlined'}
                                        className={
                                          'option-select-button plan-type-select-button ' +
                                          (beneficialSelected === 'No Idea' ? 'selected' : '')
                                        }
                                        onClick={() => {
                                          this.setState({
                                            beneficialSelected: 'No Idea'
                                          });
                                        }}
                                      >
                                        {intl.formatMessage({
                                          id: 'No Idea'
                                        })}
                                      </Button>
                                      <div>
                                        <textarea
                                          type="text"
                                          name="beneficial"
                                          className="life-insured-inline-input left-value-input beneficial-text-area"
                                          onChange={this.handleChange.bind(this)}
                                          value={beneficial || ''}
                                          rows="2"
                                        />
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.cancel.bind(this, 'beneficial')}
                                        >
                                          {intl.formatMessage({ id: 'Cancel' })}
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="insure-details-edit-button"
                                          onClick={this.save.bind(this, beneficial, 'beneficial')}
                                        >
                                          {intl.formatMessage({ id: 'Save' })}
                                        </Button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className="fs-value"
                                      onClick={this.handleBeneficialEdit.bind(
                                        this,
                                        'beneficial',
                                        beneficial !== undefined ? beneficial : val.beneficial,
                                        beneficialSelected ? beneficialSelected : val.beneficialSelected
                                      )}
                                    >
                                      {(beneficialSelected !== '' &&
                                        beneficialSelected !== null &&
                                        beneficialSelected !== undefined) ||
                                      (val.beneficialSelected !== undefined &&
                                        val.beneficialSelected !== null &&
                                        val.beneficialSelected !== '') ? (
                                        <div className="life-insured-seleced-button">
                                          {beneficialSelected
                                            ? intl.formatMessage({
                                                id: beneficialSelected
                                              })
                                            : val.beneficialSelected
                                            ? intl.formatMessage({
                                                id: val.beneficialSelected
                                              })
                                            : ''}
                                        </div>
                                      ) : (
                                        ' '
                                      )}
                                      {beneficial !== undefined ? (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: sanitize(beneficial ? beneficial.replace(/\n/gi, '<br/>') : '')
                                          }}
                                        />
                                      ) : val.beneficial && !this.isNullOrEmpty(val.beneficial) ? (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: sanitize(
                                              val.beneficial ? val.beneficial.replace(/\n/gi, '<br/>') : ''
                                            )
                                          }}
                                        />
                                      ) : (
                                        <span
                                          className="left-null-value"
                                          onClick={this.handleBeneficialEdit.bind(
                                            this,
                                            'beneficial',
                                            beneficial !== undefined ? beneficial : val.beneficial,
                                            beneficialSelected ? beneficialSelected : val.beneficialSelected
                                          )}
                                        >
                                          N/A
                                        </span>
                                      )}
                                      <EditIcon className="fs-value-edit-icon" />
                                    </div>
                                  )}
                                </div>
                              </li>
                            ) : (
                              ''
                            )}
                            {(val.categoryId === '59b0eaee14ea7f1aafb6d0b5' ||
                              val.categoryId === '59b0eb3814ea7f1aafb6d0b7') && (
                              <div>
                                {(val.categoryId !== '59b0eb3814ea7f1aafb6d0b7' ||
                                  val.subCategoryId !== '59b0ed98dae1831be084f807') && (
                                  <li>
                                    <div className="left-title">
                                      {intl.formatMessage({
                                        id: 'policy_status_label'
                                      })}
                                      :
                                    </div>
                                    <div className="left-value">
                                      {this.state.edit === 'policyStatus' ? (
                                        <div>
                                          <Button
                                            variant={selectedPolicyStatus === 'In-force' ? 'contained' : 'outlined'}
                                            className={
                                              'option-select-button plan-type-select-button ' +
                                              (selectedPolicyStatus === 'In-force' ? 'selected' : '')
                                            }
                                            onClick={() => {
                                              this.setState({
                                                selectedPolicyStatus:
                                                  selectedPolicyStatus !== 'In-force' ? 'In-force' : ''
                                              });
                                            }}
                                          >
                                            {intl.formatMessage({
                                              id: 'in-force'
                                            })}
                                          </Button>
                                          <Button
                                            variant={selectedPolicyStatus === 'Holiday' ? 'contained' : 'outlined'}
                                            className={
                                              'option-select-button plan-type-select-button ' +
                                              (selectedPolicyStatus === 'Holiday' ? 'selected' : '')
                                            }
                                            onClick={() => {
                                              this.setState({
                                                selectedPolicyStatus:
                                                  selectedPolicyStatus !== 'Holiday' ? 'Holiday' : ''
                                              });
                                            }}
                                          >
                                            {intl.formatMessage({
                                              id: 'holiday'
                                            })}
                                          </Button>
                                          <Button
                                            variant={selectedPolicyStatus === 'Paid-up' ? 'contained' : 'outlined'}
                                            className={
                                              'option-select-button plan-type-select-button ' +
                                              (selectedPolicyStatus === 'Paid-up' ? 'selected' : '')
                                            }
                                            onClick={() => {
                                              this.setState({
                                                selectedPolicyStatus:
                                                  selectedPolicyStatus !== 'Paid-up' ? 'Paid-up' : ''
                                              });
                                            }}
                                          >
                                            {intl.formatMessage({
                                              id: 'paid-up'
                                            })}
                                          </Button>
                                          {/*<Button*/}
                                          {/*  variant={selectedPolicyStatus === 'Lapse' ? 'contained' : 'outlined'}*/}
                                          {/*  className={*/}
                                          {/*    'option-select-button plan-type-select-button ' +*/}
                                          {/*    (selectedPolicyStatus === 'Lapse' ? 'selected' : '')*/}
                                          {/*  }*/}
                                          {/*  onClick={() => {*/}
                                          {/*    this.setState({*/}
                                          {/*      selectedPolicyStatus: selectedPolicyStatus !== 'Lapse' ? 'Lapse' : ''*/}
                                          {/*    });*/}
                                          {/*  }}*/}
                                          {/*>*/}
                                          {/*  {intl.formatMessage({*/}
                                          {/*    id: 'lapse'*/}
                                          {/*  })}*/}
                                          {/*</Button>*/}
                                          {/*<Button*/}
                                          {/*    variant={selectedPolicyStatus === 'Surrender' ? 'contained' : 'outlined'}*/}
                                          {/*    className={*/}
                                          {/*      'option-select-button plan-type-select-button ' +*/}
                                          {/*      (selectedPolicyStatus === 'Surrender' ? 'selected' : '')*/}
                                          {/*    }*/}
                                          {/*    onClick={() => {*/}
                                          {/*      this.setState({*/}
                                          {/*        selectedPolicyStatus: selectedPolicyStatus !== 'Surrender' ? 'Surrender' : ''*/}
                                          {/*      });*/}
                                          {/*    }}*/}
                                          {/*>*/}
                                          {/*  {intl.formatMessage({*/}
                                          {/*    id: 'surrender'*/}
                                          {/*  })}*/}
                                          {/*</Button>*/}
                                          <div>
                                            <input
                                              className="life-insured-inline-input left-value-input"
                                              placeholder={intl.formatMessage({
                                                id: 'policy_status_label'
                                              })}
                                              type="text"
                                              name="policyStatus"
                                              onChange={this.handleChange.bind(this)}
                                              value={policyStatus || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'policyStatus')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(this, policyStatus, 'policyStatus')}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className="fs-value self__btn--grp"
                                          onClick={this.handleSelectedPolicyStatus.bind(
                                            this,
                                            'policyStatus',
                                            policyStatus || policyStatus === '' ? policyStatus : val.policyStatus,
                                            selectedPolicyStatus ? selectedPolicyStatus : val.selectedPolicyStatus
                                          )}
                                        >
                                          {selectedPolicyStatus !== undefined ? (
                                            <div
                                              className={
                                                selectedPolicyStatus !== '' ? 'life-insured-seleced-button' : ''
                                              }
                                            >
                                              {selectedPolicyStatus !== ''
                                                ? intl.formatMessage({
                                                    id: selectedPolicyStatus.toLowerCase()
                                                  })
                                                : ''}
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                val.selectedPolicyStatus !== '' &&
                                                val.selectedPolicyStatus !== undefined
                                                  ? 'life-insured-seleced-button'
                                                  : ''
                                              }
                                            >
                                              {val.selectedPolicyStatus !== '' && val.selectedPolicyStatus !== undefined
                                                ? intl.formatMessage({ id: val.selectedPolicyStatus.toLowerCase() })
                                                : ''}
                                            </div>
                                          )}
                                          {policyStatus || policyStatus === '' ? (
                                            policyStatus
                                          ) : val.policyStatus ? (
                                            val.policyStatus
                                          ) : (
                                            <span
                                              className="left-null-value"
                                              onClick={this.handleSelectedPolicyStatus.bind(
                                                this,
                                                'policyStatus',
                                                policyStatus ? policyStatus : val.policyStatus,
                                                selectedPolicyStatus ? selectedPolicyStatus : val.selectedPolicyStatus
                                              )}
                                            >
                                              N/A
                                            </span>
                                          )}
                                          <EditIcon className="fs-value-edit-icon" />
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                )}
                                {val.subCategoryId !== '5c18ebd120ca05b7e2b32565' &&
                                  (val.categoryId !== '59b0eb3814ea7f1aafb6d0b7' ||
                                    val.subCategoryId !== '59b0ed98dae1831be084f807') && (
                                    <li>
                                      <div className="left-title">
                                        {intl.formatMessage({
                                          id: 'Premium Holiday from'
                                        })}
                                      </div>
                                      <div className="left-value">
                                        {this.state.edit === 'premiumHolidayDate' ? (
                                          <div>
                                            <DatePicker
                                              name="premiumHolidayDate"
                                              //maxDate={moment()}
                                              format="DD-MMM-YYYY"
                                              clearable={true}
                                              className="left-value-input"
                                              value={
                                                this.state.premiumHolidayDate === '' ||
                                                this.state.premiumHolidayDate === undefined ||
                                                this.state.premiumHolidayDate === null
                                                  ? null
                                                  : moment.parseZone(this.state.premiumHolidayDate)
                                              }
                                              openTo="year"
                                              onChange={date =>
                                                this.setState({
                                                  premiumHolidayDate: date
                                                })
                                              }
                                              autoOk={true}
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    <IconButton style={{ padding: '0 5px 0 0', marginLeft: '-12px' }}>
                                                      <TodayIcon color="action" style={{ fontSize: 20 }} />
                                                    </IconButton>
                                                  </InputAdornment>
                                                )
                                              }}
                                              inputProps={{ style: { textAlign: 'center' } }}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={() => this.setState({ edit: '' })}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.handleChangePremiumDate.bind(this)}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <div
                                            className="fs-value"
                                            onClick={() =>
                                              this.setState({
                                                edit: 'premiumHolidayDate',
                                                editValue:
                                                  this.state.premiumHolidayDate !== null &&
                                                  this.state.premiumHolidayDate !== undefined &&
                                                  this.state.premiumHolidayDate !== ''
                                                    ? moment.parseZone(this.state.premiumHolidayDate)
                                                    : null
                                              })
                                            }
                                          >
                                            {this.state.premiumHolidayDate === undefined ||
                                            this.state.premiumHolidayDate === null ||
                                            this.state.premiumHolidayDate === '' ? (
                                              <span
                                                className="left-null-value"
                                                onClick={() =>
                                                  this.setState({
                                                    edit: 'premiumHolidayDate',
                                                    editValue:
                                                      this.state.premiumHolidayDate !== null &&
                                                      this.state.premiumHolidayDate !== undefined &&
                                                      this.state.premiumHolidayDate !== ''
                                                        ? moment.parseZone(this.state.premiumHolidayDate)
                                                        : null
                                                  })
                                                }
                                              >
                                                N/A
                                              </span>
                                            ) : this.state.premiumHolidayDate !== null &&
                                              this.state.premiumHolidayDate !== undefined &&
                                              this.state.premiumHolidayDate !== '' ? (
                                              moment.parseZone(this.state.premiumHolidayDate).format('DD-MMM-YYYY')
                                            ) : null}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </div>
                                        )}
                                      </div>
                                    </li>
                                  )}

                                {isSubCategory(val.subCategoryId, ['saving', 'provident', 'others']) && (
                                  <li>
                                    <div className="left-title">
                                      {intl.formatMessage({
                                        id: 'Total withdrawals'
                                      })}
                                      :
                                    </div>
                                    <div className="left-value">
                                      {this.state.edit === 'totalWithdrawn' ? (
                                        <div>
                                          <NumberFormat
                                            //type="number"
                                            //name="totalWithdrawn"
                                            className="left-value-input"
                                            thousandSeparator={true}
                                            onValueChange={values => {
                                              const { value } = values;
                                              this.setState({
                                                totalWithdrawn: value
                                              });
                                            }}
                                            value={this.state.totalWithdrawn || ''}
                                          />
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="insure-details-edit-button"
                                            onClick={this.cancel.bind(this, 'totalWithdrawn')}
                                          >
                                            {intl.formatMessage({ id: 'Cancel' })}
                                          </Button>
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="insure-details-edit-button"
                                            onClick={this.save.bind(
                                              this,
                                              this.state.totalWithdrawn,
                                              'totalWithdrawn',
                                              this.state.totalWithdrawn ? this.state.totalWithdrawn : val.totalWithdrawn
                                            )}
                                          >
                                            {intl.formatMessage({ id: 'Save' })}
                                          </Button>
                                        </div>
                                      ) : (
                                        <div
                                          className="fs-value"
                                          onClick={this.handleEdit.bind(
                                            this,
                                            'totalWithdrawn',
                                            this.state.totalWithdrawn ? this.state.totalWithdrawn : val.totalWithdrawn
                                          )}
                                        >
                                          {totalWithdrawn ? (
                                            totalWithdrawn
                                          ) : val.totalWithdrawn ? (
                                            val.totalWithdrawn
                                          ) : (
                                            <span
                                              className="left-null-value"
                                              onClick={this.handleEdit.bind(
                                                this,
                                                'totalWithdrawn',
                                                this.state.totalWithdrawn
                                                  ? this.state.totalWithdrawn
                                                  : val.totalWithdrawn
                                              )}
                                            >
                                              N/A
                                            </span>
                                          )}
                                          <EditIcon className="fs-value-edit-icon" />
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                )}

                                {isSubCategory(val.subCategoryId, ['saving', 'provident', 'others']) && (
                                  <Fragment>
                                    <li>
                                      <div className="left-title">
                                        {intl.formatMessage({
                                          id: 'Market Value'
                                        })}
                                        :
                                      </div>
                                      <div className="left-value">
                                        {this.state.edit === 'marketValue' ? (
                                          <div>
                                            <NumberFormat
                                              // type="number"
                                              // name="marketValue"
                                              thousandSeparator={true}
                                              className="left-value-input"
                                              onValueChange={values => {
                                                const { value } = values;
                                                this.setState({
                                                  marketValue: value
                                                });
                                              }}
                                              value={this.state.marketValue || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'marketValue ')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(
                                                this,
                                                this.state.marketValue,
                                                'marketValue',
                                                this.state.marketValue ? this.state.marketValue : val.marketValue
                                              )}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <div
                                            className="fs-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'marketValue',
                                              this.state.marketValue ? this.state.marketValue : val.marketValue
                                            )}
                                          >
                                            {marketValue ? (
                                              marketValue
                                            ) : val.marketValue ? (
                                              val.marketValue
                                            ) : (
                                              <span
                                                className="left-null-value"
                                                onClick={this.handleEdit.bind(
                                                  this,
                                                  'marketValue',
                                                  marketValue ? marketValue : val.marketValue
                                                )}
                                              >
                                                N/A
                                              </span>
                                            )}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </div>
                                        )}
                                      </div>
                                    </li>
                                    <li>
                                      <div className="left-title">
                                        {intl.formatMessage({
                                          id: 'surrender-value'
                                        })}
                                        :
                                      </div>
                                      <div className="left-value">
                                        {this.state.edit === 'surrenderValue' ? (
                                          <div>
                                            <NumberFormat
                                              thousandSeparator={true}
                                              className="left-value-input"
                                              onValueChange={values => {
                                                const { value } = values;
                                                this.setState({
                                                  surrenderValue: value
                                                });
                                              }}
                                              value={this.state.surrenderValue || ''}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.cancel.bind(this, 'surrenderValue ')}
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.save.bind(
                                                this,
                                                this.state.surrenderValue,
                                                'surrenderValue',
                                                this.state.surrenderValue
                                                  ? this.state.surrenderValue
                                                  : val.surrenderValue
                                              )}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <div
                                            className="fs-value"
                                            onClick={this.handleEdit.bind(
                                              this,
                                              'surrenderValue',
                                              this.state.surrenderValue ? this.state.surrenderValue : val.surrenderValue
                                            )}
                                          >
                                            {surrenderValue ? (
                                              surrenderValue
                                            ) : val.surrenderValue ? (
                                              val.surrenderValue
                                            ) : (
                                              <span
                                                className="left-null-value"
                                                onClick={this.handleEdit.bind(
                                                  this,
                                                  'surrenderValue',
                                                  surrenderValue ? surrenderValue : val.surrenderValue
                                                )}
                                              >
                                                N/A
                                              </span>
                                            )}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </div>
                                        )}
                                      </div>
                                    </li>
                                    <li>
                                      <div className="left-title">{`${intl.formatMessage({
                                        id: 'market-value-update-date'
                                      })}:`}</div>
                                      <div className="left-value">
                                        {this.state.edit === 'marketValueUpdateDate' ? (
                                          <div>
                                            <DatePicker
                                              name="marketValueUpdateDate"
                                              format="DD-MMM-YYYY"
                                              clearable={true}
                                              className="left-value-input"
                                              value={
                                                !this.state.marketValueUpdateDate
                                                  ? null
                                                  : moment.parseZone(this.state.marketValueUpdateDate)
                                              }
                                              openTo="date"
                                              maxDate={moment().add(7, 'days')}
                                              // disableFuture={true}
                                              onChange={date => this.setState({ marketValueUpdateDate: date })}
                                              autoOk={true}
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    <IconButton style={{ padding: '0 5px 0 0', marginLeft: '-12px' }}>
                                                      <TodayIcon color="action" style={{ fontSize: 20 }} />
                                                    </IconButton>
                                                  </InputAdornment>
                                                )
                                              }}
                                              inputProps={{ style: { textAlign: 'center' } }}
                                            />
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={() =>
                                                this.setState({
                                                  edit: '',
                                                  marketValueUpdateDate: this.state.editValue
                                                })
                                              }
                                            >
                                              {intl.formatMessage({ id: 'Cancel' })}
                                            </Button>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="insure-details-edit-button"
                                              onClick={this.handleDateChange.bind(this, 'marketValueUpdateDate')}
                                            >
                                              {intl.formatMessage({ id: 'Save' })}
                                            </Button>
                                          </div>
                                        ) : (
                                          <div
                                            className="fs-value"
                                            onClick={() =>
                                              this.setState({
                                                edit: 'marketValueUpdateDate',
                                                editValue: this.state.marketValueUpdateDate
                                                  ? moment.parseZone(this.state.marketValueUpdateDate)
                                                  : null
                                              })
                                            }
                                          >
                                            {!this.state.marketValueUpdateDate ? (
                                              <span
                                                className="left-null-value"
                                                onClick={() =>
                                                  this.setState({
                                                    edit: 'marketValueUpdateDate',
                                                    editValue: this.state.marketValueUpdateDate
                                                      ? moment.parseZone(this.state.marketValueUpdateDate)
                                                      : null
                                                  })
                                                }
                                              >
                                                N/A
                                              </span>
                                            ) : this.state.marketValueUpdateDate ? (
                                              moment.parseZone(this.state.marketValueUpdateDate).format('DD-MMM-YYYY')
                                            ) : null}
                                            <EditIcon className="fs-value-edit-icon" />
                                          </div>
                                        )}
                                      </div>
                                    </li>
                                  </Fragment>
                                )}
                              </div>
                            )}

                            <li>
                              <div className="left-title"> {intl.formatMessage({ id: 'Adviser Name' })}:</div>
                              <div className="left-value">
                                {this.state.edit === 'advisorName' ? (
                                  <div>
                                    <input
                                      type="text"
                                      name="advisorName"
                                      className="left-value-input"
                                      onChange={this.handleChange.bind(this)}
                                      value={advisorName || ''}
                                    />
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.cancel.bind(this, 'advisorName')}
                                    >
                                      {intl.formatMessage({ id: 'Cancel' })}
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.save.bind(this, advisorName, 'advisorName')}
                                    >
                                      {intl.formatMessage({ id: 'Save' })}
                                    </Button>
                                  </div>
                                ) : (
                                  <div
                                    className="fs-value"
                                    onClick={this.handleEdit.bind(
                                      this,
                                      'advisorName',
                                      advisorName ? advisorName : val.advisorName
                                    )}
                                  >
                                    {advisorName && !this.isNullOrEmpty(advisorName) ? (
                                      advisorName
                                    ) : val.advisorName && !this.isNullOrEmpty(val.advisorName) ? (
                                      val.advisorName
                                    ) : (
                                      <span
                                        className="left-null-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'advisorName',
                                          advisorName ? advisorName : val.advisorName
                                        )}
                                      >
                                        N/A
                                      </span>
                                    )}
                                    <EditIcon className="fs-value-edit-icon" />
                                  </div>
                                )}
                              </div>
                            </li>

                            <li>
                              <div className="left-title">{intl.formatMessage({ id: 'Adviser Tel' })}:</div>
                              <div className="left-value">
                                {this.state.edit === 'advisorPhoneNo' ? (
                                  <div>
                                    <Phone
                                      international={true}
                                      // inputComponent={SmartInput}
                                      country="HK"
                                      className="left-value-input"
                                      placeholder={intl.formatMessage({
                                        id: 'Enter phone number'
                                      })}
                                      value={advisorPhoneNo}
                                      onChange={value =>
                                        this.setState({
                                          advisorPhoneNo: value
                                        })
                                      }
                                      //labels={countryList.labels}
                                      countries={countryList.labels}
                                      flagsPath={'img/flags/'}
                                      indicateInvalid
                                    />
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.cancel.bind(this, 'advisorPhoneNo')}
                                    >
                                      {intl.formatMessage({ id: 'Cancel' })}
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.save.bind(this, advisorPhoneNo, 'advisorPhoneNo')}
                                    >
                                      {intl.formatMessage({ id: 'Save' })}
                                    </Button>
                                  </div>
                                ) : (
                                  <div
                                    className="fs-value"
                                    onClick={this.handleEdit.bind(
                                      this,
                                      'advisorPhoneNo',
                                      advisorPhoneNo ? advisorPhoneNo : val.advisorPhoneNo
                                    )}
                                  >
                                    {advisorPhoneNo ? (
                                      advisorPhoneNo
                                    ) : val.advisorPhoneNo ? (
                                      val.advisorPhoneNo
                                    ) : (
                                      <span
                                        className="left-null-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'advisorPhoneNo',
                                          advisorPhoneNo ? advisorPhoneNo : val.advisorPhoneNo
                                        )}
                                      >
                                        N/A
                                      </span>
                                    )}
                                    <EditIcon className="fs-value-edit-icon" />
                                  </div>
                                )}
                              </div>
                            </li>
                            <li>
                              <div className="left-title">{intl.formatMessage({ id: 'Adviser Tel 2' })}:</div>
                              <div className="left-value">
                                {this.state.edit === 'advisorPhoneNo2' ? (
                                  <div>
                                    <Phone
                                      international={true}
                                      // inputComponent={SmartInput}
                                      country="CN"
                                      className="left-value-input"
                                      placeholder={intl.formatMessage({
                                        id: 'Enter phone number'
                                      })}
                                      value={advisorPhoneNo2}
                                      onChange={value =>
                                        this.setState({
                                          advisorPhoneNo2: value
                                        })
                                      }
                                      //labels={countryList.labels}
                                      countries={countryList.labels}
                                      flagsPath={'img/flags/'}
                                      indicateInvalid
                                    />
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.cancel.bind(this, 'advisorPhoneNo2')}
                                    >
                                      {intl.formatMessage({ id: 'Cancel' })}
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.save.bind(this, advisorPhoneNo2, 'advisorPhoneNo2')}
                                    >
                                      {intl.formatMessage({ id: 'Save' })}
                                    </Button>
                                  </div>
                                ) : (
                                  <div
                                    className="fs-value"
                                    onClick={this.handleEdit.bind(
                                      this,
                                      'advisorPhoneNo2',
                                      advisorPhoneNo2 ? advisorPhoneNo2 : val.advisorPhoneNo2
                                    )}
                                  >
                                    {advisorPhoneNo2 ? (
                                      advisorPhoneNo2
                                    ) : val.advisorPhoneNo2 ? (
                                      val.advisorPhoneNo2
                                    ) : (
                                      <span
                                        className="left-null-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'advisorPhoneNo2',
                                          advisorPhoneNo2 ? advisorPhoneNo2 : val.advisorPhoneNo2
                                        )}
                                      >
                                        N/A
                                      </span>
                                    )}
                                    <EditIcon className="fs-value-edit-icon" />
                                  </div>
                                )}
                              </div>
                            </li>
                            <li>
                              <div className="left-title">{intl.formatMessage({ id: 'Adviser Email' })}:</div>
                              <div className="left-value">
                                {this.state.edit === 'advisorEmail' ? (
                                  <div>
                                    <input
                                      type="text"
                                      name="advisorEmail"
                                      className="left-value-input"
                                      onChange={this.handleChange.bind(this)}
                                      value={advisorEmail || ''}
                                    />
                                    <i className="bar" />
                                    {this.state.err.advisorEmail ? (
                                      <div className="error_field">
                                        {intl.formatMessage({
                                          id: this.state.err.advisorEmail
                                        })}
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.cancel.bind(this, 'advisorEmail')}
                                    >
                                      {intl.formatMessage({ id: 'Cancel' })}
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.save.bind(this, advisorEmail, 'advisorEmail')}
                                    >
                                      {intl.formatMessage({ id: 'Save' })}
                                    </Button>
                                  </div>
                                ) : (
                                  <div
                                    className="fs-value"
                                    onClick={this.handleEdit.bind(
                                      this,
                                      'advisorEmail',
                                      advisorEmail ? advisorEmail : val.advisorEmail
                                    )}
                                  >
                                    {advisorEmail ? (
                                      advisorEmail
                                    ) : val.advisorEmail ? (
                                      val.advisorEmail
                                    ) : (
                                      <span
                                        className="left-null-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'advisorEmail',
                                          advisorEmail ? advisorEmail : val.advisorEmail
                                        )}
                                      >
                                        N/A
                                      </span>
                                    )}
                                    <EditIcon className="fs-value-edit-icon" />
                                  </div>
                                )}
                              </div>
                            </li>
                          </div>

                          <li>
                            <div className="left-title">{intl.formatMessage({ id: 'product-link' })}:</div>
                            <div className="left-value">
                              {this.state.edit === 'productLink' ? (
                                <div>
                                  <input
                                    type="text"
                                    name="productLink"
                                    className="left-value-input"
                                    onChange={this.handleChange.bind(this)}
                                    value={productLink || ''}
                                    placeholder="https://"
                                  />
                                  <i className="bar" />
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="insure-details-edit-button"
                                    onClick={this.cancel.bind(this, 'productLink')}
                                  >
                                    {intl.formatMessage({ id: 'Cancel' })}
                                  </Button>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="insure-details-edit-button"
                                    onClick={this.save.bind(this, productLink, 'productLink')}
                                  >
                                    {intl.formatMessage({ id: 'Save' })}
                                  </Button>
                                </div>
                              ) : (
                                <div
                                  className="fs-value"
                                  onClick={event => {
                                    event.stopPropagation();
                                    this.handleEdit(
                                      'productLink',
                                      productLink || productLink === '' ? productLink : val.productLink
                                    );
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                      !(productLink || productLink === ''
                                        ? productLink
                                        : val.productLink
                                        ? val.productLink
                                        : '')
                                    }
                                    onClick={event => {
                                      event.stopPropagation();
                                      window.open(
                                        getUrlWithProtocol(
                                          productLink || productLink === ''
                                            ? productLink
                                            : val.productLink
                                            ? val.productLink
                                            : ''
                                        ),
                                        '_blank'
                                      );
                                    }}
                                    style={{ padding: 0, minWidth: 40 }}
                                  >
                                    {intl.formatMessage({ id: 'Go' })}
                                  </Button>

                                  <EditIcon className="fs-value-edit-icon" />
                                </div>
                              )}
                            </div>
                          </li>

                          <li>
                            <div className="left-title"> {intl.formatMessage({ id: 'Remark' })}:</div>
                            <div className="left-value">
                              {this.state.edit === 'remark' ? (
                                <div>
                                  <textarea
                                    type="text"
                                    name="remark"
                                    className="left-value-input"
                                    onChange={this.handleChange.bind(this)}
                                    value={remark || ''}
                                    rows="3"
                                  />
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="insure-details-edit-button"
                                    onClick={this.cancel.bind(this, 'remark')}
                                  >
                                    {intl.formatMessage({ id: 'Cancel' })}
                                  </Button>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="insure-details-edit-button"
                                    onClick={this.save.bind(this, remark, 'remark')}
                                  >
                                    {intl.formatMessage({ id: 'Save' })}
                                  </Button>
                                </div>
                              ) : (
                                <div
                                  className="fs-value"
                                  onClick={this.handleEdit.bind(this, 'remark', remark ? remark : val.remark)}
                                >
                                  {remark !== undefined ? (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: sanitize(remark ? remark.replace(/\n/gi, '<br/>') : '')
                                      }}
                                    />
                                  ) : val.remark && !this.isNullOrEmpty(val.remark) ? (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: sanitize(val.remark ? val.remark.replace(/\n/gi, '<br/>') : '')
                                      }}
                                    />
                                  ) : (
                                    <span
                                      className="left-null-value"
                                      onClick={this.handleEdit.bind(this, 'remark', remark ? remark : val.remark)}
                                    >
                                      N/A
                                    </span>
                                  )}
                                  <EditIcon className="fs-value-edit-icon" />
                                </div>
                              )}
                            </div>
                          </li>
                          {(categoryType === 'Personal' || val.subCategoryId === '5baf2af899d85c2d38c8f00f') && (
                            <li>
                              <div className="left-title">{intl.formatMessage({ id: 'Orphan Case' })}:</div>
                              <div className="left-value">
                                {this.state.edit === 'orphanCase' ? (
                                  <div>
                                    <Button
                                      variant={orphanCase === 'Yes' ? 'contained' : 'outlined'}
                                      className={
                                        orphanCase === 'Yes'
                                          ? 'option-select-button plan-type-select-button selected'
                                          : 'option-select-button plan-type-select-button'
                                      }
                                      onClick={() => {
                                        delete this.state.err.orphanCase;
                                        this.setState({
                                          orphanCase: orphanCase === 'Yes' ? '' : 'Yes'
                                        });
                                      }}
                                    >
                                      {intl.formatMessage({ id: 'Yes' })}
                                    </Button>

                                    <Button
                                      variant={orphanCase === 'No' ? 'contained' : 'outlined'}
                                      className={
                                        orphanCase === 'No'
                                          ? 'option-select-button plan-type-select-button selected'
                                          : 'option-select-button plan-type-select-button'
                                      }
                                      onClick={() => {
                                        delete this.state.err.orphanCase;
                                        this.setState({
                                          orphanCase: orphanCase === 'No' ? '' : 'No'
                                        });
                                      }}
                                    >
                                      {intl.formatMessage({ id: 'No' })}
                                    </Button>

                                    <Button
                                      variant={orphanCase === 'No Idea' ? 'contained' : 'outlined'}
                                      className={
                                        orphanCase === 'No Idea'
                                          ? 'option-select-button plan-type-select-button selected'
                                          : 'option-select-button plan-type-select-button'
                                      }
                                      onClick={() => {
                                        delete this.state.err.orphanCase;
                                        this.setState({
                                          orphanCase: orphanCase === 'No Idea' ? '' : 'No Idea'
                                        });
                                      }}
                                    >
                                      {intl.formatMessage({ id: 'No Idea' })}
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.cancel.bind(this, 'orphanCase')}
                                    >
                                      {intl.formatMessage({ id: 'Cancel' })}
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="insure-details-edit-button"
                                      onClick={this.save.bind(this, orphanCase, 'orphanCase')}
                                    >
                                      {intl.formatMessage({ id: 'Save' })}
                                    </Button>
                                    {this.state.err.orphanCase ? (
                                      <div className="error_field">
                                        {intl.formatMessage({
                                          id: this.state.err.orphanCase
                                        })}
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    className="fs-value"
                                    onClick={this.handleEdit.bind(
                                      this,
                                      'orphanCase',
                                      orphanCase ? orphanCase : val.orphanCase
                                    )}
                                  >
                                    {orphanCase ? (
                                      intl.formatMessage({ id: orphanCase })
                                    ) : val.orphanCase ? (
                                      intl.formatMessage({ id: val.orphanCase })
                                    ) : (
                                      <span
                                        className="left-null-value"
                                        onClick={this.handleEdit.bind(
                                          this,
                                          'orphanCase',
                                          orphanCase ? orphanCase : val.orphanCase
                                        )}
                                      >
                                        N/A
                                      </span>
                                    )}
                                    <EditIcon className="fs-value-edit-icon" />
                                  </div>
                                )}
                              </div>
                            </li>
                          )}
                          <li>
                            <div className="left-title">{intl.formatMessage({ id: 'draft' })}:</div>
                            <div className="left-value">
                              {this.state.edit === 'isDraft' ? (
                                <div>
                                  <Checkbox
                                    checked={isDraft || false}
                                    onChange={(event, checked) => this.setState({ isDraft: checked })}
                                  />
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="insure-details-edit-button"
                                    onClick={this.cancel.bind(this, 'isDraft')}
                                  >
                                    {intl.formatMessage({ id: 'Cancel' })}
                                  </Button>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="insure-details-edit-button"
                                    onClick={this.save.bind(this, isDraft, 'isDraft')}
                                  >
                                    {intl.formatMessage({ id: 'Save' })}
                                  </Button>
                                </div>
                              ) : (
                                <div
                                  className="fs-value"
                                  onClick={this.handleEdit.bind(
                                    this,
                                    'isDraft',
                                    isDraft !== null ? isDraft : val.isDraft
                                  )}
                                >
                                  {((isDraft !== null && isDraft === true) ||
                                    (isDraft === null && val.isDraft === true)) && (
                                    <img className="not_me_icon" alt="draft" src="img/draft.png" />
                                  )}
                                  {isDraft !== null
                                    ? intl.formatMessage({ id: !isDraft ? 'No' : 'Yes' })
                                    : intl.formatMessage({ id: !val.isDraft ? 'No' : 'Yes' })}
                                  <EditIcon className="fs-value-edit-icon" />
                                </div>
                              )}
                            </div>
                          </li>
                          {!!this.props.dashboardPolicyData.attachments && isShowFileAttachments && (
                            <Grid container className="file-attachments-column-container">
                              <Grid item xs={5} sm={4} md={5}>
                                <div className="column-title">{intl.formatMessage({ id: 'attachments' })}:</div>
                              </Grid>
                              <Grid item xs={12} sm={8} md={7}>
                                {/* REVIEW: The handler functions are inline because this file has too many lines anyway... */}
                                <FilesUploadButton
                                  isLoading={this.state.isFileUploadLoading}
                                  files={this.props.dashboardPolicyData.attachments.map(a => {
                                    return {
                                      name: a.filename,
                                      ...a
                                    };
                                  })}
                                  onFilesSubmitted={async files => {
                                    this.setState({
                                      isFileUploadLoading: true
                                    });
                                    try {
                                      const filesToAttachments = files => {
                                        return files.map(file => {
                                          return {
                                            filename: file.name,
                                            size: file.size,
                                            url: URL.createObjectURL(file)
                                          };
                                        });
                                      };
                                      const attachmentsData = await uploadAttachments({
                                        resourceId: this.props.dashboardPolicyData._id,
                                        data: this.props.dashboardPolicyData,
                                        attachments: filesToAttachments(files),
                                        type: MEDIA_UPLOAD_TYPE.PORTFOLIO
                                      });
                                      const newAttachments = [
                                        ...(this.props.dashboardPolicyData.attachments
                                          ? this.props.dashboardPolicyData.attachments
                                          : []),
                                        ...attachmentsData
                                      ];
                                      this.proceedEditPolicy({
                                        id: this.props.dashboardPolicyData._id,
                                        attachments: newAttachments
                                      });
                                    } catch (error) {
                                      toast.info(IconToast('error', intl.formatMessage({ id: 'upload-files-error' })), {
                                        className: 'new-toast'
                                      });
                                    } finally {
                                      this.setState({
                                        isFileUploadLoading: false
                                      });
                                    }
                                  }}
                                  onFileRemoved={fileIndex => {
                                    const { attachments } = this.props.dashboardPolicyData;
                                    const newAttachments = [
                                      ...attachments.slice(0, fileIndex),
                                      ...attachments.slice(fileIndex + 1)
                                    ];
                                    this.proceedEditPolicy({
                                      id: this.props.dashboardPolicyData._id,
                                      attachments: newAttachments
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </ul>
                      </div>
                      <Dialog
                        open={this.state.modalIsOpen1}
                        onClose={this.closeModal1}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        TransitionComponent={SlideTransition}
                        fullWidth={true}
                      >
                        <DialogTitle id="alert-dialog-slide-title">
                          {intl.formatMessage({ id: 'Use full edit' })}
                        </DialogTitle>
                        <DialogContent>
                          {intl.formatMessage({
                            id: 'click-bottom-right-button-to-change-this-value'
                          })}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={this.closeModal1} color="primary">
                            {intl.formatMessage({ id: 'OK' })}
                          </Button>
                        </DialogActions>
                      </Dialog>
                      {/* Delete Policy Modal */}
                      <Dialog
                        open={this.state.modalIsOpen}
                        onClose={this.closeModal}
                        TransitionComponent={SlideTransition}
                        fullWidth={true}
                      >
                        <DialogTitle>{intl.formatMessage({ id: 'delete-policy-dialog-title' })}</DialogTitle>
                        <DialogContent>
                          {intl.formatMessage({
                            id: 'Confirm-delete-policy'
                          })}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={this.closeModal} color="primary">
                            {intl.formatMessage({ id: 'Cancel' })}
                          </Button>
                          <span onClick={() => history.goBack()}>
                            <Button onClick={this.deletePolicy.bind(this)} color="primary" autoFocus>
                              {intl.formatMessage({ id: 'OK' })}
                            </Button>
                          </span>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default InsuredPolicyView;
