import React, { useRef, useEffect, useCallback } from "react";
import Lottie from "../../../components/Lottie/Lottie";
import { usePrevious } from "../../../utils/hooks";
import { ERROR, INIT, LOADING, SUCCESS } from "../../../constants/phase";

const LoginLockAnim = props => {
  const { status, onCompleteAnimation } = props;

  const previousStatus = usePrevious(status);
  const unlockAnimRef = useRef(null);
  const nextAnimationRef = useRef(null);
  const statusRef = useRef(status);
  const lastSegmentStepRef = useRef(31);
  const animationStackRef = useRef([]);
  const isPlayingRef = useRef(false);

  const playLoading = useCallback(async () => {
    isPlayingRef.current = true;
    if (unlockAnimRef.current) {
      const anim = await unlockAnimRef.current.animationDidLoad();
      anim.setSpeed(1.4);
      anim.playSegments(
        [
          [31, 10],
          [10, 51],
          [51, 31]
        ],
        true
      );
      lastSegmentStepRef.current = 31;
    }
  }, []);

  const playSuccess = useCallback(async () => {
    if (unlockAnimRef.current) {
      isPlayingRef.current = true;
      const anim = await unlockAnimRef.current.animationDidLoad();
      anim.setSpeed(1.25);
      anim.playSegments([31, 141], true);
      lastSegmentStepRef.current = 141;
    }
  }, []);

  const playFailure = useCallback(async () => {
    if (lastSegmentStepRef.current !== 31) {
      isPlayingRef.current = true;
      const anim = await unlockAnimRef.current.animationDidLoad();
      anim.setSpeed(1.4);
      anim.playSegments([lastSegmentStepRef.current, 31], true);
      lastSegmentStepRef.current = 31;
    } else {
      isPlayingRef.current = false;
      animationStackRef.current.shift();
      onCompleteAnimation(ERROR);
    }
  }, [onCompleteAnimation]);

  const playInit = useCallback(async () => {
    if (lastSegmentStepRef.current !== 31) {
      isPlayingRef.current = true;
      const anim = await unlockAnimRef.current.animationDidLoad();
      anim.setSpeed(1.4);
      anim.playSegments([lastSegmentStepRef.current, 31], true);
      lastSegmentStepRef.current = 31;
    } else {
      isPlayingRef.current = false;
      animationStackRef.current.shift();
      onCompleteAnimation(INIT);
    }
  }, [onCompleteAnimation]);

  const playAnimation = useCallback(
    status => {
      animationStackRef.current.push(status);

      if (status === LOADING) {
        playLoading()
          .then()
          .catch(console.log);
      } else if (status === SUCCESS) {
        playSuccess()
          .then()
          .catch(console.log);
      } else if (status === ERROR) {
        playFailure()
          .then()
          .catch(console.log);
      } else if (status === INIT) {
        playInit()
          .then()
          .catch(console.log);
      }
    },
    [playFailure, playInit, playLoading, playSuccess]
  );

  const onComplete = () => {
    const nextStatus = statusRef.current;

    isPlayingRef.current = false;

    const completedAnimationStatus = animationStackRef.current.shift();
    onCompleteAnimation(completedAnimationStatus);

    if (nextStatus === LOADING) {
      playAnimation(LOADING);
    } else {
      if (nextAnimationRef.current) {
        nextAnimationRef.current = false;
        playAnimation(nextStatus);
      }
    }
  };

  useEffect(() => {
    const run = async () => {
      statusRef.current = status;
      nextAnimationRef.current = true;

      if (!isPlayingRef.current) {
        playAnimation(status);
      }
    };

    if (status !== previousStatus) {
      run()
        .then()
        .catch(console.log);
    }
  }, [playAnimation, previousStatus, status]);

  return (
    <Lottie
      ref={unlockAnimRef}
      style={{
        width: "50px",
        height: "50px"
      }}
      options={{
        animationData: "animations/unlock.json",
        loop: false,
        autoplay: false
      }}
      initialFrame={31}
      onComplete={onComplete}
    />
  );
};

export default LoginLockAnim;
