import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import EmergencyExportView from '../../../Share/partials/EmergencyExportView';
import { injectIntl } from 'react-intl';
import { renderHeaderButton } from '../../../../store/headerButtonControl/duck';
import { updateUserDetails } from '../../../../store/user/duck';
import {
  getEmergencyExport,
  updateEmergencyExport,
  updateEmergencyExportInputs
} from '../../../../store/emergencyExport/duck';
import { toggleControl } from '../../../../store/control/duck';

const AdvisorEmergencyExportWrapper = props => {
  const { intl, clientId, emergencyExport, renderHeaderButton, getEmergencyExport } = props;

  const localStorageClientId = window.localStorage.getItem('clientId');
  const finalClientId = clientId ? clientId : localStorageClientId ? JSON.parse(localStorageClientId) : undefined;

  useEffect(() => {
    renderHeaderButton(false);
  }, [renderHeaderButton]);

  useEffect(() => {
    getEmergencyExport(finalClientId);
  }, [finalClientId, getEmergencyExport]);

  return (
    <div>
      {finalClientId !== undefined ? (
        <div>
          <EmergencyExportView {...props} previousPage="/client" emergencyExport={emergencyExport} />
        </div>
      ) : (
        <div>
          <div id="wrapper">
            <div id="page-content-wrapper">
              <div className="container-fluid emergency-view-padding">
                <h3 className="text-center">{intl.formatMessage({ id: 'No Client is Selected!' })}</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    updatePhase: state.user.updatePhase,
    loading: state.emergencyExport.loading,
    emergencyExport: state.emergencyExport.emergencyExport,
    userSubscription: state.userSubscription.userSubscription,
  }),
  {
    renderHeaderButton,
    updateUserDetails,
    updateEmergencyExportInputs,
    getEmergencyExport,
    updateEmergencyExport,
    toggleControl
  }
)(AdvisorEmergencyExportWrapper);

export default injectIntl(container);
