import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import FunctionCardsView from '../../../components/FunctionCard/FunctionCardsView';
import PolicyReviewChooseClient from '../../PolicyReview/PolicyReviewChooseClient/PolicyReviewChooseClient';
import { updateViewingClient } from '../../../store/client/duck';
import ChatGPTIGPostDrawer from '../../../components/ClientEntry/ChatGPTPresetDrawer/ChatGPTIGPostDrawer/ChatGPTIGPostDrawer';
import ChatGPTMatchLazyPacks from '../../../components/ClientEntry/ChatGPTPresetDrawer/ChatGPTMatchLazyPacks/ChatGPTMatchLazyPacks';
import { toggleControl, toggleDialog } from '../../../store/control/duck';
import { goToExport, toggleMeetingNoteDialog } from '../../../components/ClientEntry/utils';
import { tracking } from '../../../utils/tracking';
import ChatGPTFunDrawer from '../../../components/ClientEntry/ChatGPTPresetDrawer/ChatGPTFunDrawer/ChatGPTFunDrawer';
import { ChatGPTRewriteMessageDrawer } from '../../../components/ClientEntry/ChatGPTPresetDrawer/ChatGPTRewriteMessage';
import NativeOrWeb from '../../../utils/native';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';
import ChatGPTPersonalAnalysis from '../../../components/ClientEntry/ChatGPTPresetDrawer/ChatGPTPersonalAnalysis/ChatGPTPersonalAnalysis';

const ChatGPTOptions = props => {
  const { intl, history, viewingClient, updateViewingClient, toggleControl, toggleDialog } = props;

  const onSelectClient = client => {
    updateViewingClient(client);
  };

  const igPost = () => {
    tracking('Click on Social Media Post - ChatGPT');

    toggleControl('chatGPTIGPostDrawer', { client: viewingClient });
  };

  const iceBreaking = () => {
    if (!viewingClient) {
      return;
    }

    tracking('Click on IceBreaking Script - ChatGPT');

    history.push({
      pathname: '/client',
      state: {
        selectedClient: viewingClient,
        gpt: 'ice-breaking'
      }
    });
  };

  const matchLazyPacks = () => {
    if (!viewingClient) {
      return;
    }

    tracking('Click on Match Lazy Packs - ChatGPT');

    console.log(viewingClient);
    toggleControl('chatGPTMatchLazyPacks', { client: viewingClient });
  };

  const personalAnalysis = () => {
    tracking('Click on Personal Analysis - ChatGPT');

    toggleControl('ChatGPTPersonalAnalysis', { client: viewingClient });
  };

  const personalMsg = () => {
    if (!viewingClient) {
      return;
    }

    tracking('Click on Personalized Message - ChatGPT');

    toggleControl('chatGPTPersonalMessageDrawer', { client: viewingClient });
    goToExport(viewingClient, history);
  };

  const voiceNotes = () => {
    if (!viewingClient) {
      return;
    }

    tracking('Click on Voice Notes - ChatGPT');

    toggleMeetingNoteDialog(toggleDialog, viewingClient, { triggerRecordDialog: true });
    history.push({ pathname: '/client', selectedClient: viewingClient, gpt: true });
  };

  const fun = () => {
    tracking('Click on Fun - ChatGPT');

    toggleControl('chatGPTFunDrawer', {});
  };

  const rewriteMessage = () => {
    tracking('Click on RewriteMessage - ChatGPT');
    toggleControl('chatGPTRewriteMessageDrawer', {
      open: true,
      message: '',
      client: viewingClient,
      onValue: value => {
        NativeOrWeb.copyToClipboard(value)
          .then(() => {
            toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), { className: 'new-toast' });
          })
          .catch(console.log);
      }
    });
  };

  // const productRecomment = () => {
  //   tracking('Click on Product Recommendation - ChatGPT');
  //   toggleControl('gptProductRecommendationDrawer', {
  //     open: true,
  //     showSendButton: true,
  //     client: viewingClient
  //   });
  // }

  console.log(' ChatGPTOptions viewingClient: ', viewingClient);

  const needToSelectClient = func => {
    if (!viewingClient) {
      return () => toast.info(IconToast('info', intl.formatMessage({ id: 'please-select-client-first' })));
    }

    return func;
  };

  return (
    <Fragment>
      <ChatGPTPersonalAnalysis />
      <ChatGPTIGPostDrawer />
      <ChatGPTFunDrawer />
      <ChatGPTRewriteMessageDrawer />
      <ChatGPTMatchLazyPacks />
      <FunctionCardsView
        className="chat-gpt-options"
        getItems={({ intl, history }) => [
          {
            text: intl.formatMessage({ id: 'chat-gpt-options-lazy-packs' }),
            subtitle: intl.formatMessage({ id: 'chat-gpt-options-lazy-packs-desc' }),
            imgSrc: 'img/icons/lazypack_good_active_color.svg',
            imgAlt: 'lazy-packs',
            color: '#5fc3d2',
            onClick: personalAnalysis
          },
          {
            text: intl.formatMessage({ id: 'gpt-message-rewite-title' }),
            subtitle: intl.formatMessage({ id: 'gpt-message-rewite-description' }),
            imgSrc: 'img/icons/edit_active_color.svg',
            imgAlt: 'emojis',
            color: '#3999f8',
            onClick: rewriteMessage
          },
          {
            text: intl.formatMessage({ id: 'chat-gpt-options-voice-notes' }),
            subtitle: intl.formatMessage({ id: 'chat-gpt-options-voice-notes-desc' }),
            imgSrc: 'img/icons/voice_star_active_color.svg',
            imgAlt: 'voice-notes',
            color: viewingClient ? '#2260a5' : '#868686',
            onClick: needToSelectClient(voiceNotes)
          },
          {
            text: intl.formatMessage({ id: 'chat-gpt-options-ice-breaking' }),
            subtitle: intl.formatMessage({ id: 'chat-gpt-options-ice-breaking-desc' }),
            imgSrc: 'img/icons/speaker_star_active_color.svg',
            imgAlt: 'ice-breaking',
            color: viewingClient ? '#203398' : '#868686',
            onClick: needToSelectClient(iceBreaking)
          },
          {
            text: intl.formatMessage({ id: 'chat-gpt-options-ig-post' }),
            subtitle: intl.formatMessage({ id: 'chat-gpt-options-ig-post-desc' }),
            imgSrc: 'img/icons/paper_good_active_color.svg',
            imgAlt: 'ig-post',
            color: '#5fc3d2',
            onClick: igPost
          },
          {
            text: intl.formatMessage({ id: 'chat-gpt-options-fun' }),
            subtitle: intl.formatMessage({ id: 'chat-gpt-options-fun-desc' }),
            imgSrc: 'img/icons/smile_edit_active_color.svg',
            imgAlt: 'emojis',
            color: '#3999f8',
            onClick: fun
          },
          {
            text: intl.formatMessage({ id: 'chat-gpt-options-personal-msg' }),
            subtitle: intl.formatMessage({ id: 'chat-gpt-options-personal-msg-desc' }),
            imgSrc: 'img/icons/unread_message_active_color.svg',
            imgAlt: 'personal-msg',
            color: viewingClient ? '#2260a5' : '#868686',
            onClick: needToSelectClient(personalMsg)
          }
          // {
          //   text: intl.formatMessage({ id: 'gpt-options-product-recommendation-title' }),
          //   subtitle: intl.formatMessage({ id: 'gpt-options-product-recommendation-desc' }),
          //   imgSrc: 'img/gpt/social_media.png',
          //   imgAlt: 'ig-post',
          //   color: '#5fc3d2',
          //   onClick: productRecomment,
          //   disabled: !viewingClient
          // },
        ]}
        prepend={
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography style={{ whiteSpace: 'pre-wrap' }}>
                {intl.formatMessage({ id: 'chat-gpt-options-hint' })}
              </Typography>
            </Grid>
            <Grid item>
              <PolicyReviewChooseClient selectedClient={viewingClient || ''} setSelectedClient={onSelectClient} />
            </Grid>
          </Grid>
        }
      />
    </Fragment>
  );
};

const container = connect(
  state => ({
    viewingClient: state.client.viewingClient
  }),
  {
    updateViewingClient,
    toggleControl,
    toggleDialog
  }
)(ChatGPTOptions);

export default injectIntl(container);
