import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { getWebImageUrl } from '../../../utils/image';

const PoweredBy = props => {
  return (
    <Grid
      container
      justify="flex-end"
      alignItems="center"
      wrap={window.innerWidth > 500 ? 'nowrap' : 'wrap'}
      spacing={1}
      className="powered-by"
      {...props}
    >
      <Grid item>
        <Typography style={{ whiteSpace: 'nowrap' }}>Powered by</Typography>
      </Grid>
      <Grid item>
        <img src={getWebImageUrl('img/policy-review/PfplusIcon.png')} alt="powered-by-portfoplus" height="30" />
      </Grid>
      <Grid item>
        <img src={getWebImageUrl('img/policy-review/FA2024_logo.png')} alt="fin-tech-awards-2024" height="30" />
      </Grid>
    </Grid>
  );
};

export default PoweredBy;
