import _ from 'lodash';
import { ADVISER_LOGO_FUNCTIONS } from '../constants/user';
import { UserSubscription } from './user-subscription';

export const getConnectedAdviserDetail = user =>
  _.get(user, 'advisorDetails', []).find(adviserDetail => adviserDetail.connection);
export const isUserConnected = user => _.get(user, 'userType', '') === 'User' && !!getConnectedAdviserDetail(user);
export const shouldShowUserFirstGuideline = user => user && user.userType === 'User' && !user.firstLoginGuidelineDone;
export const shouldShowAdviserFirstGuideline = user =>
  user && user.userType === 'Adviser' && !user.firstLoginGuidelineDone;
export const shouldShowWelcomeDialog = user => user && !!user.welcomeMessage;
export const getUserCurrency = user => _.get(user, 'currency', 'HKD');
export const isUserType = user => _.get(user, 'userType') === 'User';
export const isAdviserType = user => _.get(user, 'userType') === 'Adviser';
export const formatCampaignCode = code => (code || '').trim().toUpperCase();
export const hasQuickClosingCampaignCode = (user, teamCampaignCodes) =>
  !!_.get(user, 'campaignCodes', [])
    .concat(teamCampaignCodes || [])
    .find(campaignCode =>
      ['DE1123', 'WIN1111', 'ODWIN'].find(code => formatCampaignCode(code) === formatCampaignCode(campaignCode.text))
    );
export const hasCampaignCode = (user, teamCampaignCodes, code) =>
  _.get(user, 'campaignCodes', [])
    .concat(teamCampaignCodes || [])
    .find(campaignCode => formatCampaignCode(campaignCode.text) === formatCampaignCode(code));
export const hasReferralCode = (user, code) =>
  _.get(user, 'campaignCodes', []).find(
    campaignCode => campaignCode.isReferralCode && formatCampaignCode(campaignCode.text) === formatCampaignCode(code)
  );
export const shouldEnableChatGPT = ({ user, teamCampaignCodes, userSubscription }) =>
  !!hasCampaignCode(user, teamCampaignCodes, 'FEATURE_GPT') ||
  new UserSubscription(userSubscription).containVaildUltraSubscription();
export const shouldEnableChatGPTTest = ({ user, teamCampaignCodes }) =>
  !!hasCampaignCode(user, teamCampaignCodes, 'FEATURE_GPTTEST');
export const shouldShowFinHome = ({ user, teamCampaignCodes }) =>
  !!hasCampaignCode(user, teamCampaignCodes, 'PARTNER_FINHOME');
export const shouldEnableSpeechToText = ({ user, teamCampaignCodes, userSubscription }) =>
  !!hasCampaignCode(user, teamCampaignCodes, 'FEATURE_GPT') ||
  new UserSubscription(userSubscription).containVaildUltraSubscription();
export const showShowTeamLibrary = ({ user, teamCampaignCodes }) =>
  !!hasCampaignCode(user, teamCampaignCodes, 'FEATURE_TEAMFILE');
export const shouldEnableNFCAutoGen = ({ user, teamCampaignCodes }) =>
  !!hasCampaignCode(user, teamCampaignCodes, 'FEATURE_AUTONFC');
export const shouldEnableTeamChatBot = ({ user, teamCampaignCodes, userSubscription }) =>
  !!hasCampaignCode(user, teamCampaignCodes, 'FEATURE_TEAMCHATBOT') ||
  new UserSubscription(userSubscription).containVaildUltraSubscription();
export const getUserInitial = user => (user?.name ? user.name.charAt(0).toUpperCase() : '');
export const formatPhoneNumber = phoneNumber => (phoneNumber ? phoneNumber.replace(/\+852|\+86|\+886/, '$& ') : '');
export const tagsToTagsString = tags => (tags || []).map(tag => `#${tag}`).join(' ');
export const tagsStringToTags = str =>
  (str || '')
    .trim()
    .split('#')
    .map(tag => tag.trim())
    .filter(tag => !!tag);
export const getUserTimezone = user => {
  const { timeZone } = user || {};
  return timeZone ?? 480;
};

export const getUserLogo = (user, teamLogoUrl, functionName) => {
  const { customLogo, customLogoSettings } = user || {};

  if (!teamLogoUrl && !customLogo) {
    return null;
  }

  if (teamLogoUrl && !customLogo) {
    return teamLogoUrl;
  }

  switch (functionName) {
    case ADVISER_LOGO_FUNCTIONS.LOGIN:
      return teamLogoUrl || null;
    case ADVISER_LOGO_FUNCTIONS.MENU:
      if (customLogo && customLogoSettings?.useInMenu === true) {
        return customLogo;
      }
      return teamLogoUrl || null;
    case ADVISER_LOGO_FUNCTIONS.PDF:
      if (customLogo && customLogoSettings?.useInPDF === true) {
        return customLogo;
      }
      return teamLogoUrl || null;
    case ADVISER_LOGO_FUNCTIONS.LAZY_PACK:
      if (customLogo && customLogoSettings?.useInLazyPack === true) {
        return customLogo;
      }
      return teamLogoUrl || null;
    case ADVISER_LOGO_FUNCTIONS.PROFILE_PLUS:
      if (customLogo && customLogoSettings?.useInProfilePlus === true) {
        return customLogo;
      }
      return teamLogoUrl || null;
    case ADVISER_LOGO_FUNCTIONS.ACCOUNT:
      return customLogo || teamLogoUrl || null;
    default:
      return teamLogoUrl || customLogo || null;
  }
};

export const formatTwoRowsString = str => {
  if (!str) {
    return str;
  }

  if ((str.match(/\n/g) || []).length <= 1) {
    return str;
  }

  let formattedStr = '';
  const splits = str.split('\n').filter(split => !!split.trim());

  for (let i = 0; i < splits.length; i++) {
    if (!splits[i].trim()) {
      continue;
    }

    formattedStr += splits[i];

    if (i < splits.length - 1) {
      if (i === 0) {
        formattedStr += '\n';
      } else {
        formattedStr += ' ';
      }
    }
  }

  return formattedStr;
};
