import { Capacitor } from '@capacitor/core';
import { CapacitorCalendar } from '@ebarooni/capacitor-calendar';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { App } from '@capacitor/app';
import { Preferences } from '@capacitor/preferences';

let listener = null;

async function savePendingCalendarEvent(event) {
    await Preferences.set({
        key: 'pendingCalendarEvent',
        value: JSON.stringify(event),
    });
}

async function getPendingCalendarEvent() {
    const { value } = await Preferences.get({ key: 'pendingCalendarEvent' });
    return value ? JSON.parse(value) : null;
}

async function clearPendingCalendarEvent() {
    await Preferences.remove({ key: 'pendingCalendarEvent' });
}

async function addAppStateListener(callback) {
    async function handleAppStateChange(state) {
        if (state.isActive) {
            if (listener) {
                listener.remove();
                listener = null;
            }

            let permission = await CapacitorCalendar.checkPermission({ alias: 'writeCalendar' });
            if (permission.result === 'granted') {
                await callback();
            }
        }
    }

    if (listener) {
        listener.remove();
        listener = null;
    }
    listener = await App.addListener('appStateChange', handleAppStateChange);
}

async function checkAndRecoverCalendarEvent() {
    const event = await getPendingCalendarEvent();
    if (event) {
        let permission = await CapacitorCalendar.checkPermission({ alias: 'writeCalendar' });
        if (permission.result === 'granted') {
            await CapacitorCalendar.createEventWithPrompt(event);
            await clearPendingCalendarEvent();
        }
    }
}

// async function addEventToCalendar(event) {
//     let defaultCalendar = await CapacitorCalendar.getDefaultCalendar();
//     if (defaultCalendar.result.allowsContentModifications) {
//         return true;
//     } else {
//         let calendars = await CapacitorCalendar.listCalendars();
//         for (let calendar of calendars.result) {
//             if (calendar.allowsContentModifications) {
//                 await CapacitorCalendar.createEvent({ ...event, calendarId: calendar.id });
//                 return true;
//             }
//         }
//         return false;
//     }
// }

async function addEventToCalendarPrompt(event) {
    if (Capacitor.getPlatform() === 'ios') {
        await CapacitorCalendar.requestPermission({ alias: 'writeCalendar' });
    } else {
        await CapacitorCalendar.requestAllPermissions();
    }

    let permission = await CapacitorCalendar.checkPermission({ alias: 'writeCalendar' });
    if (permission.result !== "granted") {
        if (Capacitor.getPlatform() === 'ios') {
            await savePendingCalendarEvent(event);
            await addAppStateListener(async () => {
                await checkAndRecoverCalendarEvent();
            });
        }

        await NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App
        });

        if (Capacitor.getPlatform() === 'android') {
            permission = await CapacitorCalendar.checkPermission({ alias: 'writeCalendar' });
            if (permission.result === 'granted') {
                await CapacitorCalendar.createEventWithPrompt(event);
                return true;
            }
        }
        return false;
    }

    await CapacitorCalendar.createEventWithPrompt(event);
    return true;
}

export { addEventToCalendarPrompt, checkAndRecoverCalendarEvent };
