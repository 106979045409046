import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createUser, handleSignOut, resetUserStore } from '../../store/user/duck';
import BasicDetails from './BasicDetails/BasicDetails';
import LoginBannerContainer from '../../components/LoginBannerContainer/LoginBannerContainer';
import { Grid, useMediaQuery } from '@material-ui/core';
import ExtraDetails from './ExtraDetails/ExtraDetails';
import _ from 'lodash';
import { resetUserSubscription } from '../../store/userSubscription/duck';
import {
  validatePaConnectedEmail,
  validateEmail,
  validateEmailBackend,
  validateName,
  validatePassword,
  validatePasswordConfirm,
  validatePhoneNumber,
  validateTacChecked
} from '../../utils/validators';
import EmailRegisteredDialog from '../NewSignUp/contents/EmailRegisteredDialog/EmailRegisteredDialog';
import { toast } from 'react-toastify';
import IconToast from '../../components/NewToast';
import { LOADING } from '../../constants/phase';
import { formatEmail } from '../../utils/email';
import { usePrevious } from '../../utils/hooks';
import IntlProvideWrapper from '../../components/IntlProvideWrapper/IntlProvideWrapper';
import RegisterSuccess from './RegisterSuccess/RegisterSuccess';

const PersonalAssistantSignUp = props => {
  const {
    intl,
    isAuthenticated,
    createPhase,
    createMessage,
    createMessageExtras,
    createUser,
    handleSignOut,
    resetUserStore,
    resetUserSubscription,
    language,
    setLanguage
  } = props;

  const isMd = useMediaQuery(theme => theme.breakpoints.up('md'));

  const prevCreatePhase = usePrevious(createPhase);

  const [page, setPage] = useState(0);
  const [emailRegisteredDialog, setEmailRegisteredDialog] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [paConnectedEmail, setPaConnectedEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [tacChecked, setTacChecked] = useState(true);

  const [errors, setErrors] = useState({});

  const onChange = event => {
    setErrors({ ..._.omit(errors, [event.target.name]) });

    switch (event.target.name) {
      case 'language':
        setLanguage(event.target.value);
        break;
      case 'name':
        setName(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      case 'paConnectedEmail':
        setPaConnectedEmail(event.target.value);
        break;
      case 'phoneNumber':
        setPhoneNumber(event.target.value);
        break;
      case 'password':
        setPassword(event.target.value);
        break;
      case 'confirmPassword':
        setConfirmPassword(event.target.value);
        break;
      case 'tacChecked':
        setTacChecked(event.target.checked);
        break;
      default:
        break;
    }
  };

  const onNext = async event => {
    event.preventDefault();

    const errs = {};

    const validateNameResult = validateName(name);
    const validateEmailResult = validateEmail(email);

    if (validateNameResult) {
      errs.name = intl.formatMessage({ id: validateNameResult });
    }
    if (validateEmailResult) {
      errs.email = intl.formatMessage({ id: validateEmailResult });
    }

    setErrors(errs);

    if (Object.keys(errs).length === 0) {
      const validateEmailBackendResult = await validateEmailBackend(email);
      if (validateEmailBackendResult) {
        if (validateEmailBackendResult === 'existing-user' || validateEmailBackendResult === 'existing-adviser') {
          setEmailRegisteredDialog(true);
        } else {
          toast.info(IconToast('error', intl.formatMessage({ id: 'invalid-email-address' })), {
            className: 'new-toast'
          });
        }
      } else {
        setPage(1);
      }
    }
  };

  const onSubmit = async event => {
    event.preventDefault();

    const errs = {};

    const paConnectedEmailValidationResult = validatePaConnectedEmail(paConnectedEmail);
    if (paConnectedEmailValidationResult) {
      errs.paConnectedEmail = intl.formatMessage({ id: paConnectedEmailValidationResult });
    }

    const phoneNumberValidationResult = validatePhoneNumber(phoneNumber);
    if (phoneNumberValidationResult) {
      errs.phoneNumber = intl.formatMessage({ id: phoneNumberValidationResult });
    }

    const passwordValidationResult = validatePassword(password);
    if (passwordValidationResult) {
      errs.password = intl.formatMessage({ id: passwordValidationResult });
    }

    const passwordConfirmValidationResult = validatePasswordConfirm(confirmPassword, password);
    if (passwordConfirmValidationResult) {
      errs.confirmPassword = intl.formatMessage({ id: passwordConfirmValidationResult });
    }

    const tacCheckedValidationResult = validateTacChecked(tacChecked);
    if (tacCheckedValidationResult) {
      errs.tacChecked = intl.formatMessage({ id: tacCheckedValidationResult });
    }

    setErrors(errs);

    if (Object.keys(errs).length === 0) {
      const details = {
        userType: 'Adviser',
        isPa: true,
        timeZone: -new Date().getTimezoneOffset(),
        name: name.trim(),
        email: formatEmail(email),
        language: language,
        password: password,
        phoneNumber: phoneNumber,
        paConnectedEmail: paConnectedEmail
      };

      createUser(details);
    }
  };

  const closeEmailRegisteredDialog = () => setEmailRegisteredDialog(false);

  useEffect(() => {
    if (isAuthenticated) {
      handleSignOut();
    }
    resetUserStore();
    resetUserSubscription();
  }, [handleSignOut, isAuthenticated, resetUserStore, resetUserSubscription]);

  useEffect(() => {
    if (prevCreatePhase !== createPhase) {
      if (createPhase === true) {
        setPage(2);
      } else if (createPhase === false) {
        setTimeout(
          () =>
            toast.info(IconToast('error', intl.formatMessage({ id: createMessage }, createMessageExtras)), {
              className: 'new-toast'
            }),
          500
        );
      }
    }
  }, [prevCreatePhase, createPhase, intl, createMessage, createMessageExtras]);

  return (
    <LoginBannerContainer className="nsu" loginBanner={true} ready={true}>
      <EmailRegisteredDialog open={!!emailRegisteredDialog} email={email} onClose={closeEmailRegisteredDialog} />

      <Grid item xs={12}>
        {page === 0 && (
          <BasicDetails
            name={name}
            email={email}
            language={language}
            errors={errors}
            onChange={onChange}
            onNext={onNext}
          />
        )}

        {page === 1 && (
          <ExtraDetails
            paConnectedEmail={paConnectedEmail}
            phoneNumber={phoneNumber}
            password={password}
            confirmPassword={confirmPassword}
            tacChecked={tacChecked}
            errors={errors}
            loading={createPhase === LOADING}
            onChange={onChange}
            onSubmit={onSubmit}
          />
        )}

        {page === 2 && <RegisterSuccess />}
      </Grid>
    </LoginBannerContainer>
  );
};

const container = connect(
  state => ({
    isAuthenticated: state.user.isAuthenticated,
    createPhase: state.user.createPhase,
    createMessage: state.user.createMessage,
    createMessageExtras: state.user.createMessageExtras
  }),
  {
    createUser,
    handleSignOut,
    resetUserStore,
    resetUserSubscription
  }
)(PersonalAssistantSignUp);

const Component = injectIntl(container);

const wrappedComponent = props => {
  return (
    <IntlProvideWrapper defaultLanguage="zh-Hant-HK">
      {({ language, setLanguage }) => <Component language={language} setLanguage={setLanguage} {...props} />}
    </IntlProvideWrapper>
  );
};

export default wrappedComponent;
