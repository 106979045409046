import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Pikto from './Pikto';
import { getSearchObject } from '../../utils/router';
import { withRouter } from 'react-router';
import AdviserInfo from '../../views/Export/AdviserInfo/AdviserInfo';
import { getReferralLink } from '../../utils/referral';
import { Button, Container, Fab, Grid, IconButton, useMediaQuery } from '@material-ui/core';
import _ from 'lodash';
import './PiktoAdviser.css';
import PoweredBy from '../../views/PolicyReview/PoweredBy/PoweredBy';
import { INIT } from '../../constants/phase';
import { getAdvisorMessage } from '../../store/referral/duck';
import { tracking } from '../../utils/tracking';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import NativeOrWeb from '../../utils/native';
import { increaseAdviserProfilePlusLike } from '../../store/adviserProfilePlus/duck';
import { trackEvent } from '../../store/event/api';
import Sticky from 'react-stickynode';
import { IntlProvider } from 'react-intl';
import Lottie from '../Lottie/Lottie';
import { toast } from 'react-toastify';
import IconToast from '../NewToast';
import { getUserLogo } from '../../utils/user';
import { ADVISER_LOGO_FUNCTIONS } from '../../constants/user';
import { translateMessage } from '../../utils/locale';
import { getLocaleMessages } from '../../utils/locale';
import { formatCapacitorUrl } from '../../utils/url';
import { getAdviserSubscriptionNickname } from '../../store/userSubscription/api';
import { trackDirectAnonymous } from '../../store/analytics/api';

const CloseButtonStyle = {
  position: 'fixed',
  top: 'var(--top-padding)',
  right: 0,
  zIndex: 2,
  width: 48,
};

const PiktoAdviserStyle = {
  maxWidth: 800,
  padding: 'var(--top-padding) 0 95px 0',
};

// const WhatsappButtonContainerStyle = {
//   position: 'fixed',
//   bottom: 10,
//   left: '50%',
//   transform: 'translate(-50%, 0)',
//   zIndex: 1,
//   width: '100%',
//   padding: '0 16px',
//   textAlign: 'center',
//   opacity: 0.9,
//   maxWidth: 800
// };

const ShareButtonStyle = {
  // width: 39,
  // height: 39,
  // borderRadius: '50%',
  minWidth: 'unset',
  padding: 4,
  // background: 'var(--my-gradient)'
};

const WhatsappButtonStyle = {
  maxWidth: 768,
  background: 'var(--my-gradient)',
  borderRadius: 16,
};

const LikeButtonStyle = {
  padding: '0px 26px',
  minWidth: 'unset',
  minHeight: 'unset',
  borderRadius: 32,
};

const TeamImageStyle = {
  width: '100%',
  maxWidth: 240,
};

const GridPaddingStyle = {
  padding: '0 16px',
};

const GridContainerStyle = {
  paddingBottom: 50,
};

const PiktoAdviser = (props) => {
  const {
    history,
    location,
    match,
    userDetails,
    advisorMessagePhase,
    adviser,
    teamLogoUrl,
    getAdvisorMessage,
    increaseAdviserProfilePlusLike,
    ...rest
  } = props;

  const [liked, setLiked] = useState(false);
  const [adviserSubscriptionStatus, setAdviserSubscriptionStatus] = useState("loading");
  const [id, setId] = useState('');
  const [aId, setAId] = useState('');
  const [uid, setUid] = useState('');
  const [locale, setLocale] = useState('');

  const showTeamLogo = useMediaQuery('(min-width: 632px)');
  const userLogo = getUserLogo(adviser, teamLogoUrl, ADVISER_LOGO_FUNCTIONS.LAZY_PACK);
  const searchObj = getSearchObject(history);
  const [trackerID, setTrackerID] = useState(require('../../utils/uuid4').uuid4());
  const shareUrl = `${process.env.PFPLUS_URL}${location.pathname}${location.search}`;

  const [keytag, setKeytag] = useState('');

  const onLike = !liked
    ? () => {
      setLiked(true);
      trackEvent('lp', 'Like Lazy Pack', { id: id, adviserId: aId, uid: uid }).then().catch();
    }
    : undefined;

  const onShare = NativeOrWeb.isSharable()
    ? () => {
      const shareData = {
        title: document.title,
        url: shareUrl,
      };
      // if (isMobile && !isIPad13 && !isIOS && tId) {
      //   shareData.imageUrl = `${PFPLUS_STATIC_URL}full-${tId}.png`;
      // }
      NativeOrWeb.share(shareData)
        .then(() => {
          trackEvent('lp', 'Share Lazy Pack', { id: id, adviserId: aId, uid: uid }).then().catch();
        })
        .catch((error) => console.error(error));
    }
    : () => {
      NativeOrWeb.copyToClipboard(shareUrl).then(() => {
        trackEvent('lp', 'Share Lazy Pack', { id: id, adviserId: aId, uid: uid }).then().catch();

        toast.info(IconToast('info', translateMessage('app-msg-copied', locale)), {
          className: 'new-toast',
        });
      });
    };

  const whatsapp = () => {
    tracking('Click on Hot Topic Content - Whatsapp');

    const adviserName = _.get(adviser, 'name', '');
    const lpLink = formatCapacitorUrl(window.location.href);
    const link = getReferralLink({
      targetAdviser: adviser,
      client: undefined,
      isQRCode: false,
      userDetails: userDetails,
      source: 'lazy-pack',
      extraSearch: {
        defaultMessage: translateMessage(
          'pikto-adviser-default-msg',
          locale,
          { adviserName: adviserName, lpLink: lpLink }
        )
      },
    });

    window.open(link, '_blank');
  };

  const close = () => history.goBack();

  useEffect(() => {
    if (history.location.search) {
      console.log("PiktoAdviser searchObj update::: ", getSearchObject({ location: history.location }))
      let searchObj = getSearchObject({ location: history.location });
      setId(searchObj.id);
      setAId(searchObj.aId);
      setUid(searchObj.uid);
      setKeytag(`/lp/${searchObj.id}/${searchObj.aId}`)
      let tempLocale = searchObj.l;
      if (tempLocale !== "en" && tempLocale !== "zh-Hant-HK" && tempLocale !== "zh") {
        tempLocale = "zh-Hant-HK";
      }
      setLocale(tempLocale);
    }
  }, [history.location.search])

  useEffect(() => {
    const fetchSubscriptionNickname = async () => {
      if (aId) {
        try {
          let result = await getAdviserSubscriptionNickname(aId);
          if (result.subscriptionNickname) {
            setAdviserSubscriptionStatus("active");
          } else if (result.message === "Failed to fetch") {
            setAdviserSubscriptionStatus("failed_to_fetch");
          } else {
            setAdviserSubscriptionStatus("expired");
          }
        } catch (err) {
          setAdviserSubscriptionStatus("error");
        }
      }
    };

    fetchSubscriptionNickname();
  }, [aId]);

  useEffect(() => {
    if ((advisorMessagePhase === INIT && aId) || (aId && adviser._id !== aId)) {
      // console.log("PiktoAdviser advisorMessagePhase data before init::: ", adviser._id, " aId::: ", aId)
      // console.log("PiktoAdviser advisorMessagePhase adviserSubscriptionStatus::: ", adviserSubscriptionStatus)
      if (adviserSubscriptionStatus !== "expired" && adviserSubscriptionStatus !== "loading") {
        getAdvisorMessage(aId);
        // console.log("PiktoAdviser advisorMessagePhase data after init::: ", adviser._id, " aId::: ", aId)
      }
    }
  }, [advisorMessagePhase, getAdvisorMessage, adviserSubscriptionStatus, aId]);

  useEffect(() => {
    // Tracking viewership
    try {
      const referring_domain = document.referrer?.split('/')
      const url = window.location.href.split('?')

      const trackAmplitudePageViewed = require('../../utils/tracking/index').trackAmplitudePageViewed;
      trackAmplitudePageViewed({
        domain: window.location.hostname,
        location: window.location.href,
        path: window.location.pathname,
        title: document.title,
        url: url && url.length >= 1 ? url[0] : undefined,
        referrer: document.referrer,
        referring_domain: referring_domain && referring_domain.length >= 2 ? referring_domain[2] : undefined,
      }, {
        "Language": searchObj.l,
        "Content Id": searchObj.id,
        "Adviser Id": searchObj.aId,
        "Uid": searchObj.uid
      })
    } catch (error) {
      console.log('trackAmplitudePageViewed error : ', error)
    }
  }, [])

  useEffect(() => {
    // Tracking viewership, page viewed in 5S
    const trackViewership = require('../../utils/tracking/index').trackViewership;
    trackViewership(trackerID, {
      "Page Path": "/lp",
      "Element ID": trackerID,
      "Page URL": window.location.href,
      "Interval": 5000,
      "Language": searchObj.l,
      "Lazy Pack ID": searchObj.id,
      "Adviser Id": searchObj.aId,
      "Uid": searchObj.uid
    })

    let timer = null;
    if (!userDetails?._id) {
      timer = setTimeout(() => {
        trackDirectAnonymous(searchObj.aId, 'clientViewHotTopic', searchObj.id.replace('featured-', ''));
      }, 5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [])


  useEffect(() => {
    increaseAdviserProfilePlusLike(aId);
  }, [increaseAdviserProfilePlusLike, aId]);

  let stickyTop;
  try {
    stickyTop = parseInt(
      (getComputedStyle(document.documentElement).getPropertyValue('--top-padding') || '').replace('px', ''),
    );
  } catch (error) {
    stickyTop = 0;
  }


  return (
    <div id={trackerID} key={keytag}>
      {/* {console.log(id, aId, uid)} */}
      {adviserSubscriptionStatus === "expired" &&
        <div style={{ justifyContent: "center", textAlign: "center" }}>
          <h2>
            {translateMessage('lazy-pack-adviser-subscription-expired', locale)}
            <a
              className='login-link'
              target="_blank"
              href="https://api.whatsapp.com/send?phone=85266370320&text=%28Exp%20LP%29%20%E6%88%91%E7%9C%8B%E5%88%B0%E6%87%B6%E4%BA%BA%E5%8C%85%E9%80%A3%E7%B5%90%E5%A4%B1%E6%95%88"
            >
              WhatsApp PortfoPlus
            </a>
          </h2>

          <br />

          <h2>
            <a
              className="login-link"
              target="_blank"
              href="https://www.portfoplus.com"
            >
              {translateMessage('learn-more', locale)}
            </a>
          </h2>
        </div>
      }
      {adviserSubscriptionStatus !== "expired" && adviserSubscriptionStatus !== "loading" &&
        <Container className="pikto-adviser" style={PiktoAdviserStyle}>
          {history.length > 1 && (
            <IconButton style={CloseButtonStyle} onClick={close}>
              <CloseRoundedIcon />
            </IconButton>
          )}

          <Grid container direction="column" alignItems="center" justify="center" style={GridContainerStyle}>
            {adviser && adviser._id && (
              <Fragment>
                <Grid item>
                  <Grid container justify="center" alignItems="center" wrap="nowrap">
                    <Grid item style={{ flexGrow: 1 }}>
                      <IntlProvider
                        key={locale}
                        locale={locale}
                        defaultLocale={locale}
                        messages={getLocaleMessages(locale).messages}
                      >
                        <AdviserInfo
                          show={true}
                          showQRCode={false}
                          hideAvatar={false}
                          adviser={adviser}
                          referLinkParams={{ source: 'lazy-pack' }}
                          direction="row"
                          MyDetailsProfilePictureProps={{
                            width: 110,
                            style: { border: 0, borderRadius: '50%' },
                          }}
                        />
                      </IntlProvider>
                    </Grid>
                    {showTeamLogo && (
                      <Grid item style={{ margin: '0 8px', flexGrow: undefined, textAlign: 'right' }}>
                        {userLogo ? (
                          <img src={userLogo} alt="logo" style={TeamImageStyle} />
                        ) : (
                          <img src="img/Namecard-PortfoAd-updated.png" alt="portfoplus" style={TeamImageStyle} />
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Fragment>
            )}

            <Sticky enabled={true} innerZ={3} top={stickyTop}>
              <Grid item style={{ padding: '0 8px 8px 8px', background: '#fff' }}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item style={{ flexGrow: 1 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth={true}
                      onClick={whatsapp}
                      style={WhatsappButtonStyle}
                    >
                      {translateMessage('wtsapp-me-know-more', locale)}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={
                        <Lottie
                          style={{
                            width: 28,
                            height: 28,
                            marginRight: -6,
                          }}
                          options={{
                            animationData: 'animations/4448-heart.json',
                            loop: false,
                            autoplay: false,
                          }}
                          isPlaying={liked}
                        />
                      }
                      onClick={onLike}
                      style={LikeButtonStyle}
                    >
                      {translateMessage('like-3', locale)}
                    </Button>
                  </Grid>
                  <Grid item>
                    <IconButton variant="contained" color="primary" style={ShareButtonStyle} onClick={onShare}>
                      <ShareOutlinedIcon style={{ fontSize: 26 }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Sticky>

            {adviserSubscriptionStatus !== "expired" && adviserSubscriptionStatus !== "loading" &&
              <Grid item>
                <Pikto uid={uid} {...rest} />
              </Grid>
            }

            <Grid item style={GridPaddingStyle}>
              <PoweredBy />
            </Grid>
          </Grid>
        </Container>
      }
    </div>
  );
};

const container = connect(
  (state) => ({
    userDetails: state.user.userDetails,
    advisorMessagePhase: state.referral.advisorMessagePhase,
    adviser: state.referral.advisorMessage, // reuse existing getAdvisorMessage API's data,
    teamLogoUrl: _.get(state, 'referral.teamLogoUrl.teamLogoUrl'), // reuse existing getAdvisorMessage API's data,
  }),
  {
    getAdvisorMessage,
    increaseAdviserProfilePlusLike,
  },
)(PiktoAdviser);

export default withRouter(container);
