import React, { PureComponent } from 'react';
import Slider from 'react-slick';
import './styles.scss';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getRegion } from '../../utils/region';
import _ from 'lodash';
import { getProFlagFromLocalStorage, ShortSubscriptions } from '../../utils/user-subscription';
import { DialogContentText, Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../components/Transition/Transition';
import AnimatedSvg from '../../components/AnimatedSvg/AnimatedSvg';
import Preferences from './partials/Preferences/Preferences';
import { isUserConnected } from '../../utils/user';
import { getDefaultRoute } from '../../utils/router';

class WelcomeView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      checked: false,
      err: {},
      doubleChecked: false,
      doubleCheckedManage: false,
      CheckboxClass: 'double-check-label',
      slideIndex: 0,
      prefData: {}
    };
  }

  UNSAFE_componentWillMount(props) {
    // fetch user details api
    this.props.getUser();
    const isLinkerUser = new ShortSubscriptions(this.props.userDetails.subscriptions).containLinkerSubscription();
    if (isLinkerUser) {
      // directly start
      this.startNow();
    }
  }

  //if client made by adviser login then asking him to change the password for first time
  forChangePassword() {
    const err = {};
    const { intl, userDetails } = this.props;
    if (this.state.doubleChecked === false) {
      err.doubleChecked = intl.formatMessage({
        id: 'Please read and accept beforehand'
      });
    }
    if (this.state.doubleCheckedManage === false) {
      err.doubleCheckedManage = intl.formatMessage({
        id: 'Please read and accept beforehand'
      });
    }
    this.setState({ err });
    if (!Object.keys(err).length) {
      this.props.getUser();
      // if (
      //   location &&
      //   location.state &&
      //   location.state.userPasswordChange &&
      //   location.state.userPasswordChange.passwordChangeKey
      // ) {
      //   userPasswordChange.passwordChangeKey =
      //     location.state.userPasswordChange.passwordChangeKey;
      // } else {
      // userPasswordChange.passwordChangeKey = _.get(
      //   userDetails,
      //   'passwordChangeKey'
      // );
      //}
      //userPasswordChange.path = true;
      let userLoginDetail = {};
      userLoginDetail.state = true;
      userLoginDetail.hideWelcome = this.state.checked;
      userLoginDetail.passwordChangeKey = _.get(userDetails, 'passwordChangeKey');
      this.props.updateUserDetails(this.state.prefData);
      this.props.history.push({
        pathname: '/change_password',
        state: { userLoginDetail }
      });
    }
  }

  startNow = preferences => {
    var user = this.props.userDetails;
    const { userDetails, location } = this.props;
    let data = {};
    data.hideWelcome = this.state.checked;
    if (preferences) {
      data = { ...data, ...preferences };
      data.preferencesDone = true;
    }
    let routePath = getDefaultRoute(userDetails);
    if (this.props.getUserPhase === true && this.props.userDetails.passwordChangeKey) {
      data.hideWelcome = false;
      this.setState({ modalIsOpen: true, prefData: data });
    } else {
      // sending the welcome message , remind target & reminderTarget(to show the popup of reminder) on dashboard so that they display only once when user is login
      // logic to display the welcome message , reminder target and reminder popup
      var welcomeMessage = user && user.welcomeMessage ? user.welcomeMessage : false;
      var birthdayPopUp = user.userType === 'Adviser' ? true : false;
      var showAdviserBlast = userDetails.userType === 'User' ? true : false;
      const showTeamBlast = userDetails.userType === 'Adviser' ? true : false;
      this.props.history.push({
        pathname: routePath,
        state: {
          welcomeMessage: welcomeMessage,
          birthdayPopUp: birthdayPopUp,
          showAdviserBlast: showAdviserBlast,
          showTeamBlast,
          noBackButton: true,
          loginRedirect: true
        }
      });
      this.props.updateUserDetails(data);
    }
  };

  // when user login for next time the welcome page will skip
  welcomePageHide() {
    this.setState({ checked: !this.state.checked });
  }

  //To logout by popup logout option
  handleLogout() {
    this.props.handleSignOut();
  }

  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: index => this.setState({ slideIndex: index })
    };
    const { intl, userDetails } = this.props;
    const { slideIndex } = this.state;

    const welcomeSvgUrls = [
      'animated-svg/client-01-consolidation.html',
      'animated-svg/adviser-02-analysis.html',
      'animated-svg/client-03-coverage.html',
      'animated-svg/adviser-04-target.html'
    ];

    if (userDetails && userDetails.userType === 'Adviser') {
      welcomeSvgUrls[0] = 'animated-svg/adviser-01-search.html';
      welcomeSvgUrls[2] = 'animated-svg/adviser-03-trust.html';
    }

    let fakeButtonsDiv = (
      <div className="welcome-buttons">
        <div className="welcome-left-fake-button" onClick={() => this.slider.slickPrev()} />
        <div className="welcome-right-fake-button" onClick={() => this.slider.slickNext()} />
      </div>
    );

    const welcomeLocalePrefix = userDetails && userDetails.userType === 'Adviser' ? 'adv' : 'user';
    //for existing connected user, do not show new welcome dialog
    const preferencesDone =
      userDetails &&
      ((userDetails.preferencesDone === undefined && isUserConnected(userDetails)) ||
        userDetails.preferencesDone ||
        userDetails.userType === 'Adviser');

    const isLinkerUser = new ShortSubscriptions(userDetails.subscriptions).containLinkerSubscription();
    return (
      <div>
        {(preferencesDone || !userDetails) && !isLinkerUser && (
          <div id="welcome-page">
            <div className="welcome-slider">
              <Slider {...settings} ref={slider => (this.slider = slider)}>
                {welcomeSvgUrls.map((svgUrl, index) => (
                  <div className="page-containt sliderImg" key={index}>
                    {fakeButtonsDiv}
                    <AnimatedSvg url={svgUrl} animated={true} />
                    <div className="welcome-msg-wrapper">
                      <div className="title">
                        <div>
                          {intl.formatMessage({
                            id: `login-${welcomeLocalePrefix}-title-${index + 1}`
                          })}
                        </div>
                      </div>
                      <div className="hint">
                        <div>
                          {intl.formatMessage({
                            id: `login-${welcomeLocalePrefix}-description-${index + 1}`
                          })}
                        </div>
                      </div>
                      <div className="action">
                        <div>
                          {intl.formatMessage({
                            id: `login-${welcomeLocalePrefix}-fakebutton-${index + 1}`
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>

              <div className="btn-welcome">
                <div style={{ width: '100%', textAlign: 'right' }}>
                  <FormControlLabel
                    color="primary"
                    className="wel-check-box"
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.checked}
                        onClick={this.welcomePageHide.bind(this)}
                      />
                    }
                    label={intl.formatMessage({ id: 'dun-show-again' })}
                    style={{ float: 'unset' }}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <Button
                    type="submit"
                    className={'welcomeButton gradient-action-button'}
                    variant="contained"
                    color="primary"
                    onClick={() => this.startNow()}
                  >
                    {intl.formatMessage({ id: 'Start Now' })}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {userDetails && !preferencesDone && (
          <Preferences
            onClose={this.startNow}
            userDetails={userDetails}
            setShownDashHelp={this.props.setShownDashHelp}
            updateFirstGuidelineState={this.props.updateFirstGuidelineState}
          />
        )}
        <Dialog
          open={this.state.modalIsOpen}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Welcome' })}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {intl.formatMessage({
                id: 'Welcome! You are now connected with your adviser. Please change the password now.'
              })}
            </DialogContentText>
            <div className="welcome-doublecheck">
              <div className="check_box">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="doubleChecked"
                      value="doubleChecked"
                      checked={this.state.doubleChecked}
                      onChange={() => {
                        this.setState({
                          doubleChecked: !this.state.doubleChecked
                        });
                        delete this.state.err.doubleChecked;
                      }}
                      color="primary"
                    />
                  }
                  className={this.state.CheckboxClass}
                />
              </div>
              {/*<div className="welcome-sms-box text-center">*/}
              <div className="welcome-sms-box">
                {intl.formatMessage({
                  id: 'I agree with PortfoPlus'
                })}{' '}
                <a
                  rel="noopener noreferrer"
                  className="login-link"
                  href="https://www.portfoplus.com/terms.html"
                  target="_blank"
                >
                  {intl.formatMessage({ id: 'Terms of Service' })}
                </a>{' '}
                {intl.formatMessage({ id: 'and' })}{' '}
                <a
                  rel="noopener noreferrer"
                  className="login-link"
                  href="https://www.portfoplus.com/privacy.html"
                  target="_blank"
                >
                  {intl.formatMessage({ id: 'Privacy Policy' })}
                </a>
                {this.state.err.doubleChecked ? <div className="error_field">{this.state.err.doubleChecked}</div> : ''}
              </div>
            </div>
            <div className="welcome-doublecheck">
              <div className="check_box">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="doubleCheckedManage"
                      value="doubleCheckedManage"
                      checked={this.state.doubleCheckedManage}
                      onChange={() => {
                        this.setState({
                          doubleCheckedManage: !this.state.doubleCheckedManage
                        });
                        delete this.state.err.doubleCheckedManage;
                      }}
                      color="primary"
                    />
                  }
                  className={this.state.CheckboxClass}
                />
              </div>
              <div>
                {intl.formatMessage({
                  id: 'understand_message_for_user'
                })}{' '}
                {this.state.err.doubleCheckedManage ? (
                  <div className="error_field">{this.state.err.doubleCheckedManage}</div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleLogout.bind(this)} color="primary">
              {intl.formatMessage({ id: 'Logout' })}
            </Button>
            <Button onClick={this.forChangePassword.bind(this)} color="primary" autoFocus>
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default WelcomeView;
