import { isNavAside } from '../../../utils/device';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { messageListener } from './utils/messageListener';
import { buildSearchString, getSearchObject } from '../../../utils/router';
import { getReferralProfile } from './utils/referralProfile';
import SubscriptionDialog from '../../../views/Subscription/SubscriptionDialog/SubscriptionDialog';
import { injectIntl } from 'react-intl';
import { UserSubscription } from '../../../utils/user-subscription';
import { Capacitor } from '@capacitor/core';
import LinkerLeavePageConfirm from './Components/LeavePageConfirm';
import { getUser } from '../../../store/user/duck';
import { usePrevious } from '../../../utils/hooks';

const EditProfile = props => {
  const {
    userSubscription,
    stripePromise,
    showPadding,
    history,
    userDetails,
    getUser,
    getUserPhase,
    getUserPhaseSuccess,
    intl
  } = props;

  const currentCoreSubscription = new UserSubscription(userSubscription).getCurrentCoreSubscription();

  const linkerUrl = process.env.LINKER_URL;
  const isSideNav = isNavAside();
  const search = buildSearchString([getSearchObject(history.location.search), getReferralProfile({})]);

  // useEffect(() => handleGetUserPhaseUpdated, [getUserPhase]);

  const [shouldShowLeavePageConfirm, setShouldShowLeavePageConfirm] = React.useState(true);

  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = React.useState(false);

  const handleSubscriptionOpen = () => {
    setSubscriptionDialogOpen(true);
  };
  const handleSubscriptionClose = () => {
    setSubscriptionDialogOpen(false);
  };

  // const handleGetUserPhaseUpdated = () => {
  //   console.log("getUserPhaseSuccess", getUserPhaseSuccess);
  //   history.push("/linker/adviser/" + userDetails._id);
  // };

  const handleProfileUpdated = () => {
    setShouldShowLeavePageConfirm(false);
    getUser();

    history.push('/linker/adviser/' + userDetails._id);
  };

  useEffect(() => {
    return messageListener(
      history,
      undefined,
      {
        PROFILE_UPDATED: () => handleProfileUpdated(),
        SHOW_UPGRADE_MODAL: () => handleSubscriptionOpen()
      },
      userDetails
    );
  }, []);

  const isNativeApp = Capacitor.isNativePlatform();
  return (
    <div
      style={{
        margin: isNativeApp ? '0 0 0 48px 0' : '0',
        overflow: 'hidden',
        height: 'calc(100% - 45px)',
        width: isSideNav ? 'calc(100% - 60px)' : '100%',
        position: 'absolute',
        top: 45,
        paddingRight: showPadding ? '48px' : '0'
      }}
    >
      <LinkerLeavePageConfirm intl={intl} shouldShow={shouldShowLeavePageConfirm} />
      <SubscriptionDialog
        intl={intl}
        userDetails={userDetails}
        stripePromise={stripePromise}
        open={subscriptionDialogOpen}
        onClose={handleSubscriptionClose}
        type="upgrade"
        currentSubscription={currentCoreSubscription}
        trialing={false}
        disableCoupon={true}
        defaultCoupon=""
      />
      <iframe
        allow="clipboard-read; clipboard-write"
        src={`${linkerUrl}/frame/my-details?${search}`}
        frameBorder="0"
        height="100%"
        width="100%"
      />
    </div>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    userSubscription: state.userSubscription.userSubscription,
    getUserPhase: state.user.getUserPhase,
    getUserPhaseSuccess: state.user.getUserPhaseSuccess
  }),
  {
    getUser
  }
)(EditProfile);

export default injectIntl(container);
