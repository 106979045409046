//the data to be updated for advisor
export const getNewUsedTag = (redux_data, old_data_input, new_data_input) => {
  let redux = redux_data ? [...redux_data] : [];
  let old_data = old_data_input ? [...old_data_input] : [];
  let new_data = new_data_input ? [...new_data_input] : [];

  var addData = new_data.filter(new_tmp => {
    return (
      old_data.find(old_tmp => {
        return new_tmp.text === old_tmp.text;
      }) === undefined
    );
  });
  var minusData = old_data.filter(old_tmp => {
    return (
      new_data.find(new_tmp => {
        return new_tmp.text === old_tmp.text;
      }) === undefined
    );
  });

  //Addtion
  addData.forEach(data => {
    //If does not exist, add it
    if (
      redux.find(tmp => {
        return tmp.text === data.text;
      }) === undefined
    ) {
      redux.push({
        text: data.text,
        color: data.color,
        numberOfUses: 1
      });
    } else {
      //If already exist
      redux = redux.map(data_2 => {
        data_2.numberOfUses = data_2.text === data.text ? data_2.numberOfUses + 1 : data_2.numberOfUses;
        return data_2;
      });
    }
  });

  //minus data
  minusData.forEach(data => {
    //If the number of used is 1, delete it

    if (
      redux.filter(tmp => {
        return tmp.text === data.text;
      })[0].numberOfUses === 1
    ) {
      redux = redux.filter(data_2 => {
        return data_2.text !== data.text;
      });
    } else {
      //If not delete it, deduct numberOfUses by 1
      redux = redux.map(data_2 => {
        data_2.numberOfUses = data_2.text === data.text ? data_2.numberOfUses - 1 : data_2.numberOfUses;
        return data_2;
      });
    }
  });
  return redux;
};

export const getAvailableTag = (validCoreSubscription, isTeamMember) => {
  var availableTag = 0;
  if (validCoreSubscription) {
    if (validCoreSubscription.nickname === 'Ultra') {
      availableTag = 100;
    } else if (validCoreSubscription.nickname === 'Pro') {
      availableTag = 50;
    } else {
      availableTag = 5;
    }
  } else {
    availableTag = 0;
  }

  return availableTag;
};

export const isExceedLimit = (savingTag, usedTag, availableTag, inputTag) => {
  usedTag = usedTag.map(value => {
    return {
      color: value.color,
      text: value.text
    };
  });

  if (savingTag !== undefined && savingTag !== null) {
    usedTag = usedTag.concat(savingTag);
  }

  usedTag = usedTag.reduce((acc, current) => {
    const x = acc.find(item => item.text === current.text);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  var isExist =
    usedTag === null || usedTag === undefined
      ? false
      : usedTag.find(value => {
        return value.text === inputTag.text;
      }) === undefined
        ? false
        : true;

  if (availableTag > usedTag.length || isExist === true) {
    return true;
  } else {
    return false;
  }
};

const tagColor = [
  '#ff24ff',
  '#ff2492',
  '#a448ff',
  '#4848ff',
  '#2492ff',
  '#00bedc',
  '#969612',
  '#00e772',
  '#e77200',
  '#ff2424',
  '#990099',
  '#003399',
  '#ff0000',
  '#009933',
  '#006699',
  '#cc0066',
  '#00cc00',
  '#cc0099'
];

export const generateTagColor = () => {
  let i = Math.round(Math.random() * (tagColor.length - 1));
  return tagColor[i] + 'FF';
};

export const getUsedTagColor = (usedTag, tag) => {
  var searchResult = usedTag.find(tmp => {
    return tmp.text === tag.text;
  });
  return searchResult === undefined ? tag.color : searchResult.color;
};
