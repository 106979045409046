import React, { memo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export function CodeBlock({ node, inline, className, children, ...props }) {
  if (!inline) {
    return (
      <div className="team-bot-code-block-wrapper">
        <pre {...props} className="team-bot-code-block">
          <code>{children}</code>
        </pre>
      </div>
    );
  }

  return (
    <code className={`${className} team-bot-inline-code`} {...props}>
      {children}
    </code>
  );
}

const components = {
  code: CodeBlock,
  pre: ({ children }) => <>{children}</>,
  ol: ({ node, children, ...props }) => {
    return (
      <ol className="team-bot-ordered-list" {...props}>
        {children}
      </ol>
    );
  },
  li: ({ node, children, ...props }) => {
    return (
      <li className="team-bot-list-item" {...props}>
        {children}
      </li>
    );
  },
  ul: ({ node, children, ...props }) => {
    return (
      <ul className="team-bot-unordered-list" {...props}>
        {children}
      </ul>
    );
  },
  strong: ({ node, children, ...props }) => {
    return (
      <span className="team-bot-strong-text" {...props}>
        {children}
      </span>
    );
  },
  a: ({ node, children, ...props }) => {
    return (
      <a className="team-bot-link" target="_blank" rel="noreferrer" {...props}>
        {children}
      </a>
    );
  },
  h1: ({ node, children, ...props }) => {
    return (
      <h1 className="team-bot-heading-1" {...props}>
        {children}
      </h1>
    );
  },
  h2: ({ node, children, ...props }) => {
    return (
      <h2 className="team-bot-heading-2" {...props}>
        {children}
      </h2>
    );
  },
  h3: ({ node, children, ...props }) => {
    return (
      <h3 className="team-bot-heading-3" {...props}>
        {children}
      </h3>
    );
  },
  h4: ({ node, children, ...props }) => {
    return (
      <h4 className="team-bot-heading-4" {...props}>
        {children}
      </h4>
    );
  },
  h5: ({ node, children, ...props }) => {
    return (
      <h5 className="team-bot-heading-5" {...props}>
        {children}
      </h5>
    );
  },
  h6: ({ node, children, ...props }) => {
    return (
      <h6 className="team-bot-heading-6" {...props}>
        {children}
      </h6>
    );
  }
};

const remarkPlugins = [remarkGfm];

const NonMemoizedMarkdown = ({ children }) => {
  return (
    <ReactMarkdown remarkPlugins={remarkPlugins} components={components}>
      {children}
    </ReactMarkdown>
  );
};

export const Markdown = memo(NonMemoizedMarkdown, (prevProps, nextProps) => prevProps.children === nextProps.children);
