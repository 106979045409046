import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, hexToRgb, Typography } from '@material-ui/core';
import { MDRT_CHOICES, THEME_COLOR_PALETTES, getValidThemeColorOption } from '../../../constants/my-details';
import _ from 'lodash';
import { getIsMDRTIntl, getMainProductIntl, getMainServiceIntl, getSeniorityIntl } from '../../../utils/my-details';
import AdviserInfoTagChip from '../AdviserInfoTagChip/AdviserInfoTagChip';
import { tryTranslate } from '../../../utils/locale';
import AdviserInfoCardAbstract from '../AdviserInfoCardAbstract/AdviserInfoCardAbstract';

const OtherAwardsTypoStyle = {
  whiteSpace: 'pre-wrap'
};

const FullWidthStyle = {
  width: '100%'
};

function getCustomizedProductsOrServicesArray(customizedProductsOrServices) {
  if (!customizedProductsOrServices || customizedProductsOrServices.length === 0) return [];
  return customizedProductsOrServices
    .split('#')
    .map(e => e.trim())
    .filter(e => e != '');
}

const AdviserInfoPageBusiness = props => {
  const { intl, adviserInfo, isIntoView } = props;

  const {
    company,
    seniority,
    license,
    shouldShowLicense,
    mainProductsServices,
    customizedProductsOrServices,
    isMDRT,
    otherAwards,
    jobExperience
  } = adviserInfo || {};
  const themeColor = getValidThemeColorOption(adviserInfo);
  const borderColor = THEME_COLOR_PALETTES[themeColor][1];
  const RGB = hexToRgb(borderColor)
    .replace('rgb', 'rgba')
    .replace(')', ', 0.3)');
  const formattedMainProductsServices = mainProductsServices || [];
  const formattedMainProductsServicesTags = mainProductsServices.map(e =>
    tryTranslate(intl, getMainProductIntl(e) || getMainServiceIntl(e))
  );
  const customizedProductsOrServicesTags = getCustomizedProductsOrServicesArray(customizedProductsOrServices);
  const productsOrServicesTags = [...formattedMainProductsServicesTags, ...customizedProductsOrServicesTags];

  return (
    <AdviserInfoCardAbstract
      className="adviser-info-page-business"
      title={intl.formatMessage({ id: 'adv-info-business-title' })}
      adviserInfo={adviserInfo}
      contentHeight="calc(100% - 110px)"
      isIntoView={isIntoView}
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid
          item
          style={{
            ...FullWidthStyle,
            flexGrow: 1,
            paddingBottom: 34
          }}
          className="content-container"
        >
          <Grid container direction="column" alignItems="flex-start" spacing={3}>
            {company && (
              <Grid item style={FullWidthStyle}>
                <Grid container direction="column" alignItems="flex-start" spacing={1}>
                  <Grid item>
                    <Typography display="inline" className="emoji">
                      💻
                    </Typography>
                    <Typography display="inline" className="title" style={{ whiteSpace: 'pre-wrap' }}>
                      {intl.formatMessage({ id: 'Company' })}
                    </Typography>
                  </Grid>
                  <Grid item style={{ maxWidth: '100%' }}>
                    <AdviserInfoTagChip color={borderColor} label={company} isIntoView={isIntoView} />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {seniority && (
              <Grid item style={FullWidthStyle}>
                <Grid container direction="column" alignItems="flex-start" spacing={1}>
                  <Grid item>
                    <Typography display="inline" className="emoji">
                      🕐
                    </Typography>
                    <Typography display="inline" className="title">
                      {intl.formatMessage({ id: 'seniority' })}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <AdviserInfoTagChip
                      color={borderColor}
                      label={tryTranslate(intl, getSeniorityIntl(seniority))}
                      isIntoView={isIntoView}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {license && shouldShowLicense && (
              <Grid item style={FullWidthStyle}>
                <Grid container direction="column" alignItems="flex-start" spacing={1}>
                  <Grid item>
                    <Typography display="inline" className="emoji">
                      🪪
                    </Typography>
                    <Typography display="inline">{intl.formatMessage({ id: 'license' })}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ whiteSpace: 'pre-line' }}>{license}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item style={FullWidthStyle}>
              <Grid container direction="column" alignItems="flex-start" spacing={1}>
                <Grid item>
                  <Typography display="inline" className="emoji">
                    📁
                  </Typography>
                  <Typography display="inline" className="title">
                    {intl.formatMessage({ id: 'main-products-services' })}
                  </Typography>
                </Grid>
                <Grid item>
                  {productsOrServicesTags.length > 0 ? (
                    <Grid container alignItems="center" justify="flex-start" spacing={1}>
                      {productsOrServicesTags.map(tag => (
                        <Grid item key={tag}>
                          <AdviserInfoTagChip color={borderColor} label={tag} isIntoView={isIntoView} />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography color="textSecondary">-</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {((isMDRT || []).length > 0 || otherAwards) && (
              <Grid item style={FullWidthStyle}>
                <Grid container direction="column" alignItems="flex-start" spacing={1}>
                  <Grid item>
                    <Typography display="inline" className="emoji">
                      🏆
                    </Typography>
                    <Typography display="inline" className="title">
                      {intl.formatMessage({ id: 'mdrt-achievement' })}
                    </Typography>
                  </Grid>
                  {(isMDRT || []).length > 0 && (
                    <Grid item>
                      <Grid container alignItems="center" justify="flex-start" spacing={1}>
                        {(isMDRT || []).map(mdrtChoice =>
                          MDRT_CHOICES.includes(mdrtChoice) ? (
                            <Grid item key={mdrtChoice}>
                              <AdviserInfoTagChip
                                color={borderColor}
                                label={tryTranslate(intl, getIsMDRTIntl(mdrtChoice))}
                                isIntoView={isIntoView}
                              />
                            </Grid>
                          ) : null
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <Typography style={OtherAwardsTypoStyle}>{otherAwards || ''}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {jobExperience && (
              <Grid item style={FullWidthStyle}>
                <Grid container direction="column" alignItems="flex-start" spacing={1}>
                  <Grid item>
                    <Typography display="inline" className="emoji">
                      💼
                    </Typography>
                    <Typography display="inline" className="title">
                      {intl.formatMessage({ id: 'job-experience' })}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>{jobExperience}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </AdviserInfoCardAbstract>
  );
};

export default injectIntl(AdviserInfoPageBusiness);
