import { isChromeIOS } from './device';
import NativeOrWeb from './native';

export const blobToDataURL = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => resolve(e.target.result);
    reader.onerror = e => reject(e);
    reader.readAsDataURL(blob);
  });

export const dataUrlToBlob = strUrl => {
  const parts = strUrl.split(/[:;,]/);
  const type = parts[1];
  const decoder = parts[2] === 'base64' ? atob : decodeURIComponent;
  const binData = decoder(parts.pop());
  const uiArr = new Uint8Array(binData.length);

  for (let i = 0; i < binData.length; i++) {
    uiArr[i] = binData.charCodeAt(i);
  }

  const BlobConstructor = window.Blob || window.MozBlob || window.WebKitBlob || String;
  return new BlobConstructor([uiArr], { type });
};

const createAnchor = (url, fileName) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.setAttribute('download', fileName);
  anchor.style.display = 'none';
  document.body.appendChild(anchor);
  return anchor;
};

const triggerDownload = (url, fileName) => {
  const anchor = createAnchor(url, fileName);
  anchor.click();
  document.body.removeChild(anchor);
};

const isSafari = () => /Version\/[\d.]+.*Safari/.test(navigator.userAgent);

const downloadForSafari = url => {
  window.location.href = url;
};

const downloadBlob = (blob, fileName) => {
  if (navigator.msSaveBlob && !isChromeIOS) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const url = URL.createObjectURL(blob);
    triggerDownload(url, fileName);
    URL.revokeObjectURL(url);
  }
};

const handleDataUrl = (payload, mimeType) => {
  if (payload.length > 1024 * 1024 * 1.999 && !(payload instanceof String)) {
    payload = dataUrlToBlob(payload);
    mimeType = payload.type || 'application/octet-stream';
  }
  return { payload, mimeType };
};

export const download = (data, strFileName = 'download', strMimeType = 'application/octet-stream') => {
  let payload = data;
  let mimeType = strMimeType;

  if (typeof data === 'string' && /^data:[\w+\-]+\/[\w+\-]+[,;]/.test(data)) {
    ({ payload, mimeType } = handleDataUrl(data, mimeType));
  }

  const blob = payload instanceof Blob ? payload : new Blob([payload], { type: mimeType });

  if (isSafari()) {
    downloadForSafari(URL.createObjectURL(blob));
  } else {
    downloadBlob(blob, strFileName);
  }

  return NativeOrWeb.downloadFile(blob, strFileName, strMimeType);
};

export const downloadApp = () => {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    window.open('https://itunes.apple.com/hk/app/portfoplus/id1590830597?mt=8', '_blank');
  } else {
    window.open('https://www.portfoplus.com/adviser/index.html', '_blank');
  }
};
