const AccessableRoute = {
  Linker: [
    '/',
    '/home',
    /^\/linker\//,
    /^\/adviser\//,
    /^\/new_help_center.*/,
    '/settings',
    '/signing_classroom',
    '/change_password',
    '/change_password_success',
    '/about',
    '/verify_Adviser'
  ],
  LinkerPro: ['/referral_records'],
  DEFAULT: ['*']
};

export function getAccessableRoute(subscriptionNickname) {
  switch (subscriptionNickname) {
    case 'Linker':
      return AccessableRoute.Linker;
    case 'LinkerPro':
      return [...AccessableRoute.Linker, ...AccessableRoute.LinkerPro];
    default:
      return AccessableRoute.DEFAULT;
  }
}
