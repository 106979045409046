export const versionString = '12.9.2';
export const versionDescription = '優化用戶介面\n 系統升級加強效能及穩定性\n';

//202503282359, 12.9.2 (Web) [RISK 1] feat(ai): ice breaking dics #2671 [RISK 0] feat(ai): icon #2669 [RISK 0] add custom product tag accept enter key #2670 [RISK 0] lk update referral profile #2668 [RISK 0] android accept nfc input #2667
//202503282359, 12.8.4 (Web, AppStore) [Risk: 1] Add premium financing to financial concept #2653 [Risk: 1] Increase tag limit #2652 [Risk: 1] Update fintech award to 2024 #2655 [Risk: 1] Update subscription expiry whatsapp message #2658 [RISK 0] Landing Page: Privacy update #2656 [Risk 0] add batch filter, nfc id filter to nfcCard admin #2657 [RISK 1] fix next location #2660 [RISK 1] Lazy pack recommendation update #2661 [RISK 1] keep-constant-language #2662
//202503182359, 12.6.8 (Web, AppStore) [Risk 1] Add Ai usage tracking #2650
//202503172359, 12.5.8 (Web, AppStore) [RISK-1] TeamChatBot prompt move to s3 #2647 [RISK - 1] Port 492 add customized products or services field #2646 [RISK-0] update CampaignCodeControl #2644 [RISK 1] Chubb ai update #2648 [RISK: 1] feat: update icon (#2649) [RISK 0] landing: add selling points section #2645
//202503112359, 12.4.4 (Web, AppStore) [Risk 0] Linker UI [RISK 1] Chubb specific ai feature [RISK 0] chubb ai feature clean code [PORT-500] [Risk: 1] Add voice create sos paper default policy name fix: team chat bot style [PORT-420] [Risk: 1] Add voice time limit [PORT-498] [Risk: 1] Add android in app update and app store review [PORT-499][Risk: 1] Add calendar
//202502282359, 12.3.4 (Web, AppStore) [PORT-497] [Risk: 1] Update referral rewards [Risk 0] seniority tr updatex
//202502212359, 12.3.2 (Web, AppStore) [RISK: 1] fix download pic in mobile [RISK: 1] team chat bot [RISK: 1] ai meeting note [Risk 1] Add customizedProductsOrServices field [PORT-447] [Risk: 1] Import phone contacts
//202502182359, 12.2.4 (Web, AppStore) PORT-142-Chubb-advisor-meeting-notes-function-will-recommend-Chubb-specific-product @Paul Lam, PORT-182-Figure-out-a-way-to-find-the-of-people-using-App-vs-Web @Paul Lam, revert app icon @Harry Yang, fix welcome animation @Harry Yang, download social post pic @Harry Yang, combine-gpt-campaign-code-and-resume-ultra-plan @Howie Huang, fix-connect-client-error @Howie Huang, hide-campaign-code @Howie Huang, remove-admin-delete-all @Howie Huang INITIAL_GPT_QUOTA = 50
//202501132359, 12.1.9 (Web, AppStore) Vcf bug fix, linker team feature, linker pro feature, linker accessible route update, Icon update
//202501132359, 12.1.8 (Web, AppStore) [RISK 0] clean console [PORT-465] [Risk: 1] Add tracking for editing meeting notes [PORT-461] [Risk: 1] Add adviser share Ecard tracking [PORT-462] [Risk: 1] Linker add to wallet [RISK 1] deprecate campaign code logic for linker [RISK 1] Linker phase 2 uiux [RISK 2] Limited Access Right to Linker User [Risk 1] Email verification fix [Risk: 1] Fix downloading ecard in apple wallet in native app
//202501132359, 12.1.3 (Web, AppStore) fix- users.nfcCards, Subscription dialog bug fix
//202501092359, 12.1.2 (Web, AppStore) [Risk: 2] Linker next feature [PORT-441] [Risk: 1] Update mdrt target
//202412272359, 12.1.0 (Web, AppStore) [PORT-356] [Risk: 1] Add tracking for premium financing [PORT-355] [Risk: 1] Prevent users from subscribing [PORT-373] [Risk: 1] Voice create sos paper [PORT-376] [Risk: 1] Change profile plus default language [Risk: 1] feat: chatgpt personal analysis lazy packs [PORT-380] [Risk 1] feat: ai prompt update
//202412122359, 12.0.7 (Web, AppStore) [PORT-331] [Risk: 1] Add house icon by default [PORT-330] [Risk: 1] Hide upgrade subscription for IOS app [PORT-329] [Risk: 1] Change profile plus button style [PORT-328] [Risk: 1] Change qr code logo [PORT-327] [Risk: 1] Send push notifications to specific users
//202412042359, 12.0.3 (Web, AppStore) [Risk: 1] Add Ami [Risk: 1] Add wechat features [Risk: 1] Update signup UI [Risk: 1] Add financial concept item
//202412042359, 12.0.2 (Web) [RISK 2] Port 201 selectable time for linker online reg [RISK 1] Accpet Nick Name Type Linker [Risk: 2] Skip login for existing user payment [Risk: 1] Add user details to tracking when generating pdf [Risk: 2] Linker bug fix
//202410222359, 11.13.5 (Web, AppStore) [Risk: 1] Fix financial pyramid bug [PORT-223] [Risk: 1] Modify sorting logic [PORT-213] [Risk: 1] Update change subscription dialog wording
//202410222359, 11.13.3 (Web) [Risk: 1] Skip coupon page
//202409252359, 11.13.2 (Web, AppStore) [Risk: 1] Fix policy review alignment [PORT-181] [Risk: 1] Add warning before closing meeting dialog
//202409201630, 11.12.9 (Web) [RISK 0] sos pdf preview css update [ RISK 0 ] Update client entry ui [ RISK 0 ] Update weekly keyword ui [RISK 0] await user props for tracking
//202409162359, 11.12.8 (Web, AppStore) [Risk: 1] Update verify email template [Risk: 1] Show team news without login [Risk: 1] [PORT-172] Update holdcover and medical card locations [Risk: 1] [PORT-143] Fix translation [Risk: 1] Fix notification popup issue
//202409032359, 11.12.2 (Web, AppStore) [Risk: 1] [PORT-149] Fix signup link issue [RISK 1] Port 139 ai record UI finetune
//202408312359, 11.12.1 (Web, AppStore) [RISK 1] Port 107 edit export sos paper is too big for moblie [Risk: 1] [PORT-138] Change wallet related wording [Risk: 1] [PORT-81] Update FTLife to CTF Life [Risk: 1] [PORT-135] [PORT-137] Update profile + translation and company display [Risk: 1] [Risk: 2] [PORT-90] Allow change email [PORT-114] Add stay logged in settings [Risk: 1] Fix setting default payment method [Risk: 1] Improve client and policy tracking
//202408152359, 11.11.0 (Web, AppStore) [Risk: 2] [PORT-64] Update capacitor to 6 (Web, AppStore) [Risk: 1] [PORT-113] Extend token expiry time [Risk: 1] [PORT-129] Add ios app banner [RISK 0] refactory and specific path updated
//202408152359, 11.10.2 (Web, AppStore) [RISK 1] Force open in native browser [Risk: 1] [PORT-103] Change subscription plan using url [Risk: 1] [PORT-92] Use gpt 4o [RISK 1] Port 59 add tracking to sign up and payment [Risk: 1] [PORT-84] Add phone number filter in user subscription admin [Risk: 1] [PORT-87] Improve create sample client [RISK 0 ] hide event button [ RISK: 0 ] Update Advisor Card UI [Risk: 1] [PORT-51] Remove linkedin tracking [RISK 0] update e-wallet tr image [Risk: 1] Improve direct tracking [Risk: 1] Improve direct tracking v2
//202408082359, 11.9.0 (Web, AppStore) [Risk: 1] [PORT-101] Add coupon when creating subscription  [Risk: 1] [PORT-100] Add analytics  [RISK 0] update sos info image
//202407271500, 11.8.0 (Web, AppStore) [RISK 1] Port 33 host headless cms to serve news hot topics, [RISK 1] Port 45 build in app layout for the post content display, [Risk: 1] [PORT-98] Cancel current subscription when payment failed, [Risk: 0] [PORT-82] Remove phone number countries
//202407271500, 11.7.2 (Web, AppStore) [RISK 1] Add event page for PFP Olympic
//202407232359, 11.6.5 (Web Only) [Risk 0][PORT-68]fix wording "rewrite" message [Risk: 0] [PORT-62] Fix not able to click login in sign up [Risk: 1] [PORT-54] Use plan id in coupon admin
//202407222359, 11.6.4 (Web Only) [Risk: 1] [PORT-55] Stripe phase two follow up [RISK 1][PORT-57] Update emergency export output & ux
//202407202359, 11.6.3 (Web Only) [Risk: 1] Serve lazy pack by user ip \n優化懶人包訪問速度 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用
//202407192359, 11.6.2 (AppStore) [Risk: 4] [PORT-30] Stripe phase two; [risk 1][PORT-8]review hotlines from insurers; [Risk 1][PORT-38]Add emoji for notes ai suggest; [Risk: 2][PORT-11] Multiple policies in create sample [Risk 2] Report Types: Instant Subscriber List #2401 '\n升級註冊及訂閱功能 \n優化製作救命紙功能 \n優化用戶界面 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用';
//202407122359, 11.5.2 (Web Only) [Risk 0] Landing page : remove trial content [Risk: 0] Update Llama version to 3 [risk 1] update Prudential Logo icon [Risk: 0] Change emergency reference wording
//202407042359, 11.4.7 (AppStore) [risk 1] Optimize quick notes format  [Risk: 1] Fix demo login UI  [Risk: 1] Add license
//202407022359, 11.4.1 (Web Only) [Risk: 1] Change demo account password [Risk: 0] Add recruitment piktochart to scrap [RISK0] UI Fix   '\n優化用戶界面 \n修復已知問題 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用';

//2024 June
//202406292359, 11.3.7:(AppStore) [Risk 1] Add Emergency Hotline - Phase 1 [Risk: 1] Allow percent in number[Risk: 1] Add saving and accident to create demo client [RISK1] Rmd w3 page integration [Risk 2] Rewrite Message via GPT [Gemini-1.5-flash]    '\n新增AI改寫信息功能 \n增加跑數狗專區 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用';
//202406272359, 11.2.6:(Web Only) [Risk: 1] Allow percent in number   [RISK1] Rmd w3 page integration [Risk 2] Rewrite Message via GPT [Gemini-1.5-flash]
//202406252359, 11.1.8: (AppStore)  [RISK 1] Bug fix numbo [risk 1] update financial charts js [Risk: 1] Add company name label in policy picker [Risk 0] add emoji to match hot topic [Risk: 1] Improve edit family [RISK 1] Amplitude PageView Tracker[Risk: 1] Fix signup report   '\n修复已知问题 \n優化用戶界面 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用';
//202406192359, 11.0.6: (AppStore)  [Risk: 1] Fix notification language [RISK 1] Exclude monthly subscription for admin email report      '\n修复已知问题 \n優化用戶界面 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用';
//202406142359, 11.0.0:（AppStore）[Risk: 1] Store referral record id in session storage [RISK 1] Update lp-viewership tracker   '\n增加客戶家庭管理功能 \n增強AI應用功能 \n優化用戶界面 \n需求分析增加儲存功能 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用';
//202406132359, 10.23.0:(Web Only) [Risk: 0] Add new piktochart domain； [Risk: 1] Save selected policies in session storage；[Risk: 1] Fix notification center language [Risk: 1] Update match lazy pack u； [Risk: 1] Fix load payment method twice issue [Risk: 1] Add family [Risk: 0] Update signing classroom content ； [RISK 1] add props for user tracking
//202406110659, 10.22.0:（Web Only）[Risk: 1] Add search client；[RISK 1] Scroll Frozen Fix；[Risk: 1] Add search client；[RISK 1] Team hot topic thumbnail fix   '\n優化用戶界面 \修复已知问题 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用';

//2024 May
//202405312359, 10.20.0:(AppStore) [RISK 0] add apple / google play icon at landing page; [RISK 2] NFC Enhancement : Differentiate UX for multiple audience types [Risk: 0] Use gpt-4o [Risk: 3] Revamp stripe flow  [Risk 0] Update E-card Wording [Risk: 1] Add warning if not number   '\n優化用戶界面 \n增強AI應用功能 \n增强NFC功能 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用';
//202405252359, 10.19.0:(AppStore) [risk 1: ]Enhance meeting notes with AI suggest; [RISK:0] Update monthly payment TNC [tr]; [Risk: 1] Disable upgrade subscription button ; [Risk: 2] Add stripe subscription manually; [RISK 0] Delete app uxui;   '\n優化用戶界面 \n增強AI應用功能 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用';
//202405142359, 10.18.0:(AppStore) [Risk: 0] Update delete account translation
//202405142359, 10.17.0:(AppStore) [Risk: 2] Revamp lazy pack id uid and aid
//202405142359, 10.16.0:(AppStore) [Risk: 1] Fix subscription pipeline;[Risk: 1] Fix detail expense bug; [Risk: 1] Fix piktochart bug; (Risk 0) update translation for bday no and lazypack matching logic; [RISK 1] Delete app uxui; [Risk: 1] Change custom questions   '\n優化用戶界面 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用';
//202405102359, 10.15.0:(AppStore) [RISK 1] Account Deletion Service;[RISK 1] remove-payment-reminder-in-monthly-plan; [RISK 1] Enhance Need analysis life caculator;[Risk 0] remove-figure-at-chart; [Risk 0] Landing page update;  [Risk: 1] Add remark for demo client ; [Risk: 1] Change initial value of date of birth; [Risk: 1] Update whatsapp wizard ui [Risk: 2] Add surrender value [Risk: 0] Add Stripe verification file ; [Risk: 1] Add birthday number; [Risk: 1] Match lazy pack;  [Risk: 1] Add custom questions; [Risk: 1] Change remark add date style  '\n优化訂閱管理功能 \n增強AI應用功能 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用';
//202405032359, 10.12.2:(Web only) [Risk: 2] Add pay now; [Risk: 1] Change export file name; [RISK 0] Mixcare Content Update; [Risk: 1] Swap add client fields order; [Risk: 1] Fix team page members order; [Risk: 1] Add Gemini 1.5; [Risk 0] Landing Page Update; [Risk: 1] Fix import teams user type; [Risk: 1] Remove lazy pack access after subscription expires; [Risk: 1] Get all users in team and subteams; [Risk: 1] Fix icon overlapping; [Risk: 1] Fix team member role switch bug \n优化團隊管理功能 \n增強AI應用功能 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用'

//2024 APR
//202404152359, 10.9.8 :(AppStore) [Risk: 1] Fix quarter target not set;   [Risk: 1] Fix convert emails to lowercase and display NaN;   [Risk: 1] Fix find top level team;   [Risk: 1] Convert emails to lowercase before importing;   [Risk: 1] Team use different userid
//202404152359，10.9.7 :(web only) [Risk: 1] Team page update ;   [Risk: 1] Fix issue when user has no quarter target or closed biz '\n升级團隊管理功能 \n增強AI應用功能 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用';
//202404132359，10.9.5 :(Web Only) [Risk: 1] Improve policy review edit layout logic; [Risk: 2] Update team structure; [Risk: 1] Use chatgpt streaming

//2024 Mar
//202403282359，10.9.1 :(AppStore) [Risk: 1] Update team UI v2; [Risk0] Affiliate update holdcover '\n優化團隊管理功能 \n增強AI應用功能 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用';
//202403272359，10.9.0 :(AppStore) [Risk: 1] Update openai version；[Risk: 1] Bedrock；[Risk: 1] Use azure openai sdk；[Risk: 1] Change leave without exit dialog；[RISK 1] Affiliate update holdcover；[Risk: 1] Gemini；[Risk: 1] Team UI update '\n優化團隊管理功能 \n增強AI應用功能 \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用';
//202403152359, 10.8.1 : (Web Only) [Risk: 1] Fix pdf pipeline \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用 \n(僅限邀請) ChatGPT功能
//202403132359, 10.8.0 : (AppStore) [Risk: 1]Password reset time extended from 15 minutes to 30 minutes; [Risk: 2] Fix subscription upgrade issue; [Risk: 1] Change account locked wording; [Risk: 1] Close follow up dialog automatically; [Risk: 1] Remove discount logic; [Risk: 1] Fix subscription issue; [Risk: 1] Hide toast default icon; [Risk: 2] Team structure; \n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用 \n(僅限邀請) ChatGPT功能';

//2024 Feb
//202402292359, 10.7.8 : (Web Only) [Risk: 1] Fix subscription issue '\n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用 \n(僅限邀請) ChatGPT功能'
//202402222359, 10.7.5  (OTA)[Risk: 1] Validate image link in pdf server; [Risk 1] Update connect-src; [RISK0] Comment out unnecessary Console Log ;[Risk 1] Update Tracking Event; [Risk: 1] Update fintech award logo to 2023; [RISK 1] Update tracking event requirement 0208 '\n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用 \n(僅限邀請) ChatGPT功能';
//202402072359, 10.6.8: (AppStore) [RISK2] Upgrade amplitude sdk; [Risk: 1] Fix pen test vulnerabilities [Risk: 1] Fix subscription issue when payment failed; [Risk: 1] Fix policy review language; [RISK2] Update package: react-toastify; [Risk: 1] Fix month interval translation; [Risk: 2] Rename client export to emergency reference '\n系統升級加強效能及穩定性 \n-COT Plan功能限時開放試用 \n(僅限邀請) ChatGPT功能';

//2024 Jan
//202401151200, 10.1.1 : (OTA) [Risk0]Update mdrtc to 2025；   '\nMDRT資訊更新 \n-COT Plan功能限時開放試用 \n(僅限邀請) ChatGPT功能';
//202401052359, 10.1.0 : (AppStore) [Risk: 2] Revamp policy review；[Risk: 1] Fix financial pyramid; [Risk: 1] Fix wallet medium [Risk: 1] Update nfc card notification content [Risk: 1] Fix nfc referral create date issue  '\n升級NFC名片功能 \n升級理财金字塔 \n升級保單Review \n-COT Plan功能限時開放試用 \n(僅限邀請) ChatGPT功能';

//2023 Dec
//202312152359, 9.9.8  : (AppStore) [Risk: 2] Create new entry and notification when potential client taps NFC card [Risk: 2] Fix translation when sharing lazy pack [RISK-2] add 4 properties to tracking [Risk: 1] Fix premium financing pdf bug [Risk: 1] Change wording for following in Client #2296 [Risk: 1] Change QR code translation [Risk: 2] Fix nfc auto generate [Risk: 1] Add hashtag to signing classroom and lazy pack  '\n升級NFC功能 \n升級團隊(Team)功能 \n-COT Plan功能限時開放試用 \n(僅限邀請) ChatGPT功能'
//202312052359, 9.3.0  : (Web Only) [Risk: 1] Lower opacity in client page, [Risk 1] Landing page content update, [Risk: 1] Use GPT 4 Turbo   ' \n(僅限邀請)升級ChatGPT加強效能 \n登陆页有關內容更新\n優化用戶界面 \n-COT Plan功能限時開放試用';

//2023 Nov
//202311272359, 9.2.8  : (AppStore) [Risk: 1] Update piktochart to node 18 and fix mobile image issue, [Risk: 3] Update pdf server to node 18, [Risk: 1] Fix Simplified Chinese translation, [Risk: 2] Update subscription to node 18,[Risk: 2] Add team props for tracking,[risk 0]update translation, [Risk 2] Team Tracking Report Quering,[RISK-1] Ui fix for tracking off dialog, [Risk 0]update tracking images   ' \n升級PDF功能加強穩定性 \n修正簡體字庫 \n-COT Plan功能限時開放試用 \n(僅限邀請) ChatGPT功能';
//202311152359, 9.0.0  : (AppStore) '[Risk: 3] Node 18 upgrade, Update[RISK1] ui-fix-Closed-Business-input,Fix zh translation,[Risk: 1] Fix reminder page to allow empty payment method' \n系統升級加強效能及穩定性 \n修正保費提示功能 \n-COT Plan功能限時開放試用 \n(僅限邀請) ChatGPT功能
//202311032359, 8.8.8  : (AppStore) '[Risk: 1] minor style update, [Risk: 1] Remove "featured" in lp link, fine the manual input of interest can be 0.001, change the min interest rate to be 0.125 from 0.5, '  ' \n調整保費融資最低利率 \n團隊版可啟動招募Ecard \n-COT Plan功能限時開放試用 \n(僅限邀請) ChatGPT功能';

//2023 Oct
//202310271330, 8.8.5 : (Web Only) " [Risk: 1] Finconcept fab failed to redirect,[Risk 1] Fix inconsistent quarter end date [Risk 1] hiding bonus icon,[Risk: 1] update slider step from 0.01 to 0.125 (Paul Wang, pls help verify)"  ' \n優化保費融資 \n團隊版可啟動招募Ecard \n-COT Plan功能限時開放試用 \n(僅限邀請) ChatGPT功能';
//202310132359, 8.8.1  : (AppStore) 'able to show recuritment page if inside team structure (was in 8.8.0), fix www redirect to login page bug ,?? Ga4?'
//202310130530, 8.8.0  : (OTA) 'set P-fin calculater slider max value from 6.5 to 10, add Ga4 enhancement at web, Remove utm tag from landing page (www), able to show recuritment page if inside team structure, fix www redirect to login page bug'
//202310022359, 8.7.6  : (OTA Manda) 'made planMode=ft suit for Chubb signup, [RISK1] Fix createDefaultClient when email verify, [RISK1] Untag other when no currency selected, [Risk: 1] fix profile plus 404 when team setting is enabled,[Risk 1] Tr update / Hide Button Unused(fix ft card showing time duration),[RISK 1] Pull down mooncake event, [RISK 1] Automatically Generate Sign Up Report(for Admin),[Risk: 1] fix: not adding piktoId with languages for scrapping, [Risk: 1] update condition of showing team plan in SubscriptionCard & SubscriptionContent, [Risk: 1] update condition of allowing adviser to enable team page in profile plus (reverted), [Risk: 1] fix: not able to get emergency export details, update Chubb logo to be unofficial \n優化各功能 \n加保單可有多貨幣選擇 \n-COT Plan功能限時開放試用 \n(僅限邀請) ChatGPT功能''

//2023 Aug
//202308300000, 8.6.0  : (AppStore) '[Risk: 1] add more currency to policy ,[Risk: 1] new Marketplace partner (pushed 202308281200) 加保單可有多貨幣選擇 \n更新理財概念 - 香港人口老化 \n-COT Plan功能限時開放試用';';
//202308250630, 8.5.0  : (OTA Manda) '[Risk: 1] User can't update follow-up progress on client page(pushed eariler day), [Risk: 1] fix: bug in generating marketing figure(pushed eariler day), [Risk: 1] fix: not able to renew expiry date of subscription(pushed eariler day), [Risk: 1] fix: incorrect payment amount of subscription during renewal(pushed 202308241000), [Risk: 1] Client like Msg in notification center directs to 403 page , [Risk: 1] fix: input panel collapsed after virtual keyboard is shown [Risk: 1] New Financial Concept for Aging Population'; ' \n更新理財概念 - 香港人口老化 \n優化各功能 -客戶感謝/生日提醒 \n-COT Plan功能限時開放試用';
//202308172300, 8.3.5  : (OTA) 'fix Profile Pic update can't show bug (Alex Liu), temp fix going analysis page from HP would shows lasted client data, [Risk: 1] Auto-generate daily email reports for users that signed up/paid'; (僅限邀請) ChatGPT功能 \n優化各功能 \n-COT Plan功能限時開放試用';
//202308090300, 8.3.1  : (OTA Manda) 'Analysis UI enhancement, Fix login saved last clientlist status, forbid gmail.com.hk, fix admin list large list issue(deployed on 27Jul, enabled bulk refund from strip at backend' (deploy at Ireland)\n-增強保障分析功能 \n增強懶人包功能 \n優化各功能 \n-COT Plan功能限時開放試用';

//2023 Jul
//202307182355, 8.0.6  : (OTA) 'fix verify email api errorlog, fix hottopic api log, improve sentry logging, fix Is recuritment, update MPF inform page '' \n-優化各功能 \n-(僅限邀請) ChatGPT 語音>會面紀錄 \n-COT Plan功能限時開放試用'
//202307182355, 8.0.6  : (OTA Manda) 'fix connection bug, Profile+ improvement-add linebreak/not showing school etc, fix fb tracking '  ' \n-修正啟動客戶登入功能 \n-(僅限邀請) ChatGPT 語音>會面紀錄 / 改寫訊息加上Emoji 讓它更有趣 \n-COT Plan功能限時開放試用';
//202307180730, 8.0.3  : (Admin bg only) 'revamp team management portal at admin'  ' \n-推薦QR信息更新修正 \n-(僅限邀請) ChatGPT 語音>會面紀錄 / 改寫訊息加上Emoji 讓它更有趣 \n-COT Plan功能限時開放試用';
//202307161330, 8.0.2  : (AppStore) 'whitelist linkedin tracking script, reenable GPT POPUP for testing ,fix export more Emergency contact wld crash issue, add GPT add emoji function'    ' \n-推薦QR信息更新修正 \n-(僅限邀請) ChatGPT 語音>會面紀錄 / 改寫訊息加上Emoji 讓它更有趣 \n-COT Plan功能限時開放試用';
//202307090145, 7.9.6  : (OTA Manda) 'add testing Text2speech logic (control by Campaigncode FEATURE_VOICE2TXT), fix reference QR coding issue, fix msg opt update ui issue'  ' \n-推薦QR信息更新修正 \n-ChatGPT 語音>會面紀錄(僅限邀請) \n-COT Plan功能限時開放試用';
//202307090145, 7.9.2  : (OTA Manda) 'Admin can change renewal pricing, add filter in admin, fix profile pic showing thumbnail in whatsapp issue, pw hints disappear when critical hits to avoid blocking ui, signing class and hotopic can add tab, sending expiring email logic improvemnt, fix lazpack open in app issue, finconcept shows focused button, '  ' \n-增強懶人包,簽單教室功能 \n-分享電子名片/理財概念優化 \n-COT Plan功能限時開放試用';
//202307032359, 7.8.0 : (OTA Manda) 'Apple E-wallet new cerf update, add cron job console.log, Fix Campaign Code issue, fix Profile+ use adviser app open loop, update all share link to be thumbail enabled, add Currenty at review PDF, Big Data exclude no Me policy count '  ' \n-各功能(大數據,E-wallet,電子名片,分享連接,Review PDF)優化 \n-COT Plan功能限時開放試用';

//2023 Jun
//202306192359, 7.7.2 : (OTA Manda) 'P-fin edit save name bug fix'' \n-保費融資計算機編輯儲存名稱修正) \n-COT Plan功能限時開放試用';
//202306112359, 7.7.1 : (Appstore) 'P-fin add currency mulitple'  \n-保費融資計算機顯示不同貨幣(COT Plan功能) \n-分享個人卡片及懶人包時會顯示圖片 \n-COT Plan功能限時開放試用 \n 教室新增保費融資使用技巧';
//202306072359, 7.6.9 : (OTA Manda) fix FinPy bottom showing figure overlap, Pfin -ve return bar colour and interest slider bug, add "Valid" option at Other policy.  ' \n-修正PolicyReview加單按鍵 \n-分享個人卡片及懶人包時會顯示圖片 \n-COT功能限時開放試用 \n-優化保費融資計算機(COT)';
//202306050930, 7.6.6 : (Web Only) fix can't add policy at policy review   ' \n-分享個人卡片及懶人包時會顯示圖片 \n-COT功能限時開放試用 \n-簽單教室新增 保費融資使用技巧';
//202306012359, 7.6.5 : (OTA Manda) Lazypack content update, profile plus and Lazypack share with image   ' \n-分享個人卡片及懶人包時會顯示圖片 \n-COT功能限時開放試用 \n-簽單教室新增 保費融資使用技巧';
//202306010700, 7.5.5 : (OTA) Lazypack with zh selection (ran DB migration) Fix: quick create profile funcation, fix: wont show $28 after used, fix : not to show language button in HC,  add Ffin training video x 3  '\n-COT功能限時開放試用 \n-優化快速加單功能 \n-ChatGPT 測試(僅限邀請) \n-簽單教室新增 保費融資使用技巧';

//2023 May
//202305232359, 7.5.0 : (OTA Manda) Mongodb driver update to 5.0, Lazypack with zh selection (pending DB migration), E-wallet emf Ref new design  '\n-加保險摘要到客戶電子錢包新介面 (COT) \n-懶人包功能優化 \n-ChatGPT 測試(僅限邀請) ';
//202305152359, 7.3.3 : (OTA) fix scrolling issue at Meeting notes, admin page 2 display bug, smartidea pop up alwasy show fix, login routing issue,
//202305102359, 7.3.0 : (OTA Manda) hide icon in Pfin PDF, smartIDea pop up ux fix, not automatically select the first client, ChatGPT ux fix in IOS.  '\n-ChatGPT 測試(僅限邀請) \n-DISC 性格分類輸入助手 \n-加緊急參考到客戶手機E-wallet (COT 計劃-免費試用)';
//202305070600, 7.2.7 : (Appstore) add Achievers icon, GPT feedback enhance, wont select disconnected user issue, FInHome C side add icon,  '\n-ChatGPT 測試(僅限邀請) \n-DISC 性格分類輸入助手 \n-加緊急參考到客戶手機E-wallet (COT 計劃-免費試用)';
//202305040000, 7.2.2 : (OTA) ChatGPT integation launch with email feedback and tricking (control by "FEATURE_GPT").
//202305020000, 7.1.9 : (OTA Manda) update Profile+ SChinese transition, hotfix profile+ safari issue, add disc picker, finetune qrcode ui, client's selection preselected, gpt timeout issue, save all image to s3 (client blast), adviser info ui minor fix,  '\n-解決Safari新版本兼容問題 \n-DISC 性格分類輸入助手 \n-加緊急參考到客戶手機E-wallet (COT 計劃-免費試用)';

//2023 Apr
//202304170600, 7.1.0 : (Appstore) pending: hide share as referral, pending:hide icon to view share, add GPT4 send button, fix wallet Language error, update reminder icon at clientpage, refine client conneciton logic, add to wallet using bottom sheet in Mobile, fix at function help ui layout, add tutorial for add to wallet / connection '\n-加緊急參考到客戶手機E-wallet (COT 計劃-免費試用) \n-更方便為客戶開啟戶口的方法 \n-Excel匯入客戶 (在帳戶內)';
//202304170600, 6.9.3 : (OTA) add policy ui fix, make pdf add to Ewallet avalible to all (pending Video tutorial), add insurer subcat in admin with insurer colour choice in admin, 14-Apr release new lazypack about asset inheritance '\n-加緊急參考到手機E-wallet (COT 計劃-免費試用) \n-加入更多保險公司 \n-Excel匯入客戶 (在帳戶內)';
//202304120000, 6.9.0 : (OTA) update IA info for Other insurer, not using IA info as Other insurer, showing other insurer one by one showing names in Emergency pdf/google wallet, add tricking for importing action'\n-Excel匯入客戶 (在帳戶內)" \n-優化其他保險公司選項 \n-加緊急參考到手機E-wallet (COT 計劃) \n-大數據介面優化';
//202304110000, 6.8.9 : (Web Only) update Client side Icon, add import client tutorial\n-Excel匯入客戶 (在帳戶內)"\n-加緊急參考到手機E-wallet (COT 計劃) \n-大數據介面優化';
//202304090000, 6.8.8 : (AppStore) ChatGPT ui update, ios with noti badget count, Import Emergency PDF to E-wallet, import client to pfp, update icon, add till 55 in policy choice, add bg to teamnews close icon, move setting icon to Account, team blast image save in s3,\n-客戶Import from Excel \n-IOS Notifcation \n-加緊急參考到手機電子錢包 (COT 計劃)';
//202304020000, 6.7.1 : (OTA Manda) clientpage svg image, fix remove selflogo issue,add to wallet with language choice, native app pop up fix, PF+ to wallet ui update, ChatGPT deploy(hide)'\n-優化客戶/選單介面 \n-加E-Card到手機電子錢包 (COT 計劃) \n-加緊急參考到手機電子錢包 (COT 計劃)\n測試ChatGPT ';

//2023 Mar
//202303282300, 6.6.0 : (OTA Manda) pdf cta with shadow, no show EB95 in full pay subscription, update pf+ icon, PR+ social icon with words, update wording for upload self logo, (launch upload self icon to universe team)'\n-優化 PDF 介面 \n-優化 E-Card/懶人包 介面';
//202303222300, 6.5.7 : (OTA) update Policy pdf custom wording CTA words, Fix policy review loading timing issue, title ui update for policy reivew, emergency pdf wording move up, fix disconnected client erro when click analysis, fix admin sub ordering issue when update the order'\n-匯出PDF可按客戶加Remark(COT) \n-優化保單Review功能  \n-優化緊急參考功能 \n-匯出PDF小功能升級';
//202302271030, 6.5.1 : (OTA Manda) fix PyFin keep jumping, can't gen policy pdf.
//202302271030, 6.5.0 : (OTA) add Custom logo upload (hided), finetune import wording, policy review fix, remove show adivser info option in emergency pdf, team news cross with grey colour, fix direct to web version in adviser fix,'\n-匯出PDF可按客戶加Remark(COT) \n-優化保單Review功能  \n-優化緊急參考功能 \n-匯出PDF小功能升級';
//202303160000, 6.4.3 : (AppStore) Add Care for Wealth icon, replace OD app icon, finetune import logic, version display not showing none none, remark for PDF for each client, MPF plan shows insurer specific plan only, show default HK flag, fix pitochart build fail, remove capacitor:// in shared link,
//202303100000, 6.3.2 : (OTA) Fix empty Factsheet after updating client detail, unexpected height of meeting pop up, missing Ewallet icon at Profile+, CPI not showing correctly, hide button in FinPy PDF, P-Fin pdf wording overflow,'\n-增強摘要用戶體驗 \n-修復客戶潛力排名 \n-修復Affiliate圖片顯示';
//202303050030, 6.2.5 : (OTA) Visual board image to S3, meeting notes with date at add client, GA4 tracking of signup, more version info at setup page, smartidea with bottom sheet (bug need to fix), fix d/l CVF file, fix can't scroll meeting note using bottom sheet\n-增強用戶體驗 \n-修復儲蓄聯絡功能 \n-更多版本信息';
//202303012330, 6.1.5 : (OTA) exclude paidup at user charts, algin analysis chart at client page and in analysis, team news show full image.'\n-修復分析圖表 \n-現金流圖表排除 Paidup單 \n-修復團隊新聞圖示';

//2023 Feb
//202302271030, 6.1.1 : (OTA Manda) FIx CSP issue for not able to upload Profile+ Image, Referral input can't be show normally at IOS,
//202302260000, 6.1.0 : (Appstore) Profile Pic update to S3 instead of DB, Profile+ Unversity/school seperate by # (DB Migration), Import (beta, control by Campaigncode FEATURE_IMPORT), add COT Label Tag, OD native app Logo, OTA library update, Cside shows of adviser card '\n-優化數碼名片 \n-加入COT計劃功能 \n-測試客戶匯入功能(歡迎查詢)'
//202302200000, 5.9.8 : (OTA) add CSP whitelist screen, meeting note insert Date, meeting note use bottom sheet UI, Cside shows adviser referral ui update, landing page add UTM, fixed error message wording, fix paid up premium count, fix meeting note route to factsheet issue,'\n-會面筆記介面更新 \n-會面筆記可插入日期 \n-修復繳清保費計算';
//202302150000, 5.9.2 : (OTA) Enhance Referer input / filter logic, fix file upload fail due to CSP, add csp for GA 4 code '\n-增強介紹人輸入/篩選功能';
//202302122359, 5.9.0 : (Appstore) Try OTA update fix (trigger at clientpage), saving analysis with 2dp return rate, onboard joyride direct to signing class, update HP banner, update Ewallet, update ios login banner, search referrer with auto complete, translation update: sign class not show at C side, payment method not a must when input policies etc
//202302062315, 5.8.0 : (OTA) Fix non HC page show SuggestedIdea content, insurer info link to cheque tools, insuer picker UI update, update OTA default setting parameter for Prod, last meeting calucaltion fix, remove HC showing tricking ui, remove warning in console'\n-增強簽單教室資訊顯示體驗 \n-增強保險公司選單介面 \n-保險公司資訊可連接到支票精靈';

//2023 Jan
//202301310000, 5.7.3 : (OTA) remove CNY icon, fix cside SOS routing, fix sum become 102.5%, fix can't type Other as insurer, shows exact HC wording at helping tips, add wording for MC teeth cleaning service hotline '\n-增強洗牙查詢資訊 \n-修復客戶危急資訊 \n-修復保障額百分比計算';
//202301210000, 5.6.9 : (AppStore) fix pitochart invisble overlap, add in-page helptips video at 5 pages, update homepage banner, fix splashscreen in ipad, fix hottopic performance issue, xss with gtm, use local content for Hottopic in dev env, profile+ add persoality api(for new app), avator-update in s3' \n-重大更新';
//202301200000, 5.6.0 : (AppStore) fix review PDF CI shows heart icon when insured is not me, behavior tricking for Hottopic, update login banner, Fin Py Detail expense not updated in real time, HC/ HP add suggested content, HP updated shortcut from Pfin to Cheque, beta codepush targer version update, loading spinner update, fix Pitcchart zindex issue blocking to click Ecard button,
//202301170000, 5.3.9 : (OTA) fix Emergency PDF colour, fix helpcenter can't route to same page, OTA update fix,
//202301160000, 5.3.6 : (OTA) fix insurer refacter related bug: Emergency linked to whatif / Emergeny pdf always show 1 colour, Helpcenter UIUX update, added 3 x video at helpcenter ' \n-更新簽單教室: 內地通關Tips \n-更新簽單教室介面 \n-製作PDF顏色修復';
//202301100000, 5.3.3 : (AppStore) E-wallet about to select data to export, ClientList performance improve, refacter insurance data, feedback form add whatsapp button, profile+ pop up to reminder input whatsapp data, ' \n-增加懶人包/簽單教室推介項目 \n-更新簽單教室 \n';
//202301100000, 5.1.5 : (OTA) Featured cat for HC/HT, C-side hided Move policy, update HC/HT style/get mid q Youtude Thumbnail, update E-wallet campaign code to be FEATURE_EMINFO' \n-增加懶人包/簽單教室推介項目 \n-更新簽單教室 \n';
//202301070000, 5.1.1 : (OTA) update E-wallet logic ' \n-新增懶人包 \n-更新簽單教室 \n';
//202301060000, 5.1.0 : (OTA) update MiniEcard UI, minor em PDF ui, update signing classroom' \n-優化名片 \n-更新簽單教室 \n-更新懶人包\n';
//202301040000, 5.0.8 : (OTA) Set Target UI update, Fix XSS log at console, fix shadow of Name card mini button, Fin Pyramid UI finetune, link for force to use webapp, Fix 5.0.1 issue in proper way. ' \n-優化設定目標功能 \n-優化理財金字塔 \n-新增限定用WebApp開啟功能 \n-修復PDF我的名片按鍵\n';

//2022 Dec
//202212301530, 5.0.1 : (OTA) Temp fix of XSS can't upload profile picture '\n-修復PDF我的名篇陰影  \n-優化理財金字塔 \n-新增限定用WebApp開啟功能 \n';
//202212300000, 5.0.0 : (OTA) New theme colour, update MDRT 2024 requirement, update CPI to be 3.178% 香港1990至2021数据
//202212242300, 4.9.5 : (Appstore) Adviser QRCode behaviour update to direct messaege, Fin Pyramid UI update, Test-> Emergency Info add to Ewallet '\n-新增Referral 功能: 掃瞄PDF的QRCode 可直接whatsapp \n-優化理財金字塔';
//202212222300, 4.8.7 : (OTA) Mini Ecard UI update; remove Ecard button in Hottopic '\n-個人卡片介面更新 PDF內也可按鍵查閱顧問名片';
//202212202311, 4.8.2 : update Korea emergency no. from 911 to 112, fix broken icon at walkthru popup, minor ui update the no see period since last met.'\n-優化緊急聯繫熱線\n-優化簽單教室圖示';
//202212180155, 4.8.0 : XSS protection, show EB95 code at begining of signup, fix input referral code at signup bug, homepage at HOT '\n-加強系統保安\n-優化理財金字塔';
//202212132359, 4.7.3 : (OTA) team Hottopic using text json, disable old hard coded FB pixel, C-side remove covid radar, adjust HT / HC pop up size, fix pitochart android left / right scrolling, remove css unsplash code '\n-優化簽單教室\n-優化Android系統觀看懶人包\n-更新生意天書';
//202212082359, 4.6.8 : add desc in OTA pop up message, Pfin style update, show 2 line for copy box, add WP/PB to policy quick edit, update Tips style, Help center big pop up, Ecard not showing pop up if not edited : '-保費融資介面更新\n-改善快速編輯保單\n-簽單教室介面優化\n-編輯電子卡片版面優化';
//202212042226, 4.6.1 : Push to both app store

//2022 Nov
//202211281500, 4.6.0 : Relocate help Center, Fin Pyramid default show expense, Updated capacitor, updated Google API, fintune client reminder UI, (Set api server cors hosts via env variable CORS_HOSTS)
//202211281500, 4.5.0 : Apply new theme colour at pdf, review pdf default no print mode, pfin with 3d.p., export PDF with Private mode, show profile pic at Account page, show "share to client" in dail button at Profile+,
//202211250430, 4.3.9 : Export PDF with Rider, HC show youtude with bigger screen, Ecard mid screen show share CTA
//202211211323, 4.3.7 : Fix environment variables (LP cant show in IOS app)
//202211191650, 4.3.6 : Green Profile+, profile+ A side ui update, LP/HC add update date, emerg PDF show co. name, policy with WP / PB option, OTA fix
//202211132232, 4.2.6 : Polcy picker add Mkt value, Fin Pyramid fix adding Market value to Asset. Adding date to HC/ LP .
//202211110000, 4.2.0 : Help center/ LP add badget, importance not a must field when add client, MC remove iframe, extend forget pw link to be 15mins
//202211060038, 4.1.3 : Macau insurer Info, fix: Bday sorting fix at Task, policyname history show, Admin NFC gen link fix

//2022 Oct
//202210220625. 4.1.0 : Policy name history select, MC client renewal, create client ui with bigger take note area, admin nfc link generator, fix lazypack bubtton to ecard 404 error
//202210220625. 3.9.9 : add health finconcept, fix team 50tag logic, add pitochart s3 copy, Admin ecard generater, Emg pdf clickable, Admin session fix, referral program add condition
//202210220625. 3.9.2 : (OTA) fix- android disable team icon, whatsapp thumbnail share , pdf with clickable link, Help center pop up, wasapp link fix
//202210190008, 3.9.0 : new Help center UI, hottopic feedback with link, update HP banner
//202210162323, 3.8.6 : enhanced paytrial pop up for auto charging, analysis inflation to be 3->0%
//202210102330, 3.8.5 : fix cron job issue in aws, fix ewallet pic issue, more option for protection / payment terms, ci more option
//202210070625, 3.8.1 : share from C for Hottopic on iOS devices , font size in share box
//202210010200, 3.8.0 : Whatsapp wizard, piktochat with red dot, refer program, google/apple wallet, reg pake "login button" with pararmeter, analysis trigger dif FinCon

//2022 Sep
//202209262330, 3.7.2 : piktochart with US debt and update H and P chart, Pfin with 8 save (data migrataion)
//202209222359, 3.7.0 : push Notification fix , cheque tools ui, quick note zoom in, wts tracking, mixpanel tracking fix
//202209180100, 3.6.5 : config VPC for prod and db
