import LeavePageConfirm from '../../../../components/LeavePageConfirm/LeavePageConfirm';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Prompt } from 'react-router';
import { Fragment } from 'react';
import React from 'react';

function LinkerLeavePageConfirm({ intl, shouldShow }) {
  return (
    <LeavePageConfirm
      shouldShow={shouldShow}
      save={() => {}}
      render={({ open, onPromptMessage, cancel, exit }) => {
        return (
          <Fragment>
            <Prompt when={shouldShow} message={onPromptMessage} />
            <Dialog open={open}>
              <DialogTitle>
                {intl.formatMessage({
                  id: 'linker-editor-leave-page-alert-title'
                })}
              </DialogTitle>
              <DialogContent>
                {intl.formatMessage({
                  id: 'linker-editor-leave-page-alert-message'
                })}
              </DialogContent>
              <DialogActions>
                <Button onClick={exit} variant="text">
                  {intl.formatMessage({
                    id: 'linker-editor-leave-page-alert-cta-leave'
                  })}
                </Button>
                <Button onClick={cancel} variant="contained" color="primary">
                  {intl.formatMessage({
                    id: 'linker-editor-leave-page-alert-cta-stay'
                  })}
                </Button>
              </DialogActions>
            </Dialog>
          </Fragment>
        );
      }}
    />
  );
}

export default LinkerLeavePageConfirm;
