import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { isNavAside } from "../../../../utils/device";
import { getReferralProfile } from "../utils/referralProfile";
import { buildSearchString, getSearchObject } from "../../../../utils/router";
import { messageListener } from "../utils/messageListener";

const Appointment = props => {
  const { intl, history } = props;

  const isSideNav = isNavAside();

  useEffect(() => {
    return messageListener(history);
  }, []);

  const search = getSearchObject(history);

  const newSearch = buildSearchString([
    search,
    getReferralProfile({
      onNextAction: "PUSH",
      onNextData: "/home"
    })
  ]);

  return (
    <div
      style={{
        overflow: "hidden",
        height: "calc(100% - 45px)",
        width: isSideNav ? "calc(100% - 60px)" : "100%",
        position: "absolute",
        top: 45
      }}
    >
      <iframe
        src={process.env.LINKER_URL + "/frame/timeslots" + "?" + newSearch}
        frameBorder="0"
        height="100%"
        width="100%"
        allow="camera"
      />
    </div>
  );
};

export default injectIntl(Appointment);
