import _, { get, size } from 'lodash';
import moment from 'moment';
import store from '../../store';
import { GET_CLIENT_DETAILS, INIT_PHASE, UPDATE_VIEWING_CLIENT } from '../../store/client/duck';
import { BORDER_COLORS, CPI_WORDINGS } from './ClientEntry';
import { translateMessage } from '../../utils/locale';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { RESET_ANALYSIS_DEFAULT_VALUES } from '../../store/analysis/duck';
import { SCORE_LEVEL_COLORS } from '../../constants/analysis';
import { getAgeFromDob } from '../../utils/date';
import { tracking } from '../../utils/tracking';
import { getInsurerTitleEn, isInsurerOther } from '../../utils/insurer';
import { toggleControl, toggleDialog } from '../../store/control/duck';

const IMPORTANCE_IMAGES = {
  'V.VIP': 'img/vvip.png',
  VIP: 'img/vip.png'
};

export const isInactiveEmail = client => {
  const email = getClientEmail(client);
  return !!(!email || email.indexOf(client._id.toString()) > -1);
};
export const getClientConnection = (client, userDetails) => {
  if (client && client.advisorDetails && size(client.advisorDetails) > 0) {
    if (!userDetails) {
      return client.advisorDetails.find(adviserDetail => adviserDetail.connection);
    } else {
      const adviserDetail = client.advisorDetails.find(adviserDetail => adviserDetail.email === userDetails.email);
      return adviserDetail ? adviserDetail.connection : false;
    }
  }

  return false;
};
export const isClientDisconnected = client =>
  client && !(client.passwordChangeKey || client.inactiveUser || getClientConnection(client));
export const getClientTracking = (client, userDetails) => {
  if (getClientConnectionStatus(client) === 'Inactive') {
    return _.get(client, 'trackingId');
  } else {
    return _.get(
      _.get(client, 'advisorDetails', []).find(
        adviserDetail => !userDetails || adviserDetail.email === userDetails.email
      ),
      'trackingId'
    );
  }
};
export const isClientTrackingActive = (client, userDetails) =>
  _.get(getClientTracking(client, userDetails), 'isActive', false);
export const getClientConnectionStatus = client => {
  if (client) {
    if (isClientDisconnected(client)) {
      return 'Disconnected';
    } else {
      if (client.inactiveUser) {
        if (client.connectionRequestId) {
          return 'Pending';
        } else {
          return 'Inactive';
        }
      } else {
        if (client.passwordChangeKey) {
          return 'Pending';
        } else {
          return 'Connected';
        }
      }
    }
  } else {
    return undefined;
  }
};
export const getClientName = client =>
  client ? (client.factsheetId && client.factsheetId.name ? client.factsheetId.name : client.name) : undefined;
export const getClientEmail = client => {
  const email = _.get(client, 'factsheetId.email', '');
  return email.indexOf(_.get(client, '_id')) > -1 ? '' : email;
};
export const getClientWhatsapp = client => _.get(client, 'factsheetId.whatsapp');
export const getClientLineId = client => _.get(client, 'factsheetId.lineId');
export const getCPIScoreColor = client =>
  client.validScore && Number.parseInt(client.scoreLevel) < size(BORDER_COLORS)
    ? BORDER_COLORS[Number.parseInt(client.scoreLevel)]
    : BORDER_COLORS[3];
export const getCPIIntl = client => {
  return client.validScore && Number.parseInt(client.scoreLevel) < size(CPI_WORDINGS)
    ? CPI_WORDINGS[Number.parseInt(client.scoreLevel)]
    : CPI_WORDINGS[3];
};
export const getClientDOB = client => {
  const factsheet = get(client, 'factsheetId', {});
  const isDisconnected = isClientDisconnected(client);
  return factsheet.dob ? factsheet.dob : !isDisconnected ? get(client, 'dob') : undefined;
};
export const getLastMeetingDate = client => {
  const isDisconnected = isClientDisconnected(client);
  const lastMeetingDate = _.get(client, 'factsheetId.latestMeetingDate');
  return !isDisconnected ? lastMeetingDate : undefined;
};
export const getLastMeetingMonthsDays = lastMeetingDate => {
  if (!lastMeetingDate) {
    return { lastMeetingDurationMonths: undefined, lastMeetingDurationDays: undefined };
  }

  const nowMoment = moment();
  const lastMeetingDateMoment = moment(lastMeetingDate, 'YYYY-MM-DD');
  const difference = nowMoment.diff(lastMeetingDateMoment);
  const lastMeetingDuration = moment.duration(difference);
  const isFutureDay = difference < 0;
  const lastMeetingDurationMonths = isFutureDay
    ? Math.ceil(lastMeetingDuration.asMonths())
    : Math.floor(lastMeetingDuration.asMonths());
  const lastMeetingDurationDays = isFutureDay
    ? Math.ceil(
        lastMeetingDuration
          .clone()
          .add(lastMeetingDurationMonths, 'month')
          .asDays()
      )
    : Math.floor(
        lastMeetingDuration
          .clone()
          .subtract(lastMeetingDurationMonths, 'month')
          .asDays()
      );

  return { lastMeetingDurationMonths, lastMeetingDurationDays };
};
export const formatLastMeetingDuration = (intl, { lastMeetingDurationMonths, lastMeetingDurationDays }) => {
  if (lastMeetingDurationMonths === 0 && lastMeetingDurationDays === 0) {
    return `0 ${intl.formatMessage({ id: 'daycount' })}`;
  }

  return lastMeetingDurationMonths || lastMeetingDurationDays
    ? `${lastMeetingDurationMonths} ${intl.formatMessage({
        id: 'Mth'
      })} ${lastMeetingDurationDays} ${intl.formatMessage({ id: 'daycount' })}`
    : '-';
};

export const setClientIdLocalStorage = id => {
  if (id) {
    localStorage.setItem('clientId', JSON.stringify(id));
  } else {
    localStorage.removeItem('clientId');
  }
};

export const formatHistoryState = (state, history) => {
  return state;
};

export const goToAddClient = (history, stateObj) => {
  localStorage.removeItem('clientId');
  localStorage.removeItem('currentPage');
  localStorage.removeItem('clientFactsheetDataOne');
  localStorage.removeItem('clientFactsheetDataTwo');
  localStorage.removeItem('clientPersonalData');
  store.dispatch({ type: INIT_PHASE });
  history.push('/client_personal_form', stateObj);
};

export const goToPortfolio = (client, history, isReplace, extraLocationState) => {
  store.dispatch({ type: UPDATE_VIEWING_CLIENT, payload: { data: { client } } });
  setClientIdLocalStorage(client._id);

  const clientIncData = {};
  clientIncData.monthlyExpense = get(client, 'factsheetId.monthlyExpense');
  clientIncData.monthlyIncome = get(client, 'factsheetId.monthlyIncome');
  clientIncData.debt = get(client, 'factsheetId.debt');
  clientIncData.age = getAgeFromDob(client.factsheetId.dob, client.factsheetId.age);

  const clientFactsheetDetail = {};
  clientFactsheetDetail.clientIncData = clientIncData;

  localStorage.setItem('factsheetDOB', JSON.stringify(_.get(client, 'factsheetId.dob', '')));

  const stateObj = _.merge({ clientFactsheetDetail, client }, extraLocationState);
  const pathObj = { pathname: '/dashboard', state: stateObj };
  isReplace ? history.replace(pathObj) : history.push(pathObj);
};

export const goToAddPortfolio = (client, history, userDetails, isReplace, extraLocationState) => {
  const clientIncData = {};
  const clientFactsheetDetail = {};

  if (client) {
    if (!client.inactiveUser && !client.passwordChangeKey && !getClientConnection(client)) {
      return;
    }

    store.dispatch({ type: UPDATE_VIEWING_CLIENT, payload: { data: { client } } });

    setClientIdLocalStorage(client._id);
    localStorage.setItem('factsheetDOB', JSON.stringify(_.get(client, 'factsheetId.dob', '')));

    clientIncData.clientStatus = getClientConnectionStatus(client);
    clientFactsheetDetail.clientIncData = clientIncData;
    localStorage.setItem('factsheetDOB', JSON.stringify(_.get(client, 'factsheetId.dob', '')));
  }

  const stateObj = _.merge({ clientFactsheetDetail }, extraLocationState);

  sessionStorage.removeItem('editPolicyDetails');
  sessionStorage.removeItem('editPolicy');
  localStorage.removeItem('addRider');
  localStorage.removeItem('previousPolicyData');
  localStorage.removeItem('insurer');

  const pathObj = { pathname: '/add_portfolio', state: stateObj };
  isReplace ? history.replace(pathObj) : history.push(pathObj);
};

export const goToAnalysis = (client, history, target) => {
  store.dispatch({ type: RESET_ANALYSIS_DEFAULT_VALUES });
  setClientIdLocalStorage(client._id);
  store.dispatch({ type: UPDATE_VIEWING_CLIENT, payload: { data: { client } } });

  //to remove the items from localstorage of analysis
  [
    'lifeAge',
    'FamilySupport',
    'extraMoney',
    'whatIfProtectionAmount',
    'whatIfProtectionAmountOfCi',
    'debt',
    'monthlyExpense',
    'monthlyIncome',
    'savingTabMonthlyIncome',
    'savingReturnRate',
    'savingInflationRate',
    'savingTabSavingTerms',
    'savingTabShowclientueAt',
    'savingRetirementTerms',
    'savingRetireAfter',
    'savingwhatIfTopUpMonthlySaving',
    'savingCurrentAsset',
    'includeMortageAmount'
  ].forEach(k => localStorage.removeItem(k));
  sessionStorage.removeItem('useTotalLife');

  const clientIncData = {};
  clientIncData.monthlyExpense = get(client, 'factsheetId.monthlyExpense');
  clientIncData.monthlyIncome = get(client, 'factsheetId.monthlyIncome');
  clientIncData.debt = get(client, 'factsheetId.debt');
  clientIncData.age = getAgeFromDob(client.factsheetId.dob, client.factsheetId.age);

  const clientFactsheetDetail = {};
  clientFactsheetDetail.clientIncData = clientIncData;

  let pathname = 'analysis';

  if (target === 'Life') {
    clientIncData.currentSlide = 1;
    pathname = '/need-analysis';
  } else if (target === 'C.I.') {
    clientIncData.currentSlide = 2;
    pathname = '/need-analysis';
  } else if (target === 'Saving') {
    clientIncData.currentSlide = 3;
    pathname = '/need-analysis';
  } else if (target === 'overall') {
    clientIncData.currentSlide = 0;
    pathname = '/need-analysis';
  }

  history.push({
    pathname: pathname,
    state: formatHistoryState({ clientFactsheetDetail, client }, history)
  });
};

export const goToReminder = (client, history, userDetails) => {
  setClientIdLocalStorage(client._id);
  store.dispatch({ type: UPDATE_VIEWING_CLIENT, payload: { data: { client } } });

  const clientIncData = {};
  clientIncData.clientStatus = getClientConnectionStatus(client);
  const inactiveEmail = isInactiveEmail(client);

  clientIncData.name = get(client, 'factsheetId.name');
  clientIncData.phoneNumber = get(client, 'factsheetId.whatsapp');
  clientIncData.email = get(client, 'factsheetId.email');
  clientIncData.emailShow = !inactiveEmail;
  const clientFactsheetDetail = {};
  clientFactsheetDetail.clientIncData = clientIncData;

  history.push({
    pathname: '/reminder',
    state: formatHistoryState({ clientFactsheetDetail }, history)
  });
};

export const goToExportOption = (client, history, isReplace, extraLocationState) => {
  setClientIdLocalStorage(client._id);
  sessionStorage.setItem('client', JSON.stringify(client));
  store.dispatch({ type: UPDATE_VIEWING_CLIENT, payload: { data: { client } } });

  const stateObj = _.merge({}, extraLocationState);
  const pathObj = { pathname: '/export_options', state: stateObj };

  const connection = getClientConnection(client);
  if (connection || client.inactiveUser || client.passwordChangeKey) {
    isReplace ? history.replace(pathObj) : history.push(pathObj);
  }
};

export const goToExport = (client, history, isReplace, extraLocationState) => {
  setClientIdLocalStorage(client._id);
  store.dispatch({ type: UPDATE_VIEWING_CLIENT, payload: { data: { client } } });

  const inactiveEmail = isInactiveEmail(client);
  const connection = getClientConnection(client);
  const email = getClientEmail(client);

  const clientDetail = {
    clientWhatsApp: client.factsheetId && client.factsheetId.whatsapp ? client.factsheetId.whatsapp : '',
    clientEmail: email && !inactiveEmail ? email : ''
  };

  const stateObj = _.merge({ clientDetail }, extraLocationState);
  const pathObj = { pathname: '/emergency_reference', exportClientId: client._id, state: stateObj };
  if (connection || client.inactiveUser || client.passwordChangeKey) {
    isReplace ? history.replace(pathObj) : history.push(pathObj);
  }
};

export const goToExportEmergencyEWallet = (client, history, options) => {
  setClientIdLocalStorage(client._id);
  store.dispatch({ type: UPDATE_VIEWING_CLIENT, payload: { data: { client } } });

  history.push('/export-emergency-e-wallet', { options });
};

export const goToFactsheet = (client, history, state) => {
  state = { replaceRoute: '/client_factsheet', ...state };

  setClientIdLocalStorage(client._id);
  store.dispatch({ type: UPDATE_VIEWING_CLIENT, payload: { data: { client } } });
  store.dispatch({ type: GET_CLIENT_DETAILS, payload: { factsheetId: client.factsheetId._id, userId: client._id } });

  history.push({
    pathname: '/client_factsheet',
    clientId: client._id,
    factsheetId: client.factsheetId._id,
    state: formatHistoryState(state, history)
  });
};

export const geToFactsheetDetails = (client, history, getClientDetails, state) => {
  setClientIdLocalStorage(client._id);
  store.dispatch({ type: UPDATE_VIEWING_CLIENT, payload: { data: { client } } });
  getClientDetails({ userId: client._id, factsheetId: client.factsheetId._id });

  history.push({ pathname: '/client_personal_form', state: formatHistoryState(state, history) });
};

export const enableTracking = (client, userDetails, { startDate, opportunity, expectedBusinessAmount }) => {
  const startDate_format = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
  const startDate1 = startDate_format ? moment.utc(startDate_format).format('YYYY-MM-DDT00:00:00.00Z') : '';
  const obj = {};
  obj.clientId = client._id;
  obj.factsheetId = client.factsheetId._id;
  obj.advisorId = userDetails._id;

  obj.met = 0;
  const err = {};
  if (expectedBusinessAmount === '') {
    err.expectedBusinessAmount = 'Please fill in';
  }
  this.setState({ err });
  //Added new filed on modal and also insert them on create new tracking
  if (expectedBusinessAmount) {
    obj.businessVol = expectedBusinessAmount;
  }
  if (opportunity) {
    obj.opportunity = opportunity;
    if (opportunity === 'H') {
      const date_high = moment().format('YYYY-MM-DD');
      obj.dateHigh = moment.utc(date_high).format('YYYY-MM-DDT00:00:00.00Z');
      obj.dayCount = moment().diff(moment(startDate1, 'YYYY-MM-DD'), 'days');
    } else {
      obj.dayCount = 0;
    }
  } else {
    obj.dayCount = 0;
  }

  //obj.startDate = moment.utc(startDate);
  obj.startDate = startDate1;
  obj.isActive = client.trackingId ? !client.trackingId.isActive : true;
  obj.connectionStatus = this.state.connectionStatus;
  if (!Object.keys(err).length) {
    this.props.enableTracking(obj);

    // Tracking
    tracking('Enable Business Tracking');
  }
};

export const getBirthdayGreetingMessage = (client, language) => {
  let message = '';
  message += translateMessage('Happy Birthday', language, { name: _.get(client, 'factsheetId.name', '') });
  message += '\n';
  message += translateMessage('wish_message', language);
  message += '\n';
  message += translateMessage('prepared_gift', language);
  return message;
};

export const getPolicyDueGreetingMessage = (client, policyDueData, language) => {
  const clientName = getClientName(client);

  let message = '';
  message += `${translateMessage('Hi', language)} ${clientName}`;
  message += '\n';
  message += translateMessage('Remind you of the following policy due info:', language);
  message += '\n\n';

  const policyMessage = [];
  if (policyDueData && policyDueData.length > 0) {
    policyDueData.forEach(data => {
      if (data.userId === client._id) {
        const insurer = _.get(data, 'insurerId', {});
        const isOtherInsurer = isInsurerOther(insurer);
        const insurerTitleEn = getInsurerTitleEn(insurer);
        const insurerName = isOtherInsurer ? _.get(data, 'insurer', '') : insurerTitleEn;
        const policySubCat = _.get(data, 'subCategoryId.title', '');
        const policyNumber = _.get(data, 'policyNumber', '');
        const policyDueDate1 = moment(_.get(data, 'policyDueDate', null))
          .locale(language)
          .format('DD-MMM-YYYY');
        let temp = '';
        temp += `${translateMessage('Insurer', language)}: ${
          insurerName && !isOtherInsurer ? translateMessage(insurerName, language) : insurerName
        }`;
        temp += '\n';
        temp += `${translateMessage('Type', language)}: ${
          policySubCat ? translateMessage(policySubCat, language) : ' '
        }`;
        temp += '\n';
        temp += `${translateMessage('Policy No.', language)}: ${policyNumber}`;
        temp += '\n';
        temp += `${translateMessage('Due date', language)}: ${policyDueDate1}`;
        temp += '\n\n';
        policyMessage.push(temp);
      }
    });
    if (getClientConnection(client)) {
      policyMessage.push(
        `${translateMessage('Login', language)}${' '}https://app.portfoplus.com${' '}${translateMessage(
          'for more information and payment methods',
          language
        )}`
      );
    }
  }

  message += policyMessage.join('');

  return message;
};

export const getPaidUpPoliciesGreetingMessage = (client, paidUpPolicies, language) => {
  const clientName = getClientName(client);

  let message = '';
  message += `${translateMessage('Hi', language)} ${clientName}`;
  message += '\n';
  message += translateMessage('paid-up-greet', language);
  message += '\n\n';

  const policyMessage = [];
  if (paidUpPolicies && paidUpPolicies.length > 0) {
    paidUpPolicies.forEach(data => {
      const insurer = _.get(data, 'insurerId', {});
      const isOtherInsurer = isInsurerOther(insurer);
      const insurerTitleEn = getInsurerTitleEn(insurer);
      const insurerName = isOtherInsurer ? _.get(data, 'insurer', '') : insurerTitleEn;
      const policySubCat = _.get(data, 'subCategoryId.title', '');
      const policyNumber = _.get(data, 'policyNumber', '');
      const policyDueDate1 = moment(_.get(data, 'paidUpDate', null))
        .locale(language)
        .format('DD-MMM-YYYY');
      let temp = '';
      temp += `${translateMessage('Insurer', language)}: ${
        insurerName && !isOtherInsurer ? _.get(data, `insurerId.title.${language}`, insurerName) : insurerName
      }`;
      temp += '\n';
      temp += `${translateMessage('Type', language)}: ${policySubCat ? translateMessage(policySubCat, language) : ' '}`;
      temp += '\n';
      temp += `${translateMessage('Policy No.', language)}: ${policyNumber}`;
      temp += '\n';
      temp += `${translateMessage('Due date', language)}: ${policyDueDate1}`;
      temp += '\n\n';
      policyMessage.push(temp);
    });
    if (getClientConnection(client)) {
      policyMessage.push(
        `${translateMessage('Login', language)}${' '}https://app.portfoplus.com${' '}${translateMessage(
          'for more information and payment methods',
          language
        )}`
      );
    }
  }

  message += policyMessage.join('');

  return message;
};

export const toggleBirthdayGreetingDialog = (toggleDialog, intl, client) => {
  const dob = getClientDOB(client);
  if (dob) {
    const dobMoment = moment(dob);
    toggleDialog('greeting', {
      title: intl.formatMessage({ id: 'Greeting' }),
      content: (
        <Typography align="center">
          <img alt="" className="imageClientState" src="img/birthdayClient.svg" />
          <span>
            {dobMoment.format('DD-MMM-YYYY')} ({moment().diff(dobMoment, 'years')})
          </span>
        </Typography>
      ),
      phone: _.get(client, 'factsheetId.phoneNumber'),
      whatsapp: _.get(client, 'factsheetId.whatsapp'),
      email: getClientEmail(client),
      getMessage: language => getBirthdayGreetingMessage(client, language)
    });
  }
};

export const togglePaidUpPoliciesGreetingDialog = (toggleDialog, intl, client) => {
  toggleDialog('greeting', {
    title: intl.formatMessage({ id: 'Client Reminder' }),
    phone: _.get(client, 'factsheetId.phoneNumber'),
    whatsapp: _.get(client, 'factsheetId.whatsapp'),
    email: getClientEmail(client),
    getMessage: language => getPaidUpPoliciesGreetingMessage(client, client.paidUpPolicies, language)
  });
};

export const toggleMeetingNoteDialog = (toggleDialog, client, extraProps) => {
  const latestMeetingDate = _.get(client, 'factsheetId.latestMeetingDate', null);
  toggleDialog('meeting', {
    mode: 'note',
    client: client,
    latestMeetingDate: latestMeetingDate ? moment.parseZone(latestMeetingDate) : null,
    lastMeetingLocation: _.get(client, 'factsheetId.lastMeetingLocation', ''),
    fsReminderDate: _.get(client, 'factsheetId.fsReminderDate', null),
    fsReminderActive: _.get(client, 'factsheetId.fsReminderActive', false),
    remark: _.get(client, 'factsheetId.remark', ''),
    tags: _.get(client, 'factsheetId.tags', []),
    isRecruitment: _.get(client, 'factsheetId.isRecruitment', false),
    nextMeetingDate: _.get(client, 'factsheetId.nextMeetingDate'),
    ...extraProps
  });
};

export const getConstellation = momentObj => {
  if (!momentObj) {
    return undefined;
  }

  const month = momentObj.month() + 1;
  const day = momentObj.date();

  let id;

  if (month === 12) {
    day < 22 ? (id = 'sagittarius') : (id = 'goat');
  } else if (month === 1) {
    day < 20 ? (id = 'goat') : (id = 'aquarius');
  } else if (month === 2) {
    day < 19 ? (id = 'aquarius') : (id = 'pisces');
  } else if (month === 3) {
    day < 21 ? (id = 'pisces') : (id = 'aries');
  } else if (month === 4) {
    day < 20 ? (id = 'aries') : (id = 'bull');
  } else if (month === 5) {
    day < 21 ? (id = 'bull') : (id = 'gemini');
  } else if (month === 6) {
    day < 21 ? (id = 'gemini') : (id = 'cancer');
  } else if (month === 7) {
    day < 23 ? (id = 'cancer') : (id = 'leo');
  } else if (month === 8) {
    day < 23 ? (id = 'leo') : (id = 'virgo');
  } else if (month === 9) {
    day < 23 ? (id = 'virgo') : (id = 'libra');
  } else if (month === 10) {
    day < 23 ? (id = 'libra') : (id = 'scorpion');
  } else if (month === 11) {
    day < 22 ? (id = 'scorpion') : (id = 'sagittarius');
  }

  return { image: `/img/${id}.png`, descIntl: `cstlt-${id}` };
};

export const getImportanceImage = importance => (importance ? get(IMPORTANCE_IMAGES, importance) : undefined);

export const LAST_MEETING_COLOR_BAR_PAIRS = [
  {
    lastMeetingDurationMonths: { lessThan: 6 },
    width: 25,
    color: SCORE_LEVEL_COLORS[3]
  },
  {
    lastMeetingDurationMonths: { largerThan: 6, lessThan: 12 },
    width: 50,
    color: SCORE_LEVEL_COLORS[2]
  },
  {
    lastMeetingDurationMonths: { largerThan: 12, lessThan: 18 },
    width: 75,
    color: SCORE_LEVEL_COLORS[1]
  },
  {
    lastMeetingDurationMonths: { largerThan: 18 },
    width: 100,
    color: SCORE_LEVEL_COLORS[0]
  }
];
export const getLastMeetingColorBar = ({ lastMeetingDurationMonths, lastMeetingDurationDays }) => {
  if (!lastMeetingDurationMonths && !lastMeetingDurationDays) {
    return { width: 20, color: 'grey' };
  } else {
    if (!lastMeetingDurationMonths) {
      return LAST_MEETING_COLOR_BAR_PAIRS[0];
    }

    let finalPair;
    for (const pair of LAST_MEETING_COLOR_BAR_PAIRS) {
      if (
        (!pair.lastMeetingDurationMonths.largerThan ||
          lastMeetingDurationMonths >= pair.lastMeetingDurationMonths.largerThan) &&
        (!pair.lastMeetingDurationMonths.lessThan ||
          lastMeetingDurationMonths < pair.lastMeetingDurationMonths.lessThan)
      ) {
        finalPair = pair;
      }
    }
    return finalPair;
  }
};

export const openChatGPTIceBreakingDrawer = client => {
  const { factsheetId } = client || {};
  const { personality } = factsheetId || {};

  if (personality) {
    store.dispatch(toggleControl('chatGPTIceBreakingDrawer', { client }));
  } else {
    store.dispatch(toggleDialog('chatGPTInsufficientData', { client }));
  }
};

export const openChatGPTMatchLazyPacks = client => {
  store.dispatch(toggleControl('chatGPTMatchLazyPacks', { client }));
};

export const openChatGPTPersonalAnalysis = client => {
  store.dispatch(toggleControl('chatGPTPersonalAnalysis', { client }));
};
