import { fetch } from '../../utils';
import _ from 'lodash';

const thisFetch = (url, options) =>
  fetch(
    url,
    _.merge(
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      },
      options
    )
  )
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      return error;
    });

export const setupPaymentMethodApi = (metadata, email) =>
  thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/stripe/setupPaymentMethod`, {
    method: 'POST',
    body: JSON.stringify({ metadata, email })
  });

export const submitPaymentApi = (amount, description, shortDescription, metadata, authorization, email) =>
  thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/stripe/submitPayment`, {
    method: 'POST',
    body: JSON.stringify({ amount, description, shortDescription, metadata, authorization, email })
  });

export const createSubscriptionApi = (priceId, cancelAtPeriodEnd, couponId, email) =>
  thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/stripe/createSubscription`, {
    method: 'POST',
    body: JSON.stringify({ priceId, cancelAtPeriodEnd, couponId, email })
  });

export const createFreeSubscriptionApi = (token, cancelAtPeriodEnd, email) =>
  thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/stripe/createFreeSubscription`, {
    method: 'POST',
    body: JSON.stringify({ token, cancelAtPeriodEnd, email })
  });

export const getPaymentMethodApi = (stripeSubscriptionId) =>
  thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/stripe/getPaymentMethod?stripeSubscriptionId=${stripeSubscriptionId}`, {
    method: 'GET'
  });

export const getCustomerCreditsApi = () =>
  thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/stripe/getCustomerBalance`, {
    method: 'GET'
  });
