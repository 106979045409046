import React, { Fragment, useState, useEffect } from 'react';
import ChatGPTPresetDrawer from '../ChatGPTPresetDrawer';
import { connect } from 'react-redux';
import { toggleControl } from '../../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { Button, Card, Grid, Typography, Box } from '@material-ui/core';
import ActionButton from '../ActionButton/ActionButton';
import { handleError, resetGPTQuota, validateGPTQuota, decreaseGPTQuota } from '../../../../utils/openai';
import { getGPTRecommendation, getGPTRecommendationSample } from '../../../../store/vertex/vertex';
import LoadingBackdrop from '../../../LoadingBackdrop/LoadingBackdrop';
import { useForceUpdate } from '../../../../utils/hooks';
import { Stop } from '@material-ui/icons';
import { trackDirect } from '../../../../store/analytics/api';
import { tracking } from '../../../../utils/tracking';

export { GPTProductRecommendationButton } from './GPTProductRecommendationButton';
// import { trackDirect } from '../../../../store/analytics/api';
// import { tracking } from '../../../../utils/tracking';
// trackDirect('suggestHotTopic');
// tracking('AI: ChatGPT Personal Analysis');

export const DebugView = props => {
  const data = getGPTRecommendationSample;

  return (
    <Fragment>
      <GPTProductRecommendationDrawer
        intl={props.intl}
        gptProductRecommendationDrawer={{
          showSendButton: false,
          result: data,
          client: {},
          open: true
        }}
      />
    </Fragment>
  );
};
export const GPTProductRecommendationDebugView = injectIntl(DebugView);

const GPTProductRecommendationDrawer = props => {
  const { intl, gptProductRecommendationDrawer, toggleControl, language } = props;

  const [result, setResult] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const forceUpdate = useForceUpdate();

  const { showSendButton, client, open } = gptProductRecommendationDrawer || {};

  useEffect(() => {
    setResult(gptProductRecommendationDrawer.result || undefined);
  }, [gptProductRecommendationDrawer.result]);

  const isOpen = !!gptProductRecommendationDrawer && open;

  const onClose = () => {
    toggleControl('gptProductRecommendationDrawer', { ...gptProductRecommendationDrawer, open: false });
  };

  const onExited = () => {
    toggleControl('gptProductRecommendationDrawer', {
      ...gptProductRecommendationDrawer,
      open: false,
      results: undefined
    });
  };

  const onFeedback = () => {
    toggleControl('chatGPTFeedbackDialog', {
      client: client,
      extraContent: result,
      onSubmit: () => {
        resetGPTQuota();
        forceUpdate();
      }
    });
  };

  const send = async () => {
    try {
      setLoading(true);
      validateGPTQuota();
      trackDirect('generateProductRecommendation');
      tracking('AI: Generate Product Recommendation');

      const _result = await getGPTRecommendation(
        client,
        'product_comparison',
        'chubb_ci_recommendation_demo.json',
        language
      );
      setResult(_result);
      decreaseGPTQuota();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const clear = () => {
    setResult(undefined);
  };

  return (
    <Fragment key={props.key}>
      <LoadingBackdrop open={loading} />
      <ChatGPTPresetDrawer open={isOpen} onClose={onClose} onExited={onExited} onFeedback={onFeedback}>
        <Grid
          container
          direction="column"
          spacing={2}
          style={{
            height: '100%',
            paddingBottom: '48px'
          }}
        >
          <Grid item>
            <Typography style={{ whiteSpace: 'pre-wrap', marginBottom: '24px' }}>
              {intl.formatMessage({ id: 'gpt-options-product-recommendation-drawer-content' })}
            </Typography>
            <Typography variant="h6" style={{ color: '#999' }}>
              {intl.formatMessage({ id: 'gpt-product-recommendation-reasons-title' })}
            </Typography>

            {showSendButton && (
              <Grid item>
                <ActionButton onClick={send}>
                  <Typography>{intl.formatMessage({ id: 'gpt-options-product-recommendation-send' })}</Typography>
                </ActionButton>
              </Grid>
            )}
          </Grid>

          {result && (
            <Fragment>
              <ReasonList
                plan={result.plan}
                reasons={result.reason_of_recommendation}
                amount={result.suggest_insure_amount}
                intl={intl}
                detailPage={result.product_detail_page}
                moreProducts={result.reasons_for_others}
              />

              <ScriptForSell script={result.selling_script} intl={intl} />

              <Typography variant="body1">{result.reason}</Typography>
            </Fragment>
          )}
        </Grid>
      </ChatGPTPresetDrawer>
    </Fragment>
  );
};

const ReasonList = ({
  /** @type {String} */ plan,
  /** @type {<String>[]} */ reasons,
  /** @type {number} */ amount,
  /** @type {any} */ intl,
  /** @type {String} */ detailPage,
  /** @type {String} */ moreProducts
}) => {
  return (
    reasons.length > 0 && (
      <Card
        style={{
          margin: '8px',
          padding: '16px',
          backgroundColor: 'rgba(250, 250, 250, 0.9)'
        }}
      >
        <Grid container style={{ marginBottom: '24px' }}>
          <Typography variant="h6">{plan}</Typography>

          <Grid item>
            {amount && (
              <Typography variant="body1" style={{ marginBottom: '12px' }}>
                {intl.formatMessage({ id: 'gpt-product-recommendation-amount' })}
                {amount}{' '}
              </Typography>
            )}
            {reasons.map((reason, index) => {
              return (
                <Grid key={index} container style={{ marginBottom: '16px' }}>
                  <Grid item xs={1}>
                    <Box style={{ marginRight: '8px', paddingTop: '4px' }}>
                      <Stop style={{ fontSize: '14px' }} />
                    </Box>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="body1">{reason}</Typography>
                  </Grid>
                </Grid>
              );
            })}

            {detailPage && (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => {
                  window.open(detailPage, '_blank');
                }}
                style={{ fontSize: 12, marginLeft: 4, marginRight: 4, minWidth: 'unset' }}
              >
                {intl.formatMessage({ id: 'gpt-product-recommendation-product-detail-page-cta' })}
              </Button>
            )}
          </Grid>
        </Grid>
        {moreProducts && moreProducts.length > 0 && (
          <Grid container style={{ marginBottom: '12px' }}>
            <Typography variant="body1" style={{ color: '#aaa' }}>
              {intl.formatMessage({ id: 'gpt-product-recommendation-other-products-title' })}
            </Typography>
            {moreProducts.map((p, index) => {
              return (
                <Grid key={index} container style={{ marginBottom: '4px' }}>
                  <Grid item xs={12}>
                    <Typography variant="body1">{p.plan}</Typography>
                  </Grid>
                  <>
                    {p.reasons.map((mr, index) => (
                      <Grid container key={index}>
                        <Grid item xs={1}>
                          <Box style={{ marginRight: '8px', paddingTop: '4px' }}>
                            <Stop style={{ fontSize: '14px' }} />
                          </Box>
                        </Grid>
                        <Grid item xs={11}>
                          <Typography variant="body1">{mr}</Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Card>
    )
  );
};

const ScriptForSell = ({ /** @type {<String>[]} */ script, /** @type {any} */ intl }) => {
  return (
    <Fragment>
      <Grid item>
        <Typography variant="h6" style={{ color: '#999' }}>
          {intl.formatMessage({ id: 'gpt-product-recommendation-selling-script-title' })}
        </Typography>
        <Typography variant="body1">{script}</Typography>
      </Grid>
    </Fragment>
  );
};

const MoreProducts = (/**@type {any[]} */ reasons, /**@type {any} */ intl) => {
  return (
    reasons.length > 0 && (
      <Card
        style={{
          margin: '8px',
          padding: '16px',
          backgroundColor: 'rgba(250, 250, 250, 0.9)'
        }}
      >
        <>
          <Typography variant="h6" style={{ color: '#aaa' }}>
            {intl.formatMessage({ id: 'gpt-product-recommendation-other-products-title' })}
          </Typography>
        </>
        {reasons.map((reason, index) => {
          return (
            <Grid key={index} container style={{ marginBottom: '16px' }}>
              <Grid item xs={1}>
                <Box style={{ marginRight: '8px', paddingTop: '4px' }}>
                  <Stop style={{ fontSize: '14px' }} />
                </Box>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1">{reason.plan}</Typography>
                <Typography variant="body2">{reason.reasons.join(', ')}</Typography>
              </Grid>
            </Grid>
          );
        })}
      </Card>
    )
  );
};

const container = connect(
  state => ({
    gptProductRecommendationDrawer: state.control.gptProductRecommendationDrawer
  }),
  {
    toggleControl
  }
)(GPTProductRecommendationDrawer);

export default injectIntl(container);
