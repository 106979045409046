import React, { Component, Fragment } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import _ from 'lodash';
import MyToast from '../../components/MyToast';
import Dashboard from '../Dashboard/container';
import Welcome from '../WelcomePage/container';
import Auth from '../Auth/Auth';
import Login from '../Login/container';
import ResetPassword from '../ForgetPassword/container';
import Signup from '../Signup/container';
import NewSignUp from '../NewSignUp/NewSignUp';
import UserDetails from '../UserDetails/container';
import AddPolicy from '../AddPolicy/container';
import Share from '../Share/container';
import Settings from '../Settings/container';
import Analysis from '../Analysis/container';
import NeedAnalysis from '../Analysis/NeedAnalysis';
import PremiumProtection from '../Analysis/PremiumProtection';
import Reminder from '../Reminder/container';
import Message from '../Message/container';
import AdviserProfile from '../Message/partials/AdviserProfile/container';
import InsuredDetails from '../InsuredDetails/container';
import QRCode from '../QRCode/container';
import LoginDemo from '../LoginDemo/container';
import TopNavigation from '../../components/topNavigation';
import AdvisorDashboardContainer from '../Advisor/Dashboard/container';
import AdvisorBusinessContainer from '../Advisor/Business/container';
import Client from '../Advisor/Client/container';
import ClientMessage from '../Advisor/Message/container';
import Referral from '../Advisor/Referral/container';
import DefaultWhatupMessage from '../Advisor/DefaultMessage/container';
import AdvisorTeamDashboardContainer from '../Advisor/Team/container';
import AcceptTeamInvitation from '../Advisor/Team/teamInvitation/container';
import AdviserTools from '../Advisor/Tools/container';
import HotTopicView from '../Advisor/Tools/hotTopic/container';
import ChequeOutView from '../Advisor/Tools/chequeOut/container';
import NewHelpCenterView from '../Advisor/Tools/NewHelpCenter/NewHelpCenter';
import Guideline from '../../components/guidelines/Guideline/container';
import NotFound from '../NotFound/NotFound';
import setTargetView from '../Advisor/Tools/setTarget/container';
import Dice from '../Dice/container';
import TargetsCalculator from '../TargetsCalculator/container';
import AdviserBlast from '../Advisor/AdviserBlast/container';
import ChooseUserType from '../ChooseUserType/component';
import ClientSignUp from '../ClientSignUp/component';
import SayThankYouModal from '../../components/SayThankYouModal/SayThankYouModal';
import * as Sentry from '@sentry/browser';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { initChartJs } from './chart-js';
import { muiTheme } from './mui-theme';
import ProRequiredDialog from '../../components/SubscriptionComponents/ProRequiredDialog/ProRequiredDialog';
import SubscriptionView from '../Subscription/Subscription';
import PremiumFinancingAnalysis from '../Advisor/Tools/PremiumFinancingAnalysis/PremiumFinancingAnalysis';
import ErrorView from '../../components/ErrorView/ErrorView';
import ConfCall from '../Advisor/Tools/confCall/container';
import FreeTools from '../Advisor/Tools/FreeTools/container';
import '../../index.scss';
import COVIDQuickCheck from '../Advisor/Tools/COVID/COVIDQuickCheck/COVIDQuickCheck';
import COVID from '../Advisor/Tools/COVID/COVID';
import StandardContentDialog from '../../components/StandardContentComponents/StandardContentDialog/StandardContentDialog';
import MoreInfoDialog from '../../components/MoreInfoDialog/MoreInfoDialog';
import { history } from './history';
import { getDefaultRoute } from '../../utils/router';
import DiscardConfirmDialog from '../../components/DiscardConfirmDialog/DiscardConfirmDialog';
import {
  hasQuickClosingCampaignCode,
  isUserConnected,
  shouldEnableChatGPT,
  shouldShowUserFirstGuideline,
  showShowTeamLibrary
} from '../../utils/user';
import ClientTools from '../ClientTools/component';
import ShareWithAdviser from '../Share/ShareWithAdviser/ShareWithAdviser';
import ShareWIthAdviserIn from '../Share/ShareWIthAdviserIn/ShareWIthAdviserIn';
import PolicyReviewContainer from '../PolicyReview/PolicyReviewContainer';
import ExportOptions from '../ExportOptions/ExportOptions';
import AdviserHomePage from '../AdviserHomePage/AdviserHomePage';
import { initSwiperJS } from './swiper-js';
import CreateDemoClient from '../Advisor/CreateDemoClient/CreateDemoClient';
import ReferAdviser from '../ReferAdviser/ReferAdviser';
import QuickClosing from '../QuickClosing/QuickClosing';
import Partnership from '../Advisor/Partnership/component';
import PartnershipDetails from '../Advisor/PartnershipDetails/component';
import { tracking } from '../../utils/tracking';
import MyProfileAdviser from '../MyProfile/MyProfileAdviser';
import MyProfileUser from '../MyProfile/MyProfileUser';
import FreeTrialLazyPackExcludedDialog from '../../components/StandardContentComponents/FreeTrialLazyPackExcludedDialog/FreeTrialLazyPackExcludedDialog';
import DoorOpener from '../Advisor/DoorOpener/DoorOpener';
import MedicalCardReferral from '../Advisor/MedicalCardReferral/MedicalCardReferral';
import PiktoAdviser from '../../components/Pikto/PiktoAdviser';
import MedicalCardMain from '../Advisor/MedicalCardMain/MedicalCardMain';
import MedicalCardPurchase from '../Advisor/MedicalCardPurchase/MedicalCardPurchase';
import MedicalCardQRCode from '../Advisor/MedicalCardQRCode/MedicalCardQRCode';
import MedicalCardQRApply from '../MedicalCardQRApply/MedicalCardQRApply';
import AppLayoutUpdater from '../../components/AppContentContainer/AppLayoutUpdater';
import IFrame from '../iFrames/IFrame';
import PurchaseLazyPack from '../iFrames/PurchaseLazyPack';
import DateClientTools from '../Advisor/MeetingTools/MeetingTools';
import ClientAcquisition from '../Advisor/ClientAcquisition/ClientAcquisition';
import Recruitment from '../Advisor/Recruitment/Recruitment';
import ClientMatching from '../Advisor/ClientMatching/ClientMatching';
import FinancialPyramidContainer from '../Analysis/FinancialPyramid/FinancialPyramidContainer';
import AddToHomePageSnackbar from '../../components/AddToHomePageSnackbar/AddToHomePageSnackbar';
import FinancialChartsDialog from '../../components/ContentsDialog/FinancialChartsDialog/FinancialChartsDialog';
import MyQRDialog from '../../components/MyQRDialog/MyQRDialog';
import ReferralQRCodeDialog from '../../components/ReferralQRCodeDialog/ReferralQRCodeDialog';
import MyWhiteboardDialog from '../../components/MyWhiteboard/MyWhiteboardDialog';
import ReduxConnectedIntlProvider from '../../reduxConnectedIntlProvider';
import MyMuiPickersUtilsProvider from './MyMuiPickersUtilsProvider';
import PrivateRoute from './PrivateRoute';
import AnotherPrivateRoute from './AnotherPrivateRoute';
import MyDetails from '../Advisor/MyDetails/MyDetails';
import AdviserInfoPagePublic from '../AdviserInfoPage/AdviserInfoPagePublic';
import AdviserInfoPageManage from '../Advisor/AdviserInfoPageManage/AdviserInfoPageManage';
import ReferralResponse from '../Advisor/Referral/partials/referralResponce';
import IFrameDialog from '../iFrames/IFrameDialog/IFrameDialog';
import NativeOrWeb from '../../utils/native';
import NativeFallback from '../NewSignUp/contents/NativeFallback/NativeFallback';
import PermissionDeniedDialog from '../../components/PermissionDeniedDialog/PermissionDeniedDialog';
import PermissionDialog from '../../components/PermissionDialog/PermissionDialog';
import NativeFallbackCopy from '../NewSignUp/contents/NativeFallbackCopy/NativeFallbackCopy';
import { Container } from '@material-ui/core';
import NativeUpdatedDialog from '../../components/NativeUpdatedDialog/NativeUpdatedDialog';
import EnterpriseOnlyDialog from '../../components/EnterpriseOnlyDialog/EnterpriseOnlyDialog';
import NativeNetworkOfflineDialog from '../../components/NativeNetworkOfflineDialog/NativeNetworkOfflineDialog';
import { desktop } from '../../utils/device';
import DesktopStyle from './DesktopStyle';
import HotTopicSeeMoreCategory from '../Advisor/Tools/hotTopic/HotTopicSeeMoreCategory';
import ExpenseCalculatorDialog from '../../components/ExpenseCalculatorDialog/ExpenseCalculatorDialog';
import VersionIncompatibleDialog from '../../components/VersionIncompatibleDialog/VersionIncompatibleDialog';
import RecruitmentContentsDialog from '../../components/ContentsDialog/RecruitmentContentsDialog/RecruitmentContentsDialog';
import RecruitmentGoogleForm from '../iFrames/RecruitmentGoogleForm';
import TeamBlast from '../Advisor/TeamBlast/TeamBlast';
import PersonalAssistantSignUp from '../PersonalAssistantSignUp/PersonalAssistantSignUp';
import WhiteboardRemote from '../iFrames/WhiteboardRemote/WhiteboardRemote';
import { WebAppVersionProvider } from '../../contexts/WebAppVersionContext';
import AdviserExport from '../Advisor/AdviserExport/AdviserExport';
import NFCCard from '../iFrames/NFCCard';
import Affiliate from '../Advisor/Affiliate/Affiliate';
import { shouldShowReferAdviserPage } from '../../utils/adviser-referral';
import { INIT, SUCCESS } from '../../constants/phase';
import WhatsappWizard from '../Advisor/WhatsappWizard/WhatsappWizard';
import { getConfiguration } from '../../store/configuration/duck';
import ThirdPartyRedirectDialog from '../../components/ThirdPartyRedirectDialog/ThirdPartyRedirectDialog';
import EmergencyEWalletShare from '../Advisor/EmergencyEWalletShare/EmergencyEWalletShare';
import WalletPassLink from '../WalletPassLink/WalletPassLink';
import WebRedirection from '../WebRedirection/WebRedirection';
import LoadingBackdrop from '../../components/LoadingBackdrop/LoadingBackdrop';
import OTAUpdateDialog from '../../components/OTAUpdateDialog/OTAUpdateDialog';
import Import from '../Settings/Import/Import';
import ReferMyAdviser from '../ReferMyAdviser/ReferMyAdviser';
import ConnectionRequest from '../ConnectionRequest/ConnectionRequest';
import ConnectionRequestAccept from '../ConnectionRequestAccept/ConnectionRequestAccept';
import ChatGPTOptions from '../Advisor/ChatGPTOptions/ChatGPTOptions';
import ChatGPTDisclaimerDialog from '../../components/ClientEntry/ChatGPTDisclaimerDialog/ChatGPTDisclaimerDialog';
import ChatGPTFeedbackDialog from '../../components/ChatGPTFeedbackDialog/ChatGPTFeedbackDialog';
import HotTopicItemDialog from '../Advisor/Tools/hotTopic/HotTopicItemDialog/HotTopicItemDialog';
import TeamLibrary from '../Advisor/TeamLibrary/TeamLibrary';
import TeamLibraryCategory from '../Advisor/TeamLibraryCategory/TeamLibraryCategory';
import RUNMATHDOGHK from '../../views/iFrames/RUNMATHDOGHK.js';
import { AccountDeletion } from '../AccountDeletion';
import { loadStripe } from '@stripe/stripe-js/pure';
import Event from '../Advisor/Event/Event.js';
import EventDetail from '../Advisor/EventDetail/EventDetail.js';
import WeeklyKeywords from '../Advisor/WeeklyKeywords/WeeklyKeywords.js';
import Appointment from '../../views/iFrames/Linker/Appointment/Appointment.js';
import { trackDirect } from '../../store/analytics/api';
import { UserSubscription } from '../../utils/user-subscription';
import LinkerCardView from '../iFrames/Linker/LinkerCardView.js';
import LinkerPublicProfileView from '../iFrames/Linker/LinkerPublicProfileView';
import LinkerEditPorfile from '../../views/iFrames/Linker/EditProfile.js';
import { checkAndRecoverCalendarEvent } from '../../utils/native/calendar';

// add deep link listener
NativeOrWeb.addDeepLinkListener(history);
NativeOrWeb.addNFCListener(history);

// avoid closing the app by clicking the hardware back button
NativeOrWeb.addBackListener(history);

initChartJs();
initSwiperJS();

export const SHOW_SIDE_BAR_WIDTH = 960;

class App extends Component {
  constructor() {
    super();
    this.state = {
      pickerLocale: 'en',
      isDevelopment: process.env.NODE_ENV === 'development',
      hasError: false
    };
  }

  componentDidMount() {
    const { updateRouter, getInsurers } = this.props;

    document.cookie = 'smartbanner_exited=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';

    getInsurers();
    // console.log(`Is updated: ${isLatestVersion}`);

    // if (!this.props.isLatestVersion) {
    //   const toastContent = node => <div onClick={this.props.emptyCacheStorage}>{node}</div>;
    //   console.log('Update available');
    //   toast.info(toastContent(IconToast('info', this.props.intl.formatMessage({ id: 'Update available' }))), {
    //     className: 'new-toast',
    //     autoClose: false
    //   });
    // }
    // Page(URL) Tracking

    this.props.getConfiguration();

    this.meta = document.createElement('meta');
    this.meta.name = 'apple-itunes-app';
    this.meta.content = `app-id=1590830597, app-argument=${encodeURIComponent(window.location.href)}`;
    document.head.appendChild(this.meta);

    this.historyListener = history.listen((path, action) => {
      const onHandleHotTopicRoutes = () => {
        if (action === 'POP') {
          if (window.location.pathname === path.pathname) {
            history.replace(path);
          }
        }
      };

      switch (path.pathname) {
        case '/advisorDashboard':
          // Tracking
          tracking('Navigate to client tab');
          break;
        case '/premium-protection':
          tracking('Premium Protection Accessed');
          break;
        case '/dashboard':
          tracking('Policy Portfolio (Dashboard) Accessed');
          break;
        case '/c-dashboard':
          tracking('Policy Portfolio (Dashboard) Accessed');
          break;
        case '/need-analysis':
          tracking('Need Analysis Accessed');
          break;
        case '/new_help_center':
          onHandleHotTopicRoutes();
          break;
        case '/hot_topic':
          onHandleHotTopicRoutes();
          break;
        default:
          break;
      }

      if (action === 'PUSH' || action === 'REPLACE') {
        window.scrollTo(0, 0);
      }

      this.meta.content = `app-id=1590830597, app-argument=${encodeURIComponent(window.location.href)}`;

      updateRouter({ location: path, action: action });
    });

    if (NativeOrWeb.getPlatform() === 'ios') {
      checkAndRecoverCalendarEvent();
    }
  }

  async UNSAFE_componentWillMount() {
    this.props.currencyExchangeRate();
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }

    this.historyListener();
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { userDetails } = props;

    if (userDetails?._id) {
      if (!this.interval) {
        trackDirect('use');
        this.interval = setInterval(() => {
          trackDirect('use');
        }, 60000);
      }

      // set user information in sentry
      Sentry.configureScope(function (scope) {
        scope.setUser({
          id: userDetails._id,
          email: userDetails.email,
          username: userDetails.name
        });
      });
    } else {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    }
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    const {
      isAuthenticated,
      userDetails,
      requireExplicitLogin,
      setShownDashHelp,
      updateFirstGuidelineState,
      insurerGetPhase,
      getInsurers
    } = this.props;

    if (insurerGetPhase === INIT && prevProps.insurerGetPhase !== INIT) {
      getInsurers();
    }

    if (!prevProps.isAuthenticated && isAuthenticated) {
      localStorage.setItem('tokenLogin', 'false');
    }
    let routePath = getDefaultRoute(userDetails);
    const pathName = window.location.pathname;
    let path = userDetails && userDetails.hideWelcome ? routePath : '/welcome';
    if (
      !requireExplicitLogin &&
      isAuthenticated &&
      (pathName === '/login' || pathName === '/') &&
      !_.isEmpty(userDetails)
    ) {
      if (userDetails.userType === 'User' && !isUserConnected(userDetails)) {
        setShownDashHelp(false);
      } else if (userDetails.userType === 'Adviser') {
      }
      updateFirstGuidelineState(false);
      // history.push({ pathname: path });
    }

    try {
      if (!this.banner && !NativeOrWeb.isNativePlatform() && window.location.pathname.includes('login')) {
        if (window.smartbanner?.publish) {
          if (window.smartbanner.publish() !== false) {
            this.banner = true;
          }
        }
      } else if (this.banner && window.smartbanner?.exit) {
        window.smartbanner.exit();
        this.banner = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const {
      initialAuthDone,
      isAuthenticated,
      userDetails,
      userSubscription,
      visitedDashboard,
      subscriptions,
      subscriptionInitialFetched,
      handleSignOut,
      keepShowingNewSignUp,
      policyReviewPresentMode,
      financialPyramidPresentMode,
      showPadding,
      teamCampaignCodes,
    } = this.props;

    const { hasError } = this.state;
    const showNewSignUp =
      isAuthenticated &&
      _.get(userDetails, 'userType') !== 'User' &&
      ((subscriptionInitialFetched &&
        subscriptions.length === 0 &&
        _.get(history, 'location.pathname', '').toLowerCase() !== '/signup') ||
        keepShowingNewSignUp);
    const userType = _.get(userDetails, 'userType');
    const formattedShowPadding = showPadding && !financialPyramidPresentMode && !showNewSignUp;
    const shouldShowReferAdviserRoute = shouldShowReferAdviserPage(userSubscription);
    const showChatGPT = shouldEnableChatGPT({ user: userDetails, teamCampaignCodes: teamCampaignCodes, userSubscription: userSubscription });
    const showTeamLibrary = showShowTeamLibrary({ user: userDetails, teamCampaignCodes: teamCampaignCodes });

    const userSubscriptionO = new UserSubscription(userSubscription);
    const isProUser = userSubscriptionO.containVaildProSubscription();

    let stripePromise = null;
    let wechatLinks = ['/cheque_out', '/targetsCalculator', '/premium_financing_trial'];
    if (history.location.pathname && !wechatLinks.includes(history.location.pathname.trim())) {
      stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);
    }

    return (
      <WebAppVersionProvider>
        <MuiThemeProvider theme={muiTheme}>
          {<DesktopStyle />}

          {!initialAuthDone && <Auth history={history} />}
          <div
            className="App-pageContainer"
            style={{
              backgroundColor: '#fff',
              display: formattedShowPadding ? 'flex' : undefined,
              width: formattedShowPadding ? '100%' : undefined
            }}
          >
            {formattedShowPadding && <div style={{ width: '60px' }} />}
            <div style={{ width: formattedShowPadding ? 'calc(100% - 60px)' : '100%', height: '100%' }}>
              <ReduxConnectedIntlProvider>
                <MyMuiPickersUtilsProvider>
                  {hasError ? (
                    <ErrorView
                      onClick={() => {
                        handleSignOut();
                        this.setState({ hasError: false });
                      }}
                    />
                  ) : (
                    <Fragment>
                      <MyToast />
                      <Guideline />
                      <VersionIncompatibleDialog />
                      {userDetails && // is logged in
                        userDetails.userType === 'User' && // is user
                        isUserConnected(userDetails) && // is connected
                        visitedDashboard && ( // user has visited dashboard
                          // !shouldShowWelcomeDialog(userDetails) &&  // avoid showing with welcome dialog
                          <SayThankYouModal />
                        )}
                      <ProRequiredDialog history={history} />
                      <MoreInfoDialog />
                      <DiscardConfirmDialog />
                      <FreeTrialLazyPackExcludedDialog history={history} />
                      {/*<AddToHomePageSnackbar />*/}
                      <ReferralQRCodeDialog history={history} />
                      <IFrameDialog history={history} />
                      <PermissionDeniedDialog />
                      <PermissionDialog />
                      <ExpenseCalculatorDialog />
                      <ThirdPartyRedirectDialog />

                      {NativeOrWeb.isNativePlatform() && (
                        <Fragment>
                          <NativeUpdatedDialog />
                          <NativeNetworkOfflineDialog />
                          <OTAUpdateDialog />
                        </Fragment>
                      )}

                      {userDetails && userDetails.userType === 'Adviser' && (
                        <Fragment>
                          <FinancialChartsDialog history={history} />
                          <RecruitmentContentsDialog history={history} />
                          <MyQRDialog history={history} />
                          <MyWhiteboardDialog />
                        </Fragment>
                      )}

                      {initialAuthDone && (
                        <Router history={history}>
                          <React.Fragment>
                            <StandardContentDialog />
                            <HotTopicItemDialog />
                            {userDetails && userDetails.userType === 'Adviser' && (
                              <Fragment>
                                <ChatGPTDisclaimerDialog />
                                <ChatGPTFeedbackDialog />
                              </Fragment>
                            )}

                            {NativeOrWeb.shouldShowEnterpriseDialog(userDetails) && <EnterpriseOnlyDialog />}
                            <AppLayoutUpdater />
                            <Switch>
                              <Route exact path="/" render={() => <></>} />
                              <Route path="/welcome" render={() => <></>} />
                              <Route path="/login" render={() => <></>} />
                              <Route path="/signup" render={() => <></>} />
                              <Route path="/userType" render={() => <></>} />
                              <Route path="/clientSignUp" render={() => <></>} />
                              <Route path="/verify_user/:Id?" render={() => <></>} />
                              <Route path="/verify_Adviser/:adviserId?" render={() => <></>} />
                              <Route path="/need-analysis/overall/safari-gen" render={() => <></>} />
                              <Route path="/need-analysis/life/safari-gen" render={() => <></>} />
                              <Route path="/need-analysis/ci/safari-gen" render={() => <></>} />
                              <Route path="/need-analysis/saving/safari-gen" render={() => <></>} />
                              <Route path="/premium_financing/safari-gen" render={() => <></>} />
                              {_.get(userDetails, 'passwordChangeKey') && (
                                <Route path="/change_password" render={() => <></>} />
                              )}
                              <Route path="/referral_response/:Id?" render={() => <></>} />
                              <Route path="/lp" render={() => <></>} />
                              <Route path="/web-required" render={() => <></>} />
                              <Route path="/profile-plus" render={() => <></>} />
                              <Route path="/adviser/:userId" render={() => <></>} />
                              <Route path="/linker/adviser/:userId" render={() => <></>} />
                              <Route path="/ca/:cardId" render={() => <></>} />
                              <Route path="/pa-signup" render={() => <></>} />
                              <Route path="/whiteboard-remote" render={() => <></>} />
                              <Route path="/reset_password" render={() => <></>} />
                              <Route path="/wallet-pass-link/:token" render={() => <></>} />
                              <Route path="/connection-request/accept" render={() => <></>} />
                              <Route path="/connection-request/:connectionRequestId" render={() => <></>} />
                              <Route
                                render={() =>
                                  showNewSignUp || policyReviewPresentMode || financialPyramidPresentMode ? (
                                    <></>
                                  ) : (
                                    <TopNavigation {...this.props} />
                                  )
                                }
                              />
                            </Switch>
                            <Switch>
                              {/*Public routes*/}
                              <Route
                                exact
                                path="/"
                                render={() => (
                                  <Redirect
                                    to={{ pathname: getDefaultRoute(userDetails), search: history.location.search }}
                                  />
                                )}
                              />
                              <Route path={'/login'} component={Login} />
                              <Route
                                path="/get-portfoplus"
                                render={() => {
                                  history.push('/home');
                                  return <></>;
                                }}
                              />
                              <Route path={'/userType'} component={ChooseUserType} />
                              <Route path={'/clientSignUp'} component={ClientSignUp} />
                              <Route
                                path={'/signup'}
                                render={props => (
                                  <NewSignUp
                                    heading={'Sign Up'}
                                    stepper={true}
                                    withRegister={true}
                                    pageLayout={true}
                                    stripePromise={stripePromise}
                                  />
                                )}
                              />
                              <Route path={'/forgot_password'} component={ResetPassword} />
                              <Route path={'/reset_password/:Id?'} component={ResetPassword} />
                              <Route path={'/verify_user/:Id?'} component={Signup} />
                              <Route path={'/verify_Adviser/:adviserId?'} component={Signup} />
                              <Route path={'/dem/:userType'} component={LoginDemo} />
                              <Route path={'/dem'} component={LoginDemo} />
                              <Route path={'/dice'} component={Dice} />
                              <Route path={'/targetsCalculator'} component={TargetsCalculator} />
                              <Route path={'/share/:Id?'} component={Share} userDetails={userDetails} />
                              <Route path="/share-a/:id" render={props => <ShareWithAdviser />} />
                              <Route path={'/export_pure'} component={Share} userDetails={userDetails} />
                              <Route path={'/share_link_view'} component={Share} userDetails={userDetails} />
                              <Route path={'/view_share_details'} component={Share} userDetails={userDetails} />
                              <Route path={'/insurer_detail'} component={InsuredDetails} />
                              <Route path={'/emergency_info'} component={InsuredDetails} />
                              <Route path={'/consentResponse/:Id?'} component={Client} />
                              <Route
                                path={'/referral_response/:Id?'}
                                component={ReferralResponse}
                                userDetails={userDetails}
                              />
                              <Route path={'/qr_code/:qrCodeId?'} component={QRCode} userDetails={userDetails} />
                              <Route path={'/accpetInvitation/:Id?'} component={AcceptTeamInvitation} />
                              <Route path={'/conf_call/:Id?'} component={ConfCall} userDetails={userDetails} />
                              <Route path={'/cheque_out'} component={ChequeOutView} userDetails={userDetails} />
                              <Route
                                path={'/premium_financing_trial'}
                                render={props => (
                                  <PremiumFinancingAnalysis {...props} userDetails={userDetails} trial={true} />
                                )}
                              />
                              <Route path="/lp" render={() => <PiktoAdviser />} />
                              <Route path="/adviser/:userId" component={AdviserInfoPagePublic} />
                              <Route
                                path="/linker/adviser/:userId"
                                render={props => <LinkerPublicProfileView {...props} stripePromise={stripePromise} />}
                              />
                              <Route path="/ca/:cardId" component={LinkerCardView} />
                              <Route path="/medical-card-qr-apply" component={MedicalCardQRApply} />
                              <Route
                                path="/web-required"
                                render={() => (
                                  <Container maxWidth="sm" style={{ padding: 0 }}>
                                    <NativeFallbackCopy />
                                  </Container>
                                )}
                              />
                              <Route path="/pa-signup" component={PersonalAssistantSignUp} />
                              <Route path="/whiteboard-remote" component={WhiteboardRemote} />
                              <Route path="/wallet-pass-link/:token" component={WalletPassLink} />
                              <Route
                                path="/dl/app"
                                render={() => {
                                  window.location.href = '/';
                                  return <></>;
                                }}
                              />
                              <Route path="/web/" component={WebRedirection} />
                              <Route path="/web/:path" component={WebRedirection} />
                              {/*Private Routes*/}
                              {showNewSignUp && (
                                <Route
                                  path={'*'}
                                  render={props =>
                                    !NativeOrWeb.isIOSPlatform() ? (
                                      <NewSignUp
                                        heading={'subscription'}
                                        stepper={true}
                                        withRegister={false}
                                        pageLayout={true}
                                        checkedVideo={true}
                                        logoutButton={true}
                                        stripePromise={stripePromise}
                                      />
                                    ) : (
                                      <Container maxWidth="sm" style={{ padding: 0 }}>
                                        <NativeFallback />
                                      </Container>
                                    )
                                  }
                                />
                              )}
                              {isAuthenticated && !subscriptionInitialFetched && (
                                <Route render={() => <LoadingBackdrop open={true} />} />
                              )}

                              <PrivateRoute
                                path={'/new_help_center'}
                                component={NewHelpCenterView}
                                userDetails={userDetails}
                              />
                              <PrivateRoute path="/share-a-in/:id" render={props => <ShareWIthAdviserIn />} />
                              <PrivateRoute path={'/welcome'} component={Welcome} userDetails={userDetails} />
                              <PrivateRoute path={'/personalData'} component={UserDetails} userDetails={userDetails} />
                              <PrivateRoute path={'/myDetail'} component={UserDetails} userDetails={userDetails} />
                              <PrivateRoute path="/export_options" component={ExportOptions} />
                              <PrivateRoute path="/policy_review" component={PolicyReviewContainer} />
                              <PrivateRoute path={'/add_portfolio'} component={AddPolicy} userDetails={userDetails} />
                              <PrivateRoute path={'/insurance_type'} component={AddPolicy} userDetails={userDetails} />
                              <PrivateRoute path={'/insurer'} component={AddPolicy} userDetails={userDetails} />
                              <PrivateRoute path={'/plan'} component={AddPolicy} userDetails={userDetails} />
                              <PrivateRoute path={'/payment'} component={AddPolicy} userDetails={userDetails} />
                              <PrivateRoute path={'/detail'} component={AddPolicy} userDetails={userDetails} />
                              <PrivateRoute path={'/apendix'} component={AddPolicy} userDetails={userDetails} />
                              <PrivateRoute path={'/share_from'} component={Share} userDetails={userDetails} />
                              <PrivateRoute
                                path={'/connect_with_adviser'}
                                component={Share}
                                userDetails={userDetails}
                              />
                              <PrivateRoute path={'/export'} component={Share} userDetails={userDetails} />
                              <PrivateRoute path={'/settings'} component={Settings} userDetails={userDetails} />
                              <PrivateRoute path={'/change_password'} component={Settings} userDetails={userDetails} />
                              <PrivateRoute path={'/change_language'} component={Settings} userDetails={userDetails} />
                              <PrivateRoute path={'/change_timezone'} component={Settings} userDetails={userDetails} />
                              <PrivateRoute path={'/about'} component={Settings} userDetails={userDetails} />
                              <PrivateRoute
                                path={'/need-analysis'}
                                exact
                                component={NeedAnalysis}
                                userDetails={userDetails}
                              />
                              <PrivateRoute
                                path={'/premium-protection'}
                                exact
                                component={PremiumProtection}
                                userDetails={userDetails}
                              />
                              <PrivateRoute
                                path={'/analysis_market'}
                                exact
                                component={Analysis}
                                userDetails={userDetails}
                              />
                              <PrivateRoute path={'/saving'} component={Analysis} userDetails={userDetails} />
                              <PrivateRoute path={'/reminder'} component={Reminder} />
                              <PrivateRoute path={'/refer_client'} component={AdviserProfile} />
                              <PrivateRoute path={'/chat'} component={Message} />
                              <PrivateRoute path={'/insured_details'} component={InsuredDetails} />
                              <PrivateRoute path={'/insurer_info'} component={InsuredDetails} />
                              <PrivateRoute path={'/name_card'} component={Referral} userDetails={userDetails} />
                              <PrivateRoute path={'/connect_with_me'} component={Share} userDetails={userDetails} />
                              <PrivateRoute
                                path={'/conf_call_beta/:Id?'}
                                component={ConfCall}
                                userDetails={userDetails}
                              />
                              <PrivateRoute
                                path={'/covid-tools'}
                                component={COVIDQuickCheck}
                                userDetails={userDetails}
                              />
                              {/* Adviser Routes */}
                              <PrivateRoute
                                path={'/advisorDashboard'}
                                component={AdvisorDashboardContainer}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/adviser_business'}
                                component={AdvisorBusinessContainer}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/advisor_team'}
                                component={AdvisorTeamDashboardContainer}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/client'}
                                component={Client}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/client/data/:data'}
                                component={Client}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/client_factsheet'}
                                component={Client}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/adviser_tools'}
                                component={AdviserTools}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/hot_topic'}
                                component={HotTopicView}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/hot_topic_category'}
                                component={HotTopicSeeMoreCategory}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/new_help_center_category"
                                component={HotTopicSeeMoreCategory}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/hot_topic_tag'}
                                component={HotTopicSeeMoreCategory}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/new_help_center_tag"
                                component={HotTopicSeeMoreCategory}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/adviser_chat'}
                                component={ClientMessage}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/referral_records'}
                                render={props => (
                                  <Referral
                                    {...props}
                                    userDetails={userDetails}
                                    userType="Adviser"
                                    stripePromise={stripePromise}
                                  />
                                )}
                              />
                              <AnotherPrivateRoute
                                path={'/need-analysis/overall/safari-gen'}
                                render={props => <NeedAnalysis safariGenerate={'overall'} {...props} />}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <AnotherPrivateRoute
                                path={'/need-analysis/life/safari-gen'}
                                render={props => <NeedAnalysis safariGenerate={'life'} {...props} />}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <AnotherPrivateRoute
                                path={'/need-analysis/ci/safari-gen'}
                                render={props => <NeedAnalysis safariGenerate={'ci'} {...props} />}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <AnotherPrivateRoute
                                path={'/need-analysis/saving/safari-gen'}
                                render={props => <NeedAnalysis safariGenerate={'saving'} {...props} />}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <AnotherPrivateRoute
                                path={'/premium_financing/safari-gen'}
                                render={props => (
                                  <PremiumFinancingAnalysis
                                    safariGenerate={'premium_financing'}
                                    {...props}
                                    userDetails={userDetails}
                                  />
                                )}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/premium_financing'}
                                component={PremiumFinancingAnalysis}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/set_target'}
                                component={setTargetView}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/subscription'}
                                component={SubscriptionView}
                                userDetails={userDetails}
                                userType="Adviser"
                                stripePromise={stripePromise}
                              />
                              <PrivateRoute
                                path="/home"
                                component={AdviserHomePage}
                                userDetails={userDetails}
                                userType="Adviser"
                                stripePromise={stripePromise}
                              />
                              <PrivateRoute
                                path="/add_demo_client"
                                component={CreateDemoClient}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              {shouldShowReferAdviserRoute && (
                                <PrivateRoute
                                  path="/refer_adviser"
                                  component={ReferAdviser}
                                  userDetails={userDetails}
                                  userType="Adviser"
                                />
                              )}
                              {hasQuickClosingCampaignCode(userDetails, teamCampaignCodes) && (
                                <PrivateRoute
                                  path="/quick_closing"
                                  component={QuickClosing}
                                  userDetails={userDetails}
                                  userType="Adviser"
                                />
                              )}
                              <PrivateRoute
                                path={'/partnership'}
                                component={Partnership}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/partnershipDetails'}
                                component={PartnershipDetails}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/frame"
                                render={() => <IFrame showPadding={showPadding} />}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/runmathdoghk"
                                render={() => <RUNMATHDOGHK showPadding={showPadding} />}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              {/*<PrivateRoute
                              path="/apcs"
                              render={() => <APCS showPadding={showPadding} />}
                              userDetails={userDetails}
                              userType="Adviser"
                            />
                            <PrivateRoute
                              path="/nova"
                              render={() => <NOVAAF showPadding={showPadding} />}
                              userDetails={userDetails}
                              userType="Adviser"
                            />
                            <PrivateRoute
                              path="/nova-dir"
                              render={() => <NOVADIR showPadding={showPadding} />}
                              userDetails={userDetails}
                              userType="Adviser"
                            />
                            <PrivateRoute
                              path="/nova-1v"
                              render={() => <NOVA1V showPadding={showPadding} />}
                              userDetails={userDetails}
                              userType="Adviser"
                            />
                             <PrivateRoute
                              path="/synergy"
                              render={() => <Synergy showPadding={showPadding} />}
                              userDetails={userDetails}
                              userType="Adviser"
                            />
                            <PrivateRoute
                              path="/eh"
                              render={() => <EH showPadding={showPadding} />}
                              userDetails={userDetails}
                              userType="Adviser"
                            />
                            <PrivateRoute
                              path="/universe"
                              render={() => <Universe showPadding={showPadding} />}
                              userDetails={userDetails}
                              userType="Adviser"
                            />
                            <PrivateRoute
                              path="/my-team"
                              render={() => <MyTeam showPadding={showPadding} />}
                              userDetails={userDetails}
                              userType="Adviser"
                            />*/}
                              <PrivateRoute
                                path={'/linker/my-details'}
                                component={LinkerEditPorfile}
                                userDetails={userDetails}
                                userType="Adviser"
                                stripePromise={stripePromise}
                              />

                              <PrivateRoute
                                path={'/appointment'}
                                component={Appointment}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/purchase-lazy-pack"
                                render={() => <PurchaseLazyPack showPadding={showPadding} />}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/recruitment-form"
                                render={() => <RecruitmentGoogleForm showPadding={showPadding} />}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/door-opener"
                                component={DoorOpener}
                                userDetails={userDetails}
                                userType="Adviser"
                                stripePromise={stripePromise}
                              />
                              <PrivateRoute
                                path="/medical-card"
                                component={MedicalCardMain}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/medical-card-referral"
                                component={MedicalCardReferral}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/medical-card-purchase"
                                component={MedicalCardPurchase}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/medical-card-qr-code"
                                component={MedicalCardQRCode}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/meeting-tools"
                                component={DateClientTools}
                                userDetails={userDetails}
                                userType="Adviser"
                                stripePromise={stripePromise}
                              />
                              <PrivateRoute
                                path="/client-acquisition"
                                component={ClientAcquisition}
                                userDetails={userDetails}
                                userType="Adviser"
                                stripePromise={stripePromise}
                              />
                              <PrivateRoute
                                path="/recruitment"
                                component={Recruitment}
                                userDetails={userDetails}
                                userType="Adviser"
                                stripePromise={stripePromise}
                              />
                              <PrivateRoute
                                path="/client-matching"
                                component={ClientMatching}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/whatsapp-wizard"
                                component={WhatsappWizard}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/financial-pyramid"
                                component={FinancialPyramidContainer}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/profile-plus"
                                component={AdviserInfoPageManage}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/my-details"
                                component={MyDetails}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/dashboard"
                                component={Dashboard}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/analysis"
                                component={Analysis}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/client_personal_form"
                                component={Client}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/emergency_reference"
                                component={Client}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/adviser_message"
                                component={ClientMessage}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/covid19"
                                component={COVID}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/free_tools"
                                component={FreeTools}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/adviser_blast"
                                component={AdviserBlast}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/team_blast"
                                component={TeamBlast}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/default_whatup_message'}
                                component={DefaultWhatupMessage}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/adviser-export"
                                component={AdviserExport}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/nfc-card"
                                component={NFCCard}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/affiliate"
                                component={Affiliate}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/export-emergency-e-wallet"
                                component={EmergencyEWalletShare}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path="/import"
                                component={Import}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              {showChatGPT && (
                                <PrivateRoute
                                  path="/gpt-options"
                                  component={ChatGPTOptions}
                                  userDetails={userDetails}
                                  userType="Adviser"
                                />
                              )}
                              {/* User Routes */}
                              <PrivateRoute
                                path="/c-dashboard"
                                component={Dashboard}
                                userDetails={userDetails}
                                userType="User"
                              />
                              <PrivateRoute
                                path="/c-analysis"
                                component={Analysis}
                                userDetails={userDetails}
                                userType="User"
                              />
                              <PrivateRoute
                                path="/existing_policy"
                                component={AddPolicy}
                                userDetails={userDetails}
                                userType="User"
                              />
                              <PrivateRoute
                                path="/share_options"
                                component={Share}
                                userDetails={userDetails}
                                userType="User"
                              />
                              <PrivateRoute
                                path="/message"
                                component={Message}
                                userDetails={userDetails}
                                userType="User"
                              />
                              <PrivateRoute
                                path="/client-tools"
                                component={ClientTools}
                                userDetails={userDetails}
                                userType="User"
                              />
                              <PrivateRoute
                                path="/get_users"
                                component={ReferMyAdviser}
                                userDetails={userDetails}
                                userType="User"
                              />
                              <PrivateRoute
                                path="/connection-request/accept"
                                component={ConnectionRequestAccept}
                                userDetails={userDetails}
                                userType="User"
                              />
                              {/* Adviser/User Routes */}
                              <PrivateRoute
                                path={'/my-profile'}
                                component={userType === 'Adviser' ? MyProfileAdviser : MyProfileUser}
                                userDetails={userDetails}
                                userType={userType || 'User'}
                                stripePromise={stripePromise}
                              />
                              <Route path="/connection-request/:connectionRequestId" component={ConnectionRequest} />
                              {showTeamLibrary && (
                                <PrivateRoute
                                  path={'/team_library'}
                                  component={TeamLibrary}
                                  userDetails={userDetails}
                                  userType="Adviser"
                                />
                              )}
                              {showTeamLibrary && (
                                <PrivateRoute
                                  path={'/team_library_category'}
                                  component={TeamLibraryCategory}
                                  userDetails={userDetails}
                                  userType="Adviser"
                                />
                              )}
                              <PrivateRoute
                                path="/account_deletion"
                                exact={true}
                                component={AccountDeletion}
                              ></PrivateRoute>
                              {/* <Route path={'/account_deletion/:Id?'} component={AccountDeletion} /> */}

                              <PrivateRoute
                                path={'/events/:id'}
                                component={EventDetail}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/events'}
                                component={Event}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <PrivateRoute
                                path={'/weekly_keywords/:id'}
                                component={WeeklyKeywords}
                                userDetails={userDetails}
                                userType="Adviser"
                              />
                              <Route path="*" exact={true} component={NotFound} />
                            </Switch>
                          </React.Fragment>
                        </Router>
                      )}
                    </Fragment>
                  )}
                </MyMuiPickersUtilsProvider>
              </ReduxConnectedIntlProvider>
            </div>
          </div>
        </MuiThemeProvider>
      </WebAppVersionProvider>
    );
  }
}

export default App;
