import React, { Fragment, useState, useEffect, useRef } from "react";
import WelcomeDialog from "../WelcomeDialog/WelcomeDialog";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import _ from "lodash";
import { ShortSubscriptions } from "../../utils/user-subscription";
import { tracking } from "../../utils/tracking";
import { updateUserDetails } from "../../store/user/duck";
import { getThankYouApi } from "../../store/thankYou/api";
import { adviserAllModalDone } from "../../store/notificationCenter/duck";
import DateClientDialog from "../DateClientDialog/DateClientDialog";
import TargetModalPopup from "../../views/Advisor/Dashboard/partials/business/DashboardTargetModal/dashboardTargetModal";
import { injectIntl } from "react-intl";
import SubscriptionExpiryReminderDialog from "../SubscriptionComponents/SubscriptionExpiryReminderDialog/SubscriptionExpiryReminderDialog";
import ShowThankYouModal from "../ShowThankYouModal/ShowThankYouModal";
import TeamNewsDialog from "../TeamNewsDialog/TeamNewsDialog";
import AppIconChangingDialog from "./AppIconChangingDialog/AppIconChangingDialog";
import moment from "moment-timezone";

let thankYouChecked = false;
const AdviserLoginLogic = props => {
  const {
    intl,
    history,
    userDetails,
    subscriptions,
    adviserBusiness,
    updateUserDetails,
    adviserAllModalDone,
    onCloseAllDialogs,
    teamNewsUrls
  } = props;

  const [welcomeDialog, setWelcomeDialog] = useState(undefined);
  const [targetDialog, setTargetDialog] = useState(undefined);
  const [dateClientDialog, setDateClientDialog] = useState(undefined);
  const [teamNewsDialog, setTeamNewsDialog] = useState(undefined);
  const [unreadThankYou, setUnreadThankYou] = useState([]);

  const closeWelcomeDialog = () => {
    setWelcomeDialog(false);

    updateUserDetails({ welcomeMessage: false });

    // FB Pixel tracking adviser starting free trial (welcome dialog will only be shown for first time)
    if (
      userDetails.emailVerified &&
      new ShortSubscriptions(subscriptions).isCoreSubscriptionFreeTrial()
    ) {
      // pixelTracking('StartTrial');
    }
    adviserAllModalDone();
    setDateClientDialog(true);
  };

  const closeTargetDialog = () => {
    localStorage.setItem("targetDisplayTime", moment().toISOString());
    setTargetDialog(false);
    adviserAllModalDone();
    // setDateClientDialog(true);
  };

  const closeDateClientDialog = () => {
    // Tracking
    tracking(
      "Q4 Prospecting Series Modal: Continue to operate as usual clicked"
    );
    setDateClientDialog(false);
  };

  const closeTeamNewsDialog = () => {
    localStorage.setItem("teamNewsDisplayTime", moment().toISOString());
    setTeamNewsDialog(false);
  };

  function displayTeamNews(teamNewsUrls) {
    if (
      teamNewsUrls &&
      Array.isArray(teamNewsUrls) &&
      teamNewsUrls.length > 0
    ) {
      let teamNewsDisplayTime = localStorage.getItem("teamNewsDisplayTime");
      if (
        !teamNewsDisplayTime ||
        !moment(teamNewsDisplayTime)
          .tz("Asia/Hong_Kong")
          .isSame(moment().tz("Asia/Hong_Kong"), "day")
      ) {
        setTeamNewsDialog(teamNewsUrls);
      }
    }
  }

  useEffect(() => {
    displayTeamNews(teamNewsUrls);

    if (history.action === "PUSH" && history.location.state) {
      let welcomeMessage = history.location.state.welcomeMessage;
      setWelcomeDialog(!!welcomeMessage);
      setDateClientDialog(false);

      if (welcomeMessage) {
        setTimeout(() => {
          if (history) {
            history.replace({
              ...history.location,
              state: { ...history.location.state, welcomeMessage: undefined }
            });
          }
        }, 500);
      }
    }
  }, [history]);

  useEffect(() => {
    async function getThankYou() {
      if (!thankYouChecked && userDetails?.userType === "Adviser") {
        let apiResult = await getThankYouApi({
          filter: { adviserRead: false }
        });
        thankYouChecked = true;
        if (
          apiResult.success === true &&
          Array.isArray(apiResult.data) &&
          apiResult.data.length > 0
        ) {
          setUnreadThankYou(apiResult.data);
        }
      }
    }

    getThankYou();
    let targetDisplayTime = localStorage.getItem("targetDisplayTime");
    if (
      userDetails?.remindTarget &&
      (!targetDisplayTime ||
        !moment(targetDisplayTime)
          .tz("Asia/Hong_Kong")
          .isSame(moment().tz("Asia/Hong_Kong"), "day"))
    ) {
      setTargetDialog(true);
    }
  }, [userDetails]);

  useEffect(() => {
    if (!welcomeDialog && !targetDialog) {
      adviserAllModalDone();
    }
  }, [welcomeDialog, targetDialog, adviserAllModalDone]);

  useEffect(() => {
    if (
      welcomeDialog === false &&
      targetDialog === false &&
      dateClientDialog === false &&
      teamNewsDialog === false
    ) {
      if (onCloseAllDialogs) {
        onCloseAllDialogs();
      }
    }
  }, [
    dateClientDialog,
    onCloseAllDialogs,
    targetDialog,
    teamNewsDialog,
    welcomeDialog
  ]);

  const isLinkerUser = new ShortSubscriptions(
    subscriptions
  ).containLinkerSubscription();


  return (
    <Fragment>
      <AppIconChangingDialog />
      {!isLinkerUser && (
        <WelcomeDialog
          open={welcomeDialog}
          onGo={closeWelcomeDialog}
          type="adviser"
        />
      )}
      {!isLinkerUser && (
        <DateClientDialog
          open={dateClientDialog}
          onClose={closeDateClientDialog}
        />
      )}
      {!isLinkerUser && (
        <TargetModalPopup
          open={targetDialog}
          onClose={closeTargetDialog}
          intl={intl}
          adviserBusiness={adviserBusiness}
          userDetails={userDetails}
        />
      )}
      {!isLinkerUser && (
        <TeamNewsDialog
          open={!!teamNewsDialog}
          onClose={closeTeamNewsDialog}
          teamNewsUrls={teamNewsDialog}
        />
      )}
      <SubscriptionExpiryReminderDialog history={history} />
      <ShowThankYouModal unreadThankYous={unreadThankYou} />
    </Fragment>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    subscriptions: state.userSubscription.subscriptions,
    adviserBusiness: state.adviserDashboard.adviserBusiness,
    teamNewsUrls: state.team.teamNewsUrls
  }),
  {
    updateUserDetails,
    adviserAllModalDone
  }
)(AdviserLoginLogic);

export default injectIntl(withRouter(container));
