import React, { useState } from 'react';
import { connect } from 'react-redux';
import FunctionCardsView from '../../../components/FunctionCard/FunctionCardsView';
import GuidanceGroup from '../../../components/GuidanceGroup/GuidanceGroup';
import { injectIntl } from 'react-intl';
import { HotLabel } from '../../AdviserHomePage/HotLabel/HotLabel';
import { tracking } from '../../../utils/tracking';
import { updateViewingClient } from '../../../store/client/duck';
import { setClientIdLocalStorage } from '../../../components/ClientEntry/utils';
import { toggleControl, toggleDialog } from '../../../store/control/duck';
import GridButtonLayout from '../../../components/GridButtonLayout/GridButtonLayout';
import CornerTriangle from '../../../components/CornerTriangle/CornerTriangle';
import { FINANCIAL_CHARTS_SAVE_EARLY_ITEM } from '../../../constants/financial-charts';
import { UserSubscription } from '../../../utils/user-subscription';
import SubscriptionDialog from '../../Subscription/SubscriptionDialog/SubscriptionDialog';
// import ECardDialog from '../../../components/ECardDialog/ECardDialog';

const MeetingTools = props => {
  const { intl, history, userDetails, stripePromise, updateViewingClient, toggleDialog, toggleControl } = props;

  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);

  const userSubscriptionO = new UserSubscription(props.userSubscription);
  const currentCoreSubscription = userSubscriptionO.getCurrentCoreSubscription();
  const shouldDisableFunctions = !userSubscriptionO.containVaildProSubscription();

  function openSubscriptionDialog() {
    setSubscriptionDialogOpen(true);
  }

  // const [eCardDialog, setECardDialog] = useState(false);
  // const closeECardDialog = () => setECardDialog(false);

  const getItems = ({ intl, history }) => [
    {
      color: '#5fc3d2',
      imgSrc: 'img/icons/pie_active_color.svg',
      imgAlt: 'useful-charts',
      text: intl.formatMessage({ id: 'fin-charts' }),
      subtitle: intl.formatMessage({ id: 'fin-charts-desc' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on Financial Concept - Client Meet');
          var defaultItem = { ...FINANCIAL_CHARTS_SAVE_EARLY_ITEM };
          toggleDialog('financialCharts', { defaultItem });
        }
      },
      styles: {
        position: 'relative'
      },
      extras: (
        <HotLabel
          text={intl.formatMessage({ id: 'hot' })}
          style={{ zIndex: 1, left: 'unset', right: 0, borderRadius: '4px 0 0 4px' }}
        />
      )
    },
    {
      color: '#3999f8',
      imgSrc: 'img/icons/triangle_active_color.svg',
      imgAlt: 'financial-pyramid',
      text: intl.formatMessage({ id: 'fin-pyramid' }),
      subtitle: intl.formatMessage({ id: 'fin-pyramid-desc' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on Financial pyramid - Client Meet');
          history.push({ pathname: '/financial-pyramid' });
        }
      }
    },
    {
      color: '#2260a5',
      imgSrc: 'img/icons/paper_search_active_color.svg',
      imgAlt: 'policy-review',
      text: intl.formatMessage({ id: 'export-sum-desc' }),
      subtitle: intl.formatMessage({ id: 'mt-policy-review-desc' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on Policy Review - Client Meet');
          toggleControl('chatGPTPersonalMessageDrawer', false);
          history.push('/policy_review');
        }
      },
      styles: {
        position: 'relative'
      },
      extras: (
        <HotLabel
          text={intl.formatMessage({ id: 'hot' })}
          style={{ zIndex: 1, left: 'unset', right: 0, borderRadius: '4px 0 0 4px' }}
        />
      )
    },
    {
      color: '#483fd2',
      imgSrc: 'img/icons/magnify_active_color.svg',
      imgAlt: 'need-analysis',
      text: intl.formatMessage({ id: 'Need Analysis' }),
      subtitle: intl.formatMessage({ id: 'mt-need-analysis-desc' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Click on Need Analysis - Client Meet');
          updateViewingClient(undefined);
          localStorage.removeItem('clientId');
          history.push({
            pathname: '/need-analysis',
            state: { previousPath: '/meeting-tools', clientFactsheetDetail: { clientIncData: { currentSlide: 3 } } }
          });
        }
      }
    }
  ];

  const guidance = [
    {
      label: intl.formatMessage({ id: 'guide-btn-add-client' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Add client before meeting (Joyride) - Client Meet');
          history.push({ pathname: '/client' }, { guidance: 'addClient' });
        }
      }
    },
    {
      label: intl.formatMessage({ id: 'guide-btn-connect-client' }),
      onClick: () => {
        if (shouldDisableFunctions) {
          openSubscriptionDialog();
        } else {
          tracking('Activate client ac. (Joyride) - Client Meet');
          history.push({ pathname: '/client' }, { guidance: 'connectClient' });
        }
      }
    }
  ];

  const premiumFinancing = () => {
    if (shouldDisableFunctions) {
      openSubscriptionDialog();
    } else {
      tracking('Click on Premium Financing -  Client Meet');
      history.push('/premium_financing');
    }
  };

  const chequeWizard = () => {
    if (shouldDisableFunctions) {
      openSubscriptionDialog();
    } else {
      tracking('Click on Cheque Wizard - Client Meet');
      history.push('/cheque_out');
    }
  };

  return (
    <>
      <SubscriptionDialog
        intl={intl}
        userDetails={userDetails}
        stripePromise={stripePromise}
        open={subscriptionDialogOpen}
        onClose={() => setSubscriptionDialogOpen(false)}
        type="upgrade"
        currentSubscription={currentCoreSubscription}
        trialing={false}
        disableCoupon={true}
        defaultCoupon=""
      />

      <FunctionCardsView
        className="meeting-tools"
        getItems={getItems}
        prepend={<GuidanceGroup items={guidance} />}
        append={
          <GridButtonLayout
            items={[
              {
                image: <img src="img/pfinancing.png" alt="premium-financing" />,
                text: intl.formatMessage({ id: 'pfa' }),
                onClick: premiumFinancing,
                extra: <CornerTriangle text="COT" backgroundColor="#FF9900" />
              },
              {
                image: <img src="img/cheque.png" alt="cheque-wizard" />,
                text: intl.formatMessage({ id: 'Cheque Wizard' }),
                onClick: chequeWizard
              }
            ]}
          />
        }
      />
      {/*<ECardDialog open={eCardDialog} onClose={closeECardDialog} />*/}
    </>
  );
};

const container = connect(
  state => ({
    userSubscription: state.userSubscription.userSubscription
  }),
  {
    updateViewingClient,
    toggleDialog,
    toggleControl
  }
)(MeetingTools);

export default injectIntl(container);
