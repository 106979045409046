import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import { Button, IconButton, Typography, CircularProgress } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { getPriceAmountDisplay } from '../../../../utils/user-subscription';
import PricingCard from '../../../../components/SubscriptionComponents/Pricing/PricingCard/PricingCard';
import UpdatePaymentMethod from '../../../../components/SubscriptionComponents/UpdatePaymentMethod/UpdatePaymentMethod';
import { getPaymentMethodApi } from '../../../../store/stripe/api';
import './CurrentCoreSubscriptionPricingCard.scss';

const CurrentCoreSubscriptionPricingCard = props => {
  const { intl, userDetails, stripePromise, header, hideAmountDue, subscription, extras, fullWidth, defaultHideCardDetails, ...rest } = props;

  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const [hideCardDetails, setHideCardDetails] = useState(defaultHideCardDetails);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [noPaymentMethod, setNoPaymentMethod] = useState(false);
  const [updatePaymentMethodOpen, setUpdatePaymentMethodOpen] = useState(false);

  async function fetchPaymentMethod(stripeSubscriptionId) {
    setLoading(true);
    let result = await getPaymentMethodApi(stripeSubscriptionId);
    if (result.defaultPaymentMethod) {
      setNoPaymentMethod(false);
      setPaymentMethod(result.defaultPaymentMethod);
    } else {
      setNoPaymentMethod(true);
      setPaymentMethod(null);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!hideCardDetails && !updatePaymentMethodOpen && subscription.stripeSubscriptionId) {
      fetchPaymentMethod(subscription.stripeSubscriptionId);
    }
  }, [hideCardDetails]);

  useEffect(() => {
    if (initialLoading) {
      // Avoid loading twice initially
      setInitialLoading(false);
    } else if (!hideCardDetails && !updatePaymentMethodOpen && subscription.stripeSubscriptionId) {
      // Add some delay as Stripe may return incorrect default payment method if immediately fetch after change
      setLoading(true);
      const timer = setTimeout(() => {
        fetchPaymentMethod(subscription.stripeSubscriptionId);
        setHideCardDetails(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [updatePaymentMethodOpen]);

  if (!subscription) {
    return null;
  }

    if (intl.locale === "zh-Hant-HK" || intl.locale === "zh") {
      var startedAtText = moment(subscription.startedAt).format('YYYY年M月D日');
      var expiredAtText = moment(subscription.expiredAt).subtract(1, 'days').format('YYYY年M月D日');
  } else {
      var startedAtText = moment(subscription.startedAt).format('D MMM YYYY');
      var expiredAtText = moment(subscription.expiredAt).subtract(1, 'days').format('D MMM YYYY');;
  }
  const priceAmountText = getPriceAmountDisplay(subscription.subscriptionPlan.amount - subscription.currentCouponValue);

  let formattedSubscriptionPlan = { ...subscription.subscriptionPlan };
  if (subscription.subscriptionPlan && subscription.subscriptionPlan.isTrial && subscription.isTrialEnded) {
    formattedSubscriptionPlan.isTrial = false;
  }

  async function openUpdatePaymentMethod() {
    setUpdatePaymentMethodOpen(true);
  }

  function switchCardDetailsVisibility() {
    if (!hideCardDetails) {
      setPaymentMethod(null);
    }
    setHideCardDetails(!hideCardDetails);
  }

  const getCardIconSrc = (brand) => {
    switch (brand.toLowerCase()) {
      case 'visa':
        return 'img/payments/visa.svg';
      case 'mastercard':
        return 'img/payments/mastercard.svg';
      case 'amex':
        return 'img/payments/american_express.svg';
      case 'jcb':
        return 'img/payments/jcb.svg';
      case 'unionpay':
        return 'img/payments/unionpay.svg';
      default:
        return 'img/payments/generic.svg';
    }
  };

  const getWalletIconSrc = (method) => {
    switch (method) {
      case 'apple_pay':
        return 'img/payments/apple_pay.svg';
      case 'google_pay':
        return 'img/payments/google_pay.svg';
      default:
        return null;
    }
  };

  if (paymentMethod?.card) {
    var cardIconSrc = getCardIconSrc(paymentMethod.card.brand);
    var walletIconSrc = getWalletIconSrc(paymentMethod.card.wallet?.type);
  }

  return (
    <>
      <UpdatePaymentMethod
        intl={intl}
        userDetails={userDetails}
        stripePromise={stripePromise}
        open={updatePaymentMethodOpen}
        onClose={() => setUpdatePaymentMethodOpen(false)}
        subscription={subscription}
        subscriptionPlan={subscription.subscriptionPlan}
      />

      <div className={fullWidth ? "pricingCardFullWidth" : "pricingCardWidth"}>
        <PricingCard
          intl={intl}
          header={header}
          plan={formattedSubscriptionPlan}
          content={
            <div>
              <div>{`${intl.formatMessage({ id: 'sub-start-date' })}: ${startedAtText}`}</div>

              <div>
                {`${intl.formatMessage({
                  id: subscription.autoExtend ? 'sub-next-invoice' : 'sub-expired-on'
                })}: ${expiredAtText}`}
              </div>

              {subscription.autoExtend && (
                <>
                  <div>{`${intl.formatMessage({ id: 'sub-amount-due' })}: $${priceAmountText}`}</div>
                  <hr />

                  <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 5 }}>
                    <span>
                      {intl.formatMessage({ id: 'payment-method' })}
                      <IconButton onClick={switchCardDetailsVisibility} style={{ padding: 0, marginBottom: 4, marginLeft: 4 }}>
                        {hideCardDetails ?
                          <VisibilityIcon style={{ fontSize: 16 }} />
                          :
                          <VisibilityOffIcon style={{ fontSize: 16 }} />
                        }
                      </IconButton>
                    </span>

                    <Button
                      variant="outlined"
                      color="primary"
                      size='small'
                      style={{ minWidth: 'unset', padding: "1px 5px", fontSize: "small" }}
                      onClick={openUpdatePaymentMethod}
                    >
                      {intl.formatMessage({ id: 'Update' })}
                    </Button>
                  </div>

                  <div>
                    {loading ?
                      <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress size={24} />
                      </div>
                      :
                      !hideCardDetails && noPaymentMethod ?
                        <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                          {intl.formatMessage({ id: 'no-payment-method-on-file' })}
                        </div>
                        :
                        <div className='paymentMethodInfo'>
                          <span style={{ display: 'flex', alignItems: 'center' }}>
                            {hideCardDetails || !paymentMethod || !paymentMethod.card || !walletIconSrc ?
                              null
                              :
                              <img src={walletIconSrc} alt={`${paymentMethod?.card?.wallet?.type} icon`} height="24" style={{ marginRight: 3 }} />
                            }

                            {hideCardDetails || !paymentMethod || !paymentMethod.card ?
                              <img src={'img/payments/generic.svg'} alt={`generic card icon`} height="24" style={{ marginRight: 3 }} />
                              :
                              <img src={cardIconSrc} alt={`${paymentMethod?.card?.brand} icon`} height="24" style={{ marginRight: 3 }} />
                            }

                            <Typography style={{ marginLeft: 2 }}>
                              {`•⁠•⁠•⁠⁠• •⁠•⁠•⁠⁠•⁠ •⁠•⁠•⁠⁠•⁠⁠ ${hideCardDetails || !paymentMethod || !paymentMethod.card ?
                                "•⁠•⁠•⁠⁠•"
                                :
                                paymentMethod.card.last4}`
                              }
                            </Typography>
                          </span>

                          <Typography>
                            {intl.formatMessage({ id: 'expiration' })} {
                              hideCardDetails || !paymentMethod || !paymentMethod.card ?
                                "•⁠•/•⁠•"
                                :
                                `${paymentMethod.card.exp_month < 10 ? '0' + paymentMethod.card.exp_month : paymentMethod.card.exp_month}/${paymentMethod.card.exp_year.toString().substring(2)}`
                            }
                          </Typography>
                        </div>
                    }
                  </div>
                </>
              )}
            </div>
          }
          extras={extras}
          style={{ width: "100%" }}
          {...rest}
        />
      </div>
    </>
  );
};

export default injectIntl(CurrentCoreSubscriptionPricingCard);
