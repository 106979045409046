import React, { useState, useEffect, useRef } from 'react';

function ResponsiveWrapper({
    children,
    childRef,
    maxWidth,
    maxHeight,
    excludeHeight,
    containerStyle,
}) {
    const [scale, setScale] = useState(1);
    const [childWidth, setChildWidth] = useState(0);
    const [childHeight, setChildHeight] = useState(0);
    const max = {
        width: maxWidth ?? window.innerWidth,
        height: maxHeight ?? window.innerHeight,
    };
    useEffect(() => {
        const outer = childRef.current;
        const _childWidth = outer.clientWidth;
        const _childHeight = outer.clientHeight;

        setChildWidth(_childWidth);
        setChildHeight(_childHeight);

        const handleResize = () => {
            if (max.height >= _childHeight && max.width >= _childWidth) {
                // console.log('scale set 1');
                setScale(1);
                return;
            } else if (excludeHeight && max.width <= _childWidth) {
                // console.log('excludeHeight::: ', excludeHeight, ' max.width::: ', max.width, ' _childWidth::: ', _childWidth);
                setScale(max.width / _childWidth);
                return;
            } else if (excludeHeight && max.width >= _childWidth) {
                setScale(1);
                return;
            }
            else {
                const _scale = Math.min(max.width / _childWidth, max.height / _childHeight);
                setScale(_scale);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial scaling

        return () => window.removeEventListener('resize', handleResize);
    }, [childRef?.current?.clientHeight, childRef?.current?.clientWidth]);

    const asperatio = (childWidth / childHeight)
    const boxSize = {
        width: childWidth,
        height: excludeHeight ? childHeight * (asperatio * scale) : max.height,
    }

    // console.log('ResponsiveWrapper::: ',
    //     ' scale::: ', scale,
    //     ' asperatio', asperatio,
    //     ' boxSize::: ', boxSize,
    //     ' max.width::: ', max.width,
    //     ' windowHeight::: ', max.height,
    //     ' childWidth::: ', childWidth,
    //     ' childHeight::: ', childHeight,
    //     ' maxWidth::: ', maxWidth,
    //     ' maxHeight::: ', maxHeight,
    //     ' containerStyle::: ', containerStyle,
    //     ' verticalRef::: ', verticalRef,
    //     ' excludeHeight ::: ', excludeHeight,
    // );

    // console.log('ResponsiveWrapper::: ', ' verticalRef?.current?.offsetTop::: ', verticalRef?.current?.offsetTop);


    return scale >= 1 ? children : (
        <div style={{
            overflow: 'hidden',
            display: 'flex',
            width: '100%',
            justifyContent: 'left',
            alignItems: 'start',
            ...containerStyle
        }}>
            <div style={{}}>
                <div style={{ position: 'absolute' }}>
                    <div style={{
                        transform: `scale(${scale})`,
                    }}>
                        <div id="wrap"
                            style={{
                                width: `${boxSize.width}px`,
                                position: 'absolute'
                            }}>
                            {children}
                        </div>
                    </div >
                </div>
            </div>
        </div >
    );
}

export default ResponsiveWrapper;