import mixpanel from 'mixpanel-browser';
// import amplitude from 'amplitude-js/amplitude';
// import store from "../../store";

/* 
From JAN 2024, tracking sdk change from amplitude-js/amplitude to @amplitude/analytics-browser
SDK document: https://www.docs.developers.amplitude.com/data/sdks/browser-2/
*/

import * as amplitude from '@amplitude/analytics-browser';

// For next big update (Tracking, the init service should move to here)
// And should set one time only, unless it's necessary

const setAmplitude = (userId, props) => {
  try {
    const identity = {
      Name: props.name,
      Email: props.email,
      'Email Verified': props.emailVerified,
      'User Type': props.userType,
      'Last Login': props.lastLogin,
      'Campaign Codes Text': props.campaignCodesText,
      'Opt Out Email': props.optOutOfEmail,
      Language: props.language,
      'Team Name': props.teamDetails?.teamNames,
      'Team Short Name': props.teamDetails?.teamShortNames,
      'Team Campaign Codes': props.teamDetails?.teamCampaignCodes,
      Teams: props.teams,
      Seniority: props.seniority,
      'Personality Tags': props.personalityTags,
      Personality: props.personality,
      'Is MDRT': props.isMDRT,
      Age: props.age,
      DOB: props.dob,
      Gender: props.gender,
      Hobbies: props.hobbies
    };

    Object.keys(identity).forEach(key => {
      if (!identity[key] || identity[key] === null || identity[key] === undefined) {
        delete identity[key];
      }
    });

    const _i = new amplitude.Identify();
    Object.keys(identity).forEach(key => {
      _i.set(key, identity[key]);
    });

    amplitude.setUserId(userId);
    amplitude.identify(_i);
  } catch (error) {
    console.log('error set amplitude error ::: ', error);
  }
};

const setMixpanel = (userId, props) => {
  try {
    const identity = {
      $name: props.name,
      $email: props.email,
      $emailVerified: props.emailVerified,
      $created: props.createDate,
      $lastLogin: props.lastLogin,
      $campaignCodesText: props.campaignCodesText,
      $optOutOfEmail: props.optOutOfEmail,
      $language: props.language,
      $hasValidCoreSubscription: props.hasValidCoreSubscription,
      $isTrialing: props.isTrialing,
      $teamName: props.teamDetails?.teamNames,
      $teamShortName: props.teamDetails?.teamShortNames,
      $teamCampaignCodes: props.teamDetails?.teamCampaignCodes,
      $teams: props.teams,
      'User Type': props.userType,
      $gender: props.gender,
      $hobbies: props.hobbies,
      $seniority: props.seniority,
      $personalityTags: props.personalityTags,
      $personality: props.personality,
      $isMDRT: props.isMDRT,
      $age: props.age,
      $dob: props.dob
    };

    // remove props identity that's undefined
    Object.keys(identity).forEach(key => {
      if (!identity[key] || identity[key] === null || identity[key] === undefined) {
        delete identity[key];
      }
    });

    mixpanel.identify(userId);
    mixpanel.people.set(identity);
  } catch (error) {
    console.log('error set mixpanel error ::: ', error);
  }
};

export const setTrackingUserProps = (userId, props) => {
  setAmplitude(userId, props);
  setMixpanel(userId, props);
};
