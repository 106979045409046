import React, { Fragment, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles, Switch } from '@material-ui/core';
import { Preferences } from '@capacitor/preferences';

const useStyles = makeStyles({
  root: {
    marginRight: -8
  }
});

const StaySignedInSwitch = props => {
  const [staySignedIn, setStaySignedIn] = useState(false);
  const classes = useStyles(props);

  useEffect(() => {
    const checkStaySignedIn = async () => {
      let initialStaySignedIn = await Preferences.get({ key: 'loginTimeout' });
      if (initialStaySignedIn.value === "true"){
        setStaySignedIn(false);
      } else{
        setStaySignedIn(true);
      }
    };

    checkStaySignedIn();
  }, []);

  const onChange = async event => {
    let newStaySignedIn = event.target.checked;
    await Preferences.set({ key: 'loginTimeout', value: (!newStaySignedIn).toString() });
    setStaySignedIn(newStaySignedIn);
  };

  return (
    <Fragment>
      <Switch color="primary" checked={staySignedIn} onChange={onChange} classes={classes} />
    </Fragment>
  )
};

export default injectIntl(StaySignedInSwitch);
